import _ from "lodash";
import { CopyItem } from "../../common";

export const getBrandDisplayData = (items) => {
  if (_.isEmpty(items)) {
    return [];
  }

  return _.map(items, (item) => {
    return {
      uuid: <CopyItem text={_.get(item, "uuid", "")} />,
      name: _.get(item, "name", ""),
      logo: _.get(item, "logo", ""),
      title: _.get(item, "title", ""),
      website: _.get(item, "website.link", ""),
      overlay_banner_ad_text: _.get(item, "overlay_banner_ad_text"),
      brand_color: _.get(item, "brand_color"),
      branded_partner_page: _.get(item, "branded_partner_page"),
      status: _.get(item, "status"),
      order_number: _.get(item, "order_number"),
      countries_allowed: _.get(item, "countries_allowed"),
      countries_disallowed: _.get(item, "countries_disallowed"),
    };
  });
};
