import _ from "lodash";
import React from "react";

const QalboxBrandAdUnit = ({index, adUnitObject, onChange}) => {
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;

    const nameSplitArr = name.split('.');

    if (nameSplitArr && nameSplitArr.length > 1) {
        const newObject = {...adUnitObject[nameSplitArr[0]], [nameSplitArr[1]]: value}
        const newAdUnit = {...adUnitObject, [nameSplitArr[0]]: newObject}
        onChange(index, newAdUnit);
        return;
    }

    const newAdUnit = {...adUnitObject, [name]: value}
    onChange(index, newAdUnit);
  }

  const handleInputCheckBoxChange = (e) => {
    const { name, checked } = e.target;
    const newAdUnit = {
        ...adUnitObject,
        [name]: checked,
    };
    onChange(index, newAdUnit);
  }
  
  return (
    <div>
      <div className="form-group">
        <label>Is Active</label>
        <input
          type="checkbox"
          name="active"
          checked={adUnitObject && adUnitObject.active}
          onChange={handleInputCheckBoxChange}
          style={{ transform: "scale(1.5)", marginLeft: "10px" }}
        />
      </div>
      <div className="form-group">
        <label>Ad Space</label>
        <input
          value={
            adUnitObject && adUnitObject.ad_space ? adUnitObject.ad_space : ""
          }
          onChange={handleInputTextChange}
          type="text"
          name="ad_space"
          className="form-control"
          placeholder="Android Ad Unit ID"
        />
      </div>
      <div className="form-group">
        <label>Android Ad Unit ID</label>
        <input
          value={
            adUnitObject && adUnitObject.ad_units && adUnitObject.ad_units.android ? adUnitObject.ad_units.android : ""
          }
          onChange={handleInputTextChange}
          type="text"
          name="ad_units.android"
          className="form-control"
          placeholder="Android Ad Unit ID"
        />
      </div>
      <div className="form-group">
        <label>IOS Ad Unit ID</label>
        <input
          value={adUnitObject && adUnitObject.ad_units && adUnitObject.ad_units.ios ? adUnitObject.ad_units.ios : ""}
          onChange={handleInputTextChange}
          type="text"
          name="ad_units.ios"
          className="form-control"
          placeholder="IOS Ad Unit ID"
        />
      </div>
    </div>
  );
};

export default QalboxBrandAdUnit;
