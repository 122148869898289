const HAJJ_AND_UMRAH_TYPE = Object.freeze({
  GUIDES: 'guides',
  RESOURCES: 'resources'
});

const SUPPORTED_LANGUAGES = Object.freeze({
  English: 'en',
  'Bahasa Indonesia': 'id',
  'Bahasa Melayu': 'ms',
  French: 'fr',
  Arabic: 'ar',
});

const SPECIAL_HEADERS_COUNT = 2;

const IMAGES_DIRECTORY_PREFIX = 'images/hajj_and_umrah';

export {
  HAJJ_AND_UMRAH_TYPE,
  SUPPORTED_LANGUAGES,
  SPECIAL_HEADERS_COUNT,
  IMAGES_DIRECTORY_PREFIX,
};


