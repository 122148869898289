import _ from 'lodash';
import React,
{
  Fragment,
  useState,
  useEffect,
  useReducer,
} from 'react';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  MPInput,
  OperationStatus,
} from '../../widgets/common';
import './style.css';
import {
  deleteUserLogsReducer,
  DeleteUserLogsInitialState,
} from './Reducers';
import {
  actionTypes,
} from './actionTypes';
import {
  confirmAlert,
} from "react-confirm-alert";
import AppModal from '../../widgets/modal';
import {
  getUser,
} from '../../services/mp-cf-api-service';
import {
  deleteUserLogsHook,
  getUserLogsRowCountHook,
} from '../../hooks';
import {
  getSelectedTables,
  getSelectedCheckBoxTables,
} from './helper';
import {
  UserLogsDetailsList,
  UserLogsOperationStatusesList,
} from './components';
import {
  createLog,
} from '../../services/logs';
import DataModeratorWrapper from '../../widgets/roledwrapper/datamoderatorwrapper';

const DeleteUserLogs = ({
                          history,
                        }) => {
  let userId;
  let existingState;

  if(history) {
    const { state } = history.location;
    userId = state ? state.userId : null;
    if(userId) {
      existingState = {
        ...DeleteUserLogsInitialState,
        firebaseUID: userId,
      }
    }
  }

  const [ fetchingFirebaseData, setFetchingFirebaseData ]  = useState(false);
  const [ userLogsDeleteData, doDelete, resetDeleteData ] = deleteUserLogsHook();
  const [ userLogsRowsCountData, doFetch, resetFetchData ] = getUserLogsRowCountHook();
  const[ state, dispatch ]  = useReducer(deleteUserLogsReducer,
    userId ? existingState : DeleteUserLogsInitialState);
  const {
    email,
    idfa,
    phone,
    deviceId,
    ipAddress,
    firebaseUID,
    selectedTables,
  } = state;
  const isFetching = userLogsRowsCountData && userLogsRowsCountData.isFetching;
  const isDeleting = userLogsDeleteData && userLogsDeleteData.isDeleting;

  useEffect(async () => {
    if(!firebaseUID && email) {
      setFetchingFirebaseData(true);
      const { data= {} } = await getUser(firebaseUID);
      setFetchingFirebaseData(false);
      if(!_.isEmpty(data)) {
        dispatch({
          type: actionTypes.DUL_UPDATE_INPUT_DATA,
          payload: data,
        });
      }
    }
  }, [email]);

  useEffect(async () => {
    if(firebaseUID) {
      setFetchingFirebaseData(true);
      const { data= {} } = await getUser(firebaseUID);
      setFetchingFirebaseData(false);
      if(!_.isEmpty(data)) {
        dispatch({
          type: actionTypes.DUL_UPDATE_INPUT_DATA,
          payload: data,
        });
      }
    }
  }, [firebaseUID]);

  useEffect(async () => {
    if(!firebaseUID && phone) {
      setFetchingFirebaseData(true);
      const { data= {} } = await getUser(firebaseUID);
      setFetchingFirebaseData(false);
      if(!_.isEmpty(data)) {
        dispatch({
          type: actionTypes.DUL_UPDATE_INPUT_DATA,
          payload: data,
        });
      }
    }
  }, [phone]);

  const onClickReset = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={false}
            headingText={`Are you sure want to reset the data you entered ?`}
            closeButtonText={'No'}
            okButtonText={`Yes, Reset`}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              dispatch({
                type: actionTypes.DUL_RESET,
              });
              resetFetchData({});
              resetDeleteData({});
            }}
          />
        );
      }
    });
  };

  const onTableDataFetchComplete = operationData => {
    if(!operationData.isError) {
      toast.success('Logs data fetch success.', {
        position: toast.POSITION.BOTTOM_LEFT
      });
      const selectedBQTables = getSelectedTables(operationData.data);
      dispatch({
        type: actionTypes.DUL_UPDATE_PROP_VAL,
        payload: {
          prop: 'selectedTables',
          value: selectedBQTables,
        },
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onClickSearch = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={false}
            headingText={`Are you sure want to start the search with the data you entered ?`}
            closeButtonText={'No'}
            okButtonText={`Yes, Search`}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              doFetch({
                requestData: {
                  email, firebaseUID, ipAddress, phone, deviceId, idfa,
                },
                onOperationComplete: onTableDataFetchComplete,
              });
              resetDeleteData({});
            }}
          />
        );
      }
    });
  };

  const onDeleteComplete = operationData => {
    if(!operationData.isError) {
      createLog('delete_user_bq_data_success', {
        selectedTables,
        userDetails: {
          idfa,
          email,
          phone,
          deviceId,
          ipAddress,
          firebaseUID,
        },
      });
      toast.success('Logs data delete success.', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onClickDelete = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={false}
            headingText={`Are you sure want to delete the all the you selected ?`}
            closeButtonText={'No'}
            okButtonText={`Yes, Delete`}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              doDelete({
                requestData: {
                  selectedTables,
                  userDetails: {
                    idfa,
                    email,
                    phone,
                    deviceId,
                    ipAddress,
                    firebaseUID,
                  },
                },
                onOperationComplete: onDeleteComplete,
              });
            }}
          />
        );
      }
    });
  };

  const shouldEnableSearchBtn = ((firebaseUID && email) || (firebaseUID || phone)
    || deviceId || ipAddress || idfa) && !fetchingFirebaseData;
  const shouldEnableResetBtn = email || phone || firebaseUID || deviceId || ipAddress || idfa;
  const shouldEnableDeleteBtn = !_.isEmpty(selectedTables);

  return (
    <BasicLayout pagePermission="moderate_precalc">
      <DataModeratorWrapper showErrorMessage={true} >
        <ToastContainer autoClose={2000}/>
          <Row>
            <form className={'form'}>
              <Col md={12}>
                <h3 className='content_editor_title'>{'Delete User Logs'}</h3>
              </Col>
              {
                (isDeleting || isFetching)
                  ?
                  <OperationStatus
                    operationInProgressText={`Please wait! ${isDeleting ? 'Deleting' : 'Fetching'} user logs...`}
                  />
                  :
                  <Fragment>
                    <Col md={6}>
                      <div className='form-group'>
                        <MPInput
                          showLabel={true}
                          inlineLabel={false}
                          showLoader={fetchingFirebaseData}
                          labelText={'Email'}
                          value={email}
                          placeHolder={'Email'}
                          onChangeValue={(value) => dispatch({
                            type: actionTypes.DUL_UPDATE_PROP_VAL,
                            payload: {
                              prop: 'email',
                              value,
                            },
                          })
                          }
                        />
                      </div>
                      <div className='form-group'>
                        <MPInput
                          showLabel={true}
                          inlineLabel={false}
                          labelText={'Firebase UID'}
                          value={firebaseUID}
                          placeHolder={'Firebase UID'}
                          onChangeValue={(value) => dispatch({
                            type: actionTypes.DUL_UPDATE_PROP_VAL,
                            payload: {
                              prop: 'firebaseUID',
                              value,
                            },
                          })
                          }
                        />
                      </div>
                      <div className='form-group'>
                        <MPInput
                          showLabel={true}
                          inlineLabel={false}
                          labelText={'IP Address'}
                          value={ipAddress}
                          placeHolder={'IP Address'}
                          onChangeValue={(value) => dispatch({
                            type: actionTypes.DUL_UPDATE_PROP_VAL,
                            payload: {
                              prop: 'ipAddress',
                              value,
                            },
                          })
                          }
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='form-group'>
                        <MPInput
                          showLabel={true}
                          inlineLabel={false}
                          showLoader={fetchingFirebaseData}
                          labelText={'Phone'}
                          value={phone}
                          placeHolder={'Phone'}
                          onChangeValue={(value) => dispatch({
                            type: actionTypes.DUL_UPDATE_PROP_VAL,
                            payload: {
                              prop: 'phone',
                              value,
                            },
                          })
                          }
                        />
                      </div>
                      <div className='form-group'>
                        <MPInput
                          showLabel={true}
                          inlineLabel={false}
                          labelText={'Device Id'}
                          value={deviceId}
                          placeHolder={'Device Id'}
                          onChangeValue={(value) => dispatch({
                            type: actionTypes.DUL_UPDATE_PROP_VAL,
                            payload: {
                              prop: 'deviceId',
                              value,
                            },
                          })
                          }
                        />
                      </div>
                      <div className='form-group'>
                        <MPInput
                          showLabel={true}
                          inlineLabel={false}
                          labelText={'IDFA'}
                          value={idfa}
                          placeHolder={'IDFA'}
                          onChangeValue={(value) => dispatch({
                            type: actionTypes.DUL_UPDATE_PROP_VAL,
                            payload: {
                              prop: 'idfa',
                              value,
                            },
                          })
                          }
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <Button
                        onClick={onClickSearch}
                        disabled={!shouldEnableSearchBtn}
                        className="btn btn-primary">
                        <span className={'glyphicon glyphicon-search'} aria-hidden='true'/>
                        &nbsp;{'Search Logs'}
                      </Button>
                      <Button
                        style={{ 'marginLeft': '5px' }}
                        onClick={onClickReset}
                        disabled={!shouldEnableResetBtn}
                        className="btn btn-primary">
                        <span className={'glyphicon glyphicon-repeat'} aria-hidden='true'/>
                        &nbsp;{'Reset'}
                      </Button>
                    </Col>
                    <UserLogsDetailsList
                      userLogsRowsCountData={userLogsRowsCountData}
                      userLogsDeleteData={userLogsDeleteData}
                      onClickDelete={onClickDelete}
                      onUpdateValues={(value) => dispatch({
                        type: actionTypes.DUL_UPDATE_PROP_VAL,
                        payload: {
                          prop: 'selectedTables',
                          value: getSelectedCheckBoxTables(value),
                        },
                      })}
                      shouldEnableDeleteBtn={shouldEnableDeleteBtn}
                    />
                    <UserLogsOperationStatusesList
                      userLogsStatusData={userLogsDeleteData}
                      dataKey={'table_log_delete_statuses'}
                    />
                  </Fragment>
              }
            </form>
          </Row>
        </DataModeratorWrapper>
    </BasicLayout>
  );
};

export default DeleteUserLogs;
