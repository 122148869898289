import _ from 'lodash';
import React,
{
  Component,
} from 'react';
import {
  Badge,
  Col,
  Row,
} from 'react-bootstrap';
import LoginButton from './loginbutton.js';
import HeaderAlert from '../headeralert';
import mplogo from './mp_logo_activate_acct.png';
import './style.css';
import {
  ENVIRONMENT,
  WEB_APP_ENV,
} from '../../Constants';

class Header extends Component {
  renderLoginButton() {
    const {
      user,
    } = this.props;

    if(user) {
      return (
        <div className="header__right_menu">
          <span className="header__display_name">{user.displayName}</span>
          <a className="header__logout" href="/logout">Logout</a>
        </div>
      );
    } else if(this.props.page !== "login"){
      return <LoginButton />;
    }
  }

  render() {
    const {
      alertType,
      alertMessage,
    } = this.props;

    const envClass = WEB_APP_ENV === ENVIRONMENT.PRODUCTION
      ? 'env_warn'
      : 'env_no_warn';

    return (
      <Row>
        <Col md={12}>
          <header className="header">
            <HeaderAlert alertMessage={alertMessage} alertType={alertType}/>
            <div className="header__inner">
              <a href="/" className="header__branding">
                <img
                  alt="Muslim Pro Logo"
                  width="45"
                  src={mplogo}
                />
                &nbsp;
                <div className={'header__name'}>
                  <div>
                    {`Admin Console`}
                  </div>
                  <div className={'header_env_name'}>
                    <Badge
                      className={envClass}
                    >
                      {_.startCase(WEB_APP_ENV)}
                    </Badge>
                  </div>
                </div>
              </a>
              {this.renderLoginButton()}
            </div>
          </header>
        </Col>
      </Row>
    );
  }
}

export default Header;
