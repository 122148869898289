import React,
{
  Component,
} from 'react';
import './style.css';

class LoginButton extends Component {
  render() {
    return (
      <a href="/login" className="header__login_btn">Login</a>
    );
  }
}

export default LoginButton;
