import React,
{
  Component,
} from 'react';
import firebase from 'firebase/app';
import BasicLayout from '../../layouts/basiclayout';
import {
  Row,
  Col,
  Button,
  FormControl,
  ControlLabel,
} from 'react-bootstrap';
import {
  WEB_APP_URL,
  RTDB_DATABASE,
  RTDB_DATABASE_URL,
  RTDB_DATABASE_NODE,
} from '../../Constants';
import DateTime from '../../widgets/datetime';
import './style.css';
import rtdbRef from "../../apis/rtdbRef";

const RTDB = ((path) => {
  return firebase.database().ref(path);
});

async function getCodeDetailFromRTDB(code) {
  let detail;
  let snapshot = await RTDB(`/promoCodesMultiUsage/${code}`).once('value');
  detail = snapshot.val();
  if(detail) {
    detail.promoType = "multi";
  } else {
    snapshot = await rtdbRef.promoCodes(`/promoCodes/${code}`).once('value');
    detail = snapshot.val();
    if(detail) {
      detail.promoType = "onetime";
    }
  }
  return detail;
}

function saveHistory(code) {
  let codes = localStorage.getItem('PromoCodeSearchHistory');
  if(!codes) {
    codes = [];
  } else {
    codes = JSON.parse(codes);
  }

  if(codes.indexOf(code) === -1) {
    codes = [code].concat(codes);
    codes = codes.slice(0, 20);
    localStorage.setItem('PromoCodeSearchHistory', JSON.stringify(codes));
  }
}

function getHistory() {
  const codes = localStorage.getItem('PromoCodeSearchHistory');
  if(!codes) {
    return [];
  } else {
    return JSON.parse(codes);
  }
}

class SearchPromoCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoCode: "",
      promoCodeInput: "",
      codeDetail: null,
      fetching: false,
      moderators: localStorage.getItem('moderatorsInfo') ? JSON.parse(localStorage.getItem('moderatorsInfo')) : null
    };
  }

  componentWillMount() {
    const query = this.props.match.params.query;
    if(query) {
      saveHistory(query);
      this.setState({
        promoCode: query,
        promoCodeInput: query
      });
      this.searchForPromoCode(query);
    }
  }

  async searchForPromoCode(query) {
    this.setState({ fetching: true });
    const codeDetail = await getCodeDetailFromRTDB(query);
    this.setState({
      codeDetail,
      fetching: false
    });
  }

  renderDetail(codeDetail, fetching, promoCode, moderators) {
    if(fetching) {
      return <div className="promo__code__detail">
        Promo code detail loading...
      </div>;
    } else {
      if(!codeDetail) {
        return <div className="promo__code__detail">
          Code not found. <strong>{promoCode}</strong> is not a valid code.
        </div>;
      } else {
        const { promoType } = codeDetail;
        let detailComp;
        if(promoType === "onetime") {
          detailComp = this.renderOnetimeDetail(codeDetail, promoCode, moderators);
        } else {
          detailComp = this.renderMultiDetail(codeDetail, promoCode, moderators);
        }
        return <div className="promo__code__detail">
          {detailComp}
        </div>
      }
    }
  }

  renderOnetimeDetail(codeDetail, promoCode, moderators) {
    const {
      type,
      expiry,
      user_id,
      campaign,
      duration,
      subdomain,
      created_by,
      redeem_datetime,
    } = codeDetail;
    const dbURL =
      `${RTDB_DATABASE_URL}${RTDB_DATABASE.MUSLIM_PRO_APP}/${RTDB_DATABASE_NODE.PROMO_CODE_MULTI_USAGE}/${promoCode}`;
    const subdomainUrl = `https://${subdomain}.muslimpro.com`;
    const modProfileUrl = `${WEB_APP_URL}/search-user/${created_by}`;
    return <div>
      <p className="small">Showing result for <a target="_blank" href={dbURL}>{promoCode}</a></p>
      <p><strong>Promo type: </strong>One-time usage</p>
      <p><strong>Campaign: </strong>{campaign}</p>
      <p><strong>Premium URL: </strong><a target="_blank" href={subdomainUrl}>{subdomain}.muslimpro.com</a></p>
      <p><strong>Premium type: </strong>{type}</p>
      <p><strong>Duration: </strong>{duration}</p>
      <p><strong>Expiry: </strong>{expiry}</p>
      <p>
        <strong>Created by: </strong>
        <a
          target="_blank"
          href={modProfileUrl}
        >
          {
            moderators[created_by] && moderators[created_by].displayName
              ? moderators[created_by].displayName
              : created_by
          }
        </a>
      </p>
      <hr />
      {this.renderRedeemInfo(redeem_datetime, user_id)}
    </div>;
  }

  renderRedeemInfo(redeem_datetime, user_id) {
    if(!user_id) {
      return <p><strong>Redeemed: </strong>No</p>;
    } else {
      const userProfileUrl = `${WEB_APP_URL}/search-user/${user_id}`;
      return <div>
        <p><strong>Redeemed: </strong>Yes</p>
        <p><strong>Redeemed by: </strong><a target="_blank" href={userProfileUrl}>{user_id}</a></p>
        <p><strong>Redeemed at: </strong><DateTime timestamp={redeem_datetime} /></p>
      </div>;
    }
  }

  renderMultiDetail(codeDetail, promoCode, moderators) {
    const {
      expiry,
      created_by,
      created_datetime,
    } = codeDetail;
    const dbURL =
      `${RTDB_DATABASE_URL}${RTDB_DATABASE.MUSLIM_PRO_APP}/${RTDB_DATABASE_NODE.PROMO_CODE_MULTI_USAGE}/${promoCode}`;
    const modProfileUrl = `${WEB_APP_URL}/search-user/${created_by}`;
    return <div>
      <p className="small">Showing result for <a target="_blank" href={dbURL}>{promoCode}</a></p>
      <p><strong>Promo type: </strong>Multi usage</p>
      <p><strong>Expiry: </strong>{expiry}</p>
      <p>
        <strong>Created by: </strong>
        <a
          target="_blank"
          href={modProfileUrl}
        >
          {
            moderators[created_by] && moderators[created_by].displayName
            ? moderators[created_by].displayName
            : created_by
          }
        </a>
      </p>
      <p><strong>Created at: </strong><DateTime timestamp={created_datetime} /></p>
    </div>;
  }

  onPromocodeInputChange(e) {
    this.setState({
      promoCodeInput: e.target.value
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
    window.location = `/search-promo-code/${this.state.promoCodeInput}`;
  }

  renderRecentSearches(promoHistory) {
    return promoHistory.map((item) => {
      const itemUrl = `/search-promo-code/${item}`;
      return <a href={itemUrl}>{item}</a>;
    });
  }

  render() {
    const {
      fetching,
      promoCode,
      codeDetail,
      moderators={},
      promoCodeInput,
    } = this.state;
    const promoHistory = getHistory();
    return (
      <BasicLayout pagePermission="read_codes">
        <Row>
          <Col className="col-md-12 page__title_wrapper">
            <h3>Search promo code</h3>
          </Col>
          <Col className="col-md-8">
            <Row>
              <Col className="col-md-12">
                <form onSubmit={this.onFormSubmit.bind(this)}>
                  <ControlLabel>Enter the promo code</ControlLabel>
                  <FormControl
                    className="promo__search_input"
                    onChange={this.onPromocodeInputChange.bind(this)}
                    value={promoCodeInput}
                  />
                  <Button type="submit" className="btn-primary promo__search_btn">Search</Button>
                </form>
                <hr />
              </Col>
              <Col className="col-md-12">
                {this.renderDetail(codeDetail, fetching, promoCode, moderators)}
              </Col>
            </Row>
          </Col>
          <Col className="col-md-4">
            <strong>Recent searches</strong>
            <div className="promo__code_recent_search">{this.renderRecentSearches(promoHistory)}</div>
          </Col>
        </Row>
      </BasicLayout>
    );
  }
}

export default SearchPromoCode;
