import React,
{
  useReducer,
} from 'react';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  listFileVersionsReducer,
} from './Reducers';
import {
  actionTypes,
} from './actionTypes';
import {
  SettingsFiles,
  SettingsFileOperationTypes,
} from './Constants';
import {
  FetchForm,
} from './common';
import {
  updateSettingsFileHook,
  fetchSettingsFileWithVersionsHook,
} from '../../hooks';
import GCSFileVersionViewer from '../../widgets/gcs-flie-version-viewer';
import {
  shouldShowGcsFileVersionLister,
} from './helper';

const fetchTypes = Object.keys(SettingsFileOperationTypes).map((option) => {
  return {
    key: option,
    value: SettingsFileOperationTypes[option]
  }
});

const settingFiles = Object.keys(SettingsFiles).map((option) => {
  return {
    key: option,
    value: SettingsFiles[option]
  }
});

const InitialState = {
  selectedFile: '',
  selectedFetchType: '',
  showVersionLister: false,
};

const ListSettingsFileVersions = () => {
  const[ state, dispatch ] = useReducer(listFileVersionsReducer, InitialState);
  const {
    selectedFetchType, selectedFile, showVersionLister,
  } = state;

  const [ settingsFileVersionsData, doFetch ] = fetchSettingsFileWithVersionsHook();
  const isFetching = settingsFileVersionsData && settingsFileVersionsData.isFetching;

  const disableFetchButton = !selectedFile || isFetching;
  const showGcsFileVersionLister =
      shouldShowGcsFileVersionLister(settingsFileVersionsData, showVersionLister);

  const onCompleteFetch = operationData => {
    if(!operationData.isError) {
      toast.success(`Fetching file: ${selectedFile} versions Success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  return (
      <BasicLayout pagePermission="readonly">
        <ToastContainer autoClose={2000}/>
          <Row>
            <Col md={12}>
              <h3 className='content_editor_title'>{'List Settings File Versions'}</h3>
            </Col>
          </Row>
        <FetchForm
            dispatch={dispatch}
            fetchTypes={fetchTypes}
            selectedFetchType={selectedFetchType}
            settingFiles={settingFiles}
            selectedFile={selectedFile}
            disableFetchButton={disableFetchButton}
            isFetching={settingsFileVersionsData.isFetching}
            propUpdateActionType={actionTypes.SLF_UPDATE_PROP_VAL}
            fetchActionType={actionTypes.SLF_FETCH_VERSIONS}
            onSubmit={(file) => {
              doFetch({
                fileName: file,
                onOperationComplete: onCompleteFetch,
              })
            }}
        />
        <br/>
        {
          showGcsFileVersionLister ?
              <Row>
                <Col md={12}>
                  <GCSFileVersionViewer
                      fileVersionsList={settingsFileVersionsData.data}
                      fileUpdateHook={updateSettingsFileHook}
                  />
                </Col>
              </Row> : null
        }
      </BasicLayout>
  );
};

export default ListSettingsFileVersions;
