import React,
{
  Component,
} from 'react';
import axios from 'axios';
import {
  Form,
  Button,
  FormGroup,
  FormControl,
} from 'react-bootstrap';
import {
  createLog,
} from '../../services/logs';
const activatePremiumApiUrl = 'https://us-central1-muslim-pro-app.cloudfunctions.net/MPWebAPIActivatePremium';

async function tryActivatePremium({
                                    code,
                                    targettedUserId,
                                  }) {
  return await axios({
    method: 'post',
    url: `${activatePremiumApiUrl}/`,
    headers: {
      'idToken': localStorage.getItem('idToken') || "",
      'uid': localStorage.getItem('uid') || "",
      'ac_targetted_user_id': targettedUserId,
      'Content-Type': 'application/json'
    },
    data: {
      code,
      isAccountCreation: false
    }
  });
}

export default class RedeemPremiumCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      message: "",
      messageClass: ""
    };
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { code } = this.state;
    const { targettedUserId } = this.props;
    console.log(`Calling the MPWebAPIActivatePremiumDebug API to redeem ${code} for user ${targettedUserId}`);
    this.setState({
      loading: true,
      message: 'Trying to redeem the premium code',
      messageClass: "muted"
    });
    try {
      const resp = await tryActivatePremium({code, targettedUserId});
      let msgObj = {
        message: 'Premium code has been redeemed successfully',
        messageClass: "success"
      };
      if(resp.data.result === 'error') {
        msgObj = {
          message: 'Invalid premium code',
          messageClass: "danger"
        };
      }
      createLog('user_redeem_premium_code', {
        code,
        targettedUserId,
        msgObj,
      });
      this.setState({
        loading: false,
        ...msgObj
      });
    } catch (e) {
      this.setState({
        loading: false,
        message: 'Failed to redeem the premium code',
        messageClass: "danger"
      });
    }
  }

  handleFieldChange(e) {
    this.setState({
      code: e.target.value
    });
  }

  rendereMessage({
                   message,
                   messageClass,
                 }) {
    if(message === "") {
      return null;
    } else {
      return <p className={`small text-${messageClass}`}>{message}</p>;
    }
  }

  render() {
    const {
      code,
      message,
      messageClass,
    } = this.state;
    return <div>
      <Form inline onSubmit={this.handleSubmit.bind(this)}>
        <FormGroup>
          <FormControl
            value={code} placeholder="Enter the code here"
            onChange={this.handleFieldChange.bind(this)}
          />
        </FormGroup>
        {' '}
        <FormGroup>
          <Button type="submit" bsStyle="warning">Redeem</Button>
        </FormGroup>
      </Form>
      {this.rendereMessage({message, messageClass})}
    </div>;
  }
}
