import React, { useState, useEffect, useRef } from "react";

const QalboxS3Player = ({fileName, isShort = false}) => {
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    if (fileName) {
        getSignedUrl(fileName, isShort);
    }
  }, [fileName, isShort]);

  const getSignedUrl = async (fileName, isShort) => {
    let functionName = 'qalbox-admin-media-service';
    let file = fileName;
    
    if (isShort) {
        file = fileName.split('s3://qalbox-shorts-artifacts/')[1];
        functionName = 'qalbox-admin-short-service';
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}${functionName}/v1/get/s3-signed-url?file=${file}`
      );

      if (response.status !== 200) {
        throw response.status;
      }

      const responseUrlJson = await response.json();
      console.log(responseUrlJson.url);
      setVideoUrl(responseUrlJson.url);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {videoUrl && (
        <video preload autoplay loop muted width="320" height="240" controls><source src={videoUrl} /></video>
      )}
    </div>
  );
};

export default QalboxS3Player;