import React from 'react';
import BasicLayout from '../../layouts/basiclayout';
import {
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  getConfirmItems,
  onDeleteComplete,
  ContentV2OperationStatus,
  ContentV2ListTableToolBox,
} from './common';
import {
  getDeletableContents,
  getDownloadableContents,
} from './helper';
import {
  confirmAlert,
} from "react-confirm-alert";
import AppModal from '../../widgets/modal';
import exportFromJSON from 'export-from-json'
import {
  MPTable,
} from '../../widgets/mp-table';
import {
  deleteV2ContentsHook,
  listContentsWithPaginationHook,
} from '../../hooks';
import {
  ScaleLoader,
} from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';

const CommonList = ({
                      title,
                      columns,
                      initialFilters = [],
                    }) => {

  const [ state, setState ] = React.useState({
    displayData: [],
    contents: [],
    dataFetchError: null,
    pageCount: 1000,
  });

  const [ lastTableConfig, setLastTableConfig ] = React.useState({});
  const [ pageIndexData, setPageIndexData ] = React.useState({});

  const [ listContentsData, doFetch ] = listContentsWithPaginationHook();
  const [ deleteContentsData, doDelete ] = deleteV2ContentsHook();

  const isFetching = listContentsData && listContentsData.isFetching;
  const isDeleting = deleteContentsData && deleteContentsData.isDeleting;

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.
  const skipPageResetRef = React.useRef(false);

  const fetchIdRef = React.useRef(0);

  const onUpdateTableConfig = React.useCallback(async ({
                                                         tableConfig, lastTableConfig, originalData,
                                                         pageIndexData, setState, state,
                                                       }) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    const onFetchComplete = (data) => {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        setState({
          ...state,
          ...data,
        });
      }
    };

    doFetch({
      tableConfig,
      lastTableConfig,
      originalData,
      pageIndexData,
      skipPageResetRef,
      onOperationComplete: onFetchComplete,
    });
  }, []);

  const onDeleteSelected = (selectedFlatRows) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <AppModal
                show={true}
                showBody={true}
                headingText={`Are you sure want to delete the following contents ?`}
                modalBody={getConfirmItems(selectedFlatRows)}
                modalBodyClassName={'precalcs-diff-merge-view'}
                closeButtonText={'No'}
                okButtonText={'Yes, Delete'}
                handleClose={onClose}
                handleOk={() => {
                  onClose();
                  const deletableContents = getDeletableContents(selectedFlatRows, contents);
                  doDelete({
                    contents: deletableContents,
                    onOperationComplete: onDeleteComplete,
                  });
                }}
            />
        );
      }
    });
  };

  const onDownloadSelected = (selectedFlatRows, exportType) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <AppModal
                show={true}
                showBody={true}
                headingText={`Are you sure want to download the following contents ?`}
                modalBody={getConfirmItems(selectedFlatRows)}
                modalBodyClassName={'precalcs-diff-merge-view'}
                closeButtonText={'No'}
                okButtonText={'Yes, Download'}
                handleClose={onClose}
                handleOk={() => {
                  onClose();
                  const downloadableContents = getDownloadableContents(selectedFlatRows, contents);
                  const fileName = 'content-v2-download';
                  exportFromJSON({ data: downloadableContents, fileName, exportType })
                }}
            />
        );
      }
    });
  };

  const {
    contents,
    pageCount,
    displayData,
    dataFetchError,
  } = state;

  return (
      <LoadingOverlay
          active={isDeleting}
          spinner={<ScaleLoader />}
          text='Deleting the selected contents.'
      >
        <BasicLayout pagePermission="content_editor">
          <ToastContainer autoClose={2000}/>
          <div>
            <Row>
              <Col md={12}>
                <h3 className='content_editor_title'>{title}</h3>
              </Col>
            </Row>
            <MPTable
                originalData={contents}
                displayData={displayData}
                columns={columns}
                loading={isFetching}
                onUpdateTableConfig={onUpdateTableConfig}
                additionalTableConfig={{ setState, state }}
                setLastTableConfig={setLastTableConfig}
                lastTableConfig={lastTableConfig}
                pageCount={pageCount}
                skipPageResetRef={skipPageResetRef}
                setPageIndexData={setPageIndexData}
                pageIndexData={pageIndexData}
                tableError={dataFetchError}
                onDeleteSelected={onDeleteSelected}
                onDownloadSelected={onDownloadSelected}
                TableToolBox={ContentV2ListTableToolBox}
                TableOperationStatus={ContentV2OperationStatus}
                initialFilters={initialFilters}
                paginationList={[50, 100, 150, 200]}
            />
          </div>
        </BasicLayout>
      </LoadingOverlay>
  );
}

export default CommonList;
