import React, {useEffect, useState} from 'react';
import BasicLayout from '../../layouts/basiclayout';
import firebase from 'firebase/app';
import 'firebase/firestore'
import './style.css'
import {AddDailyDeenContentForm} from "./components/addContent";
import {ListDailyDeenContent} from "./components/listContent";
import {ToastContainer} from "react-toastify";
import {ListHasChangedContext} from "./context/listHasChangedContext";

const UpdateContent = () => {
  const [listChanged, setListChanged] = useState(true);

  return (
    <BasicLayout pagePermission="moderate_precalc">
      <ListHasChangedContext.Provider value={[listChanged, setListChanged]}>
        <ToastContainer autoClose={2000} />
        <h3 className='content_editor_title'>{'Daily Deens Content Update'}</h3>
        {/*<AddDailyDeenContentForm/>*/}

        <h3 className='content_editor_title'>{'Daily Deens Content List'}</h3>
        <div  className={'quran-quotes-table-main-reference'}>
          <div className={'daily-deen-table-ref-entry'}>
            <div className={'daily-deen-table-ref-today-entry'}></div>
            <span>Today's Daily Deen</span>
          </div>
          <div className={'daily-deen-table-ref-entry'}>
            <div className={'daily-deen-table-ref-old-entry'}></div>
            <span>Old Daily Deen</span>
          </div>
          <div className={'daily-deen-table-ref-entry'}>
            <div className={'daily-deen-table-ref-default-entry'}></div>
            <span>Default Daily Deen</span>
          </div>
          <div className={'daily-deen-table-ref-entry'}>
            <div className={'daily-deen-table-ref-preview-entry'}></div>
            <span>Preview Daily Deen</span>
          </div>
        </div>
        <ListDailyDeenContent year="2023"/>
      </ListHasChangedContext.Provider>
    </BasicLayout>
  );
};

export default UpdateContent;