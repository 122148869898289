const VIRTUAL_TOUR_TYPES = Object.freeze({
  Hajj: 'Hajj',
});

const SUPPORTED_LANGUAGES = ['en', 'id', 'ms', 'fr', 'tr', 'bn', 'ar', 'ur'];
const SPECIAL_HEADERS_COUNT = 1
const IMAGES_DIRECTORY_PREFIX = 'images/virtual_tours';

const URL_REGEX =
  new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.)" +
    "{1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");

export {
  URL_REGEX,
  VIRTUAL_TOUR_TYPES,
  SUPPORTED_LANGUAGES,
  SPECIAL_HEADERS_COUNT,
  IMAGES_DIRECTORY_PREFIX,
}