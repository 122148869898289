import _ from 'lodash';
import React,
{
  useState,
} from 'react';
import {
  Button,
} from 'react-bootstrap';
import {
  timeUTCtoLocal,
} from '../../utils';
import {
  CopyToClipboard,
} from 'react-copy-to-clipboard';
import {
  confirmAlert,
} from 'react-confirm-alert';
import {
  toast,
} from 'react-toastify';
import AppModal from '../modal';
import ReactJson from 'react-json-view';
import JSONDiffView from '../json-diff-view';
import './style.css';
import {
  getDiffDataFromVersionDiff,
  getOrderedFileVersionsList,
} from './helper';
import {
  OperationStatus,
} from '../common';

const GCSFileVersionViewer = ({
                                fileUpdateHook,
                                fileVersionsList,
                              }) => {
  const [ updateFileUpdateData, setUpdateFileUpdateData ] = useState(null);
  const [ versionDiff, setVersionDiff ] = useState({});
  const [ fileUpdateData, doUpdate ] = fileUpdateHook();

  const getFileData = (data, version_id) => {
    return (
        <ReactJson
            src={data}
            theme={"monokai"}
            name={`Version ${version_id} - Data`}
            displayDataTypes={false}
            displayObjectSize={false}
            collapsed={true}
        />
    );
  };

  const getDiffData = () => {
    const [ left, right ] = getDiffDataFromVersionDiff(versionDiff);
    return (
        <JSONDiffView
            leftSideData={left}
            rightSideData={right}
            messageIfBothSideIdentical={'Both versions are identical.'}
        />
    );
  };

  const onReloadVersions = () => {
    window.location.reload();
  };

  const onShowDiff = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <AppModal
                show={true}
                showBody={true}
                headingText={`Version diff for `}
                closeButtonText={'Close'}
                handleClose={onClose}
                modalBody={getDiffData()}
                modalBodyClassName={'gcs-file-diff-view-main'}
                handleOk={() => {
                  onClose();
                }}
            />
        );
      }
    });
  };

  const onOperationComplete = operationData => {
    if(!operationData.isError) {
      toast.success(`Restoring file Success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onClickRestoreVersion = (fileVersion) => {
    const { version_id } = fileVersion;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <AppModal
                show={true}
                showBody={false}
                headingText={`Are you sure want to restore file version : ${version_id} ?`}
                closeButtonText={'No'}
                okButtonText={`Yes, Restore`}
                handleClose={onClose}
                handleOk={() => {
                  setUpdateFileUpdateData(fileVersion);
                  doUpdate({
                    fileName: fileVersion.name,
                    updatedFile: fileVersion.data,
                    onOperationComplete,
                  });
                  onClose();
                }}
            />
        );
      }
    });
  };

  const onClickShowData = (fileVersion, lastUpdated) => {
    const { data, version_id } = fileVersion;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <AppModal
                show={true}
                showBody={true}
                headingText={`Data for file version : ${version_id} (Last updated : ${lastUpdated})`}
                closeButtonText={'Close'}
                handleClose={onClose}
                modalBody={getFileData(data, version_id)}
                handleOk={() => {
                  onClose();
                }}
            />
        );
      }
    });
  };

  const onToggleVersionDiff = (checked, fileVersion) => {
    const {
      version_id
    } = fileVersion;


    if(_.size(versionDiff) > 1) {
      setVersionDiff({});
    } else {
      versionDiff[version_id] = checked ? {
        side: _.size(versionDiff) === 0 ? 'right' : 'left',
        fileVersion,
      } : null;
      setVersionDiff({ ...versionDiff });
    }
  };

  const orderedFileVersionsList = getOrderedFileVersionsList(fileVersionsList);
  const rows = orderedFileVersionsList.map((fileVersion, index) => {
    const {
      id, version_id, time_created, time_updated, time_deleted, data, valid_json,
    } = fileVersion;

    return (
        <tr key={index + 1}>
          <td className={'gcs-file-row-index'}>
            <div className={'gcs-file-row-index-number'}>{index + 1}</div>
            <span style={{ textAlign: 'center' }}>
                <input
                    className={'content__list_options_bar_input'}
                    type="checkbox"
                    checked={versionDiff[version_id] || false}
                    onChange={(e) => onToggleVersionDiff(e.target.checked, fileVersion)}
                />
              </span>
          </td>
          <td className={'text-center'}>{version_id}</td>
          <td className={`text-center ${valid_json ? 'gcs-file-valid-data' : 'gcs-file-invalid_data'}`}>
            {valid_json ? 'True': 'False'}
          </td>
          <td>
            {timeUTCtoLocal({ utcTimeString: time_created, localFormat: 'YYYY-MMM-DD h:mm: A'})}
          </td>
          <td>
            {timeUTCtoLocal({ utcTimeString: time_updated, localFormat: 'YYYY-MMM-DD h:mm: A' })}
          </td>
          <td>
            {timeUTCtoLocal({ utcTimeString: time_deleted, localFormat: 'YYYY-MMM-DD h:mm: A' })}
          </td>
          <td>
            <div className={'gcs-file-version-list-tools'}>
              <Button
                  onClick={
                    () => onClickShowData(fileVersion,
                      timeUTCtoLocal({
                        utcTimeString: time_updated,
                        localFormat: 'dddd, MMMM Do YYYY, h:mm:ss A',
                      })
                    )}
                  className='margin-all3 btn btn-info btn-xs'
              >
                <span className={'glyphicon glyphicon-zoom-in'} aria-hidden='true'/>
                &nbsp;Show Data
              </Button>
              <Button
                  onClick={() => onClickRestoreVersion(fileVersion)}
                  className='margin-all3 btn btn-warning btn-xs'
                  disabled={index===0}
              >
                <span className={'glyphicon glyphicon-save'} aria-hidden='true'/>
                &nbsp;Restore
              </Button>
              <CopyToClipboard
                  text={JSON.stringify(data)}
                  onCopy={() => {
                    console.log(`Copied id : ${id}, version : ${version_id}`)
                  }}
              >
                <Button className="btn btn-xs btn-dark">
                  &nbsp;<span className="glyphicon glyphicon-duplicate" aria-hidden="true"/>
                </Button>
              </CopyToClipboard>
            </div>
          </td>
        </tr>
    );
  });

  if(!_.isEmpty(fileUpdateData)) {
    if(fileUpdateData.isError && !fileUpdateData.isUpdating) {
      toast.error(`${fileUpdateData.errorMsg}`, {
        position: toast.POSITION.BOTTOM_LEFT
      });

      return (
          <OperationStatus
              operationStatusData={fileUpdateData}
              showActionBtn={true}
              actionBtnText={'Restart'}
              actionBtnAction={onReloadVersions}
          />
      );
    }

    if(!fileUpdateData.isError && !fileUpdateData.isUpdating) {
      return (
          <OperationStatus
              operationStatusData={fileUpdateData}
              showActionBtn={true}
              actionBtnText={'Restart'}
              actionBtnAction={onReloadVersions}
          />
      );
    }

    if(fileUpdateData.isUpdating) {
      return (
          <OperationStatus
              operationInProgressText={
                `Please wait!! Updating the File : ${updateFileUpdateData.name},
               with Version ${updateFileUpdateData.version_id} Data.`
              }
          />
      );
    }
  }

  const diffTools = _.size(versionDiff) === 2 ?
      <div className={'gcs-file-version-diff-tools'}>
        <p className={'gcs-file-version-diff-warning'}>
          {'**At once you only be able to compare two versions.'}
        </p>
        <Button
            onClick={() => onShowDiff()}
            className='margin-all3 btn btn-primary'
        >
          <span className={'glyphicon glyphicon-list-alt'} aria-hidden='true'/>
          &nbsp;Show Diff
        </Button>
      </div> : null;

  return (
      <div className={'content__list_table_wrapper table-responsive'}>
        {diffTools}
        <div className={'gcs-file-versions-table-main'}>
          <table className={'table small table-responsive gcs-file-versions-table'}>
            <thead>
            <tr>
              <th className={'text-center'}>#</th>
              <th className={'text-center'}> Version Id</th>
              <th className={'text-center'}> Valid JSON </th>
              <th> Created Date</th>
              <th> Updated Date</th>
              <th> Deleted Date</th>
              <th/>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
          </table>
        </div>
      </div>
  );
};

export default GCSFileVersionViewer;
