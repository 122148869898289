import React,
{
  Component,
} from 'react';
import './style.css';

class HeaderAlert extends Component {

  render() {
    const { alertMessage, alertType } = this.props;
    if(!alertMessage) {
      return null;
    }

    return (
      <div className="alert__header">
        <div className={`alert alert-${alertType || 'info'} text-center`} role="alert">
          {this.props.alertMessage}
        </div>
      </div>
    );
  }
}

export default HeaderAlert;
