import axios from 'axios';

const getTypeBySKU = (sku) => {
  console.log(`Fired ${sku}`);
  let premiumType = "";
  if(!sku) {
    return premiumType;
  }
  switch (sku) {
    case 'com.bitsmedia.muslimpro.adfree.USA1':
      premiumType = 'lifetime';
      break;
    case 'com.bitsmedia.android.muslimpro.premiumupgrade.usd':
      premiumType = 'lifetime';
      break;
    case 'com.bitsmedia.muslimpro.adfree.IDR1':
      premiumType = 'lifetime';
      break;
    case 'com.bitsmedia.muslimpro.yearly.1':
      premiumType = 'yearly';
      break;
    case 'muslimpro_premium_yearly_1_usd':
      premiumType = 'yearly';
      break;
    case 'com.bitsmedia.muslimpro.monthly.1':
      premiumType = 'monthly';
      break;
    case 'muslimpro_premium_monthly_1_usd':
      premiumType = 'monthly';
      break;
    case 'com.bitsmedia.android.muslimpro.premiumupgrade':
      premiumType = 'lifetime';
      break;
    case 'com.bitsmedia.muslimpro.adfree':
      premiumType = 'lifetime';
      break;
    case '000001016234':
      premiumType = 'lifetime';
      break;
    case 'premium_giveaway':
      premiumType = 'giveaway';
      break;
    case 'com.bitsmedia.muslimpro.yearly':
      premiumType = 'yearly';
      break;
    case 'muslimpro_premium_yearly_1':
      premiumType = 'yearly';
      break;
    case 'com.bitsmedia.muslimpro.monthly':
      premiumType = 'monthly';
      break;
    case 'muslimpro_premium_monthly_1':
      premiumType = 'monthly';
      break;
    case 'muslimpro_premium_monthly_promo_30d':
      premiumType = 'monthly';
      break;
    case 'muslimpro_premium_yearly_promo_30d':
      premiumType = 'yearly';
      break;
    case 'com.bitsmedia.muslimpro.monthly_promo_30d':
      premiumType = 'monthly';
      break;
    case 'com.bitsmedia.muslimpro.yearly_promo_30d':
      premiumType = 'yearly';
      break;
    case 'muslimpro_premium_weekly_celcom_30d':
      premiumType = 'weekly';
      break;
    case 'com.bitsmedia.android.muslimpro.yearly.ramadan.offer':
      premiumType = 'yearly';
      break;
    case 'com.bitsmedia.muslimpro.yearly.ramadan.offer':
      premiumType = 'yearly';
      break;
    case 'com.bitsmedia.muslimpro.monthly.retain.offer':
      premiumType = 'monthly';
      break;
    case 'com.bitsmedia.muslimpro.yearly.retain.offer':
      premiumType = 'yearly';
      break;
    case 'com.bitsmedia.muslimpro.adfree.3years':
      premiumType = 'yearly';
      break;
    case 'muslimpro_qalbox_premium_monthly_scholar':
    case 'muslimpro_qalbox_premium_monthly':
    case 'com.bitsmedia.muslimpro.qalbox.monthly.legacy.upgrade':
    case 'com.bitsmedia.muslimpro.qalbox.monthly.upgrade':
    case 'com.bitsmedia.muslimpro.qalbox.monthly.regular.2':
    case 'com.bitsmedia.muslimpro.qalbox.monthly.regular':
    case 'com.bitsmedia.muslimpro.qalbox.monthly':
      premiumType = 'monthly';
      break;
    case 'muslimpro_qalbox_premium_yearly_scholar':
    case 'muslimpro_qalbox_premium_yearly':
    case 'com.bitsmedia.muslimpro.qalbox.yearly.legacy.upgrade':
    case 'com.bitsmedia.muslimpro.qalbox.yearly.upgrade':
    case 'com.bitsmedia.muslimpro.qalbox.yearly.regular.2':
    case 'com.bitsmedia.muslimpro.qalbox.yearly.regular':
    case 'com.bitsmedia.muslimpro.qalbox.yearly':
      premiumType = 'yearly';
      break;
    default:
      return;
  }

  return premiumType;
}

const COMMUNITY_DB = {
  ar: 'mp-comm-db-4',
  de: 'mp-comm-db-4',
  debug: 'mp-comm-db-4',
  en: 'mp-comm-db-1',
  es: 'mp-comm-db-4',
  fr: 'mp-comm-db-2',
  id: 'mp-comm-db-3',
  it: 'mp-comm-db-4',
  ja: 'mp-comm-db-4',
  ms: 'mp-comm-db-4',
  nl: 'mp-comm-db-4',
  pt: 'mp-comm-db-4',
  ru: 'mp-comm-db-4',
  th: 'mp-comm-db-4',
  tr: 'mp-comm-db-4',
  ur: 'mp-comm-db-4',
  zh: 'mp-comm-db-4',
  az: 'mp-comm-db-4',
  bn: 'mp-comm-db-4',
  da: 'mp-comm-db-4',
  ff: 'mp-comm-db-4',
  ug: 'mp-comm-db-4'
};

const getCommunityDBURL = (language) => {
  return `https://${COMMUNITY_DB[language]}.firebaseio.com`;
};

const contentSupportedLanguages = {
  "all": "All",
  "en": "English",
  "id": "Bahasa Indonesia",
  "ms": "Bahasa Melayu",
  "fr": "French",
  "ar": "Arabic"
};

const contentTypes = {
  "all": "All",
  "article": "Article",
  "video": "Video",
  "image": "Image"
};

const parseQueryString = (query) => {
  let vars = query.split("&");
  let queryString = {};
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    let key = decodeURIComponent(pair[0]);
    let value = decodeURIComponent(pair[1]);
    if (typeof queryString[key] === "undefined") {
      queryString[key] = decodeURIComponent(value);
    } else if (typeof queryString[key] === "string") {
      let arr = [queryString[key], decodeURIComponent(value)];
      queryString[key] = arr;
    } else {
      queryString[key].push(decodeURIComponent(value));
    }
  }
  return queryString;
};

const ecommerceCategories = {
  "womens_clothing_tops": "Womens' Clothing - Tops",
  "womens_clothing_pants": "Womens' Clothing - Pants",
  "womens_clothing_skirts": "Womens' Clothing - Skirts",
  "womens_clothing_dresses": "Womens' Clothing - Dresses",
  "womens_clothing_baju_kurung": "Womens' Clothing - Baju Kurung",
  "womens_clothing_kaftans": "Womens' Clothing - Kaftans",
  "womens_clothing_jubbas": "Womens' Clothing - Jubbas",
  "womens_clothing_hijab": "Womens' Clothing - Hijab",
  "mens_clothing_jubbas": "Mens' Clothing - Jubbas",
  "mens_clothing_baju_kurung": "Mens' Clothing - Baju Kurung",
  "mens_clothing_sarong": "Mens' Clothing - Sarong",
  "mens_clothing_tops": "Mens' Clothing - Tops",
  "baby_and_kids_clothing": "Baby & Kids Clothing",
  "toys": "Toys",
  "prayer_essentials_prayer_beads": "Prayer Essentials - Prayer beads",
  "prayer_essentials_prayer_caps": "Prayer Essentials - Prayer caps",
  "prayer_essentials_womens_prayer_wear": "Prayer Essentials - Women’s prayer wear",
  "prayer_essentials_prayer_mats": "Prayer Essentials - Prayer mats",
  "prayer_essentials_children_prayer_mats": "Prayer Essentials - Children prayer mats",
  "islamic_books_children_books": "Islamic Books - Children books",
  "islamic_books_adults_books": "Islamic Books - Adults books",
  "muslim_pro_gifts": "Muslim Pro Gifts",
  "beauty_and_cosmetics": "Beauty & Cosmetics",
  "home_and_decor": "Home & Decor",
  "personal_care": "Personal care",
  "hajj_and_umrah_men": "Hajj & Umrah - Men",
  "hajj_and_umrah_women": "Hajj & Umrah - Women",
  "hajj_and_umrah_kids": "Hajj & Umrah - Kids",
  "hajj_and_umrah_accessories": "Hajj & Umrah - Accessories"
};

const countries = {
  "ALL": "All countries",
  "SG": "Singapore",
  "MY": "Malaysia",
  "ID": "Indonesia",
  "EU": "All Euro user countries (Might not support in all features)",
  "AD": "Andorra",
  "AE": "United Arab Emirates",
  "AF": "Afghanistan",
  "AG": "Antigua and Barbuda",
  "AI": "Anguilla",
  "AL": "Albania",
  "AM": "Armenia",
  "AO": "Angola",
  "AQ": "Antarctica",
  "AR": "Argentina",
  "AS": "American Samoa",
  "AT": "Austria",
  "AU": "Australia",
  "AW": "Aruba",
  "AX": "Aland Islands",
  "AZ": "Azerbaijan",
  "BA": "Bosnia and Herzegovina",
  "BB": "Barbados",
  "BD": "Bangladesh",
  "BE": "Belgium",
  "BF": "Burkina Faso",
  "BG": "Bulgaria",
  "BH": "Bahrain",
  "BI": "Burundi",
  "BJ": "Benin",
  "BL": "Saint Barthelemy",
  "BM": "Bermuda",
  "BN": "Brunei",
  "BO": "Bolivia",
  "BQ": "Bonaire, Saint Eustatius and Saba ",
  "BR": "Brazil",
  "BS": "Bahamas",
  "BT": "Bhutan",
  "BV": "Bouvet Island",
  "BW": "Botswana",
  "BY": "Belarus",
  "BZ": "Belize",
  "CA": "Canada",
  "CC": "Cocos Islands",
  "CD": "Democratic Republic of the Congo",
  "CF": "Central African Republic",
  "CG": "Republic of the Congo",
  "CH": "Switzerland",
  "CI": "Ivory Coast",
  "CK": "Cook Islands",
  "CL": "Chile",
  "CM": "Cameroon",
  "CN": "China",
  "CO": "Colombia",
  "CR": "Costa Rica",
  "CU": "Cuba",
  "CV": "Cape Verde",
  "CW": "Curacao",
  "CX": "Christmas Island",
  "CY": "Cyprus",
  "CZ": "Czech Republic",
  "DE": "Germany",
  "DJ": "Djibouti",
  "DK": "Denmark",
  "DM": "Dominica",
  "DO": "Dominican Republic",
  "DZ": "Algeria",
  "EC": "Ecuador",
  "EE": "Estonia",
  "EG": "Egypt",
  "EH": "Western Sahara",
  "ER": "Eritrea",
  "ES": "Spain",
  "ET": "Ethiopia",
  "FI": "Finland",
  "FJ": "Fiji",
  "FK": "Falkland Islands",
  "FM": "Micronesia",
  "FO": "Faroe Islands",
  "FR": "France",
  "GA": "Gabon",
  "GB": "United Kingdom",
  "GD": "Grenada",
  "GE": "Georgia",
  "GF": "French Guiana",
  "GG": "Guernsey",
  "GH": "Ghana",
  "GI": "Gibraltar",
  "GL": "Greenland",
  "GM": "Gambia",
  "GN": "Guinea",
  "GP": "Guadeloupe",
  "GQ": "Equatorial Guinea",
  "GR": "Greece",
  "GS": "South Georgia and the South Sandwich Islands",
  "GT": "Guatemala",
  "GU": "Guam",
  "GW": "Guinea-Bissau",
  "GY": "Guyana",
  "HK": "Hong Kong",
  "HM": "Heard Island and McDonald Islands",
  "HN": "Honduras",
  "HR": "Croatia",
  "HT": "Haiti",
  "HU": "Hungary",
  "IE": "Ireland",
  "IL": "Israel",
  "IM": "Isle of Man",
  "IN": "India",
  "IO": "British Indian Ocean Territory",
  "IQ": "Iraq",
  "IR": "Iran",
  "IS": "Iceland",
  "IT": "Italy",
  "JA": "Japan",
  "JE": "Jersey",
  "JM": "Jamaica",
  "JO": "Jordan",
  "JP": "Japan",
  "KE": "Kenya",
  "KG": "Kyrgyzstan",
  "KH": "Cambodia",
  "KI": "Kiribati",
  "KM": "Comoros",
  "KN": "Saint Kitts and Nevis",
  "KO": "South Korea",
  "KP": "North Korea",
  "KR": "South Korea",
  "KW": "Kuwait",
  "KY": "Cayman Islands",
  "KZ": "Kazakhstan",
  "LA": "Laos",
  "LB": "Lebanon",
  "LC": "Saint Lucia",
  "LI": "Liechtenstein",
  "LK": "Sri Lanka",
  "LR": "Liberia",
  "LS": "Lesotho",
  "LT": "Lithuania",
  "LU": "Luxembourg",
  "LV": "Latvia",
  "LY": "Libya",
  "MA": "Morocco",
  "MC": "Monaco",
  "MD": "Moldova",
  "ME": "Montenegro",
  "MF": "Saint Martin",
  "MG": "Madagascar",
  "MH": "Marshall Islands",
  "MK": "Macedonia",
  "ML": "Mali",
  "MM": "Myanmar",
  "MN": "Mongolia",
  "MO": "Macao",
  "MP": "Northern Mariana Islands",
  "MQ": "Martinique",
  "MR": "Mauritania",
  "MS": "Montserrat",
  "MT": "Malta",
  "MU": "Mauritius",
  "MV": "Maldives",
  "MW": "Malawi",
  "MX": "Mexico",
  "MZ": "Mozambique",
  "NA": "Namibia",
  "NC": "New Caledonia",
  "NE": "Niger",
  "NF": "Norfolk Island",
  "NG": "Nigeria",
  "NI": "Nicaragua",
  "NL": "Netherlands",
  "NO": "Norway",
  "NP": "Nepal",
  "NR": "Nauru",
  "NU": "Niue",
  "NZ": "New Zealand",
  "OM": "Oman",
  "PA": "Panama",
  "PE": "Peru",
  "PF": "French Polynesia",
  "PG": "Papua New Guinea",
  "PH": "Philippines",
  "PK": "Pakistan",
  "PL": "Poland",
  "PM": "Saint Pierre and Miquelon",
  "PN": "Pitcairn",
  "PR": "Puerto Rico",
  "PS": "Palestinian Territory",
  "PT": "Portugal",
  "PW": "Palau",
  "PY": "Paraguay",
  "QA": "Qatar",
  "RE": "Reunion",
  "RO": "Romania",
  "RS": "Serbia",
  "RU": "Russia",
  "RW": "Rwanda",
  "SA": "Saudi Arabia",
  "SB": "Solomon Islands",
  "SC": "Seychelles",
  "SD": "Sudan",
  "SE": "Sweden",
  "SH": "Saint Helena",
  "SI": "Slovenia",
  "SJ": "Svalbard and Jan Mayen",
  "SK": "Slovakia",
  "SL": "Sierra Leone",
  "SM": "San Marino",
  "SN": "Senegal",
  "SO": "Somalia",
  "SR": "Suriname",
  "SS": "South Sudan",
  "ST": "Sao Tome and Principe",
  "SV": "El Salvador",
  "SX": "Sint Maarten",
  "SY": "Syria",
  "SZ": "Swaziland",
  "TC": "Turks and Caicos Islands",
  "TD": "Chad",
  "TF": "French Southern Territories",
  "TG": "Togo",
  "TH": "Thailand",
  "TJ": "Tajikistan",
  "TK": "Tokelau",
  "TL": "East Timor",
  "TM": "Turkmenistan",
  "TN": "Tunisia",
  "TO": "Tonga",
  "TR": "Turkey",
  "TT": "Trinidad and Tobago",
  "TV": "Tuvalu",
  "TW": "Taiwan",
  "TZ": "Tanzania",
  "UA": "Ukraine",
  "UG": "Uganda",
  "UM": "United States Minor Outlying Islands",
  "US": "United States",
  "UY": "Uruguay",
  "UZ": "Uzbekistan",
  "VA": "Vatican",
  "VC": "Saint Vincent and the Grenadines",
  "VE": "Venezuela",
  "VG": "British Virgin Islands",
  "VI": "U.S. Virgin Islands",
  "VN": "Vietnam",
  "VU": "Vanuatu",
  "WF": "Wallis and Futuna",
  "WS": "Samoa",
  "XK": "Kosovo",
  "YE": "Yemen",
  "YT": "Mayotte",
  "ZA": "South Africa",
  "ZM": "Zambia",
  "ZW": "Zimbabwe"
};

const firebaseApiCaller = (url, data) => {
  return axios({
    method: 'post',
    url: `${url}/`,
    headers: {
      'idToken': localStorage.getItem('idToken') || "",
      'uid': localStorage.getItem('uid') || "",
      'Content-Type': 'application/json'
    },
    data
  });
};

export {
  getTypeBySKU,
  getCommunityDBURL,
  contentSupportedLanguages,
  contentTypes,
  parseQueryString,
  ecommerceCategories,
  firebaseApiCaller,
  countries,
};
