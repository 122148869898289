import _ from "lodash";
import React, { useEffect, useState } from "react";
import { convertArrayToSelectList, getCountryCodesAsLabelValuePair } from "../helper";
import QalboxMultiSelectDropdown from "../media/helperComponents/qalboxMultiSelect";
import QalboxBrandWebsite from "./helperComponents/brandWebsite";
import { QalboxTextInput } from "../helperComponents/qalboxTextInput";
import { QalboxInputWrapper } from "../helperComponents/qalboxInputWrapperComponent";
import QalboxBrandAdUnitsComponent from "./helperComponents/brandAdUnitsComponent";
import QalboxURLInputV1 from "../media/helperComponents/qalboxURLInputV1";
import BrandImageBox from "./helperComponents/brandImageBox";
import QalboxImageUploader from "../media/helperComponents/qalboxImageUploader";
import QalboxJSONEditor from "./helperComponents/QalboxJSONEditor";
import { useCreateBrand } from "./hooks/useCreateBrand";
import { useUpdateBrand } from "./hooks/useUpdateBrand";

const initialBrandObject = {
  name: "",
  media_details_branded_logo: "",
  title: "",
  website: null,
  overlay_banner_ad_text: "",
  brand_color: "",
  branded_partner_page: "",
  active: false,
  countries_allowed: [],
  countries_disallowed: [],
  order_number: "",
  rail_branded_logo: "",
  landing_branded_logo: "",
  branded_partner_featured_video: [],
  branded_partner_featured_image: null,
  branded_partner_ad_units: [],
  custom_configs: null
};

export const BrandModal = ({ data, handleUpdates }) => {
  const [brand, setBrand] = useState(initialBrandObject);
  const [createBrandHook] = useCreateBrand();
  const [updateBrandHook] = useUpdateBrand();

  const handleInputTextChange = (e) => {
    let { name, value } = e.target;

    setBrand((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputImageChange = (e) => {
    let { name, value } = e.target;

    const nameArr = name.split(".");

    if (nameArr && nameArr.length > 1) {
      const prefix = nameArr[0];
      const suffix = nameArr[1];

      let newImageObject = {};

      const prevImageObject = _.get(brand, prefix, null);

      if (brand && prevImageObject) {
        newImageObject = { ...prevImageObject, [suffix]: value };
      } else {
        newImageObject = { [suffix]: value };
      }

      setBrand((prevState) => ({
        ...prevState,
        [prefix]: newImageObject,
      }));
    } else {
      setBrand((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleMultiSelect = (name, array) => {
    const value = array.map((x) => x.value);

    setBrand((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkInput = (brand) => {
    if (brand && (!isNaN(brand.order_number) || brand.order_number === '')) {
      return true;
    } else {
      alert('Order Number supports Empty or Numeric values !!!');
      return false;
    }
  };

  const handleOnSave = () => {
    if (!checkInput(brand)) return;
    createBrandHook(brand, handleUpdates);
  };

  const handleOnUpdate = async () => {
    if (!checkInput(brand)) return;
    updateBrandHook(brand, handleUpdates);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setBrand((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const setAdUnits = (adUnits) => {
    setBrand((prevState) => ({
      ...prevState,
      branded_partner_ad_units: adUnits,
    }));
  };

  const onBrandWebsiteChange = (value) => {
    setBrand((prevState) => ({
      ...prevState,
      website: value,
    }));
  };

  const handleURLChange = (name, urlArray) => {
    setBrand((prevState) => ({
      ...prevState,
      [name]: urlArray,
    }));
  };

  const handleJSONInput = (jsObject) => {
    setBrand((prevState) => ({
      ...prevState,
      custom_configs: jsObject,
    }));
  };

  useEffect(() => {
    if (data) {
      setBrand(data);
    }
  }, [data]);

  return (
    <div>
      <QalboxTextInput
        label="Name"
        name="name"
        value={brand.name}
        onChange={handleInputTextChange}
      />
      <QalboxTextInput
        label="Title"
        name="title"
        value={brand.title}
        onChange={handleInputTextChange}
      />
      <QalboxInputWrapper
        label="Media Details Logo"
        component={
          <QalboxImageUploader
            name='media_details_branded_logo'
            value={brand.media_details_branded_logo ? brand.media_details_branded_logo : ""}
            handleInputTextChange={handleInputTextChange}
          />
        }
      />
      <QalboxInputWrapper
        label="Website"
        component={
          <QalboxBrandWebsite
            website={brand.website}
            onChange={onBrandWebsiteChange}
          />
        }
      />
      <div style={{ display: "inline-flex" }}>
        <QalboxTextInput
          style={{ marginRight: "20px" }}
          label={"Order Number"}
          name="order_number"
          value={brand.order_number}
          onChange={handleInputTextChange}
        />
      </div>
      <QalboxInputWrapper
        label="Is Active"
        component={
          <input
            type="checkbox"
            name="active"
            checked={brand.active}
            onChange={handleCheckboxChange}
            style={{ transform: "scale(1.5)", marginLeft: "10px" }}
          />
        }
      />
      <QalboxTextInput
        label="Overlay Banner Ad Text"
        name="overlay_banner_ad_text"
        value={brand.overlay_banner_ad_text}
        onChange={handleInputTextChange}
      />
      <QalboxTextInput
        label="Brand Color"
        name="brand_color"
        value={brand.brand_color}
        onChange={handleInputTextChange}
      />
      <QalboxTextInput
        label="Branded Partner Page"
        name="branded_partner_page"
        value={brand.branded_partner_page}
        onChange={handleInputTextChange}
      />
      <QalboxInputWrapper
        label="Rail Branded Logo"
        component={
          <QalboxImageUploader
            name='rail_branded_logo'
            value={brand.rail_branded_logo ? brand.rail_branded_logo : ""}
            handleInputTextChange={handleInputTextChange}
          />
        }
      />
      <QalboxInputWrapper
        label="Landing Branded Logo"
        component={
          <QalboxImageUploader
            name='landing_branded_logo'
            value={brand.landing_branded_logo ? brand.landing_branded_logo : ""}
            handleInputTextChange={handleInputTextChange}
          />
        }
      />
      <QalboxInputWrapper
        label="Branded Featured Video"
        component={
          <QalboxURLInputV1
            componentName={"branded_partner_featured_video"}
            urlArray={
              brand.branded_partner_featured_video
                ? brand.branded_partner_featured_video
                : []
            }
            onChange={handleURLChange}
          />
        }
      />
      <QalboxInputWrapper
        label="Branded Featured Image"
        component={
          <BrandImageBox
            objectIdentifier={'branded_partner_featured_image'}
            imageObject={brand.branded_partner_featured_image}
            handleInputTextChange={handleInputImageChange}
          />
        }
      />
      <QalboxInputWrapper
        label="Countries Allowed"
        component={
          <QalboxMultiSelectDropdown
            name={"countries_allowed"}
            onChange={handleMultiSelect}
            selectedOptions={convertArrayToSelectList(brand.countries_allowed)}
            options={getCountryCodesAsLabelValuePair()}
          />
        }
      />
      <QalboxInputWrapper
        label="Countries Disallowed"
        component={
          <QalboxMultiSelectDropdown
            name={"countries_disallowed"}
            onChange={handleMultiSelect}
            selectedOptions={convertArrayToSelectList(
              brand.countries_disallowed
            )}
            options={getCountryCodesAsLabelValuePair()}
          />
        }
      />
      <QalboxInputWrapper
        label="Ad Units"
        component={
          <QalboxBrandAdUnitsComponent adUnits={brand.branded_partner_ad_units} setAdUnits={setAdUnits}/>
        }
      />
      <QalboxInputWrapper
        label="Custom Configs"
        component={
          <QalboxJSONEditor jsonObject={brand.custom_configs} onChange={handleJSONInput} />
        }
      />
      <QalboxInputWrapper
        style={{ marginTop: "5px" }}
        component={
          data ? (
            <button className="pagination-button" onClick={handleOnUpdate}>
              Update
            </button>
          ) : (
            <button className="pagination-button" onClick={handleOnSave}>
              Save
            </button>
          )
        }
      />
    </div>
  );
};