import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  settingsUpdateRawFile,
} from '../../services/mp-service-v1-api-service';
import {
  getSettingFileUpdateConfig,
} from '../../pages/settings/helper';

const updateSettingsFile = () => {
  const [ settingsFileUpdateData, setSettingsFileUpdateData ] = useState({ });
  const [ updateData, setUpdateData] = useState(null);
  const {
    fileName,
    updatedFile,
    onOperationComplete,
  } = updateData || {};

  const updateSettingsRawFile = async () => {
    try {
      console.log(`Updating file: ${fileName}...`);
      setSettingsFileUpdateData({
        isUpdating: true,
        isError: false,
        updatedFileName: fileName,
      });
      const updateRawFileApiResp =  await settingsUpdateRawFile({
        fileName,
        updatedData: updatedFile,
        updateConfig: getSettingFileUpdateConfig(fileName),
      });

      const responseData = updateRawFileApiResp.data;
      let errorMsg = null;
      if(responseData.success) {
        console.log(`Updating file: ${fileName} Success!!`);
      } else {
        console.log(`Updating file: ${fileName} Failed!!`);
        errorMsg = `Error occurred while updating file: ${fileName}.`;
      }

      setSettingsFileUpdateData({
        data: responseData.data,
        isUpdating: false,
        isError: !responseData.success,
        errorMsg,
        updatedFileName: fileName,
      });
      onOperationComplete({
        isError: !responseData.success,
        errorMsg,
        updatedFileData: updatedFile,
      });
    } catch (err) {
      console.log(`Error while updating file: ${fileName}. Error : ${err}`);
      const errorMsg = (err.response && err.response.status === 404) ?
          `File: ${fileName} not found!` : `Error occurred while updating file: ${fileName}.`;
      setSettingsFileUpdateData({
        data: null,
        isUpdating: false,
        isError: true,
        errorMsg,
        updatedFileName: fileName,
      });
      onOperationComplete({
        isError: true,
        errorMsg,
        updatedFileData: updatedFile,
      });
    }
  };

  useEffect( () => {
    if(!_.isEmpty(updateData)) {
      updateSettingsRawFile();
    }
  }, [ updateData ]);

  return [
    settingsFileUpdateData,
    setUpdateData,
  ];
};

export default updateSettingsFile;
