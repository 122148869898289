import React,
{
  useState,
} from 'react';
import {
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  MPGCSUploadImages,
} from '../../widgets/upload-images';
import {
  ImageUploadInfoPanel,
} from './common';
import {
  getUploadableFiles,
  getSupportedLanguages,
  getSupportedImageTypes,
  getSupportedImageSizes,
} from './helper';
import {
  MPSelect,
} from '../../widgets/common';

const UploadImages = () => {
  const [ language, setLanguage ] = useState('');
  const [ imageType, setImageType ] = useState('');
  const [ imageSize, setImageSize ] = useState('');
  const disableUploadButton = !language || !imageType || !imageSize;

  return (
    <BasicLayout pagePermission="moderate_precalc">
      <ToastContainer autoClose={2000}/>
      <Row>
        <Col md={12}>
          <h3 className='content_editor_title'>{'Upload Images'}</h3>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <div className="form-group">
            <label
              style={{
                'marginRight': '5px',
              }}
            >
              {'Language : '}
            </label>
            <MPSelect
              items={getSupportedLanguages()}
              selectedItem={language}
              onChangeSelect={setLanguage}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="form-group">
            <label
              style={{
                'marginRight': '5px',
              }}
            >
              {'Type : '}
            </label>
            <MPSelect
              items={getSupportedImageTypes()}
              selectedItem={imageType}
              onChangeSelect={setImageType}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="form-group">
            <label
              style={{
                'marginRight': '5px',
              }}
            >
              {'Size : '}
            </label>
            <MPSelect
              items={getSupportedImageSizes()}
              selectedItem={imageSize}
              onChangeSelect={setImageSize}
            />
          </div>
        </Col>
      </Row>
      <MPGCSUploadImages
        InfoPanel={ImageUploadInfoPanel}
        getUploadableFiles={
          files => getUploadableFiles({
            files,
            selectedLanguage: language,
            selectedImageType: imageType,
            selectedImageSize: imageSize,
          })
        }
        disableUploadButton={disableUploadButton}
        onUploadSuccess={() => setLanguage('')}
        acceptTypes={'image/jpg , image/jpeg, image/png'}
        enableOptimizeImages={false}
      />
    </BasicLayout>
  );
};

export default UploadImages;
