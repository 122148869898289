import React,
{
  Component,
} from 'react';

class PageWrapper extends Component {
  render() {
    const {
      show,
    } = this.props;
    if(show) {
      return <span>{this.props.children}</span>;
    } else {
      return <p className="text-danger developer__options"><span className="glyphicon glyphicon-exclamation-sign"></span> Access denied! You are not permitted to view this page.</p>;
    }
  }
}

export default PageWrapper;
