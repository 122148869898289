import _ from 'lodash';
import {
  fileNameRegex,
} from './Constants';
import {
  actionTypes,
} from './actionTypes';
import {
  getPrecalcListByFileName,
  getUpdatedAndReplacedPrecalcsList,
} from './helper';

const rawUpdateReducer = (state, { type , payload }) => {
  const {
    fileName,
    validFileName,
    selectedCountry,
    updatedPrecalcList,
    existingPrecalcsList,
  } = state;

  switch (type) {
    case actionTypes.RJ_UPDATE_FILE_NAME:
      const isValidFileName = payload ? fileNameRegex.test(payload) : true;
      if(isValidFileName) {

        let selectedCountryJSON = existingPrecalcsList && existingPrecalcsList[selectedCountry];
        if(updatedPrecalcList) {
          selectedCountryJSON = updatedPrecalcList;
        }
        const countryPrecalcsListJSON = getPrecalcListByFileName(selectedCountryJSON, payload);

        return {
          ...state,
          fileName: payload,
          validFileName: isValidFileName,
          selectedCountryPrecalcsListJSON: countryPrecalcsListJSON,
        };
      }

      return {
        ...state,
        fileName: payload,
        validFileName: isValidFileName,
      };

    case actionTypes.RJ_UPDATE_JSON_VALUE:
      const updatedPrecalcsListValues = payload['updated_src'];

      if(fileName && validFileName) {
        let selectedCountryJSON = existingPrecalcsList && existingPrecalcsList[selectedCountry];
        if(updatedPrecalcList) {
          selectedCountryJSON = updatedPrecalcList;
        }

        const updatedAndReplacedPrecalcsList =
            getUpdatedAndReplacedPrecalcsList(selectedCountryJSON,
                updatedPrecalcsListValues, fileName);

        return {
          ...state,
          updatedPrecalcList: updatedAndReplacedPrecalcsList,
        };
      } else {
        return {
          ...state,
          updatedPrecalcList: updatedPrecalcsListValues,
        };
      }

    case actionTypes.RJ_RESET_ALL:
      const selectedCountryJSON = existingPrecalcsList && existingPrecalcsList[selectedCountry];

      return {
        ...state,
        fileName: '',
        updatedPrecalcList: selectedCountryJSON,
        selectedCountryPrecalcsListJSON: [...selectedCountryJSON],
      };

    case actionTypes.RJ_RESET_CURRENT_FILE:
      const selectedOlCountryJSON = updatedPrecalcList || (existingPrecalcsList && existingPrecalcsList[selectedCountry]);
      const initialCountryJSON = existingPrecalcsList && existingPrecalcsList[selectedCountry];
      const countryPrecalcsListJSON = getPrecalcListByFileName(initialCountryJSON, payload);
      const updatedAndReplacedPrecalcsList =
          getUpdatedAndReplacedPrecalcsList(selectedOlCountryJSON, countryPrecalcsListJSON, payload);

      return {
        ...state,
        updatedPrecalcList: updatedAndReplacedPrecalcsList,
        selectedCountryPrecalcsListJSON: [...countryPrecalcsListJSON],
      };

    case actionTypes.RJ_CONFIRM_PRECALC_LIST_UPDATE:
      return {
        ...state,
        shouldUpdatePrecalcList: true,
      };

    default:
      return state;
  }
};

const listMergeReducer = (state, { type , payload }) => {
  const {
    currentListId,
    precalcListIds,
    mergedCountryJSONById,
    diffableCountryOldJSONById,
    diffableCountryNewJSONById,
    diffableSelectedItemNewJSONById,
    diffableSelectedItemOldJSONById,
  } = state;

  switch (type) {
    case actionTypes.LM_UPDATE_PRECALC_LIST_INDEX:
    {
      let {
        indexValue,
      } = payload;

      if(indexValue < 0 || indexValue >= precalcListIds.length) {
        indexValue = 0;
      }

      return {
        ...state,
        selectedPrecalcListIndex: indexValue,
        currentListId: precalcListIds[indexValue],
      };
    }

    case actionTypes.LM_UPDATE_CURRENT_MERGE:
    {
      const {
        value,
        section,
      } = payload;
      const currentIdMergeList = mergedCountryJSONById[currentListId] || [];
      currentIdMergeList.push(value['existing_value']);
      mergedCountryJSONById[currentListId] = currentIdMergeList;
      if(section === 'left') {
        diffableSelectedItemOldJSONById[currentListId] = _.values(value['updated_src']);
      } else {
        diffableSelectedItemNewJSONById[currentListId] = _.values(value['updated_src']);
      }

      return {
        ...state,
        mergedCountryJSONById: {...mergedCountryJSONById},
      };
    }

    case actionTypes.LM_RESET_MERGE:

      return {
        ...state,
        stateResetted: true,
        mergedCountryJSONById: {},
        diffableSelectedItemOldJSONById: {...diffableCountryOldJSONById},
        diffableSelectedItemNewJSONById: {...diffableCountryNewJSONById},
      };

    case actionTypes.LM_RESET_CURRENT_MERGE:
    {
      mergedCountryJSONById[currentListId] = [];

      diffableSelectedItemOldJSONById[currentListId] = [...diffableCountryOldJSONById[currentListId]];
      diffableSelectedItemNewJSONById[currentListId] = [...diffableCountryNewJSONById[currentListId]];

      return {
        ...state,
        mergedCountryJSONById: {...mergedCountryJSONById},
        diffableSelectedItemOldJSONById: diffableSelectedItemOldJSONById,
        diffableSelectedItemNewJSONById: diffableSelectedItemNewJSONById,
      };
    }

    case actionTypes.LM_CONFIRM_PRECALC_LIST_UPDATE:
      return {
        ...state,
        updatedPrecalcList: payload,
      };

    default:
      return state;
  }
};

export {
  rawUpdateReducer,
  listMergeReducer,
};
