import React,
{
  Component,
} from 'react';
import Header from '../../widgets/header';
import {
  Row,
  Col,
} from 'react-bootstrap';
import './style.css';

class LoginLayout extends Component {
  render() {
    return (
      <div>
        <Header page={this.props.page}/>
        <Row>
          <Col className="loginlayout__content" mdOffset={4} md={4}>
            {this.props.children}
          </Col>
        </Row>
      </div>
    );
  }
}

export default LoginLayout;
