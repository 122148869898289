import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  updatePrecalcFile,
} from '../../services/mp-service-v1-api-service';

const updatePrecalcFIle = () => {
  const [ precalcFileUpdateData, setPrecalcFileUpdateData ] = useState(null);
  const [ updateData, setUpdateData] = useState(null);
  const {
    fileName,
    updatedFile,
    onOperationComplete,
  } = updateData || {};

  const updatePrecalcFileData = async () => {
    try {
      console.log(`Updating Precalc File : ${fileName} ...`);
      setPrecalcFileUpdateData({
        isUpdating: true,
        isError: false,
        updatedFileName: fileName,
      });

      const precalcFileUpdateApiResp =  await updatePrecalcFile({
        fileName,
        data: updatedFile,
      });

      const responseData = precalcFileUpdateApiResp.data;
      let errorMsg = null;
      if(responseData.success) {
        console.log(`Precalc File ${fileName} Update Success!!`);
      } else {
        console.log(`Precalc File ${fileName} Update Failed!!`);
        errorMsg = `Error occurred while updating file: ${fileName}.`;
      }

      setPrecalcFileUpdateData({
        data: responseData.data,
        isUpdating: false,
        isError: !responseData.success,
        errorMsg,
        updatedFileName: fileName,
      });
      onOperationComplete({
        isError: !responseData.success,
        errorMsg,
      });
    } catch (err) {
      console.log(`Error while updating file: ${fileName}. Error : ${err}`);
      const errorMsg = (err.response && err.response.status === 404) ?
          `File: ${fileName} not found!` : `Error occurred while updating file: ${fileName}.`;
      setPrecalcFileUpdateData({
        data: null,
        isUpdating: false,
        isError: true,
        errorMsg,
        updatedFileName: fileName,
      });
      onOperationComplete({
        isError: true,
        errorMsg,
      });
    }
  };

  useEffect( () => {
    if(!_.isEmpty(updateData)) {
      updatePrecalcFileData();
    }
  }, [ updateData ]);

  return [
    precalcFileUpdateData,
    setUpdateData,
  ];
};

export default updatePrecalcFIle;
