import React,
{
  Fragment,
  useReducer,
} from 'react';
import ReactJson from 'react-json-view';
import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import {
  actionTypes,
} from './actionTypes';
import {
  getPrecalcsListTitleForRawUpdate,
} from './helper';
import {
  COUNTRIES,
} from './Constants';
import {
  rawUpdateReducer,
} from './Reducers';
import {
  confirmAlert,
} from "react-confirm-alert";
import AppModal from '../../widgets/modal';
import {
  updatePrecalcListHook,
} from '../../hooks';
import {
  ScaleLoader,
} from 'react-spinners';

const invalidFileNameErrorMsg = 'File name is invalid. It should be similar to : MY0000003';

const getUpdatedPrecalcsInfo = (changedPreclcsListJSON) => {
  return (
      <ReactJson
          src={changedPreclcsListJSON}
          theme={'monokai'}
          name={'Updated Changes'}
          displayDataTypes={false}
          displayObjectSize={false}
      />
  );
};

const InitialState = {
  fileName: '',
  validFileName: true,
  shouldUpdatePrecalcList: false,
  updatedPrecalcList: null,
  updatedPrecalcs: {},
};

const PrecalcListRawUpdater = ({
                                 selectedCountry,
                                 existingPrecalcsList,
                               }) => {

  const [ state, dispatch ] = useReducer(rawUpdateReducer, {
    ...InitialState,
    existingPrecalcsList,
    selectedCountry,
    selectedCountryPrecalcsListJSON: existingPrecalcsList[selectedCountry],
  });
  const {
    fileName,
    validFileName,
    updatedPrecalcList,
    shouldUpdatePrecalcList,
    selectedCountryPrecalcsListJSON,
  } = state;

  const [ precalcListUpdate ] =
      updatePrecalcListHook({
        selectedCountry,
        updatedPrecalcList,
        shouldUpdatePrecalcList,
      });
  const precalcsListTitle = getPrecalcsListTitleForRawUpdate(fileName, selectedCountry);

  if(precalcListUpdate) {
    setTimeout(()=> {
      window.location.reload();
    }, 1500);
  }

  const confirmPrecalcsListUpdate = () => dispatch({
    type: actionTypes.RJ_CONFIRM_PRECALC_LIST_UPDATE,
  });

  const updatePrecalcList = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <AppModal
                show={true}
                showBody={true}
                headingText={`Are you sure want to update the Precalcs List for ${COUNTRIES[selectedCountry]} - [${selectedCountry}]:`}
                modalBody={getUpdatedPrecalcsInfo(updatedPrecalcList)}
                modalBodyClassName={'precalcs-diff-merge-view'}
                closeButtonText={'No'}
                okButtonText={'Yes, Update'}
                handleClose={onClose}
                handleOk={() => {
                  confirmPrecalcsListUpdate();
                  onClose();
                }}
            />
        );
      }
    });
  };

  if(precalcListUpdate) {
    return (
        <Row className={'precalc-list-manual-update'}>
          <Col md={12} className={'text-center'}>
            <h3 className={`precalc-list-update-operation-title-${precalcListUpdate.status ? 'success' : 'error'}`}>
              {precalcListUpdate.message}
            </h3>
            {
              !precalcListUpdate.status ?
                  <Fragment>
                    <p className={'precalc-list-update-operation-info'}>
                      {'Something went wrong while updating the precalcs list.!'}
                    </p>
                  </Fragment> : null
            }
          </Col>
        </Row>
    );
  }

  if(shouldUpdatePrecalcList && !precalcListUpdate) {
    return (
        <Row className={'precalc-list-manual-update'}>
          <Col md={12} className={'text-center'}>
            <h6 className={'precalc-list-update-operation-title'}>
              {'Please wait!! Updating the Precalc List.'}
            </h6>
            <ScaleLoader
                color={'#097233'}
            />
          </Col>
        </Row>
    );
  }

  return (
      <div className={'update-row-file-main'}>
        <Row>
          <Col md={12}>
            <p className={'update-differ-paste-header'}>
              {`Precalcs List JSON for ${COUNTRIES[selectedCountry]} - [${selectedCountry}]:`}
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className={'precalc-update-raw-tool-bar'}>
            <div className="form-group">
              <label style={{ 'marginRight': '5px' }}>
                {'File Name (Optional) : '}
              </label>
              <input value={fileName}
                     required
                     onChange={
                       (e) => dispatch({
                         type: actionTypes.RJ_UPDATE_FILE_NAME,
                         payload: e.target.value,
                       })
                     }
                     type="text"
                     className="form-control"
                     placeholder="Sheet Number"
              />
              {
                validFileName ?
                    null :
                    <div className={'invalid-file-name-error'}>
                      {invalidFileNameErrorMsg}
                    </div>
              }
            </div>
            <div className={'precalc-update-raw-tool-bar'}>
              <div className={'precalc-update-raw-reset-buttons'}>
                {
                  updatedPrecalcList ?
                      <Button
                          onClick={() => dispatch({
                            type: actionTypes.RJ_RESET_ALL,
                          })
                          }
                          disabled={!updatedPrecalcList}
                          className="btn-primary precalc-update-raw-reset-btn"
                      >
                        {`Reset All - ${selectedCountry}`}
                      </Button> : null
                }
                {
                  fileName && validFileName ?
                      <Button
                          onClick={() => dispatch({
                              type: actionTypes.RJ_RESET_CURRENT_FILE,
                              payload: fileName,
                            })
                          }
                          disabled={!updatedPrecalcList}
                          className="btn-primary precalc-update-raw-reset-btn"
                      >
                        {`Reset - ${fileName}`}
                      </Button> : null
                }
                {
                  updatedPrecalcList ?
                      <Button
                          onClick={() => updatePrecalcList()}
                          disabled={!updatedPrecalcList}
                          className="btn-primary precalc-update-raw-reset-btn"
                      >
                        {`Update Precalcs List - ${selectedCountry}`}
                      </Button> : null
                }
              </div>
            </div>
          </Col>
        </Row>
        <Row className={'precalc-update-raw-json-main'}>
          <Col md={12}
               className={'precalc-update-raw-json-view precalcs-diff-merge-view'}
          >
            <ReactJson
                src={selectedCountryPrecalcsListJSON}
                theme={"monokai"}
                name={precalcsListTitle}
                displayDataTypes={true}
                displayObjectSize={true}
                collapsed={!fileName}
                onEdit={(updatedValues) => dispatch({
                      type: actionTypes.RJ_UPDATE_JSON_VALUE,
                      payload: updatedValues,
                    })
                }
                onAdd={(updatedValues) => dispatch({
                      type: actionTypes.RJ_UPDATE_JSON_VALUE,
                      payload: updatedValues,
                    })
                }
                onDelete={(updatedValues) => dispatch({
                    type: actionTypes.RJ_UPDATE_JSON_VALUE,
                    payload: updatedValues,
                  })
                }
            />
          </Col>
        </Row>
      </div>
  );
};

export default PrecalcListRawUpdater;
