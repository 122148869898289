import _ from 'lodash';
import React from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import ReactJson from 'react-json-view';
import {
  getSubOptions,
  getFilteredUserData,
} from './helper';
import './style.css';
import SubOption from './subOption';

const OptionSection = ({
                         userData,
                         mainOption,
                         onClickOption,
                         mainOptionText,
                       }) => {

    const filteredUserData = getFilteredUserData({
      userData,
      mainOption,
    });
    const showJsonStructure = !_.isEmpty(filteredUserData);
    const subOperations = getSubOptions(mainOption);
    const developerSubOptions = _.map(subOperations, (val, key) => {
        return (
            <SubOption
                key={key}
                subOption={key}
                subOptionText={val}
                onClickSubOption={({ subOption }) => onClickOption({
                    subOption,
                    mainOption,
                    userData,
                })}
            />
        );
    });

    return (
        <>
            <Row>
                <Col>
                    <h5 className={'dev-opt-section-title'}>
                        {mainOptionText}
                    </h5>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <form className={'form-inline dev-sub-opt-form'}>
                        {developerSubOptions}
                    </form>
                </Col>
                {
                    showJsonStructure &&
                    <Col md={6}>
                        <ReactJson
                            src={filteredUserData}
                            theme={'twilight'}
                            name={false}
                            collapsed={true}
                            displayDataTypes={true}
                            displayObjectSize={true}
                        />
                    </Col>
                }
            </Row>
        </>
    );
};

export default OptionSection;
