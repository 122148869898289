import React,
{
  Component,
} from 'react';
import {
  Row,
  Col,
  Panel,
  Alert,
  Button,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap';
import moment from 'moment';
import BasicLayout from '../../layouts/basiclayout';
import {
  countries,
} from '../../services/utils';
import {
  Icon,
} from 'react-fa';
import './style.css';
import {
  HIJRI_MONTH_NAMES,
} from './Constants';
import {
  hijriGetRawJSON,
} from '../../services/api-v1-services/other-services';

class Hijri extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hijriJSON: {},
      fetching: true
    };
  }
  async componentWillMount() {
    await this.fetchData();
  }

  async fetchData() {
    this.setState({
      fetching: true
    });
    const resp = await hijriGetRawJSON();
    const respData = resp.data;
    if(respData.data && respData.success) {
      this.setState({
        hijriJSON: respData.data,
        fetching: false
      });
    } else {
      this.setState({
        fetching: false
      });
    }
  }

  async onRefreshHandler() {
    await this.fetchData();
  }

  renderLastUpdatedAt(timestamp, fetching) {
    if(fetching) {
      return <p className="hijri_loader"><Icon name="cog" spin /> Please wait patiently while fetching the hijri.json from server ..</p>;
    } else if(!timestamp) {
      return <p>Something went wrong. Please refresh the page.</p>;
    } else {
      return <Alert bsStyle="info">
        <Row>
          <Col md={8}>
            <p className="hijri_json_updated_at_msg">The hijri.json was last updated at <strong>{moment(timestamp*1000).format('LLLL')}</strong></p>
          </Col>
          <Col md={4}>
            <Button onClick={this.onRefreshHandler.bind(this)} bsStyle="primary" className="pull-right">Refresh data</Button>
          </Col>
        </Row>
      </Alert>;
    }
  }

  renderStartsOfHijriYear(countryBasedCalendar, fetching) {
    if(fetching || !countryBasedCalendar) {
      return null;
    } else {
      const countryList = Object.keys(countryBasedCalendar);
      return countryList.sort().map((countryCode) => {
        const countryName = (countryCode === 'default') ? 'DEFAULT' : (countries[countryCode.toUpperCase()] || countryCode.toUpperCase());
        const countryCalendar = countryBasedCalendar[countryCode];
        const years = Object.keys(countryCalendar);
        const yearEls = years.sort((a, b) => { return b - a; }).map((year) => {
          const monthStarts = countryCalendar[year];
          const monthsEls = monthStarts.map((startDate, i) => {
            let style;
            if([1, 8, 9, 12].indexOf(i+1) > -1) {
              style = 'warning';
            }
            return <ListGroupItem key={i} bsStyle={style} className="small">{HIJRI_MONTH_NAMES[i]} <span className="pull-right">{startDate}</span></ListGroupItem>;
          });
          return <Panel key={year}>
            <Panel.Heading>{year}</Panel.Heading>
            <Panel.Body>
              <ListGroup>
                {monthsEls}
              </ListGroup>
            </Panel.Body>
          </Panel>
        });
        return <Panel key={countryName}>
          <Panel.Heading>
            <Panel.Title componentClass="h3">{countryName}<code className="hijri_json_code pull-right">{countryCode}</code></Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            {yearEls}
          </Panel.Body>
        </Panel>
      });
    }
  }

  renderSightings(sightings, fetching) {
    if(fetching || !sightings) {
      return null;
    } else {
      const years = Object.keys(sightings);
      return years.sort((a, b) => { return b - a; }).map((year) => {
        const countryList = sightings[year];
        const countryEls = Object.keys(countryList).sort().map((countryCode) => {
          const countryName = (countryCode === 'default') ? 'DEFAULT' : (countries[countryCode.toUpperCase()] || countryCode.toUpperCase());
          const style = countryList[countryCode] ? 'success' : 'danger';
          return <ListGroupItem key={countryCode}  className="small" bsStyle={style}>{countryName} <code className="hijri_json_code pull-right">{countryCode}</code></ListGroupItem>;
        });
        return <Panel key={year}>
          <Panel.Heading>
            <Panel.Title componentClass="h3">{year}</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <ListGroup>
              {countryEls}
            </ListGroup>
          </Panel.Body>
        </Panel>;
      });
    }
  }

  renderTitle(fetching, title) {
    if(fetching) {
      return null;
    } else {
      return <h3 className="hijri_section_title">{title}</h3>;
    }
  }

  render() {
    const { hijriJSON = {}, fetching } = this.state;
    const { timestamp, sightings = {} } = hijriJSON;
    const { eidulfitr, ramadan } = sightings;
    const countryBasedCalendar = hijriJSON;
    if(countryBasedCalendar) {
      delete countryBasedCalendar['timestamp'];
      delete countryBasedCalendar['sightings'];
    }
    return (
      <BasicLayout pagePermission="moderate_precalc">
        <div className="container-fluid hijri_json">
          <Row>
            <Col md={12}>
              {this.renderLastUpdatedAt(timestamp, fetching)}
            </Col>
            <Col md={12}>
              <ul>
                <li>Red background means NOT updated</li>
                <li>Green background means updated</li>
                <li>Yellow background on left side are IMPORTANT hijri months</li>
              </ul>
            </Col>
            <Col md={4}>
              {this.renderTitle(fetching, 'Starts of Hijri Year')}
              {this.renderStartsOfHijriYear(countryBasedCalendar, fetching)}
            </Col>
            <Col md={4}>
              {this.renderTitle(fetching, 'Ramadan Sightings')}
              {this.renderSightings(ramadan, fetching)}
            </Col>
            <Col md={4}>
              {this.renderTitle(fetching, 'Eid ul Fitr Sightings')}
              {this.renderSightings(eidulfitr, fetching)}
            </Col>
          </Row>
        </div>
      </BasicLayout>
    );
  }
}

export default Hijri;
