import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  deleteV2BulkContents, updateV2HashTags,
} from '../../services/mp-cf-api-service';

const deleteContents = () => {
  const [ deleteContentsData, setDeleteContentsData ] = useState({ });
  const [ deleteData, setDeleteData ] = useState(null);
  const {
    contents,
    onOperationComplete,
  } = deleteData || {};

  const deleteV2Contents = async () => {
    try {
      console.log(`Deleting contents...`);
      setDeleteContentsData({
        isDeleting: true,
        isError: false,
      });

      const contentIds = _.map(contents, 'id');
      console.log(`Ready to delete ${JSON.stringify(contentIds)}`);

      const hashTagDeleteResp = await updateV2HashTags({
        contents,
        operation: 'remove',
        force_error: false,
      });

      let errorMsg = null;
      let isError = false;

      if (hashTagDeleteResp.data.result === 'success') {
        console.log('Hash tags Delete Success!!');
        const contentDeleteResp = await deleteV2BulkContents({
          content_ids: contentIds,
        });
        if (contentDeleteResp.data.result === 'success') {
          console.log('Contents Delete Success!!');
        } else {
          console.log('Contents Delete Failed!!');
          errorMsg = `Error occurred while deleting contents.`;
          isError = true;
        }
      } else {
        errorMsg = `Error occurred while deleting hashtags.`;
        isError = true;
      }

      setDeleteContentsData({
        isDeleting: false,
        isError,
        errorMsg,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError,
          errorMsg,
        });
      }
    } catch (err) {
      console.log('Something went wrong while deleting contents. ', err);
      const errorMsg = `Error occurred while deleting contents.`;
      setDeleteContentsData({
        isDeleting: false,
        isError: true,
        errorMsg,
      });
      if(onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(deleteData)) {
      deleteV2Contents();
    }
  }, [ deleteData ]);

  return [
    deleteContentsData,
    setDeleteData,
  ];
};

export default deleteContents;
