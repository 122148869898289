import { md5 } from './utils';
import firebase from 'firebase/app';
import { PREMIUM_TRANSACTION_DATABASE_URL } from '../Constants'

const subpath = '/transaction-userid'

const getRef = {
  transactionDB: () => {
    const app = firebase.app();
    return app.database(PREMIUM_TRANSACTION_DATABASE_URL);
  },
  transactionDB_fallback: ()  => {
    const app = firebase.app();
    return app.database(PREMIUM_TRANSACTION_DATABASE_URL);
  }
}

export const resolveTransactionRef = async (transactionId) => {
  const newValue = await getRef.transactionDB().ref(`${subpath}/${transactionId}`).once('value');

  if (newValue.val() !== null) {
    return getRef.transactionDB().ref(`${subpath}/${transactionId}`);
  } else {
    return getRef.transactionDB_fallback().ref(`${subpath}/${transactionId}`);
  }
};

export const getTransactionEntrySnapshotWithCallback = async (transactionId, success_callback ) => {
  return (await resolveTransactionRef(transactionId)).once('value', success_callback).catch((error) => {
    throw error;
  });
};

export const setTransactionEntryItem = async (itemKey, purchaseObject) => {
  const mapItem = { token: purchaseObject.token, user_id: purchaseObject.user_id, type: purchaseObject.type };
  const md5Token = md5(purchaseObject.token);
  await getRef.transactionDB().ref(`${subpath}/${md5Token}/${itemKey}`).set(mapItem);
};

export const removeTransactionEntryItem = async (transactionId) => {
  await Promise.all([getRef.transactionDB().ref(`${subpath}/${transactionId}`).remove(), getRef.transactionDB_fallback().ref(`${subpath}/${transactionId}`).remove()]);
};

export const removeTransactionEntryItemByPath = async (path) => {
  await Promise.all([getRef.transactionDB().ref(`${subpath}${path}`).remove(), getRef.transactionDB_fallback().ref(`${subpath}${path}`).remove()]);
};
