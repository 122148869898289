import React,
{
  useReducer,
} from 'react';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  OperationStatus,
} from '../../widgets/common';
import {
  SettingsFiles,
  SettingsFileOperationTypes,
  SettingsTSFileOperationTypes,
} from './Constants';
import {
  TsUpdateInitialState,
  updateTimestampsReducer,
} from './Reducers';
import {
  actionTypes,
} from './actionTypes';
import {
  updateSettingsFileTimestampsHook,
} from '../../hooks';
import {
  isValidKeyLocations,
  getTimestampLocations,
  shouldDisableTsUpdateBtn,
  getSettingFilesWithTimestamps,
} from './helper';
import {
  TimestampUpdateForm,
} from './common';
import {
  createLog,
} from '../../services/logs';

const operationTypes = Object.keys(SettingsFileOperationTypes).map((option) => {
  return {
    key: option,
    value: SettingsFileOperationTypes[option]
  }
});

const tsOperationTypes = Object.keys(SettingsTSFileOperationTypes).map((option) => {
  return {
    key: option,
    value: SettingsTSFileOperationTypes[option]
  }
});

const settingFilesWithTs = Object.keys(getSettingFilesWithTimestamps()).map((option) => {
  return {
    key: option,
    value: SettingsFiles[option]
  }
});

const UpdateSettingsFileTimestamps = () => {
  const[ state, dispatch ] = useReducer(updateTimestampsReducer, TsUpdateInitialState);

  const {
    selectedFile,
    selectedFetchType,
    timestampLocationSelect,
    timestampLocationManual,
    selectedTimestampLocationType,
  } = state;

  const validTimestampLocations = isValidKeyLocations(timestampLocationManual);

  const [ settingsFileUpdateData, doUpdate ] = updateSettingsFileTimestampsHook();
  const isUpdating = settingsFileUpdateData && settingsFileUpdateData.isUpdating;

  const onOperationComplete = operationData => {
    if(!operationData.isError) {
      createLog(`${selectedFile}_timestamp_update_success`.toLowerCase(), {
        'timestampLocations': getTimestampLocations(selectedTimestampLocationType,
          timestampLocationSelect, timestampLocationManual),
      }).then(() => console.log(`${selectedFile} create log success!`))
        .catch(() => console.log(`${selectedFile} create log failed!`));
      toast.success(`Updating time stamps of file: ${selectedFile} Success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      dispatch({
        type: actionTypes.SUT_RESET_STATE,
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const updateTimestamps = () => {
    doUpdate({
      fileName: selectedFile,
      updateConfig: {
        'updateTimestamps': true,
        'timestampLocations': getTimestampLocations(selectedTimestampLocationType,
            timestampLocationSelect, timestampLocationManual)
      },
      onOperationComplete,
    });
  };

  const disableUpdateButton = shouldDisableTsUpdateBtn(selectedTimestampLocationType,
      timestampLocationSelect, timestampLocationManual, validTimestampLocations);

  return (
      <BasicLayout pagePermission="readonly">
        <ToastContainer autoClose={2000}/>
        <div>
          <Row>
            <Col md={12}>
              <h3 className='content_editor_title'>{'Update Settings File Timestamps'}</h3>
            </Col>
          </Row>
          <TimestampUpdateForm
              dispatch={dispatch}
              operationTypes={operationTypes}
              tsOperationTypes={tsOperationTypes}
              selectedFetchType={selectedFetchType}
              selectedTimestampLocationType={selectedTimestampLocationType}
              settingFilesWithTs={settingFilesWithTs}
              selectedFile={selectedFile}
              timestampLocationSelect={timestampLocationSelect}
              timestampLocationManual={timestampLocationManual}
              disableUpdateButton={disableUpdateButton || isUpdating}
              validLocations={validTimestampLocations}
              isUpdating={isUpdating}
              propUpdateActionType={actionTypes.SUT_UPDATE_PROP_VAL}
              fetchActionType={actionTypes.SUT_FETCH_FILE}
              onSubmit={updateTimestamps}
          />
          {
            isUpdating ?
                <OperationStatus
                    operationInProgressText={`Updating ${selectedFile} file.`}
                /> : null
          }
        </div>
      </BasicLayout>
  );
};

export default UpdateSettingsFileTimestamps;
