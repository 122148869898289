import React from 'react';
import './style.css';
import ReloadButton from '../reloadbutton';
import {
  MenuItems,
} from '../../menu';

const Sidebar = ({
                   usersPermission,
                 }) => {

  return (
    <div>
      <div className="sidebar mp-side-bar">
        <MenuItems usersPermission={usersPermission} />
        <ReloadButton />
      </div>
    </div>
  );
};

export default Sidebar;
