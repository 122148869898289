import React,
{
  useReducer,
} from 'react';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  diff,
} from 'json-diff';
import BasicLayout from '../../layouts/basiclayout';
import {
  OperationStatus,
} from '../../widgets/common';
import {
  SettingsFiles,
  SettingsFileOperationTypes,
} from './Constants';
import {
  updateRawReducer,
} from './Reducers';
import {
  actionTypes,
} from './actionTypes';
import {
  fetchSettingsFileHook,
  updateSettingsFileHook,
} from '../../hooks';
import JSONFileUpdater from '../../widgets/json-file-updator';
import {
  getJSONUpdaterData,
  shouldShowJSONFileUpdater,
} from './helper';
import {
  FetchForm,
} from './common';
import {
  createLog,
} from '../../services/logs';


const fetchTypes = Object.keys(SettingsFileOperationTypes).map((option) => {
  return {
    key: option,
    value: SettingsFileOperationTypes[option]
  }
});

const settingFiles = Object.keys(SettingsFiles).map((option) => {
  return {
    key: option,
    value: SettingsFiles[option]
  }
});

const InitialState = {
  fileData: null,
  selectedFetchType: '',
  selectedFile: '',
  showJSONUpdater: false,
};

const UpdateRawSettingsFile = () => {
  const[ state, dispatch ] = useReducer(updateRawReducer, InitialState);

  const {
    selectedFetchType, selectedFile, showJSONUpdater,
  } = state;

  const [ settingsFileData, doFetch ] = fetchSettingsFileHook();
  const [ settingsFileUpdateData, doUpdate ] = updateSettingsFileHook();
  const jsonUpdaterData = getJSONUpdaterData(selectedFile, settingsFileData,settingsFileUpdateData)

  const showJSONFileUpdater =
      shouldShowJSONFileUpdater(settingsFileData, jsonUpdaterData, showJSONUpdater);
  const isFetching = settingsFileData && settingsFileData.isFetching;
  const isUpdating = settingsFileUpdateData && settingsFileUpdateData.isUpdating;

  const onCompleteUpdate = operationData => {
    if(!operationData.isError) {
      createLog(`${selectedFile}_file_update_success`.toLowerCase(), {
        updatedDiff: diff(settingsFileData.data, operationData.updatedFileData),
      }).then(() => console.log(`${selectedFile} create log success!`))
        .catch(() => console.log(`${selectedFile} create log failed!`));
      toast.success(`Updating file: ${selectedFile} Success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onCompleteFetch = operationData => {
    if(!operationData.isError) {
      toast.success(`Fetching file: ${selectedFile} Success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const disableFetchButton = !selectedFile || isFetching;

  return (
      <BasicLayout pagePermission="readonly">
        <ToastContainer autoClose={2000}/>
        <div>
          <Row>
            <Col md={12}>
              <h3 className='content_editor_title'>{'Update Settings File Raw JSON'}</h3>
            </Col>
          </Row>
          <FetchForm
              dispatch={dispatch}
              fetchTypes={fetchTypes}
              selectedFetchType={selectedFetchType}
              settingFiles={settingFiles}
              selectedFile={selectedFile}
              disableFetchButton={disableFetchButton}
              isFetching={isFetching}
              propUpdateActionType={actionTypes.SRJ_UPDATE_PROP_VAL}
              fetchActionType={actionTypes.SRJ_FETCH_FILE}
              onSubmit={(file) => {
                doFetch({
                  fileName: file,
                  onOperationComplete: onCompleteFetch,
                })
              }}
          />
          {
            isUpdating ?
                <OperationStatus
                    operationInProgressText={`Updating ${selectedFile} file.`}
                /> : null
          }
          {
            showJSONFileUpdater ?
                <JSONFileUpdater
                    jsonData={jsonUpdaterData}
                    fileName={selectedFile}
                    onSubmitUpdatedFile={(updatedFile) => doUpdate({
                      fileName: selectedFile,
                      updatedFile,
                      onOperationComplete: onCompleteUpdate,
                    })
                    }
                /> : null
          }
        </div>
      </BasicLayout>
  );
};

export default UpdateRawSettingsFile;
