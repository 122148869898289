// Delete User Logs
const DUL_RESET = 'DUL_RESET';
const DUL_UPDATE_INPUT_DATA = 'DUL_UPDATE_INPUT_DATA';
const DUL_UPDATE_PROP_VAL = 'DUL_UPDATE_PROP_VAL';
const DUL_TOGGLE_PROP_VAL = 'DUL_TOGGLE_PROP_VAL';

export const actionTypes = {
  DUL_RESET,
  DUL_UPDATE_PROP_VAL,
  DUL_TOGGLE_PROP_VAL,
  DUL_UPDATE_INPUT_DATA,
};