import React, { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  fetchQuranQuotesRawJsonHook, updateQuranQuotesRawJsonHook,
} from '../../hooks';
import { OperationStatus } from '../../widgets/common';
import { QuranQuotesList } from './common';
import { getQuranQuotesList } from './helper';

const List = () => {

  const [ quranQuotesListData, doFetch ] = fetchQuranQuotesRawJsonHook();
  const isFetching = quranQuotesListData && quranQuotesListData.isFetching;

  const [ quranQuotesUpdateData, doUpdate ] = updateQuranQuotesRawJsonHook();
  const isUpdating = quranQuotesUpdateData && quranQuotesUpdateData.isUpdating;

  const onFetchComplete = operationData => {
    if(!operationData.isError) {
      toast.success(`Fetching Quoran Quotes Data Success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  useEffect(() => {
    doFetch({
      onOperationComplete: onFetchComplete,
    });
  }, []);

  const quranQuotesList = getQuranQuotesList(quranQuotesListData);

  return (
    <BasicLayout pagePermission="moderate_precalc">
      <ToastContainer autoClose={2000}/>
      <div>
        <form className={'form-inline'}>
          <Row>
            <Col md={12}>
              <h3 className='content_editor_title'>{'Verse of the day list'}</h3>
            </Col>
          </Row>
          {
            (isUpdating || isFetching)
              ?
              <OperationStatus
                operationInProgressText={`Please wait! ${isUpdating ? 'Updating' : 'Fetching'} Quran Quotes...`}
              />
              :
              <Row>
                <Col md={8}>
                  <QuranQuotesList
                    quranQuotes={quranQuotesList}
                    doUpdate={doUpdate}
                  />
                </Col>
              </Row>
          }
        </form>
      </div>
    </BasicLayout>
  );
};

export default List;
