import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  qalBoxSearchGenres,
} from '../../services/mp-cf-api-service';

const genresSearch = () => {
  const [ responseData, setResponseData ] = useState({ });
  const [ updateData, setOperationData ] = useState(null);
  const {
    searchConfig,
    onOperationComplete,
  } = updateData || {};

  const searchMediaItems = async () => {
    try {
      console.log(`QalBox searching genres ...`);
      setResponseData({
        isLoading: true,
        isError: false,
      });

      const mediaGenresApiResp = await qalBoxSearchGenres({
        data: searchConfig,
      });

      console.log(mediaGenresApiResp);

      const responseData = _.get(mediaGenresApiResp, 'data', {});

      let errorMsg = null;
      let isError = false;

      if(responseData.success) {
        console.log('QalBox search genre items Success!!');
      } else {
        console.log('QalBox search genre items Failed!!');
        errorMsg = `Failed to search QalBox genre items.`;
        isError = true;
      }

      setResponseData({
        isLoading: false,
        isError,
        errorMsg,
        data: responseData.payload,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError,
          errorMsg,
          data: responseData.payload,
        });
      }
    } catch (err) {
      console.log('Something went wrong while searching QalBox media items. ', err);
      const errorMsg = `Error occurred while searching QalBox media items.`;
      setResponseData({
        isLoading: false,
        isError: true,
        errorMsg,
        data: null,
      });
      if(onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
          data: null,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(updateData)) {
      searchMediaItems();
    }
  }, [ updateData ]);

  return [
    responseData,
    setOperationData,
  ];
};

export default genresSearch;
