import React,
{
  Component,
} from 'react';
import BasicLayout from '../../layouts/basiclayout';
import {
  Col,
  Row,
} from 'react-bootstrap';
import SearchBar from './searchBar';
import ResultItem from './resultItem';
import RecentSearches from './recentSearches';
import './style.css';
import 'firebase/auth';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Icon,
} from 'react-fa';
import {
  getUser,
} from '../../services/mp-cf-api-service';
import AdminUserService from "../../service_v2/users/admin-user-service";
import ShardManager from "../../service_v2/users/shard-manager";
import PremiumService from "../../service_v2/premium/premium-service";
const _ = require("lodash")

class SearchUser extends Component {

  constructor(props) {
    super(props);
    this.state = {
      users: null,
      searchResultText: "",
      searchString: "",
      searchBarDefault: "",
      searching: false
    };
  }

  componentWillMount() {
    const query = this.props.match.params.query;
    if(query) {
      this.onSearch(query);
    }
  }

  onUpdateURL(searchString) {
    window.location = `/search-user/${searchString}`;
  }

  onRefreshSearchData( search ){
    const now = new Date();
    this.performSimpleSearch(search).then( () => {
      this.setState( (prev) => ({
        ...prev,
        searchResultText: "Refreshed on " + `${now.toLocaleString()}`
      }) )
    })
  }

  onSearch(searchString) {
    if(searchString.trim() === "") {
      return;
    }
    console.log(`Searched ${searchString}`);
    this.setState({
      searchString,
      searchBarDefault: searchString,
      searching: true
    });
    this.performSimpleSearch(searchString);
    this.clearUsers();
  }

  performSimpleSearch(searchString) {
    return getUser(searchString)
    .then((res) => {
      if(res.data === "") {
        console.log("No user matched with search string");
        this.setState( (prev) => ({
          ...prev,
          searchResultText: "No User Found"
        }))
        this.clearUsers();
        this.stopLoading();
        toast.info("No user found", {
          position: toast.POSITION.BOTTOM_LEFT
        });
      } else {
        const firebaseAcc = res.data;
        Promise.all([
          ShardManager.getUserShardInfo(firebaseAcc.uid),
          ShardManager.getUserShardUrl(firebaseAcc.uid)
        ]).then( ([shardId, shardUrl]) => {
          Promise.allSettled([
            AdminUserService.queryUserData( firebaseAcc.uid, { path: "/" } ),
            PremiumService.getStripePurchase( firebaseAcc.uid )
          ])
            .then( ([userdata, stripedata]) => {
              if( userdata.status == "fulfilled" && !!userdata.value ) {
                this.setState({
                  users: {
                    firebaseAcc,
                    muslimProAcc: userdata.value,
                    stripeData: _.get(stripedata, "value", null),
                    shardUrl: shardUrl,
                    shardId: shardId
                  },
                  searching: false
                });
              }else{
                toast.warn("This user does not have any data on MuslimPro database.", {
                  position: toast.POSITION.BOTTOM_LEFT
                });
                this.clearUsers();
              }
            })
            .catch( (error) => {
              console.log("error getuser/stripe", error)
              this.setState( (prev) => {
                return {
                  ...prev,
                  searching: false
                }
              })
            })

          AdminUserService.queryUserData( firebaseAcc.uid, { path: "/" } )
            .then( muslimProAcc => {
              if(muslimProAcc) {
                this.setState({
                  users: {
                    firebaseAcc,
                    muslimProAcc,
                    shardUrl: shardUrl,
                    shardId: shardId
                  },
                  searching: false
                });
              } else {
                toast.warn("This user does not have any data on MuslimPro database.", {
                  position: toast.POSITION.BOTTOM_LEFT
                });
                this.clearUsers();
              }
            })
        })


      }
    })
    .catch((err) => {
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      this.clearUsers();
      this.stopLoading();
    });
  }

  clearUsers() {
    this.setState({
      users: null,
    });
  }

  stopLoading() {
    this.setState({
      searching: false
    });
  }

  renderSearchResults() {
    const { searching, searchString, users, searchResultText } = this.state;
    let usersEl;
    if(!searching) {
      if(users) {
        usersEl = <ResultItem searchString={searchString} user={users} onRefresh={this.onRefreshSearchData.bind(this)}/>;
        return (
          <div>
            <p className="resultitems__count">{searchResultText}</p>
            {usersEl}
          </div>
        );
      } else {
        return <p className="resultitems__count">{searchResultText}</p>;
      }

    } else {
      return (
        <p className="resultitems__count">
          <Icon spin name="spinner" /> Fetching user
        </p>
      );
    }
  }

  onRecentClick(recent) {
    this.onUpdateURL(recent);
  }

  render() {
    return (
      <BasicLayout pagePermission="read_users">
        <ToastContainer autoClose={5000} />
        <Row>
          <Col className="searchbar__cont" md={12}>
            <SearchBar searchBarDefault={this.state.searchBarDefault} onSearch={this.onUpdateURL.bind(this)}/>
          </Col>
          <Col className="resultitems__cont" md={8}>
            {this.renderSearchResults()}
          </Col>
          <Col className="recentsearches__cont" md={4}>
            <strong>Recent searches</strong>
            <RecentSearches onRecentClick={this.onRecentClick.bind(this)} lastSearch={this.state.searchString} limit={10}/>
          </Col>
        </Row>
      </BasicLayout>
    );
  }
}

export default SearchUser;
