// Create Timeline Promo
const TPC_RESET = 'TPC_RESET';
const TPC_UPDATE_PROP_VAL = 'TPC_UPDATE_PROP_VAL';
const TPC_UPDATE_COUNTRIES = 'TPC_UPDATE_COUNTRIES';
const THC_UPDATE_AUDIENCES = 'THC_UPDATE_AUDIENCES';
const TPC_ON_UPLOAD_IMAGE = 'TPC_ON_UPLOAD_IMAGE';
const TPC_REMOVE_IMAGE = 'TPC_REMOVE_IMAGE';
const TPC_TOGGLE_OPTIMISE_IMAGE = 'TPC_TOGGLE_OPTIMISE_IMAGE';
const TPC_TOGGLE_PROP_VAL = 'TPC_TOGGLE_PROP_VAL';

// List Timeline Promos
const TPL_UPDATE_DELETE = 'TPL_UPDATE_DELETE';

export const actionTypes = {
  TPC_RESET,
  TPC_UPDATE_PROP_VAL,
  TPC_UPDATE_COUNTRIES,
  THC_UPDATE_AUDIENCES,
  TPC_ON_UPLOAD_IMAGE,
  TPC_REMOVE_IMAGE,
  TPC_TOGGLE_OPTIMISE_IMAGE,
  TPC_TOGGLE_PROP_VAL,
  TPL_UPDATE_DELETE,
};