import React from 'react';
import {
  ramadanListColumns,
} from './columns';
import CommonList from './commonList';
import {
  CONTENT_TYPE,
} from './Constants';

const ListRamadan = () => {

  return (
      <CommonList
          title={'Content V2 Ramadan List'}
          initialFilters={
            [
              { id: 'forRamadan', value: 'True' },
              { id: 'typeIcon', value: CONTENT_TYPE.QUOTE },
              { id: 'languageCode', value: 'en' },
              { id: 'yearCode', value: new Date().getFullYear().toString() },
            ]
          }
          columns={ramadanListColumns()}
      />
  );
};

export default ListRamadan;
