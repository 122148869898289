import _ from 'lodash';

const getRowCount = (userLogsRowsCountData) => {
  if(_.isEmpty(userLogsRowsCountData) ||
    _.isEmpty(userLogsRowsCountData.data)) {
    return 0;
  }

  const data = userLogsRowsCountData.data;

  return data.total_records_count;
};

const getCheckBoxes = (userLogsRowsCountData) => {
  if(_.isEmpty(userLogsRowsCountData) ||
    _.isEmpty(userLogsRowsCountData.data)) {
    return [];
  }

  const table_log_counts = userLogsRowsCountData.data.table_log_counts;
  if(_.isEmpty(table_log_counts)) {
    return [];
  }

  const checkBoxes = [];
  for (const table_log_count of table_log_counts) {
    if(table_log_count.success) {
      checkBoxes.push({
        id: table_log_count['table_name'],
        name: `${table_log_count['table_name']} (${table_log_count['records_count']})`,
        initialValue: table_log_count['records_count'] > 0,
      });
    }
  }

  return checkBoxes;
};

const getSelectedTables = (userLogsRowsCountData) => {
  if(_.isEmpty(userLogsRowsCountData) ||
    _.isEmpty(userLogsRowsCountData.table_log_counts)) {
    return [];
  }

  const selectedTables = [];
  for (const table_log_count of userLogsRowsCountData.table_log_counts) {
    if(table_log_count['records_count'] > 0) {
      selectedTables.push({
        table_name: table_log_count['table_name'],
        delete: table_log_count['records_count'] > 0,
      });
    }
  }

  return selectedTables;
};

const getSelectedCheckBoxTables = selectedOptions => {
  return _.map(_.pickBy(selectedOptions, _.identity), (val, key) => {
    return {
      table_name: key,
      delete: val,
    };
  });
};

const getDownloadDataCheckBoxes = (downloadDataObj) => {
  if(_.isEmpty(downloadDataObj)) {
    return [];
  }

  const checkBoxes = [];
  for (const downloadDataKey of _.keys(downloadDataObj)) {
    checkBoxes.push({
      id: downloadDataKey,
      name: downloadDataKey,
      initialValue: true,
    });
  }

  return checkBoxes;
};

const getBQLogsDataCheckBoxes = (userLogsData) => {
  if(_.isEmpty(userLogsData)
    || _.isEmpty(userLogsData.data)
    || _.isEmpty(userLogsData.data.table_logs)) {
    return [];
  }

  const checkBoxes = [];
  for (const tableLog of userLogsData.data.table_logs) {
    checkBoxes.push({
      id: tableLog['table_name'],
      name: `${tableLog['table_name']} - (${tableLog['records_count']})`,
      initialValue: tableLog['records_count'] > 0,
    });
  }

  return checkBoxes;
};

const getBQLogsUserData = (userLogsData) => {
  const userBqLogsData = {};
  if(!userLogsData || _.isEmpty(userLogsData.table_logs)) {
    return userBqLogsData;
  }

  for(const table_log of userLogsData.table_logs) {
    userBqLogsData[table_log['table_name']] = table_log['rows'];
  }

  return userBqLogsData;
};

const getDownloadData = (exportType, data) => {
  switch (exportType) {
    case 'csv':
    case 'xls':
      return [_.reduce(data, function(result, value, key) {
        const subData = _.reduce(value, function(subResult, subValue, subKey) {
          if (!_.isEmpty(subValue)) {
            subResult[subKey] = JSON.stringify(subValue);
          }

          return subResult;
        }, {});
        return {
          ...result,
          ...subData,
        };
      }, {})];
    default:
      return data;
  }
};

const getFreeTrialStatus = premiumStatus => {
  if (_.has(premiumStatus, 'free_trial')) {
    return _.get(premiumStatus, 'free_trial');
  }

  return false;
};

export {
  getRowCount,
  getCheckBoxes,
  getDownloadData,
  getBQLogsUserData,
  getSelectedTables,
  getFreeTrialStatus,
  getBQLogsDataCheckBoxes,
  getDownloadDataCheckBoxes,
  getSelectedCheckBoxTables,
}
