import axios from 'axios';
import {
  MP_CF_GEN2_API_BASE_URL,
} from './Constants';

const getBaseConfig = (idToken) => {
  const headers = {
    'idToken': idToken || '',
    'uid': localStorage.getItem('uid') || '',
    'Content-Type': 'application/json',
    'If-None-Match': 'dd'
  };

  if (idToken) {
    headers['Authorization'] = `Bearer ${idToken}`;
  }

  return {
    baseURL: MP_CF_GEN2_API_BASE_URL,
    headers,
  };
}

export const creatRequestGen2 = (idToken, requestConfigs) => {
  return axios.create(getBaseConfig(idToken))(requestConfigs);
};
