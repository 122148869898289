import React,
{
  useMemo,
  Fragment,
  useState,
} from 'react';
import {
  addDays,
} from 'date-fns';
import {
  DateRangePicker,
} from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './style.css';


const MPDateRangePicker = ({
                             startDate = new Date(),
                             endDate = null,
                             onApplyDate = (data) => console.log('On Apply Date : ', data),
                             onUpdateDate = (data) => console.log('On Update Date : ', data),
                             onResetDate = (data) => console.log('On Reset Date : ', data),
                             direction = 'vertical',
                             shouldScroll = false,
                             minDaysFromStart,
                             maxDateFromStart,
                             showApplyButton=true,
                             dataKey='selection'
                           }) => {
  const [ state, setState ] = useState({
    [dataKey]: {
      startDate,
      endDate,
      key: dataKey,
    },
  });

  const [ showPicker, setShowPicker ] = useState(false);

  const onPicketChangeDate = item => {
    setState({ ...state, ...item });
    onUpdateDate(item);
  };

  const onApply = () => {
    onApplyDate(state[dataKey]);
    setShowPicker(false);
  };

  const onReset = () => {
    setState({
      ...state,
      [dataKey]: {
        startDate,
        endDate,
        key: dataKey,
      },
    });
    onResetDate({
      ...state[dataKey],
      startDate,
      endDate,
    });
  };


  const minDate = minDaysFromStart && addDays(startDate, -minDaysFromStart);
  const maxDate = maxDateFromStart && addDays(startDate, maxDateFromStart);

  const ranges = useMemo(() => {
    return [ state[dataKey]];
  }, [ state[dataKey] ]);

  return (
      <div className={'mp-date-range-picker-main'}>
        <span
            onClick={() => setShowPicker(!showPicker)}
        >
          <i className="mp-date-range-picker-small-icon fa fa-calendar"/>
        </span>
        {
          showPicker &&
          <Fragment>
            <div className={'mp-date-range-picker-cover'}
                 onClick={() => setShowPicker(false)}
            />
            <div className={'mp-date-range-picker'}>
              <DateRangePicker
                  onChange={onPicketChangeDate}
                  moveRangeOnFirstSelection={true}
                  months={1}
                  minDate={minDate}
                  maxDate={maxDate}
                  direction={direction}
                  scroll={{ enabled: shouldScroll }}
                  ranges={ranges}
              />
              {
                showApplyButton &&
                <div className={'mp-date-range-picker-tools'}>
                  <button
                      className={'btn btn-default mp-date-range-picker-btn-apply'}
                      onClick={() => onApply()}
                  >
                    Apply
                  </button>
                  <button
                      className={'btn btn-default mp-date-range-picker-btn-reset'}
                      onClick={() => onReset()}
                  >
                    Reset
                  </button>
                </div>
              }
            </div>
          </Fragment>

        }
      </div>
  );
}

export {
  MPDateRangePicker,
};
