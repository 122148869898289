import React,
{
  Component,
} from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import {
  createLog,
} from '../../services/logs';
import './styles.css';

class Logout extends Component {
  componentDidMount() {
    firebase.auth().signOut().then(function() {
      // Sign-out successful.
      createLog('logout');
      localStorage.removeItem("eav1at");
      localStorage.removeItem("idToken");
      localStorage.removeItem("uid");
      localStorage.removeItem("user");
      localStorage.removeItem("userPermission");
      localStorage.removeItem("moderatorsInfo");
      localStorage.removeItem("dataModerators");
      setTimeout(()=> {
        window.location = "/login";
      }, 2000);
    }, function(error) {
      // An error happened.
      window.location = "/login";
    });
  }

  render() {
    return (
      <div className="logout">
        Logging out...
      </div>
    );
  }
}

export default Logout;
