import _ from 'lodash';
import React, { Component } from 'react';
import Toggle from 'react-toggle';
import moment from 'moment';
import { editQuranPlaylists, redisFlushCacheForCF } from '../../services/mp-cf-api-service';
import { ToastContainer, toast } from 'react-toastify';
import {
  Row, Col, Modal, Button, FormControl, FormGroup, ControlLabel, Alert,
} from 'react-bootstrap';
import Select from 'react-select';
import CountryPicker from '../../widgets/countryPicker';
import { SurasV2Edit } from './common';
import {
  FEATURED_SUPPORTED_LANGUAGES, QURAN_PLAYLIST_FEATURED_CACHE_ID_DEBUG, QURAN_PLAYLIST_FEATURED_CACHE_ID,
} from './constants';

const options = Object.keys(FEATURED_SUPPORTED_LANGUAGES).map((key, i) => {
  return <option key={i} value={key}>{FEATURED_SUPPORTED_LANGUAGES[key]}</option>;
});

const changesMsg = 'Changes made, please click save button to update playlist.';

export default class EditModal extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      playlist: {},
      updateObj: {},
      statusMsg: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      playlist: nextProps && nextProps.playlist ? nextProps.playlist : {}
    });
  }

  onUpdateField(key, e) {
    const { playlist, updateObj } = this.state;
    playlist[key] = e.target.value;
    if(key === 'published_at') {
      if(!playlist[key] || playlist[key] === "") {
        delete playlist[key];
      } else {
        playlist[key] = moment(playlist[key]).unix() * 1000;
      }
    }
    if(['pinned_duration', 'featured_duration'].indexOf(key) > -1) {
      if(!playlist[key] || playlist[key].trim() === "") {
        delete playlist[key];
      } else {
        playlist[key] = parseInt(playlist[key], 10);
      }
    }
    if(['is_pinned', 'is_featured', 'is_deleted'].indexOf(key) > -1) {
      playlist[key] = e.target.checked;
    }
    if(playlist[key] !== null || playlist[key] !== undefined) {
      updateObj[key] = playlist[key];
    }
    this.setState({
      playlist,
      updateObj,
      statusMsg: changesMsg
    });
  }

  onSuraChange(change) {
    const suras = change.map((sura) => {
      return sura.value;
    });
    const { playlist, updateObj } = this.state;
    playlist['suras'] = suras;
    updateObj['suras'] = suras;
    playlist['suras_v2'] = _.map(suras, (suraId) => {
      return _.find(playlist['suras_v2'], ({ sura_id }) => suraId === sura_id) || { sura_id: suraId };
    });
    updateObj['suras_v2'] = playlist['suras_v2'];

    this.setState({
      playlist,
      updateObj,
      statusMsg: changesMsg
    });
  }

  onAyaChange(suraId, changes) {
    const ayas = changes.map(aya => {
      return aya.value;
    });
    const { playlist, updateObj } = this.state;

    const index = _.findIndex(playlist['suras_v2'], { sura_id: suraId });
    const updateSura = { sura_id: suraId };
    if(!_.isEmpty(ayas)) {
      updateSura['aya_ids'] = ayas;
    }
    playlist['suras_v2'].splice(index, 1, updateSura);
    updateObj['suras_v2'] = playlist['suras_v2'];

    this.setState({
      playlist,
      updateObj,
      statusMsg: changesMsg
    });
  }

  onHide() {
    this.setState({
      updateObj: {}
    });
    this.props.onHide();
  }

  renderSurasSelect(suras) {
    const surasOptions = [];
    for(let i=1; i<=114; i++) {
      surasOptions.push({
        value: i,
        label: i
      });
    }
    const selectedSuras = suras.map((i) => {
      return { value: i, label: i };
    });
    return <Select onChange={this.onSuraChange.bind(this)} isSearchable={true} value={selectedSuras} options={surasOptions} isMulti/>;
  }

  renderSurasV2(suras_v2) {

    return <SurasV2Edit suras_v2={suras_v2} onEditAya={this.onAyaChange.bind(this)} />;
  }

  onCountryChange(supportedCountriesObjList) {
    supportedCountriesObjList = supportedCountriesObjList || [];
    const supportedCountries = [];
    supportedCountriesObjList.forEach((item) => {
      supportedCountries.push(item.value);
    });
    const { playlist, updateObj } = this.state;
    playlist['supported_countries'] = supportedCountries;
    updateObj['supported_countries'] = supportedCountries;
    this.setState({
      playlist,
      updateObj,
      statusMsg: changesMsg
    });
  }

  renderSupportedCountriesSelect(supportedCountries) {
    return <CountryPicker
      hideLabel={true}
      ignoreEU={true}
      selectedCountries={supportedCountries}
      onChange={this.onCountryChange.bind(this)}/>
  }

  async onUpdatePlaylist(updateObj, e) {
    e.preventDefault();
    this.setState({
      statusMsg: 'Updating playlist...'
    });
    const { debug, playlist } = this.props;
    const resp = await editQuranPlaylists({
      playlistId: playlist.id,
      playlistUpdates: updateObj,
      debug
    });
    this.setState({
      statusMsg: ''
    });
    if(resp.data && resp.data.success) {
      const msg = resp.data.payload;
      const cacheId = debug ? QURAN_PLAYLIST_FEATURED_CACHE_ID_DEBUG : QURAN_PLAYLIST_FEATURED_CACHE_ID;
      const flushCacheResp = await redisFlushCacheForCF({ mainKey: cacheId });
      if(flushCacheResp.data && flushCacheResp.data.result === 'success') {
        toast.success(`${msg} and cache has been cleared.`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
      } else {
        toast.warning(`${msg} but failed to clear the cache. Please contact developer`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }
      setTimeout(() => {
        this.props.onEditSuccessful();
        this.props.onHide();
      }, 2000);
    } else {
      toast.error(resp.data.payload.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  }

  render() {
    const { playlist, updateObj, statusMsg } = this.state;
    console.log("updateObj", updateObj);
    const { name, id, suras=[], description, language_code, published_at, supported_countries,
            is_pinned, is_featured, is_deleted, pinned_duration, featured_duration, cover_img_url,
            suras_v2,
          } = playlist;
    const collectionName = this.props.debug === 1 ? 'QuranPlaylistsDebug' : 'QuranPlaylists';
    const qpDBLink = `https://console.firebase.google.com/project/muslim-pro-app/database/firestore/data~2F${collectionName}~2F${id}`;
    const publishedAtString = moment(published_at || Date.now()).format('LLLL');
    const disableSaveButton = Object.keys(updateObj).length === 0;
    return <Modal
      {...this.props}
      className="quranplaylist__edit_modal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Editing: {name} [<a target="_blank" href={qpDBLink}>{id}</a>]
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ToastContainer autoClose={2000} />
        <form>
          <FormGroup>
            <ControlLabel>Name</ControlLabel>
            <FormControl onChange={this.onUpdateField.bind(this, 'name')} value={name} placeholder="Name"/>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs={9}>
                <ControlLabel>Description</ControlLabel>
                <FormControl onChange={this.onUpdateField.bind(this, 'description')} value={description} componentClass="textarea" rows="5" placeholder="Description"/>
                <ControlLabel>Cover Image</ControlLabel>
                <FormControl onChange={this.onUpdateField.bind(this, 'cover_img_url')} value={cover_img_url}  placeholder="Cover Image URL"/>
              </Col>
              <Col xs={3}>
                <ControlLabel>Cover Image Preview</ControlLabel>
                <img width="100%" alt="cover_img_url" src={cover_img_url} />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <ControlLabel>Suras</ControlLabel>
            {this.renderSurasSelect(suras)}
          </FormGroup>
          {
            _.isEmpty(suras_v2)
            ? null
            : <FormGroup>
                <ControlLabel>Ayas</ControlLabel>
                <Alert bsStyle="warning">
                  <strong>NOTE:</strong> Selecting nothing in the Aya Selection drop down will be equivalent to Selecting All Ayas for each Sura.
                </Alert>
                {this.renderSurasV2(suras_v2)}
              </FormGroup>
          }
          <FormGroup>
            <ControlLabel>Supported countries (Optional)</ControlLabel>
            {this.renderSupportedCountriesSelect(supported_countries)}
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs={6}>
                <ControlLabel>Language</ControlLabel>
                <select onChange={this.onUpdateField.bind(this, 'language_code')} value={language_code} className="form-control">
                  {options}
                </select>
              </Col>
              <Col xs={6}>
                <ControlLabel>Publish at (Local)</ControlLabel>
                <p>{publishedAtString}</p>
                <FormControl onChange={this.onUpdateField.bind(this, 'published_at')} type="datetime-local" placeholder="Publish at"/>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs={6}>
                <ControlLabel>Pinned duration (In days)</ControlLabel>
                <FormControl onChange={this.onUpdateField.bind(this, 'pinned_duration')} type="number" min="0" value={pinned_duration} placeholder="Pinned duration"/>
              </Col>
              <Col xs={6}>
                <ControlLabel>Featured duration (In days)</ControlLabel>
                <FormControl onChange={this.onUpdateField.bind(this, 'featured_duration')} type="number" value={featured_duration} min="0" placeholder="Featured Duration"/>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs={4}>
                <ControlLabel>Is pinned</ControlLabel>
                <div className="block"><Toggle onChange={this.onUpdateField.bind(this, 'is_pinned')} checked={is_pinned}/></div>
              </Col>
              <Col xs={4}>
                <ControlLabel>Is Featured</ControlLabel>
                <div className="block"><Toggle onChange={this.onUpdateField.bind(this, 'is_featured')} checked={is_featured}/></div>
              </Col>
              <Col xs={4}>
                <ControlLabel>Mark deleted</ControlLabel>
                <div className="block"><Toggle onChange={this.onUpdateField.bind(this, 'is_deleted')} checked={is_deleted}/></div>
              </Col>
            </Row>
          </FormGroup>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <p className="pull-left">{statusMsg}</p>
        <Button onClick={this.onHide.bind(this)}>Cancel</Button>
        <Button disabled={disableSaveButton} onClick={this.onUpdatePlaylist.bind(this, updateObj)} bsStyle="primary">Save</Button>
      </Modal.Footer>
    </Modal>;
  }
}
