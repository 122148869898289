import {
    MP_PROMO_CODES_DATABASE_URL,
    MP_PREMIUM_CRON_DATABASE_URL,
} from './Constants';
import firebase from 'firebase/app';

const rtdbRef = {
    promoCodes: (path) => {
        const app = firebase.app();
        return app.database(MP_PROMO_CODES_DATABASE_URL).ref(path);
    },
    premiumCron: (path) => {
        const app = firebase.app();
        return app.database(MP_PREMIUM_CRON_DATABASE_URL).ref(path);
    },
};

export default rtdbRef;
