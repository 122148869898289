import _ from 'lodash';
import React,
{
  useReducer,
} from 'react';
import ImageZoom from 'react-medium-image-zoom';
import {
  Link,
} from 'react-router-dom';
import './style.css';
import {
  ColorPreviewBox,
} from '../../widgets/color-picker';
import {
  timelinePromoListReducer,
} from './Reducers';
import {
  actionTypes,
} from './actionTypes';
import {
  confirmAlert,
} from "react-confirm-alert";
import AppModal from '../../widgets/modal';
import {
  getDeleteTimelinePromosInfo,
} from './common';
import {
  getTimeLinePromosForDelete,
  getDeleteTimelinePromosInfoHeader,
} from './helper';
import {
  Button,
} from 'react-bootstrap';
import {
  crudTimelinePromosHook,
} from '../../hooks';
import {
  toast,
} from 'react-toastify';
import {
  CopyToClipboard,
} from 'react-copy-to-clipboard';

const InitialState = {
  bulkDeleteMap: {},
};

const getPlatforms  = ({ platforms }) => {
  if(_.isEmpty(platforms)) {
    return null;
  }

  const platformsList = platforms.map(platform => {
    return <li key={platform}>{platform}</li>
  })
  return (
      <ul className={'mp-tp-platforms'}>
        {platformsList}
      </ul>
  );
};

const getCountries  = ({ countries }) => {
  if(_.isEmpty(countries)) {
    return null;
  }

  const platformsList = countries.map(platform => {
    return <li key={platform}>{platform}</li>
  })
  return (
      <ul className={'mp-tp-platforms'}>
        {platformsList}
      </ul>
  );
};

const TimelinePromosList = ({ timelinePromos }) => {
  const { timeline_promos } = timelinePromos || {};
  const[ state, dispatch ]  = useReducer(timelinePromoListReducer, InitialState);
  const { bulkDeleteMap } = state;

  const onCompleteOperation = operationData => {
    if(!operationData.isError) {
      toast.success(`Timeline Promos Deleted successfully!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(`Timeline Promos deletion failed!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }

    setTimeout(()=> {
      window.location.reload();
    }, 1500);
  };

  const [ timelinePromosOperationData, startOperation ] = crudTimelinePromosHook();
  const operationInProgress = timelinePromosOperationData
      && timelinePromosOperationData.operationInProgress;

  console.log(timelinePromosOperationData);

  const onDeleteTimelinePromo = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <AppModal
                show={true}
                showBody={true}
                headingText={getDeleteTimelinePromosInfoHeader(bulkDeleteMap)}
                modalBody={getDeleteTimelinePromosInfo(bulkDeleteMap)}
                modalBodyClassName={'precalcs-diff-merge-view'}
                closeButtonText={'No'}
                okButtonText={`Yes, Delete`}
                handleClose={onClose}
                handleOk={() => {
                  startOperation({
                    timelinePromos: getTimeLinePromosForDelete(bulkDeleteMap),
                    operation: 'delete',
                    onCompleteOperation,
                  })
                  onClose();
                }}
            />
        );
      }
    });
  };


  const rows = _.values(timeline_promos).map((timeline_promo, index) => {
    const {
      id, name, banner_url, action_url, countries, action_button_text_color,
      action_button_background, action_button_text, close_button_color, start, end,
      hide_for_premium, hide_banner, hide_cta, platforms,
    } = timeline_promo;

    return (
        <tr className={`content__list_table_row ${hide_banner ? 'hidden_item' : ''}`} key={index + 1}>
          <td className={'content-v2-index'}>
            <div className={'content-v2-index-number'}>{index + 1}</div>
            <span style={{ textAlign: 'center' }}>
                <input
                    className={'content__list_options_bar_input'}
                    type='checkbox'
                    checked={bulkDeleteMap[id] || false}
                    onChange={
                      (e) => dispatch({
                        type: actionTypes.TPL_UPDATE_DELETE,
                        payload: {
                          timelinePromo: timeline_promo,
                          checked: e.target.checked,
                        },
                      })
                    }
                />
              </span>
          </td>
          <td>
            <ImageZoom
                image={{
                  src: `${banner_url}`,
                  style: { width: '64px' }
                }}
                zoomImage={{
                  src: `${banner_url}`,
                }}
            />
          </td>
          <td className={'timeline-promo-item-title'}>{name}</td>
          <td className={'text-center'}>
            <p className={'word-wrap-text text-center'}>{id}</p>
            <CopyToClipboard
              text={id}
              onCopy={() => {
                console.log(`Copied id : ${id}`)
              }}
            >
              <Button className="btn btn-xs btn-dark mp-content-v2-table-hover-btn">
                &nbsp;<span className="glyphicon glyphicon-duplicate" aria-hidden="true"/>
              </Button>
            </CopyToClipboard>
          </td>
          <td  className={'text-center'}>{hide_for_premium ? 'Yes' : 'No'}</td>
          <td  className={'text-center'}>{hide_cta ? 'Yes' : 'No'}</td>
          <td>{getPlatforms({ platforms })}</td>
          <td>{getCountries({ countries })}</td>
          <td className={'text-center'}>
            <ColorPreviewBox
                hexColor={action_button_text_color}
                textDirection={'bottom'}
            />
          </td>
          <td className={'text-center'}>
            <ColorPreviewBox
                hexColor={action_button_background}
                textDirection={'bottom'}
            />
          </td>
          <td>{action_button_text}</td>
          <td className={'text-center'}><a target='_blank' href={action_url}>Click</a></td>
          <td className={'text-center'}>
            <ColorPreviewBox
                hexColor={close_button_color}
                textDirection={'bottom'}
            />
          </td>
          <td className={'text-center'}>{start}</td>
          <td className={'text-center'}>{end}</td>
          <td>
            <Link
                className='margin-all3 btn btn-primary btn-xs'
                to={{
                  pathname: '/timeline-promo-edit',
                  state: { existingTimelinePromo: timeline_promo }
                }}
            >
              <span className={'glyphicon glyphicon-edit'} aria-hidden='true'/>
              &nbsp;Edit
            </Link>
          </td>
        </tr>
    );
  });

  return (
      <div className='content__list_table_wrapper table-responsive '>
        {
          _.size(bulkDeleteMap) > 0 ?
              <Button
                  disabled={operationInProgress}
                  onClick={onDeleteTimelinePromo}
                  className='margin-all3 btn btn-danger btn-sm timeline-promos-delete-btn'
              >
                <span className={'glyphicon glyphicon-trash'} aria-hidden='true'/>
                &nbsp;{operationInProgress ? 'Deleting...' : 'Delete Selected'}
              </Button>
              : null
        }
        <table className='table small time-line-promo-table'>
          <thead>
          <tr>
            <th className={'text-center'}>#</th>
            <th>Preview</th>
            <th className={'text-center'}>Name</th>
            <th className={'text-center'}>Id</th>
            <th className={'text-center'}>Hide for Premium</th>
            <th className={'text-center'}>Hide CTA</th>
            <th>Platforms</th>
            <th>Countries</th>
            <th className={'text-center'}>AC Btn Text Color</th>
            <th className={'text-center'}>AC Btn BG Color</th>
            <th className={'text-center'}>AC Btn Text</th>
            <th className={'text-center'}>AC Btn URL</th>
            <th className={'text-center'}>Close Btn Color</th>
            <th className={'text-center'}>Start Date</th>
            <th className={'text-center'}>End Date</th>
            <th/>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </table>
      </div>
  );
};

export default TimelinePromosList;
