import {
  actionTypes,
} from './actionTypes';

const updateRawReducer = (state, { type , payload }) => {
  switch (type) {
    case actionTypes.SRJ_UPDATE_PROP_VAL:
      const {
        prop,
        value,
      } = payload;

      return {
        ...state,
        selectedFile: '',
        [prop]: value,
        showJSONUpdater: false,
      };

    case actionTypes.SRJ_FETCH_FILE:
      return {
        ...state,
        fetchFile: true,
        showJSONUpdater: true,
      };

    default:
      return state;
  }
};

const TsUpdateInitialState = {
  fileData: null,
  selectedFetchType: '',
  selectedFile: '',
  selectedTimestampLocationType: '',
  timestampLocationSelect: '',
  timestampLocationManual: '',
  showJSONUpdater: false,
};

const updateTimestampsReducer = (state, { type , payload }) => {
  switch (type) {
    case actionTypes.SUT_UPDATE_PROP_VAL:
      const { prop, value } = payload;

      if(prop === 'selectedFetchType') {
        return {
          ...state,
          selectedFile: '',
          selectedTimestampLocationType: '',
          timestampLocationSelect: '',
          timestampLocationManual: '',
          [prop]: value,
        };
      }

      if(prop === 'selectedFile') {
        return {
          ...state,
          selectedTimestampLocationType: '',
          timestampLocationSelect: '',
          timestampLocationManual: '',
          [prop]: value,
        };
      }

      if(prop === 'selectedTimestampLocationType') {
        return {
          ...state,
          timestampLocationSelect: '',
          timestampLocationManual: '',
          [prop]: value,
        };
      }

      if(prop === 'timestampLocationSelect') {
        return {
          ...state,
          timestampLocationManual: '',
          [prop]: value,
        };
      }

      if(prop === 'timestampLocationManual') {
        return {
          ...state,
          timestampLocationSelect: '',
          [prop]: value,
        };
      }

      return {
        ...state,
        [prop]: value,
      };

    case actionTypes.SUT_FETCH_FILE:
      return {
        ...state,
        fetchFile: true,
      };

    case actionTypes.SUT_RESET_STATE:
      console.log('SUT_RESET_STATE')
      return {
        ...TsUpdateInitialState,
      };

    default:
      return state;
  }
};


const KVUpdateInitialState = {
  fileData: null,
  selectedFetchType: '',
  selectedUpdateType: '',
  selectedFile: '',
  keyLocations: '',
  jsonValue: null,
};

const updateKeyValuesReducer = (state, { type , payload }) => {
  switch (type) {
    case actionTypes.SUKV_UPDATE_PROP_VAL:
      const {
        prop,
        value,
      } = payload;

      if(prop === 'selectedFetchType') {
        return {
          ...state,
          selectedFile: '',
          keyLocations: '',
          selectedUpdateType: '',
          [prop]: value,
        };
      }

      if(prop === 'selectedFile') {
        return {
          ...state,
          keyLocations: '',
          selectedUpdateType: '',
          [prop]: value,
        };
      }

      return {
        ...state,
        [prop]: value,
      };

    case actionTypes.SUKV_FETCH_FILE:
      return {
        ...state,
        fetchFile: true,
      };

    case actionTypes.SUKV_RESET_STATE:
      return {
        ...KVUpdateInitialState,
      }

    default:
      return state;
  }
};

const listFileVersionsReducer = (state, { type , payload }) => {
  switch (type) {
    case actionTypes.SLF_UPDATE_PROP_VAL:
      const { prop, value } = payload;

      return {
        ...state,
        selectedFile: '',
        [prop]: value,
        showVersionLister: false,
      };

    case actionTypes.SLF_FETCH_VERSIONS:
      return {
        ...state,
        fetchFile: true,
        showVersionLister: true,
      };

    default:
      return state;
  }
};

const TSAInitialState = {
  version: '',
  platform: '',
  flushCache: false,
  premiumStatus: '',
  language: '',
  countryCode: '',
};

const testSettingsAPIReducer = (state, { type , payload }) => {
  switch (type) {
    case actionTypes.STA_UPDATE_PROP_VAL:
      const {
        prop,
        value,
      } = payload;

      return {
        ...state,
        [prop]: value,
      };

    case actionTypes.STA_RESET_STATE:
      return {
        ...TSAInitialState,
      };

    default:
      return state;
  }
};

export {
  TSAInitialState,
  updateRawReducer,
  KVUpdateInitialState,
  TsUpdateInitialState,
  testSettingsAPIReducer,
  updateKeyValuesReducer,
  updateTimestampsReducer,
  listFileVersionsReducer,
};
