const RedisSectionOptions = {
  'all': 'All',
  'default': 'Default',
  'server': 'Server',
  'clients': 'Clients',
  'memory': 'Memory',
  'persistence': 'Persistence',
  'stats': 'Stats',
  'replication': 'Replication',
  'cpu': 'CPU',
  'commandstats': 'Commandstats',
  'cluster': 'Cluster',
  'keyspace': 'Keyspace',
};

const APIV2RedisFlushTypes = {
  'manual': 'Manual',
  'select': 'Select',
};

const APIV2RedisFlushKeys = {
  'Settings - All': { mainKey: 'settings', subKey: null},
  'Settings - Zakat Currency': { mainKey: 'settings', subKey: 'zakat_cached_currencies'},
  'Settings - Zakat Gold':  { mainKey: 'settings', subKey: 'zakat_cached_gold'},
  'Settings - Zakat Silver':  { mainKey: 'settings', subKey: 'zakat_cached_silver'},
  'Settings - Quran Quotes':  { mainKey: 'settings', subKey: 'quran_quotes.json'},
  'Settings - Backgrounds':  { mainKey: 'settings', subKey: 'backgrounds.json'},
  'Settings - Translations':  { mainKey: 'settings', subKey: 'translations.json'},
  'Settings - Did you know':  { mainKey: 'settings', subKey: 'didyouknow.json'},
  'Settings - Stickers':  { mainKey: 'settings', subKey: 'stickers.json'},
  'Settings - Auto Settings':  { mainKey: 'settings', subKey: 'autosettings.json'},
  'Settings - Recitations':  { mainKey: 'settings', subKey: 'recitations.json'},
  'Settings - Dua Recitations':  { mainKey: 'settings', subKey: 'dua_recitations.json'},
  'Settings - Adhans':  { mainKey: 'settings', subKey: 'adhans.json'},
  'Settings - Settings Legacy':  { mainKey: 'settings', subKey: 'settings_legacy.json'},
  'Settings - Nisab':  { mainKey: 'settings', subKey: 'nisab.json'},
  'Settings - Dhikrs':  { mainKey: 'settings', subKey: 'dhikrs.json'},
  'Settings - TnC':  { mainKey: 'settings', subKey: 'terms_and_privacy.json'},
};

const CFRedisFlushKeys = {
  'All': { mainKey: 'all', subKey: null},
  'Content V2 - Get API': { mainKey: 'content-v2-get', subKey: null},
  'Content V2 - List API': { mainKey: 'content-v2-list', subKey: null},
  'Content V2 - List Featured API': { mainKey: 'content-v2-list-featured', subKey: null},
  'Content V2 - Hash Tags': { mainKey: 'content-v2-hash-tags', subKey: null},
  'Content V2 - Featured Order': { mainKey: 'content-v2-list-featured-positions', subKey: null},
  'Content V2 - Website Get API': { mainKey: 'content-for-web-cache-id', subKey: null},
  'Content V2 - Website (Media releases)': { mainKey: 'content-for-web-cache-idmedia-releases', subKey: null},
  'Mecca Live': { mainKey: 'mecca-live-cache-id', subKey: null},
  'QPL - Featured': { mainKey: 'quran-playlist-featured-cache-id', subKey: null},
  'QPL - Featured Debug': { mainKey: 'quran-playlist-featured-cache-id-debug', subKey: null},
  'QPL - Cover Image - AR': { mainKey: 'quran-playlist-cover-image-ar-cache-id', subKey: null},
  'QPL - Cover Image - MS': { mainKey: 'quran-playlist-cover-image-ms-cache-id', subKey: null},
  'QPL - Cover Image - ID': { mainKey: 'quran-playlist-cover-image-id-cache-id', subKey: null},
  'QPL - Cover Image - EN': { mainKey: 'quran-playlist-cover-image-en-cache-id', subKey: null},
  'QPL - Cover Image - FR': { mainKey: 'quran-playlist-cover-image-fr-cache-id', subKey: null},
  'Rewards': { mainKey: 'rewards', subKey: null},
};

const RedisCaches = {
  'CloudFunctions': 'Cloud Functions',
  'API_V2': 'API V2',
};

export {
  RedisSectionOptions,
  APIV2RedisFlushTypes,
  APIV2RedisFlushKeys,
  CFRedisFlushKeys,
  RedisCaches,
};


