const MEDIA_TYPE = Object.freeze({
  MOVIE: 'movie',
  SERIES: 'series',
  EPISODE: 'episode',
});
const DISPLAY_CATEGORY_TYPE = Object.freeze({
  BROWSE: 'browse',
  ISLAM: 'islam',
  LIFESTYLE: 'lifestyle',
  FILMS: 'films',
  SERIES: 'series',
  KIDS: 'kids'
});
const SUPPORTED_IMAGE_TYPE = Object.freeze({
  THUMBNAIL: 'thumbnail',
  PREVIEW: 'preview',
  FEATURED: 'featured',
  TITLE: 'title',
});
const SUPPORTED_IMAGE_TYPE_V2 = Object.freeze({
  THUMBNAIL: 'thumbnail',
  PREVIEW: 'preview',
  TITLE: 'title',
});
const SUPPORTED_IMAGE_SIZE = Object.freeze({
  LARGE: 'large',
  SMALL: 'small',
});
const SUPPORTED_MEDIA_TYPE = Object.freeze({
  HLS: 'HLS',
  DASH: 'MPEG-DASH',
});

const ALLOWED_MATURITY_RATINGS = [
  'G',
  'PG',
  'PG-13',
  'NC-16',
  'M-18',
  'R-21',
];
const ALLOWED_MAX_QUALITIES = [
  'HD',
  '4K',
];
const ALLOWED_ENCODERS = [
  'aws-media-converter',
];
const ENCODING_STATUS = Object.freeze({
  P: 'processing',
  S: 'success',
  E: 'error'
});

const SUPPORTED_LANGUAGES = ['en', 'fr', 'ms', 'id', 'ar', 'de'];
const DEFAULT_SUPPORTED_LANGUAGE = 'en';
const SPECIAL_HEADERS_COUNT = 2

const URL_REGEX =
  new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.)" +
    "{1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");

const CDN_URL_REGEX = Object.freeze({
  HLS: /^(https:\/\/cdn.media.qalbox.com\/)\b([-a-zA-Z0-9@:%_+.~#?&/=]*)(.m3u8)$/gm,
  DASH: /^(https:\/\/cdn.media.qalbox.com\/)\b([-a-zA-Z0-9@:%_+.~#?&/=]*)(.mpd)$/gm,
});

const IMAGES_DIRECTORY_PREFIX  = 'images/qalbox';

const QALBOX_SUPPORTED_COUNTRY_CODES = Object.freeze({
  "SG": "Singapore",
  "MY": "Malaysia",
  "ID": "Indonesia",
  "AD": "Andorra",
  "AE": "United Arab Emirates",
  "AF": "Afghanistan",
  "AG": "Antigua and Barbuda",
  "AI": "Anguilla",
  "AL": "Albania",
  "AM": "Armenia",
  "AO": "Angola",
  "AQ": "Antarctica",
  "AR": "Argentina",
  "AS": "American Samoa",
  "AT": "Austria",
  "AU": "Australia",
  "AW": "Aruba",
  "AX": "Aland Islands",
  "AZ": "Azerbaijan",
  "BA": "Bosnia and Herzegovina",
  "BB": "Barbados",
  "BD": "Bangladesh",
  "BE": "Belgium",
  "BF": "Burkina Faso",
  "BG": "Bulgaria",
  "BH": "Bahrain",
  "BI": "Burundi",
  "BJ": "Benin",
  "BL": "Saint Barthelemy",
  "BM": "Bermuda",
  "BN": "Brunei",
  "BO": "Bolivia",
  "BQ": "Bonaire, Saint Eustatius and Saba ",
  "BR": "Brazil",
  "BS": "Bahamas",
  "BT": "Bhutan",
  "BV": "Bouvet Island",
  "BW": "Botswana",
  "BY": "Belarus",
  "BZ": "Belize",
  "CA": "Canada",
  "CC": "Cocos Islands",
  "CD": "Democratic Republic of the Congo",
  "CF": "Central African Republic",
  "CG": "Republic of the Congo",
  "CH": "Switzerland",
  "CI": "Ivory Coast",
  "CK": "Cook Islands",
  "CL": "Chile",
  "CM": "Cameroon",
  "CN": "China",
  "CO": "Colombia",
  "CR": "Costa Rica",
  "CU": "Cuba",
  "CV": "Cape Verde",
  "CW": "Curacao",
  "CX": "Christmas Island",
  "CY": "Cyprus",
  "CZ": "Czech Republic",
  "DE": "Germany",
  "DJ": "Djibouti",
  "DK": "Denmark",
  "DM": "Dominica",
  "DO": "Dominican Republic",
  "DZ": "Algeria",
  "EC": "Ecuador",
  "EE": "Estonia",
  "EG": "Egypt",
  "EH": "Western Sahara",
  "ER": "Eritrea",
  "ES": "Spain",
  "ET": "Ethiopia",
  "FI": "Finland",
  "FJ": "Fiji",
  "FK": "Falkland Islands",
  "FM": "Micronesia",
  "FO": "Faroe Islands",
  "FR": "France",
  "GA": "Gabon",
  "GB": "United Kingdom",
  "GD": "Grenada",
  "GE": "Georgia",
  "GF": "French Guiana",
  "GG": "Guernsey",
  "GH": "Ghana",
  "GI": "Gibraltar",
  "GL": "Greenland",
  "GM": "Gambia",
  "GN": "Guinea",
  "GP": "Guadeloupe",
  "GQ": "Equatorial Guinea",
  "GR": "Greece",
  "GS": "South Georgia and the South Sandwich Islands",
  "GT": "Guatemala",
  "GU": "Guam",
  "GW": "Guinea-Bissau",
  "GY": "Guyana",
  "HK": "Hong Kong",
  "HM": "Heard Island and McDonald Islands",
  "HN": "Honduras",
  "HR": "Croatia",
  "HT": "Haiti",
  "HU": "Hungary",
  "IE": "Ireland",
  "IL": "Israel",
  "IM": "Isle of Man",
  "IN": "India",
  "IO": "British Indian Ocean Territory",
  "IQ": "Iraq",
  "IR": "Iran",
  "IS": "Iceland",
  "IT": "Italy",
  "JA": "Japan",
  "JE": "Jersey",
  "JM": "Jamaica",
  "JO": "Jordan",
  "JP": "Japan",
  "KE": "Kenya",
  "KG": "Kyrgyzstan",
  "KH": "Cambodia",
  "KI": "Kiribati",
  "KM": "Comoros",
  "KN": "Saint Kitts and Nevis",
  "KO": "South Korea",
  "KP": "North Korea",
  "KR": "South Korea",
  "KW": "Kuwait",
  "KY": "Cayman Islands",
  "KZ": "Kazakhstan",
  "LA": "Laos",
  "LB": "Lebanon",
  "LC": "Saint Lucia",
  "LI": "Liechtenstein",
  "LK": "Sri Lanka",
  "LR": "Liberia",
  "LS": "Lesotho",
  "LT": "Lithuania",
  "LU": "Luxembourg",
  "LV": "Latvia",
  "LY": "Libya",
  "MA": "Morocco",
  "MC": "Monaco",
  "MD": "Moldova",
  "ME": "Montenegro",
  "MF": "Saint Martin",
  "MG": "Madagascar",
  "MH": "Marshall Islands",
  "MK": "Macedonia",
  "ML": "Mali",
  "MM": "Myanmar",
  "MN": "Mongolia",
  "MO": "Macao",
  "MP": "Northern Mariana Islands",
  "MQ": "Martinique",
  "MR": "Mauritania",
  "MS": "Montserrat",
  "MT": "Malta",
  "MU": "Mauritius",
  "MV": "Maldives",
  "MW": "Malawi",
  "MX": "Mexico",
  "MZ": "Mozambique",
  "NA": "Namibia",
  "NC": "New Caledonia",
  "NE": "Niger",
  "NF": "Norfolk Island",
  "NG": "Nigeria",
  "NI": "Nicaragua",
  "NL": "Netherlands",
  "NO": "Norway",
  "NP": "Nepal",
  "NR": "Nauru",
  "NU": "Niue",
  "NZ": "New Zealand",
  "OM": "Oman",
  "PA": "Panama",
  "PE": "Peru",
  "PF": "French Polynesia",
  "PG": "Papua New Guinea",
  "PH": "Philippines",
  "PK": "Pakistan",
  "PL": "Poland",
  "PM": "Saint Pierre and Miquelon",
  "PN": "Pitcairn",
  "PR": "Puerto Rico",
  "PS": "Palestinian Territory",
  "PT": "Portugal",
  "PW": "Palau",
  "PY": "Paraguay",
  "QA": "Qatar",
  "RE": "Reunion",
  "RO": "Romania",
  "RS": "Serbia",
  "RU": "Russia",
  "RW": "Rwanda",
  "SA": "Saudi Arabia",
  "SB": "Solomon Islands",
  "SC": "Seychelles",
  "SD": "Sudan",
  "SE": "Sweden",
  "SH": "Saint Helena",
  "SI": "Slovenia",
  "SJ": "Svalbard and Jan Mayen",
  "SK": "Slovakia",
  "SL": "Sierra Leone",
  "SM": "San Marino",
  "SN": "Senegal",
  "SO": "Somalia",
  "SR": "Suriname",
  "SS": "South Sudan",
  "ST": "Sao Tome and Principe",
  "SV": "El Salvador",
  "SX": "Sint Maarten",
  "SY": "Syria",
  "SZ": "Swaziland",
  "TC": "Turks and Caicos Islands",
  "TD": "Chad",
  "TF": "French Southern Territories",
  "TG": "Togo",
  "TH": "Thailand",
  "TJ": "Tajikistan",
  "TK": "Tokelau",
  "TL": "East Timor",
  "TM": "Turkmenistan",
  "TN": "Tunisia",
  "TO": "Tonga",
  "TR": "Turkey",
  "TT": "Trinidad and Tobago",
  "TV": "Tuvalu",
  "TW": "Taiwan",
  "TZ": "Tanzania",
  "UA": "Ukraine",
  "UG": "Uganda",
  "UM": "United States Minor Outlying Islands",
  "US": "United States",
  "UY": "Uruguay",
  "UZ": "Uzbekistan",
  "VA": "Vatican",
  "VC": "Saint Vincent and the Grenadines",
  "VE": "Venezuela",
  "VG": "British Virgin Islands",
  "VI": "U.S. Virgin Islands",
  "VN": "Vietnam",
  "VU": "Vanuatu",
  "WF": "Wallis and Futuna",
  "WS": "Samoa",
  "XK": "Kosovo",
  "YE": "Yemen",
  "YT": "Mayotte",
  "ZA": "South Africa",
  "ZM": "Zambia",
  "ZW": "Zimbabwe"
});

const PLAY_RESTRICTIONS = [
  'NONE',
  'PREMIUM_USERS_ONLY',
];

const MEDIA_LABELS = [
  'WEEKLY',
  'NEW',
  'TOP10',
];

const MEDIA_LABELS_V2 = [
  { originalText: "WEEKLY", text: "WEEKLY" },
  { originalText: "NEW", text: "NEW" },
  { originalText: "TOP10", text: "TOP10" },
  { originalText: "LEARN", text: "LEARN" },
];

const URL_HLS = {
  type: SUPPORTED_MEDIA_TYPE.HLS,
  mimeType: "application/x-mpegURL",
  encoder: ALLOWED_ENCODERS[0],
};

const HLS_CONFIGS = {
  keySystems: {
    "com.apple.fps.1_0": {
      certificateUri:
        "https://fp-keyos.licensekeyserver.com/cert/07fbcb8b4cdad9d3c864b4a08585fcc5.der",
      licenceUrl: "https://fp-keyos.licensekeyserver.com/getkey",
    },
  },
};

const URL_DASH = {
  type: SUPPORTED_MEDIA_TYPE.DASH,
  mimeType: "application/dash+xml",
  encoder: ALLOWED_ENCODERS[0],
};

const DASH_CONFIGS = {
  keySystems: {
    "com.widevine.alpha": {
      certificateUri: "https://wv-keyos.licensekeyserver.com/",
    },
    "com.microsoft.playready": {
      certificateUri:
        "https://pr-keyos.licensekeyserver.com/core/rightsmanager.asmx",
    },
  },
};

const MEDIA_CDN_LOCATION = "https://cdn.media.qalbox.com/encoded/media/qalbox/";
const SHORTS_CDN_LOCATION = "https://cdn.short.qalbox.com/";
const HLS_FILE_NAME = "master_hls.m3u8";
const DASH_FILE_NAME = "master_dash.mpd";
const SHORTS_HLS_FILE_NAME = "manifest.m3u8";
const SHORTS_DASH_FILE_NAME = "manifest.mpd";

const DEFAULT_MEDIA_TYPE_SEARCH_FILTER = [ "all", "movie", "series", "episode", "live" ];
const DEFAULT_PREMIUM_SEARCH_FILTER = [ "all", "free", "premium" ];


export {
  URL_REGEX,
  MEDIA_TYPE,
  CDN_URL_REGEX,
  ALLOWED_ENCODERS,
  PLAY_RESTRICTIONS,
  SUPPORTED_LANGUAGES,
  SUPPORTED_IMAGE_TYPE,
  SUPPORTED_IMAGE_SIZE,
  SUPPORTED_MEDIA_TYPE,
  DISPLAY_CATEGORY_TYPE,
  SPECIAL_HEADERS_COUNT,
  ALLOWED_MAX_QUALITIES,
  IMAGES_DIRECTORY_PREFIX,
  ALLOWED_MATURITY_RATINGS,
  DEFAULT_SUPPORTED_LANGUAGE,
  QALBOX_SUPPORTED_COUNTRY_CODES,
  MEDIA_LABELS,
  SUPPORTED_IMAGE_TYPE_V2,
  ENCODING_STATUS,
  URL_DASH,
  URL_HLS,
  DASH_CONFIGS,
  HLS_CONFIGS,
  MEDIA_CDN_LOCATION,
  HLS_FILE_NAME,
  DASH_FILE_NAME,
  MEDIA_LABELS_V2,
  SHORTS_CDN_LOCATION,
  SHORTS_HLS_FILE_NAME,
  SHORTS_DASH_FILE_NAME,
  DEFAULT_MEDIA_TYPE_SEARCH_FILTER,
  DEFAULT_PREMIUM_SEARCH_FILTER
}
