import LoadingOverlay from "react-loading-overlay";
import { useTimelineCacheClear } from "./hooks/useTimelineCacheClear";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import { ScaleLoader } from "react-spinners";

export const QalboxTimelineClearCacheButton = () => {
  const [clearCache, loading, error, success] = useTimelineCacheClear();

  const handleTimelineCacheClear = () => {
    clearCache();
  };

  useEffect(() => {
    if (error) {
      toast.error("Error occured while deleting timeline cache", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    if (success) {
      toast.success("Timeline cache clear is completed !!!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [error, success]);

  return (
      <LoadingOverlay
        active={loading}
        spinner={<ScaleLoader />}
        text="Loading...."
      >
        <ToastContainer autoClose={2000} />
        <button className="toolbar-button delete redis" onClick={handleTimelineCacheClear}>Clear Timeline Cache</button>
      </LoadingOverlay>
  );
};
