import _ from 'lodash';
import React,
{
  Component,
} from 'react';
import {
  Link,
} from 'react-router-dom';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  HAJJ_AND_UMRAH_TYPE,
  SUPPORTED_LANGUAGES,
} from './Constants';
import ImageZoom from 'react-medium-image-zoom';
import AppModal from '../../widgets/modal';
import './style.css';
import {
  ScaleLoader,
} from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import {
  hajjAndUmrahDelete,
  hajjAndUmrahGetRawJSON,
} from '../../services/mp-service-v1-api-service';
import {
  getDisplayDate,
} from '../../utils';
import {
  LoadingItems,
  ResultsCount,
  LastUpdatedTime,
} from '../../widgets/common';
import {
  CopyToClipboard,
} from 'react-copy-to-clipboard';

const types = [
  {
    key: 'all',
    value: 'All',
  },
];
Object.keys(HAJJ_AND_UMRAH_TYPE).map((type) => {
  return types.push({
    key: HAJJ_AND_UMRAH_TYPE[type],
    value: type
  });
});

const languages = [
  {
    key: 'all',
    value: 'All',
  },
];
Object.keys(SUPPORTED_LANGUAGES).map((languageCode) => {
  return languages.push({
    key: SUPPORTED_LANGUAGES[languageCode],
    value: languageCode
  })
});


const getFilteredContents = (contents, filter) => {
  const filteredContents = [];
  Object.keys(contents).forEach(language => {
    const languageContents = Object.values(contents[language]);
    const filteredData = _.filter(languageContents, filter);
    filteredContents.push(...filteredData);
  });

  return filteredContents;
};

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      deleting: false,
      contents: {},
      filter: {},
      error: null,
      lastUpdateTime: null,
      showBulkDeleteModal: false,
      bulkDeleteMap: {},
    };
  }

  componentDidMount() {
    this.setState({
          loading: true,
        },
        () => {
      this.fetchContents().then(() => console.log('Data fetch success!'));
    });
  }

  async fetchContents(filter) {
    try {
      const apiResponse = await hajjAndUmrahGetRawJSON(filter);
      const response = apiResponse.data;

      if(response.success) {
        const contentsData = response.data;
        this.setState({
          loading: false,
          contents: contentsData.contents,
          lastUpdateTime: contentsData.timestamp * 1000,
        })
      } else {
        this.setState({
          error: 'Something went wrong while fetching contents.',
          loading: false,
        })
      }
    } catch (err) {
      console.log(`Error occured while fetching data. Error : ${err}`);
      this.setState({
        error: 'Something went wrong while fetching contents.',
        loading: false,
      })
    }
  }

  onSelectChange(selectKey, e) {
    let {
      filter,
    } = this.state;
    const value = e.target.value;
    if(value === 'all') {
      delete filter[selectKey];
    } else {
      filter[selectKey] = value;
    }

    this.setState({
      filter,
    });
  }

  getOptions(arr, selectKey, defaultVal = 'all') {
    const options = arr.map((item, i) => {
      return (
          <option value={item.key} key={i}>{item.value}</option>
      );
    });

    return (
        <select
            value={defaultVal}
            onChange={this.onSelectChange.bind(this, selectKey)}
            className='form-control'
        >
          {options}
        </select>
    )
  }

  renderFilterBar(filter) {
    const { language_code, type } = filter;
    const languageOptions = this.getOptions(languages, 'language_code', language_code);
    const typeOptions = this.getOptions(types, 'type', type);

    return (
        <Row className='content__list_filterbar'>
          <Col md={3}>
            &nbsp;Language:
            {languageOptions}
          </Col>
          <Col md={3}>
            &nbsp;Type:
            {typeOptions}
          </Col>
        </Row>
    );
  }

  deleteContent(content) {
    const deleteMap = this.state.bulkDeleteMap;

    deleteMap[content.id] = content;

    this.setState({
      bulkDeleteMap: deleteMap,
      showBulkDeleteModal: true,
    });
  }

  onToggleBulkDelete(item, e) {
    const deleteMap = this.state.bulkDeleteMap;

    if(e.target.checked) {
      deleteMap[item.id] = item;
    } else {
      delete deleteMap[item.id];
    }

    this.setState({
      bulkDeleteMap: deleteMap,
    });
  }

  renderList(contents, bulkDeleteMap) {
    if(_.isEmpty(contents)) {
      return <p>Sorry. No contents found.</p>
    }

    const rows = contents.map((content, index) => {
      const {
        id, type, title, language_code, url, thumbnail_url, source, publish_start_date,
        publish_end_date, is_hidden
      } = content;
      return (
          <tr className={`content__list_table_row ${is_hidden ? 'hidden_item' : ''}`} key={index + 1}>
            <td className={'content-v2-index'}>
              <div className={'content-v2-index-number'}>{index + 1}</div>
              <span style={{ textAlign: 'center' }}>
                <input
                    className={'content__list_options_bar_input'}
                    type='checkbox'
                    checked={bulkDeleteMap[id] || false}
                    onChange={this.onToggleBulkDelete.bind(this, content)}
                />
              </span>
            </td>
            <td className={'text-center'}>
              <ImageZoom
                image={{
                  src: `${thumbnail_url}`,
                  style: { width: '64px' }
                }}
                zoomImage={{
                  src: `${thumbnail_url}`,
                }}
              />
            </td>
            <td className={'hajj-and-umrah-list-item-title'}>{title}</td>
            <td className={'text-center'}>
              <p className={'word-wrap-text text-center'}>{id}</p>
              <CopyToClipboard
                text={id}
                onCopy={() => {
                  console.log(`Copied id : ${id}`)
                }}
              >
                <Button className="btn btn-xs btn-dark mp-content-v2-table-hover-btn">
                  &nbsp;<span className="glyphicon glyphicon-duplicate" aria-hidden="true"/>
                </Button>
              </CopyToClipboard>
            </td>
            <td className={'text-center'}>{language_code}</td>
            <td className={'text-center'}>{type}</td>
            <td className={'text-center'}><a target='_blank' href={url}>Click</a></td>
            <td className={'text-center'}>{source}</td>
            <td className={'text-center hajj-and-umrah-list-item-date'}>
              {getDisplayDate(publish_start_date)}
            </td>
            <td className={'text-center hajj-and-umrah-list-item-date'}>
              {getDisplayDate(publish_end_date)}
            </td>
            <td className={'text-center'}>
              <button
                  onClick={this.deleteContent.bind(this, content)}
                  className='margin-all3 btn btn-danger btn-xs'
              >
                <span className={'glyphicon glyphicon-trash'} aria-hidden='true'/>
                &nbsp;Delete
              </button>
              <Link
                  className='margin-all3 btn btn-primary btn-xs'
                  to={{
                    pathname: '/hajj-and-umrah-edit',
                    state: { content: content }
                  }}
              >
                <span className={'glyphicon glyphicon-edit'} aria-hidden='true'/>
                &nbsp;Edit
              </Link>
            </td>
          </tr>
      );
    });

    return (
        <div className='content__list_table_wrapper table-responsive '>
          <table className='hajj-and-umrah-table table small'>
            <thead>
            <tr>
              <th className={'text-center'}>#</th>
              <th className={'text-center'}>Preview</th>
              <th className={'text-center'}>Title</th>
              <th className={'text-center'}>Id</th>
              <th className={'text-center'}>Language</th>
              <th className={'text-center'}>Type</th>
              <th className={'text-center'}>URL</th>
              <th className={'text-center'}>Source</th>
              <th className={'text-center'}>Publish Start Date</th>
              <th className={'text-center'}>Publish End Date</th>
              <th/>
            </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
    );
  }

  getDeleteAllContentsInfoHeader(bulkDeleteMap) {
    return `Are you sure want to delete following ${_.size(bulkDeleteMap)} contents?`
  }

  getDeleteAllContentsInfo(bulkDeleteMap) {
    return (
        <table className='table table-sm table-striped table-hover table-responsive'>
          <thead className='thead-dark'>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>Language</th>
            <th scope='col'>Type</th>
            <th scope='col'>Title</th>
            <th scope='col'>Id</th>
          </tr>
          </thead>
          <tbody>
          {
            Object.values(bulkDeleteMap).map((item, index) => {
              return (
                  <tr key={index}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item.language_code}</td>
                    <td>{item.type}</td>
                    <td>{item.title}</td>
                    <td>{item.id}</td>
                  </tr>
              );
            })
          }
          </tbody>
        </table>
    );
  };

  handleBulkDeleteModalClose() {
    this.setState({
      showBulkDeleteModal: false,
      bulkDeleteMap: {},
    });
  }

  async deleteContents(contents) {
    return hajjAndUmrahDelete({
      contents,
    });
  }

  async handleBulkDeleteModalOk() {
    this.setState({
      showBulkDeleteModal: false,
      deleting: true,
    });

    try {
      const contentsToDelete = Object.values(this.state.bulkDeleteMap);
      const apiResponse = await this.deleteContents(contentsToDelete);
      const response = apiResponse.data;
      console.log(`response : ${JSON.stringify(response)}`);

      if(response.success) {
        toast.success('Contents deleted successfully!', {
          position: toast.POSITION.BOTTOM_LEFT
        });
        setTimeout(()=> {
          window.location.reload();
        }, 1000);
      } else {
        toast.error('Something went wrong while deleting bulk contents.', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }
      this.setState({ deleting: false });
    } catch (err) {
      console.log('Error while deleting the contents : ', err);
      this.setState({ deleting: false });
      toast.error('Something went wrong while deleting bulk contents.', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  }

  deleteAllContents() {
    this.setState({
      showBulkDeleteModal: true,
    });
  }

  render() {

    const {
      filter,
      loading,
      deleting,
      contents,
      lastUpdateTime,
      bulkDeleteMap,
      showBulkDeleteModal,
    } = this.state;

    const filteredContents = getFilteredContents(contents, filter);

    return (
        <LoadingOverlay
            active={deleting}
            spinner={<ScaleLoader />}
            text='Deleting the contents.'
        >
          <BasicLayout pagePermission='content_editor'>
            <AppModal
                showBody
                show={showBulkDeleteModal}
                headingText={this.getDeleteAllContentsInfoHeader(bulkDeleteMap)}
                modalBody={this.getDeleteAllContentsInfo(bulkDeleteMap)}
                closeButtonText={'Cancel'}
                okButtonText={'Delete'}
                handleClose={this.handleBulkDeleteModalClose.bind(this)}
                handleOk={this.handleBulkDeleteModalOk.bind(this)}
                modalBodyClassName={'bulk-delete-list-modal-body'}
            />
            <ToastContainer autoClose={2000} />
            <h3 className='content_editor_title'>{'Hajj and Umrah List'}</h3>
            <Row>
              <Col md={12}>
                <LastUpdatedTime lastUpdatedTimeStamp={lastUpdateTime}/>
                <ResultsCount results={filteredContents} />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                {
                  _.size(bulkDeleteMap) > 0 ?
                      <button onClick={this.deleteAllContents.bind(this, bulkDeleteMap)}
                              className='margin-all3 btn btn-danger btn-sm'>
                        <span className={'glyphicon glyphicon-trash'} aria-hidden='true'/>
                        &nbsp;Delete Selected
                      </button>
                      : null
                }
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {this.renderFilterBar(filter)}
              </Col>
              <Col md={12}>
                {
                  loading ?
                      <LoadingItems loading={loading}/>
                      : this.renderList(filteredContents, bulkDeleteMap)
                }
              </Col>
            </Row>
          </BasicLayout>
        </LoadingOverlay>
    );
  }
}

export default List;
