import _ from "lodash";
import { useEffect } from "react";
import { convertArrayToSelectListV3, convertToSelectObject } from "../../helper";
import QalboxMultiSelectDropdown from "../../media/helperComponents/qalboxMultiSelect";
import { qalboxGetMediaSeriesListHook } from "../../../../hooks";
import QalboxSelectDropdown from "../../media/helperComponents/qalboxSelect";
import { ScaleLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";

export const QalboxMediaSeriesDropdown = ({mediaSeries, handleMultiSelect, isOnlyDropDown = false, handleSelect}) => {
  const [responseMediaSeriesList, setMediaSeriesListRequestData] = qalboxGetMediaSeriesListHook();

  const requestMediaSeriesList = () => {
    setMediaSeriesListRequestData({
      searchConfig: {
        language_code: "en",
        active: true,
      },
    });
  };

  useEffect(() => {
    requestMediaSeriesList();
  }, []);

  return (
    <LoadingOverlay
      active={_.get(responseMediaSeriesList, "isLoading", false)}
      spinner={<ScaleLoader />}
      text="Loading...."
    >
      {!isOnlyDropDown ? (
        <div className="form-group">
          <label>Series</label>
          <QalboxMultiSelectDropdown
            onChange={handleMultiSelect}
            selectedOptions={mediaSeries}
            options={convertArrayToSelectListV3(
              _.get(responseMediaSeriesList, "data", [])
            )}
          />
        </div>
      ) : (
        <QalboxSelectDropdown
          name={"series_meta_series_id"}
          selectedOption={convertToSelectObject(mediaSeries)}
          options={convertArrayToSelectListV3(
            _.get(responseMediaSeriesList, "data", [])
          )}
          onChange={handleSelect}
        />
      )}
    </LoadingOverlay>
  );
};