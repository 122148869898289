import React,
{
  useState,
} from 'react';
import {
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  MPGCSUploadImages,
} from '../../widgets/upload-images';
import {
  ImageUploadInfoPanel,
} from './common';
import {
  getUploadableFiles,
  getSupportedLanguages,
} from './helper';
import {
  MPSelect,
} from '../../widgets/common';

const UploadImages = () => {
  const [ language, setLanguage ] = useState('');

  return (
    <BasicLayout pagePermission="moderate_precalc">
      <ToastContainer autoClose={2000}/>
      <Row>
        <Col md={12}>
          <h3 className='content_editor_title'>{'Upload Images'}</h3>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <div className="form-group">
            <label style={{'marginRight': '5px'}}>{'Language : '}</label>
            <MPSelect
              items={getSupportedLanguages()}
              selectedItem={language}
              onChangeSelect={setLanguage}
            />
          </div>
        </Col>
      </Row>
      <MPGCSUploadImages
        InfoPanel={ImageUploadInfoPanel}
        getUploadableFiles={files => getUploadableFiles(files, language)}
        disableUploadButton={!language}
        onUploadSuccess={() => setLanguage('')}
      />
    </BasicLayout>
  );
};

export default UploadImages;
