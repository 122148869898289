import React, { useEffect, useState } from 'react';
import { Button, Panel, Row, Col, FormControl, ControlLabel, Modal, Checkbox } from 'react-bootstrap';
import { skuDbUrl, platformIconMap, skuPlatforms, premiumListPositions } from './constants';
import BasicLayout from '../../layouts/basiclayout';
import ToggleButton from 'react-toggle-button';
import firebase from 'firebase/app';
import { Icon } from 'react-fa';
import JSONTreeWrapper from '../../widgets/jsonTree';
import CountryPicker from '../../widgets/countryPicker';
import { ToastContainer, toast } from 'react-toastify';
import {
  MPSelect, OperationStatus,
} from '../../widgets/common';
import './style.css';
import {
  updateSettingsFileTimestampsHook,
} from '../../hooks';
import {
  confirmAlert,
} from 'react-confirm-alert';
import AppModal from '../../widgets/modal';
require('firebase/firebase-functions');

const convertIntoFirebaseKey = (sku) => {
  return sku.split('.').join('_');
}

const getDBPrefix = (debug) => {
  return debug ? 'staging' : 'production';
};

const getSKUDefinitions = async (defRef) => {
  const defs = await defRef.once('value');
  const defsObj = defs.val();
  const result = [];
  Object.keys(defsObj).forEach((defId) => {
      const def = defsObj[defId];
      def.key = defId;
      result.push(def);
  });
  return result;
};

const getActiveInstances = async (instancesRef) => {
  const instances = await instancesRef.once('value');
  const instancesObj = instances.val();
  return instancesObj;
};

const Dropdown = (props) => {
  const { value, setValue, options } = props;
  return <MPSelect items={options} selectedItem={value} onChangeSelect={setValue} />;
};

const showSuccessToast = (message) => {
  toast.success(message, {
    position: toast.POSITION.BOTTOM_LEFT
  });
};

const DefComp = (props) => {
  const { def = {}, updateSKUDefs, setSelectedDefId, setShowUseModal, selectedDefIds, setSelectedDefIds, debugMode } = props;
  const { key, disabled, platform } = def;
  const indexOnSelected = selectedDefIds.indexOf(key);

  let app = firebase.app();
  const db = app.database(skuDbUrl);
  const disableRef = db.ref(`/${getDBPrefix(debugMode)}/definitions/${key}/disabled`);

  const handleDisableDef = async () => {
    const confirm = window.confirm(`Do you really want to disable ${key}?`);
    if(confirm) {
      await disableRef.set(true);
      updateSKUDefs();
      showSuccessToast(`SKU ${key} disabled`);
    }
  };

  const handleEnableDef = async () => {
    const confirm = window.confirm(`Do you really want to enable ${key}?`);
    if(confirm) {
      await disableRef.set(false);
      updateSKUDefs();
      showSuccessToast(`SKU ${key} enabled`);
    }
  };

  const useBtnClick = async () => {
    setSelectedDefId(key);
    setShowUseModal(true);
  };

  const duplicateBtnClick = async () => {
    const newKey = window.prompt('Enter a new SKU definition id:', key);
    if(newKey) {
      delete def.key;
      await db.ref(`/${getDBPrefix(debugMode)}/definitions/${newKey}`).set(def);
      updateSKUDefs();
      showSuccessToast('SKU definition duplicated');
    }
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.currentTarget.checked;
    let cloned = [...selectedDefIds];
    if(!isChecked) {
      cloned = cloned.filter((c) => { return c !== key});
    } else {
      cloned.push(key);
    }
    setSelectedDefIds(cloned);
  };

  const renderSelectedIndex = () => {
    if(indexOnSelected > -1) {
      return <code className="selected_index">{indexOnSelected+1}</code>;
    } else {
      return null;
    }
  }

  const disableToggleBtn = disabled ?
  <Icon title="Enable SKU definition" className="text-success def_act_btn" onClick={handleEnableDef} name='play-circle' /> :
  <Icon title="Disable SKU definition" className="text-danger def_act_btn" onClick={handleDisableDef} name='stop-circle' />;

  return <div className="def_comp row">
    <Col className="text-left" xs={2}><Checkbox onChange={handleCheckboxChange} />{renderSelectedIndex()}</Col>
    <Col className="def_name" xs={7}><Icon name={platformIconMap[platform]} />  {key}
    </Col>
    <Col className="text-right" xs={3}>
      <Icon title="Use SKU definition" className="def_act_btn" onClick={useBtnClick} name='check-circle'/>
      <Icon title="Duplicate SKU definition" className="def_act_btn" onClick={duplicateBtnClick} name='clone'/>
      {disableToggleBtn}
    </Col>
    <Col xs-offset={2} xs={10}><JSONTreeWrapper data={def} /></Col>
  </div>;
};

const DefUseModal = (props) => {
  const {
    defId, db, setShowUseModal, updateActiveInstances, showUseModal, defIds, debugMode, updateSettingsTimestamps,
  } = props;
  const [platform='android', setPlatform] = useState();
  const [position='premium_page', setPosition] = useState();
  const [countries=[], setCountries] = useState();
  const handleCountrySelect = (selectedCountries) => {
    const result = [];
    if(selectedCountries) {
      selectedCountries.forEach((selected) => {
        result.push(selected.value);
      });
    }
    setCountries(result);
  };

  const addMultipleInstances = async ({ db, platform, position, countryCode, defList, defId, defIds, debugMode }) => {
    let list = [defId];
    if(defIds.length) {
      list = defIds;
    }
    list.forEach((id) => {
      if(defList.indexOf(id) === -1) {
        defList.push(id);
      }
    });
    return await db.ref(`/${getDBPrefix(debugMode)}/active_instances/${platform}/${position}/${countryCode}`).set(defList);
  };

  const addToActiveInstances = async ({platform, position, countries, defId, defIds, debugMode }) => {
    const confirm = window.confirm("Do you really want to deploy the selected SKUs?");
    if(confirm) {
      if(platform) {
        countries.forEach(async (countryCode) => {
          const snap = await db.ref(`/${getDBPrefix(debugMode)}/active_instances/${platform}/${position}/${countryCode}`).once('value');
          const defList = snap.val() || [];
          await addMultipleInstances({ db, platform, position, countryCode, defList, defId, defIds, debugMode });
          updateActiveInstances();
          setShowUseModal(false);
          updateSettingsTimestamps();
        });
        showSuccessToast('New SKU(s) added to active instance');
      }
    }
  };

  const skusToBeUsed = defIds.length ? defIds.join(', ') : defId;

  return <Modal
    className="def_use_modal"
    size="sm"
    show={showUseModal}
    onHide={() => { setShowUseModal(false); }}
    aria-labelledby="example-modal-sizes-title-sm">
  <Modal.Header closeButton>
    <Modal.Title id="example-modal-sizes-title-sm">
      Use SKU Definition <span className="text-danger">{skusToBeUsed}</span>
    </Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Row>
      <Col xs={4}>
        <ControlLabel className="platform_control_label">Please select a platform</ControlLabel>
        <Dropdown value={platform} setValue={setPlatform} options={skuPlatforms}/>
      </Col>
      <Col xs={4}>
        <ControlLabel className="platform_control_label">Please select a position</ControlLabel>
        <Dropdown value={position} setValue={setPosition} options={premiumListPositions}/>
      </Col>
      <Col xs={4}>
        <CountryPicker onChange={handleCountrySelect} ignoreEU/>
      </Col>
    </Row>
  </Modal.Body>
  <Modal.Footer>
    <Button onClick={() => { addToActiveInstances({platform, position, countries, defId, defIds, debugMode}); }} bsStyle="primary">Deploy</Button>
    <Button onClick={() => { setShowUseModal(false); }} bsStyle="default">Cancel</Button>
  </Modal.Footer>
</Modal>;
};


const PremiumSKUManagerTool = (props) => {
  const { debugMode } = props;
  const [definitions=[], setDefinitions] = useState();
  const [activeInstances, setActiveInstances] = useState();
  const [selectedDefId, setSelectedDefId] = useState();
  const [selectedDefIds=[], setSelectedDefIds] = useState();
  const [showUseModal=false, setShowUseModal] = useState();
  const [testPlatform='android', setTestPlatform] = useState();
  const [testPosition='premium_page', setTestPosition] = useState();
  const [testCountryCode='SG', setTestCountryCode] = useState();
  const [testLanguageCode='en', setTestLanguageCode] = useState();
  const [testing=false, setTesting] = useState();
  const [testResult, setTestResult] = useState();
  let app = firebase.app();
  const db = app.database(skuDbUrl);
  const defRef = db.ref(`/${getDBPrefix(debugMode)}/definitions`);
  const instancesRef = db.ref(`/${getDBPrefix(debugMode)}/active_instances`);

  const [ settingsFileUpdateData, doUpdateSettingTs ] = updateSettingsFileTimestampsHook();
  const updatingTimeStamps = settingsFileUpdateData && settingsFileUpdateData.isUpdating;

  const updateSKUDefs = async () => {
    const defs = await getSKUDefinitions(defRef);
    setDefinitions(defs);
  };

  const updateActiveInstances = async () => {
    const instances = await getActiveInstances(instancesRef);
    setActiveInstances(instances);
  };

  useEffect(() => {
    setSelectedDefIds([]);
    updateSKUDefs();
    updateActiveInstances();
    setTestResult();
    setTesting();
  }, [debugMode]);

  const renderDefinitions = () => {
    if(!definitions) {
      return <p>No definitions found yet...</p>;
    } else {
      return definitions.map((def, i) => {
        return <DefComp
          index={i}
          key={i}
          def={def}
          updateSKUDefs={updateSKUDefs}
          setSelectedDefId={setSelectedDefId}
          setShowUseModal={setShowUseModal}
          selectedDefIds={selectedDefIds}
          setSelectedDefIds={setSelectedDefIds}
          debugMode={debugMode}
        />
      });
    }
  };

  const handleGenerateSKUMaps = async () => {
    const confirm = window.confirm('Do you really want to generate SKU maps again?');
    if(confirm) {
      const defsSnapshot = await db.ref(`/${getDBPrefix(debugMode)}/definitions`).once('value');
      const defsObj = defsSnapshot.val();
      const promises = [], consumableSKUList = [];
      const platforms = {};
      Object.keys(defsObj).forEach((defId) => {
          const def = defsObj[defId];
          const { type, sku, platform, duration_unit, recurring } = def;
          if(!platforms[platform]) {
              platforms[platform] = {};
          }
          if(!platforms[platform][type]) {
              platforms[platform][type] = [];
          }
          platforms[platform][type].push(sku);
          if(type) {
            const p0 = db.ref(`/${getDBPrefix(debugMode)}/sku_maps/by_sku/${convertIntoFirebaseKey(sku)}`).set(type);
            promises.push(p0);
            if(duration_unit && recurring === false && consumableSKUList.indexOf(sku)===-1) {
              consumableSKUList.push(sku);
            }
          } else {
            alert(`Please check for missing premium type for ${defId}`);
          }
      });
      Promise.all(promises);
      db.ref(`/${getDBPrefix(debugMode)}/sku_maps/consumable`).set(consumableSKUList);
      return await db.ref(`/${getDBPrefix(debugMode)}/sku_maps/by_platform`).set(platforms);
    }
  };

  const onTimestampUpdateComplete = operationData => {
    if(!operationData.isError) {
      toast.success(`Timestamps update success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const updateSettingsTimestamps = () => {
    doUpdateSettingTs({
      fileName: 'settings_api_base',
      updateConfig: {
        'updateTimestamps': true,
        'timestampLocations': [
          'all>timestamp',
          'all>configurable_apis>premium_skus_list>timestamp',
        ],
      },
      onOperationComplete: onTimestampUpdateComplete,
    });
  };

  const onClickUpdateSettingsTimestamps = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <AppModal
                show={true}
                showBody={false}
                modalBodyClassName={'precalcs-diff-merge-view'}
                headingText={'Are you sure want to update the settings.json timestamps ?'}
                closeButtonText={'No'}
                okButtonText={`Yes, Update`}
                handleClose={onClose}
                handleOk={() => {
                  onClose();
                  updateSettingsTimestamps();
                }}
            />
        );
      }
    });
  };

  const handleSingleActiveSKURemoval = async ({did, platform, countryCode, dkey, positionKey}) => {
    const confirm = window.confirm(`Do you really want to delete the ${dkey+1}. ${did} entry from ${platform} > ${positionKey} > ${countryCode} active instances?`);
    if(confirm) {
      const ref = db.ref(`/${getDBPrefix(debugMode)}/active_instances/${platform}/${positionKey}/${countryCode}/${dkey}`);
      await ref.remove();
      updateActiveInstances();
      showSuccessToast(`SKU ${dkey} removed from ${platform} > ${positionKey} > ${countryCode}`);
    }
  };

  const handleAllActiveSKURemoval = async ({platform, countryCode, positionKey}) => {
    const confirm = window.confirm(`Do you really want to delete all the entries from ${platform} > ${positionKey} > ${countryCode} active instances?`);
    if(confirm) {
      const ref = db.ref(`/${getDBPrefix(debugMode)}/active_instances/${platform}/${positionKey}/${countryCode}`);
      await ref.remove();
      updateActiveInstances();
      showSuccessToast(`All SKUs removed from ${platform} > ${positionKey} > ${countryCode}`);
    }
  };

  const renderActiveInstances = () => {
    if(!activeInstances) {
      return <p>Active instances not loaded yet.</p>;
    }
    const platforms = Object.keys(activeInstances);
    const panels = platforms.map((platform, pkey) => {
      const platformInstance = activeInstances[platform];
      const positionKeys = Object.keys(platformInstance);
      const positionsComp = positionKeys.map((positionKey) => {
        const countries = platformInstance[positionKey];
        const countryCodes = Object.keys(countries);
        const rows = countryCodes.map((countryCode, rkey) => {
        const defIdList = countries[countryCode];
        const defIdListComp = defIdList.map((did, dkey) => {
          return <li key={dkey}>{did} <Icon onClick={() => { handleSingleActiveSKURemoval({did, platform, countryCode, dkey, positionKey}); }} className="cursor-pointer text-muted" name="times"/></li>;
        });
          return <tr key={rkey} className="active_instance_table_row">
            <td>
              {countryCode}
            </td>
            <td>
              <ol>{defIdListComp}</ol>
            </td>
            <td className="text-right">
              <Icon onClick={() => { handleAllActiveSKURemoval({platform, countryCode, positionKey}); }} className="def_act_btn cursor-pointer text-muted" name="trash"/>
            </td>
          </tr>
        });
        return <div>
          <p className="position_title">{positionKey}</p>
          <table className="table small">
          <thead>
            <tr>
              <th>Countries</th>
              <th>SKUs</th>
              <th className="text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
        </div>;
      });



      return <div key={pkey}>
          <h4 className="sub_panel_title">All active SKU instances of {platform}</h4>
          {positionsComp}
      </div>;
    });
    return <div>{panels}</div>;
  };

  const handleTestCountryChange = (e) => {
    setTestCountryCode(e.target.value);
  };

  const handleTestLanguageChange = (e) => {
    setTestLanguageCode(e.target.value);
  };

  const handleTestBtnClick = async () => {
    setTesting(true);
    const PremiumSKUsList = firebase.functions().httpsCallable(`PremiumSKUsList${debugMode?'Debug':''}`);
    const result = await PremiumSKUsList({
      country_code: testCountryCode.toUpperCase(),
      platform: testPlatform,
      position: testPosition,
      language_code: testLanguageCode.toLowerCase()
    });
    setTesting(false);
    setTestResult(result.data);
  };

  const TestResult = () => {
    if(testing) {
      return <p>Calling the {`PremiumSKUsList${debugMode?'Debug':''}`} API</p>
    } else {
      if(!testResult) {
        return null;
      }
      showSuccessToast('API Response received');
      return <div>
        <PremiumPageSimulator data={testResult} />
        <p>The API response is below:</p>
        <JSONTreeWrapper data={testResult} />
      </div>;
    }
  };
  
  const PremiumPageSimulator = (props) => {
    const { payload } = props.data;
    const items = payload.map((item, i) => {
      const { title, description, attribution, selected } = item;
      const selectedClass = selected ? 'selected_sku' : '';
      const attrComp = attribution ? <p className="premium_page_attr">{attribution}</p> : null;
      return <div key={i}>
        {attrComp}
        <div className={`premium_page_sku ${selectedClass}`}>
          <strong>{title.toUpperCase()}</strong> - {description}
        </div>
      </div>;
    });
    return <div className="premium_page_simulator_wrapper">
      {items}
    </div>;
  };

  const handleUseSelectedClick = () => {
    setShowUseModal(true);
  };

  const renderUseSelectedButton = () => {
    if(!selectedDefIds.length) {
      return null;
    } else {
      return <Button onClick={handleUseSelectedClick} title="Use selected skus" className="btn-xs use_selected_btn">Deploy selected</Button>;
    }
  };

  if (updatingTimeStamps) {
    return (
        <OperationStatus
            operationInProgressText={'Updating settings.json timestamp values...'}
        />
    );
  }

  return (
    <div className="premium_sku_dashboard_wrapper">
        <div className="action_button_wrapper">
          <a className="btn action_button btn-xs btn-default" href={`https://console.firebase.google.com/u/0/project/muslim-pro-app/database/mp-premium-skus/data/${getDBPrefix(debugMode)}`} target="_blank">Open {getDBPrefix(debugMode)} RTDB</a>
          <Button onClick={handleGenerateSKUMaps} className="action_button btn-xs btn-default">Generate SKU Maps</Button>
          <Button
              onClick={onClickUpdateSettingsTimestamps}
              className="action_button btn-xs btn-default">
            Update Settings.json Timestamps
          </Button>
        </div>
        <hr />
        <Row>
          <Col md={6}>
              <Panel className="custom_panel">
                <h4 className="panel_title">Definitions</h4>
                {renderDefinitions()}
                {renderUseSelectedButton()}
                <DefUseModal
                  defId={selectedDefId}
                  db={db}
                  updateActiveInstances={updateActiveInstances}
                  showUseModal={showUseModal}
                  setShowUseModal={setShowUseModal}
                  defIds={selectedDefIds}
                  debugMode={debugMode}
                  updateSettingsTimestamps={updateSettingsTimestamps}
                />
              </Panel>
          </Col>
          <Col md={6}>
            <Panel className="custom_panel">
              <h4 className="panel_title">Active Instances</h4>
              {/* <Alert bsStyle='warning'>
                <p className="small text-danger text-justified">Making changes in the instances will require a timestamp update on <a target='_blank' href='/update-settings-raw-file'>settings.json</a> for PremiumSKUsList under configurable apis to reflect the change on users end.</p>
              </Alert> */}
              {renderActiveInstances()}
            </Panel>
          </Col>
          <Col md={6}></Col>
          <Col md={6}>
            <Panel className="test_panel">
              <h4 className="panel_title">Test List API</h4>
              <ControlLabel className="test_control_label">Platform</ControlLabel>
              <Dropdown value={testPlatform} setValue={setTestPlatform} options={skuPlatforms}/>
              <ControlLabel className="test_control_label">Position</ControlLabel>
              <Dropdown value={testPosition} setValue={setTestPosition} options={premiumListPositions}/>
              <ControlLabel className="test_control_label">Country Code</ControlLabel>
              <FormControl value={testCountryCode} onChange={handleTestCountryChange}  placeholder="Please type here"/>
              <ControlLabel className="test_control_label">Language Code</ControlLabel>
              <FormControl value={testLanguageCode} onChange={handleTestLanguageChange} placeholder="Please type here" />
              <br />
              <Button onClick={handleTestBtnClick} bsStyle="primary">Test now</Button>
              <div className="test_result_wrapper">
                <TestResult />
              </div>
            </Panel>
          </Col>
        </Row>
      </div>
  );
}

const PremiumSKUManager = () => {
  const [debugMode=true, setDebugMode] = useState();

  const handleDebugChange = () => {
    const newDebugMode = !debugMode;
    setDebugMode(newDebugMode);
    const message = newDebugMode ? 'Debug mode turned ON' : 'Debug mode turned OFF';
    showSuccessToast(message);
  };

  return <BasicLayout pagePermission="write_codes">
    <div className="debug_toggle_container">
      Debug mode <ToggleButton onToggle={handleDebugChange} value={debugMode} />
    </div>
    <PremiumSKUManagerTool debugMode={debugMode}/>
    <ToastContainer autoClose={2000} />
  </BasicLayout>;
};

export default PremiumSKUManager;
