import JSONTree from 'react-json-tree';
import React,
{
  Component,
} from 'react';

const theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#272822',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
};

class JSONTreeWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  toggleShowHide() {
    this.setState({
      show: !this.state.show
    });
  }

  render() {
    const { show } = this.state;
    const btnTitle = show ? <span>Hide json structure <span className="glyphicon glyphicon-triangle-top"></span></span> : <span>Show json structure <span className="glyphicon glyphicon-triangle-bottom"></span></span>;
    const tree = show ? <JSONTree data={this.props.data} theme={theme} invertTheme={false} /> : null;
    return <div>
      <p onClick={this.toggleShowHide.bind(this)} className="sidebar__nav_more json__tree_toggler">{btnTitle}</p>
      {tree}
    </div>;
  }
}

export default JSONTreeWrapper;
