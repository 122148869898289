import React from 'react';
import {
  countries,
} from '../services/utils';
import Select from 'react-select';

export default class CountryPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountries: null,
    };
  }

  componentWillMount() {
    if(this.props.selectedCountries) {
      const selectedCountries = [];
      this.props.selectedCountries.forEach((countryCode) => {
        selectedCountries.push({
          value: countryCode,
          label: countries[countryCode],
        });
      });
      this.setState({
        selectedCountries,
      });
    }
  }

  getCountryOptions() {
    if(this.props.ignoreEU) {
      delete countries['EU'];
    }
    const countryCodeArray = Object.keys(countries);
    return countryCodeArray.map((countryCode) => {
      return {
        value: countryCode,
        label: `${countryCode} -- ${countries[countryCode]}`,
      }
    });
  }

  onCountriesChange(countries) {
    let selectedCountries = [];
    if(countries.length === 0) {
      selectedCountries = null;
    } else {
      countries.forEach((countryCode) => {
        selectedCountries.push(countryCode);
      });
    }
    this.setState({
      selectedCountries
    });
    this.props.onChange(selectedCountries);
  }

  render() {
    return <div>
      {this.props.hideLabel ? null : <p><strong>Select a country </strong></p>}
      <Select
        value={this.state.selectedCountries}
        onChange={this.onCountriesChange.bind(this)}
        isSearchable={true} options={this.getCountryOptions()} isMulti/>
    </div>;
  }
}
