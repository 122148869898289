import React from 'react';
import {
  Col,
} from 'react-bootstrap';
import './style.css';
import {
  Icon,
} from 'react-fa';

const SectionHeader = (props) => {
    const {
      icon,
      title,
    } = props;
    const iconComp = icon ? <Icon name={icon} /> : null;
    return <Col className="section__header" md={12}>
      <h4>{iconComp} {title}</h4>
      <hr />
    </Col>
  };

  export default SectionHeader;
