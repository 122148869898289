import React, { useState, useEffect } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useFilters,
} from "react-table";
import "./style.css";
import QalboxTableToolbar from "./tool-bar";
import LoadingOverlay from "react-loading-overlay";
import { ScaleLoader } from "react-spinners";

const QalboxTable = ({ table }) => {
  const {
    columns,
    data,
    handleManifest,
    handleDelete,
    handleSync,
    handleProdSync,
    handleEdit,
    isLoading,
    tableError,
    isGlobalSearchOn = true,
    parentPageIndex = 0,
    parentPageSize = 10,
    setPageIndex = (index) => console.log(index),
  } = table;

  if (tableError) return "error";

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    state: { pageIndex, filters },
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: parentPageIndex, pageSize: parentPageSize },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const handleSelectRow = (row) => {
    if (selectedRows.includes(row)) {
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow !== row)
      );
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };

  const resetSelectedRows = () => {
    setSelectedRows([]);
  };

  const handleSyncAndResetSelection = (selectedFlatRows) => {
    handleSync(selectedFlatRows, resetSelectedRows);
  };

  const handleDeleteAndResetSelection = (selectedFlatRows) => {
    handleDelete(selectedFlatRows, resetSelectedRows);
  };

  const handleEditAndResetSelection = (selectedFlatRows) => {
    handleEdit(selectedFlatRows, resetSelectedRows);
  };

  const handleManifestDuplicate = (selectedFlatRows) => {
    handleManifest(selectedFlatRows, resetSelectedRows);
  };

  const handleProdSyncTable = (selectedFlatRows) => {
    handleProdSync(selectedFlatRows, resetSelectedRows);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);

    if (!selectAll) {
      setSelectedRows([...page]);
    } else {
      setSelectedRows([]);
    }
  };

  useEffect(() => {
    console.log(pageIndex);
    setPageIndex(pageIndex);
  }, [pageIndex]);

  return (
    <LoadingOverlay
      active={isLoading}
      spinner={<ScaleLoader />}
      text="Data is Loading"
    >
      <div>
        {isGlobalSearchOn && (
          <input
            type="text"
            placeholder="Search..."
            className="search-input"
            onChange={(e) => setGlobalFilter(e.target.value)}
            style={{ marginBottom: "10px" }}
          />
        )}

        <QalboxTableToolbar
          selectedRows={selectedRows}
          onSync={handleSync ? handleSyncAndResetSelection : null}
          onDelete={handleDelete ? handleDeleteAndResetSelection : null}
          onEdit={handleEdit ? handleEditAndResetSelection : null}
          onManifestDuplicate={handleManifest ? handleManifestDuplicate : null}
          onProdSync={handleProdSync ? handleProdSyncTable : null}
        />

        <div className="my-table-wrapper">
          <table {...getTableProps()} className="my-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  <th>
                    <input
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={selectAll}
                    />
                  </th>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="header-cell"
                    >
                      {column.render("Header")}
                      <div>
                        {column.defaultCanFilter ? column.render("Filter") : null}
                      </div>
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " ▼"
                            : " ▲"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    <td>
                      <input
                        type="checkbox"
                        onChange={() => handleSelectRow(row)}
                        checked={selectedRows.includes(row)}
                      />
                    </td>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} className="cell-content">
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <button
            className="pagination-button"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </button>
          <button
            className="pagination-button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"<"}
          </button>
          <button
            className="pagination-button"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {">"}
          </button>
          <button
            className="pagination-button"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>
          <span className="page-info">
            Page {pageIndex + 1} of {pageOptions.length}
          </span>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default QalboxTable;
