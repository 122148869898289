import {
  actionTypes,
} from './actionTypes';
import {countries as CountryList} from "../../services/utils";
const countryOptions = Object.keys(CountryList).map((countryCode) => {
  return {
    value: countryCode,
    label: CountryList[countryCode]
  }
});
const _ = require("lodash")

const timelinePromoCreateReducer = (state, { type , payload }) => {
  const { countrySelectPolicy } = payload;

  switch (type) {
    case actionTypes.TPC_RESET:
      return {
        ...payload,
        resetColors: true,
      };
    case actionTypes.TPC_UPDATE_PROP_VAL:
      const {
        prop,
        value,
      } = payload;
      return {
        ...state,
        [prop]: value,
      };
    case actionTypes.TPC_UPDATE_COUNTRIES:
      // data for Select tag
      const countryCodes = [];
      payload.countries.forEach((countryCode) => {
        countryCodes.push({ value: countryCode.value, label: countryCode.value});
      });

      let effectiveCountryCodes = [...countryCodes]
      // real-data
      if( countrySelectPolicy == "exclude" ) {
        effectiveCountryCodes = _.differenceWith(countryOptions, [{ value: 'ALL' }, ...countryCodes], (c1, c2) => {
          return c1.value == c2.value;
        }).map( ( item ) => ({ label: item.value , value: item.value }))
      }else if( countrySelectPolicy == "select" ) {
        effectiveCountryCodes = [...countryCodes]
      }

      return {
        ...state,
        selected_countries: countryCodes,
        countries: effectiveCountryCodes,
        countrySelectPolicy: countrySelectPolicy
      };
    case actionTypes.THC_UPDATE_AUDIENCES:
      const platforms = [];
      const selectionLength = payload.length;
      if (selectionLength === 0) {
        platforms.push({'value':'all','label':'ALL'});
      } else {
        payload.forEach((platform) => {
          if(selectionLength > 1 && platform.value === 'all') {
            // Do nothing.
          } else {
            console.log(platform)
            platforms.push(platform);
          }
        });
      }

      return {
        ...state,
        platforms,
      };
    case actionTypes.TPC_ON_UPLOAD_IMAGE:
      return {
        ...state,
        uploadedFile: payload,
      };
    case actionTypes.TPC_REMOVE_IMAGE:
      return {
        ...state,
        uploadedFile: null,
      };
    case actionTypes.TPC_TOGGLE_PROP_VAL:
      const {
        prop_item,
      } = payload;

      return {
        ...state,
        [prop_item]: !state[prop_item],
      };
    case actionTypes.TPC_TOGGLE_OPTIMISE_IMAGE:
      return {
        ...state,
        optimizeImages: payload,
      };
    default:
      return state;
  }
};

const timelinePromoListReducer = (state, { type , payload }) => {
  const {
    bulkDeleteMap,
  } = state;

  switch (type) {
    case actionTypes.TPL_UPDATE_DELETE:
      if(payload.checked) {
        bulkDeleteMap[payload.timelinePromo.id] = payload.timelinePromo;
      } else {
        delete bulkDeleteMap[payload.timelinePromo.id];
      }

      return {
        ...state,
        bulkDeleteMap: {
          ...bulkDeleteMap,
        },
      };

    default:
      return state;
  }
};

export {
  timelinePromoListReducer,
  timelinePromoCreateReducer,
}
