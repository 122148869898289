import React from 'react';
import {
  specialListColumns,
} from './columns';
import CommonList from './commonList';

const ListSpecial = () => {

  return (
      <CommonList
          title={'Content V2 Feature/Pinned/Hidden List'}
          initialFilters={
            [
              { id: 'featured', value: 'True' },
              { id: 'languageCode', value: 'en' },
            ]
          }
          columns={specialListColumns()}
      />
  );
}

export default ListSpecial;
