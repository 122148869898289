import _ from 'lodash';
import React,
{
  useState,
  useEffect,
} from 'react';
import BasicLayout from '../../layouts/basiclayout';
import {
  Row,
  Col,
  Form,
  Badge,
  Button,
  FormGroup,
  ListGroup,
  FormControl,
  ListGroupItem,
} from 'react-bootstrap';
import {
  MPInput,
  MPControlLabel,
} from '../../widgets/common';
import SectionHeader from '../../widgets/sectionheader';
import SectionLoader from '../../widgets/sectionloader';
import {
  addActionHistory,
  getEmailDashboardSenderAddresses,
  getEmailDashboardReplyToAddresses,
} from '../../services/admin-db-service';
import {
  sendBulkMail,
  getMailingLists,
  getEmailTemplates,
} from '../../services/mp-cf-api-service';
import {
  convertToRFCDateTime,
} from '../../utils';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  createLog,
} from '../../services/logs';
import Histories from './histories';
import './style.css';
import {
  mailGunListEmailTags,
} from '../../hooks';
import ReactTags from 'react-tag-autocomplete';
import {
  getEmailTags,
  getExistingTags,
} from './helper';
import {
  STO_REGEX,
  TZO_REGEX,
  HISTORY_PATH,
} from './Constants';

const SelectSender = (props) => {
  const [ senders, setSenders ] = useState();
  useEffect(() => {
    const getSenders = async () => {
      const list = await getEmailDashboardSenderAddresses({});
      setSenders(list);
    };
    getSenders();
  }, []);

  if(!senders) {
    return (<SectionLoader text="Please wait while we fetch senders addresses"/>);
  }
  const options = senders.map((item) => {
    const { handle, domain } = item;
    const address = `${handle}@${domain}`;
    return (<option key={address} value={address}>{address}</option>);
  });
  return (<select onChange={(e) => {props.onChange(e.target.value)}} className="form-control">
    <option value="">-- Please select one --</option>
    {options}
  </select>);
};

const SelectReplyTo = (props) => {
  const [replyTos, setReplyTos] = useState();
  useEffect(() => {
    const getReplyTos = async () => {
      const list = await getEmailDashboardReplyToAddresses({});
      setReplyTos(list);
    };
    getReplyTos();
  }, []);

  if(!replyTos) {
    return (<SectionLoader text="Please wait while we fetch reply to addresses"/>);
  }
  const options = replyTos.map((item) => {
    const { handle, domain } = item;
    const address = `${handle}@${domain}`;
    return (<option key={address} value={address}>{address}</option>);
  });
  return (<select onChange={(e) => {props.onChange(e.target.value)}} className="form-control">
    <option value="">-- Please select one --</option>
    {options}
  </select>);
};

const SelectEmailTemplate = (props) => {
  const [emailTemplate, setEmailTemplate] = useState();
  const [emailTemplateList, setEmailTemplateList] = useState();

  useEffect(() => {
    const getList = async () => {
      const resp = await getEmailTemplates();
      if(resp && resp.data && resp.data.success && resp.data.payload && resp.data.payload.templates) {
        setEmailTemplateList(resp.data.payload.templates);
      }
    };
    getList();
  }, []);

  const onChange = (template) => {
    setEmailTemplate(template);
    props.onChange(template);
  };

  if(!emailTemplateList) {
    return (<SectionLoader text="Please wait while we fetch email templates"/>);
  }

  const ListGroupItems = emailTemplateList.map((item) => {
    const { createdAt,  name, description } = item;
    return (<ListGroupItem
      onClick={() => {onChange(name)}}
      className={emailTemplate===name ? "active" : "inactive"}
      header={<div><p>{name}</p> <span className="small text-muted pull-right created_at">Created at {createdAt}</span></div>}>
      {description}
    </ListGroupItem>);
  });

  return (<ListGroup>
    {ListGroupItems}
  </ListGroup>);
};

const SelectMailingList = (props) => {
  const [mailingList, setMailingList] = useState();
  const [mailingLists, setMailingLists] = useState();

  useEffect(() => {
    const getList = async () => {
      const resp = await getMailingLists();
      if(resp && resp.data && resp.data.success && resp.data.payload && resp.data.payload.mailingLists) {
        setMailingLists(resp.data.payload.mailingLists);
      }
    };
    getList();
  }, []);

  const onChange = (mailingList) => {
    setMailingList(mailingList);
    props.onChange(mailingList);
  };

  if(!mailingLists) {
    return (<SectionLoader text="Please wait while we fetch the mailing lists"/>);
  }

  const listGroupItems = mailingLists.map((item) => {
    const { address, created_at, description, members_count, name } = item;
    return (<ListGroupItem
      onClick={() => {onChange(address)}}
      className={mailingList===address ? "active" : "inactive"}
      header={<div><p>{name} <Badge>{members_count}</Badge></p> <span className="small text-muted pull-right created_at">Created at {created_at}</span></div>}>
      <p>{description}</p>
    </ListGroupItem>);
  });

  return <ListGroup>
    {listGroupItems}
  </ListGroup>;
};

const AddTags = ({ tags, onChange }) => {
  const [ fetchEmailTagsData, doFetchEmailTags ] = mailGunListEmailTags();
  const tagsData = fetchEmailTagsData && fetchEmailTagsData.data;
  const isFetchingEmailTags = fetchEmailTagsData && fetchEmailTagsData.isFetching;
  const existingTags = getExistingTags(tagsData && tagsData.items);

  useEffect(() => {
    doFetchEmailTags({
      limit: 100,
    });
  }, []);

  const handleEmailTagsDelete = (i) => {
    tags.splice(i, 1);
    onChange(tags);
  };

  const handleEmailTagAddition = (newTag) => {
    const tagsIndex = tags.findIndex(tag => tag.name === newTag.name);
    if(tags.length < 3 && tagsIndex < 0) {
      onChange(tags.concat([{ tag: newTag.name, ...newTag }]));
    }
  };

  if(isFetchingEmailTags) {
    return (
      <SectionLoader text="Please wait while we fetch the email tags."/>
    );
  }

  return (
    <Row>
      <Col md={8}>
        <div className="form-group">
          <ReactTags
            allowNew
            minQueryLength={1}
            delimiterChars={[',', ' ']}
            placeholder={'Add Tags (Maximum of 3)'}
            tags={tags}
            suggestions={existingTags}
            handleDelete={handleEmailTagsDelete}
            handleAddition={handleEmailTagAddition}
          />
        </div>
      </Col>
    </Row>
  );
};

const SendOptimization = ({ onChange, value, regex }) => {

  const [error, setError] = useState('');

  const onChangeValue = val => {
    if(regex.test(val)) {
      setError('');
    } else {
      setError('Invalid value.');
    }

    onChange(val);
  };

  return (
    <Row>
      <Col md={6}>
        <MPInput
          value={value}
          placeHolder={'Add value'}
          onChangeValue={onChangeValue}
        />
        {
          error &&
          <p className='email__dashboard-error-text'>
              {error}
          </p>
        }
      </Col>
    </Row>
  );
};

const Schedule = (props) => {
  const [schedule, setSchedule] = useState();

  const onChange = (sch) => {
    setSchedule(sch);
    if(sch === 'now') {
      props.onScheduledAt();
    }
    props.onChange(sch);
  };

  const renderDateTimePicker = () => {
    if(!schedule || schedule !== 'later') {
      return null;
    } else {
      return (<Col md={6}><FormControl onChange={(e) => { props.onScheduledAt(e.target.value) }} type="datetime-local" placeholder="Schedule at"/></Col>);
    }
  };

  return (
    <Row>
      <Col md={6}>
        <select onChange={(e) => { onChange(e.target.value) }} className="form-control">
          <option value="">-- Please select one --</option>
          <option value="now">Now</option>
          <option value="later">Send later</option>
        </select>
      </Col>
      {renderDateTimePicker()}
    </Row>
  );
};

const initialState = {
  senderAddress: null,
  senderAddressAlias: 'Muslim Pro',
  emailTemplate: null,
  mailingList: null,
  schedule: null,
  scheduledAt: null,
  replyTo: null,
  subject: null,
  tags: [],
  sto: null,
  tzo: null,
};

const EmailDashboard = () => {
  const [state, setState] = useState(initialState);

  const {
    senderAddress, senderAddressAlias, emailTemplate, mailingList, schedule, scheduledAt, replyTo, subject,
    tags, sto, tzo,
  } = state;
  const isScheduleNotProperlySet = !schedule || (schedule==='later' && !scheduledAt);
  const sendButtonDisabled = !senderAddress || !emailTemplate || !mailingList || isScheduleNotProperlySet || !subject;

  const callSendAPI = async ({debug}) => {
    let user = localStorage.getItem('user');
    let userEmail;

    if(user) {
      user = JSON.parse(user);
      userEmail = user.email;
    }

    const recipient = debug ? userEmail : mailingList;
    const config = {
      from: senderAddress,
      to: recipient,
      subject,
      template: emailTemplate
    };

    if (senderAddressAlias) {
      config['from'] = `${senderAddressAlias} <${senderAddress}>`;
    }

    if(replyTo) {
      config["replyTo"] = replyTo;
    }

    if(schedule === 'later' && scheduledAt) {
      config["scheduledAt"] = convertToRFCDateTime(scheduledAt);
    }

    if(!_.isEmpty(tags)) {
      config["tags"] = getEmailTags(tags);
    }

    if(sto) {
      config["sto"] = sto;
    }

    if(tzo) {
      config["tzo"] = tzo;
    }

    const confirm = window.confirm(`Are you sure, you want to send ${emailTemplate} to the mailing list ${recipient}?`);
    if(confirm) {
      const resp = await sendBulkMail({config});
      if(resp.data && resp.data.success) {
        createLog('email_dashboard_send_email', config);
        addActionHistory({actionPath: HISTORY_PATH, history: {...config, sentBy: userEmail}});
        setState(initialState);
        toast.success('Email sent successfully', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      } else {
        console.log("Send Bulk Email API Response", resp);
        toast.error('Failed to send email. Please try again or contact developer', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }
    }
  };

  return (
    <BasicLayout pagePermission="write_users">
      <ToastContainer autoClose={2000} />
      <Row className="email__dashboard">
        <SectionHeader title="Send email" icon="envelope"/>
        <Col md={6}>
          <Form>
            <FormGroup>
              <MPControlLabel success={senderAddress}>Select sender address</MPControlLabel>
              <p className="field_info">User will see one of this @muslimpro.com email address as the email sender.</p>
              <SelectSender onChange={(sender) => { setState({...state, senderAddress: sender}); }}/>
            </FormGroup>
            <hr />
            <FormGroup>
              <MPControlLabel optional>Sender Alias</MPControlLabel>
              <FormControl
                  value={senderAddressAlias}
                  onChange={(e) => { setState({ ...state, senderAddressAlias: e.target.value }); }}
              />
            </FormGroup>
            <hr />
            <FormGroup>
              <MPControlLabel optional>Select reply to address</MPControlLabel>
              <p className="field_info">If user replies to the email, then we will receive the reply into this email address; only if you select one from the list.</p>
              <SelectReplyTo onChange={(reply) => { setState({...state, replyTo: reply}); }}/>
            </FormGroup>
            <hr />
            <FormGroup>
              <MPControlLabel success={subject}>Subject</MPControlLabel>
              <FormControl onChange={(e) => { setState({ ...state, subject: e.target.value }); }} />
            </FormGroup>
            <hr />
            <FormGroup>
              <MPControlLabel success={emailTemplate}>Select an email template</MPControlLabel>
              <p className="field_info">Please select the appropirate template you want to send to the users. If you are not sure, you can select the templates and send a test email to yourself to see how does it look. Test email will cost nothing.</p>
              <SelectEmailTemplate onChange={(template) => { setState({ ...state, emailTemplate: template }); }}/>
            </FormGroup>
            <hr />
            <FormGroup>
              <MPControlLabel success={mailingList}>Select a mailing list</MPControlLabel>
              <p className="field_info">Note that all the users of the mailing list will receive the email which you are about to send. Also mailgun will deduct same amount of email from the monthly quota or for extra email they will charge $0.80/1000 email.</p>
              <SelectMailingList onChange={(list) => { setState({ ...state, mailingList: list }); }}/>
            </FormGroup>
            <hr />
            <FormGroup>
              <MPControlLabel success={!_.isEmpty(tags)}>Add tags (Maximum of 3 - Optional)</MPControlLabel>
              <p className="field_info">Note that you can add only maximum of 3 tags and it is optional.</p>
              <AddTags
               tags={tags}
               onChange={(updatedTags) => setState({ ...state, tags: updatedTags })}
              />
            </FormGroup>
            <hr />
            <FormGroup>
              <MPControlLabel success={STO_REGEX.test(sto)}>Send Time Optimization (STO - Optional)</MPControlLabel>
              <p className="field_info">
                Note that value must be set to the number of hours in [0-9]+h format, with the
                minimum being 24h and the maximum being 72h.
              </p>
              <SendOptimization
                value={sto}
                regex={STO_REGEX}
                onChange={(updatedSto) => setState({ ...state, sto: updatedSto })}
              />
            </FormGroup>
            <hr />
            <FormGroup>
              <MPControlLabel success={TZO_REGEX.test(tzo)}>Timezone Optimization (TZO - Optional)</MPControlLabel>
              <p className="field_info">
                Note that value should be set to preferred delivery time in HH:mm or hh:mmaa format,
                where HH:mm is used for 24 hour format without AM/PM and hh:mmaa is used for 12 hour
                format with AM/PM.
              </p>
              <SendOptimization
                value={tzo}
                regex={TZO_REGEX}
                onChange={(updatedTzo) => setState({ ...state, tzo: updatedTzo })}
              />
            </FormGroup>
            <hr />
            <FormGroup>
              <MPControlLabel success={!isScheduleNotProperlySet}>Schedule</MPControlLabel>
              <p className="field_info">You can choose to send the email now or schedule a date time when the email will be send to the users.</p>
              <Schedule onChange={(sch) => { setState({ ...state, schedule: sch }); }} onScheduledAt={(value) => { setState({ ...state, scheduledAt: value }); }} />
            </FormGroup>
            <hr />
            <FormGroup>
              <Button onClick={()=> { callSendAPI({debug: false}); }} disabled={sendButtonDisabled} bsStyle="primary" className="send__button">Send</Button>
              <Button onClick={()=> { callSendAPI({debug: true}); }} disabled={sendButtonDisabled} bsStyle="info" className="send__button">Send test email</Button>
            </FormGroup>
          </Form>
        </Col>
        <Col md={6}>
          <Histories />
        </Col>
      </Row>
    </BasicLayout>
  );
};

export default EmailDashboard;
