import React from 'react';
import './tool-bar.css'

const QalboxTableToolbar = ({ selectedRows, onDelete, onSync, onEdit, onManifestDuplicate, onProdSync }) => {
  return (
    <div className="table-toolbar">
      {onEdit &&
        <button className="toolbar-button" onClick={() => onEdit(selectedRows)} disabled={selectedRows.length !== 1}>
          Edit
        </button>
      }
      {onDelete &&
        <button className="toolbar-button delete" onClick={() => onDelete(selectedRows)} disabled={selectedRows.length === 0}>
          Delete
        </button>
      }
      {onSync &&
        <button className="toolbar-button" onClick={() => onSync(selectedRows)} disabled={selectedRows.length === 0}>
          Sync
        </button>
      }
      {onManifestDuplicate &&
        <button className="toolbar-button manifest" onClick={() => onManifestDuplicate(selectedRows)} disabled={selectedRows.length !== 1}>
          Duplicate Manifest
        </button>
      }
      {onProdSync &&
        <button className="toolbar-button prodsync" onClick={() => onProdSync(selectedRows)} disabled={selectedRows.length === 0}>
          Sync to Production
        </button>
      }
    </div>
  );
};

export default QalboxTableToolbar;
