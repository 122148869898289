import { useState } from 'react';
import axiosService from '../../../../service_v2/common/axios-service';

export const useDeleteShort = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const deleteShort = async (uuid, callback) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axiosService.delete(
        `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}qalbox-admin-short-service/v1/delete/${uuid}`
      );

      if (!response?.data) {
        throw new Error(`Failed to delete short (status ${response?.status})`);
      }

      callback(true);
      return response?.statusText; // Return success status from the response
    } catch (err) {
      console.error(err);
      setError(err.message || 'An error occurred while deleting the short');
      return false; // Return false if an error occurs
    } finally {
      setIsLoading(false);
    }
  };

  return [ deleteShort, isLoading, error ];
};