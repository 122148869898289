import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  settingsUpdateFileTimestamp,
} from '../../services/mp-service-v1-api-service';

const updateSettingsFileTimestamps = () => {
  const [ settingsFileTsUpdateData, setSettingsFileTsUpdateData ] = useState({ });
  const [ updateData, setUpdateData] = useState(null);
  const {
    fileName,
    updateConfig,
    onOperationComplete,
  } = updateData || {};

  const updateSettingsFileTS = async () => {
    try {
      console.log(`Updating file: ${fileName}...`);
      setSettingsFileTsUpdateData({
        isUpdating: true,
        isError: false,
        updatedFileName: fileName,
      });
      const updateRawFileApiResp =  await settingsUpdateFileTimestamp({
        fileName,
        updateConfig,
      });

      const responseData = updateRawFileApiResp.data;
      let errorMsg = null;
      if(responseData.success) {
        console.log(`Updating timestamps of file: ${fileName} Success!!`);
      } else {
        console.log(`Updating timestamps of file: ${fileName} Failed!!`);
        errorMsg = `Error occurred while updating timestamps of file: ${fileName}.`;
      }

      setSettingsFileTsUpdateData({
        data: responseData.data,
        isUpdating: false,
        isError: !responseData.success,
        errorMsg,
        updatedFileName: fileName,
      });
      onOperationComplete({
        isError: !responseData.success,
        errorMsg,
      });
    } catch (err) {
      console.log(`Error while updating timestamps of file: ${fileName}. Error : ${err}`);
      const errorMsg = (err.response && err.response.status === 404) ?
          `File: ${fileName} not found!` :
          `Error occurred while updating timestamps of file: ${fileName}.`;
      setSettingsFileTsUpdateData({
        data: null,
        isUpdating: false,
        isError: true,
        errorMsg,
        updatedFileName: fileName,
      });
      onOperationComplete({
        isError: true,
        errorMsg,
      });
    }
  };

  useEffect( () => {
    if(!_.isEmpty(updateData)) {
      updateSettingsFileTS();
    }
  }, [ updateData ]);

  return [
    settingsFileTsUpdateData,
    setUpdateData,
  ];
};

export default updateSettingsFileTimestamps;
