const AUDIENCE_TYPE = Object.freeze({
  PREMIUM: 'premium',
  FREE: 'free',
  ALL: 'all',
});

const PLATFORMS = Object.freeze({
  all: 'ALL',
  android: 'ANDROID',
  ios: 'IOS',
});

const PREMIUM_TYPE = Object.freeze({
  LIFETIME: 'lifetime',
  YEARLY: 'yearly',
  MONTHLY: 'monthly',
});

const ENVIRONMENT = Object.freeze({
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEVELOPMENT: 'development',
});


const FIRESTORE_COLLECTIONS = Object.freeze({
  CONTENT_V2: 'data/~2FContentsV2~2F',
});

const RTDB_DATABASE = Object.freeze({
  USER_SHARD: (shardid) => `${process.env.REACT_APP_USER_SHARD_DB_PREFIX + String(shardid).padStart(2, '0')}/data`,
  MUSLIM_PRO_APP: `${process.env.REACT_APP_MUSLIM_PRO_APP_DB}/data`,
  PREMIUM_TRANSACTION: `${process.env.REACT_APP_PREMIUM_TRANSACTION_DB}/data`,
  REWARDS: `${process.env.REACT_REWARDS_DB}/data`,
});

const RTDB_DATABASE_NODE = Object.freeze({
  USERS: 'users',
  PROMO_CODE_MULTI_USAGE: 'promoCodesMultiUsage',
  USER_REWARDS: 'production/user-rewards',
});

/* MP Service API V1 URLs */
const HAJJ_AND_UMARAH_API_GET_RAW_URL = 'hajjandumrah/get-raw-json';
const HAJJ_AND_UMARAH_API_DELETE_URL = 'hajjandumrah/delete';
const HAJJ_AND_UMARAH_API_ADD_UPDATE_URL = 'hajjandumrah/add-or-update';
const MPAC_UPLAOD_FILES_TO_GCS_URL = 'mpac/upload-files-to-gcs';
const PRECALC_API_GET_RAW_PRECALC_LIST_URL = 'precalc/get-raw-precalc-list';
const PRECALC_API_GET_PRECALC_DATA_BY_LAT_LONG = 'precalc/preacls-by-coordinates';
const PRECALC_API_UPDATE_RAW_PRECALC_LIST_URL = 'precalc/update-raw-precalc-list';
const PRECALC_API_UPDATE_COUNTRY_RAW_PRECALC_LIST_URL = 'precalc/update-country-raw-precalc-list';
const PRECALC_API_VALIDATE_URL = 'precalc/validate';
const PRECALC_API_GENERATE_URL = 'precalc/generate';
const PRECALC_API_TEST_API_RESPONSE_URL = 'precalc/test-api-response';
const PRECALC_API_LIST_FILE_VERSIONS = 'precalc/list-precalc-file-versions';
const PRECALC_API_UPDATE_PRECALC_FILE = 'precalc/update-precalc-file';
const V2_REDIS_GET_INFO = 'redis/info';
const V2_REDIS_DELETE_KEY = 'redis/delete-key';
const TIMELINE_PROMOS_API_GET_RAW_URL = 'timelinepromos/get-raw-json';
const TIMELINE_PROMOS_API_DELETE_URL = 'timelinepromos/delete';
const TIMELINE_PROMOS_API_ADD_UPDATE_URL = 'timelinepromos/add-or-update';
const SETTINGS_API_GET_RAW_FILE = 'settings/get-raw-file';
const SETTINGS_API_GET_FILE_VERSIONS = 'settings/get-file-versions';
const SETTINGS_API_UPDATE_RAW_FILE = 'settings/update-raw-file';
const SETTINGS_API_UPDATE_FILE_TIMESTAMP = 'settings/update-raw-file-timestamps';
const SETTINGS_API_UPDATE_FILE_KEY_VALUES = 'settings/update-raw-file-key-values';
const SETTINGS_API_TEST_SETTINGS_API_RESPONSE = 'settings/test-api-response';
const HIJRI_API_GET_RAW_FILE = 'hijri/get-raw-json';
const HIJRI_API_UPDATE_RAW_FILE = 'hijri/update';
const HIJRI_API_GET_FILE_VERSIONS = 'hijri/get-file-versions';
const QURAN_API_GET_RAW_FILE = 'quran/get-raw-json';
const QURAN_API_UPDATE_RAW_FILE = 'quran/update-raw-json';
const BIGQUERY_API_GET_ORDERED_HASH_TAGS = 'bigquery/get-ordered-hash-tags';
const BIGQUERY_API_GET_USER_LOGS_ROW_COUNT = 'bigquery/get-user-logs-row-counts';
const BIGQUERY_API_DELETE_USER_LOGS = 'bigquery/delete-user-logs';
const BIGQUERY_API_GET_USER_LOGS = 'bigquery/get-user-logs';

/* MP CF API URLS */
const MP_CF_CONTENT_V2_GET_FOR_CONSOLE = 'ContentV2GetForConsole';
const MP_CF_CONTENT_V2_LIST_FOR_CONSOLE = 'ContentV2ListForConsole';
const MP_CF_CONTENT_V2_ADD = 'ContentV2Add';
const MP_CF_CONTENT_V2_UPDATE = 'ContentV2Update';
const MP_CF_CONTENT_V2_BULK_ADD_OR_UPDATE = 'ContentV2AddBulk';
const MP_CF_CONTENT_V2_BULK_DELETE = 'ContentV2DeleteBulk';
const MP_CF_CONTENT_V2_DELETE = 'ContentV2Delete';
const MP_CF_CONTENT_V2_REMOVE_PROPERTIES = 'ContentV2RemoveProperties';
const MP_CF_CONTENT_V2_UPDATE_HASHTAGS = 'ContentV2UpdateHashTags';
const MP_CF_CONTENT_V2_LIST_HASHTAGS_FOR_AC = 'ContentV2ListHashTagsForConsole';
const MP_CF_CONTENT_V2_SUGGEST_HASHTAGS_FOR_CONSOLE = 'ContentV2SuggestHashTagsForConsole';
const MP_CF_CONTENT_V2_GET_FAVORITES = 'ContentV2Favorites';
const MP_CF_CONTENT_GET_METADATA_URL = 'ContentGetMetadata';
const MP_CF_QURAN_PLAYTLISTS_LIST_FEATURED_FOR_AC_URL = 'QuranPlaylistListFeaturedForAC';
const MP_CF_QURAN_PLAYTLISTS_EDIT_FOR_AC_URL = 'QuranPlaylistEditForAC';
const MP_CF_QURAN_PLAYTLISTS_BULK_UPLOAD_FOR_AC_URL = 'QuranPlaylistBulkUploadForAC';
const MP_CF_GET_HIJRI_JSON_URL = 'GetHijriJSON';
const MP_CF_REDIS_FLUSH_CACHE_URL = 'RedisFlushCache';
const MP_CF_REDIS_GET_INFO_URL = 'RedisGetInfo';
const MP_CF_AC_ED_GET_TEMPLATES_URL = 'adminConsoleEDGetTemplates';
const MP_CF_AC_ED_GET_MAILING_LISTS_URL = 'adminConsoleEDGetMailingLists';
const MP_CF_AC_ED_SEND_BULK_MAIL_URL = 'adminConsoleEDSendBulkMail';
const MP_CF_VIRTUAL_TOURS_BULK_ADD_UPDATE = 'VirtualToursBulkAddUpdate';
const MP_CF_CONTENT_V2_TRENDING_HASHTAGS = 'ContentV2TrendingHashtags';
const MP_CF_MG_ADD_EMAIL_TAG = 'adminConsoleMGAddEmailTag';
const MP_CF_MG_DELETE_EMAIL_TAG = 'adminConsoleMGDeleteEmailTag';
const MP_CF_MG_LIST_EMAIL_TAGS = 'adminConsoleMGListEmailTags';
const MP_CF_AC_TRACK_USER = 'adminConsoleTrackUser';
const MP_CF_AC_DELETE_USER = 'adminConsoleDeleteUser';
const MP_CF_AC_REVALIDATE_PURCHASE = 'adminConsoleRevalidatePurchase';
const MP_CF_GET_USER = 'getUser';
const MP_CF_GET_SECRET_KEY = 'SecretKeyGet';
const MP_CF_QALBOX_MEDIA_ADD_OR_UPDATE = 'QalBox-media/addOrUpdate';
const MP_CF_QALBOX_MEDIA_SYNC_ELASTIC = 'QalBox-media/syncElastic';
const MP_CF_QALBOX_MEDIA_ENCODE = 'QalBox-EncodeMedia';
const MP_CF_QALBOX_MEDIA_ENCODE_UPDATE_MEDIA_ID = 'QalBox-EncodeMedia/updateMediaId';
const MP_CF_QALBOX_MEDIA_PURGE_CDN_CACHE = 'QalBox-EncodeMedia/purgeCDNContentByPath';
const MP_CF_QALBOX_MEDIA_DUPLICATE_MANIFEST = 'QalBox-EncodeMedia/duplicateManifestForCasting';
const MP_CF_QALBOX_MEDIA_GET = 'QalBox-media/get';
const MP_CF_QALBOX_SHORTS_GET = 'qalbox-admin-short-service/v1/get/all';
const MP_CF_QALBOX_ENCODE_MEDIA_GET = 'QalBox-EncodeMedia/get';
const MP_CF_QALBOX_MEDIA_SERIES_GET = 'QalBox-media/getSeriesList';
const MP_CF_QALBOX_MEDIA_SERIES_EPISODE_GET = 'QalBox-media/getEpisodeList';
const MP_CF_QALBOX_MEDIA_GET_SERIES_DETAILS = 'QalBox-media/getSeriesDetails';
const MP_CF_QALBOX_DISPLAY_CATEGORIES_ADD_OR_UPDATE = 'QalBox-displayCategories/addOrUpdate';
const MP_CF_QALBOX_DISPLAY_CATEGORIES_DELETE = 'QalBox-displayCategories/delete';
const MP_CF_QALBOX_SEARCH_MEDIA = 'QalBox-SearchMedia';
const MP_CF_QALBOX_DELETE_MEDIA = 'QalBox-media/delete';
const MP_CF_QALBOX_SEARCH_DISPLAY_CATEGORIES = 'qalbox-admin-display-category-service/v1/get/all';
const MP_CF_AC_SEND_VERIFICATION_EMAIL = 'adminConsoleSendEmail/emailVerification';
const MP_CF_AC_UPLOAD_IMAGE = 'adminConsoleUploadImage';
const MP_CF_CREATE_ADMIN_LOGS = 'createAdminLogs';
const MP_CF_QALBOX_SEARCH_MEDIA_TAG = 'QalBox-media/tag/list';
const MP_CF_QALBOX_CREATE_MEDIA_TAG = 'QalBox-media/tag/create';
const MP_CF_QALBOX_UPDATE_MEDIA_TAG = 'QalBox-media/tag/update';
const MP_CF_QALBOX_DELETE_MEDIA_TAG = 'QalBox-media/tag/delete';
const MP_CF_QALBOX_SEARCH_GENRE = 'QalBox-media/genre/list';
const MP_CF_QALBOX_CREATE_GENRE = 'QalBox-media/genre/create';
const MP_CF_QALBOX_UPDATE_GENRE = 'QalBox-media/genre/update';
const MP_CF_QALBOX_DELETE_GENRE = 'QalBox-media/genre/delete';

// USER ENDPOINT CF
const MP_CF_CONTENT_V2_ADMIN_QUERY_USER_DATA = 'adminConsoleQueryUserData';
const MP_CF_CONTENT_V2_ADMIN_UPDATE_USER_DATA = 'adminConsoleUpdateUserData';
const MP_CF_CONTENT_V2_ADMIN_SET_USER_DATA = 'adminConsoleSetUserData';
const MP_CF_CONTENT_V2_ADMIN_DELETE_USER_DATA = 'adminConsoleDeleteUserData';
const MP_CF_CONTENT_V2_ADMIN_CREATE_USER_DATA = 'adminConsoleCreateUserData';
const MP_CF_CONTENT_V2_ADMIN_GET_USER_SHARDID = 'adminConsoleGetUserShardId';

// Database URLs
const ADMIN_DATABASE_URL = process.env.REACT_APP_ADMIN_DATABASE_URL;
const PREMIUM_TRANSACTION_DATABASE_URL = process.env.REACT_APP_PREMIUM_TRANSACTION_DATABASE_URL
const FIRESTORE_DATABASE_URL = process.env.REACT_APP_FIRESTORE_DATABASE_URL;
const RTDB_DATABASE_URL = process.env.REACT_APP_RTDB_DATABASE_URL

const GCS_IMAGE_UPLOAD_LIMIT = parseInt(process.env.REACT_APP_GCS_IMAGE_UPLOAD_LIMIT, 10);
const MP_SERVICE_V1_API_API_KEY = process.env.REACT_APP_MP_SERVICE_V1_API_API_KEY;

const WHAT_IS_MY_IP_API = process.env.REACT_APP_MP_WHAT_IS_MY_IP_API;

const CDN_ORIGIN_DOMAIN_NAME = process.env.REACT_APP_CDN_ORIGIN_DOMAIN_NAME;
const CDN_PUBLIC_URL_DOMAIN_NAME = process.env.REACT_APP_CDN_PUBLIC_URL_DOMAIN_NAME;

// App Constants
const WEB_APP_URL = process.env.REACT_APP_WEB_APP_URL
const WEB_APP_ENV = process.env.REACT_APP_ENV


export {
  AUDIENCE_TYPE,
  PLATFORMS,
  HAJJ_AND_UMARAH_API_GET_RAW_URL,
  HAJJ_AND_UMARAH_API_DELETE_URL,
  HAJJ_AND_UMARAH_API_ADD_UPDATE_URL,
  MPAC_UPLAOD_FILES_TO_GCS_URL,
  MP_CF_CONTENT_GET_METADATA_URL,
  MP_CF_CONTENT_V2_GET_FOR_CONSOLE,
  MP_CF_CONTENT_V2_LIST_FOR_CONSOLE,
  MP_CF_CONTENT_V2_ADD,
  MP_CF_CONTENT_V2_UPDATE,
  MP_CF_CONTENT_V2_DELETE,
  MP_CF_CONTENT_V2_BULK_ADD_OR_UPDATE,
  MP_CF_CONTENT_V2_REMOVE_PROPERTIES,
  MP_CF_CONTENT_V2_BULK_DELETE,
  MP_CF_CONTENT_V2_UPDATE_HASHTAGS,
  MP_CF_CONTENT_V2_SUGGEST_HASHTAGS_FOR_CONSOLE,
  MP_CF_CONTENT_V2_LIST_HASHTAGS_FOR_AC,
  MP_CF_CONTENT_V2_GET_FAVORITES,
  MP_CF_QURAN_PLAYTLISTS_LIST_FEATURED_FOR_AC_URL,
  MP_CF_QURAN_PLAYTLISTS_EDIT_FOR_AC_URL,
  MP_CF_QURAN_PLAYTLISTS_BULK_UPLOAD_FOR_AC_URL,
  MP_CF_GET_HIJRI_JSON_URL,
  MP_CF_REDIS_FLUSH_CACHE_URL,
  MP_CF_REDIS_GET_INFO_URL,
  MP_CF_AC_ED_GET_TEMPLATES_URL,
  MP_CF_AC_ED_GET_MAILING_LISTS_URL,
  MP_CF_AC_ED_SEND_BULK_MAIL_URL,
  ADMIN_DATABASE_URL,
  PREMIUM_TRANSACTION_DATABASE_URL,
  PRECALC_API_GET_RAW_PRECALC_LIST_URL,
  PRECALC_API_GET_PRECALC_DATA_BY_LAT_LONG,
  PRECALC_API_UPDATE_RAW_PRECALC_LIST_URL,
  PRECALC_API_UPDATE_COUNTRY_RAW_PRECALC_LIST_URL,
  PRECALC_API_VALIDATE_URL,
  PRECALC_API_GENERATE_URL,
  PRECALC_API_TEST_API_RESPONSE_URL,
  PRECALC_API_LIST_FILE_VERSIONS,
  PRECALC_API_UPDATE_PRECALC_FILE,
  V2_REDIS_GET_INFO,
  V2_REDIS_DELETE_KEY,
  CDN_ORIGIN_DOMAIN_NAME,
  CDN_PUBLIC_URL_DOMAIN_NAME,
  TIMELINE_PROMOS_API_GET_RAW_URL,
  TIMELINE_PROMOS_API_DELETE_URL,
  TIMELINE_PROMOS_API_ADD_UPDATE_URL,
  SETTINGS_API_GET_RAW_FILE,
  SETTINGS_API_GET_FILE_VERSIONS,
  SETTINGS_API_UPDATE_RAW_FILE,
  SETTINGS_API_UPDATE_FILE_TIMESTAMP,
  SETTINGS_API_UPDATE_FILE_KEY_VALUES,
  SETTINGS_API_TEST_SETTINGS_API_RESPONSE,
  HIJRI_API_GET_RAW_FILE,
  HIJRI_API_UPDATE_RAW_FILE,
  HIJRI_API_GET_FILE_VERSIONS,
  FIRESTORE_DATABASE_URL,
  FIRESTORE_COLLECTIONS,
  GCS_IMAGE_UPLOAD_LIMIT,
  QURAN_API_GET_RAW_FILE,
  QURAN_API_UPDATE_RAW_FILE,
  MP_CF_VIRTUAL_TOURS_BULK_ADD_UPDATE,
  BIGQUERY_API_GET_ORDERED_HASH_TAGS,
  MP_CF_CONTENT_V2_TRENDING_HASHTAGS,
  MP_CF_MG_ADD_EMAIL_TAG,
  MP_CF_MG_DELETE_EMAIL_TAG,
  MP_CF_MG_LIST_EMAIL_TAGS,
  MP_CF_AC_TRACK_USER,
  MP_CF_GET_USER,
  MP_CF_AC_SEND_VERIFICATION_EMAIL,
  MP_CF_AC_DELETE_USER,
  MP_CF_AC_REVALIDATE_PURCHASE,
  MP_CF_AC_UPLOAD_IMAGE,
  MP_CF_CREATE_ADMIN_LOGS,
  MP_CF_QALBOX_SEARCH_MEDIA_TAG,
  MP_CF_QALBOX_CREATE_MEDIA_TAG,
  MP_CF_QALBOX_UPDATE_MEDIA_TAG,
  MP_CF_QALBOX_DELETE_MEDIA_TAG,
  MP_CF_QALBOX_SEARCH_GENRE,
  MP_CF_QALBOX_CREATE_GENRE,
  MP_CF_QALBOX_UPDATE_GENRE,
  MP_CF_QALBOX_DELETE_GENRE,
  MP_CF_CONTENT_V2_ADMIN_QUERY_USER_DATA,
  MP_CF_CONTENT_V2_ADMIN_UPDATE_USER_DATA,
  MP_CF_CONTENT_V2_ADMIN_SET_USER_DATA,
  MP_CF_CONTENT_V2_ADMIN_DELETE_USER_DATA,
  MP_CF_CONTENT_V2_ADMIN_CREATE_USER_DATA,
  MP_CF_CONTENT_V2_ADMIN_GET_USER_SHARDID,
  BIGQUERY_API_GET_USER_LOGS_ROW_COUNT,
  BIGQUERY_API_DELETE_USER_LOGS,
  BIGQUERY_API_GET_USER_LOGS,
  MP_CF_GET_SECRET_KEY,
  MP_SERVICE_V1_API_API_KEY,
  RTDB_DATABASE_URL,
  RTDB_DATABASE,
  RTDB_DATABASE_NODE,
  PREMIUM_TYPE,
  MP_CF_QALBOX_MEDIA_ADD_OR_UPDATE,
  MP_CF_QALBOX_DISPLAY_CATEGORIES_ADD_OR_UPDATE,
  MP_CF_QALBOX_DISPLAY_CATEGORIES_DELETE,
  ENVIRONMENT,
  MP_CF_QALBOX_SEARCH_MEDIA,
  MP_CF_QALBOX_DELETE_MEDIA,
  MP_CF_QALBOX_SEARCH_DISPLAY_CATEGORIES,
  WHAT_IS_MY_IP_API,
  WEB_APP_URL,
  WEB_APP_ENV,
  MP_CF_QALBOX_MEDIA_GET,
  MP_CF_QALBOX_MEDIA_SYNC_ELASTIC,
  MP_CF_QALBOX_MEDIA_ENCODE,
  MP_CF_QALBOX_ENCODE_MEDIA_GET,
  MP_CF_QALBOX_MEDIA_ENCODE_UPDATE_MEDIA_ID,
  MP_CF_QALBOX_MEDIA_PURGE_CDN_CACHE,
  MP_CF_QALBOX_MEDIA_SERIES_GET,
  MP_CF_QALBOX_MEDIA_GET_SERIES_DETAILS,
  MP_CF_QALBOX_MEDIA_SERIES_EPISODE_GET,
  MP_CF_QALBOX_SHORTS_GET,
  MP_CF_QALBOX_MEDIA_DUPLICATE_MANIFEST,
};
