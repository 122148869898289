import React, {useEffect, useState} from 'react';
import BasicLayout from '../../layouts/basiclayout';
import 'firebase/firestore'
import './style.css'
import {toast, ToastContainer} from "react-toastify";
import {Col, Row} from "react-bootstrap";
import {MPUploadExcel} from "../../widgets/upload-excel";
import {dailyDeenMediaAddOrUpdateHook, dailyDeenThemesListAddOrUpdateHook} from "../../hooks";
import {MediaExcelUploadInfoPanel, ThemeListExcelUploadInfoPanel} from "./common";
import {getMediaCopyItems, getMediaItems, getThemesListItems} from "./helper";


const UpdateContentCsv = () => {

  const onExcelUploadComplete = operationData => {
    if(!operationData.isError) {
      toast.success(operationData.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onUploadSuccess = () => {
    onExcelUploadComplete({
      operationData: {
        isError : false,
        message: 'Media items add/update success!!',
      },
    });
  };

  const onUploadFailed = () => {
    onExcelUploadComplete({
      operationData: {
        isError : true,
        message: 'Media items add/update failed!!',
      },
    });
  };

  return (
    <BasicLayout pagePermission="moderate_precalc">
      <ToastContainer autoClose={2000}/>
      <Row>
        <Col md={12}>
          <h3 className='content_editor_title'>{'Upload Excel'}</h3>
        </Col>
      </Row>
      <MPUploadExcel
        InfoPanel={MediaExcelUploadInfoPanel}
        fileUpdateHook={dailyDeenMediaAddOrUpdateHook}
        fileUpdateSuccessMessage={'Media Items Added/Updated Successfully!!'}
        getItems={getMediaItems}
        getCopySuccessItems={getMediaCopyItems}
        onUploadSuccess={onUploadSuccess}
        onUploadFailed={onUploadFailed}
        showResultOnSuccess={true}
      />
      <br/>
      <MPUploadExcel
        InfoPanel={ThemeListExcelUploadInfoPanel}
        fileUpdateHook={dailyDeenThemesListAddOrUpdateHook}
        fileUpdateSuccessMessage={'Themes List Added/Updated Successfully!!'}
        getItems={getThemesListItems}
        getCopySuccessItems={getMediaCopyItems}
        onUploadSuccess={onUploadSuccess}
        onUploadFailed={onUploadFailed}
        showResultOnSuccess={true}
        sheetName={"ThemesList"}
      />
    </BasicLayout>
  );
};

export default UpdateContentCsv;