import _ from 'lodash';
import React,
{
  Component,
} from 'react';

class DataModeratorWrapper extends Component {
  render() {
    const {
      showErrorMessage=false,
      errorMessage='Access denied! You are not permitted to view this page.',
      usersEmail,
    } = this.props;

    const locallyStoredLoggedInUser = localStorage.getItem('user');
    const locallyStoredTestEmails = localStorage.getItem('testEmails');
    const testEmails = locallyStoredTestEmails ? JSON.parse(locallyStoredTestEmails) : [];
    const currentLoggedInUser = locallyStoredLoggedInUser ? JSON.parse(locallyStoredLoggedInUser) : null;
    if (!currentLoggedInUser) {
      return null;
    }

    const locallyStoredDataModerators = localStorage.getItem('dataModerators');
    const dataModerators = locallyStoredDataModerators ? JSON.parse(locallyStoredDataModerators) : null;

    const showChildComponent =
        Boolean(_.find(dataModerators, dataModerator => currentLoggedInUser.uid === dataModerator.uid)) || testEmails.indexOf(usersEmail) > -1;
    const placeHolderComponent = showErrorMessage ?
        <p className='text-danger developer__options'>
          <span className='glyphicon glyphicon-exclamation-sign'/>
          &nbsp;{errorMessage}
        </p> : null;

    return showChildComponent ? this.props.children : placeHolderComponent;
  }
}

export default DataModeratorWrapper;
