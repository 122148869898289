import {
  useState,
  useEffect,
} from 'react';
import {
  getPrecalcDataByLatLong,
} from '../../services/mp-service-v1-api-service';

const fetchPrecalcDataByLatLong = ({
                                     latitude,
                                     longitude,
                                     countryCode,
                                     withData=false,
                                     shouldFetchPrecalcData,
                                   }) => {
  const [ precalcData, setPrecalcData ] = useState(null);

  const fetchPrecalcList = async () => {
    console.log(`Fetching Precalc Data for Country Code : ${countryCode} ...`);
    try {
      const precalcDataFetchApiResp =  await getPrecalcDataByLatLong({
        latitude,
        longitude,
        countryCode,
        withData,
      });

      const responseData = precalcDataFetchApiResp.data;
      if(responseData.success) {
        console.log(`Precalc Data Fetch Success for Country Code : ${countryCode}!!`);
        setPrecalcData(responseData.data);
      } else {
        console.log(`Precalc Data Fetch Failed for Country Code : ${countryCode}!!`);
      }
    } catch (err) {
      console.log(`Error while fetching Precalc Data for Country Code : ${countryCode}, Error : ${err}`);
    }
  };

  useEffect( () => {
    if(shouldFetchPrecalcData) {
      fetchPrecalcList();
    }
  }, [ shouldFetchPrecalcData ]);

  return [
    precalcData,
  ];
};

export default fetchPrecalcDataByLatLong;
