import _ from "lodash";
import React from "react";
import QalboxBrandAdUnit from "./brandAdUnit";

const QalboxBrandAdUnitsComponent = ({ adUnits, setAdUnits }) => {
  const handleAdd = () => {
    const currentAdUnits = adUnits ? adUnits : [];
    currentAdUnits.push({ active: false, android: "", ios: "" });
    setAdUnits(currentAdUnits);
  };

  const handleInputChange = (index, adUnit) => {
    const currentAdUnits = [...adUnits];
    currentAdUnits[index] = adUnit;
    setAdUnits(currentAdUnits);
  };

  const handleRemove = (indexToDelete) => {
    const currentAdUnits = adUnits ? adUnits : [];
    const updatedAdUnits = currentAdUnits.filter((_, index) => index !== indexToDelete);
    setAdUnits(updatedAdUnits);
  }

  return (
    <div>
      {adUnits &&
        adUnits.length > 0 &&
        adUnits.map((adUnit, index) => (
          <div
            key={index}
            style={{
              marginBottom: "10px",
              border: "1px solid #ccc",
              padding: "3px",
            }}
          >
            <div className="qalbox-form-row">
              <div
                name={index}
                style={{ marginRight: "10px" }}
                onClick={() => handleRemove(index)}
              >
                <span
                  className="glyphicon glyphicon-remove"
                  aria-hidden="true"
                />
              </div>
            </div>
            <QalboxBrandAdUnit index={index} adUnitObject={adUnit} onChange={handleInputChange} />
          </div>
        ))}
      <button onClick={handleAdd}>Create Ad unit</button>
    </div>
  );
};

export default QalboxBrandAdUnitsComponent;
