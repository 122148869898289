import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  qalBoxSearchDisplayCategories,
} from '../../services/mp-cf-api-service';

const displayCategoriesSearch = () => {
  const [ responseData, setResponseData ] = useState({ });
  const [ updateData, setOperationData ] = useState(null);
  const {
    searchConfig,
    onOperationComplete,
  } = updateData || {};

  const getDisplayCategoryItems = async () => {
    try {
      console.log(`QalBox searching display categories...`);
      setResponseData({
        isLoading: true,
        isError: false,
      });

      const mediaItemsApiResp = await qalBoxSearchDisplayCategories({
        data: searchConfig,
      });

      const responseData = _.get(mediaItemsApiResp, 'data', {});

      responseData.payload.filter((category) => {
        category.tags = category.tags.join(', ');
        category.countries_allowed = category.countries_allowed.join(', ');
        category.countries_disallowed = category.countries_disallowed.join(', ');
        category.genres = category.genres.join(', ');
        return category;
      });

      let errorMsg = null;
      let isError = false;

      if(responseData.success) {
        console.log('QalBox search display categories items add/update Success!!');
      } else {
        console.log('QalBox search display categories items add/update Failed!!');
        errorMsg = `Failed to search QalBox display categories items.`;
        isError = true;
      }

      setResponseData({
        isLoading: false,
        isError,
        errorMsg,
        data: responseData.payload,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError,
          errorMsg,
          data: responseData.payload,
        });
      }
    } catch (err) {
      console.log('Something went wrong while searching QalBox display categories items. ', err);
      const errorMsg = `Error occurred while searching QalBox display categories items.`;
      setResponseData({
        isLoading: false,
        isError: true,
        errorMsg,
        data: null,
      });
      if(onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
          data: null,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(updateData)) {
      getDisplayCategoryItems();
    }
  }, [ updateData ]);

  return [
    responseData,
    setOperationData,
  ];
};

export default displayCategoriesSearch;
