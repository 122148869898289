import {
  Component,
} from 'react';

class ConditionalWrapper extends Component {
  render() {
    if(this.props.show) {
      return this.props.children;
    } else {
      return null;
    }
  }
}

export default ConditionalWrapper;
