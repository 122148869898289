import _ from 'lodash';
import React,
{
  useRef,
  Fragment,
  useState,
  useEffect,
} from 'react';
import {
  countries,
} from '../../services/utils';
import {
  Panel,
  Button,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap';
import ToggleButton from 'react-toggle-button';
import Select from 'react-select';
import {
  getCountryOptions,
  getUpdatedFileDiff,
  getSightSelectYears,
  getSelectedCountryCodes,
  getExistingCountryCodes,
} from './helper';
import {
  HIJRI_MONTH_NAMES,
} from './Constants';
import {
  MPSelect,
  MPDataTimeInput,
} from '../../widgets/common';
import {
  confirmAlert,
} from "react-confirm-alert";
import AppModal from '../../widgets/modal';

const AddNewCountry = ({
                         year,
                         onAddCountries,
                         existingCountries,
                       }) => {
  const [ selectedCountries , setSelectedCountries ] = useState([]);
  const countryCodes = getSelectedCountryCodes(selectedCountries);
  const existingCountryCodes = getExistingCountryCodes(countryCodes, existingCountries);
  const countryCodesExisting = !_.isEmpty(existingCountryCodes);
  const disableAddButton = _.isEmpty(countryCodes) || !_.isEmpty(existingCountryCodes);
  const errorMessage = _.join(existingCountryCodes, ',');

  return (
      <ListGroupItem key={'new'}  className="small" bsStyle={'info'}>
        <div className="hijri-sighting-country-tool-box-add">
          <div className={`hijri-sighting-country-select form-group ${countryCodesExisting && 'has-error'}`}>
            <Select onChange={setSelectedCountries}
                    value={selectedCountries}
                    isSearchable={true}
                    options={getCountryOptions()}
                    isMulti={true}
                    placeholder={'Select countries'}
            />
            {
              countryCodesExisting &&
              <span className="help-block">{`Already existing: ${errorMessage}`}</span>
            }
          </div>
          <Button
              onClick={() => {
                onAddCountries({ year, countryCodes });
                setSelectedCountries([]);
              }}
              disabled={disableAddButton}
              className="btn-sm btn-primary"
              type={'button'}>
            {'Add'}
          </Button>
        </div>
      </ListGroupItem>
  );
};

const Sightings = ({
                     onResetYear,
                     onDeleteYear,
                     sightingsData,
                     onAddNewCountry,
                     onToggleCountry,
                     onDeleteCountry,
                   }) => {
  if(!sightingsData) {
    return null;
  }
  const sightings = sightingsData;

  return _.keys(sightings).sort().reverse().map((year) => {
    const countryList = sightings[year];
    let countryListElements = [
      <AddNewCountry
          year={year}
          onAddCountries={onAddNewCountry}
          existingCountries={sightings[year]}
      />
    ];

    const countryEls = _.keys(countryList).sort().map((countryCode) => {
      const countryName = (countryCode === 'default') ? 'DEFAULT' :
          (countries[countryCode.toUpperCase()] || countryCode.toUpperCase());
      const enabled = countryList[countryCode];
      const rowStyle = enabled ? 'success' : 'danger';

      return (
          <ListGroupItem key={countryCode}  className="small" bsStyle={rowStyle}>
            {countryName} - <code className="hijri_json_code">{countryCode}</code>
            <div className="hijri-sighting-country-tool-box pull-right">
              <ToggleButton
                  value={enabled}
                  onToggle={(value) => onToggleCountry({ year, countryCode, value: !value })}
              />
              <Button
                  onClick={() => onDeleteCountry({ year, countryCode })}
                  disabled={false}
                  className="btn-xs btn-danger"
                  type={'button'}>
                {'Delete'}
              </Button>
            </div>
          </ListGroupItem>
      );
    });
    countryListElements = countryListElements.concat(countryEls);

    return (
        <Panel key={year}>
          <Panel.Heading>
            <Panel.Title componentClass="h3">
              {year}
              <div className="hijri-sighting-year-tool-box pull-right">
                <Button
                    onClick={() => onResetYear({ year })}
                    disabled={false}
                    className="btn-xs btn-primary"
                    type={'button'}>
                  {'Reset'}
                </Button>
                <Button
                    onClick={ () => onDeleteYear({ year })}
                    disabled={false}
                    className="btn-xs btn-danger"
                    type={'button'}>
                  {'Delete'}
                </Button>
              </div>
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <ListGroup>
              {countryListElements}
            </ListGroup>
          </Panel.Body>
        </Panel>
    );
  });
};

const EditableDateItem = ({
                            index,
                            bsStyle,
                            startDate,
                            onChangeDate,
                          }) => {
  return (
      <ListGroupItem
          key={index}
          bsStyle={bsStyle}
          className="small hijri-years-start-date-item">
        {HIJRI_MONTH_NAMES[index]}
        <MPDataTimeInput
            className={'hijri-years-start-date-input pull-right'}
            showToday
            value={startDate}
            dateFormat={'D-M-YYYY'}
            timeFormat={false}
            closeOnSelect={true}
            onChange={onChangeDate}
            showEditLabel={true}
        />
      </ListGroupItem>
  );
};

const AddNewYear = ({
                      refMap,
                      onAddYear,
                      countryCode,
                      existingYearData,
                    }) => {

  const [ newYear , setNewYear ] = useState('');
  const existingYear = _.find(_.keys(existingYearData), year => year === newYear);
  const disableAddButton = !newYear || existingYear;
  const disableNavigateButton = !newYear;

  const onNavigate = () => {
    if(newYear) {
      const refKey = `${countryCode}-${newYear}`;
      console.log('refKey', refKey)
      const ref = refMap[refKey];
      if (ref && ref.current) {
        window.scroll({
          top: ref.current.offsetTop + 100,
          left: 0,
          behavior: 'smooth',
        });
        // window.scrollTo(0, ref.current.offsetTop);
        // ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  return (
      <Fragment>
        <div className={`hijri-sighting-country-add-year-select form-group ${existingYear && 'has-error'}`}>
          <MPSelect
              items={getSightSelectYears()}
              selectedItem={newYear}
              onChangeSelect={setNewYear}
              className={'form-control-sm'}
              selectText={'Select year to add'}
          />
          {
            existingYear &&
            <span className="help-block">{`${existingYear} already existing.`}</span>
          }
        </div>
        <Button
            onClick={onNavigate}
            disabled={disableNavigateButton}
            className="btn-xs btn-primary"
            type={'button'}>
          {'Navigate'}
        </Button>
        <Button
            onClick={() => {
              onAddYear({ year: newYear, countryCode });
              setNewYear(null);
            }}
            disabled={disableAddButton}
            className="btn-xs btn-primary"
            type={'button'}>
          {'Add'}
        </Button>
      </Fragment>
  );
};

const HijriCountryYears = ({
                             year,
                             countryCode,
                             onResetYear,
                             onDeleteYear,
                             onChangeDate,
                             onUpdateRefMap,
                             countryCalendar,
                           }) => {

  const monthStarts = countryCalendar[year];
  const monthsEls = monthStarts.map((startDate, i) => {
    const style = [1, 8, 9, 12].includes(i +1) ? 'warning' : null;

    const onChangeDateValue = (date) => {
      onChangeDate({
        date,
        year,
        countryCode,
        index: i,
      });
    };

    return (
        <EditableDateItem
            index={i}
            bsStyle={style}
            startDate={startDate}
            onChangeDate={onChangeDateValue}
        />
    );
  });

  const refKey = `${countryCode}-${year}`;
  const ref = useRef(null);
  useEffect(() => {
    onUpdateRefMap({
      key: refKey,
      ref,
    });
  }, [refKey]);

  return (
      <div className={`hijri-country-year-item-${refKey}`} ref={ref}>
        <Panel key={year}>
          <Panel.Heading>
            <Panel.Title componentClass="h3">
              {year}
              <div className="hijri-sighting-year-tool-box pull-right">
                <Button
                    onClick={() => onResetYear({ year, countryCode })}
                    disabled={false}
                    className="btn-xs btn-primary"
                    type={'button'}>
                  {'Reset'}
                </Button>
                <Button
                    onClick={ () => onDeleteYear({ year, countryCode })}
                    disabled={false}
                    className="btn-xs btn-danger"
                    type={'button'}>
                  {'Delete'}
                </Button>
              </div>
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <ListGroup>
              {monthsEls}
            </ListGroup>
          </Panel.Body>
        </Panel>
      </div>
  );
};

const HijriCountries = ({
                          refMap,
                          onAddYear,
                          countryCode,
                          onResetYear,
                          onChangeDate,
                          onDeleteYear,
                          onUpdateRefMap,
                          onResetCountry,
                          onDeleteCountry,
                          newCountriesList,
                          countryBasedCalendar,
                        }) => {
  const ref = useRef(null);

  useEffect(() => {
    onUpdateRefMap({
      key: countryCode,
      ref,
    });

    if (ref.current && newCountriesList) {
      window.scroll({
        top: ref.current.offsetTop + 120,
        left: 0,
        behavior: 'smooth',
      });
      // ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, []);

  const countryName = (countryCode === 'default') ?
      'DEFAULT' : (countries[countryCode.toUpperCase()] || countryCode.toUpperCase());

  const countryCalendar = countryBasedCalendar[countryCode];
  const yearEls = _.keys(countryCalendar).sort().reverse().map((year) => {

    return (
        <HijriCountryYears
            countryCode={countryCode}
            year={year}
            onResetYear={onResetYear}
            onDeleteYear={onDeleteYear}
            onChangeDate={onChangeDate}
            onUpdateRefMap={onUpdateRefMap}
            countryCalendar={countryCalendar}
        />
    );
  });

  return (
      <div className={'hijri-country-item'} ref={ref}>
        <Panel key={countryName}>
          <Panel.Heading className={'hijri-year-country-header'}>
            <Panel.Title componentClass="h3">
              {countryName} - <code className="hijri_json_code">{countryCode}</code>
            </Panel.Title>
            <div className="hijri-sighting-country-year-tool-box pull-right">
              <AddNewYear
                  onAddYear={onAddYear}
                  countryCode={countryCode}
                  existingYearData={countryBasedCalendar[countryCode]}
                  refMap={refMap}
              />
              <Button
                  onClick={() => onResetCountry({ countryCode })}
                  disabled={false}
                  className="btn-xs btn-primary"
                  type={'button'}>
                {'Reset'}
              </Button>
              <Button
                  onClick={ () => onDeleteCountry({ countryCode })}
                  disabled={false}
                  className="btn-xs btn-danger"
                  type={'button'}>
                {'Delete'}
              </Button>
            </div>
          </Panel.Heading>
          <Panel.Body>
            {yearEls}
          </Panel.Body>
        </Panel>
      </div>
  );
};


const HijriYears = ({
                      refMap,
                      onAddYear,
                      onResetYear,
                      onChangeDate,
                      onDeleteYear,
                      onUpdateRefMap,
                      onResetCountry,
                      onDeleteCountry,
                      newCountriesList,
                      countryBasedCalendar,
                    }) => {

  return _.keys(countryBasedCalendar).sort().map((countryCode) => {
    return (
        <HijriCountries
            countryBasedCalendar={countryBasedCalendar}
            key={countryCode}
            countryCode={countryCode}
            newCountriesList={newCountriesList}
            onResetCountry={onResetCountry}
            onDeleteCountry={onDeleteCountry}
            onAddYear={onAddYear}
            onResetYear={onResetYear}
            onDeleteYear={onDeleteYear}
            onChangeDate={onChangeDate}
            onUpdateRefMap={onUpdateRefMap}
            refMap={refMap}
        />
    );
  });
};

const onClickUpdate = ({
                         doUpdate,
                         updatedHijri,
                         initialHijri,
                         onUpdateComplete,
                       }) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
          <AppModal
              show={true}
              showBody={true}
              modalBody={getUpdatedFileDiff(updatedHijri, initialHijri)}
              modalBodyClassName={'precalcs-diff-merge-view'}
              headingText={'Are you sure want to update the Hijri Data with the changes you made?'}
              closeButtonText={'No'}
              okButtonText={`Yes, Update`}
              handleClose={onClose}
              handleOk={() => {
                doUpdate({
                  updatedFile: updatedHijri,
                  onOperationComplete: onUpdateComplete,
                });
                onClose();
              }}
          />
      );
    }
  });
};


export {
  Sightings,
  HijriYears,
  onClickUpdate,
}
