import _ from "lodash";
import React, { useState, useEffect } from "react";
import AppModal from "../../../../widgets/modal";
import LoadingOverlay from "react-loading-overlay";
import { ScaleLoader } from "react-spinners";
import { ENCODING_STATUS } from "../../Constants";
import QalboxVideoUploader from "../../media/helperComponents/qalboxVideoUploader";
import { convertArrayToSelectList, convertToSelectObject } from "../../helper";
import QalboxSelectDropdown from "../../media/helperComponents/qalboxSelect";
import QalboxSubtitleBox from "../../media/helperComponents/qalboxSubtitleBox";
import QalboxS3Player from "../../brands/helperComponents/QalboxS3Player";
import { useFetchShortEncodeDetails } from "./hooks/useFetchShortEncodeDetails";
import { useEncodeShort } from "./hooks/useEncodeShort";
import { useInvalidateShortCache } from "./hooks/useInvalidateShortCache";

export default function EncodeShort({
  uuid,
  onEncodeComplete,
}) {
  const [shortEncodeResponse, isLoadingDetails] = useFetchShortEncodeDetails(uuid);
  const [showS3Media, setShowS3Media] = useState(false);
  const [encodeShortObject, setEncodeShortObject] = useState({
    media_uuid: uuid,
    encode_id: null,
    input_path: '',
    output_path: '',
    job_template: 'nodrm',
    sprite_sheet_offset: 0,
    subtitles: [],
  });
  const [encodeShort, isEncoding] = useEncodeShort();
  const [invalidateShortCache, isCacheInvalidating] = useInvalidateShortCache();

  const [encodeMediaConfirmDialog, setEncodeMediaConfirmDialog] =
    useState(false);

  const handleEncodeInputTextChange = (e) => {
    const { name, value } = e.target;
    setEncodeShortObject((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubtitleUpdate = (subtitleList) => {
    setEncodeShortObject((prevState) => ({
      ...prevState,
      subtitles: subtitleList,
    }));
  };

  const handleEncodeMedia = () => {
    setEncodeMediaConfirmDialog(true);
  };

  const handleSelect = (name, option) => {
    setEncodeShortObject((prevState) => ({
      ...prevState,
      [name]: option.value,
    }));
  };

  const encodeMedia = async () => {
    const folderArr = encodeShortObject?.output_path?.split('/');
    const destinationPath = folderArr.filter(Boolean).join('/').replace(/\s/g, '');
    encodeShort(encodeShortObject, destinationPath, setEncodeShortObject);
    onEncodeComplete(destinationPath);
  };

  const showS3Player = () => {
    setShowS3Media(true);
  }

  const handleEncodeIdInputClick = () => {
    window.open(`https://us-east-1.console.aws.amazon.com/mediaconvert/home?region=us-east-1#/jobs/summary/${encodeShortObject.encode_id}`, '_blank');
  }

  const invalidateCdnCache = async () => {
    const folderArr = encodeShortObject?.output_path?.split('/');
    const destinationPath = folderArr.filter(Boolean).join('/');

    if (destinationPath) {
      invalidateShortCache(destinationPath);
    }
  }
  
  useEffect(() => {
    if (shortEncodeResponse) {
      setEncodeShortObject(shortEncodeResponse);
    }
  }, [shortEncodeResponse]);

  return (
    <LoadingOverlay
      active={isLoadingDetails || isEncoding || isCacheInvalidating}
      spinner={<ScaleLoader />}
      text="Loading..."
    >
      <QalboxVideoUploader
        name={"Short"}
        value={null}
        handleInputTextChange={handleEncodeInputTextChange}
      />
      <label>Input Path</label>
      <div className="qalbox-form-row">
        <input
          name="input_path"
          value={encodeShortObject.input_path}
          onChange={handleEncodeInputTextChange}
          type="text"
          className="form-control"
          placeholder="Input Path"
          style={{ marginBottom: "5px" }}
        />
        <div
          style={{
            marginLeft: "10px",
          }}
          onClick={showS3Player}
        >
          <span className="glyphicon glyphicon-film" aria-hidden="true" />
        </div>
      </div>
      <div className="form-group">
        <label>Destination Path</label>
        <input
          value={encodeShortObject.output_path}
          name="output_path"
          type="text"
          onChange={handleEncodeInputTextChange}
          className="form-control"
          placeholder="Destination Path"
          style={{ marginBottom: "5px" }}
        />
      </div>
      <button
        style={{ marginBottom: "10px", backgroundColor: "red", color: "white" }}
        onClick={invalidateCdnCache}
      >
        INVALIDATE CACHE
      </button>
      <div className="form-group">
        <label>Job Template</label>
        <QalboxSelectDropdown
          name={"job_template"}
          selectedOption={convertToSelectObject(encodeShortObject.job_template)}
          options={convertArrayToSelectList(["nodrm", "drm"])}
          onChange={handleSelect}
        />
      </div>
      <div className="form-group">
        <label>Sprite Sheet Offset</label>
        <input
          value={encodeShortObject.sprite_sheet_offset}
          name="sprite_sheet_offset"
          type="text"
          onChange={handleEncodeInputTextChange}
          className="form-control"
          placeholder="Destination Path"
          style={{ marginBottom: "5px" }}
        />
      </div>
      <div className="form-group">
        <label>Subtitles</label>
        <QalboxSubtitleBox
          subtitleList={
            encodeShortObject.subtitles ? encodeShortObject.subtitles : []
          }
          handleSubtitleUpdate={handleSubtitleUpdate}
        />
      </div>
      <div className="form-group">
        <button onClick={handleEncodeMedia} className="pagination-button">
          Encode Media
        </button>
      </div>
      {encodeShortObject.encoding_status && (
        <div>
          <label>Encoding status: </label>{" "}
          <label>{ENCODING_STATUS[encodeShortObject.encoding_status]}</label>
        </div>
      )}
      <div className="form-group">
        <label>Encode ID</label>{" "}
        <button
          style={{
            backgroundColor: "green",
            color: "white",
            borderRadius: "10px",
            marginBottom: "5px",
          }}
          onClick={handleEncodeIdInputClick}
        >
          See Encoding Job
        </button>
        <input
          value={encodeShortObject.encode_id}
          name="encode_id"
          disabled
          type="text"
          onChange={handleEncodeInputTextChange}
          className="form-control"
          placeholder="Encode ID"
          style={{ marginBottom: "5px" }}
        />
      </div>
      <AppModal
        show={showS3Media}
        showBody={true}
        headingText={`S3 Media Preview`}
        modalBody={
          <QalboxS3Player
            isShort={true}
            fileName={encodeShortObject.input_path}
          />
        }
        handleClose={() => setShowS3Media(false)}
      />
      <AppModal
        show={encodeMediaConfirmDialog}
        showBody={true}
        headingText={`Encode Media`}
        modalClassName="app-modal-confirm"
        modalBody={"Are you sure want to Encode"}
        modalBodyClassName={""}
        closeButtonText={"Cancel"}
        okButtonText={"Encode"}
        handleClose={() => setEncodeMediaConfirmDialog(false)}
        handleOk={() => {
          encodeMedia();
          setEncodeMediaConfirmDialog(false);
        }}
      />
    </LoadingOverlay>
  );
}