import React,
{
  Component,
} from 'react';
import {
  Checkbox,
} from 'react-bootstrap';
import './style.css';

// 000001 = prayer_requests, 1
// 000010 = read_codes, 2
// 000100 = write_codes, 4
// 001000 = read_users, 8
// 010000 = write_users, 16
// 100000 = change_user_permission, 32

class PermissionCol extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: 0,
      newlyChanged: ['', '', '', '', '', '', '', '']
    };
  }

  componentWillMount(nextProps) {
    if(this.props.permission!== undefined && this.props.permission!== null) {
      this.setState({
        permission: this.props.permission
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps.lastUpdated);
    if(nextProps.lastUpdated && nextProps.lastUpdated !== this.props.lastUpdated) {
      this.setState({
        newlyChanged: ['', '', '', '', '', '', '', '']
      });
    }
  }

  twoToThePowN(n) {
    return Math.pow(2, n);
  }

  check(i, label, permission) {
    let { muid } = this.props;
    permission+= this.twoToThePowN(i);
    let {newlyChanged} = this.state;
    this.setState({
      permission,
      newlyChanged: this.getNewlyChangedClassName(newlyChanged, i)
    });
    this.updateRole(muid, permission, label, 'granted');
  }

  uncheck(i, label, permission) {
    let { muid } = this.props;
    let {newlyChanged} = this.state;
    permission -= this.twoToThePowN(i);
    this.setState({
      permission,
      newlyChanged: this.getNewlyChangedClassName(newlyChanged, i)
    });
    this.updateRole(muid, permission, label, 'revoked');
  }

  getNewlyChangedClassName(newlyChanged, i) {
    if(newlyChanged[i] === 'newly__changed') {
      newlyChanged[i] = '';
    } else {
      newlyChanged[i] = 'newly__changed';
    }
    return newlyChanged;
  }

  renderCheckBoxes(permission, usersPermission, currentUID, muid, newlyChanged) {
    const hasChangePermission = (usersPermission >= 64 && currentUID!==muid);
    const permissionArr = [
      'Moderate Prayer Requests',
      'Read Promo Codes',
      'Write Promo Codes',
      'Read User',
      'Write User',
      'Moderate Precalc',
      'Change moderator permission',
      'Aideen',
    ];
    return permissionArr.map((label, i) => {
      const featurePermission = this.twoToThePowN(i);
      if(((permission & featurePermission) === featurePermission)) {
        return <td className={"text-center "+newlyChanged[i]}>
          <Checkbox
            disabled={!hasChangePermission}
            onChange={this.uncheck.bind(this, i, permissionArr[i], permission)}
            checked
          >
          </Checkbox>
        </td>
      } else {
        return <td className={"text-center "+newlyChanged[i]}>
          <Checkbox
            disabled={!hasChangePermission}
            onChange={this.check.bind(this, i, permissionArr[i], permission)}
          >
          </Checkbox>
        </td>
      }
    });
  }

  updateRole(uid, permission) {
    if(uid) {
      this.props.updatePermissionObj(uid, permission);
    }
  }

  render() {
    const {
      muid,
      currentUID,
      usersPermission,
    } = this.props;
    let {
      permission,
      newlyChanged,
    } = this.state;
    return this.renderCheckBoxes(permission, usersPermission, currentUID, muid, newlyChanged);
  }
}

export default PermissionCol;
