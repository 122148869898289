import _ from 'lodash';
import React from 'react';
import {
  Badge,
  Nav,
  Navbar,
} from 'react-bootstrap';
import './style.css';
import {
  MenuItems,
} from '../../menu';
import mplogo from '../header/mp_logo_activate_acct.png';
import {
  ENVIRONMENT,
  WEB_APP_ENV,
} from '../../Constants';

const MobileMenu = ({
                      user,
                      usersPermission,
                    }) => {

  const envClass = WEB_APP_ENV === ENVIRONMENT.PRODUCTION
    ? 'env_warn'
    : 'env_no_warn';

  return (
    <Navbar className="mobilemenu" inverse collapseOnSelect>
      <Navbar.Header>
        <Navbar.Brand>
          <a href="/search-user" className="header__branding mobile_header">
            <img alt="Muslim Pro Logo" width="40" src={mplogo}/>
            <div className={'mobile_header_name'}>
              <div className={'mobile_console_name_with_env'}>
                <span className={'mobile_console_name'}>
                  {'Admin Console'}
                </span>
                &nbsp;
                <div className={'header_env_name'}>
                  <Badge
                    className={envClass}
                  >
                    {_.startCase(WEB_APP_ENV)}
                  </Badge>
                </div>
              </div>
              <span className={'mobile_user_name'}>
                {user.displayName}
              </span>
            </div>
          </a>
        </Navbar.Brand>
        <div className={'mobile_header_options'}>
          <a className="header__logout" href="/logout">Logout</a>
          <Navbar.Toggle />
        </div>
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav>
          <MenuItems usersPermission={usersPermission} />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default MobileMenu;
