import React from 'react';
import {
  listColumns,
} from './columns';
import CommonList from './commonList';

const List = () => {

  return (
      <CommonList
          title={'Content V2 List'}
          initialFilters={[]}
          columns={listColumns()}
      />
  );
};

export default List;
