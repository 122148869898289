const MP_SERVICE_V1_API_BASE_URL = process.env.REACT_APP_MP_SERVICE_V1_API_BASE_URL;
const MP_CF_API_BASE_URL = process.env.REACT_APP_MP_CF_API_BASE_URL;
const MP_CF_GEN2_API_BASE_URL = process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL;
const MP_PROMO_CODES_DATABASE_URL = process.env.REACT_APP_MP_PROMO_CODES_DATABASE_URL;
const MP_PREMIUM_CRON_DATABASE_URL = process.env.REACT_APP_MP_PREMIUM_CRON_DATABASE_URL;
const MP_AIDEEN_BASE_URL = process.env.REACT_APP_MP_AIDEEN_BASE_URL;
const MP_API_SERVER_BASE_URL = process.env.REACT_APP_MP_API_SERVER_BASE_URL;

export {
  MP_SERVICE_V1_API_BASE_URL,
  MP_CF_API_BASE_URL,
  MP_PROMO_CODES_DATABASE_URL,
  MP_PREMIUM_CRON_DATABASE_URL,
  MP_CF_GEN2_API_BASE_URL,
  MP_AIDEEN_BASE_URL,
  MP_API_SERVER_BASE_URL
}
