import _ from "lodash";

const isValidText = (text) => {
  return text.length>0;
}

const getMediaCopyItems = (displayItemsData) => {
  console.log('getMediaCopyItems : ', displayItemsData);
  return JSON.stringify(displayItemsData);
};

const getMediaItems = async (rows) => {
  const items = [];
  const allErrors = [];
  for(let i = 1; i < rows.length; i++) {
    let errorsCount = 0;
    const data = rows[i];

    const item = {};

    const errors = {
      'row': i + 1,
      'errorsCount': 0,
      'errorList': [],
      'excelSheetRowData': data,
    };
// query
    const query = _.get(data, '[0]', null)
    if(query) {
      if(isValidText(query) ) {
        item['query'] = query.toLowerCase();
      } else {
        errors['errorList'].push(`${++errorsCount}. Query is invalid.`);
      }
    } else {
      errors['errorList'].push(`${++errorsCount}.  Query  not found.`);
    }
   
// ans
    const ans = _.get(data, "[1]", null)
    if(ans) {
      if(isValidText(ans) ) {
        item['ans'] = ans;
      } else {
        errors['errorList'].push(`${++errorsCount}. Answer is invalid.`);
      }
    } else {
      errors['errorList'].push(`${++errorsCount}.  Answer  not found.`);
    }
  
    errors['errorsCount'] = errorsCount;
    if(errors['errorsCount'] > 0) {
      allErrors.push(errors);
    }
    items.push(item);
  }

  return {
    items,
    allErrors,
  };
};

export {
  getMediaCopyItems,
  getMediaItems,
}