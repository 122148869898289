import React from 'react';
import {
  Col,
  Row,
  Alert,
  Button,
} from 'react-bootstrap';
import Collapse,
{
  Panel,
} from 'rc-collapse';
import {
  MPExpandIcon,
} from '../../widgets/common';
import {
  CopyToClipboard,
} from 'react-copy-to-clipboard';
import _ from 'lodash';
import ImageZoom from 'react-medium-image-zoom';
import {
  confirmAlert,
} from 'react-confirm-alert';
import AppModal from '../../widgets/modal';
import ReactJson from 'react-json-view';

const ImageUploadInfoPanel = () => {

  return (
    <Row>
      <Col>
        <Alert>
          <strong style={{'fontSize': '20px'}}>
            Important Note (Please read before you upload):
          </strong>
          <br/>
          <Collapse
            accordion={true}
            className={'upload-note-collapse'}
            expandIcon={MPExpandIcon}
          >
            <Panel
              header="Image Upload Guide"
              headerClass="excel-upload-note-header"
              extra={<span>Click to see/hide Details</span>}
            >
              <ol>
                <li>
                  <p>Please note you can only upload <strong><u> maximum of 30</u></strong> images to the Google Cloud Storage at a single upload.</p>
                </li>
                <li>
                  <p>Also note that if you upload the same name file it will <strong><u> replace the existing</u></strong> image in the Google Cloud Storage and it is <strong><u> irreversible.</u></strong></p>
                </li>
                <li>
                  <p>When you replace images make sure to tick the  <strong>Clear CDN Cache</strong> tick, which clear the CDN cache and it will take up to 10 minutes to take effect.</p>
                </li>
                <li>
                  <p>Before hit the <strong>upload</strong> button double check the:  <strong><u> Language</u></strong>, <strong><u> Type</u></strong>, and <strong><u> Size</u></strong> parameters.</p>
                </li>
                <li>
                  <p>If you are uploading the unoptimized images then please tick the Optimize Images section to optimize the images.
                    <strong><u>Please be careful</u></strong> when using this feature, because if you tick for already optimized images
                    it will caused to produce very weak images after the upload.
                  </p>
                </li>
              </ol>
            </Panel>
          </Collapse>
        </Alert>
      </Col>
    </Row>
  );
};


const MediaExcelUploadInfoPanel = () => {
  const sampleExcelFileUrl = 'https://docs.google.com/spreadsheets/d/1tCEmGi_qmSo2iO0m5lPRwbiCmkuS5CNVI5LMd49pxHM/edit#gid=0';

  return (
    <Row>
      <Col>
        <Alert>
          <strong style={{'fontSize': '20px'}}>
            Important Note (Please read before you upload):
          </strong>
          <br/>
          <Collapse
            accordion={true}
            className={'upload-note-collapse'}
            expandIcon={MPExpandIcon}
          >
            <Panel
              header="Media Item Upload Guide"
              headerClass="excel-upload-note-header"
              extra={<span>Click to see/hide Details</span>}
            >
              <ol>
                <li>
                  Please read the README tab in sample file.
                </li>
                <li>
                  All fields that are marked with&nbsp;
                  <strong><u>required</u></strong>&nbsp;
                  should be available when uploading the file.
                </li>
              </ol>
            </Panel>
          </Collapse>
          <label>See the Sample Excel Sheet : </label> &nbsp;
          <a href={sampleExcelFileUrl} target="_blank">
            Click to see the sample excel sheet.
          </a>
        </Alert>
      </Col>
    </Row>
  );
};

const DisplayCategoriesExcelUploadInfoPanel = () => {
  const sampleExcelFileUrl = 'https://docs.google.com/spreadsheets/d/1lHVWRJNrhlf49x5nsD0gSjz6eTOapSF3FVylNnkFWDw/edit#gid=1031545646';

  return (
    <Row>
      <Col>
        <Alert>
          <strong style={{'fontSize': '20px'}}>
            Important Note (Please read before you upload):
          </strong>
          <br/>
          <Collapse
            accordion={true}
            className={'upload-note-collapse'}
            expandIcon={MPExpandIcon}
          >
            <Panel
              header="Display Categories Upload Guide"
              headerClass="excel-upload-note-header"
              extra={<span>Click to see/hide Details</span>}
            >
              <ol>
                <li>
                  Please read the README tab in sample file.
                </li>
                <li>
                  All fields that are marked with&nbsp;
                  <strong><u>required</u></strong>&nbsp;
                  should be available when uploading the file.
                </li>
              </ol>
            </Panel>
          </Collapse>
          <label>See the Sample Excel Sheet : </label> &nbsp;
          <a href={sampleExcelFileUrl} target="_blank">
            Click to see the sample excel sheet.
          </a>
        </Alert>
      </Col>
    </Row>
  );
};

const CopyItem = ({
                    text,
                    showText = true,
                    copyIconText,
                  }) => {

  return (
    <>
      {
        showText
          ? <p className={'word-wrap-text text-center'}>{text}</p>
          : null
      }
      <CopyToClipboard
        text={text}
        onCopy={() => {
          console.log(`Copied title : ${text}`)
        }}
      >
        <Button className="btn btn-xs btn-dark mp-content-v2-table-hover-btn">
          &nbsp;<span className="glyphicon glyphicon-duplicate" aria-hidden="true"/>
          { copyIconText ? copyIconText : null }
        </Button>
      </CopyToClipboard>
    </>
  );
};

const GroupId = ({ groupId }) => {
  if(!groupId) {
    return null;
  }

  return (
    <span className="mp-content-v2-table-series-id">
        {groupId}
      </span>
  );
};

const Tags = ({
                    tags,
                    tagBackGroundColorCode='#9ae6b4',
                    tagTextColorCode='#22543d',
                  }) => {
  if(_.isEmpty(tags)) {
    return null;
  }

  return (
    <div className={'mp-content-v2-table-hashtag-list'}>
      {tags.map((tag, idx) => {
        return (
          <span
            key={idx}
            className="mp-content-v2-table-hashtag"
            style={{
              color: tagTextColorCode,
              backgroundColor: tagBackGroundColorCode,
            }}
          >
            {tag}
          </span>
        );
      })}
    </div>
  );
};


const URLS = ({
                urls,
                dataType,
              }) => {
  if (_.isEmpty(urls)) {
    return null;
  }

  const urlsSize = _.size(urls)
  const ModelBody = () => {
    return (
      <div className={`qalbox-${dataType}`}>
        {
          _.map(urls,({
                        order,
                        type,
                        mimeType,
                        configs,
                        src,
                        encoder,
                      }) => {

            return (
              <>
                <strong>{`Type :`}&nbsp;</strong>{type} <br/>
                { order ? <><strong>{`Order :`}&nbsp;</strong>{order} <br/></> : null }
                <strong>{`Mimetype :`}&nbsp;</strong>{mimeType} <br/>
                <strong>{`Encoder :`}&nbsp;</strong>{encoder || 'N/A'} <br/>
                <strong>{`Src :`}&nbsp;</strong><DisplayURL url={src} /> &nbsp;<CopyItem text={src} showText={false}/><br/>
                <strong>{`Configs :`}&nbsp;</strong>
                {
                  configs &&
                  <ReactJson
                    src={configs}
                    theme={'monokai'}
                    name={'Configs'}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    collapsed={true}
                  />
                }
                <br/>
                <br/>
              </>
            );
          })
        }
      </div>
    )
  };

  const onClickShow = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            headingText={`Details`}
            modalBody={<ModelBody />}
            modalBodyClassName={'precalcs-diff-merge-view'}
            closeButtonText={'Close'}
            handleClose={onClose}
            handleOk={() => {
              onClose();
            }}
          />
        );
      }
    });
  };

  return (
    <div className={'mp-content-v2-table-hashtag-list'}>
      <p>
        {`${urlsSize} URL(S) found.`}
      </p>
      <Button
        className="btn btn-primary btn-sm"
        onClick={onClickShow}
      >
        { 'Show' }
      </Button>
      <br />
    </div>
  );
};

const Images = ({
                images,
                dataType,
              }) => {
  if (_.isEmpty(images)) {
    return 'N/A';
  }

  const imagesSize = _.size(images)
  const smallImageUrl = _.get(images, 'small');

  const ModelBody = () => {
    return (
      <div className={`qalbox-${dataType}`}>
        {
          _.map(_.keys(images),(key) => {

            const url = images[key];

            return (
              <>
                <strong>{`${_.startCase(key)} Image URL :`}</strong>
                <br/>
                <DisplayURL url={url} /> &nbsp;
                <CopyItem text={url} showText={false}/>
                <br/>
                <br/>
              </>
            );
          })
        }
      </div>
    )
  };

  const onClickShow = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            headingText={`Details`}
            modalBody={<ModelBody />}
            modalBodyClassName={'precalcs-diff-merge-view'}
            closeButtonText={'Close'}
            handleClose={onClose}
            handleOk={() => {
              onClose();
            }}
          />
        );
      }
    });
  };

  return (
    <div className={'mp-content-v2-table-hashtag-list'}>
      {
        smallImageUrl &&
        <ImageZoom
          image={{
            src: `${smallImageUrl}`,
            style: { width: '64px' }
          }}
          zoomImage={{
            src: `${smallImageUrl}`,
          }}
        />
      }
      <br/>
      <br/>
      <p>
        {`${imagesSize} Image(S) found.`}
      </p>
      <br/>
      <Button
        className="btn btn-primary btn-sm"
        onClick={onClickShow}>
        { 'Show' }
      </Button>
    </div>
  );
};

const TextAlign = ({
                     text,
                     align='left',
                   }) => {
  if (!text) {
    return null;
  }

  return (
    <div style={{
      textAlign: `${align}`,
    }}>
      {text}
    </div>
  );
};

const DisplayURL = ({
                      url,
                      urlTex,
                    }) => {
  if (!url) {
    return null;
  }

  return (
    <a
      className={' text-center'}
      target="_blank"
      href={url}
    >
      {urlTex || url}
    </a>
  );
}

export {
  ImageUploadInfoPanel,
  MediaExcelUploadInfoPanel,
  DisplayCategoriesExcelUploadInfoPanel,
  CopyItem,
  Tags,
  GroupId,
  URLS,
  Images,
  TextAlign,
}
