import React,
{
  Component,
} from 'react';
import moment from 'moment-timezone';
import './style.css';

class DateTime extends Component {
  render() {
    let {
      timestamp,
    } = this.props;
    if(timestamp) {
      timestamp = parseInt(timestamp, 10);
    }
    const gmtTIME = moment(timestamp).utc().format('MMM DD, YYYY hh:mm');
    const sgtTime = moment(timestamp).tz('Asia/Singapore').format('MMM DD, YYYY hh:mm');
    return (
      <div className="datetime small">
        <p>{gmtTIME} GMT</p>
        <p>{sgtTime} SGT</p>
      </div>
    );
  }
}

export default DateTime;
