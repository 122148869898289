import _ from 'lodash';
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay';
import {
  Col,
  Row,
  Button
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  qalBoxDeleteMediaHook,
  genresSearch,
} from '../../hooks';
import {
  getDeletableMediaTagAndGenre,
  getMediaIdsToDelete,
  getGenreColumns,
  getGenreDisplayData,
  getValuesFromRowGenresAndTags,
} from './helper';
import {
  DEFAULT_SUPPORTED_LANGUAGE,
} from './Constants';
import {
  confirmAlert,
} from 'react-confirm-alert';
import AppModal from '../../widgets/modal';
import {
  ScaleLoader,
} from 'react-spinners';
import QalboxTable from '../../widgets/qalbox-table';
import CreateGenre from './createGenreForm';

const Genres = () => {
  const [ searchCriteria, setSearchCriteria ] = useState({language: DEFAULT_SUPPORTED_LANGUAGE, active: 'true'});
  const [ responseData, setOperationData ] = genresSearch();
  const [ deleteResponseData, setDeleteOperationData ] = qalBoxDeleteMediaHook();
  const [ actionState, setGenreAction ] = useState('Create');
  const [ genreObject, setGenreObject] = useState({
    id: null,
    name: ""
  });

  const handleNewGenreName = (e) => {
    const { name, value } = e.target;
    setGenreObject(prevState => {
      return {
        ...prevState,
        id: null,
        [name]: value,
      };
    });
  };

  const displayData = getGenreDisplayData(_.get(responseData, 'data', []));
  const isLoading = _.get(responseData, 'isLoading', false);
  const isDeleting = _.get(deleteResponseData, 'isDeleting', false);
  const tableError = _.get(responseData, 'isError', false) && {
    message: _.get(responseData, 'errorMsg', 'Something went wrong.'),
    isError: true,
  };

  const onOperationComplete = operationData => {
    if(operationData.isError) {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onDeleteComplete = operationData => {
    requestData(searchCriteria);
    if(operationData.isError) {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.success(`Selected Genre items deleted successfully.`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const requestData = useCallback(
    _.debounce(searchCriteriaObj => {
      setOperationData({
        searchConfig: {
          language_code: searchCriteriaObj.language,
          active: searchCriteriaObj.active,
        },
        onOperationComplete,
      });
    }, 400),
    [ searchCriteria ]
  );

  useEffect(() => {
    requestData(searchCriteria);
  }, [ searchCriteria ]);

  const createGenreModal = (action = "Create", editableObject = null) => {
    setGenreAction(action);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            showFooter={false}
            headingText={(action == 'Create') ? `Create a new Genre` : `Update Genre`}
            modalBody={ <CreateGenre handleNewGenreName={handleNewGenreName} setSearchCriteria={setSearchCriteria} action={action} genreObject={genreObject} editableObject={editableObject} onClose={onClose}/>}
            modalBodyClassName={'precalcs-diff-merge-view'}
            handleClose={onClose}
          />
        );
      }
    });
  };

  const onDeleteSelected = (selectedFlatRows) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            headingText={`Are you sure want to delete the following ${_.size(selectedFlatRows)} Genre items ?`}
            modalBody={getDeletableMediaTagAndGenre(selectedFlatRows)}
            modalBodyClassName={'precalcs-diff-merge-view'}
            closeButtonText={'No'}
            okButtonText={'Yes, Delete'}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              console.log(getMediaIdsToDelete(selectedFlatRows))
              setDeleteOperationData({
                deleteConfig: {
                  genres_ids: getMediaIdsToDelete(selectedFlatRows),
                  language_code: searchCriteria.language,
                  deleteType: 'genres'
                },
                onOperationComplete: onDeleteComplete,
              });
            }}
          />
        );
      }
    });
  };

  const onEditSelected = (selectedFlatRows) => {
    const editableRow = _.get(selectedFlatRows[0], 'values');
    const editableObject = getValuesFromRowGenresAndTags(editableRow);
    console.log({editableObject})
    setGenreObject(prevState => {
      return {
        ...prevState,
        id: editableObject.id,
        name: editableObject.name,
      };
    });
    createGenreModal("Update", editableObject);
  }

  const columns = getGenreColumns();

  return (
    <LoadingOverlay
      active={isDeleting}
      spinner={<ScaleLoader />}
      text='Deleting the selected Genre items.'
    >
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={2000}/>
        <Row>
          <Col md={9}>
            <h3 className='content_editor_title'>{'Genres'}</h3>
          </Col>
          <Col md={3} style={{paddingTop: '20px'}}>
              <Button
                onClick={ () => {
                  createGenreModal("Create");
                }}
                className='margin-all3 btn btn-success'
                style={{backgroundColor:"green", padding: '10px 20px'}}
                  >
                Create
              </Button>
          </Col>
        </Row>
        <QalboxTable 
          table={{
            data: displayData, 
            columns: columns, 
            handleDelete: onDeleteSelected,
            handleEdit: onEditSelected,
            isLoading: isLoading,
            tableError: tableError
          }}
        />
      </BasicLayout>
    </LoadingOverlay>
  );
};

export default Genres;