import React,
{
  Fragment,
} from 'react';
import Dropzone from "react-dropzone";
import {
  Col,
  Row,
  Button,
} from "react-bootstrap";
import {
  OperationStatus,
} from './common';

// This component was developed using https://react-dropzone.js.org/

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  maxHeight: '500px',
  overflow: 'scroll',
  border: '2px solid green',
  padding: '10px 8px',
  justifyContent: 'space-evenly',
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const ImagePreview = ({
                        file,
                        onRemoveFile = (file) => console.log('File to remove : ', file),
                        removeButtonText = 'Remove.',
                      }) => {

  return (
    <div className={'uploaded-file'} key={file.name}>
      <div style={thumb}>
        <div style={thumbInner}>
          <img alt="preview" src={file.preview} style={img} />
        </div>
      </div>
      <p>{file.name}</p>
      <button onClick={() => onRemoveFile(file)}
              type={'button'}
              className="margin-all3 btn btn-danger btn-xs">
        <span className="glyphicon glyphicon-trash" aria-hidden="true"/>
        &nbsp;{removeButtonText}
      </button>
    </div>
  );
};

const FilePreviews = ({
                        files,
                        onRemoveFile,
                        removeButtonText,
                      }) => {
  return files.map(file => {
    return <ImagePreview
              key={file.name}
              file={file}
              onRemoveFile={onRemoveFile}
              removeButtonText={removeButtonText}
          />;
  });
};

const MultiFileUploader = ({
                            files = [],
                            containerClassName = 'upload-container',
                            acceptTypes,
                            dropFileMainText = 'Drop files here or Click here to upload files.',
                            removeButtonText,
                            removeAllButtonText = 'Remove All',
                            showPreviews = false,
                            showRemoveAllButton = true,
                            showUploadButton = false,
                            uploadButtonText = 'Upload',
                            disableUploadButton = false,
                            onClickUpload = () => console.log('files upload clicked.'),
                            isUploading=false,
                            uploadingText='Please wait! Uploading File...',
                            onRemoveFile,
                            onRemoveAllFiles = () => console.log(`All files removed.`),
                            onDropFiles = (files) => console.log(`${files.length} files uploaded.`),
                            multiple, maxSize, minSize, disabled, maxFilesCount,
                            maxFileReachedErrorMsg = 'You have reached max files count.',
                            onDropRejected,
                            onDropAccepted,
                          }) => {
  let uploadComponent;
  if(files.length > maxFilesCount) {
    uploadComponent = (
        <Row>
          <div className={'max-files-upload-error'}>
            {maxFileReachedErrorMsg}
          </div>
        </Row>
    );
  } else {
    uploadComponent  = (
        <Fragment>
          <section className={showPreviews ? '' : containerClassName }>
            {
              showPreviews ?
                  <aside style={thumbsContainer}>
                    <FilePreviews
                        files={files}
                        onRemoveFile={onRemoveFile}
                        removeButtonText={removeButtonText}
                    />
                  </aside> : null
            }
            {
              files.length > 0 ?
                  null :
                  <Dropzone
                      onDrop={onDropFiles}
                      onDropAccepted={onDropAccepted}
                      onDropRejected={onDropRejected}
                      accept={acceptTypes}
                      multiple={multiple}
                      maxSize={maxSize}
                      minSize={minSize}
                      disabled={disabled}
                  >
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p>{dropFileMainText}</p>
                        </div>
                    )}
                  </Dropzone>
            }
          </section>
          {
            showUploadButton &&
            <Button
                style={{ 'marginTop': '5px' }}
                onClick={onClickUpload}
                disabled={disableUploadButton}
                className="btn btn-primary btn-sm"
                type={'button'}>
              {uploadButtonText}
            </Button>
          }
        </Fragment>
    );
  }

  if(showRemoveAllButton) {
    uploadComponent = (
        <Fragment>
          {
            files.length > 0 ?
                <Fragment>
                  <Row>
                    <Col>
                      <button
                          type={'button'}
                          onClick={onRemoveAllFiles}
                          className="btn btn-danger btn-sm">
                        <span className="glyphicon glyphicon-trash" aria-hidden="true"/>
                        &nbsp;{removeAllButtonText}
                      </button>
                    </Col>
                  </Row>
                  <br/>
                </Fragment>
                : null
          }
          <Row>
            {uploadComponent}
          </Row>
        </Fragment>
    );
  }

  if(isUploading) {
    uploadComponent = <OperationStatus operationInProgressText={uploadingText} />
  }

  return (
      <Fragment>
        {uploadComponent}
      </Fragment>
  );
};

export default MultiFileUploader;
