import React, { Component } from 'react';
import { Row, Col, ControlLabel, FormControl, Button } from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import DateTime from '../../widgets/datetime';
import JSONTreeWrapper from '../../widgets/jsonTree';
import './style.css';
import axios from 'axios';
import SectionLoader from '../../widgets/sectionloader';

const tokenCheckerApi = "https://us-central1-muslim-pro-app.cloudfunctions.net/adminConsoleTokenChecker";

class SubscriptionHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uniqueToken: '',
      store: 'apple',
      appleEntries: [],
      sku: '',
      rawResponse: {}
    };
  }

  componentWillMount() {
    const { state } = this.props.location;
    if(state) {
      const { store, token, receipt, sku } = state;
      const uniqueToken = store === 'apple' ? receipt : token;
      this.checkStoreUniqueToken({ store, sku, uniqueToken });
    }
  }

  handleInputChange(key, e) {
    this.setState({
      [key]: e.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { uniqueToken, store, sku } = this.state;
    this.checkStoreUniqueToken({ store, sku, uniqueToken });
  }

  async checkStoreUniqueToken(config) {
    this.updateLoader(true);
    const { store, sku, uniqueToken } = config;
    this.setState({
      store,
      uniqueToken,
      sku
    });
    if(store === "apple") {
      await this.checkAppleReceipt({
        receipt: uniqueToken
      });
    } else if(store === 'google') {
      await this.checkGoogleToken({
        token: uniqueToken,
        subscriptionId: sku
      });
    }
    this.updateLoader(false);
  }

  async checkAppleReceipt(data) {
    return axios({
      method: 'POST',
      url: `${tokenCheckerApi}/apple/`,
      headers: {
        'Content-Type': 'application/json',
        'idToken': localStorage.getItem('idToken') || "",
        'uid': localStorage.getItem('uid') || "",
      },
      data
    })
    .then((res) => {
      this.setState({
        rawResponse: res.data,
        appleEntries: res.data.body.latest_receipt_info || (res.data.body.receipt && res.data.body.receipt.in_app)
      });
    })
    .catch((err) => {
      console.log(err);
      this.setState({
        appleEntries: [],
        rawResponse: {}
      });
    });
  }

  async checkGoogleToken(data) {
    return axios({
      method: 'POST',
      url: `${tokenCheckerApi}/google/`,
      headers: {
        'Content-Type': 'application/json',
        'idToken': localStorage.getItem('idToken') || "",
        'uid': localStorage.getItem('uid') || "",
      },
      data
    })
    .then((res) => {
      this.setState({
        googleEntry: res.data,
        rawResponse: res.data
      });
    })
    .catch((err) => {
      console.log(err);
      this.setState({
        googleEntry: null,
        rawResponse: {}
      });
    });
  }

  renderAppleReceipts(appleEntries) {
    let entryEls;
    if(!appleEntries || appleEntries.length === 0) {
      entryEls = <p>No valid apple purchase found with this receipt</p>;
    } else {
      entryEls = appleEntries.map((entry, i) => {
        return <tr key={i}>
          <td>{i+1}</td>
          <td>{entry.product_id}</td>
          <td><DateTime timestamp={entry.original_purchase_date_ms} /></td>
          <td>{entry.expires_date}</td>
          <td>{entry.is_trial_period}</td>
        </tr>
      });
    }

    return (
      <table className="table apple__receipt_table">
        <thead>
          <tr>
            <th>SL</th>
            <th>Product ID  / SKU</th>
            <th>Purchase date</th>
            <th>Expiry date</th>
            <th>Is trial</th>
          </tr>
        </thead>
        <tbody>
          {entryEls}
        </tbody>
      </table>
    );
  }

  renderSku(store, sku) {
    if(store !== 'google') {
      return null;
    } else {
      return <div>
        <ControlLabel>SKU / Subscription Id / Product id</ControlLabel>
        <FormControl
          required
          className="sh_input_fields"
          value={sku}
          placeholder="Paste here"
          onChange={this.handleInputChange.bind(this, 'sku')}/>
      </div>;
    }
  }

  renderGoogleEntry(googleEntry={}) {
    if(!googleEntry) {
      return <div className="google__purchase__entry">
        The purchase token and sku seems invalid. There can be two possible reasons:<br/>
        <ol>
          <li>
            Either this token is fake or malformed
          </li>
          <li>
            Or the premium was expired more than 60 days ago, as a result Google won't show the history
          </li>
        </ol>
      </div>;
    }
    const { acknowledgementState, expiryTimeMillis, cancelReason, paymentState, userCancellationTimeMillis } = googleEntry;
    return <div className="google__purchase__entry">
      <p><strong>Premium acknowledged:</strong><br/>{acknowledgementState ? 'Yes' : 'No'}</p>
      {expiryTimeMillis ? <p><strong>Expiry:</strong><br /><DateTime timestamp={expiryTimeMillis}/></p>: null}
      {this.renderCancelReason(cancelReason, acknowledgementState)}
      {userCancellationTimeMillis ? <p><strong>User cancelled on:</strong><br /><DateTime timestamp={userCancellationTimeMillis}/></p> : null}
      {this.renderPaymentState(paymentState)}
    </div>;
  }

  renderCancelReason(cancelReason, acknowledgementState) {
    let systemCancelReason = "Yes, Google cancelled the subscription for some issue";
    if(!acknowledgementState) {
      systemCancelReason = <span>Yes, Our system failed to acknowledge the purchase because of our server failure. As a result Google cancelled the premium & refunded. Ask the user to repurchase it.</span>;
    }
    let reason = '';
    if(cancelReason === undefined || cancelReason === null) {
      reason = 'No';
    } else {
      if(cancelReason === 0) {
        reason = "Yes, User cancelled the subscription.";
      } else if(cancelReason === 1) {
        reason = systemCancelReason;
      } else {
        reason = "Yes, Subscription was cancelled for some unknown reason; user didn't cancel it.";
      }
    }
    return <div>
      <p><strong>Cancelled:</strong><br/>{reason}</p>
    </div>;
  }

  renderPaymentState(paymentState) {
    let info = '';
    if(paymentState === undefined || paymentState === null) {
      info = 'No payment info found';
    } else {
      if(paymentState === 0) {
        info = "Pending";
      } else if(paymentState === 1) {
        info = "Paid";
      } else if(paymentState === 2) {
        info = "Trial";
      } else {
        info = "Deferred";
      }
    }
    return <p><strong>Payment info:</strong><br/>{info}</p>;
  }

  updateLoader(loading) {
    let state = { loading };
    if(loading) {
      state = {
        ...state,
        appleEntries: [],
        googleEntry: null
      }
    }
    this.setState(state);
  }

  renderLoading(store, loading) {
    if(!loading) {
      return null;
    } else {
      return <SectionLoader text={`Please wait while we fetch the purchase info from ${store} store.`}/>;
    }
  }

  render() {
    const { googleEntry, appleEntries, store, sku, rawResponse, loading } = this.state;
    let renderHistory;
    let inputLabel = 'Paste the token here';
    if(store==='apple') {
      inputLabel = "Apple purchase receipt";
      renderHistory = this.renderAppleReceipts(appleEntries);
    }
    if(store==='google') {
      inputLabel = "Google purchase token";
      renderHistory = this.renderGoogleEntry(googleEntry);
    }

    return (
      <BasicLayout pagePermission="read_users">
        <Row>
          <Col md={12}>
            <h4>{`Check ${store} purchase history`}</h4>
            <br />
            <form onSubmit={this.handleSubmit.bind(this)}>
              <ControlLabel>{inputLabel}</ControlLabel>
              <FormControl className="sh_input_fields" value={this.state.uniqueToken} placeholder="Paste here" onChange={this.handleInputChange.bind(this, 'uniqueToken')}/>
              <ControlLabel>Store</ControlLabel>
              <select onChange={this.handleInputChange.bind(this, 'store')} className="form-control sh_input_fields">
                <option selected={store==='apple'} value="apple">Apple</option>
                <option selected={store==='google'} value="google">Google</option>
              </select>
              {this.renderSku(store, sku)}
              <Button type="submit" className="btn-primary btn-sm">Submit</Button>
            </form>
          </Col>
          <Col md={12}>
            {this.renderLoading(store, loading)}
          </Col>
          <Col md={12}>
            {renderHistory}
          </Col>
          <Col className="subscription_history_response" md={12}>
            <JSONTreeWrapper data={rawResponse}/>
          </Col>
        </Row>
      </BasicLayout>
    );
  }
}

export default SubscriptionHistory;
