import mpApiServer from "../../apis/mpApiServer";
class LessonIngestionService {
  validateLesson = (lessonContent) => {
    return mpApiServer
      .post("quran/learn/lessons/validate", {
        content: lessonContent,
      })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          if (data && data.success && data.status === 200) {
            return data;
          } else {
            throw new Error(data.error);
          }
        } else {
          throw new Error("LessonContentValidation error");
        }
      })
      .catch((error) => {
        const apiError = new Error(JSON.stringify(error?.response?.data));
        throw apiError;
      });
  };

  uploadLessonCsv = (file, language) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("language", language);

    return mpApiServer
      .post("quran/learn/lessons/populate", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const apiError = new Error(JSON.stringify(error?.response?.data));
        throw apiError;
      });
  };
}

export default new LessonIngestionService();
