import React from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Icon,
} from 'react-fa';
import  {
  Redirect,
} from 'react-router-dom'
import Sidebar from '../widgets/sidebar';
import PageWrapper from '../widgets/roledwrapper/pagewrapper';
import MobileMenu from '../widgets/mobilemenu';
import './style.css';

const Menu = ({
                user,
                alertMessage,
                authenticating,
                usersPermission,
                layoutComponent,
                pageRenderingPermission,
              }) => {

  if(user) {
    if(pageRenderingPermission === null) {

      return (
        <Row className={"basiclayout__row "+(alertMessage ? 'has-alert' : '')}>
          <Col className="text-center" md={12}>
            <Icon spin name="cog" className="permission_spinner"/>
          </Col>
        </Row>
      );
    } else {

      return (
        <div>
          <MobileMenu user={user} usersPermission={usersPermission} />
          <Row className={"basiclayout__row "+(alertMessage ? 'has-alert' : '')}>
            <Col sm={3}>
              <Sidebar usersPermission={usersPermission}/>
            </Col>
            <Col className="basiclayout__content" sm={9}>
              <PageWrapper show={pageRenderingPermission}>
                {layoutComponent}
              </PageWrapper>
            </Col>
          </Row>
        </div>
      );
    }
  } else if(!user && authenticating === false) {

    return <Redirect to='/login' />
  } else {

    return null;
  }
}

export default Menu;
