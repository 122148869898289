import { useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { qalBoxCreateGenreHook } from "../../hooks";

const CreateGenre = (props) => {
  const [ genreOBJ, setGenreObj ] = useState(
    {
      id : (props.editableObject ? props.editableObject.id : null),
      name : (props.editableObject ? props.editableObject.name : null)    
    }
  );
  const [createUpdateData, doCreateOrUpdate] = qalBoxCreateGenreHook();

  const handleSaveNewGenreName = async (event) => {
    setGenreObj(prevState => {
      const object = prevState;
      const fileUpdateHookConfig = {};
      doCreateOrUpdate({
        items:object,
        onAddOperationComplete,
        ...fileUpdateHookConfig,
      });
      props.setSearchCriteria(prev => ({...prev}));
      props.onClose();
      return {
        ...prevState
      }
    });
  };

  const onAddOperationComplete = () => {
    if (createUpdateData) {
      // props.onClose();
      // props.setSearchCriteria();
    } else {
      alert("Creation / Update of New Genre failed.");
    }
  };

  return (
    <Col md={12}>
    <br></br>
    <Row>
        <Col md={6}>
            <div className="form-group">
            <label>Name</label>
              <input
                name="name"
                value={genreOBJ.name}
                onChange={(e) => setGenreObj(prev => ({...prev, name:e.target.value}))}
                type="text"
                className="form-control"
                placeholder="Name"
              />
            </div>
        </Col>
    </Row>
    <br></br>
    <Row>
        <Col md={2}>
            <Button
                onClick={() => handleSaveNewGenreName()}
                className='margin-all3 btn btn-success btn-md'
            >
              { props.action == "Create" ? 'Create' : 'Update' }
            </Button>
        </Col>
        <Col md={2}>
            <Button
                onClick={() => props.onClose()}
                className='margin-all3 btn btn-error btn-md'
            >
              Cancel
            </Button>
        </Col>
    </Row>
  </Col>
  )
}

export default CreateGenre;