import _ from 'lodash';
import React,
{
  Fragment,
  Component,
} from 'react';
import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import ReactTags from 'react-tag-autocomplete';
import ReactJson from 'react-json-view'
import BootstrapTable from 'react-bootstrap-table-next';
import {
  CopyToClipboard,
} from 'react-copy-to-clipboard';
import {
  generatePrecalcs,
  validatePrecalcs,
  getRawPrecalcsList,
} from '../../services/mp-service-v1-api-service';
import './style.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import {
  PRECALC_FILES,
  PRECALC_OPERATIONS,
  SHEET_NUMBER_TYPES,
  SUCCESS_LIST_TABLE_HEADERS,
  COUNTRIES, ERROR_LIST_TABLE_HEADERS,
} from './Constants';

import {
  getDocuments,
  getFailedList,
  getSuccessList,
  getSelectedSheets,
  getGlobalWarnings,
  getPrecalcListIds,
  getSelectedDocumentUrl,
  shouldShowSubmitButton,
  getErrorListJSONToCopy,
  getSuccessListJSONToCopy,
  getPrecalcListUpdateStatus,
  getValidateOrPushApiPayload,
  getDiffableOldCountryListJSON,
  getDiffableCountryListJSONById,
} from './helper';
import PrecalcListMerger from './precalcListMerger';
import {
  confirmAlert,
} from "react-confirm-alert";
import AppModal from '../../widgets/modal';
import {
  createLog,
} from '../../services/logs';

const countries = _.sortBy(Object.keys(COUNTRIES).map((key) => {
  return {
    key,
    value: `${COUNTRIES[key]} - ${key}`
  }
}), ['value'] );

const operations = Object.keys(PRECALC_OPERATIONS).map((key) => {
  return {
    key,
    value: PRECALC_OPERATIONS[key]
  }
});

const sheetNumberTypes = Object.keys(SHEET_NUMBER_TYPES).map((key) => {
  return {
    key,
    value: SHEET_NUMBER_TYPES[key]
  }
});

const sheetNumberRegex = /^[1-9][0-9]*$/;

const getErrorRows = (row) => {
  const errorsListObj = row['errors'];
  const errorsList = Object.keys(errorsListObj).map((key, index) =>
      <li
          className={'list-group-item list-group-item-danger precalc-error-list-item'}
          key={index}
      >
        {`${index + 1}. ${errorsListObj[key]}`}
      </li>
  );

  const warningsListObj = row['warnings'];
  let warningsList = null;
  if(!_.isEmpty(warningsListObj)) {
    warningsList = Object.keys(warningsListObj).map((key, index) =>
        <li
            className={'list-group-item list-group-item-warning precalc-error-list-item'}
            key={index}
        >
          {`${index + 1}. ${warningsListObj[key]}`}
        </li>
    );
  }

  const errorsJSON = JSON.stringify(errorsListObj);

  return (
      <div className={'precalc-city-error-list-container'} >
        <CopyToClipboard
            text={errorsJSON}
            onCopy={() => {
              console.log(`Copied : ${errorsJSON}`)
            }}
        >
          <div className={'copy-single-errors-list'}>
            <p className={'copy-single-errors-list-text'}>
              Copy Errors
            </p>
            <Button className="btn btn-xs btn-dark">
              &nbsp;<span className="glyphicon glyphicon-duplicate" aria-hidden="true"/>
            </Button>
          </div>
        </CopyToClipboard>
        <ul className={'precalc-city-error-list'}>
          {errorsList}
          {warningsList}
        </ul>
      </div>
  )
};

const getSuccessRows = (row) => {
  const warningsListObj = row['warnings'];
  if(_.isEmpty(warningsListObj)){
    return (
        <li className={'precalc-no-warning-list-item'}>
          <p className={'precalc-no-warning-list-item-text'}>No warnings found!</p>
        </li>
    );
  }

  const warningsJSON = JSON.stringify(warningsListObj);

  const warningsList = Object.keys(warningsListObj).map((key, index) =>
      <li
          className={'list-group-item list-group-item-warning precalc-error-list-item'}
          key={index}
      >
        {`${index + 1}. ${warningsListObj[key]}`}
      </li>
  );

  return (
      <div className={'precalc-city-error-list-container'} >
        <CopyToClipboard
            text={warningsJSON}
            onCopy={() => {
              console.log(`Copied : ${warningsJSON}`)
            }}
        >
          <div className={'copy-single-errors-list'}>
            <p className={'copy-single-errors-list-text'}>
              Copy Warnings
            </p>
            <Button className="btn btn-xs btn-dark">
              &nbsp;<span className="glyphicon glyphicon-duplicate" aria-hidden="true"/>
            </Button>
          </div>
        </CopyToClipboard>
        <ul className={'precalc-city-error-list'}>
          {warningsList}
        </ul>
      </div>
  )
};

const successExpandRowConfigs = {
  renderer: row => getSuccessRows(row),
  showExpandColumn: true,
  expandByColumnOnly: true,
  onlyOneExpanding: true,
  expandColumnPosition: 'right',
  expandColumnRenderer: ({ expanded }) => {
    if (expanded) {
      return (
          <button type={'button'}
                  className="btn btn-info btn-xs">
            <span className="glyphicon glyphicon-collapse-down" aria-hidden="true"/>
            &nbsp;{'Hide Warnings'}
          </button>
      );
    }
    return (
        <button type={'button'}
                className="btn btn-warning btn-xs">
          <span className="glyphicon glyphicon-collapse-up" aria-hidden="true"/>
          &nbsp;{'Show Warnings'}
        </button>
    );
  },
};

const errorExpandRowConfigs = {
  renderer: row => getErrorRows(row),
  showExpandColumn: true,
  expandByColumnOnly: true,
  onlyOneExpanding: true,
  expandColumnPosition: 'right',
  expandColumnRenderer: ({ expanded }) => {
    if (expanded) {
      return (
          <button type={'button'}
                  className="btn btn-info btn-xs">
            <span className="glyphicon glyphicon-collapse-down" aria-hidden="true"/>
            &nbsp;{'Hide Errors'}
          </button>
      );
    }
    return (
        <button type={'button'}
                className="btn btn-danger btn-xs">
          <span className="glyphicon glyphicon-collapse-up" aria-hidden="true"/>
          &nbsp;{'Show Errors'}
        </button>
    );
  },
};

const getSuccessListRows = (successListObj, selectedGoogleDocument) => {
  return _.map(_.toPairs(successListObj), (value, index) => {
    const sheetData = value[1];
    const workSheetId = sheetData['sheet_id'];
    const sheetTitle = (
        <a target={'_blank'}
           href={`${selectedGoogleDocument}/edit#gid=${workSheetId}`}>
          {value[0]}
        </a>
    );

    return {
      id: index + 1,
      sheetTitle,
      warningsCount: sheetData['warnings_count'],
      warnings: sheetData['warnings'],
    };
  })
};

const getFaildListRows = (failedListObj, selectedGoogleDocument) => {
  return _.map(_.toPairs(failedListObj), (value, index) => {
    const sheetData = value[1];
    const workSheetId = sheetData['sheet_id'];
    const sheetTitle = (
        <a target={'_blank'}
           href={`${selectedGoogleDocument}/edit#gid=${workSheetId}`}>
          {value[0]}
        </a>
    );
    return {
      id: index + 1,
      sheetTitle,
      warningsCount: sheetData['warnings_count'],
      errorsCount: sheetData['errors_count'],
      warnings: sheetData['warnings'],
      errors: sheetData['errors'],
    };
  })
};


const InitialState = {
  reloadPage: false,
  submitting: false,
  selectedCountry: 'default',
  selectedDocument: 'default',
  selectedSheetNumberType: 'default',
  selectedOperation: 'Validate',
  selectedSheetNumbers: [],
  startSheetNumber: '',
  endSheetNumber: '',
  apiResponseData: null,
  existingPrecalcsList: null,
  diffableCountryOldJSONById: null,
  diffableCountryNewJSONById: null,
  precalcListIds: [],
};

class Precalc extends Component {
  constructor(props) {
    super(props);
    this.state = InitialState;
  }

  componentDidMount() {
    this.setState({
          loading: true,
        },
        () => {
          this.fetchPrecalcList().then(() => console.log('Precacl list fetch success!'));
        });
  }

  async fetchPrecalcList() {
    try {
      const apiResponse = await getRawPrecalcsList();
      const response = apiResponse.data;

      if(response.success) {
        const existingPrecalcsList = response.data;
        this.setState({
          loading: false,
          existingPrecalcsList,
        })
      } else {
        this.setState({
          error: 'Something went wrong while precalc list.',
          loading: false,
        })
      }
    } catch (err) {
      console.log(`Something went wrong while precalc list. Error : ${err}`);
      this.setState({
        error: 'Something went wrong while precalc list.',
        loading: false,
      })
    }
  }

  async handleSubmit() {
    const {
      selectedDocument, selectedSheetNumberType, selectedOperation, selectedSheetNumbers,
      selectedCountry, startSheetNumber, endSheetNumber, existingPrecalcsList,
    } =  this.state;

    const apiPayload = getValidateOrPushApiPayload({ selectedDocument, selectedSheetNumberType,
      selectedCountry, selectedSheetNumbers, startSheetNumber, endSheetNumber});

    try {
      console.log('apiPayload : ', JSON.stringify(apiPayload));
      if (apiPayload) {
        const precalcApiResp = selectedOperation === PRECALC_OPERATIONS.Validate ?
            await validatePrecalcs(apiPayload) : await generatePrecalcs(apiPayload);

        createLog(`precalc_${selectedOperation}_success`.toLowerCase(), {
          apiPayload: apiPayload,
        }).then(() => console.log('Precalc Action logging success!!'))
          .catch(() => console.log('Precalc Action logging failed!'));

        const responseData = precalcApiResp.data;
        if(responseData.success) {
          console.log('success');
          toast.success('Operation completed!', {
            position: toast.POSITION.BOTTOM_LEFT
          });
          const apiResponseData = responseData.data;
          if(selectedOperation === PRECALC_OPERATIONS.Validate) {
            this.setState({
              apiResponseData,
              submitting: false,
            });
          } else {
            if(apiResponseData['should_update_precalc_list_manually']) {
              const selectedCountryOldJSON = existingPrecalcsList[selectedCountry];
              const selectedCountryNewJSON =
                  apiResponseData['conflicted_precalc_list'][selectedCountry];
              const diffableCountryOldJSON =
                  getDiffableOldCountryListJSON(selectedCountryOldJSON, selectedCountryNewJSON);
              const diffableCountryOldJSONById =
                  getDiffableCountryListJSONById(diffableCountryOldJSON);
              const diffableCountryNewJSONById =
                  getDiffableCountryListJSONById(selectedCountryNewJSON);
              const precalcListIds = getPrecalcListIds(diffableCountryNewJSONById);

              this.setState({
                apiResponseData,
                diffableCountryOldJSONById,
                diffableCountryNewJSONById,
                precalcListIds,
                submitting: false,
              });
            } else {
              this.setState({
                apiResponseData,
                submitting: false,
              });
            }
          }

        } else {
          console.log(`Received error API Response . ${JSON.stringify(precalcApiResp)}`);
          toast.error(`Something went wrong!, Please try again.`, {
            position: toast.POSITION.BOTTOM_LEFT
          });
          this.setState({
            submitting: false,
          });
        }
      }
    } catch (err) {
      console.log(`Something went wrong while saving the content. Error :  ${err}`);
      createLog(`precalc_${selectedOperation}_error`.toLowerCase(), {
        apiPayload: apiPayload,
      }).then(() => console.log('Precalc Action logging success!!'))
        .catch(() => console.log('Precalc Action logging failed!'));

      if (err.response) {
        // Request made and server responded
        const { error } = err.response.data;
        if(error.type === 'APIError' && error.code === 2000) {
          toast.error('Requests Quota Exceeded to Google Sheets API!, Please try again with few ' +
              'number of files or wait few hours before try again.', {
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 7000,
          });
        } else {
          toast.error(`Something went wrong!, Please try again.`, {
            position: toast.POSITION.BOTTOM_LEFT
          });
        }
      } else {
        toast.error(`Something went wrong!, Please try again.`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }

      this.setState({ submitting: false });
    }
  }

  onSelectChange(type, e) {
    const value = e.target.value;
    if(type === 'countries') {
      this.setState({
        selectedCountry : value,
        selectedDocument : 'default',
        selectedSheetNumberType: 'default',
        selectedOperation: 'Validate',
        apiResponseData: null,
        selectedSheetNumbers: [],
        startSheetNumber: '',
        endSheetNumber: '',
      });
    }

    if(type === 'documents') {
      this.setState({
        selectedDocument : value,
        selectedSheetNumberType: 'default',
        selectedOperation: 'Validate',
        apiResponseData: null,
        selectedSheetNumbers: [],
        startSheetNumber: '',
        endSheetNumber: '',
      });
    }

    if(type === 'sheetNumberTypes') {
      this.setState({
        selectedSheetNumberType : value,
        selectedOperation: 'Validate',
        apiResponseData: null,
        selectedSheetNumbers: [],
        startSheetNumber: '',
        endSheetNumber: '',
      });
    }

    if(type === 'operations') {
      this.setState({
        selectedOperation : value,
        apiResponseData: null,
      });
    }
  }

  onFieldChange(fieldKey, e) {
    const sheetNumber = e.target.value;
    if(sheetNumber === '' || sheetNumberRegex.test(sheetNumber)){
      this.setState({
        [fieldKey]: sheetNumber,
        apiResponseData: null,
      });
    }
  }

  renderSelect(arr, selectedItem, type) {
    const options = arr.map((item, i) => {
      return (
          <option value={item.key} key={i}>{item.value}</option>
      );
    });

    return (
        <select value={selectedItem}
                onChange={this.onSelectChange.bind(this, type)}
                className="form-control">
          <option disabled value="default">Please select</option>
          {options}
        </select>
    );
  }

  confirmPrecalcsOperation = () => {
    this.setState({
      submitting: true,
      apiResponseData: null,
    }, () => this.handleSubmit());
  };

  resetPrecalcValidatePush = () => {
    this.setState({ reloadPage: true })
  };

  updatePrecalcSubmit = () => {
    const {
      selectedDocument, selectedSheetNumberType, selectedOperation, selectedSheetNumbers,
      selectedCountry, startSheetNumber, endSheetNumber,
    } =  this.state;

    const headerText = selectedOperation === PRECALC_OPERATIONS.Validate ?
        `Are you sure want to validate the following sheets for ${selectedCountry} ?` :
        `Are you sure want to push the following sheets for ${selectedCountry} ?`;
    const confirmBtnText = selectedOperation === PRECALC_OPERATIONS.Validate ?
        `Yes, Validate` : `Yes, Push`;
    const selectedGoogleDocument = getSelectedDocumentUrl(selectedDocument);
    const selectedSheets = getSelectedSheets(selectedSheetNumberType, selectedSheetNumbers,
        startSheetNumber, endSheetNumber);
    const CountrySheets = _.invert(PRECALC_FILES[selectedCountry]);

    const modalBody = (
        <div className={'precalc-confirm-window-body'}>
          <table className={'table table-responsive'}>
            <tbody>
            <tr>
              <td className={'precalc-confrim-data-key'}>
                Country :
              </td>
              <td>
                {`${COUNTRIES[selectedCountry]} - ${selectedCountry}`}
              </td>
            </tr>
            <tr>
              <td className={'precalc-confrim-data-key'}>
                Selected Document :
              </td>
              <td>
                <a
                    href={selectedGoogleDocument}
                    target="_blank">
                  {`Document - ${CountrySheets[selectedDocument]}`}
                </a>
              </td>
            </tr>
            <tr>
              <td className={'precalc-confrim-data-key'}>
                Selected Sheet Number Type :
              </td>
              <td>
                {`${SHEET_NUMBER_TYPES[selectedSheetNumberType]}`}
              </td>
            </tr>
            <tr>
              <td className={'precalc-confrim-data-key'}>
                Selected Sheets :
              </td>
              <td>
                {selectedSheets}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
    );

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <AppModal
                show={true}
                showBody={true}
                headingText={headerText}
                modalBody={modalBody}
                modalBodyClassName={'precalcs-diff-merge-view'}
                closeButtonText={'No'}
                okButtonText={confirmBtnText}
                handleClose={onClose}
                handleOk={() => {
                  this.confirmPrecalcsOperation();
                  onClose();
                }}
            />
        );
      }
    });
  };


  renderSubmitButton(submitting, operation) {
    const submitButtonText = operation === PRECALC_OPERATIONS.Validate ? 'Validate' : 'Push';
    const submittingButtonText =
        operation === PRECALC_OPERATIONS.Validate ? 'Validating...' : 'Pushing...';
    return (
        <Button
            onClick={this.updatePrecalcSubmit}
            disabled={submitting}
            className="btn-primary">
          { submitting ? submittingButtonText : submitButtonText}
        </Button>
    );
  }

  renderRestartButton(submitting) {

    return (
        <Button
            onClick={this.resetPrecalcValidatePush}
            disabled={submitting}
            className="validate-push-reset-btn">
          {'Restart'}
        </Button>
    );
  }

  handleSheetNumbersDelete (i) {
    const sheetNumbers = this.state.selectedSheetNumbers.slice(0);
    sheetNumbers.splice(i, 1);
    this.setState({
      selectedSheetNumbers: sheetNumbers,
      apiResponseData: null,
    });
  }

  handleSheetNumbersAddition (sheetNumber) {
    const sheetNumbers = _.uniqBy(this.state.selectedSheetNumbers.concat(sheetNumber), 'name');
    this.setState({
      selectedSheetNumbers: sheetNumbers,
      apiResponseData: null,
    });
  }

  handleSheetNumbersValidation (sheetNumber) {
    return sheetNumberRegex.test(sheetNumber.name);
  }

  renderSheetNumbersInput(selectedSheetNumberType, selectedSheetNumbers,
                          startSheetNumber, endSheetNumber, selectedDocument) {
    if(selectedDocument === 'default') {
      return null;
    }
    switch (selectedSheetNumberType) {
      case SHEET_NUMBER_TYPES.Custom:
        return (
            <Row className={'precalc-ranges'}>
              <Col xs={12}>
                <div className="form-group">
                  <label>Sheet Numbers: </label>
                  <ReactTags
                      minQueryLength={1}
                      allowNew
                      delimiterChars={[',', ' ']}
                      placeholder={'Add Sheet Numbers'}
                      tags={selectedSheetNumbers}
                      handleDelete={this.handleSheetNumbersDelete.bind(this)}
                      handleAddition={this.handleSheetNumbersAddition.bind(this)}
                      handleValidate={this.handleSheetNumbersValidation.bind(this)}
                  />
                </div>
              </Col>
            </Row>
        );
      case SHEET_NUMBER_TYPES.Range:
        return (
            <Row className={'precalc-ranges'}>
              <Col xs={6}>
                <div className="form-group">
                  <label style={{'marginRight': '5px'}}>{'From : '}</label>
                  <input value={startSheetNumber}
                         required
                         onChange={this.onFieldChange.bind(this, 'startSheetNumber')}
                         type="text"
                         className="form-control"
                         placeholder="Starting Number"
                  />
                </div>
                <div className="form-group precalc-end-num">
                  <label style={{'marginRight': '5px'}}>{'To : '}</label>
                  <input value={endSheetNumber}
                         required
                         onChange={this.onFieldChange.bind(this, 'endSheetNumber')}
                         type="text"
                         className="form-control"
                         placeholder="Ending Number"
                  />
                </div>
              </Col>
            </Row>
        );
      default:
        return null
    }
  }

  renderUpdatePrecalListMerger({
                                 apiResponseData, existingPrecalcsList, diffableCountryOldJSONById,
                                 diffableCountryNewJSONById, precalcListIds, selectedCountry,
                                 reloadPage,
                                }) {
    const shouldUpdatePrecalcList = getPrecalcListUpdateStatus(apiResponseData);
    if(!apiResponseData || !shouldUpdatePrecalcList) {
      return null
    }

    if(existingPrecalcsList) {
      // Old value found we can do a update now.
      const precalcListUpdateMsg =
          'Opz! Conflict detected, Precaclcs List file should update manually.';
      const precalcListInstructMsg =
          'Let`s try to fix the issue. Please look at the diff below for each conflicted ' +
          'files and add them to merge view.';

      return (
          <PrecalcListMerger
              diffableCountryNewJSONById={diffableCountryNewJSONById}
              diffableCountryOldJSONById={diffableCountryOldJSONById}
              precalcListIds={precalcListIds}
              selectedCountry={selectedCountry}
              precalcListUpdateMsg={precalcListUpdateMsg}
              precalcListInstructMsg={precalcListInstructMsg}
              reloadPage={reloadPage}
          />
      );
    } else {
      // We should update manually later.
      const precalcListUpdateMsg =
          'Opz! Conflict detected, Precaclcs List file should update manually.';
      const precalcListInstructMsg =
          'Please copy the following section and update the Precalcs List file.';

      return (
          <Row>
            <Col md={12} className={'precalc-list-manual-update'}>
              <p className={'precalc-list-update-title'}>{precalcListUpdateMsg}</p>
              <p className={'precalc-list-update-instructions'}>{precalcListInstructMsg}</p>
            </Col>
          </Row>
      );
    }
  }

  renderGlobalWarnings(apiResponseData) {
    if(!apiResponseData) {
      return null;
    }

    const warningsList = getGlobalWarnings(apiResponseData);
    if(_.isEmpty(warningsList)) {
      return null
    }

    // TODO: Change the logic to show warnings, if we happen to have more than one warnings
    // Currently we assume that, we only get one warning. i.e : radius_missing

    return (
        <Row>
          <Col md={12} className={'global-warnings'}>
            <div className="alert alert-warning mt-5" role="alert">
              <h4 className="alert-heading">Warning!</h4>
              <p className={'global-warning-header'}>
                You are about to push precalc where one or more cities have radius field.
                If you have added / updated / removed radius field, make sure you:
              </p>
              <hr/>
              <ul className={'global-warning-bullet-points'}>
                <li className="global-warning-bullet-point">
                  Have gone through this document&nbsp;
                  <a href={'_target'} >
                    https://docs.google.com/document/d/1fNOgO-a-e4l-iTak-Ic1wyckzfGxrVrk0GjTr2kR-lA
                  </a>
                  &nbsp;previously and understood the usage of radius
                </li>
                <li className="global-warning-bullet-point">
                  Understand how we try to maximize the precalc coverage in a country
                </li>
                <li className="global-warning-bullet-point">
                  Understand how precalc cities with radius and without radius work together on a country
                </li>
                <li className="global-warning-bullet-point">
                  Vulnerabilities of misuse of radius
                </li>
              </ul>
            </div>
          </Col>
        </Row>
    );
  }

  renderSuccessList(successList, selectedOperation, selectedGoogleDocument) {
    if(!successList){
      return null
    }

    const operationTitle = selectedOperation === PRECALC_OPERATIONS.Validate ?
        'No major issues found in the following sheets.' : 'Following sheets pushed successfully.';
    const successListData = getSuccessListRows(successList, selectedGoogleDocument);
    const successListJSON = getSuccessListJSONToCopy(successList);

    return (
        <Row>
          <Col md={12} className={'precalc-results-success'}>
            <div className={'success-list-header'}>
              <p className={'precalc-results-success-title'}>{operationTitle}</p>
              <CopyToClipboard
                  text={successListJSON}
                  onCopy={() => {
                    console.log(`Copied : ${successListJSON}`)
                  }}
              >
                <div className={'copy-success-list'}>
                  <p className={'copy-success-list-text'}>
                    Copy All
                  </p>
                  <Button className="btn btn-xs btn-dark">
                    &nbsp;<span className="glyphicon glyphicon-duplicate" aria-hidden="true"/>
                  </Button>
                </div>
              </CopyToClipboard>
            </div>
            <div className={'precalc-results-success-table'}>
              <BootstrapTable
                  keyField='id'
                  data={ successListData }
                  columns={ SUCCESS_LIST_TABLE_HEADERS }
                  expandRow={ successExpandRowConfigs }
              />
            </div>
          </Col>
        </Row>
    );
  }

  renderFailedList(failedList, selectedOperation, selectedGoogleDocument) {
    if(!failedList){
      return null
    }

    const operationTitle = selectedOperation === PRECALC_OPERATIONS.Validate ?
        'Some major issues found in the following sheets.' : 'Following sheets have failed to push.';

    const errorsList = getFaildListRows(failedList, selectedGoogleDocument);
    const errorsListJSON = getErrorListJSONToCopy(failedList);

    return (
        <Row>
          <Col md={12} className={'precalc-results-failed'}>
            <div className={'failed-list-header'}>
              <p className={'precalc-results-failed-title'}>{operationTitle}</p>
              <CopyToClipboard
                  text={errorsListJSON}
                  onCopy={() => {
                    console.log(`Copied : ${errorsListJSON}`)
                  }}
              >
                <div className={'copy-errors-list'}>
                  <p className={'copy-errors-list-text'}>
                    Copy All
                  </p>
                  <Button className="btn btn-xs btn-dark">
                    &nbsp;<span className="glyphicon glyphicon-duplicate" aria-hidden="true"/>
                  </Button>
                </div>
              </CopyToClipboard>
            </div>
            <div className={'precalc-results-failed-table'}>
              <BootstrapTable
                  keyField='id'
                  data={ errorsList }
                  columns={ ERROR_LIST_TABLE_HEADERS }
                  expandRow={ errorExpandRowConfigs }
              />
            </div>
          </Col>
        </Row>
    );
  }

  render() {
    const {
      selectedCountry, selectedDocument, selectedSheetNumberType, selectedOperation, submitting,
      selectedSheetNumbers, startSheetNumber, endSheetNumber, apiResponseData, existingPrecalcsList,
      diffableCountryOldJSONById, diffableCountryNewJSONById, precalcListIds, reloadPage,
    } = this.state;

    if(reloadPage) {
      window.location.reload();
    }

    const documents = getDocuments(selectedCountry);
    const selectedGoogleDocument = getSelectedDocumentUrl(selectedDocument);
    const showSubmitButton = shouldShowSubmitButton(selectedSheetNumberType, selectedSheetNumbers,
        startSheetNumber, endSheetNumber, selectedDocument);
    const successList = getSuccessList(apiResponseData);
    const failedList = getFailedList(apiResponseData);

    return (
        <BasicLayout pagePermission="moderate_precalc">
          <ToastContainer autoClose={2000} />
          <div>
            <form className={'form-inline'}>
              <Row>
                <h3>{'Validate or Push Precalcs Sheets'}</h3>
              </Row>
              <br/>
              <Row>
                <Col md={4}>
                  <div className="form-group">
                    <label style={{'marginRight': '5px'}}>{'Country : '}</label>
                    {this.renderSelect(countries, selectedCountry, 'countries')}
                  </div>
                </Col>
                {
                  documents ?
                      <Col md={4}>
                        <div className="form-group">
                          <label style={{'marginRight': '5px'}}>{'Document : '}</label>
                          {this.renderSelect(documents, selectedDocument, 'documents')}
                          {
                            selectedGoogleDocument ?
                                <Fragment>
                                  <a
                                      style={{'marginLeft': '5px'}}
                                      href={selectedGoogleDocument}
                                      target="_blank">
                                    Click to Open.
                                  </a>
                                </Fragment> : null
                          }
                        </div>
                      </Col> : null
                }
                {
                  selectedGoogleDocument ?
                      <Col md={4}>
                        <div className="form-group">
                          <label style={{'marginRight': '5px'}}>{'Sheet Numbers Type : '}</label>
                          {this.renderSelect(sheetNumberTypes, selectedSheetNumberType, 'sheetNumberTypes')}
                        </div>
                      </Col>
                      : null
                }
              </Row>
              {
                this.renderSheetNumbersInput(selectedSheetNumberType, selectedSheetNumbers,
                  startSheetNumber, endSheetNumber, selectedDocument)
              }
              {
                showSubmitButton > 0 ?
                    <Row className={'precalc-submit'}>
                      <Col md={6}>
                        <div className="form-group">
                          <label style={{'marginRight': '5px'}}>{'Operation : '}</label>
                          {this.renderSelect(operations, selectedOperation, 'operations')}
                        </div>
                        <div className="form-group validate-push-config-btn-main">
                          {this.renderSubmitButton(submitting, selectedOperation)}
                        </div>
                        <div className="form-group validate-push-config-btn-main">
                          {this.renderRestartButton(submitting)}
                        </div>
                      </Col>
                    </Row>
                    : null
              }
              {
                this.renderUpdatePrecalListMerger({
                  apiResponseData, existingPrecalcsList, diffableCountryOldJSONById,
                  diffableCountryNewJSONById, precalcListIds, selectedCountry, reloadPage,
                })
              }
              {this.renderGlobalWarnings(apiResponseData)}
              {this.renderSuccessList(successList, selectedOperation, selectedGoogleDocument)}
              {this.renderFailedList(failedList, selectedOperation, selectedGoogleDocument)}
              {
                apiResponseData ?
                    <Row className={'precalc-results-for-dev'}>
                      <ReactJson
                          src={apiResponseData}
                          theme={"monokai"}
                          name={'Operation Results'}
                          displayDataTypes={false}
                          displayObjectSize={false}
                          collapsed={true}
                      />
                    </Row> : null
              }
            </form>
          </div>
        </BasicLayout>
    );
  }
}

export default Precalc;
