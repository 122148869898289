import { actionTypes } from './actionTypes';

const RCCInitialState = {
  selectedAPI: '',
  selectedFlushType: '',
  selectedCacheKey: '',
  mainCacheKey: '',
  subCacheKey: '',
};

const redisClearCacheReducer = (state, { type , payload }) => {
  switch (type) {
    case actionTypes.RCC_UPDATE_PROP_VAL:
      const {
        prop,
        value,
      } = payload;

      if(prop === 'selectedAPI') {
        return {
          ...state,
          selectedFlushType: '',
          selectedCacheKey: '',
          mainCacheKey: '',
          subCacheKey: '',
          [prop]: value,
        };
      }

      if(prop === 'selectedFlushType') {
        return {
          ...state,
          selectedCacheKey: '',
          mainCacheKey: '',
          subCacheKey: '',
          [prop]: value,
        };
      }

      return {
        ...state,
        [prop]: value,
      };

    case actionTypes.RCC_RESET_STATE:
      return {
        ...RCCInitialState,
      };

    default:
      return state;
  }
};

export {
  RCCInitialState,
  redisClearCacheReducer,
};
