import React  from 'react';
import BasicLayout from '../../layouts/basiclayout';
import UpdateSightings from './updateSightings';

const RamadanSightings = () => {

  return (
      <BasicLayout pagePermission="moderate_precalc">
        <UpdateSightings
            dataKey={'ramadan'}
            title={'Ramadan Sightings'}
        />
      </BasicLayout>
  );
}

export default RamadanSightings;
