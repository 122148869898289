import _ from 'lodash';
import firebase from 'firebase/app';
import AdminUserService from "../service_v2/users/admin-user-service";

const fetchContentV2TrendingHashtagConfigs = async () => {
  const dbPath = 'content-v2/trending_hashtags/config';
  const snapshot = await firebase
    .database()
    .ref(dbPath)
    .once('value');

  return snapshot.val();
};

const fetchContentV2TrendingHashtags = async ({
                                                languageCode,
                                              }) => {
  const dbPath = `content-v2/trending_hashtags/hashtags/${languageCode}`;
  const snapshot = await firebase
    .database()
    .ref(dbPath)
    .once('value');

  return snapshot.val();
};

const updateContentV2TrendingHashtags = async ({
                                                 type,
                                                 updatedData,
                                                 languageCode,
                                               }) => {
  if(!languageCode || !type || _.isEmpty(updatedData)) {
    return false;
  }

  const dbPath = `content-v2/trending_hashtags/hashtags/${languageCode}/${type}`;
  return firebase
    .database()
    .ref(dbPath)
    .set(updatedData);
};

const fetchContentV2TrendingHashtagsApiLimit = async () => {
  const dbPath = `content-v2/trending_hashtags/config/max_count`;
  const snapshot = await firebase
    .database()
    .ref(dbPath)
    .once('value');

  return snapshot.val();
};

const updateContentV2TrendingHashtagsApiLimit = async ({
                                                         apiLimit,
                                                       }) => {
  if(!apiLimit) {
    return false;
  }

  const dbPath = `content-v2/trending_hashtags/config`;
  return firebase
    .database()
    .ref(dbPath)
    .update({
      max_count: parseInt(apiLimit, 10),
    });
};

const updateUserFreeTrialStatus = async ({
                                           uid,
                                           status=true,
                                         }) => {
  if(!uid) {
    return false;
  }

  return AdminUserService.updateUserDate(uid,{
    'premium_status/free_trial': status
  })
};

const getUser = async ({
                         uid,
                       }) => {
  if(!uid) {
    return null;
  }

  return AdminUserService.queryUserData(uid, {
    path: '/'
  })
};

const updateUserPremiumData = async ({
                                       uid,
                                       premiumData,
                                     }) => {
  if(!uid || _.isEmpty(premiumData)) {
    return false;
  }

  return AdminUserService.updateUserDate(uid, {
    'premium_status': premiumData
  })
};

const removeUserData = async ({
                                uid,
                                node,
                              }) => {
  if(!uid || !node) {
    return false;
  }

  return AdminUserService.deleteUserData(uid, {
    [`${node}`]: null
  })
}

export {
  fetchContentV2TrendingHashtagConfigs,
  fetchContentV2TrendingHashtags,
  fetchContentV2TrendingHashtagsApiLimit,
  updateContentV2TrendingHashtags,
  updateContentV2TrendingHashtagsApiLimit,
  updateUserFreeTrialStatus,
  getUser,
  updateUserPremiumData,
  removeUserData,
}
