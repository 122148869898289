import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  updateContentV2TrendingHashtags,
} from '../../services/rtdb-service';

const updateContentV2TrendingHashtagsByLanguage = () => {
  const [ trendingHashtagsUpdateData, setTrendingHashtagsUpdateData ] = useState({ });
  const [ updateData, setUpdateData ] = useState(null);
  const {
    languageCode,
    type,
    updatedData,
    onOperationComplete,
  } = updateData || {};

  const updateTrendingHashtags = async () => {
    try {
      console.log(`Updating trending hashtags from RTDB for languageCode : ${languageCode}`);
      setTrendingHashtagsUpdateData({
        isUpdating: true,
        isError: false,
      });

      const trendingHashtagsApiResp =  await updateContentV2TrendingHashtags({
        languageCode,
        type,
        updatedData,
      });
      console.log(`Updating trending hashtags from RTDB for language : ${languageCode} Success!!`);

      let errorMsg = null;

      setTrendingHashtagsUpdateData({
        data: trendingHashtagsApiResp,
        isUpdating: false,
        isError: false,
        errorMsg,
      });

      if(onOperationComplete) {
        onOperationComplete({
          data: trendingHashtagsApiResp,
          isError: false,
          errorMsg,
        });
      }
    } catch (err) {
      console.log(`Error while updating trending hashtags from RTDB for language : ${languageCode}. Error : ${err}`);
      const errorMsg = `Error occurred while updating hashtags from RTDB for language : ${languageCode}.`;
      setTrendingHashtagsUpdateData({
        data: null,
        isUpdating: false,
        isError: true,
        errorMsg,
      });
      if(onOperationComplete) {
        onOperationComplete({
          data: null,
          isError: true,
          errorMsg,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(updateData)) {
      updateTrendingHashtags();
    }
  }, [ updateData ]);

  return [
    trendingHashtagsUpdateData,
    setUpdateData,
  ];
};

export default updateContentV2TrendingHashtagsByLanguage;
