import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  fetchContentV2TrendingHashtags,
} from '../../services/rtdb-service';

const fetchContentV2TrendingHashtagsByLanguage = () => {
  const [ trendingHashtagsData, setTrendingHashtagsData ] = useState({ });
  const [ fetchData, setFetchData ] = useState(null);
  const {
    languageCode,
    onOperationComplete,
  } = fetchData || {};

  const fetchTrendingHashtags = async () => {
    try {
      console.log(`Fetching trending hashtags from RTDB for languageCode : ${languageCode}`);
      setTrendingHashtagsData({
        isFetching: true,
        isError: false,
      });

      const trendingHashtagsApiResp =  await fetchContentV2TrendingHashtags({
        languageCode,
      });
      console.log(`Fetching trending hashtags from RTDB for language : ${languageCode} Success!!`);

      let errorMsg = null;

      setTrendingHashtagsData({
        data: trendingHashtagsApiResp,
        isFetching: false,
        isError: false,
        errorMsg,
      });

      if(onOperationComplete) {
        onOperationComplete({
          data: trendingHashtagsApiResp,
          isError: false,
          errorMsg,
        });
      }
    } catch (err) {
      console.log(`Error while fetching trending hashtags from RTDB for language : ${languageCode}. Error : ${err}`);
      const errorMsg = `Error occurred while fetching hashtags from RTDB for language : ${languageCode}.`;
      setTrendingHashtagsData({
        data: null,
        isFetching: false,
        isError: true,
        errorMsg,
      });
      if(onOperationComplete) {
        onOperationComplete({
          data: null,
          isError: true,
          errorMsg,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(fetchData)) {
      fetchTrendingHashtags();
    }
  }, [ fetchData ]);

  return [
    trendingHashtagsData,
    setFetchData,
  ];
};

export default fetchContentV2TrendingHashtagsByLanguage;
