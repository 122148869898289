import React from 'react';
import {
  Col,
  Row,
  Alert,
} from 'react-bootstrap';
import Collapse,
{
  Panel,
} from 'rc-collapse';
import {
  MPExpandIcon,
} from '../../widgets/common';

const ImageUploadInfoPanel = () => {

  return (
    <Row>
      <Col>
        <Alert>
          <strong style={{'fontSize': '20px'}}>
            Important Note (Please read before you upload):
          </strong>
          <br/>
          <Collapse
            accordion={true}
            className={'upload-note-collapse'}
            expandIcon={MPExpandIcon}
          >
            <Panel
              header="Image Upload Guide"
              headerClass="excel-upload-note-header"
              extra={<span>Click to see/hide Details</span>}
            >
              <ol>
                <li>
                  <p>Please note you can only upload <strong><u> maximum of 30</u></strong> images to the Google Cloud Storage at a single upload.</p>
                </li>
                <li>
                  <p>Also note that if you upload the same name file it will <strong><u> replace the existing</u></strong> image in the Google Cloud Storage and it is <strong><u> irreversible.</u></strong></p>
                </li>
                <li>
                  <p>When you replace images make sure to tick the  <strong>Clear CDN Cache</strong> tick, which clear the CDN cache and it will take up to 10 minutes to take effect.</p>
                </li>
                <li>
                  <p>Before hit the <strong>upload</strong> button double check the  <strong><u> Language</u></strong> parameter.</p>
                </li>
                <li>
                  <p>If you are uploading the unoptimized images then please tick the Optimize Images section to optimize the images.
                    <strong><u>Please be careful</u></strong> when using this feature, because if you tick for already optimized images
                    it will caused to produce very weak images after the upload.
                  </p>
                </li>
              </ol>
            </Panel>
          </Collapse>
        </Alert>
      </Col>
    </Row>
  );
};


const ExcelUploadInfoPanel = () => {
  const sampleExcelFileUrl = 'https://docs.google.com/spreadsheets/d/132zDKJJs8rIsy00lVUgE5wapYYkQO0E7SiIRhjVzO4s/edit?usp=sharing';

  return (
    <Row>
      <Col>
        <Alert>
          <strong style={{'fontSize': '20px'}}>
            Important Note (Please read before you upload):
          </strong>
          <br/>
          <Collapse
            accordion={true}
            className={'upload-note-collapse'}
            expandIcon={MPExpandIcon}
          >
            <Panel
              header="Content Upload Guide"
              headerClass="excel-upload-note-header"
              extra={<span>Click to see/hide Details</span>}
            >
              <ol>
                <li>
                  All fields that are marked with&nbsp;
                  <strong><u>required</u></strong>
                  should be available when uploading the file.
                </li>
              </ol>
            </Panel>
          </Collapse>
          <label>See the Sample Excel Sheet : </label> &nbsp;
          <a href={sampleExcelFileUrl} target="_blank">
            Click to see the sample excel sheet.
          </a>
        </Alert>
      </Col>
    </Row>
  );
};

export {
  ImageUploadInfoPanel,
  ExcelUploadInfoPanel,
}
