import {
  useState,
  useEffect,
} from 'react';
import {
  updateCountryPrecalcsList,
} from '../../services/mp-service-v1-api-service';

const updatePrecalList = ({
                            selectedCountry,
                            updatedPrecalcList,
                            shouldUpdatePrecalcList = false,
                          }) => {
  const [ precalcListUpdate, setPrecalcListUpdate ] = useState(null);

  const updatePrecalcList = async () => {
    console.log('Updating PrecalcList...');
    const precalcListUpdateApiResp =  await updateCountryPrecalcsList({
      updatedData: updatedPrecalcList,
      countryCode: selectedCountry,
    });

    const responseData = precalcListUpdateApiResp.data;
    if(responseData.success) {
      console.log('Precalc List Update Success!!');
    } else {
      console.log('Precalc List Update Failed!!');
    }

    setPrecalcListUpdate({
      status: responseData.success,
      message: responseData.success ? 'Precalcs List Updated Successfully!!' : 'Opz, Something went wrong while updating!!'
    });
  };

  useEffect( () => {
    if(updatedPrecalcList && shouldUpdatePrecalcList) {
      updatePrecalcList();
    }
  }, [ updatedPrecalcList, shouldUpdatePrecalcList ]);

  return [
    precalcListUpdate,
  ];
};

export default updatePrecalList;
