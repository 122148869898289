import React from 'react';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  MPUploadExcel,
} from '../../widgets/upload-excel';
import {
  MediaExcelUploadInfoPanel,
} from './common';
import {
  qalBoxMediaAddOrUpdateHook,
} from '../../hooks';
import {
  getMediaItems,
  getMediaCopyItems,
} from './helper';

const UploadExcel = () => {

  const onExcelUploadComplete = operationData => {
    if(!operationData.isError) {
      toast.success(operationData.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onUploadSuccess = () => {
    onExcelUploadComplete({
      operationData: {
        isError : false,
        message: 'Media items add/update success!!',
      },
    });
  };

  const onUploadFailed = () => {
    onExcelUploadComplete({
      operationData: {
        isError : true,
        message: 'Media items add/update failed!!',
      },
    });
  };

  return (
    <BasicLayout pagePermission="moderate_precalc">
      <ToastContainer autoClose={2000}/>
      <Row>
        <Col md={12}>
          <h3 className='content_editor_title'>{'Upload Media Excel'}</h3>
        </Col>
      </Row>
      <MPUploadExcel
        InfoPanel={MediaExcelUploadInfoPanel}
        fileUpdateHook={qalBoxMediaAddOrUpdateHook}
        fileUpdateSuccessMessage={'Media Items Added/Updated Successfully!!'}
        getItems={getMediaItems}
        getCopySuccessItems={getMediaCopyItems}
        onUploadSuccess={onUploadSuccess}
        onUploadFailed={onUploadFailed}
        showResultOnSuccess={true}
      />
    </BasicLayout>
  );
};

export default UploadExcel;
