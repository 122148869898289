import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  hijriGetRawJSON,
} from '../../services/mp-service-v1-api-service';

const fetchHijriFile = () => {
  const [ hijriFileData, setHijriFileData ] = useState({ });
  const [ fetchData, setFetchData ] = useState(null);
  const {
    onOperationComplete,
  } = fetchData || {};

  const fetchHijriRawFile = async () => {
    try {
      console.log(`Fetching hirji file...`);
      setHijriFileData({
        isFetching: true,
        isError: false,
      });
      const getRawFileApiResp =  await hijriGetRawJSON();

      const responseData = getRawFileApiResp.data;
      let errorMsg = null;
      if(responseData.success) {
        console.log(`Fetching hirji file Success!!`);
      } else {
        console.log(`Fetching hirji file Failed!!`);
        errorMsg = `Error occurred while fetching hirji file.`;
      }

      setHijriFileData({
        data: responseData.data,
        isFetching: false,
        isError: !responseData.success,
        errorMsg,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError: !responseData.success,
          errorMsg,
          data: responseData.data,
        });
      }

    } catch (err) {
      const errorMsg = (err.response && err.response.status === 404) ?
          `Hirji file not found!` : `Error occurred while fetching hirji file.`;
      console.log(`${errorMsg} Error : ${err}`);
      setHijriFileData({
        data: null,
        isFetching: false,
        isError: true,
        errorMsg,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
          data: null,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(fetchData)) {
      fetchHijriRawFile();
    }
  }, [ fetchData ]);

  return [
    hijriFileData,
    setFetchData,
  ];
};

export default fetchHijriFile;
