import React from "react";
import QalboxSelectDropdown from "./qalboxSelect";
import { convertArrayToSelectList } from "../../helper";

const QalboxSearchFilter = ({ labelName, filterList = [], selectedSearchFilter, handleSearchFilterSelect }) => {
  return (
    <div>
      <div>
        <label
            style={{
                marginRight: "5px",
            }}
            >
            {labelName}
            </label>
        <QalboxSelectDropdown
            onChange={handleSearchFilterSelect}
            name={"searchFilter"}
            selectedOption={convertArrayToSelectList(selectedSearchFilter)}
            options={convertArrayToSelectList(filterList)}
        />
     </div>
    </div>
  );
};

export default QalboxSearchFilter;
