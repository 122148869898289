import React, { Component } from 'react';
import BasicLayout from '../../layouts/basiclayout';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import './style.css';

class PrayerTimes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sheets: []
    };
  }

  componentWillMount() {
    this.fetchSheetData();
  }

  fetchSheetData() {
    const API_URL = "https://us-central1-muslim-pro-app.cloudfunctions.net/adminConsoleGetPrayerTimes";
    return axios({
      method: 'get',
      url: `${API_URL}/`,
      headers: {
        'idToken': localStorage.getItem('idToken') || "",
        'uid': localStorage.getItem('uid') || "",
      }
    }).then((res) => {
      console.log(res.data);
      this.setState({
        sheets: res.data || []
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  renderSheetTable(sheets) {
    let rows;
    if(sheets.length === 0) {
      rows = <tr>Nothin found</tr>;
    } else {
      rows = sheets.map((item, i) => {
        return <tr key={i+1} className="small">
          <td>{i+1}</td>
          <td>{item.city}</td>
          <td>{this.isValidDate(item.last_updated) ? item.last_updated : "N/A"}</td>
          <td><a target="_blank" href={"https://docs.google.com/spreadsheets/d/"+item.spreadsheetId}>Open</a></td>
        </tr>;
      });
    }
    return (
      <Table bordered condensed hover>
        <thead>
          <tr className="table__header">
            <th>#</th>
            <th>City</th>
            <th>Last Updated</th>
            <th>Open</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    );
  }

  isValidDate(dateString) {
    if(!dateString) {
      return false;
    }
    const regEx = /^\d{2}-\d{2}-\d{4}$/;
    if(!dateString.match(regEx)) {
      return false;
    }
    return true;
  }

  render() {
    const {sheets} = this.state;
    return (
      <BasicLayout pagePermission="change_user_permission">
        {this.renderSheetTable(sheets)}
      </BasicLayout>
    );
  }
}

export default PrayerTimes;
