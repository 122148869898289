import React, { Component } from 'react';
import BasicLayout from '../../layouts/basiclayout';
import { Row, Col } from 'react-bootstrap';
import Filters from './filters';
import Pagniation from './pagniation';
import Requests from './requests';
import { getCommunityDBURL } from '../../services/utils';
import firebase from 'firebase/app';
import './style.css';
import { Icon } from 'react-fa';

class PrayerRequests extends Component {

  constructor(props) {
    super(props);
    this.state = {
      queryParams: {},
      cardArray: [],
      lastKey: null,
      firstKey: null,
      refKey: '',
      refSortCount: 0,
      firstSortCount: 0,
      lastSortCount: 0
    };
  }
  componentWillMount() {
    // Once the page loaded for first time, we determine the query params here and perform search
    if(this.props.location.search !== "") {
      const search = this.props.location.search.substring(1);
      const queryParams = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
      this.setState({
        queryParams
      });
      this.performSearch(queryParams);
    } else {
      window.location = '/prayer-requests?lan=en&reqType=visible&limit=20&sortBy=newest&refKey=';
    }
  }

  performSearch(queryParams) {
    const { limit = 20, lan = 'en', reqType = 'visible', sortBy = 'newest', refKey = '', pagniateAction = 'next', refSortCount = 0} = queryParams;

    // Switching query location based on BLOCKED field
    let blocked = false;
    let reqLocation = "/prayer-requests";
    if(reqType === 'blocked') {
      reqLocation = "/reported-requests";
      blocked = true;
    }

    let app = firebase.app();
    const baseRef = app.database(getCommunityDBURL(lan)).ref(`${lan}/${reqLocation}`);

    let builtQuery = this.buildQuery(baseRef, limit, sortBy, pagniateAction, parseInt(refSortCount, 10), refKey);
    let requestQuery = builtQuery.query;
    let childKey = builtQuery.childKey;
    let order = builtQuery.order;

    return requestQuery.on('value', (snapshot) => {
      let prayerRequests = snapshot.val();
      if(!prayerRequests) {
        console.log("Nothing found.");
        return;
      }

      let cardArray = [];
      // let requestsKeys = Object.keys(prayerRequests);
      let prayerRequestsArr = this.convertObjectToArray(prayerRequests, refKey, childKey, order);

      prayerRequestsArr.forEach((item, i) => {
        const { userName, userId, timestamp, text, prayerCountTotal, abuseReportCount, locationName, key, languageCode } = item;
        const cardData = { userName, userId, timestamp, text, prayerCountTotal, abuseReportCount, locationName, blocked, key, languageCode };
        cardArray.push(cardData);
        // Saving firstKey and lastKey to determine refKey of pagniation
        if((prayerRequestsArr.length-1) === i) {
          this.setState({
            lastKey: key,
            lastSortCount: item[childKey]
          });
        }
        if(i===0) {
          this.setState({
            firstKey: key,
            firstSortCount: item[childKey]
          });
        }
      });
      this.setState({
        cardArray
      });
    });
  }

  buildQuery(baseRef, limit, sortBy, pagniateAction, refSortCount, refKey) {
    const hasRefKey = (refKey.trim() !== '');

    let childKey, limitType, order;
    switch (sortBy) {
      case 'newest':
        childKey = 'timestamp';
        limitType = 'limitToLast';
        order = 'desc';
        break;
      case 'oldest':
        childKey = 'timestamp';
        limitType = 'limitToFirst';
        order = 'asc';
        break;
      case 'mostPrayed':
        childKey = 'prayerCountTotal';
        limitType = 'limitToLast';
        order = 'desc';
        break;
      case 'leastPrayed':
        childKey = 'prayerCountTotal';
        limitType = 'limitToFirst';
        order = 'asc';
        break;
      case 'mostReported':
        childKey = 'abuseReportCount';
        limitType = 'limitToLast';
        order = 'desc';
        break;
      case 'leastReported':
        childKey = 'abuseReportCount';
        limitType = 'limitToFirst';
        order = 'asc';
        break;
      default:
    }
    let query;
    if(hasRefKey) {
      if(pagniateAction === 'next') {
        if(limitType === 'limitToLast') {
          // Order DESC
          // Going NEXT
          // 9->8->7->6->(5)->4->3->2->-1 | 5->4
          // refKey === lastKey, endAt
          query = baseRef.orderByChild(childKey).endAt(refSortCount, refKey).limitToLast(parseInt(limit, 10)+1);
        } else {
          // Order ASC
          // Going NEXT
          // 1->2->3->4->(5)->6->7->8->-9 | 5->6
          // refKey === lastKey, startAt
          query = baseRef.orderByChild(childKey).startAt(refSortCount, refKey).limitToFirst(parseInt(limit, 10)+1);
        }
      }
      else {
        if(limitType === 'limitToLast') {
          // Order DESC
          // Going PREV
          // 9->8->7->6->(5)->4->3->2->-1 | 5<-6
          // refKey === firstKey, startAt
          query = baseRef.orderByChild(childKey).startAt(refSortCount, refKey).limitToFirst(parseInt(limit, 10)+1);
        }
        else {
          // Order ASC
          // Going PREV
          // 1->2->3->4->(5)->6->7->8->-9 | 5<-4
          // refKey === firstKey, endAt
          query = baseRef.orderByChild(childKey).endAt(refSortCount, refKey).limitToLast(parseInt(limit, 10)+1);
        }
      }
    } else {
      if(limitType === 'limitToLast') {
        query = baseRef.orderByChild(childKey).limitToLast(parseInt(limit, 10));
      } else {
        query = baseRef.orderByChild(childKey).limitToFirst(parseInt(limit, 10));
      }
    }
    return {
      query,
      childKey,
      order
    };
  }

  doSortBy(arr, childKey, order) {
    if(order === 'asc') {
      return arr.sort((a, b) => {
        if(a[childKey] > b[childKey]) { return 1; }
        else if(a[childKey] < b[childKey]) { return -1; }
        else { return 0; }
      });
    }
    return arr.sort((a, b) => {
      if(a[childKey] < b[childKey]) { return 1; }
      else if(a[childKey] > b[childKey]) { return -1; }
      else { return 0; }
    });
  }

  convertObjectToArray(obj, refKey, sortParam, order) {
    let arr = [];
    Object.keys(obj).forEach((key) => {
      // excluding the item which matches with the item with refKey
      if(key !== refKey) {
        arr.push({
          ...obj[key],
          key
        });
      }
    });
    if(sortParam) {
      arr = this.doSortBy(arr, sortParam, order);
    }
    return arr;
  }

  remove(array, element) {
      const index = array.indexOf(element);
      array.splice(index, 1);
  }

  onPaginationDropdownChange(limit) {
    this.processFilterAction('limit', limit);
  }

  onLanguageChange(lan) {
    this.processFilterAction('lan', lan);
  }

  onReqTypeChange(reqType) {
    this.processFilterAction('reqType', reqType);
  }

  onSortByChange(sortBy) {
    this.processFilterAction('sortBy', sortBy);
  }

  resetQueryBasedOnAction(actionName, queryParams) {
    switch (actionName) {
      case 'reqType':
        queryParams.refKey = '';
        queryParams.pagniateAction = 'next';
        break;
      case 'sortBy':
        queryParams.refKey = '';
        queryParams.pagniateAction = 'next';
        break;
      default:
    }
    return queryParams;
  }

  // This function will trigger the page load with proper query params
  processFilterAction(actionName, action) {
    let queryParams = {
      ...this.state.queryParams,
      [actionName]: action,
    };
    queryParams = this.resetQueryBasedOnAction(actionName, queryParams);
    this.setState({
      queryParams
    });
    this.loadUrl(queryParams);
  }

  loadUrl(queryParams) {
    let queryStr = "/prayer-requests?";
    Object.keys(queryParams).forEach((key, i) => {
      let And = (i === Object.keys(queryParams).length-1) ? '' : '&';
      queryStr+= `${key}=${queryParams[key]}${And}`;
    });
    window.location = queryStr;
  }

  onPagniate(pagniateAction) {
    const { firstKey, lastKey, firstSortCount, lastSortCount } = this.state;
    let refKey = (pagniateAction === 'prev') ? firstKey : lastKey;
    let refSortCount = (pagniateAction === 'prev') ? firstSortCount : lastSortCount;
    let queryParams = {
      ...this.state.queryParams,
      pagniateAction,
      refKey,
      refSortCount
    };
    this.setState({
      queryParams
    });
    this.loadUrl(queryParams);
  }

  render() {
    const reqComp = this.state.cardArray.length ? <Requests cards={this.state.cardArray}/> : <p className="pr__loading_text"><Icon spin name="cog"/> Please wait while prayer requests are being loaded from the database.</p>;
    return (
      <BasicLayout pagePermission="prayer_requests">
        <Row>
          <Col className="prayer__requests_col" md={12}>
            <Filters
              selectedLan={this.state.queryParams.lan}
              selectedReqType={this.state.queryParams.reqType}
              selectedSortBy={this.state.queryParams.sortBy}
              onLanguageChange={this.onLanguageChange.bind(this)}
              onReqTypeChange={this.onReqTypeChange.bind(this)}
              onSortByChange={this.onSortByChange.bind(this)}/>
          </Col>
        </Row>
        <Row>
          <Col className="prayer__requests_col" md={12}>
            {reqComp}
          </Col>
        </Row>
        <Row>
          <Col className="prayer__requests_col" md={12}>
            <Pagniation
              start={1}
              end={20}
              selectedLimit={this.state.queryParams.limit}
              onDropdownChange={this.onPaginationDropdownChange.bind(this)}
              onPagniate={this.onPagniate.bind(this)}
            />
          </Col>
        </Row>
      </BasicLayout>
    );
  }
}

export default PrayerRequests;
