import DailydeenService from "../../service_v2/dailydeen/dailydeen-service";

const addDailyDeenItem = async (flag, date, contentType, contentBody, suraStart, ayaStart, suraEnd, ayaEnd, durationSec, theme, fullImg, headerImg ) => {
  return DailydeenService.updateDailyDeenContent(
    [
      {
        flag: flag,
        date: date.slice(0,10),
        content_type: contentType,
        content_body: contentBody,
        theme: theme,
        duration: durationSec,
        start_sura: suraStart,
        start_aya: ayaStart,
        end_sura: suraEnd,
        end_aya: ayaEnd,
        full_img: fullImg,
        header_img: headerImg
      }
    ]
  ).then( r => {
    return true;
  }).catch( err => {
    console.log(err)
    return false
  })
}

const dateSorter = function(a, b){
  let aa = a.schedule_at.split('-').join();
  let bb = b.schedule_at.split('-').join();
  return !(aa < bb) ? -1 : (aa > bb ? 1 : 0);
};

export {
  addDailyDeenItem,
  dateSorter,
}