import React,
{
  Component,
} from 'react';

class InlineWrapper extends Component {
  render() {
    let {
      permission,
      usersPermission,
      optionalData,
    } = this.props;
    usersPermission = parseInt(usersPermission, 10);
    permission = parseInt(permission, 10);
    if((usersPermission & permission) === permission) {
      return <span>{this.props.children}</span>;
    } else {
      if(optionalData) {
        return <span>{optionalData}</span>;
      } else {
        return null
      }
    }
  }
}

export default InlineWrapper;
