import axios from 'axios';
import {
  MP_SERVICE_V1_API_BASE_URL,
} from './Constants';

const getBaseConfig = (apiKey) => {
  return {
    baseURL: MP_SERVICE_V1_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': Buffer.from(`ApiKey ${apiKey}`).toString('base64')
    },
  }
}

const creatRequest = (apiKey, configs) => {
  return axios.create(getBaseConfig(apiKey))(configs);
}

export default creatRequest;