import _ from 'lodash';
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay';
import {
  Col,
  Row,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  qalBoxSearchMediaHook,
  qalBoxDeleteMediaHook,
} from '../../hooks';
import {
  getDeletableMedia,
  getMediaIdsToDelete,
  getMediaItemsColumns,
  getSupportedLanguages,
  getSearchMediaDisplayData,
} from './helper';
import {
  MPInput,
  MPSelect,
  OperationStatus,
} from '../../widgets/common';
import {
  DEFAULT_SUPPORTED_LANGUAGE,
} from './Constants';
import {
  MPSimpleTable,
} from '../../widgets/mp-table';
import {
  confirmAlert,
} from 'react-confirm-alert';
import AppModal from '../../widgets/modal';
import {
  ScaleLoader,
} from 'react-spinners';

const defaultSearchConfig = {
  'language_code': 'en',
  'query': 'Goal for Freedom',
  'filter_by': {
    'any': []
  },
  'search_by': [
    {
      'field': 'title',
      'weight': 10
    }
  ],
};

const SearchOperationStatus = ({ tableError }) => {

  return (
    <OperationStatus
      operationInProgressText={`Please wait! Loading data...`}
      showActionBtn={tableError && tableError.actionURL}
      actionBtnText={tableError && tableError.actionURL ? 'Fix' : 'Restart'}
      actionBtnAction={() => window.open(tableError.actionURL,'_blank')}
      operationFailedMsg={tableError && tableError.message}
      operationFailedInfoMsg={tableError && tableError.actionURL ?
        'If the issue is related to firebase indexing then try creat thee index by clicking Fix button and and restarting the page after crearting the index, if you don`t have permission to create index ask backend team to create the index for you by sending the URL link by clicking Fix button.' : 'Please try again by changing your filters.'}
      operationStatusData={tableError}
    />
  );
};

const TableToolBox = ({
                        disableDelDownButtons,
                        disableResetAllFilters,
                        selectedRows,
                        setAllFilters,
                        onDeleteSelected,
                        onDownloadSelected,
                        initialFilters=[],
                        preElements=null,
                        postElements=null,
                      }) => {

  return (
    <div className={'mp-content-v2-table-tools'}>
      {preElements}
      <button
        className="margin-all3 btn btn-danger btn-sm"
        disabled={disableDelDownButtons}
        onClick={() => onDeleteSelected(selectedRows)}>
        <span className={"glyphicon glyphicon-trash"} aria-hidden="true"/>
        &nbsp;Delete Selected
      </button>
      {postElements}
    </div>
  )
};

const SearchMedia = () => {
  const [ language, setLanguage ] = useState(DEFAULT_SUPPORTED_LANGUAGE);
  const [ title , setTitle] = useState('');
  const [ responseData, setOperationData ] = qalBoxSearchMediaHook();
  const [ deleteResponseData, setDeleteOperationData ] = qalBoxDeleteMediaHook();

  const displayData = getSearchMediaDisplayData(_.get(responseData, 'data', []));
  const isLoading = _.get(responseData, 'isLoading', false);
  const isDeleting = _.get(deleteResponseData, 'isDeleting', false);
  const tableError = _.get(responseData, 'isError', false) && {
    message: _.get(responseData, 'errorMsg', 'Something went wrong.'),
    isError: true,
  };

  const onOperationComplete = operationData => {
    if(operationData.isError) {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      // toast.success(`Title ${title} searched successfully.`, {
      //   position: toast.POSITION.BOTTOM_LEFT
      // });
    }
  };

  const onDeleteComplete = operationData => {
    if(operationData.isError) {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.success(`Selected media items deleted successfully.`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const requestData = useCallback(
    _.debounce(tile => {
      setOperationData({
        searchConfig: {
          ...defaultSearchConfig,
          language_code: language,
          query: title,
        },
        onOperationComplete,
      });
    }, 400),
    [ title, language ]
  );

  useEffect(() => {
    if (title) {
      requestData(title);
    }
  }, [ title, language ]);


  const onDeleteSelected = (selectedFlatRows) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            headingText={`Are you sure want to delete the following ${_.size(selectedFlatRows)} media items ?`}
            modalBody={getDeletableMedia(selectedFlatRows)}
            modalBodyClassName={'precalcs-diff-merge-view'}
            closeButtonText={'No'}
            okButtonText={'Yes, Delete'}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              console.log(getMediaIdsToDelete(selectedFlatRows))
              setDeleteOperationData({
                deleteConfig: {
                  media_ids: getMediaIdsToDelete(selectedFlatRows),
                  language_code: language,
                },
                onOperationComplete: onDeleteComplete,
              });
            }}
          />
        );
      }
    });
  };

  const onDownloadSelected = (selectedFlatRows) => {
    console.log('onDownloadSelected selectedFlatRows : ', selectedFlatRows);
  };

  return (
    <LoadingOverlay
      active={isDeleting}
      spinner={<ScaleLoader />}
      text='Deleting the selected media items.'
    >
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={2000}/>
        <Row>
          <Col md={12}>
            <h3 className='content_editor_title'>{'Search Media'}</h3>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="form-group">
              <label
                style={{
                  'marginRight': '5px',
                }}
              >
                {'Language : '}
              </label>
              <MPSelect
                items={getSupportedLanguages()}
                selectedItem={language}
                onChangeSelect={setLanguage}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="form-group">
              <label
                style={{
                  'marginRight': '5px',
                }}
              >
                {'Title : '}
              </label>
              <MPInput
                value={title}
                placeHolder={'Title'}
                onChangeValue={setTitle}
              />
            </div>
          </Col>
        </Row>
        <MPSimpleTable
          displayData={displayData}
          columns={getMediaItemsColumns()}
          loading={isLoading}
          tableError={tableError}
          onDeleteSelected={onDeleteSelected}
          onDownloadSelected={onDownloadSelected}
          TableToolBox={TableToolBox}
          TableOperationStatus={SearchOperationStatus}
          paginationList={ [10, 20, 50] }
        />
      </BasicLayout>
    </LoadingOverlay>
  );
};

export default SearchMedia;
