import _ from 'lodash';

const getOrderedFileVersionsList = (fileVersionsList) => {
  return _.sortBy(fileVersionsList, fileVersion => {
    return new Date(fileVersion.time_created);
  }).reverse();
};

const getDiffDataFromVersionDiff = (versionDiffObj) => {
  let left = null;
  let right = null;
  _.keys(versionDiffObj).forEach(version => {
    const versionDiffData = versionDiffObj[version];
    if(versionDiffData['side'] === 'left') {
      left = versionDiffData['fileVersion']['data'];
    }

    if(versionDiffData['side'] === 'right') {
      right = versionDiffData['fileVersion']['data'];
    }
  });

  return [left, right];
};

export {
  getOrderedFileVersionsList,
  getDiffDataFromVersionDiff,
}