import _ from "lodash";
import React, { useEffect, useState } from "react";
import { convertArrayToSelectList, convertToSelectObject } from "../helper";
import { QalboxTextInput } from "../helperComponents/qalboxTextInput";
import { QalboxInputWrapper } from "../helperComponents/qalboxInputWrapperComponent";
import { useCreateAdUnit } from "./hooks/useCreateAdUnit";
import { useUpdateAdUnit } from "./hooks/useUpdateAdUnit";
import QalboxSelectDropdown from "../media/helperComponents/qalboxSelect";
import { ad_spaces } from "./helpers/adUnitHelper";

const initialAdUnitbject = {
  name: "",
  ad_space: "",
  android_ad_unit_id: "",
  ios_ad_unit_id: "",
  web_ad_unit_id: "",
  active: "true",
};

export const AdUnitModal = ({ data, handleUpdates }) => {
  const [adunit, setAdUnit] = useState(initialAdUnitbject);
  const [createAdUnitHook] = useCreateAdUnit();
  const [updateAdUnitHook] = useUpdateAdUnit();

  const handleSelect = (name, option) => {
    setAdUnit((prevState) => ({
      ...prevState,
      [name]: option.value,
    }));
  };

  const handleInputTextChange = (e) => {
    let { name, value } = e.target;

    setAdUnit((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setAdUnit((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleOnSave = () => {
    createAdUnitHook(adunit, handleUpdates);
  };

  const handleOnUpdate = async () => {
    updateAdUnitHook(adunit, handleUpdates);
  };

  useEffect(() => {
    if (data) {
      setAdUnit(data);
    }
  }, [data]);

  return (
    <div>
      <QalboxTextInput
        label="Name"
        name="name"
        value={adunit.name}
        onChange={handleInputTextChange}
      />
      <label>Ad Space</label>
      <QalboxSelectDropdown
          name={"ad_space"}
          selectedOption={convertToSelectObject(adunit.ad_space)}
          options={convertArrayToSelectList(ad_spaces)}
          onChange={handleSelect}
      />
      <br></br>
      <QalboxTextInput
        label="Android Ad unit"
        name="android_ad_unit_id"
        value={adunit.android_ad_unit_id}
        onChange={handleInputTextChange}
      />
      <QalboxTextInput
        label="Ios Ad unit"
        name="ios_ad_unit_id"
        value={adunit.ios_ad_unit_id}
        onChange={handleInputTextChange}
      />
      <QalboxTextInput
        label="Web Ad unit"
        name="web_ad_unit_id"
        value={adunit.web_ad_unit_id}
        onChange={handleInputTextChange}
      />
      <QalboxInputWrapper
        label="Is Active"
        component={
          <input
            type="checkbox"
            name="active"
            checked={adunit.active}
            onChange={handleCheckboxChange}
            style={{ transform: "scale(1.5)", marginLeft: "10px" }}
          />
        }
      />
      <br></br>
      <QalboxInputWrapper
        style={{ marginTop: "5px" }}
        component={
          data ? (
            <button className="pagination-button" onClick={handleOnUpdate}>
              Update
            </button>
          ) : (
            <button className="pagination-button" onClick={handleOnSave}>
              Save
            </button>
          )
        }
      />
    </div>
  );
};