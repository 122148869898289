const JsonInputTypes = {
  'string': 'String',
  'array': 'Array',
  'object': 'Object',
  'int': 'Int',
  'float': 'Float',
  'boolean': 'Boolean',
};

export  {
  JsonInputTypes,
}