import _ from 'lodash';
import React,
{
  Fragment,
  useEffect,
  useReducer,
} from 'react';
import BasicLayout from '../../layouts/basiclayout';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import {
  fetchHijriHook,
  updateHijriHook,
  updateSettingsFileTimestampsHook,
} from '../../hooks';
import {
  OperationStatus,
} from '../../widgets/common';
import {
  scrollToTop,
  isValidHijriJson,
  getCountryOptions,
  getCountryBasedCalendarData,
} from './helper';
import {
  actionTypes,
} from './actionTypes';
import {
  hijriYearsReducer,
  HijriYearsInitialState,
} from './Reducers';
import Select from 'react-select';
import {
  HijriYears,
  onClickUpdate,
} from './common';

const HijriYear = () => {
  const [ state , dispatch ] = useReducer(hijriYearsReducer, HijriYearsInitialState);
  const {
    initialHijri, updatedHijri, newCountries, newCountriesAddError, updated, newCountriesList,
    refMap,
  } = state;

  const [ hijriFileData, doFetch ] = fetchHijriHook();
  const [ hijriFileUpdateData, doUpdate ] = updateHijriHook();
  const [ settingsFileUpdateData, doUpdateSettingTs ] = updateSettingsFileTimestampsHook();

  const isFetching = hijriFileData && hijriFileData.isFetching;
  const isUpdating = hijriFileUpdateData && hijriFileUpdateData.isUpdating;
  const updatingTimeStamps = settingsFileUpdateData && settingsFileUpdateData.isUpdating;
  const updatingText = updatingTimeStamps ?
      'Updating timestamps...' : `Please wait! ${isUpdating ? 'Updating' : 'Fetching'} Hijri data...`;

  const enableUpdateButton = updated && isValidHijriJson(updatedHijri) && !isUpdating;

  const onTimestampUpdateComplete = operationData => {
    if(!operationData.isError) {
      toast.success(`Timestamps update success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onHijriFileUpdateSuccess = () => {
    doUpdateSettingTs({
      fileName: 'settings_api_base',
      updateConfig: {
        'updateTimestamps': true,
        'timestampLocations': [
          'all>timestamp',
          'all>configurable_apis>hijri>timestamp',
        ],
      },
      onOperationComplete: onTimestampUpdateComplete,
    });
  };

  const onFetchComplete = operationData => {
    if(!operationData.isError) {
      toast.success(`Fetching Hijri Data Success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      dispatch({
        type: actionTypes.HYU_UPDATE_DATA,
        payload: operationData.data,
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onUpdateComplete = operationData => {
    if(!operationData.isError) {
      toast.success(`Updating Hijri Data Success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      onHijriFileUpdateSuccess();
      if(operationData.data) {
        dispatch({
          type: actionTypes.HYU_UPDATE_DATA,
          payload: operationData.data,
        });
      }
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onAddCounties = () => {
    dispatch({
      type: actionTypes.HYU_ADD_NEW_COUNTRIES,
    });
  };

  const onNavigate = () => {
    const firstCountryCode = newCountries[0] && newCountries[0].value.toLowerCase();
    if(firstCountryCode) {
      const ref = refMap[firstCountryCode];
      if (ref && ref.current) {
        window.scroll({
          top: ref.current.offsetTop + 150,
          left: 0,
          behavior: 'smooth',
        });
        // window.scrollTo(0, ref.current.offsetTop);
        // ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  const resetState = () => {
    dispatch({
      type: actionTypes.HYU_RESET_STATE,
    });
  };

  useEffect(() => {
    doFetch({
      onOperationComplete: onFetchComplete,
    });
  }, []);

  const countryBasedCalendar = getCountryBasedCalendarData(updatedHijri);

  return (
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={2000}/>
        <div>
          <Row>
            <Col md={12}>
              <h3 className='content_editor_title'>{'Starts of Hijri Year'}</h3>
            </Col>
          </Row>
          <form className={'form-inline'} onSubmit={e => e.preventDefault()}>
            <Row>
              <Col md={4}>
                <div
                    className={`form-group ${newCountriesAddError && 'has-error'}`}
                    style={{'marginRight': '5px'}}>
                  <div className={'hijri-years-country-select-main'}>
                    <label style={{'marginRight': '5px'}}>Countries</label>
                    <Select
                        onChange={
                          (value) => dispatch({
                            type: actionTypes.HYU_UPDATE_PROP_VAL,
                            payload: {
                              prop: 'newCountries',
                              value,
                            },
                          })
                        }
                        value={newCountries}
                        isSearchable={true}
                        options={getCountryOptions()}
                        isMulti={true}
                        placeholder={'Select countries'}
                        className={'hijri-years-countries-select'}
                    />
                  </div>
                  {
                    newCountriesAddError &&
                    <span className="help-block">{newCountriesAddError}</span>
                  }
                </div>
              </Col>
              <Col md={4}>
                <Button
                    style={{ 'marginLeft': '5px', 'marginRight': '5px' }}
                    onClick={onNavigate}
                    disabled={_.isEmpty(newCountries)}
                    className="btn-primary"
                    type={'button'}>
                  {'Navigate'}
                </Button>
                <Button
                    style={{'marginRight': '5px'}}
                    onClick={onAddCounties}
                    disabled={_.isEmpty(newCountries) || newCountriesAddError}
                    className="btn-primary"
                    type={'button'}>
                  {'Add'}
                </Button>
                {
                  updated &&
                  <Fragment>
                    <Button
                        style={{'marginRight': '5px'}}
                        onClick={resetState}
                        disabled={!updated}
                        className="btn-primary"
                        type={'button'}>
                      {'Reset'}
                    </Button>
                    <Button
                        onClick={
                          () => {
                            onClickUpdate({
                              updatedHijri,
                              initialHijri,
                              doUpdate,
                              onUpdateComplete,
                            });
                          }
                        }
                        disabled={!enableUpdateButton}
                        className="btn-primary"
                        type={'button'}>
                      {isUpdating ? 'Updating...' : 'Update'}
                    </Button>
                  </Fragment>
                }
              </Col>
            </Row>
            {
              (isUpdating || isFetching || updatingTimeStamps) &&
              <OperationStatus
                  operationInProgressText={updatingText}
              />
            }
            <br/>
            {
              !isFetching && !isUpdating && !updatingTimeStamps && countryBasedCalendar &&
              <Row>
                <Col md={6}>
                  <HijriYears
                      onDeleteCountry={
                        (deleteData) => dispatch({
                          type: actionTypes.HYU_ON_DELETE_COUNTRY,
                          payload: deleteData,
                        })
                      }
                      onResetCountry={
                        (resetData) => dispatch({
                          type: actionTypes.HYU_ON_RESET_COUNTRY,
                          payload: resetData,
                        })
                      }
                      onAddYear={
                        (addData) => dispatch({
                          type: actionTypes.HYU_ON_ADD_YEAR,
                          payload: addData,
                        })
                      }
                      onDeleteYear={
                        (deleteData) => dispatch({
                          type: actionTypes.HYU_ON_DELETE_YEAR,
                          payload: deleteData,
                        })
                      }
                      onResetYear={
                        (deleteData) => dispatch({
                          type: actionTypes.HYU_ON_RESET_YEAR,
                          payload: deleteData,
                        })
                      }
                      onChangeDate={
                        (changedData) => dispatch({
                          type: actionTypes.HYU_ON_UPDATE_DATE,
                          payload: changedData,
                        })
                      }
                      onUpdateRefMap={
                        (refData) => dispatch({
                          type: actionTypes.HYU_ON_UPDATE_REF_DATA,
                          payload: refData,
                        })
                      }
                      fetching={isFetching}
                      newCountriesList={newCountriesList}
                      countryBasedCalendar={countryBasedCalendar}
                      refMap={refMap}
                  />
                </Col>
              </Row>
            }
          </form>
          <div id="jsScroll" className="scroll" onClick={scrollToTop}>
            <i className="fa fa-angle-up"/>
            <p className={'back-to-top-text'}>{'To Top'}</p>
          </div>
        </div>
      </BasicLayout>
  );
}

export default HijriYear;
