import { useState } from 'react';

export const useInvalidateShortCache = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const invalidateShortCache = async (destinationPath, handleUpdate) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}qalbox-admin-short-service/v1/invalidate/cache/`,
        {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify({ object_path: destinationPath }),
        }
      );

      const json = await response.json();

      if (json.success) {
        setSuccess(true);
        handleUpdate(json);
      } else {
        setError("Failed to create brand");
      }
    } catch (error) {
      setError("An error occurred while creating the brand");
    } finally {
      setLoading(false);
    }
  };

  return [ invalidateShortCache, loading, error, success ];
};