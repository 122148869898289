import React,
{
  Fragment,
  useEffect,
  useReducer,
} from 'react';
import BasicLayout from '../../layouts/basiclayout';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
  Alert,
} from 'react-bootstrap';
import {
  confirmAlert,
} from 'react-confirm-alert';
import {
  clearCFsRedisCacheHook,
  fetchTrendingHashtagsHook,
  fetchRTDBTrendingHashtagsHook,
  updateRTDBTrendingHashtagsHook,
  fetchContentV2OrderedHashtagsHook,
  fetchRTDBTrendingHashtagsApiLimitHook,
  updateRTDBTrendingHashtagsApiLimitHook,
} from '../../hooks';
import {
  MPSelect,
  OperationStatus,
} from '../../widgets/common';
import {
  actionTypes,
} from './actionTypes';
import {
  THTInitialState,
  trendingHashtagsReducer,
} from './Reducers';
import {
  ContentSupportedLanguages,
  CONTENT_V2_TRENDING_HASH_TAG_CACHE_ID,
} from './Constants';
import {
  TrendingHashTags,
  ApiTrendingHashTags,
  getUpdatedHashtagsInfo,
  TrendingHashTagsToolBox,
  DatabaseTrendingHashTags,
} from './common';
import {
  getUpdatableHashtags,
  hasHashtagsDataChanged,
} from './helper';
import AppModal from '../../widgets/modal';
import './style.css';

delete ContentSupportedLanguages["all"];
const languages = Object.keys(ContentSupportedLanguages).map((languageCode) => {
  return {
    key: languageCode,
    value: ContentSupportedLanguages[languageCode]
  }
});

const ListHashTags = () => {
  const[ state, dispatch ] = useReducer(trendingHashtagsReducer, THTInitialState);
  const {
    selectedLanguageCode, order, dbLimit, apiLimit, newCustomHashTag, newBlockedHashTag,
    customHashtags, blockedHashtags,  rtdbCustomHashtags, rtdbBlockedHashtags,
    hashtagExistingErrorMsg,
  } = state;

  const [ orderedHashtagsData, doFetchOrderedHashtags ] = fetchContentV2OrderedHashtagsHook();
  const [ rtdbTrendingHashtagsData, doFetchRTDBHashtags ] = fetchRTDBTrendingHashtagsHook();
  const [ rtdbTrendingHashtagsApiLimitData, doFetchRTDBHashtagsApiLimit ] =
    fetchRTDBTrendingHashtagsApiLimitHook();
  const [ rtdbTrendingHashtagsUpdateData, doUpdateRTDBHashtags ] = updateRTDBTrendingHashtagsHook();
  const [ rtdbTrendingHashtagsApiLimitUpdateData, doUpdateRTDBHashtagsApiLimit ] =
    updateRTDBTrendingHashtagsApiLimitHook();
  const [ trendingHashtagsData, doFetchTrendingHashtags ] = fetchTrendingHashtagsHook();
  const [ clearCacheResponseData, setClearCacheData ] = clearCFsRedisCacheHook();

  const isFetchingTrendingHashtagsData = orderedHashtagsData && orderedHashtagsData.isFetching;
  const dbTrendingHashtags = orderedHashtagsData && orderedHashtagsData.data;
  const trendingHashtags = trendingHashtagsData
    && trendingHashtagsData.data
    && trendingHashtagsData.data.result
    && trendingHashtagsData.data.result.payload;

  const loadingTrendingHashtagsData = trendingHashtagsData
    && trendingHashtagsData.isFetching;
  const loadingRtdbHashtagsData = rtdbTrendingHashtagsData
    && rtdbTrendingHashtagsData.isFetching;
  const loadingRtdbApiLimitsData = rtdbTrendingHashtagsApiLimitData
    && rtdbTrendingHashtagsApiLimitData.isFetching;

  const updatingRtdbHashtagsData = rtdbTrendingHashtagsUpdateData
    && rtdbTrendingHashtagsUpdateData.isUpdating;
  const updatingRtdbApiLimitsData = rtdbTrendingHashtagsApiLimitUpdateData
    && rtdbTrendingHashtagsApiLimitUpdateData.isUpdating;

  const isCacheClearing = clearCacheResponseData
    && clearCacheResponseData.isClearing;

  const customHashtagsDataChanged = hasHashtagsDataChanged(customHashtags, rtdbCustomHashtags);
  const blockedHashtagsDataChanged = hasHashtagsDataChanged(blockedHashtags, rtdbBlockedHashtags);

  const onFetchComplete = operationData => {
    if(!operationData.isError) {
      toast.success(`Fetching Trending Hashtags from DB Success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onFetchCompleteRTDBTrendingHashtagsData = operationData => {
    if(!operationData.isError) {
      toast.success(`Fetching RTDB trending hashtags data success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      dispatch({
        type: actionTypes.THT_FETCH_COMPLETE_RTDB_TRENDING_HASHTAGS,
        payload: operationData,
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onFetchCompleteRTDBTrendingHashtagsApiLimitData = operationData => {
    if(!operationData.isError) {
      toast.success(`Fetching API limit data success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      dispatch({
        type: actionTypes.THT_FETCH_COMPLETE_RTDB_TRENDING_HASHTAGS_API_LIMIT,
        payload: operationData,
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onFetchCompleteTrendingHashtags = operationData => {
    if(!operationData.isError) {
      toast.success(`Fetching Trending Hashtags Success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const fetchDBData = () => {
    doFetchOrderedHashtags({
      languageCode: selectedLanguageCode,
      order,
      limit: dbLimit,
      onOperationComplete: onFetchComplete,
    });
  };

  const fetchTrendingHashtags = () => {
    doFetchTrendingHashtags({
      languageCode: selectedLanguageCode,
      onOperationComplete: onFetchCompleteTrendingHashtags,
    });
  };

  useEffect(() => {
    fetchDBData();
    doFetchRTDBHashtags({
      languageCode: selectedLanguageCode,
      onOperationComplete: onFetchCompleteRTDBTrendingHashtagsData,
    });
    doFetchRTDBHashtagsApiLimit({
      onOperationComplete: onFetchCompleteRTDBTrendingHashtagsApiLimitData,
    });
    fetchTrendingHashtags();
  }, [selectedLanguageCode]);

  const onClearCacheComplete = operationData => {
    if(!operationData.isError) {
      toast.success(`Clear API Cache Success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      // Re fetch trending hashtags
      fetchTrendingHashtags();
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onCompleteSync = ({ operationData , successMsg }) => {
    if(!operationData.isError) {
      toast.success(successMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      // Clear API Cache
      setClearCacheData({
        mainKey: CONTENT_V2_TRENDING_HASH_TAG_CACHE_ID,
        subKey: selectedLanguageCode,
        onOperationComplete: onClearCacheComplete,
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const syncAPILimit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={false}
            headingText={`Are you sure want to update API limit to ${apiLimit} ?`}
            modalBodyClassName={'precalcs-diff-merge-view'}
            closeButtonText={'No'}
            okButtonText={`Yes, Update`}
            handleClose={onClose}
            handleOk={() => {
              doUpdateRTDBHashtagsApiLimit({
                apiLimit,
                onOperationComplete: (operationData) => {
                  onCompleteSync({
                    operationData,
                    successMsg: 'Api Limit sync Success!!'
                  })
                },
              });
              onClose();
            }}
          />
        );
      }
    });
  };

  const syncCustomHashtags = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            headingText={`Are you sure want to sync custom hashtags ?`}
            modalBody={
              getUpdatedHashtagsInfo({
                newHashtags: customHashtags,
                rtdbHashtags: rtdbCustomHashtags,
              })
            }
            modalBodyClassName={'precalcs-diff-merge-view'}
            closeButtonText={'No'}
            okButtonText={`Yes, Sync`}
            handleClose={onClose}
            handleOk={() => {
              const updatableHashtags = getUpdatableHashtags(customHashtags);
              doUpdateRTDBHashtags({
                languageCode: selectedLanguageCode,
                type: 'custom',
                updatedData: updatableHashtags,
                onOperationComplete: (operationData) => {
                  onCompleteSync({
                    operationData,
                    successMsg: 'Custom hashtags sync Success!!'
                  })
                },
              });
              onClose();
            }}
          />
        );
      }
    });
  };

  const syncBlockedHashtags = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            headingText={`Are you sure want to sync blocked hashtags ?`}
            modalBody={
              getUpdatedHashtagsInfo({
                newHashtags: blockedHashtags,
                rtdbHashtags: rtdbBlockedHashtags,
              })
            }
            modalBodyClassName={'precalcs-diff-merge-view'}
            closeButtonText={'No'}
            okButtonText={`Yes, Sync`}
            handleClose={onClose}
            handleOk={() => {
              const updatableHashtags = getUpdatableHashtags(blockedHashtags);
              doUpdateRTDBHashtags({
                languageCode: selectedLanguageCode,
                type: 'blocked',
                updatedData: updatableHashtags,
                onOperationComplete:  (operationData) => {
                  onCompleteSync({
                    operationData,
                    successMsg: 'Blocked hashtags sync Success!!'
                  })
                },
              });
              onClose();
            }}
          />
        );
      }
    });
  };

  const addNewCustomTag = () => {
    dispatch({
      type: actionTypes.THT_ADD_CUSTOM_HASHTAG,
      payload: { hashtag: newCustomHashTag, source: 'custom' },
    });
  };

  const blockNewCustomTag = () => {
    dispatch({
      type: actionTypes.THT_BLOCK_HASHTAG,
      payload: { hashtag: newBlockedHashTag, source: 'custom_block' },
    });
  };

  const resetCustomHashtags = () => {
    dispatch({
      type: actionTypes.THT_RESET_CUSTOM_HASHTAGS,
    });
  };

  const resetBlockedHashtags = () => {
    dispatch({
      type: actionTypes.THT_RESET_BLOCKED_HASHTAGS,
    });
  };

  return (
    <BasicLayout pagePermission="moderate_precalc">
      <ToastContainer autoClose={2000}/>
      <div>
        <Row>
          <Col md={12}>
            <h3 className='content_editor_title'>{'Content V2 Trending Hashtags'}</h3>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <div
              className={'form-group'}
              style={{'marginRight': '5px'}}
            >
              <label style={{'marginRight': '5px'}}>Language</label>
              <MPSelect
                items={languages}
                selectedItem={selectedLanguageCode}
                onChangeSelect={
                  (value) => dispatch({
                    type: actionTypes.THT_UPDATE_PROP_VAL,
                    payload: {
                      prop: 'selectedLanguageCode',
                      value,
                    },
                  })
                }
              />
            </div>
          </Col>
        </Row>
        {
          isCacheClearing ?
            <OperationStatus
              operationInProgressText={`Please wait! Clearing cache...`}
            /> :
            <Fragment>
              {
                hashtagExistingErrorMsg ?
                  <Row>
                    <Col md={12}>
                      <div>
                  <span className="trending-hashtags-error-msg">
                    {hashtagExistingErrorMsg}
                  </span>
                      </div>
                    </Col>
                  </Row> :
                  null
              }
              <Row>
                <Col md={6}>
                  <div className={'trending-hashtags-container'}>
                    <h4>
                      Trending Hashtags from API Response
                    </h4>
                    {
                      (updatingRtdbApiLimitsData ||
                        loadingRtdbApiLimitsData ||
                        loadingTrendingHashtagsData) ?
                        <OperationStatus
                          operationInProgressText={`Please wait! ${updatingRtdbApiLimitsData ? 'Updating' : 'Fetching'} data...`}
                        /> :
                        <div>
                          <TrendingHashTagsToolBox
                            dispatch={dispatch}
                            actionType={actionTypes.THT_UPDATE_PROP_VAL}
                            inputType={'number'}
                            min={5}
                            max={100}
                            placeHolder={'Limit'}
                            labelText={'API Limit'}
                            showLabel={true}
                            value={apiLimit}
                            onChangeValue={
                              (value) => dispatch({
                                type: actionTypes.THT_UPDATE_PROP_VAL,
                                payload: {
                                  prop: 'apiLimit',
                                  value,
                                },
                              })
                            }
                            firstButtonText={'Sync'}
                            onClickFirstButton={syncAPILimit}
                            disableFirstButton={!apiLimit}
                            showSecondButton={false}
                            showThirdButton={false}
                          />
                          <Alert className={'trending-hashtags-limit-warn-msg'} bsStyle="danger">
                            <strong>NOTE :</strong> This API limit is applicable to all languages
                          </Alert>
                          <ApiTrendingHashTags
                            apiTrendingHashTags={trendingHashtags}
                          />
                        </div>
                    }
                  </div>
                </Col>
                <Col md={6}>
                  <div className={'trending-hashtags-container'}>
                    <h4>
                      Trending Hashtags in Database
                    </h4>
                    {
                      isFetchingTrendingHashtagsData ?
                        <OperationStatus
                          operationInProgressText={`Please wait! Fetching data...`}
                        /> :
                        <div>
                          <TrendingHashTagsToolBox
                            dispatch={dispatch}
                            actionType={actionTypes.THT_UPDATE_PROP_VAL}
                            inputType={'number'}
                            min={5}
                            max={100}
                            placeHolder={'Limit'}
                            labelText={'Display Limit'}
                            showLabel={true}
                            value={dbLimit}
                            onChangeValue={
                              (value) => dispatch({
                                type: actionTypes.THT_UPDATE_PROP_VAL,
                                payload: {
                                  prop: 'dbLimit',
                                  value,
                                },
                              })
                            }
                            firstButtonText={'Fetch'}
                            onClickFirstButton={fetchDBData}
                            showSecondButton={false}
                            showThirdButton={false}
                          />
                          <DatabaseTrendingHashTags
                            onClickAddToCustom={
                              (value) => dispatch({
                                type: actionTypes.THT_ADD_CUSTOM_HASHTAG,
                                payload: value,
                              })
                            }
                            onClickBlock={
                              (value) => dispatch({
                                type: actionTypes.THT_BLOCK_HASHTAG,
                                payload: value,
                              })
                            }
                            dbTrendingHashTags={dbTrendingHashtags}
                          />
                        </div>
                    }

                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className={'trending-hashtags-container'}>
                    <h5>
                      Custom Trending Hashtags
                    </h5>
                    {
                      (updatingRtdbHashtagsData || loadingRtdbHashtagsData) ?
                        <OperationStatus
                          operationInProgressText={`Please wait! ${updatingRtdbHashtagsData ? 'Updating' : 'Fetching'} data...`}
                        /> :
                        <div>
                          <TrendingHashTagsToolBox
                            placeHolder={'Custom Tag'}
                            value={newCustomHashTag}
                            onChangeValue={
                              (value) => dispatch({
                                type: actionTypes.THT_UPDATE_PROP_VAL,
                                payload: {
                                  prop: 'newCustomHashTag',
                                  value,
                                },
                              })
                            }
                            onClickFirstButton={addNewCustomTag}
                            onClickSecondButton={syncCustomHashtags}
                            onClickThirdButton={resetCustomHashtags}
                            disableFirstButton={!newCustomHashTag}
                            disableSecondButton={!customHashtagsDataChanged}
                            disableThirdButton={!customHashtagsDataChanged}
                          />
                          <TrendingHashTags
                            trendingHashTags={customHashtags}
                            hashTagBackGroundColorCode={'rgb(154 202 230)'}
                            showUpAndDownButtons={true}
                            onClickUp={
                              (hashtag) => dispatch({
                                type: actionTypes.THT_CHANGE_CUSTOM_HASHTAG_ORDER,
                                payload: { hashtag, direction: 'up' },
                              })
                            }
                            onClickDown={
                              (hashtag) => dispatch({
                                type: actionTypes.THT_CHANGE_CUSTOM_HASHTAG_ORDER,
                                payload: { hashtag, direction: 'down' },
                              })
                            }
                            onRemoveHashtag={
                              (hashtag) => dispatch({
                                type: actionTypes.THT_REMOVE_CUSTOM_HASHTAG,
                                payload: hashtag,
                              })
                            }
                          />
                        </div>
                    }
                  </div>
                </Col>
                <Col md={6}>
                  <div className={'trending-hashtags-container'}>
                    <h5>
                      Blocked Trending Hashtags
                    </h5>
                    {
                      (updatingRtdbHashtagsData || loadingRtdbHashtagsData) ?
                        <OperationStatus
                          operationInProgressText={`Please wait! ${updatingRtdbHashtagsData ? 'Updating' : 'Fetching'} data...`}
                        /> :
                        <div>
                          <TrendingHashTagsToolBox
                            placeHolder={'Blocked Tags'}
                            value={newBlockedHashTag}
                            onChangeValue={
                              (value) => dispatch({
                                type: actionTypes.THT_UPDATE_PROP_VAL,
                                payload: {
                                  prop: 'newBlockedHashTag',
                                  value,
                                },
                              })
                            }
                            onClickFirstButton={blockNewCustomTag}
                            onClickSecondButton={syncBlockedHashtags}
                            onClickThirdButton={resetBlockedHashtags}
                            disableFirstButton={!newBlockedHashTag}
                            disableSecondButton={!blockedHashtagsDataChanged}
                            disableThirdButton={!blockedHashtagsDataChanged}
                          />
                          <TrendingHashTags
                            trendingHashTags={blockedHashtags}
                            hashTagBackGroundColorCode={'rgb(230 154 154)'}
                            onRemoveHashtag={
                              (hashtag) => dispatch({
                                type: actionTypes.THT_REMOVE_BLOCKED_HASHTAG,
                                payload: hashtag,
                              })
                            }
                          />
                        </div>
                    }
                  </div>
                </Col>
              </Row>
            </Fragment>
        }
      </div>
    </BasicLayout>
  );
}

export default ListHashTags;
