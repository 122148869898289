import React from "react";
import CustomFilter from "../../../../widgets/qalbox-table/custom-filter";

export const getQalboxAdUnitColumns = () => {
  return React.useMemo(() => [
    {
      Header: "ID",
      accessor: "id",
      disableSortBy: true,
      disableFilters: true,
      width: 300,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Name",
      accessor: "name",
      defaultCanSort: true,
      defaultCanFilter: true,
      Filter: CustomFilter,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Ad Space",
      accessor: "ad_space",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Android",
      accessor: "android_ad_unit_id",
      defaultCanSort: true,
      defaultCanFilter: false,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "IOS",
      accessor: "ios_ad_unit_id",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Web",
      accessor: "web_ad_unit_id",
      defaultCanSort: true,
      defaultCanFilter: false,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    }
  ]);
};
