import React from "react";
import { getMediaURLArrayV1, getMediaURLArrayV1NoDRM, getMediaURLArrayWithDRM, getMediaURLArrayWithoutDRM } from "../../helper";
import QalboxCopyInputBox from "./qalboxCopyInputBox";
import QalboxURLInputBox from "./qalboxURLInputBox";

const QalboxURLInput = ({ componentName, urlArray, onChange, isDrmProtected, setDrmProtected }) => {
  const handleInputTextChange = (e) => {
    let { name, value } = e.target;

    const prefix = name.split(".")[1];
    name = name.split(".")[0];

    if (isDrmProtected) {
      value = getMediaURLArrayV1(prefix, name, value, urlArray);
    } else {
      value = getMediaURLArrayV1NoDRM(prefix, value, urlArray);
    }

    onChange(name, value);
  };

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    setDrmProtected(checked);
    let modifiedUrlArray = urlArray;

    if (!checked) {
      modifiedUrlArray = getMediaURLArrayWithoutDRM(urlArray);
    } else {
      modifiedUrlArray = getMediaURLArrayWithDRM(urlArray);
    }
    
    onChange(componentName, modifiedUrlArray);
  };

  return (
    <div>
      <div className="form-group">
        <label>Is DRM Protected</label>
        <input
          type="checkbox"
          name="is_drm_protected"
          checked={isDrmProtected}
          onChange={handleCheckboxChange}
          style={{ transform: "scale(1.5)", marginLeft: "10px" }}
        />
      </div>
      <div className="form-group">
        <label>HLS URL</label>
        <QalboxURLInputBox
          name={`${componentName}.0`}
          value={urlArray && urlArray.length > 0 ? urlArray[0].src : ""}
          handleInputTextChange={handleInputTextChange}
          isHls = {true}
        />
        <label>DASH URL</label>
        <QalboxURLInputBox
          name={`${componentName}.1`}
          value={urlArray && urlArray.length > 1 ? urlArray[1].src : ""}
          handleInputTextChange={handleInputTextChange}
        />
      </div>
    </div>
  );
};

export default QalboxURLInput;
