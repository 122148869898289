import _ from 'lodash';
import React,
{
  useState,
} from 'react';
import {
  Link,
} from 'react-router-dom';
import {
  Icon,
} from 'react-fa';
import {
  MenuList,
} from './index';
import {
  updateExpandedMenus,
} from './helper';
import InlineWrapper from '../widgets/roledwrapper/inlinewrapper';
import ConditionalWrapper from '../widgets/roledwrapper/conditionalwrapper';
import DataModeratorWrapper from '../widgets/roledwrapper/datamoderatorwrapper';

const MoreLessIcon = ({
                        icon,
                        label,
                        isExpanded,
                        toggleMoreLess,
                      }) => {

  return (
    <li
      onClick={() => toggleMoreLess(label, !isExpanded)}
      className="sidebar__nav_more">
      <Icon name={icon} /> {label}
      <span className={`glyphicon glyphicon-triangle-${isExpanded ? 'top' : 'bottom'}`}/>
    </li>
  );
}

const MenuItem = ({
                    icon,
                    menu=[],
                    label,
                    expandedMenus,
                    toggleMoreLess,
                    usersPermission,
                  }) => {

  let count = 0, menuEl, activeInGroup;
  menu.forEach((item) => {
    if(window.location.pathname.includes(item.route) || item.route.startsWith(window.location.pathname)) {
      activeInGroup = true;
    }
  });
  const isExpanded = expandedMenus[label];

  menuEl = menu.map((item, i) => {
    if((usersPermission & item.permission) === item.permission) {
      count++;
    }

    const makeActive = window.location.pathname === item.route
      || window.location.pathname === item.route.split('?')[0]
      || item.route.split('/')[1] === window.location.pathname.split('/')[1];

    const LinkComponent = (
        <li>
          <Link className={makeActive ? 'active' : ''} to={item.route}>
            <span className={item.icon + ' sidebar__nav__icon'}/>
            {item.label}
          </Link>
        </li>
    );

    if(!activeInGroup && expandedMenus && !isExpanded) {
      return null;
    } else {
      if (item.requireDataModeratorPermission) {
        return (
            <DataModeratorWrapper>
              {LinkComponent}
            </DataModeratorWrapper>
        );
      } else {
        return (
            <InlineWrapper
                key={i}
                usersPermission={usersPermission}
                permission={item.permission}
            >
              {LinkComponent}
            </InlineWrapper>
        );
      }
    }
  });

  return (
    <ConditionalWrapper show={( count > 0)}>
      <ul className="sidebar__nav">
        <MoreLessIcon
          label={label}
          icon={icon}
          isExpanded={isExpanded}
          toggleMoreLess={toggleMoreLess}
        />
        {menuEl}
      </ul>
    </ConditionalWrapper>
  );
};

const MenuItems = ({
                     usersPermission,
                   }) => {
  const [ expandedMenus, setExpandedMenus ] = useState({});
  const toggleMoreLess = (key, showHide) => {
    const updatedMenus = updateExpandedMenus(expandedMenus, key, showHide)
    setExpandedMenus(updatedMenus);
  };

  return _.map(MenuList, menuListItem => {
    return (
      <MenuItem
        key={menuListItem.label}
        expandedMenus={expandedMenus}
        menu={menuListItem.menu}
        label={menuListItem.label}
        icon={menuListItem.icon}
        usersPermission={usersPermission}
        toggleMoreLess={toggleMoreLess}
      />
    );
  });
};

export {
  MenuItem,
  MenuItems,
  MoreLessIcon,
}
