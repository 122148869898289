import axiosService from "../common/axios-service";
import {
  MP_CF_CONTENT_V2_ADMIN_CREATE_USER_DATA,
  MP_CF_CONTENT_V2_ADMIN_DELETE_USER_DATA,
  MP_CF_CONTENT_V2_ADMIN_GET_USER_SHARDID,
  MP_CF_CONTENT_V2_ADMIN_QUERY_USER_DATA,
  MP_CF_CONTENT_V2_ADMIN_SET_USER_DATA,
  MP_CF_CONTENT_V2_ADMIN_UPDATE_USER_DATA
} from "../../Constants";

class AdminUserService {
  getUserShardId = (uid) => {
    return this._baseUserEndpoint(MP_CF_CONTENT_V2_ADMIN_GET_USER_SHARDID, {
      target_uid: uid
    })
  }

  queryUserData =  (uid, { path }) => {
    return this._baseUserEndpoint(MP_CF_CONTENT_V2_ADMIN_QUERY_USER_DATA, {
      path,
      target_uid: uid
    })
  }

  updateUserDate =  (uid, body) => {
    return this._baseUserEndpoint(MP_CF_CONTENT_V2_ADMIN_UPDATE_USER_DATA, {
      ...body,
      target_uid: uid
    })
  }

  createUserData =  (uid, body) => {
    return this._baseUserEndpoint(MP_CF_CONTENT_V2_ADMIN_CREATE_USER_DATA, {
      ...body,
      target_uid: uid
    })
  }

  setUserData =  (uid, body) => {
    return this._baseUserEndpoint(MP_CF_CONTENT_V2_ADMIN_SET_USER_DATA, {
      ...body,
      target_uid: uid
    })
  }

  deleteUserData =  (uid, body) => {
    return this._baseUserEndpoint(MP_CF_CONTENT_V2_ADMIN_DELETE_USER_DATA, {
      ...body,
      target_uid: uid
    })
  }

  _baseUserEndpoint = (endpointName, body) => {
    return axiosService.post(endpointName, {
      data: {
        ...body,
        platform: "web"
      },
    }).then( response => {
      if( response.status === 200 ) {
        const data = response.data['result'];
        if( data && (data.success && data.status == 'success') ) {
          return data.payload;
        }else{
          console.log(endpointName, 'error',  data.error )
        }
      }else{
        console.log(endpointName, response.status )
      }
    }).catch( error => {
      console.log(endpointName, error )
    })
  }
}

export default new AdminUserService();