import React,
{
  Component,
} from 'react';
import {
  Col,
  Row,
  Button,
  FormControl,
  ControlLabel,
} from 'react-bootstrap';
import './style.css';

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: ""
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      searchString: nextProps.searchBarDefault
    });
  }

  handleSearchSubmit(e) {
    e.preventDefault();
    this.props.onSearch(this.state.searchString);
  }

  handleInputChange(e) {
    this.setState({
      searchString: e.target.value
    });
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSearchSubmit.bind(this)}>
          <ControlLabel>Search user</ControlLabel>
          <Row>
            <Col md={10}>
              <FormControl value={this.state.searchString} placeholder="Enter users email, phone, uid or a purchase token" onChange={this.handleInputChange.bind(this)}/>
            </Col>
            <Col md={2}>
              <Button className="btn-primary search__button" type="submit">Submit</Button>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

export default SearchBar;
