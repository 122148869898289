import _ from 'lodash';
import React from 'react';
import {
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  fetchTimeLinePromosRawJsonHook,
} from '../../hooks';
import TimelinePromosList from './timelinePromosList';
import {
  ResultsCount,
  LastUpdatedTime,
} from '../../widgets/common';

const renderTimelinePromosList  = (timelinePromos) => {
  if(_.isEmpty(timelinePromos)) {
    return (
        <p>
          {'No Timeline Promos found!'}
        </p>
    );
  }

  return (
      <Row>
        <Col md={12}>
          <LastUpdatedTime lastUpdatedTimeStamp={timelinePromos['timestamp']*1000}/>
          <ResultsCount
              results={timelinePromos['timeline_promos']}
              itemText={'Timeline Promos'}
          />
          <TimelinePromosList timelinePromos={timelinePromos}/>
        </Col>
      </Row>
  );
};

const ListTimelinePromos = () => {

  const [ timelinePromos ] = fetchTimeLinePromosRawJsonHook({
    shouldFetchTimelinePromos: true,
  });

  return (
      <BasicLayout pagePermission="readonly">
        <ToastContainer autoClose={2000}/>
        <div>
          <form className={'form-inline'}>
            <Row>
              <Col md={12}>
                <h3 className='content_editor_title'>{'Timeline Promos List'}</h3>
              </Col>
            </Row>
            {renderTimelinePromosList(timelinePromos)}
            <br/>
          </form>
        </div>
      </BasicLayout>
  );
};

export default ListTimelinePromos;
