import {
  createAdminLogs,
} from './mp-cf-api-service';

const USER = JSON.parse(localStorage.getItem('user'));
const showBgLogsRes = localStorage.getItem("showBgLogsRes") || false;
const ip_address = localStorage.getItem("ip_address");

const createLog = (actionType, actionObject) => {
  const logObj = buildLogObject(actionType, actionObject);
  return createAdminLogsWithCF(logObj);
};

const createAdminLogsWithCF = (data) => {
  return createAdminLogs(data)
  .then((res) => {
    if(showBgLogsRes) {
      console.log("createAdminLogs API: ", res.data);
    }
    return Promise.resolve();
  })
  .catch((err) => {
    if(showBgLogsRes) {
      console.log("createAdminLogs error: ", err);
    }
    return Promise.resolve();
  });
}

const buildLogObject = (actionType, actionObject) => {
  let user = USER, actObj = actionObject;
  if(actionType === 'login') {
    user = actionObject;
    actObj = null;
  }
  const {
    email,
    uid,
  } = user;

  return {
    timestamp: parseInt(Date.now()/1000, 10),
    user_id: uid,
    email,
    ip_address,
    user_agent: window.navigator.userAgent,
    action_type: actionType,
    action_object: JSON.stringify(actObj),
  };
}

export {
  createLog,
};
