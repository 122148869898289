import React  from 'react';
import {
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  MPGCSUploadImages,
} from '../../widgets/upload-images';
import {
  getUploadableFiles,
} from './helper';

const UploadImages = () => {

  return (
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={2000}/>
        <Row>
          <Col md={12}>
            <h3 className='content_editor_title'>{'Upload Images'}</h3>
          </Col>
        </Row>
        <MPGCSUploadImages
            getUploadableFiles={files => getUploadableFiles(files)}
        />
      </BasicLayout>
  );
};

export default UploadImages;
