import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import './style.css';

const languages = ['ar','de','debug','en','es','fr','id','it','ja','ms','nl','pt','ru','th','tr','ur','zh','az','bn','da','ff','ug'];


class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      langValue: 'en',
      reqTypeValue: 'visible',
      sortByValue: 'newest'
    };
    this.handleLangChange = this.handleLangChange.bind(this);
    this.handleReqTypeChange = this.handleReqTypeChange.bind(this);
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  componentWillMount() {
    this.setState({
      langValue: this.props.selectedLan,
      reqTypeValue: this.props.selectedReqType,
      sortByValue: this.props.selectedSortBy,
    });
  }

  handleLangChange(e) {
    this.setState({langValue: e.target.value});
    this.props.onLanguageChange(e.target.value);
  }

  handleReqTypeChange(e) {
    this.setState({reqTypeValue: e.target.value});
    this.props.onReqTypeChange(e.target.value);
  }

  handleSortBy(e) {
    this.setState({sortByValue: e.target.value});
    this.props.onSortByChange(e.target.value);
  }

  renderLanguages(languages) {
    return languages.map((lang, i) => {
      return <option key={i} value={lang}>{lang}</option>;
    });
  }

  render() {
    return (
      <div className="filter__cont">
        <Row>
          <Col md={2}>
            <strong className="filter__label">Filter options:</strong>
          </Col>
          <Col md={3}>
            <div className="filter__drop">
              Status of requests {' '}
              <select value={this.state.reqTypeValue} onChange={this.handleReqTypeChange}>
                <option value="visible">Visible</option>
                <option value="blocked">Blocked</option>
              </select>
            </div>
          </Col>
          <Col md={3}>
            <div className="filter__drop">
            Language {' '}
            <select value={this.state.langValue} onChange={this.handleLangChange}>
              {this.renderLanguages(languages)}
            </select>
            </div>
          </Col>
          <Col md={2}>
            <strong className="filter__label">Sort by:</strong>
          </Col>
          <Col md={3}>
            <select value={this.state.sortByValue} onChange={this.handleSortBy} className="filter__drop">
              <option value="newest">Newest</option>
              <option value="oldest">Oldest</option>
              <option value="mostPrayed">Most prayed</option>
              <option value="leastPrayed">Least prayed</option>
              <option value="mostReported">Most reported</option>
              <option value="leastReported">Least reported</option>
            </select>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Filters;
