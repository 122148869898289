import _ from 'lodash';
import React,
{
  Fragment,
  Component,
} from 'react';
import {
  Row,
  Col,
  Alert,
  Button,
} from 'react-bootstrap';
import BasicLayout from "../../layouts/basiclayout";
import {
  toast,
  ToastContainer,
} from "react-toastify";
import MultiFileUploader from "../../widgets/multiFileUploader";
import readXlsxFile from 'read-excel-file';
import moment from 'moment';
import JSONPretty from 'react-json-pretty';
import JSONPrettyMon from 'react-json-pretty/dist/adventure_time';
import Collapse,
{
  Panel,
} from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import {
  updateV2HashTags,
  getContentMetaData,
  createOrUpdateV2BulkContents,
} from '../../services/mp-cf-api-service';
import {
  isBoolean,
} from './helper';
import {
  CONTENT_TYPE,
  FEATURED_TYPE,
  ContentSupportedLanguages,
  EXCEL_CONTENT_CREATE_LIMIT,
  ENGLISH_CHARACTERS_SUPPORTED_LANGUAGES,
} from './Constants';
import {
  CDN_ORIGIN_DOMAIN_NAME,
} from '../../Constants';
import {
  isValidAudiences,
  getDateFromExcelValue,
} from '../../utils';
import {
  getVideoId,
  shouldShowAds,
  isValidHashTag,
  getRandomNumber,
  getDataFromMeta,
} from './helper';
const sampleExcelFileUrl = 'https://docs.google.com/spreadsheets/d/1xm1unYDzVjgFwZ8i0dfJe-cqdVrRYD8VUmZxam4c1Y8/edit?usp=sharing';

const SupportedLanguages = _.without(_.keys(ContentSupportedLanguages), 'all');

const InitialState = {
  uploading: false,
  files: [],
  allErrors: [],
};

const BASE_DATE = '01.01.2019'; // DD.MM.YYYY
const SPECIAL_HEADERS_COUNT = 2;

const getQuoteDayCode = (name) => {
  return Number(name.split('_')[2]);
};

const getYearCode = (momentDate) => {
  return momentDate.year();
};

const getQuotePublishDate = (dayCode) => {
  const start_date = moment(BASE_DATE);
  return start_date.add(dayCode - 1, 'days');
};

const OLD_CONTENTS_ARRAY = [];

const arrowPath = 'M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88' +
    '.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.' +
    '6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-0.7 5.' +
    '2-2L869 536.2c14.7-12.8 14.7-35.6 0-48.4z';

function expandIcon({ isActive }) {
  return (
      <i style={{ marginRight: '.5rem' }}>
        <svg
            viewBox="0 0 1024 1024"
            width="1em"
            height="1em"
            fill="currentColor"
            style={{
              verticalAlign: '-.125em',
              transition: 'transform .2s',
              transform: `rotate(${isActive ? 90 : 0}deg)`,
            }}
        >
          <path d={arrowPath} p-id="5827"></path>
        </svg>
      </i>
  );
}

const isValidContentType = (contentType) => {
  return Object.values(CONTENT_TYPE).includes(contentType)
};

const getValidFeaturedCategory = (featuredCategory) => {
  if(!featuredCategory) {
    return FEATURED_TYPE.DEFAULT;
  }

  if(Object.values(FEATURED_TYPE).includes(featuredCategory)) {
    return featuredCategory;
  }

  return null;
};

const isValidLanguageCode = (languageCode) => {
  return SupportedLanguages.includes(languageCode.toLowerCase())
};

const getMetaInfo = async (url) => {
  const metaData = {};

  // analysing the url for youtube
  let url_temp = url;
  let domain;
  url_temp = url_temp.split('//');
  if (url_temp.length === 1) {
    //no protocol - no http
    url = 'http://' + url;
    domain = (url_temp[0].split('/'))[0];
  } else {
    domain = (url_temp[1].split('/'))[0];
  }

  if(domain.endsWith('youtube.com') || domain.endsWith('youtu.be')) {
    let video_id = getVideoId(url);
    if (video_id != null) {
      url = 'https://youtube.com/watch?v=' + video_id;
      metaData.video_id = video_id;
    }
  }

  const metaDataResp = await getContentMetaData({url});
  const htmlString = metaDataResp.data;
  // fetching meta
  const jQ = window.jQuery;
  const crawledHTML = jQ.parseHTML(htmlString);
  let contentThumbnail = null, contentTitle = null,
      contentDescription = null, contentSource = null;

  crawledHTML.forEach((token) => {
    if(jQ(token).is('meta')) {
      if(!contentThumbnail){ contentThumbnail = getDataFromMeta('image', jQ(token)); }
      if(!contentTitle){ contentTitle = getDataFromMeta('title', jQ(token)); }
      if(!contentDescription){ contentDescription = getDataFromMeta('description', jQ(token)); }
      if(!contentSource){ contentSource = getDataFromMeta('site_name', jQ(token)); }
    }
  });

  metaData.urls = [url];
  metaData.thumbnail_url = contentThumbnail;
  metaData.title = contentTitle;
  metaData.summary = contentDescription;
  metaData.source = contentSource;

  return metaData;
};

const getArrayFromString = (str) => {
  return str.replace(/\s/g, '').replace(/(^,)|(,$)/g, "").split(',')
};

const getContents = async (rows) => {
  const contents = [];
  const allErrors = [];
  // Starting from 2, because we need to skip title and comments.
  for(let i = SPECIAL_HEADERS_COUNT; i < rows.length; i++) {
    let errorsCount = 0;
    const data = rows[i];

    const content = {
      'referenceId': i + 1,
    };

    const errors = {
      'row': i + 1,
      'errorsCount': 0,
      'errorList': [],
      'excelSheetRowData': data,
    };

    // Content Type
    let contentType = data[0];
    if(!contentType) {
      errors['errorList'].push(`${++errorsCount}. Content type is missing.`);
    } else {
      contentType = contentType.toLowerCase();
      if(isValidContentType(contentType)) {
        content['content_type'] = contentType;
      } else {
        errors['errorList'].push(`${++errorsCount}. Content type is invalid.`);
      }
    }

    // Content Id
    const contentId = data[1];
    if(contentId) {
      content['id'] = contentId;
      OLD_CONTENTS_ARRAY.push({
        id: contentId
      });
    }

    // Language Code
    let languageCode = data[2];
    if(!languageCode) {
      errors['errorList'].push(`${++errorsCount}. Content language is missing.`);
    } else {
      languageCode = languageCode.toLowerCase();
      if(isValidLanguageCode(languageCode)) {
        content['language_code'] = languageCode;
      } else {
        errors['errorList'].push(`${++errorsCount}. Language code is invalid.`);
      }
    }

    // Content Title
    const contentTitle = data[3];
    if(contentType && (contentType === CONTENT_TYPE.QUOTE || contentType === CONTENT_TYPE.IMAGE)) {
      // image and quote content types
      if(contentId) {
        // old content
        if(contentTitle) {
          content['title'] = contentTitle;
        }

      } else {
        // new content
        if(contentTitle) {
          content['title'] = contentTitle;
        } else {
          errors['errorList'].push(`${++errorsCount}. Content title is missing.`);
        }
      }
    } else {
      // other content types
      if(contentId) {
        // old content
        if(contentTitle) {
          content['title'] = contentTitle;
        }
      } else {
        // new content - does't matter if empty, will fetch based on url later.
        content['title'] = contentTitle;
      }
    }

    // Content For Ramadan
    if(contentType && contentType === CONTENT_TYPE.QUOTE) {
      const forRamadan = data[4];
      if(contentId) {
        // old content
        if(!_.isNil(forRamadan)) {
          content['for_ramadan'] = forRamadan;
        }
      } else {
        // new content
        if(_.isNil(forRamadan)) {
          errors['errorList'].push(`${++errorsCount}. Content For Ramadan is missing.`);
        } else {
          content['for_ramadan'] = forRamadan;
        }
      }
    }

    // Content Thumbnail Url and Urls
    const thumbnailUrl = data[5];
    if(contentType && contentType === CONTENT_TYPE.QUOTE) {
      if(thumbnailUrl) {
        content['thumbnail_url'] = thumbnailUrl;
        content['urls'] = [thumbnailUrl];
      } else {
        const fileUrl = content['for_ramadan'] ?
            `images/inspiration/ramadan/${languageCode}/${contentTitle}.jpg` :
            `images/inspiration/${languageCode}/${contentTitle}.jpg`;
        const publicUrl = `https://${CDN_ORIGIN_DOMAIN_NAME}/${fileUrl}`;
        content['thumbnail_url'] = publicUrl;
        content['urls'] = [publicUrl];
      }
    }

    if(contentType && contentType === CONTENT_TYPE.IMAGE) {
      if(contentId) {
        // old content
        if(thumbnailUrl) {
          content['thumbnail_url'] = thumbnailUrl;
          content['urls'] = [thumbnailUrl];
        }
      } else {
        // new content
        if(thumbnailUrl) {
          content['thumbnail_url'] = thumbnailUrl;
          content['urls'] = [thumbnailUrl];
        } else {
          errors['errorList'].push(`${++errorsCount}. Content Thumbnail URL is missing. [New image contents must have Thumbnail URL]`);
        }
      }
    }

    // Content Is Featured
    const isFeatured = data[7];
    if(contentId) {
      // old content
      if(!_.isNil(isFeatured)) {
        if (isBoolean(isFeatured)) {
          content['is_featured'] = isFeatured;
        } else {
          errors['errorList'].push(`${++errorsCount}. Invalid value for Is Featured.`);
        }
      }
    } else {
      // new content
      if(!_.isNil(isFeatured)) {
        if (isBoolean(isFeatured)) {
          content['is_featured'] = isFeatured;
        } else {
          errors['errorList'].push(`${++errorsCount}. Invalid value for Is Featured.`);
        }
      } else {
        content['is_featured'] = false;
      }
    }

    // Content Featured Data.
    if(content['is_featured']) {
      // Content Featured Start Date.
      const featureStartDate = data[8];
      if(_.isNil(featureStartDate)) {
        errors['errorList'].push(`${++errorsCount}. Content Featured Start Date is missing.`);
      } else {
        const formattedDate = _.toString(getDateFromExcelValue(featureStartDate));
        if (!_.isNumber(featureStartDate) || formattedDate === 'Invalid Date') {
          errors['errorList'].push(`${++errorsCount}. Content Featured Start Date is Invalid.`);
        } else {
          content['featured_start_date'] = getDateFromExcelValue(featureStartDate);
        }
      }

      // Content Featured End Date.
      const featureEndDate = data[9];
      if(_.isNil(featureEndDate)) {
        errors['errorList'].push(`${++errorsCount}. Content Featured End Date is missing.`);
      } else {
        const formattedDate = _.toString(getDateFromExcelValue(featureEndDate));
        if (!_.isNumber(featureEndDate) || formattedDate === 'Invalid Date') {
          errors['errorList'].push(`${++errorsCount}. Content Featured End Date is Invalid.`);
        } else {
          content['featured_end_date'] = getDateFromExcelValue(featureEndDate);
        }
      }

      // Content Featured Category.
      const featuredCategory = getValidFeaturedCategory(data[21]);
      if(featuredCategory) {
        content['featured_category'] = featuredCategory
      } else {
        errors['errorList'].push(`${++errorsCount}. Invalid featured category.`);
      }
    }

    // Content Is Pinned
    const isPinned = data[10];
    if(contentId) {
      // old content
      if(!_.isNil(isPinned)) {
        if (isBoolean(isPinned)) {
          content['is_pinned'] = isPinned;
        } else {
          errors['errorList'].push(`${++errorsCount}. Invalid value for Is Pinned.`);
        }
      }
    } else {
      // new content
      if(!_.isNil(isPinned)) {
        if (isBoolean(isPinned)) {
          content['is_pinned'] = isPinned;
        } else {
          errors['errorList'].push(`${++errorsCount}. Invalid value for Is Pinned.`);
        }
      } else {
        content['is_pinned'] = false;
      }
    }

    // Content Pinned Start and End Dates
    if(content['is_pinned']) {
      // Content Pinned Start Date.
      const pinnedStartDate = data[11];
      if(_.isNil(pinnedStartDate)) {
        errors['errorList'].push(`${++errorsCount}. Content Pinned Start Date is missing.`);
      } else {
        const formattedDate = _.toString(getDateFromExcelValue(pinnedStartDate));
        if (!_.isNumber(pinnedStartDate) || formattedDate === 'Invalid Date') {
          errors['errorList'].push(`${++errorsCount}. Content Pinned Start Date is Invalid.`);
        } else {
          content['pinned_start_date'] = getDateFromExcelValue(pinnedStartDate);
        }
      }

      // Content Pinned End Date.
      const pinnedEndDate = data[12];
      if(_.isNil(pinnedEndDate)) {
        errors['errorList'].push(`${++errorsCount}. Content Pinned End Date is missing.`);
      } else {
        const formattedDate = _.toString(getDateFromExcelValue(pinnedEndDate));
        if (!_.isNumber(pinnedEndDate) || formattedDate === 'Invalid Date') {
          errors['errorList'].push(`${++errorsCount}. Content Pinned End Date is Invalid.`);
        } else {
          content['pinned_end_date'] = getDateFromExcelValue(pinnedEndDate);
        }
      }
    }

    // Content is Hidden
    const isHidden = data[13];
    if(contentId) {
      // old content
      if(!_.isNil(isHidden)) {
        if (isBoolean(isHidden)) {
          content['is_hidden'] = isHidden;
        } else {
          errors['errorList'].push(`${++errorsCount}. Invalid value for Is Hidden.`);
        }
      }
    } else {
      // new content
      if(!_.isNil(isHidden)) {
        if (isBoolean(isHidden)) {
          content['is_hidden'] = isHidden;
        } else {
          errors['errorList'].push(`${++errorsCount}. Invalid value for Is Hidden.`);
        }
      } else {
        content['is_hidden'] = false;
      }
    }


    // Content Source
    const source = data[15];
    if(source) {
      content['source'] = source;
    }

    // Content Series Id
    const seriesId = data[16];
    if(seriesId) {
      content['series_id'] = seriesId;
    }

    // Content Audiences
    let audiences = [];
    const audiencesString = data[17];
    if(audiencesString) {
      const audiencesArray = getArrayFromString(audiencesString);
      if(!_.isEmpty(audiencesArray)) {
        audiences = _.map(audiencesArray, audience => audience.toLowerCase())
      }
    }
    if(contentId) {
      // old content
      if(!_.isEmpty(audiences)) {
        if(isValidAudiences(audiences)) {
          content['audiences'] = audiences;
        } else {
          errors['errorList'].push(`${++errorsCount}. Audiences are invalid.`);
        }
      }
    } else {
      // new content
      if(_.isEmpty(audiences)) {
        // Use default
        content['audiences'] = ['all'];
      } else {
        if(isValidAudiences(audiences)) {
          content['audiences'] = audiences;
        } else {
          errors['errorList'].push(`${++errorsCount}. Audiences are invalid.`);
        }
      }
    }

    // Content Countries
    let countries = [];
    const countriesString = data[18];
    if(countriesString) {
      const countriesArray = getArrayFromString(countriesString);
      if(!_.isEmpty(countriesArray)) {
        countries = _.map(countriesArray, country => country.toUpperCase())
      }
    }
    if(contentId) {
      // old content
      if(!_.isEmpty(countries)) {
        content['countries'] = countries;
      }
    } else {
      // new content
      content['countries'] = countries;
    }

    // Content HashTags
    let hashTags = [];
    const hashTagString = data[19];
    if(hashTagString) {
      const hashTagsArray = getArrayFromString(hashTagString);
      if(!_.isEmpty(hashTagsArray)) {
        if(ENGLISH_CHARACTERS_SUPPORTED_LANGUAGES.includes(content['language_code'])) {
          hashTags = _.filter(_.map(hashTagsArray,
                  hashTag => hashTag.replace(/[^a-zA-Z0-9 ]|\s/g, '').toLowerCase()), _.size);
        } else {
          hashTags = _.filter(_.map(hashTagsArray,
              hashTag => hashTag.replace(/\s/g, '')), isValidHashTag);
        }

        if(!_.isEmpty(hashTags)) {
          hashTags = _.uniq(hashTags);
        }
      }
    }
    if(contentId) {
      // old content
      if(!_.isEmpty(hashTags)) {
        content['hash_tags'] = hashTags;
      }
    } else {
      // new content
      content['hash_tags'] = hashTags;
    }

    // Content Publish Date
    const publishedDate = data[20];
    if(contentType === CONTENT_TYPE.QUOTE) {
      if(content['for_ramadan']) {
        // Ramadan Content
        if(_.isNil(publishedDate)) {
          errors['errorList'].push(`${++errorsCount}. Ramadan Content must have a publish date.`);
        } else {
          const formattedDate = _.toString(getDateFromExcelValue(publishedDate));
          if (!_.isNumber(publishedDate) || formattedDate === 'Invalid Date') {
            errors['errorList'].push(`${++errorsCount}. Ramadan Content Publish Date is Invalid.`);
          } else {
            const updatedDate =  getDateFromExcelValue(publishedDate);
            content['day_code'] = getQuoteDayCode(content['title']);
            content['year_code'] = updatedDate.getFullYear();
            content['publish_date'] = updatedDate;
          }
        }
      } else {
        // Non-Ramdan Content
        const dayCode = getQuoteDayCode(content['title']);
        const quotePublishDate = getQuotePublishDate(dayCode);
        content['day_code'] = dayCode;
        content['year_code'] = getYearCode(quotePublishDate);
        content['publish_date'] = quotePublishDate;
      }
    } else {
      if(contentId) {
        // old content
        if(!_.isNil(publishedDate)) {
          content['publish_date'] = getDateFromExcelValue(publishedDate);
        }
      } else {
        // new content
        if(!_.isNil(publishedDate)) {
          content['publish_date'] = getDateFromExcelValue(publishedDate);
        } else {
          content['publish_date'] = new Date()
        }
      }
    }

    if(contentType === CONTENT_TYPE.IMAGE) {
      content['external_url'] = data[22];
    }

    content['external_url'] = data[22];

    // Content Urls
    if(contentId) {
      // old content
      if(contentType &&
          (contentType === CONTENT_TYPE.VIDEO || contentType === CONTENT_TYPE.ARTICLE )) {
        if(thumbnailUrl) {
          content['thumbnail_url'] = thumbnailUrl;
        }
        let urls = [];
        const urlsString = data[6];
        if(urlsString) {
          const urlsArray = getArrayFromString(urlsString);
          if(!_.isEmpty(urlsArray)) {
            urls = urlsArray
          }
        }

        if(!_.isEmpty(urls)) {
          content['urls'] = urls;
          const metaData = await getMetaInfo(urls[0]);
          console.log(`contentType : ${contentType} Meta Data : ${JSON.stringify(metaData)}`);

          // For the below params, priority goes to excel sheet values.

          if(!content['thumbnail_url'] && metaData.thumbnail_url) {
            content['thumbnail_url'] = metaData.thumbnail_url;
          }

          if(!content['title'] && metaData.title) {
            content['title'] = metaData.title;
          }

          if(!content['source'] && metaData.source) {
            content['source'] = metaData.source;
          }

          if(metaData.summary) {
            content['summary'] = metaData.summary;
          }

          if(metaData.video_id) {
            content['video_id'] = metaData.video_id;
          }
        }
      }
    } else {
      // new content
      if(contentType &&
          (contentType === CONTENT_TYPE.VIDEO || contentType === CONTENT_TYPE.ARTICLE )) {
        content['thumbnail_url'] = thumbnailUrl;
        let urls = [];
        const urlsString = data[6];
        if(urlsString) {
          const urlsArray = getArrayFromString(urlsString);
          if(!_.isEmpty(urlsArray)) {
            urls = urlsArray
          }
        }

        if(_.isEmpty(urls)) {
          errors['errorList'].push(`${++errorsCount}. URLs are missing.`);
        } else {
          content['urls'] = urls;
          const metaData = await getMetaInfo(urls[0]);
          console.log(`contentType : ${contentType} Meta Data : ${JSON.stringify(metaData)}`);

          // For the below params, priority goes to excel sheet values.

          if(!content['thumbnail_url'] && metaData.thumbnail_url) {
            content['thumbnail_url'] = metaData.thumbnail_url;
          }

          if(!content['title'] && metaData.title) {
            content['title'] = metaData.title;
          }

          if(!content['source'] && metaData.source) {
            content['source'] = metaData.source;
          }

          if(metaData.summary) {
            content['summary'] = metaData.summary;
          }

          if(metaData.video_id) {
            content['video_id'] = metaData.video_id;
          }
        }
      }
    }

    // Content show Ads
    const showAds = data[14];
    if(contentId) {
      // old content
      if(!_.isNil(showAds)) {
        if (isBoolean(showAds)) {
          content['show_ads'] = showAds;
        } else {
          errors['errorList'].push(`${++errorsCount}. Invalid value for Show Ads.`);
        }
      }
    } else {
      // new content
      if(!_.isNil(showAds)) {
        if (isBoolean(showAds)) {
          content['show_ads'] = showAds;
        } else {
          errors['errorList'].push(`${++errorsCount}. Invalid value for Show Ads.`);
        }
      } else {
        if(shouldShowAds(content)) {
          content['show_ads'] = true;
        }
      }
    }

    if(contentId) {
      // old content
      content['updated_date'] = new Date();
      content['updated_by'] = localStorage.getItem('uid');
    } else {
      // new content
      content['random_number'] = getRandomNumber();
      content['created_date'] = new Date();
      content['created_by'] = localStorage.getItem('uid');
    }

    errors['errorsCount'] = errorsCount;
    if(errors.errorsCount > 0) {
      allErrors.push(errors)
    }
    contents.push(content);
  }

  return {
    contents,
    allErrors,
  };
};

const getIdAddedContents = (contents, idsList) => {
  const updatedContents = [];
  for(const content of contents) {
    const respObj = _.find(idsList, respObj => respObj.referenceId === content.referenceId);
    content['id'] = respObj.id;
    delete content['referenceId'];

    updatedContents.push(content);
  }

  return updatedContents
};

const removeOldContentHashTags = async (oldContentIds) => {
  return  updateV2HashTags({
    contents: oldContentIds,
    operation: 'remove',
    use_db_content_for_remove: true,
    force_error: false,
  });
};

class UploadContentsExcel extends Component {
  constructor(props) {
    super(props);
    this.state = InitialState;
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach(file => URL.revokeObjectURL(file.preview))
  }

  async uploadContents() {
    const {files} = this.state;
    const rows = await readXlsxFile(files[0]);

    try {
      if (rows.length > EXCEL_CONTENT_CREATE_LIMIT) {
        this.setState({
          allErrors: [
            `Error : You only can create/update ${EXCEL_CONTENT_CREATE_LIMIT} contents for a single upload. Uploaded file has ${rows.length - SPECIAL_HEADERS_COUNT} contents.`
          ],
        })
      } else {
        const { contents, allErrors } = await getContents(rows);
        console.log(`All contents : ${JSON.stringify(contents)}`);
        if (allErrors.length > 0) {
          console.log(`allErrors : ${JSON.stringify(allErrors)}`);
          this.setState({
            allErrors,
          })
        } else {
          // Remove all content hashtags.
          const removeOldHashTagsResp = await removeOldContentHashTags(OLD_CONTENTS_ARRAY);
          if (removeOldHashTagsResp.data.result === 'success') {
            // Create new contents
            const res = await createOrUpdateV2BulkContents({ contents });

            if (res.data.result === 'success') {
              const idAddedContents = getIdAddedContents(contents, res.data.savedContents);

              // Update all content hashtags.
              const updateOldHashTagsResp = await updateV2HashTags({
                contents: idAddedContents,
                operation: 'update',
                force_error: false,
                use_db_content_publish_date: true,
              });

              if(updateOldHashTagsResp.data.result === 'success') {
                this.setState(InitialState);
                toast.success('New contents created successfully!', {
                  position: toast.POSITION.BOTTOM_LEFT
                });
              } else {
                console.log("Hashtag Update failed.");
                throw Error("Hashtag Update failed.");
              }
            } else {
              console.log("Contents Save failed.");
              throw Error("Contents Save failed.");
            }
          } else {
            console.log("Hashtag Delete failed.");
            throw Error("Hashtag Delete Failed.");
          }
        }
      }
    } catch (err) {
      console.log('Error occurred while creating the contents. Error : ', err);
      this.setState(InitialState);
      toast.error('Error occurred while creating contents. Please try again', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const confirm = window.confirm(`Are you sure about uploading this file for creating contents in Database ?`);
    if(confirm) {
      this.setState({ uploading: true });
      this.uploadContents().then(() => {
        console.log('Content upload success.')
      });
    }
  }

  resetView() {
    this.setState(InitialState);
  }

  onDrop(files) {
    console.log('files : ', files);
    this.setState({
      files: files.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
    })
  };

  onSelectChange(e) {
    const category = e.target.value;
    this.setState({ selectedLanguage : category });
  }

  renderSelect(arr, selectedLanguage) {
    const options = arr.map((item, i) => {
      return (
          <option value={item.key} key={i}>{item.value}</option>
      );
    });

    return (
        <select value={selectedLanguage}
                onChange={this.onSelectChange.bind(this)}
                className="form-control">
          <option disabled value="default">Please select one</option>
          {options}
        </select>
    );
  }

  removeFile(file) {
    const newFiles = [...this.state.files];
    newFiles.splice(newFiles.indexOf(file), 1);
    this.setState({ files: newFiles });
  };

  removeAllFiles() {
    this.setState({ files: [] });
  }

  renderSubmitButton(uploading, filesLength) {
    if(uploading) {
      return (
          <Button disabled={true} className="btn-primary content_editor_submit_btn">Uploading...</Button>
      );
    } else {
      return (
          <Button disabled={filesLength === 0} type="submit" className="btn-primary content_editor_submit_btn">Upload</Button>
      );
    }
  }

  render() {
    const {
      files, allErrors, uploading,
    } = this.state;

    return (
        <BasicLayout pagePermission="readonly">
          <ToastContainer autoClose={3000} />
          <div>
            <form className={'form-inline'} onSubmit={this.handleSubmit.bind(this)}>
              <Row>
                <h3>Upload Contents Excel</h3>
              </Row>
              <br/>

              {
                allErrors.length > 0 ?
                    <div className={'content-v2-excel-upload-errors-container'}>
                      <Row>
                        <button
                          type={'button'}
                          onClick={this.resetView.bind(this)}
                          className="btn btn-primary">
                          {'Start a new Upload'}
                          &nbsp;<span className="glyphicon glyphicon-upload" aria-hidden="true"/>
                        </button>
                      </Row>
                      <br/>
                      <Row>
                        <Col>
                          <h4>{'Excel file contains following errors. Please fix them and try again :'}</h4>
                        <br/>
                          <JSONPretty
                          id="content-v2-excel-upload-error-list"
                          data={JSON.stringify(allErrors)}
                          theme={JSONPrettyMon}
                          />
                        </Col>
                      </Row>
                    </div>
                :
                    <Fragment>
                      <Row>
                        <Col>
                          <div style={{'margin': '15px'}} className="form-group">
                            <Alert>
                              <strong style={{'fontSize': '20px'}}>Important Note (Please read before you upload):</strong>
                              <br/>
                              <Collapse
                                  accordion={true}
                                  className={'upload-note-collapse'}
                                  expandIcon={expandIcon}
                              >
                                <Panel
                                    header="Content Upload Guide"
                                    headerClass="excel-upload-note-header"
                                    extra={<span>Click to see/hide Details</span>}
                                >
                                  <ol>
                                    <li>
                                      All fields that are marked with&nbsp;
                                      <strong><u>required</u></strong> should be available when uploading the file.
                                    </li>
                                    <li>
                                      By adding the <strong><u>Content Id</u></strong>, it will&nbsp;
                                      <strong><u>replace</u></strong> the relevant content
                                      values in the database with the values in the relevant excel
                                      row if the matching content found in the database, if not it will create a
                                      new enrty with the given content id with values in the excel.
                                    </li>
                                    <li>
                                      Updating content, it will <strong><u>replace</u></strong> values in the database with the given values
                                      in the excel file row and this is a <strong><u>irreversible process</u></strong>, Hence please <u>double
                                      check all the data</u> in the excel before you upload the excel.
                                    </li>
                                    <li>
                                      It is very important <strong><u>not to change</u></strong> the &nbsp;
                                      <strong><u>Language Code</u></strong>&nbsp;
                                      when you are updating contents(i.e if you have added a <strong><u>Content Id</u></strong>)
                                    </li>
                                    <li>
                                      If you want to update the hashtags, make sure that you add &nbsp;
                                      <strong><u>all</u></strong> the <strong><u>new hashtags</u></strong> and the&nbsp;
                                      <strong><u>old hashtags</u></strong>(only the ones you want to keep). Because, it&nbsp;
                                      will <strong><u>replace all the existing hashtags</u></strong> with the added hashtags
                                      in the excel file row.
                                    </li>
                                    <li>
                                      For the hashtags, Don't add the <strong>#</strong> symbol. It should be a comma separated
                                      string array. (Refer the Sample Excel file.)
                                    </li>
                                    <li>
                                      If you want to update the audiences, make sure that you add &nbsp;
                                      <strong><u>all</u></strong> the <strong><u>new audiences</u></strong> and the&nbsp;
                                      <strong><u>old audiences</u></strong>(only the ones you want to keep). Because, it&nbsp;
                                      will <strong><u>replace all the existing audiences</u></strong> with the added audiences
                                      in the excel file row.
                                    </li>
                                    <li>
                                      If you want to update the countries, make sure that you add &nbsp;
                                      <strong><u>all</u></strong> the <strong><u>new countries</u></strong> and the&nbsp;
                                      <strong><u>old countries</u></strong>(only the ones you want to keep). Because, it&nbsp;
                                      will <strong><u>replace all the existing countries</u></strong> with the added countries
                                      in the excel file row.
                                    </li>
                                    <li>
                                      By default all the contents you upload from the excel will be available for the users to see,
                                      unless you mark them <strong><u>Hidden</u></strong> as <strong><u>True</u></strong> to
                                      hide the contents.
                                    </li>
                                    <li>
                                      If you are marking any of the content as <u>Pinned</u> or <u>Featured</u>&nbsp;
                                      you need to add relevant <strong><u>start and end dates</u></strong> for them.
                                    </li>
                                    <li>
                                      Specially when you are uploading <strong><u>quote</u></strong>&nbsp;
                                      type contents, you need to make sure that&nbsp;
                                      <strong><u>title</u></strong> and the <strong><u>image file name</u></strong>&nbsp;
                                      should be the same and follow the existing standard. (i.e `daily_inspiration_366`)
                                    </li>
                                    <li>
                                      For all the <strong><u>quote</u></strong> type contents, you need to provide the&nbsp;
                                      <strong><u>For Ramadan</u></strong> status. Other content types
                                      you can keep the cell value as blank.
                                    </li>
                                    <li>
                                      For the <strong><u>quote</u></strong> type contents, for the&nbsp;
                                      <strong><u>Ramadan cards</u></strong> you <strong><u>need</u></strong>&nbsp;
                                      to provide a <strong><u>Publish Date</u></strong>.&nbsp;
                                      And for the <strong><u>Normal cards</u></strong> you &nbsp;
                                      <strong><u>don't need</u></strong> to provide a&nbsp;
                                      <strong><u>Publish Date</u></strong>.
                                    </li>
                                    <li>
                                      If you provide a thumbnail url it will replace the existing thumbnail url for the exisiting contents.
                                      For the <strong><u>quote</u></strong> type contents don't put the thumbnail url
                                      unless you want to replace the existing thumbnail image.
                                    </li>
                                    <li>
                                      For <strong><u>image</u></strong> content type it is <strong><u>mandatory</u></strong>&nbsp;
                                      to have a thumbnail url if you are creating the content for the first time.
                                      If you are updating the existing content then you can keep blank.
                                    </li>
                                    <li>
                                      For the <strong><u>video</u></strong> and <strong><u>article</u></strong> content types, values
                                      for the <u>thumbnail_url, title, and source</u> will be automatically inserted based on the
                                      URLs you have provided in the excel, but if you provide those values in the excel file row,
                                      then those will not be automatically inserted based on the URLs.
                                    </li>
                                    <li>
                                      For the all the other content types&nbsp;
                                      <strong><u>except the quote type contents</u></strong> you can either
                                      give a <strong><u>Publish Date</u></strong> or you can keep it blank, but if you keep it blank
                                      then the <strong><u>Publish Date</u></strong> will be set to <u>date of file upload(i.e Current date.)</u>
                                    </li>
                                    <li>
                                      You can only upload <u>one file at a time</u> and it should be in&nbsp;
                                      <strong><u>Microsoft Excel Type </u></strong>(i.e [your_file_name].xlsx)
                                    </li>
                                  </ol>
                                </Panel>
                              </Collapse>
                              <label>See the Sample Excel Sheet : </label>
                              {/*<a href={SampleQuotesImagesExcelFile} download="SampleQuotesImages.xlsx">Click to download the sample excel sheet.</a>*/}
                              <a href={sampleExcelFileUrl} target="_blank"> Click to see the sample excel sheet.</a>
                            </Alert>
                          </div>
                        </Col>
                      </Row>
                      <br/>
                      <MultiFileUploader
                          files={files}
                          showPreviews={files.length > 0}
                          onRemoveFile={this.removeFile.bind(this)}
                          showRemoveAllButton={false}
                          multiple={false}
                          onRemoveAllFiles={this.removeAllFiles.bind(this)}
                          onDropFiles={this.onDrop.bind(this)}
                          dropFileMainText={'Click here to upload or Drag and drop the Excel Sheet.'}
                      />
                      <div className="form-group">
                        {this.renderSubmitButton(uploading, files.length)}
                      </div>
                    </Fragment>
              }
            </form>
          </div>
        </BasicLayout>
    );
  }
}

export default UploadContentsExcel;
