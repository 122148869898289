import _ from 'lodash';
import moment from 'moment';
import XRegExp from 'xregexp';
import {
  CONTENT_TYPE,
  FEATURED_TYPE,
  AdPermittedURLs,
  CONTENT_BASE_YEAR,
  ContentSupportedLanguages,
} from './Constants';

// More details about the library : https://www.regular-expressions.info/unicode.html
const HASHTAG_REGEX = XRegExp('^[\\p{L}\\p{Bengali}]+[0-9]*$');

const getYearAndDayCodes = (date) => {
  const yearCode = date.getFullYear();
  const today = moment(date);
  // NOTE : Leap years should have the 366th content item.
  const dayCode = today.dayOfYear(); // This gives days since the first day of the year.

  return {
    dayCode,
    yearCode,
  }
};

const getDataFromMeta = (metaKey, el) => {
  const metaName = el.attr('name');
  const metaProp = el.attr('property');
  if(metaName === `og:${metaKey}`
      || metaName === `twitter:${metaKey}`
      || metaProp === `og:${metaKey}`
      || metaProp === `twitter:${metaKey}`) {
    return el.attr('content');
  }
};

// Will be fired only if the url is youtubes
const getVideoId = (url) => {
  const re=/[/=]([a-zA-Z0-9\-_]{11})[&/]?/;
  const found = url.match(re);
  if(found != null && found.length === 2) {
    return found[1];
  } else {
    return null;
  }
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

const shouldShowAds = (content) => {
  if(_.isEmpty(content['urls']) || content['content_type'] !== CONTENT_TYPE.ARTICLE ) {
    return false;
  }

  let url = content['urls'][0];
  let domain, found = false, url_temp = url.split('//');
  if(url_temp.length === 1) {
    domain = (url_temp[0].split('/'))[0];
  } else {
    domain = (url_temp[1].split('/'))[0];
  }

  for(let i=0; i < AdPermittedURLs.length; i++) {
    if(domain.endsWith(AdPermittedURLs[i])) {
      found = true;
      break;
    }
  }

  return found;
};

const getRandomNumber = () => {
  return Math.floor(Math.random()*10) + 1;
};

const isValidHashTag = (hashTag) => {
  return hashTag && HASHTAG_REGEX.test(hashTag)
};

const isBoolean = val => 'boolean' === typeof val;

const getDisplayDate = (date, format='MMM DD, YYYY hh:mm A') => {
  return date ? moment(date._seconds*1000).format(format) : 'N/A';
};

const getSortConfig = (sortBy = []) => {
  const sortConfig = [];

  if(_.isEmpty(sortBy)) {
    sortConfig.push({ key : 'publish_date', desc:  true, isDefault: true });
  } else {
    sortBy.forEach( ({ id, desc }) => {
      switch (id) {
        case 'publishedDate':
          sortConfig.push({ key : 'publish_date', desc:  desc, isDefault: false });
          break;
        case 'updatedDate':
          sortConfig.push({ key : 'updated_date', desc:  desc, isDefault: false });
          break;
        case 'createdDate':
          sortConfig.push({ key : 'created_date', desc:  desc, isDefault: false });
          break;
        case 'featuredStartDate':
          sortConfig.push({ key : 'featured_start_date', desc:  desc, isDefault: false });
          break;
        case 'featuredEndDate':
          sortConfig.push({ key : 'featured_end_date', desc:  desc, isDefault: false });
          break;
        case 'pinnedStartDate':
          sortConfig.push({ key : 'pinned_start_date', desc:  desc, isDefault: false });
          break;
        case 'pinnedEndDate':
          sortConfig.push({ key : 'pinned_end_date', desc:  desc, isDefault: false });
          break;
        case 'languageCode':
          sortConfig.push({ key : 'language_code', desc:  desc, isDefault: false });
          break;
        case 'seriesId':
          sortConfig.push({ key : 'series_id', desc:  desc, isDefault: false });
          break;
        case 'typeIcon':
          sortConfig.push({ key : 'content_type', desc:  desc, isDefault: false });
          break;
        case 'yearCode':
          sortConfig.push({ key : 'year_code', desc:  desc, isDefault: false });
          break;
        case 'featuredCategory':
          sortConfig.push({ key : 'featured_category', desc:  desc, isDefault: false });
          break;
        default:
          break;
      }
    });
  }

  return sortConfig;
};

const getFilterConfig = (filters = []) => {
  const filterConfig = [];

  if(!_.isEmpty(filters)) {
    filters.forEach( ({ id, value }) => {
      switch (id) {
        case 'title':
          filterConfig.push({ key : 'title', value });
          break;
        case 'source':
          filterConfig.push({ key : 'source', value });
          break;
        case 'seriesId':
          filterConfig.push({ key : 'series_id', value });
          break;
        case 'typeIcon':
          filterConfig.push({ key : 'content_type', value });
          break;
        case 'languageCode':
          filterConfig.push({ key : 'language_code', value });
          break;
        case 'createdDate':
          filterConfig.push({ key : 'created_date', value });
          break;
        case 'updatedDate':
          filterConfig.push({ key : 'updated_date', value });
          break;
        case 'featured':
          filterConfig.push({ key : 'is_featured', value: 'true' === value.toLowerCase() });
          break;
        case 'featuredStartDate':
          filterConfig.push({ key : 'featured_start_date', value });
          break;
        case 'featuredEndDate':
          filterConfig.push({ key : 'featured_end_date', value });
          break;
        case 'pinned':
          filterConfig.push({ key : 'is_pinned', value: 'true' === value.toLowerCase() });
          break;
        case 'pinnedStartDate':
          filterConfig.push({ key : 'pinned_start_date', value });
          break;
        case 'pinnedEndDate':
          filterConfig.push({ key : 'pinned_end_date', value });
          break;
        case 'hidden':
          filterConfig.push({ key : 'is_hidden', value: 'true' === value.toLowerCase() });
          break;
        case 'forRamadan':
          filterConfig.push({ key : 'for_ramadan', value: 'true' === value.toLowerCase() });
          break;
        case 'yearCode':
          filterConfig.push({ key : 'year_code', value });
          break;
        case 'featuredCategory':
          filterConfig.push({ key : 'featured_category', value });
          break;
        case 'publishedDate':
          filterConfig.push({ key : 'publish_date', value });
          break;
        case 'contentId':
          filterConfig.push({ key : 'id', value });
          break;
        default:
          // Do nothing.
          break;
      }
    });
  }

  return filterConfig;
};


const getSelectOptions = (optionType) => {
  switch (optionType) {
    case 'typeIcon':
      return Object.values(CONTENT_TYPE);
    case 'featuredCategory':
      return Object.values(FEATURED_TYPE);
    case 'languageCode':
      return _.without(Object.keys(ContentSupportedLanguages), 'all');
    case 'yearCode':
      return _.range(CONTENT_BASE_YEAR, new Date().getFullYear() + 1);
    case 'featured':
    case 'pinned':
    case 'hidden':
    case 'forRamadan':
      return ['True', 'False'];
    default:
      return [];
  }
};


const getLastItemId = ({
                         isNextResult, sendLastItem, originalData,
                         pageIndexData, pageIndex,
                       }) => {

  if(originalData && sendLastItem) {
    const dataLength = originalData.length;
    return isNextResult
        ? (originalData[dataLength-1] && originalData[dataLength-1].id)
        : (pageIndexData[pageIndex] && pageIndexData[pageIndex]['data'][0]  && pageIndexData[pageIndex]['data'][0].id)
  }

  return null
};

const getExcelDate = (date, format='MM/DD/YYYY HH:mm:ss') => {
  return date ? moment(date._seconds*1000).format(format) : null;
};

const getDownloadableContents = (selectedFlatRows, contents) => {
  const selectedContentIds =
      _.map(selectedFlatRows, selectedRow => selectedRow.original.dataItemId);
  const selectedContents =
      _.filter(contents, content => selectedContentIds.includes(content.id));

  const downloadableContents = _.map(selectedContents, content => {

    const downloadableContent = {
      ...content,
      article_date: getExcelDate(content.article_date),
      created_date: getExcelDate(content.created_date),
      publish_date: getExcelDate(content.created_date),
      featured_start_date: getExcelDate(content.featured_start_date),
      featured_end_date: getExcelDate(content.featured_end_date),
      pinned_start_date: getExcelDate(content.pinned_start_date),
      pinned_end_date: getExcelDate(content.pinned_end_date),
      hash_tags: content.hash_tags.join(','),
      audiences: content.audiences.join(','),
      countries: content.countries.join(','),
      urls: content.urls.join(','),
    };

    delete downloadableContent['day_code'];
    delete downloadableContent['year_code'];
    delete downloadableContent['random_number'];

    return _.pickBy(downloadableContent, (v) => !(_.isNil(v) || v === ''));
  })

  return downloadableContents;
};

const getDownloadableHashTags  = (selectedFlatRows) => {
  return _.map(selectedFlatRows, selectedRow => {
    return {
      hashTag: selectedRow.original.hashTag,
      languageCode: selectedRow.original.languageCode,
      contentIds: selectedRow.original.contentIds,
    }
  });
};

const getDeletableContents = (selectedFlatRows, contents) => {
  const selectedContentIds =
      _.map(selectedFlatRows, selectedRow => selectedRow.original.dataItemId);
  const selectedContents =
      _.filter(contents, content => selectedContentIds.includes(content.id));

  return selectedContents;
};

const getDeleteHashtags = (selectedRows) => {
  return selectedRows.map(row => {
    const { contentIds, hashTag } = row.original;

    return {
      contentIds: contentIds.split(', '),
      hashTags: [hashTag],
    }
  });
};

const getBlockedHashtags = (rtdbTrendingHashtagsData) => {
  if(_.isEmpty(rtdbTrendingHashtagsData)) {
    return [];
  }

  const hashtagsData = rtdbTrendingHashtagsData.data
    && rtdbTrendingHashtagsData.data.blocked;

  if(!hashtagsData) {
    return [];
  }

  const hashtags = _.transform(hashtagsData, function(result, value, tag) {
    result.push({ hashtag: tag, ...value });
  }, []);

  return hashtags;
};

const getCustomHashtags = (rtdbTrendingHashtagsData) => {
  if(_.isEmpty(rtdbTrendingHashtagsData)) {
    return [];
  }

  const hashtagsData = rtdbTrendingHashtagsData.data
    && rtdbTrendingHashtagsData.data.custom;

  if(!hashtagsData) {
    return [];
  }

  const hashtags = _.transform(hashtagsData, function(result, value, tag) {
    result.push({ hashtag: tag, ...value });
  }, []);

  return _.sortBy(hashtags, 'priority');
};

const getReorderedCustomHashtags = (customHashtags=[], hashtag, direction) => {
  const currentIndex = _.findIndex(customHashtags, ['hashtag', hashtag]);

  if(currentIndex > -1) {
    const nextIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
    customHashtags.splice(nextIndex, 0,
      customHashtags.splice(currentIndex, 1)[0]);
  }

  return _.map(customHashtags, (customTag, index) => ({ ...customTag, priority: index + 1 }));
};

const getRemovedHashtags = (hashtags, hashtagToRemove) => {
  const currentIndex = _.findIndex(hashtags, ['hashtag', hashtagToRemove]);

  if(currentIndex > -1) {
    _.pullAt(hashtags, currentIndex);
  }

  return _.map(hashtags, (hashtag, index) => ({ ...hashtag, priority: index + 1 }));
};

const hasHashtagsDataChanged = (currentHashtags, rtdbHashtags) => {
  const diffBy1 = _.differenceBy(currentHashtags, rtdbHashtags);
  const diffBy2 = _.differenceBy(rtdbHashtags, currentHashtags);

  return diffBy1.length > 0 || diffBy2.length > 0;
};

const getUpdatableHashtags = (hashtags) => {
  return _.reduce(hashtags, (hashtagsMap, hashtag) => {
    hashtagsMap[hashtag['hashtag']] = _.omit(hashtag, 'hashtag');
    return hashtagsMap;
  }, {});
};

const getHashtagColor = (source, defaultColor) => {
  if(source === 'bq') {

    return 'rgb(230 199 154)';
  }

  if(source === 'custom') {

    return 'rgb(154 202 230)';
  }

  if(source === 'custom_block') {

    return 'rgb(230 154 154)';
  }

  return defaultColor;
};

const isHashtagAlreadyExisting = (hashtags, hashtag) => {
  if(_.isEmpty(hashtag)) {
    return false;
  }

  return _.findIndex(hashtags, ['hashtag', hashtag.hashtag]) > -1;
};

export {
  getYearAndDayCodes,
  getDataFromMeta,
  getVideoId,
  scrollToTop,
  shouldShowAds,
  getRandomNumber,
  isValidHashTag,
  isBoolean,
  getDisplayDate,
  getSortConfig,
  getLastItemId,
  getFilterConfig,
  getSelectOptions,
  getDownloadableContents,
  getDownloadableHashTags,
  getDeletableContents,
  getDeleteHashtags,
  getBlockedHashtags,
  getCustomHashtags,
  getReorderedCustomHashtags,
  getRemovedHashtags,
  hasHashtagsDataChanged,
  getUpdatableHashtags,
  getHashtagColor,
  isHashtagAlreadyExisting,
}
