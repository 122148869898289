import _ from "lodash";
import { CopyItem } from "../../common";

export const getAdUnitDisplayData = (items) => {
  if (_.isEmpty(items)) {
    return [];
  }

  return _.map(items, (item) => {
    return {
      id: <CopyItem text={_.get(item, "id", "")} />,
      name: _.get(item, "name", ""),
      ad_space: _.get(item, "ad_space", ""),
      title: _.get(item, "title", ""),
      android_ad_unit_id: _.get(item, "android_ad_unit_id", ""),
      ios_ad_unit_id: _.get(item, "ios_ad_unit_id"),
      web_ad_unit_id: _.get(item, "web_ad_unit_id"),
    };
  });
};
