import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  updateContentV2TrendingHashtagsApiLimit,
} from '../../services/rtdb-service';

const updateContentV2TrendingHashtagsByLanguage = () => {
  const [ trendingHashtagsApiLimitData, setTrendingHashtagsApiLimitData ] = useState({ });
  const [ updateData, setUpdateData ] = useState(null);
  const {
    apiLimit,
    onOperationComplete,
  } = updateData || {};

  const updateTrendingHashtagsApiLimit = async () => {
    try {
      console.log(`Updating trending hashtags API limit to : ${apiLimit}`);
      setTrendingHashtagsApiLimitData({
        isUpdating: true,
        isError: false,
      });

      const trendingHashtagsApiResp =  await updateContentV2TrendingHashtagsApiLimit({
        apiLimit,
      });
      console.log(`Updating trending hashtags API limit from RTDB is Success!!`);

      let errorMsg = null;

      setTrendingHashtagsApiLimitData({
        data: trendingHashtagsApiResp,
        isUpdating: false,
        isError: false,
        errorMsg,
      });

      if(onOperationComplete) {
        onOperationComplete({
          data: trendingHashtagsApiResp,
          isError: false,
          errorMsg,
        });
      }
    } catch (err) {
      console.log(`Error while fetching trending hashtags  API limit from RTDB Error : ${err}`);
      const errorMsg = `Error occurred while fetching hashtags API limit from RTDB.`;
      setTrendingHashtagsApiLimitData({
        data: null,
        isUpdating: false,
        isError: true,
        errorMsg,
      });
      if(onOperationComplete) {
        onOperationComplete({
          data: null,
          isError: true,
          errorMsg,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(updateData)) {
      updateTrendingHashtagsApiLimit();
    }
  }, [ updateData ]);

  return [
    trendingHashtagsApiLimitData,
    setUpdateData,
  ];
};

export default updateContentV2TrendingHashtagsByLanguage;
