import React,
{
  Component,
} from 'react';
import BasicLayout from '../../layouts/basiclayout';
import './style.css';
import {
  Row,
  Col,
  Button,
  FormControl,
  ControlLabel,
} from 'react-bootstrap';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import firebase from 'firebase/app';
import {
  getTypeBySKU,
} from '../../services/utils';
import {
  Icon,
} from 'react-fa';
import {
  getTransactionEntrySnapshotWithCallback,
} from '../../repositories/transactionRepository';
import {
  getUser,
} from '../../services/mp-cf-api-service';
import {
  md5,
} from '../../utils';
import AdminUserService from "../../service_v2/users/admin-user-service";
import PremiumService from "../../service_v2/premium/premium-service";
import ShardManager from "../../service_v2/users/shard-manager";
const _ = require('lodash')

const INTERVAL_CHECK_DURATION = 3000;

class TransferPremium extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: '',
      to: '',
      fuid: '',
      tuid: '',
      logs: [],
      fromUserFBObj: null,
      toUserFBObj: null,
      fromUserShardUrl: null,
      toUserShardUrl: null,
      showVerifying: false,
      verified: null,
      premiumPurchaseKey: '',
      premiumPurchase: null,
      pKeyInsideTransToBeRemoved: null,
      transferClicked: false,
      transactionUseridKey: '',
      toRenewalDate: '',
      transferStatus: [null, null, null, null, null, null],
      showTransferStatus: false,
      transferVerificationAttempts: 0,
      transferVerificationIntervalId: null,
      transferSuccessful: false,
    };
    this.DB = firebase.database();
  }

  getFirebaseUser(identifier) {
    return getUser(identifier)
      .then((res) => {
        if(res && res.data && res.data.uid) {
          return Promise.resolve(res.data);
        }
        return Promise.resolve(null);
      })
      .catch((err) => {
        return Promise.resolve(null);
      });
  }

  componentWillMount() {
    if(this.props.location.search !== "") {
      const search = this.props.location.search.substring(1);
      const queryParams = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
      const { from, to } = queryParams;
      this.setState({
        from,
        to
      });
    }
  }

  handleInputChange(key, e) {
    this.setState({
      [key]: e.target.value
    });
  }

  handleTransferSubmit(e) {
    e.preventDefault();
    const { from, to } = this.state;
    // *** Check if any user identifier is not empty
    if(!from || !to || !from.trim() || !to.trim()) {
      toast.error('User cannot be undefined or empty', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
    // *** PROCEED transfer as user identifiers are not empty
    else {
      // Start transfer logic from here
      this.setState({
        logs: [],
        fuid: '',
        tuid: '',
        showVerifying: true,
        verified: false,
        premiumPurchaseKey: '',
        premiumPurchase: null,
        pKeyInsideTransToBeRemoved: null,
        transferClicked: false,
        transactionUseridKey: null
      });

      // HERE ANY PROMISE FAIL SHOULD BREAK THE PROMISES
      PremiumService.verifyTransferPremium(from, to).then( ( data ) => {
        console.log('response', data)
        _.get(data, "payload.logs", []).map( (_log) => {
          this.addToLogs(_log.type, _log.message, _log.source);
        })
        this.setState({
          premiumPurchase: _.get(data, 'payload.premiumPurchase', null),
          fromUserFBObj: _.get(data, "payload.fromUserInfo", {}),
          toUserFBObj: _.get(data, "payload.toUserInfo", {}),
          verified: data.success,
          showVerifying: false,
          fuid: _.get(data, "payload.fromUserInfo.uid", {}),
          tuid: _.get(data, "payload.toUserInfo.uid", {})
        });
      }).catch( error => {
        console.log('error', error)
        this.addToLogs('danger',`Failed`, {showVerifying: false});

      })
    }
  }

  // checkForTransferComplete(fuid, tuid){
  //   this.setState( (prev) => {
  //     return {
  //       transferVerificationAttempts: prev.transferVerificationAttempts + 1
  //     }
  //   })
  //
  //   // check if transaction userid is created for new user.
  //   // check if new user has premium
  //   // check if old user no longer have premium
  //   this.getFirebaseUser(this.state.fuid)
  //     .then( (fromFBUser) => {
  //       if( fromFBUser ){
  //         return AdminUserService.queryUserData(fuid, { path: '/'})
  //       }else{
  //         return Promise.reject({
  //           error: `from: FBUser not found ${fuid}`
  //         })
  //       }
  //     })
  //     .then( (user) => {
  //       if( !user ){
  //         return Promise.reject({
  //           error: `from: MPUser not found ${fuid}`
  //         })
  //       }
  //       return this.getFirebaseUser(tuid);
  //     })
  //     .then( (toFBUser) => {
  //       if( toFBUser ){
  //         return AdminUserService.queryUserData(tuid, { path: '/'})
  //       }else{
  //         return Promise.reject({
  //           error: `to: FBUser not found ${tuid}`
  //         })
  //       }
  //     })
  //     .then( (user) => {
  //       if( !user ){
  //         return Promise.reject({
  //           error: `to: MPUser not found ${fuid}`
  //         })
  //       }
  //       if(user.premium_status && user.premium_status.premium && user.premium_status.premium !== "none"){
  //         return Promise.resolve();
  //       } else {
  //         return Promise.reject({
  //           err: `ToUser ${this.state.to} still do not have premium.`,
  //         })
  //       }
  //     })
  //     .then( () => {
  //       let {transferStatus} = this.state; transferStatus[2] = true;
  //       this.addToLogs('success', `Transfer Premium VERIFIED`, '', '');
  //
  //       console.log('interval cleared')
  //       clearInterval( this.state.transferVerificationIntervalId );
  //
  //     })
  //     .catch( ( error ) => {
  //       console.log('verification tick [error]', error)
  //     })
  //
  //   if( this.state.transferVerificationAttempts > 10 ) {
  //     console.log('interval cleared')
  //     clearInterval( this.state.transferVerificationIntervalId )
  //     // fail
  //     let {transferStatus} = this.state; transferStatus[2] = undefined;
  //     this.addToLogs('danger', `Transfer Premium VERIFICATION failed`, '', '');
  //   }
  //
  // }

  addToLogs(type, text, target, additional) {
    let {logs} = this.state;
    logs.push({type, text, target});
    this.setState({logs, ...additional});
  }

  renderLogs() {
    const { logs } = this.state;
    let logsEl = logs.slice(0).reverse().map((item, i) => {
      return (
        <div className={"small alert alert-"+item.type+' '+item.target} key={i}>
          <code>{item.target}</code>{item.text}
        </div>
      );
    });
    return (
      <div>
        {(logs.length > 0) ? <ControlLabel>Logs</ControlLabel> : null}
        {logsEl}
      </div>
    );
  }

  renderShowTransferring() {
    const { showVerifying, verified } = this.state;
    if(showVerifying && !verified) {
      return <p className="small"><Icon spin name="spinner"/> Verifying transfer eligibility...</p>;
    } else if(!showVerifying && verified) {
      return <p className="small text-success"><Icon name="check-circle"/> Verification successful</p>;
    }
    else if(!showVerifying && verified === false) {
      return <p className="small text-danger"><Icon name="exclamation-circle"/> Verification failed</p>;
    }
    return null;
  }

  renderLinkToFirebaseDBUser(uid, userShardUrl) {
    console.log('userShardUrl', userShardUrl)
    if(uid.trim() !== '') {
      return (
        <div className="small userlink__cont">
          <a href={userShardUrl} target="_blank">Open on firebase DB</a>
          <br />
          <a href={"/search-user/"+uid} target="_blank">Open within admin console</a>
        </div>
      );
    }
    return null;
  }

  onTransferClick() {
    const { from, to, fuid, tuid } = this.state;

    const confirmTransfer = window.confirm(`Do you want to transfer premium from user ${from}/[[${fuid}]] to user ${to}/ [[${tuid}]]?`);
    if( confirmTransfer ) {
      this.setState({
        showTransferStatus: true,
        transferClicked: true
      });
      let {transferStatus} = this.state; transferStatus[0] = true;
      this.addToLogs('success', `Transfer Premium initiated`, '', '');


      PremiumService.transferPremium(fuid, tuid).then( (response) => {
        transferStatus[1] = true;
        this.addToLogs('success', `Transfer Premium Completed`, '', '');
          this.setState({
            transferSuccessful: true,
          })
        return Promise.resolve();
      })
      //     .then( () => {
      //   console.log(`transfer check timer started. ${INTERVAL_CHECK_DURATION}`)
      //   this.addToLogs('success', `Transfer Premium Verification started`, '', '');
      //   const intervalId = setInterval( this.checkForTransferComplete.bind(this, fuid, tuid), INTERVAL_CHECK_DURATION );
      //   this.setState({
      //     transferVerificationIntervalId: intervalId
      //   })
      // })
        .catch( error => {
        console.log('error', error)
        transferStatus[1] = undefined;
        transferStatus[2] = 'na';
        this.addToLogs('danger', `Transfer failed: ${error}`, 'To', `${tuid}`);
        this.setState({
          verified: false,
          showVerifying: false
        });

      })

    }
  }

  renderButtons() {
    return (
      <div>
        <Button type="submit" className="transfer__button btn-primary">Verify</Button>
      </div>
    );
  }

  onTransferCancel() {
    const { from, to } = this.state;
    window.location = `/transfer-premium?from=${from}&to=${to}`;
  }

  renderConfirmationSection() {
    const { verified, fromUserFBObj, transferSuccessful, toUserFBObj, fromUserShardUrl, toUserShardUrl, premiumPurchase, transferClicked, fuid, tuid, transferStatus } = this.state;
    if(verified && !transferClicked) {
      return (
        <Row className="confirmation__section">
          <Col className="confirmation__section_header" md={12}>
            <Icon name="exclamation-circle" /> Confirm premium transfer
          </Col>
          <Col md={6}>
            <strong>FROM</strong><br/>
            <p className="small">The user who will lose his/her premium status</p>
            {this.renderLinkToFirebaseDBUser(fuid, fromUserShardUrl)}
            <strong>User ID</strong><br/>{fromUserFBObj.uid} <br/>
            <strong>Name </strong><br/>{fromUserFBObj.displayName || '-'} <br/>
            <strong>Email</strong><br/>{fromUserFBObj.email || '-'} <br/>
            <strong>Phone</strong><br/>{fromUserFBObj.phoneNumber || '-'} <br/>
            <strong>Premium type</strong><br/>{getTypeBySKU(premiumPurchase.purchaseObj.sku)}
            <a className="small" href={this.state.fromUserShardUrl+"/premium_status/purchases/"+premiumPurchase.purchaseKey} target="_blank"> Link to purchase</a>

          </Col>
          <Col md={6}>
            <strong>TO</strong><br/>
            <p className="small">The user who will become a premium user after transfer</p>
            {this.renderLinkToFirebaseDBUser(tuid, toUserShardUrl)}
            <strong>User ID</strong><br/>{toUserFBObj.uid} <br/>
            <strong>Name</strong><br/>{toUserFBObj.displayName || '-'} <br/>
            <strong>Email</strong><br/>{toUserFBObj.email || '-'} <br/>
            <strong>Phone</strong><br/>{toUserFBObj.phoneNumber || '-'} <br/>
            <strong>Premium type</strong><br/>Not premium
          </Col>
          <Col md={12}>
            <Button onClick={this.onTransferClick.bind(this)} className="transfer__button btn-danger">Confirm transfer</Button>
            <Button onClick={this.onTransferCancel.bind(this)} className="transfer__button btn-default">Cancel</Button>
          </Col>
        </Row>
      );
    } else if(verified && transferClicked) {
      if(this.checkTransferStatus(transferStatus)) {
        return (
          <div className="alert alert-success final-success">
            Transfer successful. <a href="/transfer-premium">Perform new transfer</a>
          </div>
        );
      } else {
        return !transferSuccessful ? (
          <div className="alert alert-warning final-success">
            Transfer processing...
          </div>
        ) : (
            <div className="alert alert-success final-success">
              Transfer successful!
            </div>
        )
      }
    } else {
      return null;
    }
  }

  checkTransferStatus(transferStatus) {
    let sts = true;
    for(let i=0; i<transferStatus.length; i++) {
      if(transferStatus[i] === null || transferStatus[i] === false) {
        sts = false;
        break;
      }
    }
    return sts;
  }

  renderForm() {
    const { verified } = this.state;
    if(verified) {
      return null;
    }
    return (
      <Col className="transfer__premium" md={5}>
        <form onSubmit={this.handleTransferSubmit.bind(this)}>
          <ControlLabel>Transfer premium from</ControlLabel>
          <FormControl value={this.state.from} placeholder="Enter users email, phone, uid" onChange={this.handleInputChange.bind(this, 'from')}/>
          <ControlLabel className="to__col">Transfer premium to</ControlLabel>
          <FormControl value={this.state.to} placeholder="Enter users email, phone, uid" onChange={this.handleInputChange.bind(this, 'to')}/>
          {this.renderButtons()}
        </form>
      </Col>
    );
  }

  renderTransferStatus() {
    const { transferStatus, showTransferStatus } = this.state;
    if(!showTransferStatus) {
      return null;
    }

    return (
      <div className="transfer__sts">
        <strong>Transfer status</strong>
        <ul>
          <li>Transfer Premium Initiated {this.renderStatusIcon(transferStatus[0])}</li>
          <li>Transfer Premium Completed {this.renderStatusIcon(transferStatus[1])}</li>
          {/*<li>Transfer Premium Verification {this.renderStatusIcon(transferStatus[2])}</li>*/}
        </ul>
      </div>
    );
  }

  renderStatusIcon(sts) {
    if(sts === null) {
      return <Icon spin name="spinner" />;
    } else if(sts === true) {
      return <Icon name="check-circle" />;
    } else if(sts === 'na') {
      return <span>N/A</span>;
    } else {
      return <Icon name="exclamation-circle" />;
    }
  }

  render() {
    return (
      <BasicLayout pagePermission="write_users">
        <ToastContainer autoClose={5000} />
        <Row>
          {this.renderForm()}
          <Col className="transfer__premium" md={8}>
            {this.renderConfirmationSection()}
            {this.renderTransferStatus()}
            {this.renderShowTransferring()}
          </Col>
          <Col className="transfer__premium" md={8}>
            <div className="alert__cont">{this.renderLogs()}</div>
          </Col>
        </Row>
      </BasicLayout>
    );
  }
}

export default TransferPremium;
