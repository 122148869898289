import _ from 'lodash';
import React,
{
  Fragment,
  useState,
} from 'react';
import {
  toast,
} from 'react-toastify';
import {
  confirmAlert,
} from "react-confirm-alert";
import AppModal from '../modal';
import {
  Col,
  Row,
  Alert,
  Button,
} from 'react-bootstrap';
import JSONPretty from 'react-json-pretty';
import JSONPrettyMon from 'react-json-pretty/dist/adventure_time';
import MultiFileUploader from '../multiFileUploader';
import {
  gcsImageUploadHook,
} from '../../hooks';
import {
  getUploadedFileDetails,
} from './helper';
import {
  GCS_IMAGE_UPLOAD_LIMIT,
} from '../../Constants';
import {
  getCdnPublicFileUrl,
} from '../../utils';
import {
  MPExpandIcon,
  MPRangeInput,
  MpMultiCheckBox,
} from '../common';
import './style.css';
import Collapse,
{
  Panel,
} from 'rc-collapse';

const ImageUploadDefaultInfoPanel = () => {

  return (
    <Row>
      <Col>
        <Alert>
          <strong style={{'fontSize': '20px'}}>
            Important Note (Please read before you upload):
          </strong>
          <br/>
          <Collapse
            accordion={true}
            className={'upload-note-collapse'}
            expandIcon={MPExpandIcon}
          >
            <Panel
              header="Image Upload Guide"
              headerClass="excel-upload-note-header"
              extra={<span>Click to see/hide Details</span>}
            >
              <ol>
                <li>
                  <p>Please note you can only upload <strong><u> maximum of 30</u></strong> images to the Google Cloud Storage at a single upload.</p>
                </li>
                <li>
                  <p>Also note that if you upload the same name file it will <strong><u> replace the existing</u></strong> image in the Google Cloud Storage and it is <strong><u> irreversible.</u></strong></p>
                </li>
                <li>
                  <p>When you replace images make sure to tick the  <strong>Clear CDN Cache</strong> tick, which clear the CDN cache and it will take up to 10 minutes to take effect.</p>
                </li>
                <li>
                  <p>If you are uploading the unoptimized images then please tick the Optimize Images section to optimize the images.
                    <strong><u>Please be careful</u></strong> when using this feature, because if you tick for already optimized images
                    it will caused to produce very weak images after the upload.
                  </p>
                </li>
              </ol>
            </Panel>
          </Collapse>
        </Alert>
      </Col>
    </Row>
  );
};

const MPGCSUploadImages = ({
                         InfoPanel=ImageUploadDefaultInfoPanel,
                         getUploadableFiles,
                         bucketName=null,
                         project=null,
                         disableUploadButton=false,
                         fileUpdateSuccessMessage='Images uploaded successfully!!`',
                         onUploadSuccess=() => console.log('Image upload was success.'),
                         onUploadFailed=() => console.log('Image upload was failed.'),
                         acceptTypes = 'image/jpg , image/jpeg',
                         enableOptimizeImages = true,
                       }) => {
  const InitialState = {
    files: [],
    clearCdnCache: false,
    optimizeImages: enableOptimizeImages,
    resizeImages: false,
    resizeImageSizes: null,
  };
  const [ state, setState ] = useState(InitialState);
  const [ uploadData, doUpload ] = gcsImageUploadHook();
  const [ isRefreshed, setIsRefreshed ] = useState(false);

  const isUploading = uploadData && uploadData.isUploading;
  const uploadedFiles = uploadData && uploadData.data;

  const resetState = () => {
    setState(InitialState);
  };

  const onDropFiles = droppedFiles => {
    const uploadedFiles= droppedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }));

    setState({
      ...state,
      files: uploadedFiles,
    });
  };

  const onRemoveFile = file =>  {
    const newFiles = [...state.files];
    newFiles.splice(newFiles.indexOf(file), 1);

    setState({
      ...state,
      files: newFiles,
    });
  };

  const onRemoveAllFiles = () =>  {
    setState({
      ...state,
      files: [],
    });
  };

  const onOperationComplete = operationData => {
    if(!operationData.isError) {
      toast.success(fileUpdateSuccessMessage, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      resetState();
      onUploadSuccess(operationData);
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      onUploadFailed();
    }
  };

  const uploadImages = async () => {
    const {
      files,
      resizeImages,
      clearCdnCache,
      optimizeImages,
      resizeImageSizes,
    } = state;
    const {
      uploadableFiles,
    } = await getUploadableFiles(files);

    doUpload({
      files: uploadableFiles,
      clearCdnCache,
      optimizeImages,
      resizeImages,
      resizeImageSizes,
      bucketName,
      project,
      onOperationComplete,
    });
  };

  const onClickUpload = () => {
    setIsRefreshed(false);

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <AppModal
                show={true}
                headingText={'Are you sure want to upload the following images ?'}
                showBody={false}
                closeButtonText={'No'}
                okButtonText={'Yes, Upload'}
                handleClose={onClose}
                handleOk={ async () => {
                    onClose();
                    await uploadImages();
                  }
                }
            />
        );
      }
    });
  }

  const copyUrls = uploadedFiles => {
    const urlArray = _.map(uploadedFiles, file => getCdnPublicFileUrl(file['public_url']));
    const copyString = urlArray.join('\r\n');
    const textArea = document.createElement('textarea');

    // Store string in innerHTML of textArea element
    textArea.innerHTML = copyString;

    // Find an id element within the body to append the textArea there temporarily
    const parentElement = document.getElementById('gcs-image-upload-url-list');
    parentElement.appendChild(textArea);

    // Simulate selection of the text from textArea programmatically
    textArea.select();

    // Simulate copy command (ctrl+c)
    // Now the string with newlines should be copied to the clipboard
    document.execCommand('copy');

    // Get rid of your fluffy textarea element
    parentElement.removeChild(textArea);
  }

  const {
    files,
  } = state;

  const checkBoxes = [
    {
      id: 'clearCdnCache',
      name: 'Clear CDN Cache',
      initialValue: state.clearCdnCache,
    },
    {
      id: 'optimizeImages',
      name: 'Optimize Images',
      initialValue: state.optimizeImages,
    },
    {
      id: 'resizeImages',
      name: 'Resize Images',
      initialValue: state.resizeImages,
    },
  ];

  const onUpdateCheckBoxValues = values => {
    setState({
      ...state,
      ...values,
    });
  };

  const onStartNewUpload = () => {
    // window.location.reload();
    setIsRefreshed(true);
  };

  const {
    resizeImages,
    resizeImageSizes,
  } = state;

  const onChangeRange = changedValues => {
    const {
      firstValue,
      secondValue,
    } = changedValues;
    setState({
      ...state,
      resizeImageSizes: [ parseInt(firstValue, 10), parseInt(secondValue, 10) ],
    });
  };

  const disableUploadBtn = disableUploadButton
      || isUploading
      || !files.length
      || !(resizeImages
          ? (resizeImageSizes
              && _.isArray(resizeImageSizes)
              && _.filter(resizeImageSizes, _.isLength).length === 2)
          : true);

  return (
      <Fragment>
        {
          !_.isEmpty(uploadedFiles) && !isRefreshed ?
              <div style={{'marginLeft': '15px', 'marginRight': '15px'}}>
                <Row>
                  <Button
                      type={'button'}
                      onClick={onStartNewUpload}
                      className="btn btn-primary">
                    {'Start a new Upload'} &nbsp;
                    <span className="glyphicon glyphicon-upload" aria-hidden="true"/>
                  </Button>
                </Row>
                <br/>
                <Row>
                  <Col>
                    <h4>{'File Urls :'}</h4>
                    <br/>
                    <div>
                      <Button
                          type={'button'}
                          onClick={() => copyUrls(uploadedFiles)}
                          className="btn btn-primary">
                        {'Copy CDN Urls'}
                        &nbsp;<span className="glyphicon glyphicon-copy" aria-hidden="true"/>
                      </Button>
                    </div>
                    <JSONPretty
                        id="gcs-image-upload-url-list"
                        data={JSON.stringify(getUploadedFileDetails(uploadedFiles))}
                        theme={JSONPrettyMon}
                    />
                  </Col>
                </Row>
              </div>
              :
              <div className={'image-upload-main'}>
                { InfoPanel && <InfoPanel/> }
                <br/>
                <Row className={'image-upload-tools-main'}>
                  <Col md={6}>
                    <div className='form-group'>
                      <MpMultiCheckBox
                          checkBoxes={checkBoxes}
                          onUpdateValues={onUpdateCheckBoxValues}
                          direction={'horizontal'}
                      />
                    </div>
                    {
                      resizeImages &&
                      <MPRangeInput
                          className={'image-upload-tools-resize-range'}
                          type={'number'}
                          firstValueLabelText={'Width'}
                          firstValuePlaceHolder={'Width'}
                          secondValueLabelText={'Height'}
                          secondValuePlaceHolder={'Height'}
                          onChangeRange={onChangeRange}
                          showLabels={true}
                      />
                    }
                  </Col>
                </Row>
                <MultiFileUploader
                    files={files}
                    showPreviews={files.length > 0}
                    onRemoveFile={onRemoveFile}
                    showRemoveAllButton={true}
                    acceptTypes={acceptTypes}
                    multiple={true}
                    showUploadButton={true}
                    onClickUpload={onClickUpload}
                    disableUploadButton={disableUploadBtn}
                    isUploading={isUploading}
                    uploadingText={'Please wait! Uploading Images...'}
                    onRemoveAllFiles={onRemoveAllFiles}
                    onDropFiles={onDropFiles}
                    dropFileMainText={'Click here to upload or Drag and drop the Images.'}
                    maxFilesCount={GCS_IMAGE_UPLOAD_LIMIT}
                    maxFileReachedErrorMsg={`Maximum number of files you can upload at a single upload is ${GCS_IMAGE_UPLOAD_LIMIT}.`}
                />
              </div>
        }
      </Fragment>
  );
};

export {
  MPGCSUploadImages,
};
