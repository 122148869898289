import React from 'react';
import _ from 'lodash';
import {
  HEADERS_WITH_SUNRISE,
  HEADERS_WITHOUT_SUNRISE
} from './Constants';
import {
  getDisplayDate,
} from '../../utils';


const LastUpdatedTime = ({
                           lastUpdatedTimeStamp,
                         }) => {
  if(!lastUpdatedTimeStamp) {
    return null
  }

  return (
      <p>
        {'Last updated time : '}&nbsp;
        <span className={'last-update-time'}>
          {getDisplayDate(lastUpdatedTimeStamp)}
        </span>
      </p>
  );
};

const PrecalcTimePannel = ({
                             timesArray,
                           }) => {
  if(_.isEmpty(timesArray)) {
    return null;
  }

  const selectedTable =  timesArray.length === HEADERS_WITH_SUNRISE.length
      ? HEADERS_WITH_SUNRISE : HEADERS_WITHOUT_SUNRISE;

  return (
      <table className='table table-hover precalc-selected-time-table'>
        <thead className={'precalc-selected-time-table-header'}>
          <tr>
            {
              selectedTable.map((time, i) =>
                  <td className={'precalc-selected-time-table-header-cell'} key={i}>{time}</td>
              )
            }
          </tr>
        </thead>
        <tbody className={'precalc-selected-time-table-body'}>
          <tr>
            {
              timesArray.map((time, i) =>
                  <td className={'precalc-selected-time-table-data-cell'} key={i}>{time}</td>
              )
            }
          </tr>
        </tbody>
      </table>
  );
};

export {
  LastUpdatedTime,
  PrecalcTimePannel,
}
