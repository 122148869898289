import _ from 'lodash';
import React,
{
  useState,
} from 'react';
import {
  toast,
} from 'react-toastify';
import readXlsxFile from 'read-excel-file';
import {
  confirmAlert,
} from "react-confirm-alert";
import AppModal from '../modal';
import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import JSONPretty from 'react-json-pretty';
import JSONPrettyMon from 'react-json-pretty/dist/adventure_time';
import MultiFileUploader from '../multiFileUploader';
import {
  OperationStatus,
} from '../common';
import './style.css';

const ShowFormattedData = ({
                             data,
                             operation = 'error',
                             resetState,
                             getCopyItems,
                           }) => {
  if (_.isEmpty(data)) {
    return null;
  }
  const isErrorOperation  = operation === 'error';

  const copyData = () => {
    const copyItems = _.isFunction(getCopyItems) ? getCopyItems(data) : JSON.stringify(data);
    console.log(copyItems);
    navigator.clipboard.writeText(copyItems);
  };

  const operationTitle = isErrorOperation
    ? 'Excel file contains following errors. Please fix them and try again :'
    : 'Please copy and paste the following IDs into the excel for future updates :';

  return (
    <div className={`excel-upload-${operation}-container`}>
      <Row>
        <Button
          type={'button'}
          onClick={resetState}
          className="btn btn-primary">
          {'Start a new Upload'} &nbsp;
          <span
            className="glyphicon glyphicon-upload"
            aria-hidden="true"
          />
        </Button>
      </Row>
      <br/>
      <Row>
        <Col>
          <h4>
            {operationTitle}
          </h4>
          <div>
            <button
              type={'button'}
              onClick={copyData}
              className={`btn btn-sm btn-${isErrorOperation ? 'danger' : 'success'}`}>
              {`Copy ${isErrorOperation ? 'Errors' : 'Data'}`}
              &nbsp;<span className="glyphicon glyphicon-copy" aria-hidden="true"/>
            </button>
          </div>
          <JSONPretty
            id="excel-upload-data-list"
            data={JSON.stringify(data)}
            theme={JSONPrettyMon}
          />
        </Col>
      </Row>
    </div>
  );
};

const MPUploadExcel = ({
                         getItems,
                         getCopySuccessItems = (data) => JSON.stringify(data),
                         fileUpdateHook,
                         fileUpdateHookConfig = {},
                         InfoPanel=null,
                         fileUpdateSuccessMessage='`Files updated successfully!!`',
                         onUploadSuccess=() => console.log('Excel upload was success.'),
                         onUploadFailed=() => console.log('Excel upload was failed.'),
                         showResultOnSuccess= false,
                         sheetName
                       }) => {

  const InitialState = {
    allErrors: [],
    files: [],
    validating: false,
    showResultSuccessData: false,
  };
  const [ state, setState ] = useState(InitialState);
  const [ createUpdateData, doCreateOrUpdate ] = fileUpdateHook();
  const isUploading = createUpdateData && createUpdateData.isUpdating;
  const resultData = createUpdateData && createUpdateData.data;
  
  const resetState = (additionalState = {}) => {
    setState({
      ...InitialState,
      ...additionalState,
    });
  };

  const onDropFiles = (droppedFiles) => {
    const uploadedFiles= droppedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }));

    setState({
      ...state,
      files: uploadedFiles,
    });
  };

  const onRemoveFile = (file) =>  {
    const newFiles = [...state.files];
    newFiles.splice(newFiles.indexOf(file), 1);

    setState({
      ...state,
      files: newFiles,
    });
  };

  const onOperationComplete = operationData => {
    if(!operationData.isError) {
      toast.success(fileUpdateSuccessMessage, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      resetState({
        showResultSuccessData: true,
      });
      onUploadSuccess();
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      onUploadFailed();
    }
  };

  const uploadExcel = async (sheetName = null) => {
    setState({
      ...state,
      validating: true,
    });

    try {
      const {
        files,
      } = state;
      let rows;
      if( sheetName != null ) {
        rows = await readXlsxFile(files[0], { sheet: sheetName} );
      }else{
        rows = await readXlsxFile(files[0] );
      }
      console.log("SheetName", sheetName,"ROWS", rows)
      const {
        items,
        allErrors,
      } = await getItems(rows);

      if(allErrors.length > 0) {
        setState({
          ...state,
          allErrors,
          validating: false,
        });
      } else {
        setState({
          ...state,
          validating: false,
        });
        doCreateOrUpdate({
          items,
          onOperationComplete,
          ...fileUpdateHookConfig,
        });
      }
    } catch (err) {
      console.log('Something went wrong while validation the excel sheet data. Error ', err);
      setState({
        ...state,
        validating: false,
      });
      toast.error('Something went wrong while validation the excel sheet data.', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

 

  const onClickUpload = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <AppModal
                show={true}
                headingText={'Are you sure want to upload the following excel ?'}
                showBody={false}
                closeButtonText={'No'}
                okButtonText={'Yes, Upload'}
                handleClose={onClose}
                handleOk={ async () => {
                    onClose();
                    await uploadExcel(sheetName);
                  }
                }
            />
        );
      }
    });
  };

  const {
    files,
    allErrors,
    validating,
    showResultSuccessData,
  } = state;

  const isErrorResult = _.size(allErrors) > 0;
  const showResult = isErrorResult || (showResultSuccessData && showResultOnSuccess);

  return (
      <div className={'excel-upload-main'}>
        {
          InfoPanel &&
          <InfoPanel/>
          
        }
        <br/>
        {
          validating
            ? <OperationStatus operationInProgressText={'Validating excel sheet data..'} />
            :
            showResult
              ?
                <ShowFormattedData
                  data={isErrorResult ? allErrors : resultData}
                  operation={isErrorResult ? 'error' : 'success'}
                  resetState={resetState}
                  getCopyItems={isErrorResult ? undefined : getCopySuccessItems}
                />
              :
              <MultiFileUploader
                files={files}
                showPreviews={files.length > 0}
                onRemoveFile={onRemoveFile}
                showRemoveAllButton={false}
                multiple={false}
                showUploadButton={true}
                onClickUpload={onClickUpload}
                disableUploadButton={isUploading || !files.length}
                isUploading={isUploading}
                uploadingText={'Please wait! Uploading Excel...'}
                onRemoveAllFiles={() => console.log('')}
                onDropFiles={onDropFiles}
                dropFileMainText={'Click here to upload or Drag and drop the Excel Sheet.'}
              />
        }
      </div>
  );
};

export {
  MPUploadExcel,
};
