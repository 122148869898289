import React from 'react';
import Select from 'react-select';

const QalboxMultiSelectDropdown = ({ name, options, selectedOptions, onChange }) => {
  const handleChange = option => {
    onChange(name, option);
  };

  const MultiValueLabel = ({ children }) => (
    <div style={{ display: 'inline-block', background: '#f0f0f0', padding: '2px 6px', borderRadius: '4px', margin: '2px' }}>
      {children}
    </div>
  );

  return (
    <Select
      isMulti
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      components={{ MultiValueLabel }}
      placeholder="Select Options..."
      isSearchable
    />
  );
};

export default QalboxMultiSelectDropdown;