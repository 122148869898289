import {
  getContentMetaData,
} from '../mp-cf-api-service';

const getDataFromMeta = (metaKey, el) => {
  const metaName = el.attr('name');
  const metaProp = el.attr('property');
  if(metaName === `og:${metaKey}`
    || metaName === `twitter:${metaKey}`
    || metaProp === `og:${metaKey}`
    || metaProp === `twitter:${metaKey}`) {
    return el.attr('content');
  }
};

const getVideoId = (url) => {
  const re=/[/=]([a-zA-Z0-9\-_]{11})[&/]?/;
  const found = url.match(re);
  if(found != null && found.length === 2) {
    return found[1];
  } else {
    return null;
  }
};

const getMetaData = async (url) => {
  const metaData = {};

  // analysing the url for youtube
  let url_temp = url;
  let domain;
  url_temp = url_temp.split('//');
  if (url_temp.length === 1) {
    //no protocol - no http
    url = 'http://' + url;
    domain = (url_temp[0].split('/'))[0];
  } else {
    domain = (url_temp[1].split('/'))[0];
  }

  if(domain.endsWith('youtube.com') || domain.endsWith('youtu.be')) {
    let video_id = getVideoId(url);
    if (video_id != null) {
      url = 'https://youtube.com/watch?v=' + video_id;
      metaData.video_id = video_id;
    }
  }

  const metaDataResp = await getContentMetaData({ url });
  const htmlString = metaDataResp.data;
  const jQ = window.jQuery;
  const crawledHTML = jQ.parseHTML(htmlString);
  let contentThumbnail = null, contentTitle = null,
    contentDescription = null, contentSource = null;

  crawledHTML.forEach((token) => {
    if(jQ(token).is('meta')) {
      if(!contentThumbnail){
        contentThumbnail = getDataFromMeta('image', jQ(token));
      }

      if(!contentTitle){
        contentTitle = getDataFromMeta('title', jQ(token));
      }

      if(!contentDescription){
        contentDescription = getDataFromMeta('description', jQ(token));
      }

      if(!contentSource){
        contentSource = getDataFromMeta('site_name', jQ(token));
      }
    }
  });

  metaData.url = url;
  metaData.thumbnailUrl = contentThumbnail
    || `https://i.ytimg.com/vi/${metaData.video_id}/hqdefault.jpg`;
  metaData.title = contentTitle;
  metaData.summary = contentDescription;
  metaData.source = contentSource;

  return metaData;
};

export {
  getMetaData,
}

