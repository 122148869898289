import _ from "lodash";
import { useState, useEffect } from "react";
import { qalBoxSyncElastic } from "../../services/mp-cf-api-service";

const syncElastic = () => {
  const [responseData, setResponseData] = useState({});
  const [updateData, setUpdateData] = useState(null);
  const { items, onOperationComplete } = updateData || {};

  const syncMediaItems = async () => {
    try {
      console.log(`QalBox add or update media times...`);
      setResponseData({
        isUpdating: true,
        isError: false,
      });

      const mediaItemsSyncApiResp = await qalBoxSyncElastic({
        media: items,
        language_code: _.get(items, "[0].language"),
      });
      const responseData = mediaItemsSyncApiResp.data;

      let errorMsg = null;
      let isError = false;

      if (responseData.success) {
        console.log("QalBox media items add/update Success!!");
      } else {
        console.log("QalBox media items add/update Failed!!");
        errorMsg = `Failed to add/update QalBox media items.`;
        isError = true;
      }

      setResponseData({
        isUpdating: false,
        isError,
        errorMsg,
        data: responseData.payload,
      });

      if (onOperationComplete) {
        onOperationComplete({
          isError,
          errorMsg,
          data: responseData.payload,
        });
      }
    } catch (err) {
      console.log(
        "Something went wrong while add/update QalBox media items. ",
        err
      );
      const errorMsg = `Error occurred while add/update QalBox media items.`;
      setResponseData({
        isUpdating: false,
        isError: true,
        errorMsg,
        data: null,
      });
      if (onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
          data: null,
        });
      }
    }
  };

  useEffect(() => {
    if (!_.isEmpty(updateData)) {
      syncMediaItems();
    }
  }, [updateData]);

  return [responseData, setUpdateData];
};

export default syncElastic;
