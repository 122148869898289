import _ from "lodash";
import React, { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import BasicLayout from "../../../layouts/basiclayout";
import { ScaleLoader } from "react-spinners";
import QalboxTable from "../../../widgets/qalbox-table";
import { getDeletableShortMedia, getQalboxShortColumns, getSearchMediaDisplayData } from "../helper";
import { ToastContainer } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import "./style.css";
import AppModal from "../../../widgets/modal";
import { CreateShortModal } from "./modalScreens/createShortModal";
import { qalboxUseDebounce } from "../../../hooks";
import { MPInput } from "../../../widgets/common";
import { confirmAlert } from "react-confirm-alert";
import { useFetchShorts } from "./hooks/useFetchShort";
import { useDeleteShort } from "./hooks/useDeleteShort";
import { useProdSyncShort } from "./hooks/useProdSyncShort";

export const QalboxShorts = () => {
  const [refresh, setRefresh] = useState(false);
  const [showCreateShortModal, setShowCreateShortModal] = useState(false);
  const [title, setTitle] = useState('');
  const debouncedSearchValue = qalboxUseDebounce(title, 1000);
  const [shortsResponse, isLoading, isError] = useFetchShorts(debouncedSearchValue, refresh);
  const [deleteShort, isDeleting] = useDeleteShort();
  const [prodSyncShorts, isLoadingProdSync] = useProdSyncShort();
  const [pageIndex, setPageIndex] = useState(0);
  const [shortContentRow, setShortContentRow] = useState(null);

  const onCreateNewQalboxShort = () => {
    setShowCreateShortModal(true);
  };

  const onEditClicked = (selectedFlatRows, resetSelectedRows) => {
    const uuid = _.get(selectedFlatRows, '[0].values.uuid.props.text', null);
    const shortMediaItem = shortsResponse.find(item => item.uuid === uuid);
    setShortContentRow(shortMediaItem);
    setShowCreateShortModal(true);
    resetSelectedRows();
  }

  const onDeleteClicked = (selectedFlatRows, resetSelectedRows) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            headingText={`Are you sure want to delete the following ${_.size(
              selectedFlatRows
            )} media short items ?`}
            modalBody={getDeletableShortMedia(selectedFlatRows)}
            modalBodyClassName={"precalcs-diff-merge-view"}
            closeButtonText={"No"}
            okButtonText={"Yes, Delete"}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              const uuidListToDelete = _.map(selectedFlatRows, "values.uuid.props.text");
              _.forEach(uuidListToDelete, uuid => {
                deleteShort(uuid, refreshTable);
              });
              
              resetSelectedRows();
            }}
          />
        );
      },
    });
  };

  const onProdSyncClicked = (selectedFlatRows, resetSelectedRows) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            headingText={`Are you sure want to sync to production the following ${_.size(
              selectedFlatRows
            )} media short items ?`}
            modalBody={getDeletableShortMedia(selectedFlatRows)}
            modalBodyClassName={"precalcs-diff-merge-view"}
            closeButtonText={"No"}
            okButtonText={"Yes, Sync to Production"}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              const uuidListToSync = _.map(selectedFlatRows, "values.uuid.props.text");
              const shortsToSync =  _.filter(shortsResponse, item => _.includes(uuidListToSync, item.uuid));
              handleOnProdSync(shortsToSync, resetSelectedRows);
            }}
          />
        );
      },
    });
  };

  const handleOnProdSync = (shorts, resetSelectedRows) => {
    prodSyncShorts(shorts, resetSelectedRows);
  };

  const handleUpdates = () => {
    setShowCreateShortModal(false);
    refreshTable();
  }

  const refreshTable = () => {
    setRefresh(prev => !prev);
  };

  return (
    <LoadingOverlay
      active={isLoading || isDeleting}
      spinner={<ScaleLoader />}
      text="Loading...."
    >
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={2000} />
        <Row>
          <Col md={9}>
            <h3 className="content_editor_title">{"Qalbox Shorts"}</h3>
          </Col>
          {process.env.REACT_APP_IS_PROD_SYNC_AVAILABLE && (
            <Col md={3} className="qalbox-short-top-col">
              <button
                className="qalbox-short-create-btn"
                onClick={onCreateNewQalboxShort}
              >
                Create
              </button>
            </Col>
          )}
        </Row>
        <div className="form-group">
          <MPInput
            value={title}
            placeHolder={"Title"}
            onChangeValue={(e) => {
              setTitle(e);
              setPageIndex(0);
            }}
          />
        </div>
        <QalboxTable
          table={{
            data: getSearchMediaDisplayData(shortsResponse),
            columns: getQalboxShortColumns(),
            isLoading: isLoading,
            tableError: isError,
            isGlobalSearchOn: false,
            parentPageIndex: pageIndex,
            setPageIndex: setPageIndex,
            handleEdit: onEditClicked,
            handleDelete: onDeleteClicked,
            handleProdSync: process.env.REACT_APP_IS_PROD_SYNC_AVAILABLE
              ? onProdSyncClicked
              : null,
          }}
        />
        <AppModal
          show={showCreateShortModal}
          showBody={true}
          headingText={`Create Short`}
          modalBody={
            <CreateShortModal
              data={shortContentRow}
              handleUpdates={handleUpdates}
            />
          }
          modalBodyClassName={""}
          closeButtonText={""}
          okButtonText={""}
          handleClose={() => {
            setShowCreateShortModal(false);
            setShortContentRow(null);
          }}
        />
      </BasicLayout>
    </LoadingOverlay>
  );
};
