import _ from 'lodash';
import React, { useState } from 'react';
import { Alert, Button, Col, Row, Table } from 'react-bootstrap';
import './style.css';
import { MPExpandIcon, MPInput } from '../../widgets/common';
import {
  getNextElementsAdded, getUpdatedQuranQuotesList, isXDaysOrMore, getValidationStartDay,
  isDataUpdated, isDataValid, getUpdatableQuranQuotes, getDeletedQuranQuotesList,
} from './helper';
import { DELETETABLE_ROWS_AFTER } from './Constants';
import { confirmAlert } from "react-confirm-alert";
import AppModal from '../../widgets/modal';
import JSONDiffView from '../../widgets/json-diff-view';
import { toast } from 'react-toastify';
import Collapse, { Panel } from 'rc-collapse';

const getUpdatedFileDiff = (updatedData, initialData) => {

  return (
    <JSONDiffView
      leftSideData={initialData}
      rightSideData={updatedData}
      messageIfBothSideIdentical={'No change found!'}
    />
  );
};

const QuranQuote = ({ quranQuote, onChangeData, firstDate, onDelete }) => {
  const [ date, suraVerse ] = _.toPairs(quranQuote)[0];
  const [ sura, verse ] = _.toPairs(suraVerse)[0];
  const dataValid = sura && verse;
  const validationStartDay = getValidationStartDay(firstDate);
  const deletableDate = isXDaysOrMore({
    dateString: date,
    startDay: validationStartDay,
    xDays: DELETETABLE_ROWS_AFTER,
  });

  return (
    <tr key={date} className={`${dataValid ? '' : 'quran-quote-row-error'} ${deletableDate ? 'quran-quote-row-deletable' : ''}`}>
      <td>{date}</td>
      <td>
        <MPInput
          type={'number'}
          value={sura}
          onChangeValue={value => onChangeData({ date, sura: value, verse })}
          min={1}
        />
      </td>
      <td>
        <MPInput
          type={'number'}
          value={verse}
          onChangeValue={value => onChangeData({ date, sura, verse: value })}
          min={1}
        />
      </td>
      <td className={'text-center'}>
        {
          date === firstDate ?
            <button
              onClick={() => onDelete({ date })}
              className='margin-all3 btn btn-danger btn-xs'
            >
              <span className={'glyphicon glyphicon-trash'} aria-hidden='true'/>
              &nbsp;Delete
            </button> : 'N/A'
        }
      </td>
    </tr>
  );
};

const QuranQuotesList = ({ quranQuotes=[], doUpdate }) => {
  if(_.isEmpty(quranQuotes)) {
    return (
      <div>
        <p>Verse of the days are empty!</p>
      </div>
    );
  }
  const [state, setState] = useState({
    initialQuranQuotes: [...quranQuotes],
    quranQuotes: [...quranQuotes],
    addNext: '',
  });
  const firstDate = _.keys(state.quranQuotes[0])[0];
  const dataUpdated =  isDataUpdated(state.quranQuotes, state.initialQuranQuotes);
  const dataValid =  isDataValid(state.quranQuotes);
  const disableUpdateBtn = !dataUpdated || !dataValid;

  const onChangeData = changedData => {
    const updatedQuranQuotes = getUpdatedQuranQuotesList(state.quranQuotes, changedData);
    setState({
      ...state,
      quranQuotes: updatedQuranQuotes,
    });
  };

  const onDelete = deleteData => {
    const deletedQuranQuotes = getDeletedQuranQuotesList(state.quranQuotes, deleteData);
    setState({
      ...state,
      quranQuotes: deletedQuranQuotes,
    });
  };

  const quoteRows = state.quranQuotes.map((quranQuote, index) => {

    return (
      <QuranQuote
        key={index}
        quranQuote={quranQuote}
        onChangeData={onChangeData}
        firstDate={firstDate}
        onDelete={onDelete}
      />
    );
  });

  const onUpdateComplete = operationData => {
    if(!operationData.isError) {
      toast.success(`Updating Quoran Quotes Success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onAddNew = () => {
    setState({
      ...state,
      quranQuotes: getNextElementsAdded(state.quranQuotes, state.addNext),
      addNext: '',
    });
  }

  const onReset = () => {
    setState({
      ...state,
      quranQuotes: [...state.initialQuranQuotes],
    });
  }

  const onUpdate = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            headingText={`Are you sure want to update the quran quotes?`}
            modalBody={getUpdatedFileDiff(state.quranQuotes, state.initialQuranQuotes)}
            modalBodyClassName={'precalcs-diff-merge-view'}
            closeButtonText={'No'}
            okButtonText={'Yes, Update'}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              doUpdate({
                items: getUpdatableQuranQuotes(state.quranQuotes, firstDate),
                onOperationComplete: onUpdateComplete,
              });
            }}
          />
        );
      }
    });
  }

  const onChangeNext = (value) => {
    setState({
      ...state,
      addNext: Number(value),
    });
  }

  return (
    <div className={'quran-quotes-table-main'}>
      <div  className={'quran-quotes-table-main-reference'}>
        <div className={'quran-quotes-table-ref-color'}>
          <div className={'quran-quotes-table-ref-color-2'}></div>
          <span>Auto delete on Update</span>
        </div>
        <div className={'quran-quotes-table-ref-color'}>
          <div className={'quran-quotes-table-ref-color-1'}></div>
          <span>Invalid data</span>
        </div>
      </div>
      <div className={'quran-quotes-table-main-tools'}>
        <MPInput
          value={state.addNext}
          placeHolder={'Add New'}
          type={'number'}
          min={1}
          onChangeValue={onChangeNext}
        />
        <Button
          onClick={onAddNew}
          className={'btn btn-primary'}
          disabled={!state.addNext}
        >
          Add New
        </Button>
        <Button
          onClick={onReset}
          className={'btn btn-primary'}
          disabled={!dataUpdated}
        >
          Reset
        </Button>
        <Button
          onClick={onUpdate}
          className={'btn btn-primary'}
          disabled={disableUpdateBtn}
        >
          Update
        </Button>
      </div>
      <Table className={'quran-quotes-table'} striped bordered condensed hover responsive>
        <thead>
        <tr>
          <th>Date</th>
          <th>Sura</th>
          <th>Verse</th>
          <th>Operation</th>
        </tr>
        </thead>
        <tbody>
        {quoteRows}
        </tbody>
      </Table>
    </div>
  );
};


const ExcelUploadInfoPanel = () => {
  const sampleExcelFileUrl = 'https://docs.google.com/spreadsheets/d/1oEVt1X391X5CD63zTFvp4OEfsQPiRe_rHQZl5zz1CJs';

  return (
    <Row>
      <Col>
        <Alert>
          <strong style={{'fontSize': '20px'}}>
            Important Note (Please read before you upload):
          </strong>
          <br/>
          <Collapse
            accordion={true}
            className={'upload-note-collapse'}
            expandIcon={MPExpandIcon}
          >
            <Panel
              header="Content Upload Guide"
              headerClass="excel-upload-note-header"
              extra={<span>Click to see/hide Details</span>}
            >
              <ol>
                <li>
                  All the dates should be present&nbsp;
                  <strong><u>without gaps</u></strong>
                  between dates.
                </li>
                <li>
                  You can't keep empty dates without sura and verse.
                </li>
              </ol>
            </Panel>
          </Collapse>
          <label>See the Sample Excel Sheet : </label> &nbsp;
          <a href={sampleExcelFileUrl} target="_blank">
            Click to see the sample excel sheet.
          </a>
        </Alert>
      </Col>
    </Row>
  );
};

export {
  QuranQuotesList,
  ExcelUploadInfoPanel,
}