import React,
{
  Fragment,
  useEffect,
  useReducer,
} from 'react';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import {
  fetchHijriHook,
  updateHijriHook,
  updateSettingsFileTimestampsHook,
} from '../../hooks';
import {
  MPSelect,
  OperationStatus,
} from '../../widgets/common';
import {
  sightingReducer,
  SightingInitialState,
} from './Reducers';
import {
  actionTypes,
} from './actionTypes';
import {
  Sightings,
  onClickUpdate,
} from './common';
import {
  getSightSelectYears,
} from './helper';

const UpdateSightings = ({
                           title,
                           dataKey,
                         }) => {
  const [ state , dispatch ] = useReducer(sightingReducer, SightingInitialState);
  const {
    newYear,
    updated,
    yearAddError,
    initialHijri,
    updatedHijri,
  } = state;

  const [ settingsFileUpdateData, doUpdateSettingTs ] = updateSettingsFileTimestampsHook();
  const [ hijriFileData, doFetch ] = fetchHijriHook();
  const [ hijriFileUpdateData, doUpdate ] = updateHijriHook();

  const isFetching = hijriFileData && hijriFileData.isFetching;
  const isUpdating = hijriFileUpdateData && hijriFileUpdateData.isUpdating;
  const updatingTimeStamps = settingsFileUpdateData && settingsFileUpdateData.isUpdating;
  const updatingText = updatingTimeStamps ?
      'Updating timestamps...' : `Please wait! ${isUpdating ? 'Updating' : 'Fetching'} Hijri data...`;

  const onTimestampUpdateComplete = operationData => {
    if(!operationData.isError) {
      toast.success(`Timestamps update success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onHijriFileUpdateSuccess = () => {
    doUpdateSettingTs({
      fileName: 'settings_api_base',
      updateConfig: {
        'updateTimestamps': true,
        'timestampLocations': [
          'all>timestamp',
          'all>configurable_apis>hijri>timestamp',
        ],
      },
      onOperationComplete: onTimestampUpdateComplete,
    });
  };

  const onFetchComplete = operationData => {
    if(!operationData.isError) {
      toast.success(`Fetching Hijri Data Success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      dispatch({
        type: actionTypes.SIGHTING_UPDATE_DATA,
        payload: operationData.data,
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onUpdateComplete = operationData => {
    if(!operationData.isError) {
      toast.success(`Updating Hijri Data Success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      onHijriFileUpdateSuccess();
      if(operationData.data) {
        dispatch({
          type: actionTypes.SIGHTING_UPDATE_DATA,
          payload: operationData.data,
        });
      }
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onAddYear = () => {
    dispatch({
      type: actionTypes.SIGHTING_ADD_NEW_YEAR,
      payload: newYear,
    });
  };

  const resetState = () => {
    dispatch({
      type: actionTypes.SIGHTING_RESET_STATE,
      payload: dataKey,
    });
  };

  useEffect(() => {
    resetState();
    doFetch({
      onOperationComplete: onFetchComplete,
    });
  }, []);

  return (
      <Fragment>
        <ToastContainer autoClose={2000}/>
        <Row>
          <Col md={12}>
            <h3 className='content_editor_title'>{title}</h3>
          </Col>
        </Row>
        <form className={'form-inline'} onSubmit={e => e.preventDefault()}>
          <Row>
            <Col md={3}>
              <div
                  className={`form-group ${yearAddError && 'has-error'}`}
                  style={{'marginRight': '5px'}}>
                <label style={{'marginRight': '5px'}}>Year</label>
                <MPSelect
                    items={getSightSelectYears()}
                    selectedItem={newYear}
                    onChangeSelect={
                      (value) => dispatch({
                        type: actionTypes.SIGHTING_UPDATE_PROP_VAL,
                        payload: {
                          prop: 'newYear',
                          value,
                        },
                      })
                    }
                />
                <span className="help-block">{yearAddError}</span>
              </div>
            </Col>
            <Col md={3}>
              <Button
                  style={{'marginRight': '5px'}}
                  onClick={onAddYear}
                  disabled={!newYear}
                  className="btn-primary"
                  type={'button'}>
                {'Add'}
              </Button>
              {
                updated &&
                <Fragment>
                  <Button
                      style={{'marginRight': '5px'}}
                      onClick={resetState}
                      disabled={!updated}
                      className="btn-primary"
                      type={'button'}>
                    {'Reset'}
                  </Button>
                  <Button
                      onClick={
                        () => {
                          onClickUpdate({
                            updatedHijri,
                            initialHijri,
                            doUpdate,
                            onUpdateComplete,
                          });
                        }
                      }
                      disabled={!updated || isUpdating}
                      className="btn-primary"
                      type={'button'}>
                    {isUpdating ? 'Updating...' : 'Update'}
                  </Button>
                </Fragment>
              }
            </Col>
          </Row>
          {
            (isUpdating || isFetching || updatingTimeStamps) &&
            <OperationStatus
                operationInProgressText={updatingText}
            />
          }
          {
            !isFetching && !isUpdating && !updatingTimeStamps && updatedHijri &&
            <Row>
              <Col md={6}>
                <Sightings
                    onResetYear={
                      (resetData) => dispatch({
                        type: actionTypes.SIGHTING_ON_RESET_YEAR,
                        payload: resetData,
                      })
                    }
                    onDeleteYear={
                      (deleteData) => dispatch({
                        type: actionTypes.SIGHTING_ON_DELETE_YEAR,
                        payload: deleteData,
                      })
                    }
                    onToggleCountry={
                      (toggleData) => dispatch({
                        type: actionTypes.SIGHTING_ON_TOGGLE_COUNTRY,
                        payload: toggleData,
                      })
                    }
                    onAddNewCountry={
                      (addData) => dispatch({
                        type: actionTypes.SIGHTING_ON_ADD_COUNTRY,
                        payload: addData,
                      })
                    }
                    onDeleteCountry={
                      (deleteData) => dispatch({
                        type: actionTypes.SIGHTING_ON_DELETE_COUNTRY,
                        payload: deleteData,
                      })
                    }
                    sightingsData={updatedHijri['sightings'][dataKey]}
                />
              </Col>
            </Row>
          }
        </form>
      </Fragment>
  );
}

export default UpdateSightings;
