import React, { Component } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Icon } from 'react-fa';
import './style.css';

class Pagniation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 20,
      start: 1,
      end: 20
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    this.setState({value: this.props.selectedLimit});
  }

  handleChange(e) {
    this.setState({value: e.target.value});
    this.props.onDropdownChange(e.target.value);
  }

  onPagniate(action) {
    this.props.onPagniate(action);
  }

  render() {
    return (
      <Row className="pagniation">
        <Col className="text-right" md={6}>
          Request per page <select value={this.state.value} onChange={this.handleChange}>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="250">250</option>
            <option value="500">500</option>
          </select>
        </Col>
        <Col md={6}>
          <Button className="btn btn-default btn-xs" onClick={this.onPagniate.bind(this, 'prev')}><Icon name="caret-left" /> Previous</Button>
          {' '}
          <Button className="btn btn-default btn-xs" onClick={this.onPagniate.bind(this, 'next')}><Icon name="caret-right" /> Next</Button>
        </Col>
      </Row>
    );
  }
}

export default Pagniation;
