import {
  useState,
  useEffect,
} from 'react';
import {
  getRawPrecalcsList,
} from '../../services/mp-service-v1-api-service';

const fetchPrecalList = ({
                           shouldFetchPrecalcList = false,
                         }) => {
  const [ precalcList, setPrecalcList ] = useState(null);

  const fetchPrecalcList = async () => {
    console.log('Fetching PrecalcList...');
    const precalcListFetchApiResp =  await getRawPrecalcsList();

    const responseData = precalcListFetchApiResp.data;
    if(responseData.success) {
      console.log('Precalc List Fetch Success!!');
      setPrecalcList(responseData.data);
    } else {
      console.log('Precalc List Fetch Failed!!');
    }
  };

  useEffect( () => {
    if(shouldFetchPrecalcList) {
      fetchPrecalcList();
    }
  }, [ shouldFetchPrecalcList ]);

  return [
    precalcList,
  ];
};

export default fetchPrecalList;
