import _ from 'lodash';
import React,
{
  useState,
  useEffect,
} from 'react';
import './style.css'
import {
  Col,
  Table,
  Alert,
  Button,
  MenuItem,
  ButtonGroup,
  DropdownButton,
} from 'react-bootstrap';
import {
  getRowCount,
  getCheckBoxes,
  getDownloadData,
  getBQLogsUserData,
  getBQLogsDataCheckBoxes,
  getDownloadDataCheckBoxes,
} from './helper';
import {
  MpMultiCheckBox,
  OperationStatus,
} from '../../widgets/common';
import exportFromJSON from 'export-from-json';
import {
  getUserLogsHook,
} from '../../hooks';
import {
  toast,
} from 'react-toastify';
import {
  createLog,
} from '../../services/logs';

const UserLogsOperationStatusesList = ({
                                         dataKey='table_log_delete_statuses',
                                         userLogsStatusData,
                                       }) => {
  if(_.isEmpty(userLogsStatusData) ||
    _.isEmpty(userLogsStatusData['data']) ||
    _.isEmpty(userLogsStatusData['data'][dataKey])) {

    return null;
  }

  const tableLogStatuses = userLogsStatusData['data'][dataKey];
  const rows = _.map(tableLogStatuses, tableLogStatus => {
    const success = tableLogStatus['success'];
    const errorMsg = tableLogStatus['error_msg'] || '';
    const operationFailedStatus = errorMsg.includes('UPDATE or DELETE statement over table') ?
      'Failed, Please try again after 2 hours!' : 'Failed, Please try again!';

    return (
      <tr key={tableLogStatus['table_name']}>
        <td className={'user-log-delete-table-td'}>
          { tableLogStatus['table_name'] }
        </td>
        <td className={`user-log-delete-table-td user-log-delete-${success ? 'success': 'error'}`}>
          { success ? 'Success' : operationFailedStatus }
        </td>
        <td
          style={{ 'maxWidth': '200px' }}
          className={`user-log-delete-table-td user-log-delete-${success ? 'success': 'error'}`}>
          { errorMsg || 'N/A' }
        </td>
      </tr>
    );
  });

  return (
    <Col md={12} className={'delete-user-total-records'}>
      <Table striped responsive bordered condensed hover className={'user-log-delete-table'}>
        <thead>
        <tr>
          <th>Table Name</th>
          <th>Operation Status</th>
          <th>Error Message</th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    </Col>
  );
};

const UserLogsDetailsList = ({
                               onClickDelete,
                               onUpdateValues,
                               userLogsDeleteData,
                               shouldEnableDeleteBtn,
                               userLogsRowsCountData,
                             }) => {
  if(!_.isEmpty(userLogsDeleteData) &&
    !_.isEmpty(userLogsDeleteData.data)) {
    return null;
  }

  if(_.isEmpty(userLogsRowsCountData) ||
    _.isEmpty(userLogsRowsCountData.data)) {
    return null;
  }

  return (
    <Col md={12} className={'delete-user-total-records'}>
      <Alert bsStyle="danger">
        <h4>{`${getRowCount(userLogsRowsCountData)} Rows found in following tables:`}</h4>
        <div className='form-group'>
          <MpMultiCheckBox
            checkBoxes={getCheckBoxes(userLogsRowsCountData)}
            onUpdateValues={onUpdateValues}
          />
        </div>
        <UserLogsOperationStatusesList
          userLogsStatusData={userLogsRowsCountData}
          dataKey={'table_log_counts'}
        />
        <Button
          onClick={onClickDelete}
          disabled={!shouldEnableDeleteBtn}
          className="btn btn-danger btn-delete-user-logs">
          <span className={'glyphicon glyphicon-trash'} aria-hidden='true'/>
          &nbsp;{'Delete Logs'}
        </Button>
      </Alert>
    </Col>
  );
};

const DownloadUserData = ({ firebaseData, mpAccountData }) => {

  const [ userOriginalData, setUserOriginalData ] =
    useState(_.cloneDeep({ firebaseData, mpAccountData }));
  const [ userLogsData, doFetch ] = getUserLogsHook();
  const [ userData, setUserData ] = useState(_.cloneDeep({ firebaseData, mpAccountData }));
  const isFetchingUserBQData = userLogsData && userLogsData.isFetching;

  if(_.isEmpty(firebaseData) && _.isEmpty(mpAccountData)) {
    return 'No user data available to download.';
  }

  const onFetchCompleteBQLogs = (operationData) => {
    if(!operationData.isError) {
      toast.success(`Fetching BQ user logs Success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      if(operationData.data) {
        const bqLogsData = getBQLogsUserData(operationData.data);
        setUserData({
          ...userData,
          bqLogsData: {
            ...bqLogsData,
          },
        });
        setUserOriginalData({
          ...userOriginalData,
          bqLogsData: {
            ...bqLogsData,
          },
        });
      }
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  useEffect(() => {
    if(firebaseData.uid) {
      doFetch({
        requestData: {
          firebaseUID: firebaseData.uid,
        },
        onOperationComplete: onFetchCompleteBQLogs,
      });
    }
  }, []);

  const onUpdateValues = (values, mainKey) => {
    for (const key of _.keys(values)) {
      if(values[key]) {
        userData[mainKey][key] = userOriginalData[mainKey][key];
      } else {
        delete userData[mainKey][key]
      }
    }
    setUserData({ ...userData });
  };

  const firebaseDataCheckBoxes = (
    <div className='form-group'>
      <MpMultiCheckBox
        checkBoxes={getDownloadDataCheckBoxes(firebaseData)}
        onUpdateValues={(values) => onUpdateValues(values, 'firebaseData')}
      />
    </div>
  );

  const mpAccountDataCheckBoxes = (
    <div className='form-group'>
      <MpMultiCheckBox
        checkBoxes={getDownloadDataCheckBoxes(mpAccountData)}
        onUpdateValues={(values) => onUpdateValues(values, 'mpAccountData')}
      />
    </div>
  );

  const bqLogsDataCheckBoxes = (
    <div className='form-group'>
      <MpMultiCheckBox
        checkBoxes={getBQLogsDataCheckBoxes(userLogsData)}
        onUpdateValues={(values) => onUpdateValues(values, 'bqLogsData')}
      />
    </div>
  );

  const fileName = userData && userData.firebaseData && userData.firebaseData.uid ?
    `${userData.firebaseData.uid}_user_data` : 'user_data';

  const onDownloadSelected = (exportType) => {
    exportFromJSON({
      data: getDownloadData(exportType, userData),
      fileName,
      exportType,
    });
    createLog('download_user_data_success', {
      exportType,
      userData,
    });
  };

  const shouldDisableDownloadBtn = _.isEmpty(userData['firebaseData']) &&
    _.isEmpty(userData['mpAccountData']);

  return (
    <div className={'download-user-data-container'}>
      <div className={'download-user-data-view'}>
        <div>
          <h4>Select Firebase Account data:</h4>
          {firebaseDataCheckBoxes}
        </div>
        <div>
          <h4>Select MP Account data:</h4>
          {mpAccountDataCheckBoxes}
        </div>
        <div>
          <h4>Select BQ Logs data:</h4>
          {
            isFetchingUserBQData ?
              <OperationStatus
                operationInProgressText={`Loading User's BQ logs.`}
              /> :
              bqLogsDataCheckBoxes
          }
        </div>
      </div>
      <ButtonGroup className={'download-user-data-button'}>
        <DropdownButton
          id={'content-v2-download'}
          disabled={shouldDisableDownloadBtn}
          title="Download Selected"
          className="margin-all3 btn btn-primary btn-sm"
        >
          <MenuItem eventKey="1"
                    onClick={() => onDownloadSelected('txt')}>
            As Text File
          </MenuItem>
          <MenuItem eventKey="2"
                    onClick={() => onDownloadSelected('csv')}>
            As CSV File
          </MenuItem>
          <MenuItem eventKey="3"
                    onClick={() => onDownloadSelected('xls')}>
            As XLS File
          </MenuItem>
          <MenuItem eventKey="4"
                    onClick={() => onDownloadSelected('json')}>
            As JSON File
          </MenuItem>
        </DropdownButton>
      </ButtonGroup>
    </div>
  );
};

export {
  DownloadUserData,
  UserLogsDetailsList,
  UserLogsOperationStatusesList,
};
