import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  uploadImagesToGCSBucket,
} from '../../services/api-v1-services/other-services';
import {
  getDefaultGCSConfigs,
} from '../../utils';

const uploadImagesToGCS = () => {
  const [ uploadResponseData, setUploadResponseData ] = useState({ });
  const [ imageUploadData, setImageUploadData ] = useState(null);
  let {
    clearCdnCache=true,
    optimizeImages=true,
    resizeImages=false,
    resizeImageSizes=null,
    files,
    bucketName,
    project,
    onOperationComplete,
  } = imageUploadData || {};

  if(!bucketName) {
    bucketName = getDefaultGCSConfigs('bucketName');
  }

  if(!project) {
    project = getDefaultGCSConfigs('project');
  }

  const uploadImages = async () => {
    try {
      console.log(`Uploading images...`);
      if(_.isEmpty(files)) {
        console.log(`Uploading files are empty`);
        return;
      }

      setUploadResponseData({
        isUploading: true,
        isError: false,
      });

      const imageUploadResp = await uploadImagesToGCSBucket({
        files,
        clearCdnCache,
        bucketName,
        project,
        optimizeImages,
        resizeImages,
        resizeImageSizes,
      });

      const responseData = imageUploadResp.data;

      let errorMsg = null;
      let isError = false;

      if(responseData.success) {
        console.log('Image upload success!!');
      } else {
        console.log('Image upload failed!!');
        errorMsg = `Failed to upload images!`;
        isError = true;
      }

      setUploadResponseData({
        isUploading: false,
        isError,
        errorMsg,
        data: responseData.data,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError,
          errorMsg,
          data: responseData.data,
        });
      }
    } catch (err) {
      console.log('Something went wrong while uploading images. ', err);
      const errorMsg = `Error occurred while create/update items.`;
      setUploadResponseData({
        isUploading: false,
        isError: true,
        errorMsg,
        data: null,
      });
      if(onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(imageUploadData)) {
      uploadImages();
    }
  }, [ imageUploadData ]);

  return [
    uploadResponseData,
    setImageUploadData,
  ];
};

export default uploadImagesToGCS;
