import React,
{
  Fragment,
  useState,
} from 'react';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  COUNTRIES,
  fileNameRegex,
  CACHE_FILE_TYPES,
} from './Constants';
import {
  getCacheKeys,
  shouldDisableCacheClearButton,
} from './helper';
import {
  clearV2RedisCacheHook,
} from '../../hooks';

const countries = Object.keys(COUNTRIES).map((key) => {
  return {
    key,
    value: `${COUNTRIES[key]} - ${key}`
  }
});

const fileTypes = Object.keys(CACHE_FILE_TYPES).map((key) => {
  return {
    key,
    value: CACHE_FILE_TYPES[key]
  }
});

const InitialState = {
  fileName: '',
  startClean: false,
  selectedFileType: 'default',
  selectedCountry: 'default',
};

const ClearPrecalcCache  = () => {
  const [
    {
      fileName,
      startClean,
      selectedFileType,
      selectedCountry,
    },
    setState
  ] = useState(InitialState);

  const onChangeFileName = (e) => {
    const value = e.target.value;
    setState(prevState => ({ ...prevState, fileName: value }));
  };

  const onSelectChange = (e, type) => {
    const value = e.target.value;
    if(type === 'countries') {
      setState(prevState => ({ ...prevState, selectedCountry: value }));
    }

    if(type === 'fileTypes') {
      setState({ ...InitialState, selectedFileType: value });
    }
  };

  const onSubmit = () => {
    setState(prevState => ({ ...prevState, startClean: true }));
  };

  const {
    mainKey,
    subKey,
  } = getCacheKeys(selectedFileType, selectedCountry, fileName);
  const [ clearCacheResponse ] = clearV2RedisCacheHook({
    mainKey,
    subKey,
    shouldClean: startClean,
  });
  console.log('clearCacheResponse', clearCacheResponse);

  const validFileName = fileName ? fileNameRegex.test(fileName) : true;
  const invalidFileNameErrorMsg = 'Invalid file name. Follow pattern : MY0000003';
  const disableSubmitBtn =
      shouldDisableCacheClearButton(selectedFileType, selectedCountry, validFileName);

  const renderSelect = (arr, selectedItem, type) => {
    const options = arr.map((item, i) => {
      return (
          <option value={item.key} key={i}>{item.value}</option>
      );
    });

    return (
        <select value={selectedItem}
                onChange={(e) => onSelectChange(e, type)}
                className="form-control">
          <option disabled value="default">Please select</option>
          {options}
        </select>
    );
  };

  if(clearCacheResponse && startClean) {
    if(clearCacheResponse.status) {
      toast.success(clearCacheResponse.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(clearCacheResponse.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
    setState(prevState => ({ ...prevState, startClean: false }));
  }

  return (
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={2000} />
          <form className={'form-inline'}>
            <Row>
              <h3>{'Clear Precalc Cache.'}</h3>
            </Row>
            <br/>
            <Row>
              <Col md={3}>
                <div className="form-group">
                  <label style={{'marginRight': '5px'}}>{'File Type : '}</label>
                  {renderSelect(fileTypes, selectedFileType, 'fileTypes')}
                </div>
              </Col>
              {
                CACHE_FILE_TYPES[selectedFileType] === CACHE_FILE_TYPES.PRECALC_FILE ?
                    <Fragment>
                      <Col md={3}>
                        <div className="form-group">
                          <label style={{'marginRight': '5px'}}>{'Country : '}</label>
                          {renderSelect(countries, selectedCountry, 'countries')}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form-group">
                          <label style={{'marginRight': '5px'}}>{'File Name (Optional) : '}</label>
                          <input value={fileName}
                                 required
                                 onChange={onChangeFileName}
                                 type="text"
                                 className="form-control"
                                 placeholder="Sheet Number"
                          />
                          {
                            fileName && !validFileName ?
                                <div className={'invalid-file-name-error'}>
                                  {invalidFileNameErrorMsg}
                                </div> : null
                          }
                        </div>
                      </Col>
                    </Fragment>
                     : null
              }
            </Row>
            <Row>
              <Col md={4} className={'cache-clear-btn-main'}>
                <div className="form-group">
                  <Button
                      onClick={onSubmit}
                      disabled={disableSubmitBtn}
                      className="btn-primary api-test-submit-button">
                    {startClean ? 'Cleaning...' : 'Clean'}
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
      </BasicLayout>
  );
};

export default ClearPrecalcCache;
