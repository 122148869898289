import React,
{
  Component,
} from 'react';
import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import BasicLayout from "../../layouts/basiclayout";
import {
  toast,
  ToastContainer,
} from "react-toastify";
import {
  RedisSectionOptions,
} from './Constants';
import './style.css';
import {
  getKeyName,
  getRowClassName,
  getKeyClassName,
} from './common';
import {
  redisGetInfoForCF,
} from '../../services/mp-cf-api-service';

const getInfoData = (info) => {
  const allDataArray = info.split('\r\n\r\n');
  let dataObj = {};
  allDataArray.forEach(data => {
    const subDataArray = data.split('\n');
    let header;
    subDataArray.forEach(subData => {
      const cleanedData = subData.replace(/\s/g, '');
      if(cleanedData.includes('#')) {
        header = cleanedData.replace('#', '');
        dataObj[header] = {};
      } else {
        const rowData = cleanedData.split(':');
        // console.log(`header: ${header}, rowData[0]: ${rowData[0]}, rowData[1]: ${rowData[1]}`);
        dataObj[header][rowData[0]] = rowData[1];
      }
    })
  });

  return dataObj;
};

const options = Object.keys(RedisSectionOptions).map((option) => {
  return {
    key: option,
    value: RedisSectionOptions[option]
  }
});

class RedisInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: "",
      refreshing: false,
      selectedCategory: 'default'
    };
  }

  componentWillMount() {
    // const query = this.props.match.params.query;
    // if (query) {
    //   this.updateContentData(query);
    // }
    this.setState({
      refreshing: true,
    });
    this.updateContentData();
  }

  updateContentData(section) {
    const data = {
      section,
    };
    return redisGetInfoForCF(data).then((res) => {
      const response = res.data;
      if(response.result === "success") {
        const info = response.info;
        this.setState({
          info: getInfoData(info),
          refreshing: false,
        })
      } else {
        return null
      }
    }).catch((err) => {
      console.log(`Something went wrong while fetching the redis info, Error : ${err}`);
      this.setState({
        refreshing: false,
      });
      toast.error(`Something went wrong while fetching the redis info.`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      refreshing: true,
    });
    this.updateContentData(this.state.selectedCategory);
  }

  getRowData(rowData) {
    return Object.keys(rowData).map((key, i) => {
      if (key) {
        return (
          <tr className={getRowClassName(key)} key={i}>
            <td className={`redis-main-table-cell ${getKeyClassName(key)}`}>
              {getKeyName(key)}
            </td>
            <td className={'redis-main-table-cell'}>
              {rowData[key]}
            </td>
          </tr>
        );
      }

      return null;
    })
  }

  renderInfo() {
    const info = this.state.info;
    if (info) {
      return Object.keys(info).map((ro, i) => {
        // console.log(`ro : ${ro}, i ${i}`);

        const header = <h4 className={'redis-table-header'} key={i}>{ro}</h4>;
        const childRows = this.getRowData(info[ro]);

        return (
            <div className="redis-main-table table-responsive">
              {header}
              <table className={'table table-sm table-striped table-bordered table-hover'}>
                <tbody>
                {childRows}
                </tbody>
              </table>
            </div>
        );
      });
    } else {
      return null
    }
  }

  onSelectChange(e) {
    const category = e.target.value;
    // console.log('category', category);
    this.setState({ selectedCategory : category, refreshing: true });
    this.updateContentData(category);
  }

  renderSelect(arr, selectedCategory) {
    const options = arr.map((item, i) => {
      return (
          <option value={item.key} key={i}>{item.value}</option>
      );
    });

    // console.log(`selectKey ${selectedCategory}, defaultVal :${defaultVal}`);
    return (
        <select value={selectedCategory}
                onChange={this.onSelectChange.bind(this)}
                className="form-control">
          <option disabled value="default">Please select one</option>
          {options}
        </select>
    );
  }

  render() {
    return (
        <BasicLayout pagePermission="readonly">
          <ToastContainer autoClose={2000} />
          <div>
            <form className={'form-inline'} onSubmit={this.handleSubmit.bind(this)}>
              <Row>
                <h3>Redis Cache Info</h3>
              </Row>
              <br/>
              <Row>
                <Col style={{'padding': '0'}} sm={6}>
                  <div style={{'marginRight': '8px'}} className="form-group">
                    <label style={{'marginRight': '5px'}}>Info Category</label>
                    {this.renderSelect(options, this.state.selectedCategory)}
                  </div>
                  <Button
                      className="btn-primary"
                      type="submit">
                    {this.state.refreshing ? 'Refreshing...' : 'Refresh'}
                  </Button>
                </Col>
              </Row>
              <br/>
              <Row>
                {this.renderInfo()}
              </Row>
            </form>
          </div>
        </BasicLayout>
    );
  }
}

export default RedisInfo;
