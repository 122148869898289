import _ from "lodash";
import { useState, useEffect } from "react";
import { qalBoxUpdateEncodeMediaId } from "../../services/mp-cf-api-service";

const updateEncodeMediaId = () => {
  const [responseData, setResponseData] = useState({});
  const [updateData, setUpdateData] = useState(null);
  const { media, onEncodingComplete } = updateData || {};

  const updateEncodeItemMediaId = async () => {
    try {
      console.log(`QalBox add or update media times...`);
      setResponseData({
        isUpdating: true,
        isError: false,
      });

      const mediaItemsSyncApiResp = await qalBoxUpdateEncodeMediaId({
        media: media,
      });
      const responseData = mediaItemsSyncApiResp.data;

      let errorMsg = null;
      let isError = false;

      if (responseData.success) {
        console.log("QalBox media items add/update Success!!");
      } else {
        console.log("QalBox media items add/update Failed!!");
        errorMsg = `Failed to add/update QalBox media items.`;
        isError = true;
      }

      setResponseData({
        isUpdating: false,
        isError,
        errorMsg,
        data: responseData.payload,
      });

      if (onEncodingComplete) {
        onEncodingComplete({
          isError,
          errorMsg,
          data: responseData.payload,
        });
      }
    } catch (err) {
      console.log(
        "Something went wrong while add/update QalBox media items. ",
        err
      );
      const errorMsg = `Error occurred while add/update QalBox media items.`;
      setResponseData({
        isUpdating: false,
        isError: true,
        errorMsg,
        data: null,
      });
      if (onEncodingComplete) {
        onEncodingComplete({
          isError: true,
          errorMsg,
          data: null,
        });
      }
    }
  };

  useEffect(() => {
    if (!_.isEmpty(updateData)) {
      updateEncodeItemMediaId();
    }
  }, [updateData]);

  return [responseData, setUpdateData];
};

export default updateEncodeMediaId;
