import {
  ENVIRONMENT,
} from '../Constants';

const firebaseEnvConfigs = {
  [ENVIRONMENT.PRODUCTION]: {
    apiKey: "AIzaSyAED8oH81YdEyK1z-r99gfqRQHQQrNXvQg",
    authDomain: "muslim-pro-app.firebaseapp.com",
    databaseURL: "https://muslim-pro-app.firebaseio.com",
    projectId: "muslim-pro-app",
    storageBucket: "muslim-pro-app.appspot.com",
    messagingSenderId: "26836749833",
  },
  [ENVIRONMENT.STAGING]: {
    apiKey: "AIzaSyD7TVSbjXnNtmOuaNRKQrKD_YeqF_zRnOE",
    authDomain: "muslim-pro-app-staging.firebaseapp.com",
    databaseURL: "https://muslim-pro-app-staging.firebaseio.com",
    projectId: "muslim-pro-app-staging",
    storageBucket: "muslim-pro-app-staging.appspot.com",
    messagingSenderId: "181783655497",
  },
  [ENVIRONMENT.DEVELOPMENT]: {
    // For now, we are using production configs.
    apiKey: "AIzaSyAED8oH81YdEyK1z-r99gfqRQHQQrNXvQg",
    authDomain: "muslim-pro-app.firebaseapp.com",
    databaseURL: "https://muslim-pro-app.firebaseio.com",
    projectId: "muslim-pro-app",
    storageBucket: "muslim-pro-app.appspot.com",
    messagingSenderId: "26836749833",
  },
};

export default firebaseEnvConfigs;


