import React,
{
  Component,
} from 'react';
import LoginLayout from '../../layouts/loginlayout';
import {
  Button,
} from 'react-bootstrap';
import {
  Icon,
} from 'react-fa';
import firebase from 'firebase/app';
import 'firebase/auth';
import {
  createLog,
} from '../../services/logs';
import './style.css';
import {
  getModerator,
} from '../../services/admin-db-service';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginError: false,
      loginAttepted: false
    };

    this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
  }

  componentWillMount() {
    firebase.auth().onAuthStateChanged((user) => {
      this.validateBitsmediaModerator(user, true);
    });
  }

  handleGoogleLogin() {
    this.setState({
      loginError: false,
      loginAttepted: true
    });
    let provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider).then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // let token = result.credential.accessToken;
      // The signed-in user info.
      let user = result.user;
      this.validateBitsmediaModerator(user, false);

    }).catch((error) => {
      // Handle Errors here.
      // let errorCode = error.code;
      let errorMessage = error.message;
      // The email of the user's account used.
      // let email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      // let credential = error.credential;
      //localStorage.removeItem(firebaseAuthKey);
      this.setState({
        loginError: true
      });
      console.log("Login failed: ", errorMessage);
    });
  }

  validateBitsmediaModerator(user, sessionedLogin) {
    if(user && this.isBitsmediaDomain(user)) {
      // Is among bitsmedia moderators
      if(!sessionedLogin) {
        createLog('login', user);
      }
      getModerator(user.uid).then((moderator) => {
        if(moderator) {
          window.location = "/search-user";
        } else {
          this.firebaseLogout();
        }
      });
    } else {
      this.firebaseLogout();
    }
  }

  isBitsmediaDomain(user) {
    const domain = user.email.replace(/.*@/, "");
    return (domain === 'bitsmedia.com');
  }

  firebaseLogout() {
    this.setState({
      loginError: true
    });
    firebase.auth().signOut().then(function() {
      localStorage.removeItem("eav1at");
      localStorage.removeItem("idToken");
      localStorage.removeItem("uid");
      localStorage.removeItem("user");
      localStorage.removeItem("userPermission");
      localStorage.removeItem("moderatorsInfo");
      localStorage.removeItem("dataModerators");
    }, function(error) {
      // An error happened.
    });
  }

  renderError() {
    const {
      loginError,
      loginAttepted,
    } = this.state;
    if(loginAttepted && loginError) {
      return <p className="text-danger small login__error_text">
        Login failed. You are not a bitsmedia moderator.
      </p>;
    } else if(loginAttepted && !loginError) {
      return <p className="text-info small login__error_text"><Icon spin name="spinner" /></p>;
    } else {
      return null;
    }
  }

  render() {
    return (
      <LoginLayout page="login">
        <div className="login">
          <h3 className="login__title text-center">Login to Firebase Admin Console</h3>
          <Button onClick={this.handleGoogleLogin} className="login__google_btn">
            <Icon className="login__google_btn_icon" name="google-plus" />
            <div className="login__google_btn_label">
              Sign in with Google
            </div>
          </Button>
          {this.renderError()}
        </div>
      </LoginLayout>
    );
  }
}

export default Login;
