import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import {Icon} from 'react-fa';
import { ToastContainer, toast } from 'react-toastify';
import firebase from 'firebase/app';
import 'firebase/database';
import axios from 'axios';
import Masonry from 'react-masonry-component';
import { createLog } from '../../services/logs';
import UndoBar from '../../widgets/undobar';
import './style.css';
import AdminUserService from "../../service_v2/users/admin-user-service";

const showBgLogsRes = localStorage.getItem("showBgLogsRes") || false;

const getLogsAPIURL = 'https://us-central1-muslim-pro-app.cloudfunctions.net/getAdminLogs';

const CommunityModerateURL = 'https://us-central1-muslim-pro-app.cloudfunctions.net/CommunityModerate';

const RTDB = ((path) => {
  return firebase.database().ref(path);
});

class Requests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      undoObj: null
    };
  }

  undoAction(undoObj) {
    this.hideUndo();
    this.setState({
      undoObj
    });
  }

  hideUndo() {
    this.setState({
      undoObj: null
    });
  }

  renderCards() {
    const masonryOptions = {
        transitionDuration: 0
    };
    const cards = this.props.cards;
    const cardEls = cards.map((card, i) => {
      return <Card data={card} key={i} undoAction={this.undoAction.bind(this)} />;
    });
    return <Masonry
                className={'my-gallery-class'} // default ''
                elementType={'div'} // default 'div'
                options={masonryOptions} // default {}
                disableImagesLoaded={false} // default false
                updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
            >
        {cardEls}
    </Masonry>;
  }

  render() {
    const { undoObj } = this.state;
    return (
      <Row className="pr__wrapper">
        <UndoBar undoObj={undoObj} hideUndo={this.hideUndo.bind(this)}/>
        <ToastContainer autoClose={2000} />
        {this.renderCards()}
      </Row>
    );
  }
}

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userBlocked: null,
      userBlockedBy: null,
      undoTrigger: null
    };
  }

  componentWillMount() {
    const { data } = this.props;
    if(data && data.userId) {
      AdminUserService.queryUserData(data.userId, { path: '/'}).then( user => {
        if(user) {
          this.setState({
            userBlocked: user.blocked ? true : false
          });
        } else {
          this.setState({
            userBlocked: false
          });
        }
      });
    }
    if(data.blocked) {
      this.getRequestBlockedBy(data.key)
        .then((userBlockedBy) => {
          if(userBlockedBy) {
            this.setState({
              userBlockedBy
            })
          }
        });
    }
  }

  getRequestBlockedBy(reqId) {
    return axios({
      method: 'get',
      url: `${getLogsAPIURL}/block_prayer_request/${reqId}`,
      headers: {
        'idToken': localStorage.getItem('idToken') || "",
        'uid': localStorage.getItem('uid') || "",
        'Content-Type': 'application/json'
      }
    })
    .then((res) => {
      const rows = res.data;
      if(showBgLogsRes) {
        console.log("getAdminLogs API: Fetched data for block_prayer_request", rows);
      }
      if(rows.length > 0) {
        return Promise.resolve(rows[0].email);
      }
      return Promise.resolve(false);
    })
    .catch((err) => {
      if(showBgLogsRes) {
        console.log("getAdminLogs error: ", err);
      }
      return Promise.resolve(false);
    });
  }

  blockUnblockPr(data) {
    const throwError = (() => {
      toast.error("Something went wrong", {
          position: toast.POSITION.BOTTOM_LEFT
      });
    });
    return axios({
      method: 'post',
      url: `${CommunityModerateURL}/`,
      headers: {
        'idToken': localStorage.getItem('idToken') || "",
        'uid': localStorage.getItem('uid') || "",
        'Content-Type': 'application/json'
      },
      data
    })
    .then((res) => {
      if(res.data.result === 'success') {
        if(!data.bulk) {
          toast.success(`Prayer request ${data.requestId} has been ${data.blocked ? 'blocked' : 'unblocked'} successfully`, {
            position: toast.POSITION.BOTTOM_LEFT
          });
        }
        return Promise.resolve();
      } else {
        throwError();
        return Promise.reject();
      }
    })
    .catch((err) => {
        console.log(err);
        throwError();
        return Promise.reject();
    });
  }

  renderBlockUnblockRequestsButton(blocked, reqId, uid, languageCode, undoObj) {
    if(blocked) {
      return (
        <button onClick={this.onUnblockReqClick.bind(this, reqId, uid, languageCode, undoObj)} type="button" className="btn btn-default btn-success pr__card_reset_btn btn-xs btn-block"><Icon name="check" /> Unblock request</button>
      );
    } else {
      return (
        <button onClick={this.onBlockReqClick.bind(this, reqId, uid, languageCode, undoObj)} type="button" className="btn btn-default btn-danger pr__card_reset_btn btn-xs btn-block"><Icon name="ban" /> Block request</button>
      );
    }
  }

  renderBlockUnblockUserButton(blocked, uid, undoObj) {
    if(blocked === true) {
      return (
        <button onClick={this.onUnblockUserClick.bind(this, uid, undoObj)} type="button" className="btn btn-success pr__card_block_btn btn-xs pull-right"><Icon name="user-times" /> Unblock user</button>
      );
    } else if (blocked === false){
      return (
        <button onClick={this.onBlockUserClick.bind(this, uid, undoObj)} type="button" className="btn btn-danger pr__card_block_btn btn-xs pull-right"><Icon name="user-times" /> Block user</button>
      );
    } else {
      return (
        <button type="button" disabled="disabled" className="btn btn-danger pr__card_block_btn btn-xs pull-right"><Icon name="user-times" /> Loading...</button>
      );
    }
  }

  blockAllPrayerRequestOfUser(uid) {
    AdminUserService.queryUserData(uid, { path: '/prayer-requests-index' }).then((index) => {
      if(index) {
        let promises = [], count = 0;
        Object.keys(index).forEach((requestId) => {
          const p = this.blockUnblockPr({
            requestId,
            languageCode: index[requestId],
            type: 'block',
            bulk: true
          });
          count++;
          promises.push(p);
        });
        Promise.all(promises).then(() => {
          toast.success(`Blocked ${count} prayer requests of ${uid}`, {
            position: toast.POSITION.BOTTOM_LEFT
          });
        })
        .catch((e) => {
          toast.error(`Something went wrong while trying block prayer requests of ${uid}`, {
            position: toast.POSITION.BOTTOM_LEFT
          });
        });
      } else {
        toast.success(`${uid} doesn't have any prayer requests to block`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }
    });
  }

  onBlockUserClick(uid, undoObj) {
    //const confirmBlockUser = window.confirm(`Do you really want to block the user ${uid}?`);
    //if(confirmBlockUser) {
      createLog('block_user', {user_id: uid});
      return AdminUserService.setUserData(uid, { blocked: true }).then(() => {
        toast.success(`User ${uid} has been blocked`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
        this.props.undoAction({
          ...undoObj,
          trigger: "blockUser"
        });
        this.blockAllPrayerRequestOfUser(uid);
      })
      .catch((err) => {
        console.log(err);
      });
    //}
  }

  onUnblockUserClick(uid, undoObj) {
    //const confirmUnblockUser = window.confirm(`Do you really want to unblock the user ${uid}?`);
    //if(confirmUnblockUser) {
      createLog('unblock_user', {user_id: uid});
      return AdminUserService.setUserData(uid, { blocked: false }).then(() => {
        toast.success(`User ${uid} has been unblocked`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
        this.props.undoAction({
          ...undoObj,
          trigger: "unblockUser"
        });
      })
      .catch((err) => {
        console.log(err);
      });
    //}
  }

  onBlockReqClick(requestId, uid, languageCode, undoObj) {
    //const confirmBlockReq = window.confirm(`Do you really want to block the prayer request ${reqId}?`);
    //if(confirmBlockReq) {
      createLog('block_prayer_request', {
        language: languageCode,
        request_id: requestId
      });
      return this.blockUnblockPr({
        requestId,
        languageCode,
        type: 'block'
      })
      .then(() => {
        this.props.undoAction({
          ...undoObj,
          trigger: "blockRequest"
        });
      });
  }

  onUnblockReqClick(requestId, uid, languageCode, undoObj) {
    //const confirmUnblockReq = window.confirm(`Do you really want to unblock the prayer request ${reqId}?`);
    //if(confirmUnblockReq) {
      createLog('unblock_prayer_request', {
        language: languageCode,
        request_id: requestId
      });
      return this.blockUnblockPr({
        requestId,
        languageCode,
        type: 'unblock'
      })
      .then(() => {
        this.props.undoAction({
          ...undoObj,
          trigger: "unblockRequest"
        });
      });
  }

  onResetReportCount(requestId, uid, languageCode) {
    const confirmResetCount = window.confirm(`Do you really want to reset the report count of the prayer request ${requestId}?`);
    if(confirmResetCount) {
      createLog('reset_prayer_request_count', {
        language: languageCode,
        request_id: requestId
      });
      return this.blockUnblockPr({
        requestId,
        languageCode,
        type: 'resetAbuseCount'
      })
      .then(() => {
          toast.success(`Report count of ${requestId} has been reset`, {
            position: toast.POSITION.BOTTOM_LEFT
          });
        })
        .catch((err) => {
         console.log(err);
        });
    }
  }

  renderBlockedBy(email) {
    if(!email) {
      return 'unknown';
    }

    return (
      <span title={email} className="small blockedby">{email}</span>
    );
  }

  renderReported(blocked, userBlockedBy) {
    if(!blocked) {
      return null;
    }

    return (
      <span className="count reported">
        <Icon name="flag" /> <span>Blocked by {this.renderBlockedBy(userBlockedBy)}</span>
      </span>
    );
  }

  render() {
    const { userName, userId, key, timestamp, text, prayerCountTotal, locationName, blocked, languageCode } = this.props.data;
    const { userBlocked, userBlockedBy } = this.state;
    const userLink = `/search-user/${userId}`;
    const undoObj = {
      actionObj: {
        uid: userId,
        requestId: key,
        languageCode
      },
      config: {
        toast,
        createLog,
        firebase
      }
    };
    const timestampInt = parseInt(timestamp, 10);
    const displayTimeStamp = (timestampInt.toString().length === 10) ? timestampInt*1000 : timestampInt;
    return (
      <Col className={"pr__card " + (blocked ? 'blocked' : '')} md={3}>
        <div className="pr__card_inner">
          <div className="blocked__header">Blocked</div>
          <div className="pr__card_meta">
            <p className="pr__card_meta_top">
              <a className="pull-left" href={userLink} target="_blank"><Icon name="user" /> {userName}</a>
              <span title={locationName} className="pull-right address"><Icon name="map-marker" /> {locationName}</span>
            </p>
            <p><Icon name="clock-o" /> {moment(displayTimeStamp).utc().format('MMM DD, YYYY hh:mm')}</p>
          </div>
          <p className="small text-muted">{key}</p>
          <div className="pr__card_text">{text}</div>
          <div className="pr__card_counts">
            <span className="count">
              <Icon name="heart" /> Prayed {prayerCountTotal}
            </span>
            {this.renderReported(blocked, userBlockedBy)}
          </div>
          <div className="pr__card_actions">
            <button onClick={this.onResetReportCount.bind(this, key, userId, languageCode)} title="Reset report count" type="button" className="btn btn-warning pr__card_block_btn btn-xs pull-left"><span className="glyphicon glyphicon-repeat"></span> Reset count..</button>
            {this.renderBlockUnblockUserButton(userBlocked, userId, undoObj)}
            {this.renderBlockUnblockRequestsButton(blocked, key, userId, languageCode, undoObj)}
          </div>
        </div>
      </Col>
    );
  }
}

export default Requests;
