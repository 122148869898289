import React, { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import { MPUploadExcel } from '../../widgets/upload-excel';
import { ExcelUploadInfoPanel } from './common';
import {
  fetchQuranQuotesRawJsonHook, updateQuranQuotesRawJsonHook,
} from '../../hooks';
import { getItems, getQuranQuotesList } from './helper';
import { OperationStatus } from '../../widgets/common';

const UploadExcel = () => {

  const [ quranQuotesListData, doFetch ] = fetchQuranQuotesRawJsonHook();
  const isFetching = quranQuotesListData && quranQuotesListData.isFetching;

  const onFetchComplete = operationData => {
    if(!operationData.isError) {
      toast.success(`Fetching Quoran Quotes Data Success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  useEffect(() => {
    doFetch({
      onOperationComplete: onFetchComplete,
    });
  }, []);

  const quranQuotesList = getQuranQuotesList(quranQuotesListData);
  // TODO: Only show upload if quranQuotesList is present.

  return (
    <BasicLayout pagePermission="moderate_precalc">
      <ToastContainer autoClose={2000}/>
      <Row>
        <Col md={12}>
          <h3 className='content_editor_title'>{'Upload Excel'}</h3>
        </Col>
      </Row>
      {
        isFetching
          ?
          <OperationStatus
            operationInProgressText={`Please wait! Fetching Quran Quotes...`}
          />
          :
          <MPUploadExcel
            InfoPanel={ExcelUploadInfoPanel}
            fileUpdateHook={updateQuranQuotesRawJsonHook}
            fileUpdateSuccessMessage={'Items Updated Successfully!!'}
            getItems={(rows) => getItems(quranQuotesList, rows)}
          />
      }
    </BasicLayout>
  );
};

export default UploadExcel;
