import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AppModal from "../../../../widgets/modal";

const QalboxImageInputBox = ({ name, value, handleInputTextChange }) => {
  const [showImagePreview, setShowImagePreview] = useState(false);
  
  return (
    <div className="qalbox-form-row">
      <textarea
        value={value}
        name={name}
        type="text"
        onChange={handleInputTextChange}
        className="form-control"
        placeholder={name}
        style={{ marginBottom: "5px", marginRight: "10px" }}
      />
      <CopyToClipboard text={value}>
        <Button className="btn btn-xs btn-dark mp-content-v2-table-hover-btn">
          &nbsp;
          <span className="glyphicon glyphicon-duplicate" aria-hidden="true" />
        </Button>
      </CopyToClipboard>
      <Button
        style={{ marginRight: "5px" }}
        className="btn btn-xs btn-dark mp-content-v2-table-hover-btn"
        onClick={() => setShowImagePreview(true)}
      >
        <span className="glyphicon glyphicon-picture" aria-hidden="true" />
      </Button>
      <AppModal
        show={showImagePreview}
        showBody={true}
        headingText={`Image Preview`}
        modalBody={
          <img
            src={value} // Replace with the actual path to your image
            alt="Image Preview"
            style={{ maxWidth: "100%", marginTop: "10px" }}
          />
        }
        modalBodyClassName={""}
        closeButtonText={""}
        okButtonText={""}
        handleClose={() => setShowImagePreview(false)}
      />
    </div>
  );
};

export default QalboxImageInputBox;
