import _ from 'lodash';
import React from 'react';
import {
  getCacheFlushKeys,
} from './helper';
import './style.css';

const HighLightInfoRows = [
  'connected_clients',
  'used_memory_human',
];

const getRowClassName = (key) => {
  return HighLightInfoRows.includes(key) ? 'redis-info-highlight-row' : '';
};

const getKeyClassName = (key) => {
  return HighLightInfoRows.includes(key) ? 'redis-info-highlight-key' : '';
};

const getKeyName = (key) => {
  return `${_.startCase(_.toLower(key))}`;
};

const getRowData = (rowData) => {
  return Object.keys(rowData).map((key, i) => {
    const data = typeof rowData[key] === 'object' ?
        JSON.stringify(rowData[key]) : rowData[key];
    if (key) {
      return (
          <tr className={getRowClassName(key)} key={i}>
            <td className={`redis-main-table-cell ${getKeyClassName(key)}`}>
              {getKeyName(key)}
            </td>
            <td className={'redis-main-table-cell'}>
              {data}
            </td>
          </tr>
      );
    }

    return null;
  })
};

const renderInfo = ({
                      info,
                      category,
                    }) => {
  if (info && category) {
    const header = (
        <h4 className={'redis-table-header'} key={category}>
          {category.toUpperCase()}
        </h4>
    );
    const childRows = getRowData(info);

    return (
        <div className="redis-main-table table-responsive">
          {header}
          <table className={'table table-sm table-striped table-bordered table-hover'}>
            <tbody>
            {childRows}
            </tbody>
          </table>
        </div>
    )
  } else {
    return null
  }
};

const getFlushInfo = ({
                        selectedAPI,
                        subCacheKey,
                        mainCacheKey,
                        selectedCacheKey,
                      }) => {

  const [mainKey, subKey] =
      getCacheFlushKeys(selectedAPI, selectedCacheKey, mainCacheKey, subCacheKey);

  return (
      <table className={'table table-responsive'}>
        <thead>
        <tr>
          <th>Main Cache Key</th>
          <th>Sub Cache Key</th>
        </tr>
        </thead>
        <tbody>
        <tr style={{ color: 'red'}}>
          <td>{mainKey}</td>
          <td>{subKey}</td>
        </tr>
        </tbody>
      </table>
  );
};

export {
  renderInfo,
  getKeyName,
  getFlushInfo,
  getKeyClassName,
  getRowClassName,
}
