import React from 'react';
import {
  Col,
  Row,
  Alert,
} from 'react-bootstrap';
import Collapse,
{
  Panel,
} from 'rc-collapse';
import {
  MPExpandIcon,
} from '../../widgets/common';

const MediaExcelUploadInfoPanel = () => {
  const sampleExcelFileUrl = 'https://docs.google.com/spreadsheets/d/1tCEmGi_qmSo2iO0m5lPRwbiCmkuS5CNVI5LMd49pxHM/edit#gid=0';

  return (
    <Row>
      <Col>
        <Alert>
          <strong style={{'fontSize': '20px'}}>
            Important Note (Please read before you upload):
          </strong>
          <br/>
          <Collapse
            accordion={true}
            className={'upload-note-collapse'}
            expandIcon={MPExpandIcon}
          >
            <Panel
              header="Excel Upload Guide"
              headerClass="excel-upload-note-header"
              extra={<span>Click to see/hide Details</span>}
            >
              <ol>
                <li>
                  Please see the sample file for format.
                </li>
                <li>
                  All fields that are marked with&nbsp;
                  <strong><u>required</u></strong>&nbsp;
                  should be available when uploading the file.
                </li>
                <li>
                  <a href="https://docs.google.com/spreadsheets/d/1JGE1rRtqebKVbJby43ZCoCg-MzQniQ82OHaWcagwKV4/edit#gid=0" >SAMPLE Document</a>
                </li>
              </ol>
            </Panel>
          </Collapse>
          <label>See the Sample Excel Sheet : </label> &nbsp;
          <a href={sampleExcelFileUrl} target="_blank">
            Click to see the sample excel sheet.
          </a>
        </Alert>
      </Col>
    </Row>
  );
};

const ClearDbInfoPanel = () => {
    return (
        <Row>
            <Col>
                <Alert>
                    <strong style={{'fontSize': '12px'}}>
                        Important Note (Please read before you reset):
                    </strong>
                    <Collapse
            accordion={true}
            className={'upload-note-collapse'}
            expandIcon={MPExpandIcon}
          >
            <Panel
              header="Database reset guide"
              headerClass="excel-upload-note-header"
              extra={<span>Click to see/hide Details</span>}
            >
              <ol>
                <li>
                  Clicking on 'Clear and Reset' button will delete all the data in MongoDb Query Collection except for the contemporary Q&A.
                </li>
                <li>
                  Only perform this operation if you are sure or in the cases mentioned below:
                  <ul>
                    <li>Model is updated</li>
                  </ul>
                </li>
              </ol>
            </Panel>
          </Collapse>
                </Alert>
            </Col>
        </Row>
    )
}


export {
  MediaExcelUploadInfoPanel,
  ClearDbInfoPanel
}