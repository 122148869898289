import _ from "lodash";
import { useEffect, useState } from "react";
import { ScaleLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import QalboxSelectDropdown from "../../media/helperComponents/qalboxSelect";
import { convertBrandArrayToSelectList, convertBrandToOption } from "../../media/helpers/brandDropdownHelpers";
import { useFetchBrands } from "../../brands/hooks/useFetchBrand";

export const QalboxSimpleBrandsDropdown = ({brandUuid, handleSelect}) => {
  const [brandList, isLoading] = useFetchBrands(true);

  return (
    <LoadingOverlay
      active={isLoading}
      spinner={<ScaleLoader />}
      text="Loading...."
    >
      <div className="form-group">
        <label>Branded Partner ID</label>
        <div style={{width: '200px'}}>
          <QalboxSelectDropdown
            name={"branded_partner_uuid"}
            options={convertBrandArrayToSelectList(brandList && brandList.length > 0 ? brandList : [])}
            selectedOption={convertBrandToOption(brandUuid, brandList && brandList.length > 0 ? brandList : [])}
            onChange={handleSelect}
          />
        </div>
      </div>
    </LoadingOverlay>
  );
};