import React from 'react';
import BasicLayout from '../../layouts/basiclayout';
import UpdateSightings from './updateSightings';

const EUFSightings = () => {
  return (
      <BasicLayout pagePermission="moderate_precalc">
        <UpdateSightings
            dataKey={'eidulfitr'}
            title={'Eid Ul Fitr Sightings'}
        />
      </BasicLayout>
  );
}

export default EUFSightings;
