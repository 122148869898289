import _ from 'lodash';
import moment from 'moment-timezone';
import {
  HAJJ_AND_UMRAH_TYPE,
  SUPPORTED_LANGUAGES,
  SPECIAL_HEADERS_COUNT,
  IMAGES_DIRECTORY_PREFIX,
} from './Constants';
import {
  isValidAudiences,
  isValidCountryCodes,
  getDateFromExcelValue,
} from '../../utils';
import {
  getMetaData,
} from '../../services/meta-data';

const getDataFromMeta = (metaKey, el) => {
  const metaName = el.attr('name');
  const metaProp = el.attr('property');
  if(metaName === `og:${metaKey}`
      || metaName === `twitter:${metaKey}`
      || metaProp === `og:${metaKey}`
      || metaProp === `twitter:${metaKey}`) {
    return el.attr('content');
  }

  return '';
};

const getLocalTime = (timestamp) => {
  if (timestamp && timestamp._seconds) {
    return moment(timestamp._seconds*1000).format('YYYY-MM-DDTHH:mm');
  }

  return timestamp || '';
};

const getMetaInfo = (htmlString) => {
  const jQ = window.jQuery;
  const crawledHTML = jQ.parseHTML(htmlString);
  let thumbnailUrl = '', title = '', summary = '', source = '';

  crawledHTML.forEach((token) => {
    if (jQ(token).is('meta')) {
      thumbnailUrl = thumbnailUrl || getDataFromMeta('image', jQ(token));
      title = title || getDataFromMeta('title', jQ(token));
      summary = summary || getDataFromMeta('description', jQ(token));
      source = source || getDataFromMeta('site_name', jQ(token));
    }
  });

  const languageCode = getLangFromHtml(htmlString);

  return {
    title,
    source,
    summary,
    thumbnailUrl,
    languageCode,
  };
};

const getLangFromHtml = (htmlString) => {
  // fetching lang from html tag
  const htmlRegex = /(<html.*?>)/;
  let htmlStartFound = htmlString.match(htmlRegex);
  let crawledHtmlTag = (htmlStartFound && htmlStartFound[0]) || "";
  // fetched <html all attrs >
  const langRegex = /(lang=".*?")/;
  let langFound = crawledHtmlTag.match(langRegex) ? crawledHtmlTag.match(langRegex)[0] : "";
  const codeRegex = /(".*?")/;
  let codeFound = langFound.match(codeRegex);
  let code = (codeFound && codeFound[0]) || "";
  code = code ? code.split('"').join('') : code;
  code = code.slice(0, 2);

  return code;
};

const isValidLanguageCode = (languageCode) => {
  return languageCode && _.values(SUPPORTED_LANGUAGES).includes(languageCode);
};

const isValidType = (type) => {
  return type && _.values(HAJJ_AND_UMRAH_TYPE).includes(type);
};

const getArrayFromString = (str) => {
  return str.replace(/\s/g, '').replace(/(^,)|(,$)/g, "").split(',')
};

const getItems = async (rows) => {
  const items = [];
  const allErrors = [];
  // Starting from SPECIAL_HEADERS_COUNT, because we need to skip title and comments.
  for(let i = SPECIAL_HEADERS_COUNT; i < rows.length; i++) {
    let errorsCount = 0;
    const data = rows[i];

    const item = {};

    const errors = {
      'row': i + 1,
      'errorsCount': 0,
      'errorList': [],
      'excelSheetRowData': data,
    };

    // Id
    const itemId = data[0] || null;
    if(itemId) {
      item['id'] = itemId;
    }

    // Language Code
    let languageCode = data[1];
    languageCode = languageCode ? languageCode.toLowerCase() : null;
    if(itemId) {
      // Old Item
      if(languageCode) {
        if(isValidLanguageCode(languageCode)) {
          item['language_code'] = languageCode;
        } else {
          errors['errorList'].push(`${++errorsCount}. Language code is invalid.`);
        }
      }
    } else {
      // New Item
      if(!languageCode) {
        errors['errorList'].push(`${++errorsCount}. Language is missing.`);
      } else {
        if(isValidLanguageCode(languageCode)) {
          item['language_code'] = languageCode;
        } else {
          errors['errorList'].push(`${++errorsCount}. Language code is invalid or not supported.`);
        }
      }
    }

    // Type
    let type = data[2];
    type = type ? type.toLowerCase() : null;
    if(itemId) {
      // Old Item
      if(type) {
        if(isValidType(type)) {
          item['type'] = type;
        } else {
          errors['errorList'].push(`${++errorsCount}. Type is invalid.`);
        }
      }
    } else {
      // New Item
      if(!type) {
        errors['errorList'].push(`${++errorsCount}. Type is missing.`);
      } else {
        if(isValidType(type)) {
          item['type'] = type;
        } else {
          errors['errorList'].push(`${++errorsCount}. Type is invalid.`);
        }
      }
    }

    // Title
    let title = data[3];
    if(itemId) {
      // Old Item
      if(title) {
        item['title'] = title;
      }
    } else {
      // New Item
      if(!title) {
        // Do nothing.
        // errors['errorList'].push(`${++errorsCount}. Title is missing.`);
      } else {
        item['title'] = title;
      }
    }

    // URL
    let url = data[4];
    if(itemId) {
      // Old Item
      if(url) {
        item['url'] = url;
      }
    } else {
      // New Item
      if(!url) {
        errors['errorList'].push(`${++errorsCount}. URL is missing.`);
      } else {
        item['url'] = url;
      }
    }

    // Thumbnail URL
    let thumbnailUrl = data[5];
    if(itemId) {
      // Old Item
      if(thumbnailUrl) {
        item['thumbnail_url'] = thumbnailUrl;
      }
    } else {
      // New Item
      if(!thumbnailUrl) {
        // Do nothing.
        // errors['errorList'].push(`${++errorsCount}. Thumbnail Url is missing.`);
      } else {
        item['thumbnail_url'] = thumbnailUrl;
      }
    }

    // Audiences
    let audiences = [];
    const audiencesString = data[6];
    if(audiencesString) {
      const audiencesArray = getArrayFromString(audiencesString);
      if(!_.isEmpty(audiencesArray)) {
        audiences = _.map(audiencesArray, audience => audience.toLowerCase())
      }
    }
    if(itemId) {
      // Old Item
      if(!_.isEmpty(audiences)) {
        if(isValidAudiences(audiences)) {
          item['audiences'] = audiences;
        } else {
          errors['errorList'].push(`${++errorsCount}. Audiences are invalid.`);
        }
      }
    } else {
      // New Item
      if(_.isEmpty(audiences)) {
        item['audiences'] = ['all'];
      } else {
        if(isValidAudiences(audiences)) {
          item['audiences'] = audiences;
        } else {
          errors['errorList'].push(`${++errorsCount}. Audiences are invalid.`);
        }
      }
    }

    // Summary
    let summary = data[7];
    if(itemId) {
      // Old Item
      if(summary) {
        item['summary'] = summary;
      }
    } else {
      // New Item
      if(!summary) {
        // Do nothing.
        // errors['errorList'].push(`${++errorsCount}. Summary is missing.`);
      } else {
        item['summary'] = summary;
      }
    }

    // Source
    let source = data[8];
    if(itemId) {
      // Old Item
      if(source) {
        item['source'] = source;
      }
    } else {
      // New Item
      if(!source) {
        // Do nothing.
        // errors['errorList'].push(`${++errorsCount}. Source is missing.`);
      } else {
        item['source'] = source;
      }
    }

    // Fetch data if not available
    if(url) {
      const metaData = await getMetaData(url);
      console.log('metaData : ', metaData);

      if(!item['title']) {
        item['title'] = metaData.title;
      }

      if(!item['source']) {
        item['source'] = metaData.source;
      }

      if(!item['summary']) {
        item['summary'] = metaData.summary;
      }

      if(!item['thumbnail_url']) {
        item['thumbnail_url'] = metaData.thumbnailUrl;
      }
    }

    // Countries
    let countries = [];
    const countriesString = data[9];
    if(countriesString) {
      const countriesArray = getArrayFromString(countriesString);
      if(!_.isEmpty(countriesArray)) {
        countries = _.map(countriesArray, country => country.toUpperCase())
      }
    }
    if(itemId) {
      // Old Item
      if(!_.isEmpty(countries)) {
        if(isValidCountryCodes(countries)) {
          item['countries'] = countries;
        } else {
          errors['errorList'].push(`${++errorsCount}. Countries are invalid.`);
        }
      }
    } else {
      // New Item
      if(_.isEmpty(countries)) {
        item['countries'] = countries;
      } else {
        if(isValidCountryCodes(countries)) {
          item['countries'] = countries;
        } else {
          errors['errorList'].push(`${++errorsCount}. Countries are invalid.`);
        }
      }
    }

    // Publish Start Date
    const publishStartDate = data[10] || null;
    if(itemId) {
      // Old Item
      if(!_.isNil(publishStartDate)) {
        item['publish_start_date'] = getDateFromExcelValue(publishStartDate, true);
      }
    } else {
      // New Item
      if(_.isNil(publishStartDate)) {
        errors['errorList'].push(`${++errorsCount}. Publish Start Day is missing.`);
      } else {
        item['publish_start_date'] = getDateFromExcelValue(publishStartDate, true);
      }
    }

    // Publish End Date
    const publishEndDate = data[11] || null;
    if(itemId) {
      // Old Item
      if(!_.isNil(publishEndDate)) {
        item['publish_end_date'] = getDateFromExcelValue(publishEndDate, true);
      }
    } else {
      // New Item
      if(_.isNil(publishEndDate)) {
        item['publish_end_date'] = publishEndDate;
      } else {
        item['publish_end_date'] = getDateFromExcelValue(publishEndDate, true);
      }
    }

    // Hide
    const isHidden = data[12];
    if(itemId) {
      // Old Item
      if(!_.isNil(isHidden)) {
        if (_.isBoolean(isHidden)) {
          item['is_hidden'] = isHidden;
        } else {
          errors['errorList'].push(`${++errorsCount}. Invalid value for Is Hidden.`);
        }
      }
    } else {
      // New Item
      if(!_.isNil(isHidden)) {
        if (_.isBoolean(isHidden)) {
          item['is_hidden'] = isHidden;
        } else {
          errors['errorList'].push(`${++errorsCount}. Invalid value for Is Hidden.`);
        }
      } else {
        item['is_hidden'] = false;
      }
    }

    errors['errorsCount'] = errorsCount;
    if(errors['errorsCount'] > 0) {
      allErrors.push(errors);
    }
    items.push(item);
  }

  return {
    items,
    allErrors,
  };
};

const getFileUploadPath = ({
                             fileName,
                             selectedLanguage,
                           }) => {
  return `/${IMAGES_DIRECTORY_PREFIX}/${selectedLanguage}/${fileName}`;
};

const getUploadableFiles = async (
  files,
  selectedLanguage,
  ) => {
  const uploadableFiles = [];

  for(const file of files) {
    const encodedImage = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(e.target.result);
      fileReader.readAsDataURL(file);
    });
    const base64EncodedImage = encodedImage.replace('data:image/jpeg;base64,', '');

    // More info about adding meta data :
    // https://cloud.google.com/storage/docs/viewing-editing-metadata#storage-view-object-metadata-nodejs
    // https://cloud.google.com/storage/docs/uploading-objects
    const metadata = {
      contentType: 'image/jpeg',
      cacheControl: 'public, max-age=31536000',
    };

    const fileUploadPath = getFileUploadPath({
      fileName: file.name,
      selectedLanguage,
    });

    uploadableFiles.push({
      fileBuffer: base64EncodedImage,
      options: {
        metadata,
      },
      fileType: 'image',
      fileUploadPath,
      makePublic: true, // This is options, default is makePublic: true, kept as a API reference.
      clear_cdn_cache: true,
    });
  }

  return {
    uploadableFiles,
  };
};

const getSupportedLanguages = () => {
  return Object.keys(SUPPORTED_LANGUAGES).map((languageCode) => {
    return {
      key: SUPPORTED_LANGUAGES[languageCode],
      value: languageCode
    }
  });
};

export {
  getItems,
  getMetaInfo,
  isValidType,
  getLocalTime,
  getDataFromMeta,
  getArrayFromString,
  getUploadableFiles,
  isValidLanguageCode,
  getSupportedLanguages,
}
