import React from "react";
import QalboxImageUploader from "../../media/helperComponents/qalboxImageUploader";

const BrandImageBox = ({
  objectIdentifier,
  imageObject,
  handleInputTextChange,
}) => {
  return (
    <div>
      <QalboxImageUploader
        name={`${objectIdentifier}.small`}
        value={imageObject ? imageObject.small : ""}
        handleInputTextChange={handleInputTextChange}
      />
      <QalboxImageUploader
        name={`${objectIdentifier}.large`}
        value={imageObject ? imageObject.large : ""}
        handleInputTextChange={handleInputTextChange}
      />
    </div>
  );
};

export default BrandImageBox;
