import React,
{
  useState,
  Fragment,
  useEffect,
} from 'react';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  mailGunAddEmailTag,
  mailGunListEmailTags,
  mailGunDeleteEmailTag,
} from '../../hooks';
import ReactTags from 'react-tag-autocomplete';
import {
  getExistingTags,
} from './helper';
import {
  confirmAlert,
} from "react-confirm-alert";
import AppModal from '../../widgets/modal';
import {
  MPInput,
  OperationStatus,
} from '../../widgets/common';
const MailGunEmailTags = () => {

  const [ newTag, setNewTag ] = useState({});
  const [ fetchData, doFetch ] = mailGunListEmailTags();
  const [ deleteData, doDelete ] = mailGunDeleteEmailTag();
  const [ addData, doAdd ] = mailGunAddEmailTag();
  const isFetching = fetchData && fetchData.isFetching;
  const isDeleting = deleteData && deleteData.isDeleting;
  const isAdding = addData && addData.isUpdating;
  const tagsData = fetchData && fetchData.data;
  const existingTags = getExistingTags(tagsData && tagsData.items);
  const disableButton = !newTag.tag || isAdding || isDeleting;

  const onFetchComplete = operationData => {
    if(!operationData.isError) {
      toast.success(`Email tags fetch success!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onDeleteComplete = operationData => {
    if(!operationData.isError) {
      toast.success(`Email tag delete success!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      setTimeout(()=> {
        window.location.reload();
      }, 500);
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onAddComplete = operationData => {
    if(!operationData.isError) {
      toast.success(`Email tag add success!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      setTimeout(()=> {
        window.location.reload();
      }, 500);
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  useEffect(() => {
    doFetch({
      limit: 100,
      onOperationComplete: onFetchComplete,
    });
  }, []);

  const onClickDeleteButton = (tagToDelete) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={false}
            headingText={`Are you sure want to delete the tag : ${tagToDelete.tag} ?`}
            closeButtonText={'No'}
            okButtonText={`Yes, Delete`}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              doDelete({
                tag: tagToDelete.tag,
                onOperationComplete: onDeleteComplete,
              })
            }}
          />
        );
      }
    });
  };

  const onClickAddButton = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={false}
            headingText={`Are you sure want to add the tag : ${newTag.tag} ?`}
            closeButtonText={'No'}
            okButtonText={`Yes, Add`}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              doAdd({
                tag: newTag.tag,
                description: newTag.description,
                onOperationComplete: onAddComplete,
              })
            }}
          />
        );
      }
    });
  };

  const handleHashTagDelete = (i) => {
    const tagToDelete = existingTags[i];
    console.log('tagToDelete : ', tagToDelete);
    onClickDeleteButton(tagToDelete);
  }

  return (
    <BasicLayout pagePermission="write_users">
      <ToastContainer autoClose={2000}/>
      <Row>
        <Col md={8}>
          <h3 className='content_editor_title'>{'Email Tags'}</h3>
        </Col>
      </Row>
      {
        (isFetching || isDeleting || isAdding) ?
        <OperationStatus
          operationInProgressText={`Please wait! syncing email tags data...`}
        /> :
          <Fragment>
            <Row>
              <form className={'form'}>
                <Col md={8}>
                  <div className='form-group'>
                    <label style={{'marginRight': '5px'}}>New Tag*</label>
                    <MPInput
                      value={newTag.tag}
                      placeHolder={'New Tag'}
                      onChangeValue={(value) => {
                        setNewTag({
                          tag: value,
                          description: newTag.description,
                        })
                      }}
                    />
                  </div>
                  <div className='form-group'>
                    <label style={{'marginRight': '5px'}}>New Tag Description (Optional)</label>
                    <MPInput
                      required={false}
                      value={newTag.description}
                      placeHolder={'New Tag Description'}
                      onChangeValue={(value) => {
                        setNewTag({
                          tag: newTag.tag,
                          description: value,
                        })
                      }}
                    />
                  </div>
                  <Button
                    onClick={onClickAddButton}
                    disabled={disableButton}
                    className="btn-primary api-test-submit-button">
                    { isAdding ? 'Adding...' : 'Add' }
                  </Button>
                </Col>
              </form>
            </Row>
            <br/>
            <Row>
              <Col md={8}>
                <div className="form-group">
                  <label style={{'marginRight': '5px'}}>{'Existing Tags : '}</label>
                  <ReactTags
                    placeholder={'Existing Tags'}
                    tags={existingTags}
                    handleDelete={handleHashTagDelete}
                    onAddition={tag => console.log(tag)}
                  />
                </div>
              </Col>
            </Row>
          </Fragment>
      }
    </BasicLayout>
  );
};

export default MailGunEmailTags;
