import React from 'react';
import {
  Col,
  Row,
  Alert,
} from 'react-bootstrap';
import Collapse,
{
  Panel,
} from 'rc-collapse';
import {
  MPExpandIcon,
} from '../../widgets/common';

const getTimelinePromoInfo = ({
                                id,
                                name,
                              }) => {
  return (
      <table className={'table table-responsive'}>
        <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{id}</td>
          <td>{name}</td>
        </tr>
        </tbody>
      </table>
  );
};

const getDeleteTimelinePromosInfo = (bulkDeleteMap) => {
  return (
      <table className='table table-sm table-striped table-hover table-responsive'>
        <thead className='thead-dark'>
        <tr>
          <th scope='col'>#</th>
          <th scope='col'>Id</th>
          <th scope='col'>Name</th>
        </tr>
        </thead>
        <tbody>
        {
          Object.values(bulkDeleteMap).map((item, index) => {
            return (
                <tr key={index}>
                  <th scope='row'>{index + 1}</th>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                </tr>
            );
          })
        }
        </tbody>
      </table>
  );
};

const ExcelUploadInfoPanel = () => {
  const sampleExcelFileUrl = 'https://docs.google.com/spreadsheets/d/1MlflbPdwrz1sDALGYPMui4ZXcakC1zA7gQszgg3LhNA/edit?usp=sharing';

  return (
    <Row>
      <Col>
        <Alert>
          <strong style={{'fontSize': '20px'}}>
            Important Note (Please read before you upload):
          </strong>
          <br/>
          <Collapse
            accordion={true}
            className={'upload-note-collapse'}
            expandIcon={MPExpandIcon}
          >
            <Panel
              header="Content Upload Guide"
              headerClass="excel-upload-note-header"
              extra={<span>Click to see/hide Details</span>}
            >
              <ol>
                <li>
                  All fields that are marked with&nbsp;
                  <strong><u>required</u></strong>
                  should be available when uploading the file.
                </li>
                <li>
                  If you update an item you must include&nbsp;
                  <strong><u>Id</u></strong> of the item, also note that all the
                  values you enter in the excel will replace in the existing item.
                </li>
              </ol>
            </Panel>
          </Collapse>
          <label>See the Sample Excel Sheet : </label> &nbsp;
          <a href={sampleExcelFileUrl} target="_blank">
            Click to see the sample excel sheet.
          </a>
        </Alert>
      </Col>
    </Row>
  );
};

export {
  getTimelinePromoInfo,
  ExcelUploadInfoPanel,
  getDeleteTimelinePromosInfo,
}
