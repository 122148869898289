const DevOptOptions = Object.freeze( {
    PREMIUM_SUBSCRIPTION: 'Premium/Subscription Actions',
    QURAN: 'Quran Actions',
});

const DevOptSubOptions = Object.freeze({
    [DevOptOptions.PREMIUM_SUBSCRIPTION]: {
        MAKE_ACCOUNT_LIFE_TIME_PREMIUM: 'Make Account Lifetime Premium',
        MAKE_ACCOUNT_YEARLY_PREMIUM: 'Make Account Yearly Premium',
        MAKE_ACCOUNT_MONTHLY_PREMIUM: 'Make Account Monthly Premium',
        REVOKE_PREMIUM: 'Revoke Premium Status But Keep Purchases',
        WIPE_PREMIUM: 'Wipe Premium Status',
    },
    [DevOptOptions.QURAN]: {
        CLEAR_QURAN_BOOK_MARKS: 'Clear Quran Bookmarks',
        CLEAR_QURAN_CHECK_MARKS: 'Clear Quran Checkkmarks',
        CLEAR_QURAN_HIGHLIGHTS: 'Clear Quran Highlights',
    },
});

const DataLocationType = Object.freeze({
    NODE: 'NODE',
    KEY_START_WITH: 'KEY_START_WITH',
})

const DataLocations = Object.freeze({
    [DevOptOptions.PREMIUM_SUBSCRIPTION]: {
        type: DataLocationType.NODE,
        keyValue: 'premium_status',
    },
    [DevOptOptions.QURAN]: {
        type: DataLocationType.KEY_START_WITH,
        keyValue: 'saved_data>quran',
    }
});

export {
    DevOptOptions,
    DevOptSubOptions,
    DataLocations,
    DataLocationType,
};
