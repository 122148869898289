import _ from 'lodash';
import React,
{
  Fragment,
  useState,
} from 'react';
import {
  Icon,
} from 'react-fa';
import {
  getDisplayDate,
} from '../../utils';
import {
  Col,
  Row,
  Button,
  ControlLabel,
} from 'react-bootstrap';
import {
  ScaleLoader,
} from 'react-spinners';
import './style.css';
import JSONInput from "react-json-editor-ajrm/index";
import locale from 'react-json-editor-ajrm/locale/en';
import {
  JsonInputTypes,
} from './Constants';
import {
  getJsonInputErrorMsg,
  isValidJsonInputData,
  isValidJsonInputObject,
  getJsonInputFilteredValues,
  getInitialCheckedItemsState,
  getJsonInputPlaceHolderValue,
} from './helper';
import JSONPrettyMon from 'react-json-pretty/dist/adventure_time';
import JSONPretty from 'react-json-pretty';
import {
  CopyToClipboard,
} from 'react-copy-to-clipboard';
import ReactDatatime from 'react-datetime';

const jsonInputTypes = Object.keys(JsonInputTypes).map((option) => {
  return {
    key: option,
    value: JsonInputTypes[option]
  }
})

const LastUpdatedTime = ({
                           lastUpdatedTimeStamp,
                         }) => {
  if(!lastUpdatedTimeStamp) {
    return null
  }

  return (
      <p>
        {'Last updated time : '}&nbsp;
        <span className={'last-update-time'}>
          {getDisplayDate(lastUpdatedTimeStamp)}
        </span>
      </p>
  );
};

const ResultsCount = ({
                        results,
                        itemText = 'Contents',
                      }) => {
  if(_.isEmpty(results)) {
    return null
  }

  return (
      <p>
        {`${_.size(results)} ${itemText} Loaded.`}
      </p>
  );
};

const LoadingItems = ({
                        loading,
                        loadingText = 'Please wait. fetching contents...',
                      }) => {
  if(!loading) {
    return null;
  }

  return (
      <p className='content__list_loading_text'>
        <Icon spin name='spinner'/>&nbsp; {loadingText}
      </p>
  );
};

const OperationStatus = ({
 operationInProgressText = 'Please wait. Operation in progress!',
 loaderColor = '#097233', operationStatusData=null, showActionBtn=false, actionBtnText='Restart',
 actionBtnAction=() => console.log('Action button pressed!'),
 operationFailedMsg = 'Something went wrong while performing the operation.',
 operationFailedInfoMsg,
 operationSuccessMsg = 'Operation completed successfully!',
 }) => {
  if(operationStatusData) {
    return (
        <Row className={'operation-in-progress-main'}>
          <Col md={12} className={'text-center'}>
            <h3
                className={`operation-in-progress-title-${operationStatusData.isError ? 'error' : 'success'}`}>
              {operationStatusData.isError ? operationFailedMsg : operationSuccessMsg}
            </h3>
            {
              showActionBtn ?
                <Button
                    onClick={actionBtnAction}
                    className='margin-all3 btn btn-primary'
                >
                  <span className={'glyphicon glyphicon-repeat'} aria-hidden='true'/>
                  &nbsp;{actionBtnText}
                </Button> : null
            }
            {
              operationFailedInfoMsg && operationStatusData.isError ?
                  <p className={'operation-in-progress-info'}>
                    {operationFailedInfoMsg}
                  </p> : null
            }
          </Col>
        </Row>
    );
  }

  return (
      <Row className={'operation-in-progress-main'}>
        <Col md={12} className={'text-center'}>
          <h6 className={'operation-in-progress-title'}>
            {operationInProgressText}
          </h6>
          <ScaleLoader
              color={loaderColor}
          />
        </Col>
      </Row>
  );
};

const MPSelect = ({
                    items, selectedItem, onChangeSelect,
                    defaultValue='', className='form-control',
                    selectText='Please select one',
                  }) => {
  const options = items.map((item, i) => {
    return (
        <option value={item.key} key={i}>{item.value}</option>
    );
  });

  return (
      <select value={selectedItem}
              onChange={(e) => onChangeSelect(e.target.value)}
              className={className}
              key={defaultValue}>
        <option disabled value={defaultValue}>
          {selectText}
        </option>
        {options}
      </select>
  );
};

const MPMultiSelect = ({
                    items, selectedItem, onChangeSelect,
                    defaultValue='', className='form-control',
                    selectText='Please select one or more',
                  }) => {
  const options = items.map((item, i) => {
    return (
        <option value={item.key} key={i}>{item.value}</option>
    );
  });

  return (
      <select value={selectedItem}
              onChange={(e) => onChangeSelect(e.target.value)}
              className={className}
              key={defaultValue}
              multiple
              >
        <option disabled value={defaultValue}>
          {selectText}
        </option>
        {options}
      </select>
  );
};

const MPInput = ({
                   value, onChangeValue, placeHolder, min, max,
                   type='text',
                   className='',
                   labelClassName='',
                   inputClassName='form-control',
                   labelText='Input',
                   required=true, showLabel=false, inlineLabel=true, showLoader=false,
                   loadingText='Loading...',
                 }) => {
  return (
      <div className={`mp-input-main ${ showLabel && inlineLabel ? 'mp-input-main-inline-label' : ''} ${className}`}>
        {
          showLabel
              ? (
                  <label className={labelClassName} style={{ 'marginRight': '5px' }}>
                    {`${labelText} : `}
                  </label>
              )
              : null
        }
        <input value={value}
               required={required}
               onChange={(e) => onChangeValue(e.target.value)}
               type={type}
               className={inputClassName}
               placeholder={placeHolder}
               min={min}
               max={max}
        />
        {
          showLoader &&
          <p className="small text-success">
            <Icon spin name="spinner" />
            &nbsp; {loadingText}
          </p>
        }
      </div>
  );
};

const MPCheckBox = ({
                      type = 'checkbox',
                      name,
                      checked = false,
                      onChangeValue,
                    }) => {

  return (
      <input type={type}
             name={name}
             checked={checked}
             onChange={(e) => onChangeValue(e.target.checked)}
      />
  )
}

/**
 *
 *
 const checkboxes = [
 {
    id: 'check-box-1',
    name: 'Check Box 1',
  },
 {
    id: 'check-box-2',
    name: 'Check Box 2',
  },
 {
    id: 'check-box-3',
    name: 'Check Box 3',
  },
 {
    id: 'check-box-4',
    name: 'Check Box 4',
  },
 ];
 * @param checkBoxes
 * @param direction - Can be one of -> ['vertical', 'horizontal']
 * @returns {*}
 * @constructor
 */
const MpMultiCheckBox = ({
                           checkBoxes,
                           onUpdateValues=(values)=>console.log(values),
                           direction= 'vertical',
                         }) => {
  const [ checkedItems, setCheckedItems ] = useState(getInitialCheckedItemsState(checkBoxes));

  const onUpdateChecked = (isCheck, itemId) => {
    const updatedValues = {
      ...checkedItems,
      [itemId]: isCheck,
    };

    setCheckedItems(updatedValues)
    onUpdateValues(updatedValues)
  };

  const checkBoxesList = checkBoxes.map(item => (
      <div className={'mp-multi-checkbox'} key={item.id}>
        <label className={'mp-multi-checkbox-label'}>{item.name}</label>
        <MPCheckBox
            name={item.name}
            checked={checkedItems[item.id]}
            onChangeValue={(checked) => onUpdateChecked(checked, item.id)}
        />
      </div>
  ));

  return (
      <div className={`mp-multi-checkbox-main mp-multi-checkbox-${direction}`}>
        {checkBoxesList}
      </div>
  );
}

const MPSingleKeyJsonInput = ({
                                theme='darktheme',
                                color='#DAA520',
                                onUpdateValue=(value)=>console.log(value), height='400px',
                              }) => {
  const [ state, setState] = useState({
    type: '',
    isValidKey: true,
    isValidData: true,
    updatedData: null,
  });
  const {
    type,
    isValidKey,
    isValidData,
    updatedData,
  }  = state;

  const placeHolderValue = getJsonInputPlaceHolderValue(type);
  const inputErrMsg = getJsonInputErrorMsg(isValidKey, isValidData, type, updatedData);

  const onUpdateData = (updateData) => {
    const updatedJsonValues = updateData['jsObject'];
    setState({
      ...state,
      isValidKey: isValidJsonInputObject(updateData, type),
      isValidData: isValidJsonInputData(updateData, type),
      updatedData: updatedJsonValues,
    })

    if(isValidKey && isValidData) {
      const filteredValues = getJsonInputFilteredValues(updatedJsonValues);
      onUpdateValue(filteredValues);
    } else {
      onUpdateValue(null);
    }
  }

  const onResetType = () => {
    setState({
      type: '',
      isValidKey: true,
      isValidData: true,
      updatedData: null,
    })
  };

  return (
      <div className={'mp-json-input'}>
        <div className={'mp-json-input-type-select'}>
          <label style={{'marginRight': '5px'}}> Type: </label>
          <MPSelect
              items={jsonInputTypes}
              selectedItem={type}
              onChangeSelect={(value) => {
                setState({
                  type: value,
                  isValidKey: true,
                  isValidData: true,
                  updatedData: null,
                })
              }}
          />
        </div>
        {
          inputErrMsg ?
              <div className={'mp-json-input-type-key-error'}>
                <label className={'mp-json-input-type-key-error-msg'}>
                  {inputErrMsg}
                </label>
                {
                 !isValidKey ?
                     <Button
                        onClick={onResetType}
                        className='btn-primary btn-xs'
                        >
                        <span className={'glyphicon glyphicon-repeat'} aria-hidden='true'/>
                        &nbsp;{'Reset'}
                     </Button> : null
                }
              </div> : null
        }
        {
          type ?
              <JSONInput
                  placeholder={{...placeHolderValue}} // data to display
                  theme={theme}
                  locale={locale}
                  colors={{
                    string: {color} // overrides theme colors with whatever color value you want
                  }}
                  onChange={onUpdateData}
                  height={height}
              /> : null
        }
      </div>
  );
};

const MPCopyToClipBoard = ({
                             data,
                             copyBtnText='',
                             onCopyData= (data) => console.log(`Copied : ${data}`),
                           }) => {
  return (
      <CopyToClipboard
          text={JSON.stringify(data)}
          onCopy={onCopyData}
      >
        <div className={'copy-single-errors-list'}>
          <p className={'copy-single-errors-list-text'}>
            {copyBtnText}
          </p>
          <Button className="btn btn-xs btn-dark">
            &nbsp;<span className="glyphicon glyphicon-duplicate" aria-hidden="true"/>
          </Button>
        </div>
      </CopyToClipboard>
  );
}

const ShowJSONData = ({
                        id='json-data',
                        data,
                        theme=JSONPrettyMon,
                        showCopyBtn=true,
                        onJSONError=error => console.error(error),
                      }) => {
  return (
      <div className={'mp-show-json-main'} >
        {
          showCopyBtn ?
              <div className={'mp-show-json-copy-btn'}>
                <MPCopyToClipBoard data={data} copyBtnText={'Copy JSON'} />
              </div> : null
        }
        <div className={'show-json-data-main'}>
          <JSONPretty
              id={id}
              data={data}
              theme={theme}
              onJSONPrettyError={onJSONError}
          />
        </div>
      </div>
  );
};


const MPDataTimeInput = (config) => {
  const [ enabledEdit,  setEnableEdit ] = useState(false);

  const onChangeDate = (value) => {
    if(_.isFunction(config.onChange)) {
      config.onChange(value);
    }

    setEnableEdit(false);
  };

  if(config.showEditLabel) {
    const updatedConfig = {
      ...config,
      onChange: onChangeDate,
    }

    return (
        <Fragment>
          {
            enabledEdit ?
              <ReactDatatime
                  {...updatedConfig}
              /> :
              <span
                  onClick={setEnableEdit}
                  className="mp-date-time-input-edit">{config.value} &nbsp;
                <Icon className={'mp-date-time-input-edit-icon'} name="edit"/>
              </span>
          }
        </Fragment>
    );
  }

  return <ReactDatatime {...config}/>;
};


const arrowPath = 'M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88' +
    '.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.' +
    '6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-0.7 5.' +
    '2-2L869 536.2c14.7-12.8 14.7-35.6 0-48.4z';

const MPExpandIcon = ({
                        isActive,
                      }) => {
  return (
      <i style={{ marginRight: '.5rem' }}>
        <svg
            viewBox="0 0 1024 1024"
            width="1em"
            height="1em"
            fill="currentColor"
            style={{
              verticalAlign: '-.125em',
              transition: 'transform .2s',
              transform: `rotate(${isActive ? 90 : 0}deg)`,
            }}
        >
          <path d={arrowPath} p-id="5827"></path>
        </svg>
      </i>
  );
}

const MPRangeInput = ({
                        onChangeRange,
                        type='text',
                        className='',
                        firstValueLabelText='First',
                        secondValueLabelText='Second',
                        firstValuePlaceHolder,
                        secondValuePlaceHolder,
                        firstInitialValue='',
                        secondInitialValue='',
                        required=true,
                        showLabels=false,
                    }) => {

  const [ firstValue, setFirstValue ] = useState(firstInitialValue);
  const [ secondValue, setSecondValue ] = useState(secondInitialValue);

  const onChangeFirstValue = value => {
    setFirstValue(value);
    onChangeRange({
      firstValue: value,
      secondValue,
    });
  };

  const onChangeSecondValue = value => {
    setSecondValue(value);
    onChangeRange({
      firstValue,
      secondValue: value,
    });
  };

  return (
      <div className={`mp-range-input ${className}`}>
        <MPInput
            type={type}
            value={firstValue}
            placeHolder={firstValuePlaceHolder}
            onChangeValue={onChangeFirstValue}
            required={required}
            showLabel={showLabels}
            labelText={firstValueLabelText}
        />
        <MPInput
            type={type}
            value={secondValue}
            placeHolder={secondValuePlaceHolder}
            onChangeValue={onChangeSecondValue}
            required={required}
            showLabel={showLabels}
            labelText={secondValueLabelText}
        />
      </div>
  );
};

const MPControlLabel = (props) => {
  const {
    success,
    children,
    optional,
  } = props;

  let statusText = success ? 'check-circle' : 'question-circle';
  statusText = optional ? null : statusText;
  const optionalComp = optional ? <span>(Optional)</span> : null;
  const statusClass = success ? 'text-success' : 'text-danger';
  return <ControlLabel className="block">{children} <Icon className={`mp_control_label_status_icon ${statusClass}`} name={statusText} />{optionalComp}</ControlLabel>
};

export {
  ResultsCount,
  LastUpdatedTime,
  LoadingItems,
  OperationStatus,
  MPSelect,
  MPInput,
  MPRangeInput,
  MPCheckBox,
  MpMultiCheckBox,
  MPMultiSelect,
  MPSingleKeyJsonInput,
  MPDataTimeInput,
  MPCopyToClipBoard,
  MPControlLabel,
  ShowJSONData,
  MPExpandIcon,
}
