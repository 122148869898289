import React from "react";
import QalboxCopyInputBox from "../../media/helperComponents/qalboxCopyInputBox";

const QalboxBrandWebsite = ({ website = {in_app: false, link: "" }, onChange }) => {
  const handleInputTextChange = (e) => {
    let { name, value } = e.target;

    if (!website) {
        website = {};
    }

    const newWebsite = {...website, [name]: value}

    onChange(newWebsite);
  };

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;

    if (!website) {
        website = {};
    }

    const newWebsite = {...website, in_app: checked}
    
    onChange(newWebsite);
  };

  return (
    <div>
      <div className="form-group">
        <label>In App</label>
        <input
          type="checkbox"
          name="in_app"
          checked={website?.in_app}
          onChange={handleCheckboxChange}
          style={{ transform: "scale(1.5)", marginLeft: "10px" }}
        />
      </div>
      <div className="form-group">
        <label>Website Link</label>
        <QalboxCopyInputBox
          name={`link`}
          value={website?.link}
          handleInputTextChange={handleInputTextChange}
        />
      </div>
    </div>
  );
};

export default QalboxBrandWebsite;