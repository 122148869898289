import React from 'react';
import './style.css';

const SubOption = ({
                       subOption,
                       subOptionText,
                       onClickSubOption,
                   }) => {

    const onClickOption = () => {
        onClickSubOption({
            subOption,
        });
    };

    return (
        <div className={'dev-sub-opt'}
             role={'button'}
             aria-pressed={'false'}
             onClick={onClickOption}
        >
            <div className={'text-center'}>
                {subOptionText}
            </div>
        </div>
    );
};

export default SubOption;
