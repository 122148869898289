import _ from 'lodash';
import React,
{
  Fragment,
  useReducer,
} from 'react';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import ToggleButton from 'react-toggle-button';
import {
  timelinePromoCreateReducer,
} from './Reducers';
import './style.css';
import {
  ColorBlockPicker,
} from '../../widgets/color-picker';
import {
  MPDataTimeInput,
} from '../../widgets/common';
import {
  actionTypes,
} from './actionTypes';
import {
  getCountriesList,
  getPlatformsList,
  getOperationButtonText,
  shouldDisableCreateButton,
  getTimeLinePromosForEditUpdate,
} from './helper';
import Select from 'react-select';
import {
  countries as CountryList,
} from '../../services/utils';
import FileUploader from '../../widgets/fileUploader';
import {
  crudTimelinePromosHook,
} from '../../hooks';
import {
  confirmAlert,
} from "react-confirm-alert";
import AppModal from '../../widgets/modal';
import {
  getTimelinePromoInfo,
} from './common';
import {
  PLATFORMS,
} from '../../Constants';
import {
  CopyToClipboard,
} from 'react-copy-to-clipboard';

const countryOptions = Object.keys(CountryList).map((countryCode) => {
  return {
    value: countryCode,
    label: CountryList[countryCode]
  }
});

const platformTypes = Object.keys(PLATFORMS).map((platform) => {
  return {
    value: platform,
    label: PLATFORMS[platform],
  }
});

let InitialState = {
  name: '',
  id: null,
  start: '',
  end: '',
  selected_countries: [],
  countries: [],
  platforms: [{'value':'all','label':'ALL'}],
  banner_url: '',
  action_button_text: '',
  action_button_text_color: '#FFFFFF',
  action_url: '',
  action_button_background: '#097233',
  close_button_color: '#000000',
  hide_for_premium: true,
  hide_banner: false,
  hide_cta: false,
  uploadedFile: null,
  optimizeImages: false,
  resetColors: false,
  countrySelectPolicy: "select"
};

const CreateTimelinePromo = ({ history }) => {
  let existingTimelinePromo = null;
  let create = true;
  let existingTimelinePromoInitialState = {};

  if(history) {
    const { state } = history.location;
    existingTimelinePromo = state ? state.existingTimelinePromo : null;
  }

  if(!_.isEmpty(existingTimelinePromo)) {
    create = false;
    existingTimelinePromoInitialState = {
      ...InitialState,
      ...existingTimelinePromo,
      countries: getCountriesList(existingTimelinePromo, countryOptions),
      platforms: getPlatformsList(existingTimelinePromo, countryOptions),
    };
  }

  const[ state, dispatch ]  = useReducer(timelinePromoCreateReducer,
      create ? InitialState: existingTimelinePromoInitialState);

  const {
    name, id, banner_url, action_button_text, action_url, hide_for_premium, optimizeImages,
    action_button_text_color, action_button_background, close_button_color, countries, selected_countries,
    uploadedFile, hide_banner, end, start, resetColors, hide_cta,
    platforms,
  } = state;

  const imageFileData = {
    imageFile: uploadedFile,
    optimizeImages: optimizeImages,
  };

  const onCompleteOperation = operationData => {
    if(!operationData.isError) {
      toast.success(`Timeline Promo ${create ? 'created' : 'updated'} successfully!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(`Timeline Promo  ${create ? 'creation' : 'update'} failed!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }

    if(create) {
      setTimeout(()=> {
        window.location.reload();
      }, 1000);
    }
  };

  const [ timelinePromosOperationData, startOperation ] = crudTimelinePromosHook();

  const operationInProgress = timelinePromosOperationData
      && timelinePromosOperationData.operationInProgress;
  const btnText = getOperationButtonText(create, operationInProgress)

  const uploadedImageUrl = uploadedFile ? URL.createObjectURL(uploadedFile) : null;
  const disableCreateBtn = shouldDisableCreateButton(state, uploadedImageUrl);

  const resetState = () => {
    dispatch({
      type: actionTypes.TPC_RESET,
      payload: create ? InitialState: existingTimelinePromoInitialState,
    })
  };

  const onClickTimelinePromoSubmit = (create) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <AppModal
                show={true}
                showBody={true}
                headingText={`Are you sure want to ${create ? 'create' : 'update'} the following Timeline Promo ?`}
                modalBody={getTimelinePromoInfo({ id, name })}
                modalBodyClassName={'precalcs-diff-merge-view'}
                closeButtonText={'No'}
                okButtonText={`Yes, ${create ? 'Create' : 'Update'}`}
                handleClose={onClose}
                handleOk={() => {
                  onClose();
                  startOperation({
                    timelinePromos: getTimeLinePromosForEditUpdate(state),
                    imageFileData,
                    operation: create ? 'create' : 'update',
                    onCompleteOperation,
                  })
                }}
            />
        );
      }
    });
  };

  return (
      <BasicLayout pagePermission="readonly">
        <ToastContainer autoClose={2000} />
        <Row>
          <Col md={12}>
            <h3 className='content_editor_title'>
              {
                existingTimelinePromo ? `Edit Timeline Promo - ${existingTimelinePromo.name}`
                    : 'Create  Timeline Promo'
              }
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <form>
              {
                id &&
                <div className='form-group'>
                  <label>Id : </label> &nbsp;
                  <Fragment>
                    <span style={{ color: '#00722d' }}>{id}</span> &nbsp;
                    <CopyToClipboard
                      text={id}
                      onCopy={() => {
                        console.log(`Copied id : ${id}`)
                      }}
                    >
                      <Button className="btn btn-xs btn-dark mp-content-v2-table-hover-btn">
                        &nbsp;<span className="glyphicon glyphicon-duplicate" aria-hidden="true"/>
                      </Button>
                    </CopyToClipboard>
                  </Fragment>
                </div>
              }
              <div className='form-group'>
                <label>Name</label>
                <input value={name}
                       required
                       onChange={
                         (e) => dispatch({
                           type: actionTypes.TPC_UPDATE_PROP_VAL,
                           payload: {
                             prop: 'name',
                             value: e.target.value,
                           },
                         })
                       }
                       type='text'
                       className='form-control'
                       placeholder='Name'
                />
              </div>
              <FileUploader
                  onUploadFile={
                    (e) => dispatch({
                      type: actionTypes.TPC_ON_UPLOAD_IMAGE,
                      payload: e.target.files[0],
                    })
                  }
                  fileDeleted={!uploadedImageUrl}
                  labelName={'Upload Banner Image'}
                  placeHolderText={'Banner Image'}
              />
              <div className='form-group'>
                <label>Banner URL</label>
                <input value={banner_url}
                       required
                       onChange={
                         (e) => dispatch({
                           type: actionTypes.TPC_UPDATE_PROP_VAL,
                           payload: {
                             prop: 'banner_url',
                             value: e.target.value,
                           },
                         })
                       }
                       type='text'
                       className='form-control'
                       placeholder='Banner URL'
                />
              </div>
              <div className='form-group'>
                <label>Action Button Text</label>
                <input value={action_button_text}
                       required
                       onChange={
                         (e) => dispatch({
                           type: actionTypes.TPC_UPDATE_PROP_VAL,
                           payload: {
                             prop: 'action_button_text',
                             value: e.target.value,
                           },
                         })
                       }
                       type='text'
                       className='form-control'
                       placeholder='Action Button Text'
                />
              </div>
              <div className='form-group'>
                <label>Action Button URL</label>
                <input value={action_url}
                       required
                       onChange={
                         (e) => dispatch({
                           type: actionTypes.TPC_UPDATE_PROP_VAL,
                           payload: {
                             prop: 'action_url',
                             value: e.target.value,
                           },
                         })
                       }
                       type='text'
                       className='form-control'
                       placeholder='Action Button URL'
                />
              </div>
              <div className='form-group'>
                <label>Action Button Text Color</label>
                <ColorBlockPicker
                    resetState={resetColors}
                    initialColor={action_button_text_color}
                    onColorChange={
                      (color) => dispatch({
                        type: actionTypes.TPC_UPDATE_PROP_VAL,
                        payload: {
                          prop: 'action_button_text_color',
                          value: color.hex,
                        },
                      })
                    }
                    defaultColors={['#FFFFFF', '#000000', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF']}
                />
              </div>
              <div className='form-group'>
                <label>Action Button Background Color</label>
                <ColorBlockPicker
                    resetState={resetColors}
                    initialColor={action_button_background}
                    onColorChange={
                      (color) => dispatch({
                        type: actionTypes.TPC_UPDATE_PROP_VAL,
                        payload: {
                          prop: 'action_button_background',
                          value: color.hex,
                        },
                      })
                    }
                    defaultColors={['#097233', '#EB3E3E', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF']}
                />
              </div>
              <div className='form-group'>
                <label>Close Button Color</label>
                <ColorBlockPicker
                    resetState={resetColors}
                    initialColor={close_button_color}
                    onColorChange={
                      (color) => dispatch({
                        type: actionTypes.TPC_UPDATE_PROP_VAL,
                        payload: {
                          prop: 'close_button_color',
                          value: color.hex,
                        },
                      })
                    }
                    defaultColors={['#FFFFFF', '#000000', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF']}
                />
              </div>
              <div className='form-group'>
                <label>Platform</label>
                <Select
                    onChange={
                      (selectedPlatforms) => dispatch({
                        type: actionTypes.THC_UPDATE_AUDIENCES,
                        payload: selectedPlatforms,
                      })
                    }
                    value={platforms}
                    isSearchable={true}
                    options={platformTypes}
                    isMulti
                />
              </div>
              <div className='form-group'>
                <label>Countries</label>
                <div onChange={ (event) => dispatch({
                    type: actionTypes.TPC_UPDATE_COUNTRIES,
                  payload: {
                    countrySelectPolicy: event.target.value,
                    countries: state.selected_countries
                  },
                  })
                }>
                  <input
                    type="radio"
                    value="select"
                    name="countries-select-policy"
                    checked={state.countrySelectPolicy == 'select'}
                    defaultChecked
                  /><label style={{ paddingRight: 10}}>Select</label>
                  <input
                    type="radio"
                    value="exclude"
                    name="countries-select-policy"
                    checked={state.countrySelectPolicy == 'exclude'}
                  /><label>Exclude</label>
                </div>
                <Select onChange={
                        (selectedCountries) => dispatch({
                          type: actionTypes.TPC_UPDATE_COUNTRIES,
                          payload: {
                            countrySelectPolicy: state.countrySelectPolicy,
                            countries: selectedCountries
                          },
                        })}
                        value={selected_countries}
                        isSearchable={true}
                        options={countryOptions}
                        isMulti
                />
              </div>
              <div className='form-group'>
                <label>Start Date</label>
                <MPDataTimeInput
                    className={'timeline-promo-date'}
                    showToday
                    value={start}
                    dateFormat={'D-M-YYYY'}
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={
                      (data) => dispatch({
                        type: actionTypes.TPC_UPDATE_PROP_VAL,
                        payload: {
                          prop: 'start',
                          value: data,
                        },
                      })
                    }
                />
              </div>
              <div className='form-group'>
                <label>End Date</label>
                <MPDataTimeInput
                    className={'timeline-promo-date'}
                    showToday
                    value={end}
                    dateFormat={'D-M-YYYY'}
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={
                      (data) => dispatch({
                        type: actionTypes.TPC_UPDATE_PROP_VAL,
                        payload: {
                          prop: 'end',
                          value: data,
                        },
                      })
                    }
                />
              </div>
              <div className="form-group">
                <label className="show__ads_label">Hide for Premium</label>
                <div>
                  <ToggleButton
                      value={hide_for_premium}
                      onToggle={
                        () => dispatch({
                          type: actionTypes.TPC_TOGGLE_PROP_VAL,
                          payload: {
                            prop_item: 'hide_for_premium',
                          },
                        })
                      }
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="show__ads_label">Hide CTA</label>
                <div>
                  <ToggleButton
                      value={hide_cta}
                      onToggle={
                        () => dispatch({
                          type: actionTypes.TPC_TOGGLE_PROP_VAL,
                          payload: {
                            prop_item: 'hide_cta',
                          },
                        })
                      }
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="show__ads_label">Hide Banner</label>
                <div>
                  <ToggleButton
                      value={hide_banner}
                      onToggle={
                        () => dispatch({
                          type: actionTypes.TPC_TOGGLE_PROP_VAL,
                          payload: {
                            prop_item: 'hide_banner',
                          },
                        })
                      }
                  />
                </div>
              </div>
              <div className={'form-group'}>
                <Button
                    disabled={disableCreateBtn || operationInProgress}
                    onClick={() => onClickTimelinePromoSubmit(create)}
                    className='btn-primary ad_units_submit_btn'
                >
                  {btnText}
                </Button>
                <Button
                    disabled={operationInProgress}
                    onClick={resetState}
                    className='btn-primary ad_units_reset_btn'
                >
                  {'Reset'}
                </Button>
              </div>
            </form>
          </Col>
          <Col md={6}>
            {
              uploadedImageUrl || banner_url ?
                  <div className='timeline_promo_preview_main'>
                    <h4>{name}</h4>
                    <div className={'timeline_promo_preview'}>
                      <img src={uploadedImageUrl || banner_url} alt='' width='100%'/>
                      {
                        !hide_cta && action_button_text ?
                            <a href={action_url} target={'_black'}
                               className={'tp-action-btn'}
                               style={{
                                 color: `${action_button_text_color}`,
                                 background: `${action_button_background}`
                               }}
                            >
                              {action_button_text}
                            </a> : null
                      }
                      <a className={'tp-close-btn'}
                         style={{
                           color: `${close_button_color}`
                         }}
                      >
                        {'X'}
                      </a>
                    </div>
                    {
                      uploadedImageUrl ?
                          <div className={'upload-tools'}>
                            <button onClick={
                                      () => dispatch({
                                        type: actionTypes.TPC_REMOVE_IMAGE,
                                      })
                                    }
                                    type={'button'}
                                    className="btn-danger btn-sm">
                                <span
                                    className="glyphicon glyphicon-trash"
                                    aria-hidden="true"
                                />
                              &nbsp;{'Reset Image'}
                            </button>
                            <span>
                                  <label className={'content__list_options_bar_label'}>
                                    {'Optimize Image : '}
                                    <input
                                        className={'content__list_options_bar_input'}
                                        type="checkbox"
                                        checked={optimizeImages}
                                        onChange={
                                          (e) => dispatch({
                                            type: actionTypes.TPC_TOGGLE_OPTIMISE_IMAGE,
                                            payload: e.target.checked,
                                          })
                                        }
                                    />
                                  </label>
                               </span>
                          </div> : null
                    }
                  </div> : null
            }
          </Col>
        </Row>
      </BasicLayout>
  );
};

export default CreateTimelinePromo;
