import mp_service_v1_api from '../../apis/mp_service_v1_api';
import {
  HAJJ_AND_UMARAH_API_ADD_UPDATE_URL,
  HAJJ_AND_UMARAH_API_DELETE_URL,
  MPAC_UPLAOD_FILES_TO_GCS_URL,
  HAJJ_AND_UMARAH_API_GET_RAW_URL,
  PRECALC_API_GENERATE_URL,
  PRECALC_API_VALIDATE_URL,
  PRECALC_API_GET_RAW_PRECALC_LIST_URL,
  PRECALC_API_UPDATE_RAW_PRECALC_LIST_URL,
  PRECALC_API_TEST_API_RESPONSE_URL,
  PRECALC_API_UPDATE_COUNTRY_RAW_PRECALC_LIST_URL,
  V2_REDIS_GET_INFO,
  V2_REDIS_DELETE_KEY,
  PRECALC_API_GET_PRECALC_DATA_BY_LAT_LONG,
  PRECALC_API_LIST_FILE_VERSIONS,
  PRECALC_API_UPDATE_PRECALC_FILE,
  TIMELINE_PROMOS_API_ADD_UPDATE_URL,
  TIMELINE_PROMOS_API_GET_RAW_URL,
  TIMELINE_PROMOS_API_DELETE_URL,
  SETTINGS_API_GET_RAW_FILE,
  SETTINGS_API_UPDATE_RAW_FILE,
  SETTINGS_API_GET_FILE_VERSIONS,
  SETTINGS_API_UPDATE_FILE_TIMESTAMP,
  SETTINGS_API_UPDATE_FILE_KEY_VALUES,
  SETTINGS_API_TEST_SETTINGS_API_RESPONSE,
  HIJRI_API_GET_RAW_FILE,
  HIJRI_API_UPDATE_RAW_FILE,
  HIJRI_API_GET_FILE_VERSIONS,
  QURAN_API_GET_RAW_FILE,
  QURAN_API_UPDATE_RAW_FILE,
  BIGQUERY_API_GET_ORDERED_HASH_TAGS,
  BIGQUERY_API_GET_USER_LOGS_ROW_COUNT,
  BIGQUERY_API_DELETE_USER_LOGS,
  BIGQUERY_API_GET_USER_LOGS,
} from '../../Constants';
import {
  getAPIV1At,
} from '../../utils';

const callMuslimProAPI = async ({
                                  data,
                                  url,
                                  method = 'GET',
                                }) => {
  const apiKey = await getAPIV1At();
  return mp_service_v1_api(apiKey, {
    method,
    url,
    data,
  });
};

const uploadImagesToGCSBucket = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: MPAC_UPLAOD_FILES_TO_GCS_URL,
    data,
  });
};

const hajjAndUmrahAddOrUpdate = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: HAJJ_AND_UMARAH_API_ADD_UPDATE_URL,
    data,
  });
};

const hajjAndUmrahGetRawJSON = async (data) => {
  return callMuslimProAPI({
    method: 'GET',
    url: HAJJ_AND_UMARAH_API_GET_RAW_URL,
    data,
  });
};

const hajjAndUmrahDelete = async (data) => {
  return callMuslimProAPI({
    method: 'DELETE',
    url: HAJJ_AND_UMARAH_API_DELETE_URL,
    data,
  });
};

const generatePrecalcs = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: PRECALC_API_GENERATE_URL,
    data,
  });
};

const validatePrecalcs = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: PRECALC_API_VALIDATE_URL,
    data,
  });
};

const getRawPrecalcsList = async () => {
  return callMuslimProAPI({
    method: 'GET',
    url: PRECALC_API_GET_RAW_PRECALC_LIST_URL,
  });
};

const getPrecalcDataByLatLong = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: PRECALC_API_GET_PRECALC_DATA_BY_LAT_LONG,
    data,
  });
};

const updatePrecalcsList = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: PRECALC_API_UPDATE_RAW_PRECALC_LIST_URL,
    data,
  });
};

const updateCountryPrecalcsList = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: PRECALC_API_UPDATE_COUNTRY_RAW_PRECALC_LIST_URL,
    data,
  });
};

const testPrecalcsAPIResponse = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: PRECALC_API_TEST_API_RESPONSE_URL,
    data,
  });
};

const listPrecalcFileVersions = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: PRECALC_API_LIST_FILE_VERSIONS,
    data,
  });
};

const updatePrecalcFile = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: PRECALC_API_UPDATE_PRECALC_FILE,
    data,
  });
};

const deleteV2RedisKey = async (data) => {
  return callMuslimProAPI({
    method: 'DELETE',
    url: V2_REDIS_DELETE_KEY,
    data,
  });
};

const getV2RedisInfo = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: V2_REDIS_GET_INFO,
    data,
  });
};

const timelinePromoAddOrUpdate = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: TIMELINE_PROMOS_API_ADD_UPDATE_URL,
    data,
  });
};

const timelinePromoGetRawJSON = async (data) => {
  return callMuslimProAPI({
    method: 'GET',
    url: TIMELINE_PROMOS_API_GET_RAW_URL,
    data,
  });
};

const timelinePromoDelete = async (data) => {
  return callMuslimProAPI({
    method: 'DELETE',
    url: TIMELINE_PROMOS_API_DELETE_URL,
    data,
  });
};

const settingsGetRawFile = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: SETTINGS_API_GET_RAW_FILE,
    data,
  });
};

const settingsGetFileVersions = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: SETTINGS_API_GET_FILE_VERSIONS,
    data,
  });
};

const settingsUpdateRawFile = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: SETTINGS_API_UPDATE_RAW_FILE,
    data,
  });
};

const settingsUpdateFileTimestamp = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: SETTINGS_API_UPDATE_FILE_TIMESTAMP,
    data,
  });
};

const settingsUpdateFileKeyValues = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: SETTINGS_API_UPDATE_FILE_KEY_VALUES,
    data,
  });
};

const settingsTestSettingsAPIResponse = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: SETTINGS_API_TEST_SETTINGS_API_RESPONSE,
    data,
  });
};

const hijriGetRawJSON = async (data) => {
  return callMuslimProAPI({
    url: HIJRI_API_GET_RAW_FILE,
    data,
  });
};

const hijriUpdateRawJSON = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: HIJRI_API_UPDATE_RAW_FILE,
    data,
  });
};

const hijriGetFileVersions = async (data) => {
  return callMuslimProAPI({
    url: HIJRI_API_GET_FILE_VERSIONS,
    data,
  });
};

const quranQuotesGetRawFile = async (data) => {
  return callMuslimProAPI({
    url: QURAN_API_GET_RAW_FILE,
    data,
  });
};

const quranQuotesUpdateRawFile = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: QURAN_API_UPDATE_RAW_FILE,
    data,
  });
};

const bigqueryGetOrderedHashtags = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: BIGQUERY_API_GET_ORDERED_HASH_TAGS,
    data,
  });
};

const bigqueryGetUserLogsRowCount = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: BIGQUERY_API_GET_USER_LOGS_ROW_COUNT,
    data,
  });
};

const bigqueryDeleteUserLogs = async (data) => {
  return callMuslimProAPI({
    method: 'DELETE',
    url: BIGQUERY_API_DELETE_USER_LOGS,
    data,
  });
};

const bigqueryGetUserLogs = async (data) => {
  return callMuslimProAPI({
    method: 'POST',
    url: BIGQUERY_API_GET_USER_LOGS,
    data,
  });
};

export {
  uploadImagesToGCSBucket,
  hajjAndUmrahGetRawJSON,
  hajjAndUmrahAddOrUpdate,
  hajjAndUmrahDelete,
  generatePrecalcs,
  validatePrecalcs,
  getRawPrecalcsList,
  getPrecalcDataByLatLong,
  updatePrecalcsList,
  updateCountryPrecalcsList,
  testPrecalcsAPIResponse,
  listPrecalcFileVersions,
  updatePrecalcFile,
  getV2RedisInfo,
  deleteV2RedisKey,
  timelinePromoAddOrUpdate,
  timelinePromoGetRawJSON,
  timelinePromoDelete,
  settingsGetRawFile,
  settingsGetFileVersions,
  settingsUpdateRawFile,
  settingsUpdateFileTimestamp,
  settingsUpdateFileKeyValues,
  settingsTestSettingsAPIResponse,
  hijriGetRawJSON,
  hijriUpdateRawJSON,
  hijriGetFileVersions,
  quranQuotesGetRawFile,
  quranQuotesUpdateRawFile,
  bigqueryGetOrderedHashtags,
  bigqueryGetUserLogsRowCount,
  bigqueryDeleteUserLogs,
  bigqueryGetUserLogs,
}
