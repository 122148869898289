import React,
{
  Fragment,
  Component,
} from 'react';
import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import ReactJson from 'react-json-view'
import {
  MPDataTimeInput,
} from '../../widgets/common';
import {
  testPrecalcsAPIResponse,
} from '../../services/mp-service-v1-api-service';
import './style.css';
import {
  fileNameRegex,
} from './Constants';
import {
  LastUpdatedTime,
  PrecalcTimePannel,
} from './components';
import moment from 'moment-timezone';
import {
  CopyToClipboard,
} from 'react-copy-to-clipboard';

const InitialState = {
  submitting: false,
  fileName: '',
  apiResponseData: null,
  selectedDate: null,
};

class TestPrecalcApi extends Component {
  constructor(props) {
    super(props);
    this.state = InitialState;
  }

  async handleSubmit(e) {
    console.log(JSON.stringify(this.state));
    e.preventDefault();
    this.setState({ submitting : true });

    const {
      fileName,
    } =  this.state;

    const apiPayload = {
      'id': fileName,
    };

    try {
      console.log('apiPayload : ', JSON.stringify(apiPayload));
      if (apiPayload) {
        const precalcApiResp =  await testPrecalcsAPIResponse(apiPayload);

        const responseData = precalcApiResp.data;
        console.log('responseData : ', JSON.stringify(responseData));
        if(responseData) {
          console.log('success');
          toast.success('Operation completed!', {
            position: toast.POSITION.BOTTOM_LEFT
          });
          this.setState({
            submitting: false,
            apiResponseData: {
              responseData,
              success: true,
              reason: null,
            },
          });
        } else {
          console.log(`Received error API Response . ${JSON.stringify(precalcApiResp)}`);
          toast.error(`Something went wrong!, Please try again.`, {
            position: toast.POSITION.BOTTOM_LEFT
          });
          this.setState({
            submitting: false,
          });
        }
      }
    } catch (err) {
      console.log(`Something went wrong while trying to get the API response. Error :  ${JSON.stringify(err)}`);
      if(err.response && err.response.status === 404) {
        this.setState({
          submitting: false,
          apiResponseData: {
            responseData: null,
            success: false,
            reason: 'File not found!'
          },
        });
        toast.error(`File not found, Check the file name again.`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
      } else {
        toast.error(`Something went wrong!, Please try again.`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
        this.setState({ submitting: false });
      }
    }
  }

  renderSubmitButton(submitting, validFileName) {
    return (
        <Button
            type="submit"
            disabled={submitting || !validFileName}
            className="btn-primary api-test-submit-button">
          { submitting ? 'Testing...' : 'Test' }
        </Button>
    );
  }

  onFieldChange(fieldKey, e) {
    const fileName = e.target.value;
    this.setState({ [fieldKey]: fileName, apiResponseData: null });
  }

  setStartDate(date) {
    this.setState({ selectedDate: date.format('D-M-YYYY') });
  }

  renderResponseDataView({
                           fileName,
                           selectedDate,
                           apiResponseData,
                           precalcsAPIResponseTitle,
                         }) {
    if(!apiResponseData) {
      return null;
    }

    let dateMessage = `Precalc Values for the Date ${selectedDate} :`;
    if(!selectedDate) {
      selectedDate = moment().format('D-M-YYYY');
      dateMessage = `Precalc Values for Today :`;
    }

    const respCssClass = apiResponseData.success ? 'success' : 'error';
    const selectedDateValues = apiResponseData.success ?
        apiResponseData.responseData['prayertimes'][selectedDate] : null;

    return (
      <div className={`precalc-api-response-main-${respCssClass}`}>
        <Row>
          <Col md={12}>
            <p className={`precalc-api-response-main-${respCssClass}-header`}>
              {
                apiResponseData.success ?
                    `Api Response for - ${fileName}` :
                    `File not found with the name "${fileName}"`
              }
            </p>
          </Col>
        </Row>
        {
          apiResponseData.success ?
            <Fragment>
              <Row className={'precalc-api-response-tools'}>
                <Col md={6} className={'precalc-api-response-tools-time'} >
                  <LastUpdatedTime
                      lastUpdatedTimeStamp={
                        parseInt(apiResponseData.responseData.timestamp, 10) * 1000
                      }
                  />
                </Col>
                <Col md={6} className={'precalc-api-response-date-picker'}>
                  <p className={'precalc-api-response-date-picker-text'}>Pick the Date :</p>
                  <MPDataTimeInput
                      showToday
                      dateFormat={'D-M-YYYY'}
                      timeFormat={false}
                      closeOnSelect={true}
                      onChange={this.setStartDate.bind(this)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className={'precalc-selected-time-table-main'} >
                  <div className={'precalc-selected-time-table-header-row'}>
                    <p className={'precalc-selected-time-table-main-text'}>
                      {dateMessage}
                    </p>
                    <CopyToClipboard
                        text={JSON.stringify(selectedDateValues)}
                        onCopy={() => {
                          console.log(`Copied : ${JSON.stringify(selectedDateValues)}`)
                        }}
                    >
                      <div className={'precalc-selected-time-table-copy'}>
                        <p className={'precalc-selected-time-table-copy-text'}>
                          {'Copy Precalcs'}
                        </p>
                        <Button className="btn btn-xs btn-dark">
                          &nbsp;<span className="glyphicon glyphicon-duplicate" aria-hidden="true"/>
                        </Button>
                      </div>
                    </CopyToClipboard>
                  </div>
                  <PrecalcTimePannel timesArray={selectedDateValues}/>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className={'precalcs-diff-merge-view precalc-api-response-json'}>
                    <ReactJson
                        src={apiResponseData.responseData}
                        theme={"monokai"}
                        name={precalcsAPIResponseTitle}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        collapsed={true}
                    />
                  </div>
                </Col>
              </Row>
            </Fragment> : null
        }
      </div>
    );
  }

  render() {
    const {
      fileName,
      submitting,
      selectedDate,
      apiResponseData,
    } = this.state;

    const precalcsAPIResponseTitle = `Precalcs API Response for [${fileName}]`;
    const validFileName = fileName ? fileNameRegex.test(fileName) : false;
    const invalidFileNameErrorMsg = 'Invalid file name. Follow pattern : MY0000003';

    return (
        <BasicLayout pagePermission="moderate_precalc">
          <ToastContainer autoClose={2000} />
          <div>
            <form className={'form-inline'} onSubmit={this.handleSubmit.bind(this)}>
              <Row>
                <h3>{'Test Precalcs API Response'}</h3>
              </Row>
              <br/>
              <Row>
                <Col md={4}>
                  <div className="form-group">
                    <label style={{'marginRight': '5px'}}>{'File Name : '}</label>
                    <input value={fileName}
                           required
                           onChange={this.onFieldChange.bind(this, 'fileName')}
                           type="text"
                           className="form-control"
                           placeholder="Sheet Number"
                    />
                    {
                      fileName && !validFileName ?
                          <div className={'invalid-file-name-error'}>
                            {invalidFileNameErrorMsg}
                          </div> : null
                    }
                  </div>
                </Col>
                <Col md={3}>
                  <div className="form-group">
                    {this.renderSubmitButton(submitting, validFileName)}
                  </div>
                </Col>
              </Row>
              {
                this.renderResponseDataView({
                  fileName,
                  selectedDate,
                  apiResponseData,
                  precalcsAPIResponseTitle,
                })
              }
            </form>
          </div>
        </BasicLayout>
    );
  }
}

export default TestPrecalcApi;
