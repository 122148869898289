import React  from 'react';
import {
  Modal,
  Button,
} from "react-bootstrap";

const AppModal = ({
                      show,
                      showBody = false,
                      showFooter = true,
                      handleClose,
                      handleOk,
                      headingText = 'Modal heading.',
                      modalBody,
                      closeButtonText,
                      okButtonText,
                      modalClassName = 'app-modal',
                      modalBodyClassName = 'app-modal-body',
                      closeButtonClassName = 'btn-primary',
                      okButtonClassName = 'btn-danger',
                      }) => {
  return (
      <Modal className={modalClassName} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{headingText}</Modal.Title>
        </Modal.Header>
        {
          showBody ?
              <Modal.Body
                  className={modalBodyClassName}
              >
                {modalBody}
              </Modal.Body> : null
        }
        {
          showFooter ?
              <Modal.Footer>
                {
                  closeButtonText ?
                      <Button
                          className={closeButtonClassName}
                          variant="secondary"
                          onClick={handleClose}
                      >
                        {closeButtonText}
                      </Button> : null
                }
                {
                  okButtonText ?
                      <Button
                          className={okButtonClassName}
                          variant="primary"
                          onClick={handleOk}
                      >
                        {okButtonText}
                      </Button> : null
                }
              </Modal.Footer> : null
        }
      </Modal>
  );
};

export default AppModal;
