import _ from 'lodash';
import moment from 'moment-timezone';
import {
  PLATFORMS,
} from '../../Constants';
import {
  SPECIAL_HEADERS_COUNT,
  IMAGES_DIRECTORY_PREFIX,
} from './Constants';
import {
  isValidPlatforms,
  isValidCountryCodes,
  getDateFromExcelValue,
} from '../../utils';

const shouldDisableCreateButton = (state, uploadedImageUrl) => {
  return !(state.name &&
      state.start &&
      state.end &&
      (state.banner_url || uploadedImageUrl) &&
      state.action_button_text &&
      !_.isEmpty(state.countries) &&
      state.action_url);
};

const getCountriesList = (existingTimelinePromos) => {
  if(_.isEmpty(existingTimelinePromos['countries'])) {
    return [];
  }

  return _.map(existingTimelinePromos['countries'], countryCode => ({
    value: countryCode.toUpperCase(),
    label: countryCode.toUpperCase(),
  }));
};

const getPlatformsList = (existingTimelinePromos) => {
  if(_.isEmpty(existingTimelinePromos['platforms'])) {
    return [];
  }

  return _.map(existingTimelinePromos['platforms'], platform => ({
    value: platform,
    label: PLATFORMS[platform],
  }));
};

const getListDate = date => {
  return moment.isMoment(date) ? date.format('D-M-YYYY') : date;
};

const getTimeLinePromosForEditUpdate = state => {
  const timeLinePromo = {
    ...state,
  };
  timeLinePromo['countries'] =
      _.map(timeLinePromo['countries'], country => country.value.toLowerCase());
  timeLinePromo['platforms'] =
      _.map(timeLinePromo['platforms'], platform => platform.value.toLowerCase());
  timeLinePromo['start'] = getListDate(timeLinePromo['start']);
  timeLinePromo['end'] = getListDate(timeLinePromo['end']);
  delete timeLinePromo['uploadedFile'];
  delete timeLinePromo['optimizeImages'];
  delete timeLinePromo['resetColors'];
  delete timeLinePromo['startOperation'];
  delete timeLinePromo['operation'];
  delete timeLinePromo['selected_countries'];
  delete timeLinePromo['countrySelectPolicy'];

  return [timeLinePromo];
};

const getDeleteTimelinePromosInfoHeader = (bulkDeleteMap) => {
  return `Are you sure want to delete following ${_.size(bulkDeleteMap)} Timeline Promos?`
};

const getTimeLinePromosForDelete = (bulkDeleteMap) => {
  return _.values(bulkDeleteMap);
};

const getOperationButtonText = (create, operationInProcess) => {
  if(create) {
    return operationInProcess ? 'Creating...': 'Create';
  } else {
    return operationInProcess ? 'Updating...': 'Update';
  }
};

const getArrayFromString = (str) => {
  return str.replace(/\s/g, '').replace(/(^,)|(,$)/g, "").split(',')
};


const getItems = async (rows) => {
  const items = [];
  const allErrors = [];
  // Starting from SPECIAL_HEADERS_COUNT, because we need to skip title and comments.
  for(let i = SPECIAL_HEADERS_COUNT; i < rows.length; i++) {
    let errorsCount = 0;
    const data = rows[i];

    const item = {};

    const errors = {
      'row': i + 1,
      'errorsCount': 0,
      'errorList': [],
      'excelSheetRowData': data,
    };

    // Id
    const itemId = data[0] || null;
    if (itemId) {
      item['id'] = itemId;
    }

    // Name
    let name = data[1];
    if(itemId) {
      // Old Item
      if(name) {
        item['name'] = name;
      }
    } else {
      // New Item
      if(!name) {
        errors['errorList'].push(`${++errorsCount}. Name is missing.`);
      } else {
        item['name'] = name;
      }
    }

    // URL
    let url = data[2];
    if(itemId) {
      // Old Item
      if(url) {
        item['banner_url'] = url;
      }
    } else {
      // New Item
      if(!url) {
        errors['errorList'].push(`${++errorsCount}. Image URL is missing.`);
      } else {
        item['banner_url'] = url;
      }
    }

    // Action Button Text
    let actionBtnText = data[3];
    if(itemId) {
      // Old Item
      if(actionBtnText) {
        item['action_button_text'] = actionBtnText;
      }
    } else {
      // New Item
      if(!actionBtnText) {
        errors['errorList'].push(`${++errorsCount}. Action Button Text is missing.`);
      } else {
        item['action_button_text'] = actionBtnText;
      }
    }

    // Action Button Text Color
    let actionBtnTextColor = data[4];
    if(itemId) {
      // Old Item
      if(actionBtnTextColor) {
        item['action_button_text_color'] = actionBtnTextColor.toUpperCase();
      }
    } else {
      // New Item
      if(!actionBtnTextColor) {
        errors['errorList'].push(`${++errorsCount}. Action Button Text Color is missing.`);
      } else {
        item['action_button_text_color'] = actionBtnTextColor.toUpperCase();
      }
    }

    // Action Button Background Color
    let actionBtnBgColor = data[5];
    if(itemId) {
      // Old Item
      if(actionBtnBgColor) {
        item['action_button_background'] = actionBtnBgColor.toUpperCase();
      }
    } else {
      // New Item
      if(!actionBtnBgColor) {
        errors['errorList'].push(`${++errorsCount}. Action Button Background Color is missing.`);
      } else {
        item['action_button_background'] = actionBtnBgColor.toUpperCase();
      }
    }

    // Action Button URL
    let actionBtnUrl = data[6];
    if(itemId) {
      // Old Item
      if(actionBtnUrl) {
        item['action_url'] = actionBtnUrl;
      }
    } else {
      // New Item
      if(!actionBtnUrl) {
        errors['errorList'].push(`${++errorsCount}. Action URL is missing.`);
      } else {
        item['action_url'] = actionBtnUrl;
      }
    }

    // Close Button Color
    let closeBtnBgColor = data[7];
    if(itemId) {
      // Old Item
      if(closeBtnBgColor) {
        item['close_button_color'] = closeBtnBgColor.toUpperCase();
      }
    } else {
      // New Item
      if(!closeBtnBgColor) {
        errors['errorList'].push(`${++errorsCount}. Close Button Color is missing.`);
      } else {
        item['close_button_color'] = closeBtnBgColor.toUpperCase();
      }
    }

    // Countries
    let countries = [];
    const countriesString = data[8];
    if(countriesString) {
      const countriesArray = getArrayFromString(countriesString);
      if(!_.isEmpty(countriesArray)) {
        countries = _.map(countriesArray, country => country.toLowerCase())
      }
    }
    if(itemId) {
      // Old Item
      if(!_.isEmpty(countries)) {
        if(isValidCountryCodes(countries, [], false)) {
          item['countries'] = countries;
        } else {
          errors['errorList'].push(`${++errorsCount}. Countries are invalid.`);
        }
      }
    } else {
      // New Item
      if(_.isEmpty(countries)) {
        errors['errorList'].push(`${++errorsCount}. Countries are empty.`);
      } else {
        if(isValidCountryCodes(countries, [], false)) {
          item['countries'] = countries;
        } else {
          errors['errorList'].push(`${++errorsCount}. Countries are invalid.`);
        }
      }
    }

    // Platforms
    let platforms = [];
    const platformsString = data[9];
    if(platformsString) {
      const platformsArray = getArrayFromString(platformsString);
      if(!_.isEmpty(platformsArray)) {
        platforms = _.map(platformsArray, country => country.toLowerCase())
      }
    }
    if(itemId) {
      // Old Item
      if(!_.isEmpty(platforms)) {
        if(isValidPlatforms(platforms)) {
          if(platforms.includes('all') && platforms.length > 1) {
            errors['errorList'].push(`${++errorsCount}. When 'all' is used for platforms you can't have other options`);
          } else {
            item['platforms'] = platforms;
          }
        } else {
          errors['errorList'].push(`${++errorsCount}. Platforms are invalid.`);
        }
      }
    } else {
      // New Item
      if(_.isEmpty(platforms)) {
        item['platforms'] = ['all'];
      } else {
        if(isValidPlatforms(platforms)) {
          if(platforms.includes('all') && platforms.length > 1) {
            errors['errorList'].push(`${++errorsCount}. When "all" is used for platforms you can't have other options`);
          } else {
            item['platforms'] = platforms;
          }
        } else {
          errors['errorList'].push(`${++errorsCount}. Platforms are invalid.`);
        }
      }
    }

    // Publish Start Date
    const publishStartDate = data[10] || null;
    if(itemId) {
      // Old Item
      if(!_.isNil(publishStartDate)) {
        item['start'] = getDateFromExcelValue(publishStartDate, false, 'D-M-YYYY');
      }
    } else {
      // New Item
      if(_.isNil(publishStartDate)) {
        errors['errorList'].push(`${++errorsCount}. Publish Start Day is missing.`);
      } else {
        item['start'] = getDateFromExcelValue(publishStartDate, false, 'D-M-YYYY');
      }
    }

    // Publish End Date
    const publishEndDate = data[11] || null;
    if(itemId) {
      // Old Item
      if(!_.isNil(publishEndDate)) {
        item['end'] = getDateFromExcelValue(publishEndDate, false, 'D-M-YYYY');
      }
    } else {
      // New Item
      if(_.isNil(publishEndDate)) {
        errors['errorList'].push(`${++errorsCount}. Publish End Day is missing.`);
      } else {
        item['end'] = getDateFromExcelValue(publishEndDate, false, 'D-M-YYYY');
      }
    }

    // Hide for Premium
    const hideForPremium = data[12];
    if(itemId) {
      // Old Item
      if(!_.isNil(hideForPremium)) {
        if (_.isBoolean(hideForPremium)) {
          item['hide_for_premium'] = hideForPremium;
        } else {
          errors['errorList'].push(`${++errorsCount}. Invalid value for Hide for Premium.`);
        }
      }
    } else {
      // New Item
      if(!_.isNil(hideForPremium)) {
        if (_.isBoolean(hideForPremium)) {
          item['hide_for_premium'] = hideForPremium;
        } else {
          errors['errorList'].push(`${++errorsCount}. Invalid value for Hide for Premium.`);
        }
      } else {
        item['hide_for_premium'] = true;
      }
    }

    // Hide CTA
    const hideCta = data[13];
    if(itemId) {
      // Old Item
      if(!_.isNil(hideCta)) {
        if (_.isBoolean(hideCta)) {
          item['hide_cta'] = hideCta;
        } else {
          errors['errorList'].push(`${++errorsCount}. Invalid value for Hide CTA.`);
        }
      }
    } else {
      // New Item
      if(!_.isNil(hideCta)) {
        if (_.isBoolean(hideCta)) {
          item['hide_cta'] = hideCta;
        } else {
          errors['errorList'].push(`${++errorsCount}. Invalid value for CTA.`);
        }
      } else {
        item['hide_cta'] = false;
      }
    }

    // Hide Banner
    const hideBanner = data[14];
    if(itemId) {
      // Old Item
      if(!_.isNil(hideBanner)) {
        if (_.isBoolean(hideBanner)) {
          item['hide_banner'] = hideBanner;
        } else {
          errors['errorList'].push(`${++errorsCount}. Invalid value for Hide Banner.`);
        }
      }
    } else {
      // New Item
      if(!_.isNil(hideBanner)) {
        if (_.isBoolean(hideBanner)) {
          item['hide_banner'] = hideBanner;
        } else {
          errors['errorList'].push(`${++errorsCount}. Invalid value for Banner.`);
        }
      } else {
        item['hide_banner'] = false;
      }
    }

    errors['errorsCount'] = errorsCount;
    if(errors['errorsCount'] > 0) {
      allErrors.push(errors);
    }
    items.push(item);
  }

  return {
    items,
    allErrors,
  };
};

const getFileUploadPath = ({ fileName }) => {
  return `/${IMAGES_DIRECTORY_PREFIX}/${fileName}`;
};

const getUploadableFiles = async (files) => {
  const uploadableFiles = [];

  for(const file of files) {
    const encodedImage = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(e.target.result);
      fileReader.readAsDataURL(file);
    });
    const base64EncodedImage = encodedImage.replace('data:image/jpeg;base64,', '');

    // More info about adding meta data :
    // https://cloud.google.com/storage/docs/viewing-editing-metadata#storage-view-object-metadata-nodejs
    // https://cloud.google.com/storage/docs/uploading-objects
    const metadata = {
      contentType: 'image/jpeg',
      cacheControl: 'public, max-age=31536000'
    };

    const fileUploadPath = getFileUploadPath({
      fileName: file.name,
    });

    uploadableFiles.push({
      fileBuffer: base64EncodedImage,
      options: {
        metadata,
      },
      fileType: 'image',
      fileUploadPath,
      makePublic: true, // This is options, default is makePublic: true, kept as a API reference.
      clear_cdn_cache: true,
    });
  }

  return { uploadableFiles };
};

export {
  getItems,
  getCountriesList,
  getPlatformsList,
  getUploadableFiles,
  getOperationButtonText,
  shouldDisableCreateButton,
  getTimeLinePromosForDelete,
  getTimeLinePromosForEditUpdate,
  getDeleteTimelinePromosInfoHeader,
}
