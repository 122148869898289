import _ from 'lodash';
import {
  actionTypes,
} from './actionTypes';

const fileUpdateReducer = (state, { type , payload }) => {
  const {
    initialJSON,
  } = state;
  switch (type) {

    case actionTypes.JFU_UPDATE_JSON_VALUE:
      const updatedValues = payload['updated_src'];

      return {
        ...state,
        updatedData: updatedValues,
      }

    case actionTypes.JFU_RESET_FILE_UPDATE:
      return {
        ...state,
        mutableJSON: _.cloneDeep(initialJSON),
        updatedData: null
      }

    case actionTypes.JFU_ON_UPDATE_JSON_FILE:
      return {
        ...state,
        mutableJSON: payload
      }

    default:
      return {
        ...state,
      }
  }
}

export {
  fileUpdateReducer,
};
