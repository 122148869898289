import * as _ from "lodash";
import React, {useContext, useEffect, useState} from "react";
import 'firebase/firestore'
import { dateSorter } from '../utils'
import {toast} from "react-toastify";
import {ListHasChangedContext} from "../context/listHasChangedContext";
import DailyDeenContentPreview from "./contentPreview";
import DailydeenService from "../../../service_v2/dailydeen/dailydeen-service";
import {Icon} from "react-fa";

const kickstart_date = new Date("2023/04/26");

export const ListDailyDeenContent = () => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ listChanged, setListChanged ] = useContext(ListHasChangedContext);

  const [ dailydeensList, setDailydeensList ] = useState([]);

  useEffect( () => {
    if( listChanged == false) return;
    setIsLoading(true);

    DailydeenService.getDailyDeenList().then( (list) => {
      setIsLoading(false);
      setDailydeensList( (list ||[]).sort( dateSorter ) )
    }).catch( err => {
      setIsLoading(false);
    })
    setListChanged(false);
  }, [listChanged]);


  const days_from_kickstart = (now, kickstart_d) =>{
    let difference = now.getTime() - kickstart_d.getTime();
    console.log( now, kickstart_d, now.getTime(), kickstart_d.getTime());
    let TotalDays = Math.floor(difference / (1000 * 3600 * 24));
    return Math.floor(TotalDays) >= 0 ? `Day ${TotalDays+1}` : "";
  }

  const handleDeleteItem = (key) => {
    DailydeenService.deleteDailyDeenById(key).then(_ => {
      console.log("deleting", key)
      setListChanged(true);
      toast.success(`${key} has been deleted`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    })
  }

  const getContentBody = (data) => {
    const sura_start = _.get(data, "data.segment_start", "").slice(0, _.get(data, "data.segment_start", "").length-3);
    const aya_start = _.get(data, "data.segment_start", "").slice(-3);
    const sura_end = _.get(data, "data.segment_end", "").slice(0, _.get(data, "data.segment_end", "").length-3);
    const aya_end = _.get(data, "data.segment_end", "").slice(-3);

    return ["sura", "surah"].includes( _.toLower(data.content_type) ) ? (
      `${sura_start}:${aya_start} - ${sura_end}:${aya_end}`
    ): data.content_body
  }


  const getRowColor = (item_date, id) => {
    const is_old = dateSorter( { schedule_at: new Date().toISOString().slice(0,10)}, { schedule_at: item_date} );
    const is_today = new Date().toISOString().slice(0,10) == item_date;

    const is_default = (id||"").startsWith("default");
    const is_preview = (id||"").startsWith("preview");
    if( is_today ) {
      return "lightgreen"
    }else {
      if( is_default ) {
        return "lightblue"
      }else if( is_preview ) {
        return "lightpink"
      }else if (is_old ){
        return "lightgray"
      }
    }
    return "white"
  }
  return (
    <table className="table">
      <thead>
      <tr>
        <th>Kickstart <br/>{kickstart_date.toISOString().slice(0,10)}</th>
        <th>Schedule</th>
        <th>Content-Type</th>
        <th>Content-Body</th>
        <th>Duration</th>
        <th>Theme</th>
        <th>ContentArray</th>
        <th>actions</th>
      </tr>
      </thead>
      <tbody>
      {
        isLoading ? <p><Icon spin name="spinner" /> Fetching Content</p>:
          dailydeensList.map( (item, idx) => {


            const full_content = _.get(item, 'content_array.full', '');
            const header_content = _.get(item, 'content_array.header', '');

            return (
              <tr style={{ backgroundColor: getRowColor(item.schedule_at, item.id) }}>
                <td>{days_from_kickstart(new Date(item.schedule_at), kickstart_date)}</td>
                <td><a href={process.env.REACT_APP_FIRESTORE_DATABASE_URL+"data/DailyDeen/Content/Content/"+item.schedule_at}>{item.schedule_at}</a></td>
                <td>{item.content_type}</td>
                <td>{getContentBody(item)}</td>
                <td>{item.duration_sec}</td>
                <td>{item.theme}</td>
                <td>
                  {
                    full_content ? <a href={full_content} target="_blank">FULL</a> : "FULL"
                  } | {
                  header_content ? <a href={header_content} target="_blank">HEADER</a> : "HEADER"
                }
                </td>
                <td>
                  <button className={"btn btn-danger"} onClick={ handleDeleteItem.bind(null, item.key) }>X</button>
                  <DailyDeenContentPreview data={item}/>
                </td>
              </tr>
            )
          })
      }
      </tbody>
    </table>
  )
}