import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  settingsUpdateFileKeyValues,
} from '../../services/mp-service-v1-api-service';

const getErrorMessage = (errorResponse, fileName) => {
  console.log(JSON.stringify(errorResponse))
  const errorCode = errorResponse && errorResponse.status;
  switch (errorCode) {
    case 400:
      return errorResponse.data.error.message;
    case 404:
      return `File: ${fileName} not found!`;
    default:
      return `Error occurred while updating key values of file: ${fileName}.`;
  }
};

const updateSettingsFileKeyValues = () => {
  const [ settingsFileKVUpdateData, setSettingsFileKVUpdateData ] = useState({ });
  const [ updateData, setUpdateData] = useState(null);
  const {
    fileName,
    keyValuesData,
    updateConfig,
    onOperationComplete,
  } = updateData || {};

  const updateSettingsFileKVs = async () => {
    try {
      console.log(`Updating key values of file: ${fileName}...`);
      setSettingsFileKVUpdateData({
        isUpdating: true,
        isError: false,
        updatedFileName: fileName,
      });
      const updateRawFileApiResp =  await settingsUpdateFileKeyValues({
        fileName,
        keyValuesData,
        updateConfig,
      });

      const responseData = updateRawFileApiResp.data;
      let errorMsg = null;
      if(responseData.success) {
        console.log(`Updating key values of file: ${fileName} Success!!`);
      } else {
        console.log(`Updating key values of file: ${fileName} Failed!!`);
        errorMsg = `Error occurred while updating key values of file: ${fileName}.`;
      }

      setSettingsFileKVUpdateData({
        data: responseData.data,
        isUpdating: false,
        isError: !responseData.success,
        errorMsg,
        updatedFileName: fileName,
      });

      onOperationComplete({
        isError: !responseData.success,
        errorMsg,
      });
    } catch (err) {
      console.log(`Error while updating key values of file: ${fileName}. Error : ${err}`);
      const errorMsg = getErrorMessage(err.response);
      setSettingsFileKVUpdateData({
        data: null,
        isUpdating: false,
        isError: true,
        errorMsg,
        updatedFileName: fileName,
      });
      onOperationComplete({
        isError: true,
        errorMsg,
      });
    }
  };

  useEffect( () => {
    if(!_.isEmpty(updateData)) {
      updateSettingsFileKVs();
    }
  }, [ updateData ]);

  return [
    settingsFileKVUpdateData,
    setUpdateData,
  ];
};

export default updateSettingsFileKeyValues;
