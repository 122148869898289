import _ from 'lodash';
import React,
{
  Fragment,
} from 'react';
import {
  useTable,
  useSortBy,
  useFilters,
  useRowSelect,
  useFlexLayout,
  usePagination,
  useResizeColumns,
} from 'react-table';
import './style.css'
import {
  Filters,
  DefaultColumnFilter,
} from './filters';

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
          <div className={'mp-table-select-box'}>
            <input
                className={'mp-table-select-box-input'}
                type="checkbox"
                ref={resolvedRef}
                {...rest}
            />
          </div>
      );
    }
);

/**
 * This table can be used to load data with
 * server side operations(i.e Sorting, Filtering, Pagination).
 *
 * @param originalData - Original data items
 * @param displayData -  Data items to be displayed on the table
 * @param columns - Table column config
 * @param loading - Data items loading indicator
 * @param onUpdateTableConfig - On update table configurations
 * @param additionalTableConfig - Additional config data to be pass to onUpdateTableConfig
 * @param setLastTableConfig - Function to update last table configurations
 * @param lastTableConfig - Table last configurations
 * @param skipPageResetRef - React Ref for page updated ref
 * @param setPageIndexData - Function to set the Page Index data
 * @param pageIndexData - Updated page index data
 * @param tableError - Table error (i.e : Data fetch error)
 * @param onDeleteSelected - On selected data items delete action
 * @param onDownloadSelected - On selected data items download action
 * @param controlledPageCount - Manual page count
 * @param TableToolBox - Tool box to show on top of table
 * @param TableOperationStatus - Operation Status components to show table status and errors
 * @param paginationList - Array of pagination values
 * @param initialPageSize - int: Number of Items to load on first page initial render
 * @param initialFilters - Initial table filters
 * @returns {*}
 * @constructor
 */
const MPTable = ({
                   originalData,
                   displayData,
                   columns,
                   loading,
                   onUpdateTableConfig,
                   additionalTableConfig={},
                   setLastTableConfig,
                   lastTableConfig,
                   skipPageResetRef,
                   setPageIndexData,
                   pageIndexData,
                   tableError=null,
                   onDeleteSelected,
                   onDownloadSelected,
                   TableToolBox,
                   TableOperationStatus,
                   pageCount: controlledPageCount,
                   paginationList=[10, 20, 50, 100, 200],
                   initialPageSize,
                   initialFilters=[],
                 }) => {
  initialPageSize = initialPageSize ? initialPageSize: paginationList[0];

  const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 50,
        maxWidth: 250,
        // Default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setAllFilters,
    // Get the state from the instance
    state: {
      pageIndex,
      pageSize,
      sortBy,
      filters,
      selectedRowIds,
    },
    selectedFlatRows,
  } = useTable(
      {
        columns,
        defaultColumn,
        data: displayData,
        initialState: {
          pageIndex: 0,
          pageSize: initialPageSize,
          filters: initialFilters,
        },
        manualPagination: true,
        manualFilters: true,
        // Tell the usePagination
        // hook that we'll handle our own data fetching
        // This means we'll also have to provide our own
        // pageCount.
        pageCount: controlledPageCount,

        // Disable all auto reset
        autoResetPage: !skipPageResetRef.current,
        autoResetExpanded: !skipPageResetRef.current,
        autoResetGroupBy: !skipPageResetRef.current,
        autoResetSortBy: !skipPageResetRef.current,
        autoResetFilters: !skipPageResetRef.current,
        autoResetRowState: !skipPageResetRef.current,
        // autoResetSelectedRows: !skipPageResetRef.current,

        // For now disable multi sort
        disableMultiSort: false,
      },
      useFilters,
      useSortBy,
      usePagination,
      useFlexLayout,
      useResizeColumns,
      useRowSelect,
      hooks => {
        hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => {
              return (
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              )
            },
          },
          ...columns,
        ])
      }
  )

  // After data changes, we turn the flag back off
  React.useEffect(() => {
    skipPageResetRef.current = false
  }, [displayData]);

  // Listen for changes in table config and use the state to fetch our new data
  React.useEffect(() => {
    const tableConfig = {
      pageIndex,
      pageSize,
      sortBy,
      filters,
    };
    onUpdateTableConfig({
      tableConfig,
      originalData,
      lastTableConfig,
      pageIndexData,
      ...additionalTableConfig,
    });
    setLastTableConfig(tableConfig);
  }, [ onUpdateTableConfig, pageIndex, pageSize, sortBy, filters ]);

  React.useEffect(() => {
    setPageIndexData({
      ...pageIndexData,
      [pageIndex]: {
        data: originalData,
      },
    });
  }, [ pageIndex, originalData ]);

  const disableResetAllFilters = _.isEmpty(_.differenceWith(filters, initialFilters, _.isEqual));
  const displayDataSize = _.size(displayData);
  const disableNextPage = !canNextPage || (displayDataSize < pageSize);
  const disableDelDownButtons = _.isEmpty(selectedRowIds);

  return (
      <div className={'mp-table-main-outer'}>
        <TableToolBox
            onDeleteSelected={onDeleteSelected}
            onDownloadSelected={onDownloadSelected}
            disableDelDownButtons={disableDelDownButtons}
            disableResetAllFilters={disableResetAllFilters}
            selectedRows={selectedFlatRows}
            setAllFilters={setAllFilters}
            initialFilters={initialFilters}
        />
        <div className="mp-table-main table-responsive">
          <table {...getTableProps()} className={'table mp-table small'}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>
                        <div>
                        <span {...column.getSortByToggleProps()}
                              className={
                                column.isSorted
                                    ? column.isSortedDesc
                                    ? "mp-table-sort-icon sort-desc"
                                    : "mp-table-sort-icon sort-asc"
                                    : "mp-table-sort-icon "
                              }
                        >
                          {column.render('Header')}
                        </span>
                          <div
                              onClick={() => console.log('column : ', column)}
                              {...column.getResizerProps()}
                              className={`resizer mp-table-resize-handle ${column.isResizing ? 'isResizing' : ''}`}
                          />
                        </div>
                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                      </th>
                  ))}
                </tr>
            ))}
            </thead>
            {
              (loading || tableError) &&
              <tbody>
                <tr>
                  <td>
                    <TableOperationStatus  tableError={tableError} />
                  </td>
                </tr>
              </tbody>
            }
            {
              !loading &&
              <Fragment>
                <tbody {...getTableBodyProps()}>
                {
                  page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr
                            className={`content__list_table_row ${row.original.rowExtraCss} ${row.isSelected ? 'mp-table-row-selected' : ''}`}
                            {...row.getRowProps()}>
                          {
                            row.cells.map(cell => {
                                  return (
                                      <td
                                          {...cell.getCellProps({
                                            className: cell.column.className
                                          })}
                                      >
                                        {cell.render('Cell')}
                                      </td>
                                  );
                                }
                            )}
                        </tr>
                    )
                  })
                }
                </tbody>
              </Fragment>
            }
          </table>
        </div>
        <div className="mp-table-pagination">
          <button
              className={'mp-table-pagination-btn'}
              onClick={() => previousPage()} disabled={!canPreviousPage}>
            &laquo; {'Previous'}
          </button>{' '}
          <button
              className={'mp-table-pagination-btn'}
              onClick={() => nextPage()} disabled={disableNextPage}>
            {'Next'} &raquo;
          </button>{' '}
          <span className={'mp-table-pagination-text'}>
            Page{' '}
            <strong>
              {pageIndex + 1}
            </strong>{' '}
          </span>
          <select
              className={'mp-table-drop-down mp-table-pagination-drop-down'}
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
          >
            {
              paginationList.map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
              ))
            }
          </select>
        </div>
      </div>
  )
};

/**
 *  This table can be used to load data without
 *  server side operations(i.e Sorting, Filtering, Pagination).
 *
 * @param displayData - Data to be displayed in the table
 * @param columns - Column config for the table
 * @param loading - Data loading status
 * @param tableError - Table error (i.e Data fetch error)
 * @param onDeleteSelected - On selected data items delete action
 * @param onDownloadSelected - On selected data items download action
 * @param TableToolBox - Tool box to show on top of table
 * @param TableOperationStatus - Operation Status components to show table status and errors
 * @param paginationList - Array of pagination values
 * @param initialPageSize - int: Number of Items to load on first page initial render
 * @param initialFilters - Initial table filters
 * @returns {*}
 * @constructor
 */
const MPSimpleTable = ({
                         displayData,
                         columns,
                         loading,
                         tableError=null,
                         onDeleteSelected,
                         onDownloadSelected,
                         TableToolBox,
                         TableOperationStatus,
                         paginationList=[10, 20, 50, 100, 200],
                         initialPageSize,
                         initialFilters=[],
                 }) => {

  initialPageSize = initialPageSize ? initialPageSize: paginationList[0];

  const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 50,
        maxWidth: 250,
        // Default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setAllFilters,
    // Get the state from the instance
    state: {
      pageIndex,
      pageSize,
      filters,
      selectedRowIds,
    },
    rows,
    selectedFlatRows,
  } = useTable(
      {
        columns,
        defaultColumn,
        data: displayData,
        initialState: {
          pageIndex: 0,
          pageSize: initialPageSize,
          filters: initialFilters,
        },
        disableMultiSort: false,

        // Disable all auto reset
        autoResetPage: false,
        autoResetExpanded: false,
        autoResetGroupBy:  false,
        autoResetSortBy: false,
        autoResetFilters: false,
        autoResetRowState: false,
        // autoResetSelectedRows: false,
      },
      useFilters,
      useSortBy,
      usePagination,
      useFlexLayout,
      useResizeColumns,
      useRowSelect,
      hooks => {
        hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => {
              return (
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              )
            },
          },
          ...columns,
        ])
      }
  )

  const disableResetAllFilters = _.isEmpty(_.differenceWith(filters, initialFilters, _.isEqual));
  const displayDataSize = _.size(displayData);
  const rowDataSize = _.size(rows);
  const disableDelDownButtons = _.isEmpty(selectedRowIds);
  const filterApplied = !disableResetAllFilters;

  if(filterApplied && !tableError && displayDataSize && !page.length) {
    tableError = {
      isError: true,
      message: 'No data found!',
    }
  }

  return (
      <div className={'mp-table-main-outer'}>
        <TableToolBox
            onDeleteSelected={onDeleteSelected}
            onDownloadSelected={onDownloadSelected}
            disableDelDownButtons={disableDelDownButtons}
            disableResetAllFilters={disableResetAllFilters}
            selectedRows={selectedFlatRows}
            setAllFilters={setAllFilters}
            initialFilters={initialFilters}
        />
        <div className="mp-table-main table-responsive">
          <table {...getTableProps()} className={'table mp-table small'}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>
                        <div>
                        <span {...column.getSortByToggleProps()}
                              className={
                                column.isSorted
                                    ? column.isSortedDesc
                                    ? "mp-table-sort-icon sort-desc"
                                    : "mp-table-sort-icon sort-asc"
                                    : "mp-table-sort-icon "
                              }
                        >
                          {column.render('Header')}
                        </span>
                          <div
                              onClick={() => console.log('column : ', column)}
                              {...column.getResizerProps()}
                              className={`resizer mp-table-resize-handle ${column.isResizing ? 'isResizing' : ''}`}
                          />
                        </div>
                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                      </th>
                  ))}
                </tr>
            ))}
            </thead>
            {
              (loading || tableError) &&
              <tbody>
              <tr>
                <td>
                  <TableOperationStatus tableError={tableError}/>
                </td>
              </tr>
              </tbody>
            }
            {
              !loading &&
              <Fragment>
                <tbody {...getTableBodyProps()}>
                {
                  page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr
                            className={`content__list_table_row ${row.original.rowExtraCss || ''} ${row.isSelected ? 'mp-table-row-selected' : ''}`}
                            {...row.getRowProps()}>
                          {
                            row.cells.map(cell => {
                                  return (
                                      <td
                                          {...cell.getCellProps({
                                            className: cell.column.className
                                          })}
                                      >
                                        {cell.render('Cell')}
                                      </td>
                                  );
                                }
                            )}
                        </tr>
                    )
                  })
                }
                </tbody>
              </Fragment>
            }
          </table>
          {
            !loading &&
            <div className={'mp-table-result-count'} colSpan="10000">
              Showing {page.length} from filtered {rowDataSize} items in all {displayDataSize}{' '}
              result items.
            </div>
          }
        </div>
        <div className='mp-table-pagination'>
          <button
              className={'mp-table-pagination-btn'}
              onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<< First'}
          </button>{' '}
          <button
              className={'mp-table-pagination-btn'}
              onClick={() => previousPage()} disabled={!canPreviousPage}>
            &laquo; {'Previous'}
          </button>{' '}
          <button
              className={'mp-table-pagination-btn'}
              onClick={() => nextPage()} disabled={!canNextPage}>
            {'Next'} &raquo;
          </button>{' '}
          <button
              className={'mp-table-pagination-btn'}
              onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'Last >>'}
          </button>{' '}
          <span className={'mp-table-pagination-text'}>
            Page{' '}
              <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <select
              className={'mp-table-drop-down mp-table-pagination-drop-down'}
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
          >
            {
              paginationList.map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
              ))
            }
          </select>
        </div>
      </div>
  )
};

export {
  MPTable,
  Filters,
  MPSimpleTable,
}
