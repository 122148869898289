import React,
{
  Component,
} from 'react';
import {
  Row,
  Col,
  Modal,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import './style.css';
import DateTime from '../../widgets/datetime';
import firebase from 'firebase/app';
import {
  Icon,
} from 'react-fa';
import ReactTooltip from 'react-tooltip';
import JSONTreeWrapper from '../../widgets/jsonTree';
import {
  CODE_EXPIRY_DURATION,
} from './Constants';
import moment from 'moment';
import InlineWrapper from '../../widgets/roledwrapper/inlinewrapper';
import PeriodPickerWithDefaultButtons from '../../widgets/periodPickerWithDefaultButtons';
import {
  getUrlParams,
  csvDownloader,
} from '../../utils';
import CountryPicker from '../../widgets/countryPicker';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  createLog,
} from '../../services/logs';
import axios from 'axios';
import {
  GIFT_A_PREMIUM_API_URL,
} from './Constants';
import {
  RoleManager,
} from '../../services/roleManager';
import rtdbRef from "../../apis/rtdbRef";
import AdminUserService from "../../service_v2/users/admin-user-service";

let roleManager;

const rtdb = ((path) => {
  return firebase.database().ref(path);
});

const giftRTDB = (({path, debug}) => {
  const gapBasePath = (debug === 1) ? 'giftAPremiumDebug' : 'giftAPremium';
  if(path) {
    return rtdb(`/${gapBasePath}/${path}`);
  } else {
    return rtdb(`/${gapBasePath}`);
  }
});


const promoCodeRTDB = (({path, debug}) => {
  const promoCodeBasePath = (debug === 1) ? 'promoCodesDebug' : 'promoCodes';
  if(path) {
    return rtdbRef.promoCodes(`/${promoCodeBasePath}/${path}`);
  } else {
    return rtdbRef.promoCodes(`/${promoCodeBasePath}/ROOT_ACCESS_NOT_AVAILABLE`);
  }
});


export default class ListGiftAPremium extends Component {
  constructor(props) {
    super(props);
    this.state = {
      giftAPremiums: null,
      selectedGiftInstance: {},
      showEditorModal: false,
      showManualOrderModal: false,
      usersPermission: null,
      from: null,
      to: null,
      filterClass: 'hidden',
      selectedOrderId: null,
      debug: 0
    };
    roleManager = new RoleManager(firebase);
  }

  async componentWillMount() {
    let debug = getUrlParams(this.props).debug;
    debug = debug ? parseInt(debug, 10) : 0;
    this.setState({debug});

    await this.fetchGiftInstances(debug);
    roleManager.getCurrentUserPermission().then((usersPermission) => {
      this.setState({
        usersPermission
      });
    });
    const selectedOrderId = this.props.match.params.query;
    if(selectedOrderId) {
      this.setState({
        selectedOrderId
      });
    }
  }

  componentDidUpdate() {
    let selectedGiftInstance;
    const {
      giftAPremiums,
      showEditorModal,
      selectedOrderId,
    } = this.state;
    if(selectedOrderId && giftAPremiums && !showEditorModal) {
      for(let i=0; i<giftAPremiums.length; i++) {
        const instance = giftAPremiums[i];
        if(instance.orderID === selectedOrderId) {
          selectedGiftInstance = instance;
          break;
        }
      }
      if(selectedGiftInstance) {
        this.setState({
          selectedGiftInstance,
          showEditorModal: true,
          selectedOrderId: null
        });
      }
    }
  }

  onEdit(selectedGiftInstance) {
    this.setState({
      selectedGiftInstance,
      showEditorModal: true,
    });
  }

  async fetchGiftInstances(debug) {
    await giftRTDB({debug}).orderByChild("timestamp").on('value', (gifts) => {
      const giftAPremiums = gifts.val();
      this.setState({
        giftAPremiums: this.convertedToSortedList(giftAPremiums)
      });
      const { selectedGiftInstance } = this.state;
      if(selectedGiftInstance && selectedGiftInstance.id) {
        this.setState({
          selectedGiftInstance: giftAPremiums[selectedGiftInstance.id]
        });
      }
    });
  }

  convertedToSortedList(giftAPremiums) {
    let sortedList = [];
    Object.keys(giftAPremiums).forEach((key) => {
      const gapObj = giftAPremiums[key];
      gapObj.id = key;
      sortedList.push(gapObj);
    });
    return sortedList.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
  }

  renderAllGifts(giftAPremiums) {
    if(!giftAPremiums) {
       return <p>Fetching...</p>;
    } else {
      return giftAPremiums.map((instance, i) => {
        return <GiftInstance onEdit={this.onEdit.bind(this)} key={i} data={instance} />;
      });
    }
  }

  onCountryChange(rawList) {
    const selectedCountries = [];
    if(rawList) {
        rawList.forEach((item) => {
        selectedCountries.push(item.value);
      });
    }
    this.setState({selectedCountries});
  }

  onPeriodPickerChange(from, to) {
    this.setState({
      from,
      to: moment(to).endOf('day').toDate()
    });
  }

  toggleFilter() {
    let { filterClass } = this.state;
    filterClass = (filterClass==='hidden') ? '' : 'hidden';
    this.setState({filterClass});
  }

  onAddOrderBtnClick() {
    this.setState({
      showManualOrderModal: true
    });
  }

  exportReportToCSV() {
    const {
      to,
      from,
      giftAPremiums,
      selectedCountries,
    } = this.state;
    const filteredGiftAPremiums = this.filterPurchases(giftAPremiums, {from, to, selectedCountries});
    const exportables = filteredGiftAPremiums.filter((item) => {
      return item.state === 'EMAIL_SENT' && !item.debug;
    });
    let csvStr = "Country,Created at,Expiring at,Order ID,Transaction ID,Discount Code,Name, Email,Qty,Amount Paid\n";
    exportables.forEach((item) => {
      const { countryCode, timestamp, discountCode, email, qty, _order, orderID, _capture, name, sessionId, _payment } = item;
      let transactionID = _capture && _capture.purchase_units &&
                            _capture.purchase_units[0] && _capture.purchase_units[0].payments &&
                            _capture.purchase_units[0].payments.captures &&
                            _capture.purchase_units[0].payments.captures[0] &&
                            _capture.purchase_units[0].payments.captures[0].id;
      transactionID = transactionID || sessionId;
      let amount = _order && _order.purchase_units && _order.purchase_units[0] && _order.purchase_units[0].amount;
      if(!amount && _payment) {
        const { amount_received = 0, currency } = _payment;
        amount = {
          value: parseFloat(amount_received/100).toFixed(2),
          currency_code: currency.toUpperCase()
        };
      }
      const totalPaid = amount && amount.value;
      csvStr += `${countryCode},${moment(timestamp).format('YYYY-MM-DD')},${moment(timestamp).add(CODE_EXPIRY_DURATION, 'years').locale('en').format('YYYY-MM-DD')},${orderID},${transactionID},${discountCode||'N/A'},${name},${email},${qty},${totalPaid}\n`;
    });
    csvDownloader({
      content: csvStr,
      fileName: `MP_GIFT_A_PREMIUM_${moment().format('YYYY-MM-DD')}`
    });
  }


  renderFilterBar({resultCount}) {
    const {
      to,
      from,
      filterClass,
    } = this.state;
    let panelHeadingMsg = (filterClass==='hidden') ? <Icon name="caret-down" /> : <Icon name="caret-up" />;
    return <div className="panel panel-default cursor-pointer">
      <div onClick={this.toggleFilter.bind(this)} className="panel-heading"><strong>Filter items</strong>&nbsp;&nbsp;{panelHeadingMsg}<span className="pull-right">Showing <strong>{resultCount}</strong> result(s)</span></div>
      <div className={"panel-body " + filterClass}>
        <Row>
          <Col md={3}>
            <CountryPicker onChange={this.onCountryChange.bind(this)}/>
          </Col>
          <Col md={9}>
            <PeriodPickerWithDefaultButtons onChange={this.onPeriodPickerChange.bind(this)} from={from} to={to}/>
          </Col>
        </Row>
      </div>
    </div>;
  }

  filterPurchases(giftAPremiums, {from, to, selectedCountries}) {
    if(!giftAPremiums || !giftAPremiums.length) {
      return giftAPremiums;
    } else {
      const filtered = giftAPremiums.filter((item) => {
        const { countryCode = '-', timestamp } = item;
        if(selectedCountries && selectedCountries.length && selectedCountries.indexOf(countryCode) === -1) {
          return null;
        }
        if(from && to) {
          const fromTimestamp = new Date(from).getTime();
          const toTimestamp = new Date(to).getTime();
          if(fromTimestamp > new Date(timestamp).getTime() || toTimestamp < new Date(timestamp).getTime()) {
            return null;
          }
        }
        return item;
      });
      return filtered;
    }
  }

  render() {
    const {
      to,
      from,
      debug,
      giftAPremiums,
      showEditorModal,
      usersPermission,
      selectedCountries,
      showManualOrderModal,
      selectedGiftInstance,
    } = this.state;
    const filteredGiftAPremiums = this.filterPurchases(giftAPremiums, {from, to, selectedCountries});
    const resultCount = filteredGiftAPremiums && filteredGiftAPremiums.length ? filteredGiftAPremiums.length : 0;
    return (
      <BasicLayout pagePermission="read_codes">
        <ReactTooltip />
        <GiftInstanceEditor
          usersPermission={usersPermission}
          data={{...selectedGiftInstance, debug }}
          show={showEditorModal}
          hideModal={()=>{ this.setState({ showEditorModal: false }); }}
          />
        <ManualOrderAdder
          debug={debug}
          show={showManualOrderModal}
          hideModal={()=>{ this.setState({ showManualOrderModal: false }); }}
          />
        <div className="gift__instance_wrapper">
          {this.renderFilterBar({resultCount})}
          <Row>
            <Col md={6}>
              <p><span className="color_box"></span>Test purchase (paid on sandbox)</p>
            </Col>
            <Col md={6} className="text-right">
              <button onClick={this.onAddOrderBtnClick.bind(this)} className="btn btn-xs btn-primary">Add order manually</button>
              {' '}
              <button onClick={this.exportReportToCSV.bind(this)} className="btn btn-xs btn-info">Export to CSV</button>
            </Col>
          </Row>
          <table className="table table-bordered">
            <thead>
              <th>Edit</th>
              <th>Created At</th>
              <th>Expiring At</th>
              <th>Payment method</th>
              <th>Order ID</th>
              <th>Transaction ID</th>
              <th>Discount Code</th>
              <th>Buyer name</th>
              <th>Email</th>
              <th>Quantity</th>
              <th>Amount</th>
              <th>Country</th>
              <th>Status</th>
            </thead>
            <tbody>
              {this.renderAllGifts(filteredGiftAPremiums)}
            </tbody>
          </table>
        </div>
      </BasicLayout>
    );
  }
}

class GiftInstance extends Component {
  renderStatusField(state) {
    if(state === 'EMAIL_SENT') {
      return <td className="completed state"><span>Completed</span></td>;
    } else if(state.startsWith("REVERTED")) {
      return <td className="failed state"><span>Reverted</span></td>;
    }
     else if(state.startsWith("ERROR")) {
      return <td className="failed state"><span>Failed</span></td>;
    } else {
      return <td className="processing state"><span>Processing</span></td>;
    }
  }

  onEdit(selectedInstance) {
    this.props.onEdit(selectedInstance);
  }

  renderPaymentMethod(paymentMethod) {
    let iconName = "exclamation-circle";
    let iconColorClass = "";
    if(paymentMethod === 'paypal') {
      iconName = "cc-paypal" ;
      iconColorClass = "paypal_color";
    } else if(paymentMethod === 'stripe') {
      iconName = "cc-stripe";
      iconColorClass = "stripe_color";
    }
    return <Icon name={iconName} className={"payment_method_icon " + iconColorClass} />;
  }

  render() {
    const {
      qty,
      name,
      debug,
      email,
      state,
      _order,
      orderID,
      _payment,
      timestamp,
      sessionId,
      countryCode = '-',
      _capture = {},
      discountCode = "N/A",
      paymentMethod,
    } = this.props.data;
    const dateTime = new Date(timestamp || Date.now()).toISOString().slice(0, 19);
    const expiringDateTime = moment(dateTime).add(CODE_EXPIRY_DURATION, 'years').locale('en').format('YYYY-MM-DD');
    let transactionID = _capture && _capture.purchase_units &&
                          _capture.purchase_units[0] && _capture.purchase_units[0].payments &&
                          _capture.purchase_units[0].payments.captures &&
                          _capture.purchase_units[0].payments.captures[0] &&
                          _capture.purchase_units[0].payments.captures[0].id;
    transactionID = transactionID || sessionId;
    let amount = _order && _order.purchase_units && _order.purchase_units[0] && _order.purchase_units[0].amount;
    if(!amount && _payment) {
      const { amount_received = 0, currency } = _payment;
      amount = {
        value: parseFloat(amount_received/100).toFixed(2),
        currency_code: currency.toUpperCase()
      };
    }
    const totalPaid = amount && amount.value;
    const currency = amount && amount.currency_code;
    const testPurchaseClass = debug ? "test_purchase" : "";
    return <tr className="small">
      <td onClick={this.onEdit.bind(this, this.props.data)} className={"text-center edit_col " + testPurchaseClass} data-tip="Click to see more details or moderate this gift instance"><Icon name="edit"/></td>
      <td className="datetime_col" data-tip={dateTime}>{dateTime.slice(0, 10)}</td>
      <td className="datetime_col" data-tip={expiringDateTime}>{expiringDateTime}</td>
      <td className="compact_col text-center">{this.renderPaymentMethod(paymentMethod)}</td>
      <td className="order_id compact_col" data-tip={orderID}><div>{orderID}</div></td>
      <td className="compact_col" data-tip={transactionID}><div>{transactionID}</div></td>
      <td data-tip={discountCode}><div>{discountCode}</div></td>
      <td className="compact_col">{name}</td>
      <td>{email}</td>
      <td className="text-center">{qty}</td>
      <td className="text-right">{currency} {totalPaid ? totalPaid : 0}</td>
      <td className="text-center">{countryCode}</td>
      {this.renderStatusField(state)}
    </tr>;
  }
}

class ManualOrderAdder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderID: "",
      buyerName: "",
      buyerEmail: "",
      qty: 1,
      discountCode: null,
      paymentMethod: 'stripe',
      isExistingOrder: false
    };
  }
  onHide() {
    this.setState({ isExistingOrder: false });
    this.props.hideModal();
  }

  async onFieldChange(key, e) {
    const value = e.target.value;
    let isExistingOrder = false;
    if(key === 'orderID' && value.trim() !== "") {
      const snap = await giftRTDB({path: value, debug: this.props.debug}).once('value');
      if(snap.val()) {
        isExistingOrder = true;
      }
    }
    this.setState({
      [key]: value,
      isExistingOrder
    });
  }

  async onAddBtnClick() {
   const {
     qty,
     orderID,
     buyerName,
     buyerEmail,
     discountCode,
     paymentMethod,
   } = this.state;
   const confirmMsg = `Are you sure you want to try adding a manual order with following info?\n
   Name: ${buyerName}
   Email: ${buyerEmail}
   Order ID: ${orderID}
   Discount code: ${discountCode || 'N/A'}
   Quantity: ${qty}`;
    const confirm = window.confirm(confirmMsg);
    if(confirm) {
      const resp = await axios.post(GIFT_A_PREMIUM_API_URL, {
        orderID,
        name: buyerName,
        email: buyerEmail,
        discountCode,
        paymentMethod,
        qty: parseInt(qty || 0, 10),
        languageCode: 'en',
        sessionId: `ac_manual_order_${Date.now()}`
      });
      if(resp && resp.data && resp.data.result === 'success') {
        toast.success('Order added manually.', {
          position: toast.POSITION.BOTTOM_LEFT
        });
        setTimeout(()=>{ this.onHide(); }, 2000);
      } else {
        toast.error('Failed to add the order manually. Please contact developers for more info.', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }
    }
  }

  render() {
    const {
      isExistingOrder,
    } = this.state;
    return <Modal className="giftinstance__editor_modal" show={this.props.show} onHide={this.onHide.bind(this)}>
        <ToastContainer autoClose={2000} />
        <Modal.Header closeButton>
          <Modal.Title>Add order manually</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section>
            <FormGroup>
              <ControlLabel>Order ID</ControlLabel>
              <FormControl onChange={this.onFieldChange.bind(this, "orderID")}/>
              <p className={"small text-danger " + (isExistingOrder ? '' : 'hidden')}><Icon name="exclamation-circle"/> This order has been used already.</p>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Buyer name</ControlLabel>
              <FormControl disabled={isExistingOrder} onChange={this.onFieldChange.bind(this, "buyerName")}/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Buyer email</ControlLabel>
              <FormControl disabled={isExistingOrder} onChange={this.onFieldChange.bind(this, "buyerEmail")}/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Quantity</ControlLabel>
              <FormControl disabled={isExistingOrder} onChange={this.onFieldChange.bind(this, "qty")} type="number" min="1"/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Discount code (Optional)</ControlLabel>
              <FormControl disabled={isExistingOrder} onChange={this.onFieldChange.bind(this, "discountCode")}/>
            </FormGroup>
          </section>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={this.onHide.bind(this)}>
              Close
            </Button>
            <Button disabled={isExistingOrder} onClick={this.onAddBtnClick.bind(this)} className="btn-primary">
              Add order
            </Button>
          </Modal.Footer>
    </Modal>;
  }
}

class GiftInstanceEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codesWithStatus: null
    };
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.data && nextProps.data.codes) {
      this.getCodesWithStatus(nextProps.data);
    }
  }

  getCodesWithStatus(data) {
    const {
      codes = [],
      debug = true,
    } = data;
    const codesStatus = {};
    const promises = codes.map((code) => {
      // Before release, change the following path to promoCodes (!)
      return promoCodeRTDB({path: code, debug}).once('value').then((snapshot) => {
        const promo = snapshot.val();
        if(promo) {
          const { user_id, redeem_datetime } = promo;
          codesStatus[code] = {
            uid: user_id,
            timestamp: redeem_datetime
          };
        }
        return Promise.resolve();
      });
    });
    Promise.all(promises).then(() => {
      this.setState({ codesWithStatus: codesStatus });
    });
  }

  onHide() {
    this.props.hideModal();
  }

  renderStateHistory(history) {
    return Object.keys(history).map((key) => {
      const hObj = history[key];
      const isErrorClass = (hObj.state.startsWith("ERROR") || hObj.state.startsWith("REVERTED")) ? "error" : "";
      return <Row key={key} className={"state__history_row " + isErrorClass}>
        <Col xs={6}><DateTime timestamp={hObj.timestamp.toString()}/></Col>
        <Col xs={6}>{hObj.state}</Col>
      </Row>
    });
  }

  renderCodes(codes, codesWithStatus) {
    let listItems;
    if(!codesWithStatus) {
      listItems = codes.map((code) => {
        const searchUrl = `/search-promo-code/${code}`;
        return <li><a href={searchUrl} target="_blank">{code}</a><Icon spin name="spinner"/></li>;
      });
    } else {
      listItems  = codes.map((code) => {
        const searchUrl = `/search-promo-code/${code}`;
        const isRedeemed = (codesWithStatus[code] && codesWithStatus[code].uid) ? <Icon className="text-success" name="check-circle" /> : null;
        const whenRedeemed = isRedeemed ? `Redeemed at ${new Date(codesWithStatus[code].timestamp).toISOString().slice(0, 19)}` : "Not redeemed";
        return <li title={whenRedeemed}><a href={searchUrl} target="_blank">{code}</a> {isRedeemed}</li>;
      });
    }

    return <ol>{listItems}</ol>;
  }

  async retryMachine({id, debug, state}) {
    giftRTDB({path: `${id}/state`, debug}).set(state);
    await giftRTDB({path: `${id}/lastModerateTimestamp`, debug}).set(Date.now());
  }

  async onRetryCapturePayment({id, debug}, e) {
    await this.retryMachine({id, debug, state: 'ORDER_VALIDATED'});
  }

  async onRetryCodeGeneration({id, debug}, e) {
    await this.retryMachine({id, debug, state: 'PAYMENT_CAPTURED'});
  }

  async onRetryEmailSend({id, debug}, e) {
    await this.retryMachine({id, debug, state: 'CODE_GENERATED'});
  }

  async revokeRedeemerPremiums(redeemers) {
    const revokePremiumPromises = [];
    // Loop all the redeemers and revoke if they are lifetime premium
    redeemers.forEach((redeemer) => {
      if(redeemer && redeemer.uid && redeemer.uid.trim() !== "") {
        console.log("check and revoke premium for ", redeemer);
        revokePremiumPromises.push(
          AdminUserService.queryUserData(redeemer.uid, { path: 'premium_status/premium' }).then((premium) => {
            // If users premium is lifetime, only then assume user is using the GAP premium
            if(premium === redeemer.premiumType) {
              console.log('Revoking premium for', redeemer);
              return AdminUserService.setUserData(redeemer.uid, { [`premium_status/premium`]: 'none' })
            } else {
              console.log("Skipping premium revoke since already not premium");
              return Promise.resolve();
            }
          })
        );
      }
    });
    return await Promise.all(revokePremiumPromises);
  }

  async getRedeemersFromCodes({codes, debug}) {
    // Read the codes
    const codeReadingPromises = [];
    codes.forEach((code) => {
      // Get the redeemers of the code
      codeReadingPromises.push(
        promoCodeRTDB({path: code, debug}).once('value').then((snap) => {
          if(snap.val()) {
            const { user_id, type } = snap.val();
            if(user_id) {
              return {
                uid: user_id,
                premiumType: type
              };
            }
          }
          return null;
        })
      );
    });
    return await Promise.all(codeReadingPromises);
  }

  async revertPurchase(data, e) {
    const confirm = window.confirm("Are you sure? By doing this you will delete the codes and mark purchase as reverted.");
    if(confirm) {
      console.log("Revert this purchase", data);
      // Form reverted object
      const timestamp = Date.now();
      const { codes, id, debug } = data;
      const currentMod = JSON.parse(localStorage['user']);
      const reverted = {
        timestamp,
        moderatorEmail: currentMod.email,
        moderatorUid: currentMod.uid
      };

      // Get redeemers of each code
      const redeemers = await this.getRedeemersFromCodes({codes, debug});
      console.log("Revoke these users premium if found any", redeemers);

      // Revoke the premiums of the redeemers if they are lifetime
      await this.revokeRedeemerPremiums(redeemers);
      console.log("All code redeemers premium for this purchase has been revoked");

      // Delete codes after revoking premiums
      await Promise.all(codes.map((code) => {
        return promoCodeRTDB({path: code, debug}).remove();
      }));
      console.log("All codes for this purchase has been deleted from the database");

      // Update state history and the main state of instance
      giftRTDB({path: `${id}/stateHistory`, debug}).push({
        state: 'REVERTED',
        timestamp
      });
      giftRTDB({path: `${id}/state`, debug}).set('REVERTED');

      // Write the reverted data
      giftRTDB({path: `${id}/reverted`, debug}).set(reverted);

      // Create admin logs in BQ and return success
      createLog('revert_gift_a_premium', {
        id
      });
      toast.success('The purchase has been reverted successfully', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  }

  hasStatesInHistory(history, state) {
    const keys = Object.keys(history);
    for(let i=0; i<keys.length; i++) {
      const historyObj = history[keys[i]];
      if(historyObj.state === state) {
        return true;
      }
    }
    return false;
  }

  renderRevertedSection(reverted, data) {
    if(reverted) {
      const {
        timestamp,
        moderatorEmail,
      } = reverted;
      return <p className="pull-left text-danger">This purchase has been reverted by {moderatorEmail} on {moment(timestamp).format('LLLL')}</p>
    } else {
      return <Button className="pull-left btn-danger" onClick={this.revertPurchase.bind(this, data)}>
        Revert purchase
      </Button>;
    }
  }

  render() {
    const {
      data = {},
    } = this.props;
    const {
      codesWithStatus,
    } = this.state;
    const {
      id,
      qty,
      name,
      email,
      state,
      codes,
      debug,
      orderID,
      reverted,
      timestamp = "",
      stateHistory={},
      paymentMethod,
      stateDescription,
    } = data;
    let isOrderValidated = this.hasStatesInHistory(stateHistory, 'ORDER_VALIDATED'),
          isPaymentCaptured = this.hasStatesInHistory(stateHistory, 'PAYMENT_CAPTURED'),
          isCodeGenerated = this.hasStatesInHistory(stateHistory, 'CODE_GENERATED');

    // Stripe doesn't require capturing payment for GAP
    // That's why on order validation, payment captured will be automatically
    // marked as true
    if(paymentMethod === 'stripe' && isOrderValidated) {
      isPaymentCaptured = true;
    }

    const canRetryPaymentCapture = isOrderValidated &&
                                   !isPaymentCaptured;

    const canRetryCodeGen = isOrderValidated &&
                            isPaymentCaptured &&
                            !isCodeGenerated &&
                            (!codes || !codes.length);
    const canRetryEmailSend = isOrderValidated &&
                              isPaymentCaptured &&
                              isCodeGenerated &&
                              codes && codes.length;
    const giftAPremiumRtdbLink = `https://console.firebase.google.com/project/muslim-pro-app/database/muslim-pro-app/data/giftAPremium/${id}`;

    return <Modal className="giftinstance__editor_modal" show={this.props.show} onHide={this.onHide.bind(this)}>
        <Modal.Header closeButton>
          <Modal.Title>Editing - Order # {orderID} <a className="small" href={giftAPremiumRtdbLink} target="_blank">Open in firebase</a></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <ToastContainer autoClose={2000} />
          <Row>
            <Col className="general_info" md={6}>
              <Row>
              <Col xs={6} className="text-right"><strong>Name:</strong></Col><Col xs={6}>{name}</Col>
              <Col xs={6} className="text-right"><strong>Email:</strong></Col><Col xs={6}>{email}</Col>
              <Col xs={6} className="text-right"><strong>Created At:</strong></Col><Col xs={6}><DateTime timestamp={timestamp.toString()}/></Col>
              <Col xs={6} className="text-right"><strong>Quantity:</strong></Col><Col xs={6}>{qty}</Col>
              <Col xs={6} className="text-right"><strong>State:</strong></Col><Col xs={6}>{state}</Col>
              <Col xs={6} className="text-right"><strong>State description:</strong></Col><Col xs={6}>{stateDescription || 'N/A'}</Col>
              <Col xs={6} className="text-right"><strong>Codes:</strong></Col><Col xs={6}>{(codes && codes.length) ? this.renderCodes(codes, codesWithStatus) : 'N/A'}</Col>
              </Row>
            </Col>
            <Col md={6}>
              <strong>History</strong>
              {this.renderStateHistory(stateHistory)}
              <hr />
              <p><strong>Retry</strong></p>
              <Button onClick={this.onRetryCapturePayment.bind(this, {id, debug})} disabled={!canRetryPaymentCapture} className="btn-xs retry__btn">Capture payment</Button>
              <InlineWrapper usersPermission={this.props.usersPermission} permission={31}>
                <Button onClick={this.onRetryCodeGeneration.bind(this, {id, debug})} disabled={!canRetryCodeGen} className="btn-xs retry__btn">Generate code</Button>
              </InlineWrapper>
              <Button onClick={this.onRetryEmailSend.bind(this, {id, debug})} disabled={!canRetryEmailSend} className="btn-xs retry__btn">Send email</Button>
            </Col>
            <Col className="jsontree" md={12}><JSONTreeWrapper data={data}/></Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {this.renderRevertedSection(reverted, data)}
          <Button onClick={this.onHide.bind(this)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>;
  }
}
