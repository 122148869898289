import React from 'react';
import {
  Filters,
} from '../../widgets/mp-table';
import {
  getSelectOptions,
} from './helper';

const listColumns = () => {
  return React.useMemo(
      () => [
        {
          Header: 'Index',
          accessor: 'index',
          width: 70,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Preview',
          accessor: 'preview',
          width: 100,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Title',
          accessor: 'title',
          width: 200,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Image URL',
          accessor: 'imageUrl',
          width: 70,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Source',
          accessor: 'source',
          width: 200,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Type',
          accessor: 'typeIcon',
          width: 100,
          disableSortBy: true,
          className: 'mp-content-v2-table-td-pt text-center',
          Filter: Filters.SelectColumnFilter({ getSelectOptions }),
          filter: 'includes',
        },
        {
          Header: 'Series Id',
          accessor: 'seriesId',
          width: 200,
          disableSortBy: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Hash Tags',
          accessor: 'hashTags',
          width: 200,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Language',
          accessor: 'languageCode',
          width: 100,
          disableSortBy: true,
          className: 'mp-content-v2-table-td-pt text-center',
          Filter: Filters.SelectColumnFilter({ getSelectOptions }),
          filter: 'includes',
        },
        {
          Header: 'Created',
          columns: [
            {
              Header: 'Date',
              accessor: 'createdDate',
              width: 100,
              className: 'mp-content-v2-table-td-pt text-center',
              Filter: Filters.DateRangeFilter,
              filter: 'range',
            },
            {
              Header: 'By',
              accessor: 'createdBy',
              width: 100,
              disableSortBy: true,
              disableFilters: true,
              className: 'mp-content-v2-table-td-pt text-center',
            },
          ],
        },
        {
          Header: 'Updated',
          columns: [
            {
              Header: 'Date',
              accessor: 'updatedDate',
              width: 100,
              className: 'mp-content-v2-table-td-pt text-center',
              Filter: Filters.DateRangeFilter,
              filter: 'range',
            },
            {
              Header: 'By',
              accessor: 'updatedBy',
              width: 100,
              disableSortBy: true,
              disableFilters: true,
              className: 'mp-content-v2-table-td-pt text-center',
            },
          ],
        },
        {
          Header: 'Published',
          accessor: 'publishedDate',
          width: 100,
          className: 'mp-content-v2-table-td-pt text-center',
          Filter: Filters.DateRangeFilter,
          filter: 'range',
        },
        {
          Header: 'DB Link',
          accessor: 'dbLink',
          width: 100,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Content Id',
          accessor: 'contentId',
          width: 200,
          disableSortBy: true,
          className: 'mp-content-v2-table-content-id mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Operations',
          accessor: 'operations',
          width: 100,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
      ],
      []
  );
}

const ramadanListColumns = () => {
  return React.useMemo(
      () => [
        {
          Header: 'Index',
          accessor: 'index',
          width: 70,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Preview',
          accessor: 'preview',
          width: 100,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Title',
          accessor: 'title',
          width: 200,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Image URL',
          accessor: 'imageUrl',
          width: 70,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Type',
          accessor: 'typeIcon',
          width: 100,
          disableSortBy: true,
          className: 'mp-content-v2-table-td-pt text-center',
          Filter: Filters.SelectColumnFilter({ getSelectOptions }),
          filter: 'includes',
        },
        {
          Header: 'Source',
          accessor: 'source',
          width: 200,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Series Id',
          accessor: 'seriesId',
          width: 200,
          disableSortBy: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Hash Tags',
          accessor: 'hashTags',
          width: 200,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Language',
          accessor: 'languageCode',
          width: 100,
          disableSortBy: true,
          className: 'mp-content-v2-table-td-pt text-center',
          Filter: Filters.SelectColumnFilter({ getSelectOptions }),
          filter: 'includes',
        },
        {
          Header: 'For Ramadan',
          accessor: 'forRamadan',
          width: 150,
          disableSortBy: true,
          className: 'mp-content-v2-table-td-pt text-center',
          Filter: Filters.SelectColumnFilter({ getSelectOptions }),
          filter: 'includes',
        },
        {
          Header: 'Year',
          accessor: 'yearCode',
          width: 150,
          disableSortBy: true,
          className: 'mp-content-v2-table-td-pt text-center',
          Filter: Filters.SelectColumnFilter({ getSelectOptions }),
          filter: 'includes',
        },
        {
          Header: 'Created',
          columns: [
            {
              Header: 'Date',
              accessor: 'createdDate',
              width: 100,
              className: 'mp-content-v2-table-td-pt text-center',
              Filter: Filters.DateRangeFilter,
              filter: 'range',
            },
            {
              Header: 'By',
              accessor: 'createdBy',
              width: 100,
              disableSortBy: true,
              disableFilters: true,
              className: 'mp-content-v2-table-td-pt text-center',
            },
          ],
        },
        {
          Header: 'Updated',
          columns: [
            {
              Header: 'Date',
              accessor: 'updatedDate',
              width: 100,
              className: 'mp-content-v2-table-td-pt text-center',
              Filter: Filters.DateRangeFilter,
              filter: 'range',
            },
            {
              Header: 'By',
              accessor: 'updatedBy',
              width: 100,
              disableSortBy: true,
              disableFilters: true,
              className: 'mp-content-v2-table-td-pt text-center',
            },
          ],
        },
        {
          Header: 'Published',
          accessor: 'publishedDate',
          width: 100,
          className: 'mp-content-v2-table-td-pt text-center',
          Filter: Filters.DateRangeFilter,
          filter: 'range',
        },
        {
          Header: 'DB Link',
          accessor: 'dbLink',
          width: 100,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Content Id',
          accessor: 'contentId',
          width: 200,
          disableSortBy: true,
          className: 'mp-content-v2-table-content-id mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Operations',
          accessor: 'operations',
          width: 100,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
      ],
      []
  );
};

const specialListColumns = () => {
  return React.useMemo(
      () => [
        {
          Header: 'Index',
          accessor: 'index',
          width: 70,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Preview',
          accessor: 'preview',
          width: 100,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Title',
          accessor: 'title',
          width: 200,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Image URL',
          accessor: 'imageUrl',
          width: 70,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Type',
          accessor: 'typeIcon',
          width: 100,
          disableSortBy: true,
          className: 'mp-content-v2-table-td-pt text-center',
          Filter: Filters.SelectColumnFilter({ getSelectOptions }),
          filter: 'includes',
        },
        {
          Header: 'Series Id',
          accessor: 'seriesId',
          width: 200,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Hash Tags',
          accessor: 'hashTags',
          width: 200,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Language',
          accessor: 'languageCode',
          width: 100,
          disableSortBy: true,
          className: 'mp-content-v2-table-td-pt text-center',
          Filter: Filters.SelectColumnFilter({ getSelectOptions }),
          filter: 'includes',
        },
        {
          Header: 'Featured',
          columns: [
            {
              Header: 'Featured',
              accessor: 'featured',
              width: 100,
              className: 'mp-content-v2-table-td-pt text-center',
              Filter: Filters.SelectColumnFilter({ getSelectOptions }),
              filter: 'includes',
              disableSortBy: true,
            },
            {
              Header: 'Type',
              accessor: 'featuredCategory',
              width: 100,
              disableSortBy: true,
              className: 'mp-content-v2-table-td-pt text-center',
              Filter: Filters.SelectColumnFilter({ getSelectOptions }),
              filter: 'includes',
            },
            {
              Header: 'Start',
              accessor: 'featuredStartDate',
              width: 100,
              className: 'mp-content-v2-table-td-pt text-center',
              Filter: Filters.DateRangeFilter,
              filter: 'range',
            },
            {
              Header: 'End',
              accessor: 'featuredEndDate',
              width: 100,
              className: 'mp-content-v2-table-td-pt text-center',
              Filter: Filters.DateRangeFilter,
              filter: 'range',
            },
          ],
        },
        {
          Header: 'Pinned',
          columns: [
            {
              Header: 'Pinned',
              accessor: 'pinned',
              width: 100,
              disableSortBy: true,
              className: 'mp-content-v2-table-td-pt text-center',
              Filter: Filters.SelectColumnFilter({ getSelectOptions }),
              filter: 'includes',
            },
            {
              Header: 'Start',
              accessor: 'pinnedStartDate',
              width: 100,
              className: 'mp-content-v2-table-td-pt text-center',
              Filter: Filters.DateRangeFilter,
              filter: 'range',
            },
            {
              Header: 'End',
              accessor: 'pinnedEndDate',
              width: 100,
              className: 'mp-content-v2-table-td-pt text-center',
              Filter: Filters.DateRangeFilter,
              filter: 'range',
            },
          ],
        },
        {
          Header: 'Hidden',
          accessor: 'hidden',
          width: 100,
          disableSortBy: true,
          className: 'mp-content-v2-table-td-pt text-center',
          Filter: Filters.SelectColumnFilter({ getSelectOptions }),
          filter: 'includes',
        },
        {
          Header: 'Created',
          columns: [
            {
              Header: 'Date',
              accessor: 'createdDate',
              width: 100,
              className: 'mp-content-v2-table-td-pt text-center',
              Filter: Filters.DateRangeFilter,
              filter: 'range',
            },
            {
              Header: 'By',
              accessor: 'createdBy',
              width: 100,
              disableSortBy: true,
              disableFilters: true,
              className: 'mp-content-v2-table-td-pt text-center',
            },
          ],
        },
        {
          Header: 'Updated',
          columns: [
            {
              Header: 'Date',
              accessor: 'updatedDate',
              width: 100,
              className: 'mp-content-v2-table-td-pt text-center',
              Filter: Filters.DateRangeFilter,
              filter: 'range',
            },
            {
              Header: 'By',
              accessor: 'updatedBy',
              width: 100,
              disableSortBy: true,
              disableFilters: true,
              className: 'mp-content-v2-table-td-pt text-center',
            },
          ],
        },
        {
          Header: 'Published',
          accessor: 'publishedDate',
          width: 100,
          className: 'mp-content-v2-table-td-pt text-center',
          Filter: Filters.DateRangeFilter,
          filter: 'range',
        },
        {
          Header: 'DB Link',
          accessor: 'dbLink',
          width: 100,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Source',
          accessor: 'source',
          width: 200,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Content Id',
          accessor: 'contentId',
          width: 200,
          disableSortBy: true,
          className: 'mp-content-v2-table-content-id mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Operations',
          accessor: 'operations',
          width: 100,
          disableSortBy: true,
          disableFilters: true,
          className: 'mp-content-v2-table-td-pt text-center',
        },
      ],
      []
  );
};

const hashTagListColumns = () => {
  return React.useMemo(
      () => [
        {
          Header: 'HashTag',
          accessor: 'hashTag',
          width: 150,
          className: 'mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center',
        },
        {
          Header: 'Language',
          accessor: 'languageCode',
          width: 150,
          className: 'mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center',
          Filter: Filters.SelectColumnFilter({ getSelectOptions }),
          filter: 'includes',
        },
        {
          Header: 'Content Ids',
          accessor: 'contentIdsEditLinks',
          disableSortBy: true,
          disableFilters: true,
          width: 600,
          className: 'mp-content-v2-table-td-pt text-center',
        }
      ],
      []
  );
};

export {
  listColumns,
  ramadanListColumns,
  specialListColumns,
  hashTagListColumns,
}
