import {
  actionTypes,
} from './actionTypes';

const DeleteUserLogsInitialState = {
  email: '',
  firebaseUID: '',
  ipAddress: '',
  phone: '',
  deviceId: '',
  idfa: '',
  selectedTables: [],
};

const deleteUserLogsReducer = (state, { type , payload }) => {

  switch (type) {
    case actionTypes.DUL_RESET:
      return {
        ...DeleteUserLogsInitialState,
      };
    case actionTypes.DUL_UPDATE_PROP_VAL:
      const {
        prop,
        value,
      } = payload;
      if(prop === 'firebaseUID') {
        return {
          ...DeleteUserLogsInitialState,
          [prop]: value,
        };
      } else {
        return {
          ...state,
          [prop]: value,
        };
      }
    case actionTypes.DUL_UPDATE_INPUT_DATA:
      const {
        phoneNumber='',
        uid='',
        email: userEmail='',
      } = payload;

      return {
        ...state,
        email: userEmail,
        firebaseUID: uid,
        phone: phoneNumber,
      };
    case actionTypes.DUL_TOGGLE_PROP_VAL:
      const {
        prop_item,
      } = payload;

      return {
        ...state,
        [prop_item]: !state[prop_item],
      };
    default:
      return state;
  }
};

export {
  deleteUserLogsReducer,
  DeleteUserLogsInitialState,
}
