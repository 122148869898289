import React,
{
  Component,
} from 'react';
import {
  Button,
} from 'react-bootstrap';
import './style.css';

class ReloadButton extends Component {
  reloadPage() {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister().then(() => {
        window.location.reload(true);
      });
    });
    localStorage.removeItem('testEmails');
    localStorage.removeItem('dataModerators');
    localStorage.removeItem('promoCodesFilterData');
    setTimeout(() => {
      window.location.reload(true);
    }, 1000);
  }

  render() {
    return (
      <div className="reload__button" title="Clicking this button will clear the application cache and fetch the latest version of admin console">
        <Button onClick={this.reloadPage.bind(this)} className="btn-danger btn-block btn-xs">
          Update admin console
        </Button>
      </div>
    );
  }
}

export default ReloadButton;
