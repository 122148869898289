import React,
{
  Fragment,
  useState,
} from 'react';
import JsonDiffReact from 'jsondiffpatch-for-react';
import ToggleButton from 'react-toggle-button';
import './style.css';

const JSONDiffView = ({
                        leftSideData,
                        rightSideData,
                        messageIfBothSideIdentical,
                      }) => {
  const [ showUnChangedData, setShowUnChangedData] = useState(false);

  return (
      <Fragment>
        <div className={'json-diff-main'}>
          <div className="json-diff-main__tool-box">
            <label className="show__ads_label">Show Un Changed Data</label>
            <div>
              <ToggleButton
                  value={showUnChangedData}
                  onToggle={(value) => {
                    setShowUnChangedData(!value);
                  }
                  }
              />
            </div>
          </div>
          <JsonDiffReact
              left={leftSideData}
              right={rightSideData}
              show={showUnChangedData}
              tips={messageIfBothSideIdentical}
              objectHash={(obj: any) => obj.id || obj._id || obj.name || JSON.stringify(obj)}
          />
        </div>
      </Fragment>
  );
};

export default JSONDiffView;
