import _ from 'lodash';
import {
  keyLocationRegex,
  SettingsFilesTSLocations,
  SettingsTSFileOperationTypes,
} from './Constants';

const getSettingFileUpdateConfig = (filename) => {
  if(!filename) {
    return null;
  }

  const timestampLocations = SettingsFilesTSLocations[filename];
  if(!_.isEmpty(timestampLocations)) {
    return  {
      updateTimestamps: true,
      timestampLocations,
    }
  }

  return null;
};

const shouldShowJSONFileUpdater = (settingsFileData, jsonUpdaterData, showJSONUpdater) => {
  return settingsFileData && settingsFileData.data
      && showJSONUpdater && !_.isEmpty(jsonUpdaterData);
};

const getJSONUpdaterData = (fileName, settingsFileData, settingsFileUpdateData) => {
  if(settingsFileUpdateData.updatedFileName === fileName) {
    return settingsFileUpdateData.data;
  }

  return settingsFileData.data;
}

const shouldShowGcsFileVersionLister = (settingsFileVersionsData, showVersionLister) => {
  return settingsFileVersionsData && settingsFileVersionsData.data && showVersionLister;
};

const getSettingFilesWithTimestamps = () => {
  const settingsFilesWithTSLocations = _.pickBy(SettingsFilesTSLocations, _.identity);
  const settingsFilesWithTS = _.pick(SettingsFilesTSLocations, _.keys(settingsFilesWithTSLocations));

  return settingsFilesWithTS;
}

const shouldDisableTsUpdateBtn = (selectedTimestampLocationType, timestampLocationSelect,
                                timestampLocationManual, validTimestampLocations) => {
  if(SettingsTSFileOperationTypes[selectedTimestampLocationType]
      === SettingsTSFileOperationTypes.manual) {
    return !(timestampLocationManual && validTimestampLocations);
  } else if(SettingsTSFileOperationTypes[selectedTimestampLocationType]
      === SettingsTSFileOperationTypes['check-box']) {
    const selectedOptions = _.pickBy(timestampLocationSelect, _.identity)
    return _.isEmpty(selectedOptions);
  } else {
    return false;
  }
};

const getTimestampLocations = (selectedTimestampLocationType,
                              timestampLocationSelect, timestampLocationManual) => {
  if(SettingsTSFileOperationTypes[selectedTimestampLocationType]
      === SettingsTSFileOperationTypes.manual) {
    return timestampLocationManual ? timestampLocationManual.split(',') : null;
  } else if(SettingsTSFileOperationTypes[selectedTimestampLocationType]
      === SettingsTSFileOperationTypes['check-box']) {
    return _.keys(_.pickBy(timestampLocationSelect, _.identity));
  } else {
    return null;
  }
}

const isValidKeyLocation = (keyLocation) => {
  if(!keyLocation) {
    return false;
  }

  return keyLocationRegex.test(keyLocation);
};


const isValidKeyLocations = (keyLocationsStr) => {
  if(!keyLocationsStr) {
    return false;
  }

  const keyLocations = keyLocationsStr.split(',');
  let isValid = true;
  keyLocations.forEach(keyLocation => {
    if(!isValidKeyLocation(keyLocation)) {
      isValid = false;
    }
  })

  return isValid;
};

const getKeyValues = (keyLocations, jsonValue, operationType) => {
  return [
    {
      "locations": [keyLocations],
      "value" : jsonValue,
      "operation": operationType,
    }
  ]
};

const shouldDisableKvUpdateButton = (validLocations, selectedUpdateType) => {
  return !(validLocations && selectedUpdateType);
};

const getKeyValueUpdateConfig = (fileName) => {
  const settingFilesWithTs = getSettingFilesWithTimestamps();
  if(_.keys(settingFilesWithTs).includes(fileName)) {
    return {
      'updateTimestamps': true,
      'timestampLocations': settingFilesWithTs[fileName],
    }
  } else {
    return {
      'updateTimestamps': false,
    }
  }
};

export {
  getSettingFileUpdateConfig,
  shouldShowJSONFileUpdater,
  getJSONUpdaterData,
  shouldShowGcsFileVersionLister,
  getSettingFilesWithTimestamps,
  shouldDisableTsUpdateBtn,
  getTimestampLocations,
  isValidKeyLocations,
  getKeyValues,
  shouldDisableKvUpdateButton,
  getKeyValueUpdateConfig,
}
