import { useState } from 'react';
import axiosService from '../../../../service_v2/common/axios-service';

export const useCreateShort = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const createShort = async (shortMedia, handleUpdate) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await axiosService.post(
        `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}qalbox-admin-short-service/v1/create/`,
        { short_content: shortMedia }
      );

      const json = response?.data;

      if (json) {
        setSuccess(true);
        handleUpdate(json);
      } else {
        setError("Failed to create short");
      }
    } catch (error) {
      setError("An error occurred while creating the short");
    } finally {
      setLoading(false);
    }
  };

  return [ createShort, loading, error, success ];
};