import { useState, useEffect } from 'react';

export const useFetchBrands = (active, refresh) => {
  const [brandsResponse, setBrandsResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBrands = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}qalbox-admin-brand-service/v1/get/all?active=${active}&limit=100&page=1`
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch brands (status ${response.status})`);
        }

        const brands = await response.json();
        setBrandsResponse(brands);
      } catch (err) {
        console.error(err);
        setError(err.message || 'An error occurred while fetching brands');
      } finally {
        setIsLoading(false);
      }
    };

    fetchBrands();

  }, [active, refresh]); // Fetch brands whenever the 'active' parameter changes

  return [ brandsResponse, isLoading, error ];
};