import React,
{
  Component,
} from 'react';
import './style.css';
import AdminUserService from "../../service_v2/users/admin-user-service";

class UndoBar extends Component {

  getMessage(trigger, actionObj) {
    let message = "", uid = actionObj.uid, requestId = actionObj.requestId;
    switch (trigger) {
      case 'blockUser':
        message = `The user ${uid} has been blocked.`;
        break;
      case 'unblockUser':
        message = `The user ${uid} has been unblocked.`;
        break;
      case 'blockRequest':
        message = `The request ${requestId} has been blocked.`;
        break;
      case 'unblockRequest':
        message = `The request ${requestId} has been blocked.`;
        break;
      default:
    }
    return message;
  }

  undoOperation(trigger, actionObj, config) {
    switch (trigger) {
      case 'blockUser':
        this.unblockUser(actionObj.uid, config);
        break;
      case 'unblockUser':
        this.blockUser(actionObj.uid, config);
        break;
      case 'blockRequest':
        this.unblockRequest(actionObj.uid, actionObj.requestId, actionObj.languageCode, config);
        break;
      case 'unblockRequest':
        this.blockRequest(actionObj.uid, actionObj.requestId, actionObj.languageCode, config);
        break;
      default:
    }
  }

  blockUser(uid, config) {
    const {
      toast,
      firebase,
      createLog,
    } = config;
    createLog('block_user', {user_id: uid});
    return AdminUserService.setUserData(uid, { blocked: true }).then(() => {
      toast.success(`User ${uid} has been blocked`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      this.props.hideUndo();
    })
    .catch((err) => {
      console.log(err);
      this.props.hideUndo();
    });
  }

  hideUndo() {
    this.props.hideUndo();
  }

  unblockUser(uid, config) {
    const {
      toast,
      firebase,
      createLog,
    } = config;
    createLog('unblock_user', {
      user_id: uid,
    });
    return AdminUserService.setUserData(uid, { blocked: false }).then(() => {
      toast.success(`User ${uid} has been unblocked`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      this.props.hideUndo();
    })
    .catch((err) => {
      console.log(err);
      this.props.hideUndo();
    });
  }

  blockRequest(uid, reqId, languageCode, config) {
    const {
      toast,
      firebase,
      createLog,
    } = config;
    createLog('block_prayer_request', {
      language: languageCode,
      request_id: reqId
    });
    return AdminUserService.setUserData(uid, { [`prayer-requests/${reqId}/blocked`]: true }).then(() => {
      toast.success(`Prayer request ${reqId} has been blocked`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      this.props.hideUndo();
    })
    .catch((err) => {
      console.log(err);
      this.props.hideUndo();
    });
  }

  unblockRequest(uid, reqId, languageCode, config) {
    const {
      toast,
      firebase,
      createLog,
    } = config;
    createLog('unblock_prayer_request', {
      language: languageCode,
      request_id: reqId,
    });
    return AdminUserService.setUserData(uid, { [`prayer-requests/${reqId}/blocked`]: false }).then(() => {
      toast.success(`Prayer request ${reqId} has been unblocked`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      this.props.hideUndo();
    })
    .catch((err) => {
      console.log(err);
      this.props.hideUndo();
    });
  }

  render() {
    const {
      undoObj,
    } = this.props;
    if(!undoObj) {
      return null;
    }
    const {
      config,
      trigger,
      actionObj,
    } = undoObj;
    return (
      <div className="undo__bar">
        {this.getMessage(trigger, actionObj)} <span onClick={this.undoOperation.bind(this, trigger, actionObj, config)} className="undo__action_btn">Undo</span><span onClick={this.hideUndo.bind(this)} className="undo__action_btn">Dismiss</span>
      </div>
    );
  }
}

export default UndoBar;
