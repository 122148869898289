// Raw JSON Update Actions
const RJ_UPDATE_FILE_NAME = 'RJ_UPDATE_FILE_NAME';
const RJ_UPDATE_JSON_VALUE = 'RJ_UPDATE_JSON_VALUE';
const RJ_RESET_ALL = 'RJ_RESET_ALL';
const RJ_RESET_CURRENT_FILE = 'RJ_RESET_CURRENT_FILE';
const RJ_CONFIRM_PRECALC_LIST_UPDATE = 'RJ_CONFIRM_PRECALC_LIST_UPDATE';

// List Merge Actions
const LM_UPDATE_PRECALC_LIST_INDEX = 'LM_UPDATE_PRECALC_LIST_INDEX';
const LM_RESET_MERGE = 'LM_RESET_MERGE';
const LM_RESET_CURRENT_MERGE = 'LM_RESET_CURRENT_MERGE';
const LM_UPDATE_CURRENT_MERGE = 'LM_UPDATE_CURRENT_MERGE';
const LM_CONFIRM_PRECALC_LIST_UPDATE = 'LM_CONFIRM_PRECALC_LIST_UPDATE';

export const actionTypes = {
  RJ_UPDATE_FILE_NAME,
  RJ_UPDATE_JSON_VALUE,
  RJ_RESET_ALL,
  RJ_RESET_CURRENT_FILE,
  RJ_CONFIRM_PRECALC_LIST_UPDATE,
  LM_UPDATE_PRECALC_LIST_INDEX,
  LM_UPDATE_CURRENT_MERGE,
  LM_RESET_MERGE,
  LM_RESET_CURRENT_MERGE,
  LM_CONFIRM_PRECALC_LIST_UPDATE,
};