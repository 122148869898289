import React,
{
  Component,
} from 'react';
import BasicLayout from '../../layouts/basiclayout';
import DateTime from '../../widgets/datetime';
import './style.css';
import rtdbRef from "../../apis/rtdbRef";

class OnetimePromoCodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoCodes: null,
      filter: "all",
      secondaryFilter: "all",
      mods: null,
      uniqueCampaigns: [],
      queryParams: null,
      campaign: "all",
      createdBy: "all"
    };
  }

  componentWillMount() {
    const moderators = localStorage.getItem('moderatorsInfo') ? JSON.parse(localStorage.getItem('moderatorsInfo')) : {};
    let mods = {};
    Object.keys(moderators).forEach((modUid) => {
      mods[modUid] = moderators[modUid].displayName;
    });
    this.setState({mods});
    if(this.props.location.search !== "") {
      const search = this.props.location.search.substring(1);
      const queryParams = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
      this.setState({
        queryParams,
        secondaryFilter: queryParams.campaign,
        childKey: 'campaign'
      });
      if(localStorage.getItem('uniqueCampaigns')) {
        this.setState({
          uniqueCampaigns: JSON.parse(localStorage.getItem('uniqueCampaigns'))
        });
        this.fetchPromoCodes("all", queryParams.campaign, "campaign");
      } else {
        this.fetchPromoCodes("all", "all");
      }
    } else {
      this.fetchPromoCodes("all", "all");
    }
  }

  fetchPromoCodes(filter, secondaryFilter, childKey) {
    if(secondaryFilter === 'all') {
      rtdbRef.promoCodes("/promoCodes").on("value", (snapshot) => {
        const promoCodes = snapshot.val();
        this.setState({promoCodes});
        this.saveUniqueCampaigns(promoCodes);
      });
    } else {
      rtdbRef.promoCodes("/promoCodes").orderByChild(childKey).equalTo(secondaryFilter).on("value", (snapshot) => {
        const promoCodes = snapshot.val();
        this.setState({promoCodes});
      });
    }
  }

  saveUniqueCampaigns(promoCodes) {
    let { uniqueCampaigns, mods } = this.state;
    let uniqueCreatedBys = [];
    Object.keys(promoCodes).forEach((key) => {
      const camp = promoCodes[key]['campaign'];
      const createdBy = promoCodes[key]['created_by'];
      if(uniqueCampaigns.indexOf(camp) === -1) {
        uniqueCampaigns.push(camp);
      }
      if(uniqueCreatedBys.indexOf(createdBy) === -1) {
        uniqueCreatedBys.push(createdBy);
      }
    });
    Object.keys(mods).forEach((modUid) => {
      if(uniqueCreatedBys.indexOf(modUid) === -1) {
        delete mods[modUid];
      }
    });
    this.setState({uniqueCampaigns, mods});
    localStorage.setItem('uniqueCampaigns', JSON.stringify(uniqueCampaigns));
  }

  renderPromoCodes() {
    const { promoCodes, filter, mods, createdBy } = this.state;
    let rows, redeemedCount = 0, totalCount = 0;

    if(!promoCodes) {
      rows = null;
    } else {
      const promoCodesKeys = Object.keys(promoCodes);
      // totalCount = promoCodesKeys.length;
      let count = 1;
      rows = promoCodesKeys.map((key, i) => {
        totalCount++;
        const promoCodeItem = promoCodes[key];
        if(createdBy !== 'all' && promoCodeItem.created_by !== createdBy) {
          totalCount--;
          return null;
        }
        const redeemedDateTime = promoCodeItem.redeem_datetime ? <DateTime timestamp={promoCodeItem.redeem_datetime} /> : "N/A";
        const redeemedClassName = promoCodeItem.redeem_datetime ? "promo__redeemed_row" : "";
        const { type, campaign, expiry, user_id, created_by, duration } = promoCodeItem;

        if(user_id) {
          redeemedCount++;
        }
        if(filter === 'all') {
          return this.renderRowVal(count++, key, type, campaign, expiry, user_id, created_by, redeemedClassName, redeemedDateTime, mods[created_by], duration);
        } else if(filter === 'redeemed' && user_id) {
          return this.renderRowVal(count++, key, type, campaign, expiry, user_id, created_by, redeemedClassName, redeemedDateTime, mods[created_by], duration);
        } else if(filter === 'notredeemed' &&  !user_id) {
          return this.renderRowVal(count++, key, type, campaign, expiry, user_id, created_by, redeemedClassName, redeemedDateTime, mods[created_by], duration);
        } else {
          totalCount--;
          return null;
        }
      });
    }

    const promoCountInfo = !totalCount ? <span>Data being processed...</span> : <span>{redeemedCount} promo codes has been redeemed out of {totalCount}. {totalCount-redeemedCount} remains.</span>;

    return (
      <div className="promo_code_table_wrapper">
        <p className="promo__count">{promoCountInfo}</p>
        <table className="table promo_code_table small">
          <thead>
            <tr>
              <th>SL</th>
              <th>Promo code</th>
              <th>Type</th>
              <th>Campaign</th>
              <th>Created by</th>
              <th>Expiry</th>
              <th>Redeem status</th>
              <th>Redeemed at</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  }

  renderRowVal(i, key, type, campaign, expiry, uid, created_by, redeemedClassName, redeemedDateTime, displayName, duration) {
    type = (type === 'monthly' || type === "yearly") ? `${type} (${duration})` : type;
    return <tr className={redeemedClassName} key={key}>
      <td>{i}</td>
      <td><a href={"https://console.firebase.google.com/project/muslim-pro-app/database/mp-promo-codes/data/promoCodes/"+key} target="_blank">{key}</a></td>
      <td>{type}</td>
      <td>{campaign}</td>
      <td><a className="truncated_col_el" target="_blank" href={"https://muslim-pro-app.firebaseapp.com/search-user/"+created_by}>{displayName || (created_by.startsWith("webui") ? "WebUI" : created_by)}</a></td>
      <td>{expiry}</td>
      {this.renderRedeemedBy(uid)}
      <td>{redeemedDateTime}</td>
    </tr>;
  }

  renderRedeemedBy(uid) {
    if(!uid) {
      return <td>Not redeemed</td>;
    } else {
      // URL USAGE ( UN-REFACTORED )
      return <td><span className="truncated_col_el">By <a target="_blank" href={"https://console.firebase.google.com/project/muslim-pro-app/database/muslim-pro-app/data/users/"+uid}>{uid}</a></span></td>;
    }
  }

  handleFilter(e) {
    this.setState({
      filter: e.target.value
    });
    this.fetchPromoCodes(e.target.value, this.state.secondaryFilter, this.state.childKey);
  }

  handleCampaignFilter(e) {
    this.setState({
      secondaryFilter: e.target.value,
      campaign: e.target.value,
      childKey: 'campaign'
    });
    // this.fetchPromoCodes(this.state.filter, e.target.value, 'campaign');
    window.location = `/onetime-promo-codes?campaign=${e.target.value}`;
  }

  handleCreatedFilter(e) {
    this.setState({
      // secondaryFilter: e.target.value,
      // childKey: 'created_by',
      createdBy: e.target.value
    });
    // window.location = `/onetime-promo-codes?campaign=${this.state.campaign}&createdBy=${e.target.value}`;
    // this.fetchPromoCodes(this.state.filter, e.target.value, 'created_by');
  }

  renderCreatedByFilter(mods) {
    if(!mods) {
      return <span className="small"> Loading...</span>;
    }
    const modsKey = Object.keys(mods);
    const modFilterOpts = modsKey.map((key, i) => {
      return <option key={i} value={key}>{mods[key]}</option>;
    });
    return <select value={this.state.createdBy} onChange={this.handleCreatedFilter.bind(this)} className="promo__filter">
      <option value="all">All</option>
      {modFilterOpts}
    </select>;
  }

  renderCampaignFilter(uniqueCampaigns) {
    if(uniqueCampaigns.length === 0) {
      return <span className="small"> Loading...</span>;
    }
    const campaignFilterOpts = uniqueCampaigns.sort().map((item, i) => {
      return <option key={i} value={item}>{item}</option>;
    });
    return <select value={this.state.secondaryFilter} onChange={this.handleCampaignFilter.bind(this)} className="promo__filter">
      <option value="all">All</option>
      {campaignFilterOpts}
    </select>;
  }

  renderFilter() {
    const { mods, uniqueCampaigns } = this.state;

    return (
      <div>
        <span>Show</span>
        {' '}
        <select value={this.state.filter} onChange={this.handleFilter.bind(this)} className="promo__filter">
          <option value="all">All</option>
          <option value="redeemed">Already redeemed</option>
          <option value="notredeemed">Not redeemed</option>
        </select>
        {' '}
        <span>Campaign</span>
        {' '}
        {this.renderCampaignFilter(uniqueCampaigns)}
        {' '}
        <span>Created by</span>
        {' '}
        {this.renderCreatedByFilter(mods)}
      </div>
    );
  }


  render() {
    return (
      <BasicLayout pagePermission="read_codes">
        {this.renderFilter()}
        {this.renderPromoCodes()}
      </BasicLayout>
    );
  }
}

export default OnetimePromoCodes;
