import firebase from "firebase";

const getUserRewardMeta = async (uid, debug= false) => {
  const app = firebase.app();
  const dbPath = `/${debug ? 'staging' : 'production'}/user-rewards/${uid}/STREAK`;
  const snapshot = await app.database('https://mp-rewards.firebaseio.com').ref(dbPath).once('value');
  return snapshot.val();
}

const getRewardDef = async (debug= false) => {
  const app = firebase.app();
  const dbPath = `/${debug ? 'staging' : 'production'}/definitions`;
  const snapshot = await app.database('https://mp-rewards.firebaseio.com').ref(dbPath).once('value');
  return snapshot.val();
}

export {
  getUserRewardMeta,
  getRewardDef,
}
