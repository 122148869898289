import _ from "lodash";
import { useEffect } from "react";
import { qalBoxSearchMediaTagHook } from "../../../../hooks";
import { convertArrayToSelectList } from "../../helper";
import QalboxMultiSelectDropdown from "../../media/helperComponents/qalboxMultiSelect";
import { ScaleLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";

export const QalboxTagsDropdown = ({tags, handleMultiSelect}) => {
  const [responseMediaTagData, setMediaTagOperationData] = qalBoxSearchMediaTagHook();

  const requestMediaTagData = () => {
    setMediaTagOperationData({
      searchConfig: {
        language_code: 'en',
        active: true,
      },
    });
  };

  useEffect(() => {
    requestMediaTagData();
  }, []);

  return (
    <LoadingOverlay
      active={_.get(responseMediaTagData, "isLoading", false)}
      spinner={<ScaleLoader />}
      text="Loading...."
    >
      <div className="form-group">
        <label>Tags</label>
        <QalboxMultiSelectDropdown
          name={"tags"}
          onChange={handleMultiSelect}
          selectedOptions={convertArrayToSelectList(tags)}
          options={convertArrayToSelectList(
            _.get(responseMediaTagData, "data", []).map((x) => x.name)
          )}
        />
      </div>
    </LoadingOverlay>
  );
};