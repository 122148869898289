import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  hijriUpdateRawJSON,
} from '../../services/mp-service-v1-api-service';

const updateHijriFile = () => {
  const [ hijriFileUpdateData, setHijriFileUpdateData ] = useState({ });
  const [ updateData, setUpdateData ] = useState(null);
  const {
    updatedFile,
    onOperationComplete,
    updateTimeStamp=true,
  } = updateData || {};

  const updateHijriRawFile = async () => {
    try {
      console.log(`Updating hirji file...`);
      setHijriFileUpdateData({
        isUpdating: true,
        isError: false,
      });


      if(updatedFile && updateTimeStamp) {
        updatedFile['timestamp'] = Math.floor(new Date().getTime()/1000);
      }
      const getRawFileApiResp =  await hijriUpdateRawJSON({
        updatedFile,
      });

      const responseData = getRawFileApiResp.data;
      let errorMsg = null;
      if(responseData.success) {
        console.log(`Updating hirji file Success!!`);
      } else {
        console.log(`Updating hirji file Failed!!`);
        errorMsg = `Error occurred while updating hirji file.`;
      }

      setHijriFileUpdateData({
        data: responseData.data,
        isUpdating: false,
        isError: !responseData.success,
        errorMsg,
      });
      onOperationComplete({
        isError: !responseData.success,
        errorMsg,
        data: responseData.data,
      });
    } catch (err) {
      const errorMsg = (err.response && err.response.status === 404) ?
          `Hirji file not found!` : `Error occurred while updating hirji file.`;
      console.log(`${errorMsg} Error : ${err}`);
      setHijriFileUpdateData({
        data: null,
        isUpdating: false,
        isError: true,
        errorMsg,
      });
      onOperationComplete({
        isError: true,
        errorMsg,
        data: null
      });
    }
  };

  useEffect( () => {
    if(!_.isEmpty(updateData)) {
      updateHijriRawFile();
    }
  }, [ updateData ]);

  return [
    hijriFileUpdateData,
    setUpdateData,
  ];
};

export default updateHijriFile;
