import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  settingsGetRawFile,
} from '../../services/mp-service-v1-api-service';

const fetchSettingsFile = () => {
  const [ settingsFileData, setSettingsFileData ] = useState({ });
  const [ fetchData, setFetchData ] = useState(null);
  const {
    fileName,
    onOperationComplete,
  } = fetchData || {};

  const fetchSettingsRawFile = async () => {
    try {
      console.log(`Fetching file: ${fileName}...`);
      setSettingsFileData({
        isFetching: true,
        isError: false,
        fetchedFileName: fileName,
      });
      const getRawFileApiResp =  await settingsGetRawFile({
        fileName,
      });

      const responseData = getRawFileApiResp.data;
      let errorMsg = null;
      if(responseData.success) {
        console.log(`Fetching file: ${fileName} Success!!`);
      } else {
        console.log(`Fetching file: ${fileName} Failed!!`);
        errorMsg = `Error occurred while fetching file: ${fileName}.`;
      }

      setSettingsFileData({
        data: responseData.data,
        isFetching: false,
        isError: !responseData.success,
        errorMsg,
        fetchedFileName: fileName,
      });
      onOperationComplete({
        isError: !responseData.success,
        errorMsg,
      });
    } catch (err) {
      console.log(`Error while fetching file: ${fileName}. Error : ${err}`);
      const errorMsg = (err.response && err.response.status === 404) ?
          `File: ${fileName} not found!` : `Error occurred while fetching file: ${fileName}.`;
      setSettingsFileData({
        data: null,
        isFetching: false,
        isError: true,
        errorMsg,
        fetchedFileName: fileName,
      });
      onOperationComplete({
        isError: true,
        errorMsg,
      });
    }
  };

  useEffect( () => {
    if(!_.isEmpty(fetchData)) {
      fetchSettingsRawFile();
    }
  }, [ fetchData ]);

  return [
    settingsFileData,
    setFetchData,
  ];
};

export default fetchSettingsFile;
