// Settings Raw JSON Update Actions
const SRJ_UPDATE_PROP_VAL = 'SRJ_UPDATE_PROP_VAL';
const SRJ_FETCH_FILE = 'SRJ_FETCH_FILE';
const SRJ_RESET_STATE = 'SRJ_RESET_STATE';

// Settings Update Timestamps Actions
const SUT_UPDATE_PROP_VAL = 'SUT_UPDATE_PROP_VAL';
const SUT_FETCH_FILE = 'SUT_FETCH_FILE';
const SUT_RESET_STATE = 'SUT_RESET_STATE';

// Settings Update Key Values Actions
const SUKV_UPDATE_PROP_VAL = 'SUKV_UPDATE_PROP_VAL';
const SUKV_FETCH_FILE = 'SUKV_FETCH_FILE';
const SUKV_RESET_STATE = 'SUKV_RESET_STATE';

// Settings List File Versions Actions
const SLF_UPDATE_PROP_VAL = 'SLF_UPDATE_PROP_VAL';
const SLF_FETCH_VERSIONS = 'SLF_FETCH_VERSIONS';

// Test Settings API Actions
const STA_UPDATE_PROP_VAL = 'STA_UPDATE_PROP_VAL';
const STA_RESET_STATE = 'STA_RESET_STATE';

export const actionTypes = {
  SRJ_UPDATE_PROP_VAL,
  SRJ_FETCH_FILE,
  SRJ_RESET_STATE,
  SLF_UPDATE_PROP_VAL,
  SLF_FETCH_VERSIONS,
  SUT_UPDATE_PROP_VAL,
  SUT_FETCH_FILE,
  SUT_RESET_STATE,
  SUKV_UPDATE_PROP_VAL,
  SUKV_FETCH_FILE,
  SUKV_RESET_STATE,
  STA_UPDATE_PROP_VAL,
  STA_RESET_STATE,
};