import _ from "lodash";
import { useEffect, useState } from "react";
import { convertArrayToObjMap } from "../../helper";
import { MPSelect } from "../../../../widgets/common";
import { DISPLAY_CATEGORY_TYPE } from "../../Constants";
import { ScaleLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import { useFetchBrands } from "../../brands/hooks/useFetchBrand";

const typeArr = convertArrayToObjMap(DISPLAY_CATEGORY_TYPE);

export const QalboxDisplayCategoryTypesDropdown = ({type, onChange}) => {
  const [typeList, setTypeList] = useState(typeArr);
  const [brandList = [], isLoading] = useFetchBrands(true);

  const handleSelect = (option) => {
    const brand = brandList.find((brandObject) => brandObject.branded_partner_page === option);
    onChange(option, brand?.uuid);
  };

  useEffect(() => {
    if (brandList) {
      const brandNameList = brandList.map(brand => brand.branded_partner_page);
      const brandSelectObjectList = convertArrayToObjMap(brandNameList);
      setTypeList(typeArr.concat(brandSelectObjectList));
    }
  }, [brandList]);

  return (
    <LoadingOverlay
      active={isLoading}
      spinner={<ScaleLoader />}
      text="Loading...."
    >
      <div className="form-group">
        <label>Type</label>
        <MPSelect
          items={typeList}
          selectedItem={type}
          onChangeSelect={handleSelect}
        />
      </div>
    </LoadingOverlay>
  );
};