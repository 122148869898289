import _ from "lodash";
import { useState, useEffect } from "react";
import { qalBoxDuplicateManifest } from "../../services/mp-cf-api-service";

const duplicateManifest = () => {
  const [responseData, setResponseData] = useState({});
  const [updateData, setUpdateData] = useState(null);
  const { media, onDuplicateManifestComplete } = updateData || {};

  const duplicateManifestItem = async () => {
    try {
      console.log(`QalBox purge CDN cache...`);
      setResponseData({
        isUpdating: true,
        isError: false,
      });

      const mediaItemsSyncApiResp = await qalBoxDuplicateManifest({
        dash_file_name: media.folder_path,
      });
      const responseData = mediaItemsSyncApiResp.data;

      let errorMsg = null;
      let isError = false;

      if (responseData.success) {
        console.log("QalBox purge CDN cache Success!!");
      } else {
        console.log("QalBox purge CDN cache Failed!!");
        errorMsg = `Failed to purge CDN cache items.`;
        isError = true;
      }

      setResponseData({
        isUpdating: false,
        isError,
        errorMsg,
        data: responseData.payload,
      });

      if (onDuplicateManifestComplete) {
        onDuplicateManifestComplete({
          isError,
          errorMsg,
          data: responseData.payload,
        });
      }
    } catch (err) {
      console.log(
        "Something went wrong while purge CDN cache. ",
        err
      );
      const errorMsg = `Error occurred while purge CDN cache.`;
      setResponseData({
        isUpdating: false,
        isError: true,
        errorMsg,
        data: null,
      });
      if (onDuplicateManifestComplete) {
        onDuplicateManifestComplete({
          isError: true,
          errorMsg,
          data: null,
        });
      }
    }
  };

  useEffect(() => {
    if (!_.isEmpty(updateData)) {
      duplicateManifestItem();
    }
  }, [updateData]);

  return [responseData, setUpdateData];
};

export default duplicateManifest;