import React,
{
  useState,
  useEffect,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import moment from 'moment';
import {
  getUsersActivePromoCode,
} from '../../utils';

const PremiumActivatedBy = (props) => {
  const [ activatePromoCode, setSctivatePromoCode ] = useState();
  const [ fetching, setFetching ] = useState(false);
  const mods = localStorage.getItem('moderatorsInfo') ? JSON.parse(localStorage.getItem('moderatorsInfo')) : {};
  const [moderators] = useState(mods);

  useEffect(() => {
    setFetching(true);
    const get = async () => {
      const { firebaseAcc, muslimProAcc } = props.user;
      const result = await getUsersActivePromoCode(firebaseAcc.uid, muslimProAcc.premium_status.premium);
      setSctivatePromoCode(result);
      setFetching(false);
    };
    get();
  }, []);


  const renderStatus = () => {
    if(fetching) {
      return <p>Fetching info...</p>;
    } else {
      if(!activatePromoCode) {
        return <p>App/Play Store <span className="small">Please check the purchases below</span></p>;
      } else {
        const { campaign, redeem_datetime, code, created_by } = activatePromoCode;
        let source = 'Admin console',
            generatorLabel = 'Generated by',
            campaignLabel = 'Campaign',
            campaignValue = campaign;
        if(campaign.startsWith("GIFT_")) {
          source = 'Gift A Premium';
          generatorLabel = 'Purchased by';
          const tokens = campaign.split("_");
          const orderId = tokens[1];
          campaignLabel = 'Order ID';
          const urlToGiftAPremium = `/list-gift-a-premiums/${orderId}`;
          const linkToGiftAPremium = <a href={urlToGiftAPremium} target="_blank">{orderId}</a>;
          campaignValue = linkToGiftAPremium;

        }
        const urlToPromoCode = `/search-promo-code/${code}`;
        const linkToPromoCode = <a href={urlToPromoCode} target="_blank">{code}</a>;

        return <div>
          <p>Promo code</p>
          <Row className="small premium__activatedby">
            <Col xs={4}>Source:</Col>
            <Col xs={8}>{source}</Col>
            <Col xs={4}>Code:</Col>
            <Col xs={8}>{linkToPromoCode}</Col>
            <Col xs={4}>{generatorLabel}:</Col>
            <Col xs={8}>{moderators[created_by] ? moderators[created_by].displayName : created_by}</Col>
            <Col xs={4}>{campaignLabel}:</Col>
            <Col xs={8}>{campaignValue}</Col>
            <Col xs={4}>Redeemed at:</Col>
            <Col xs={8}>{moment(redeem_datetime).format('LLLL')}</Col>
          </Row>
        </div>;
      }
    }
  };

  return renderStatus();
};

export default PremiumActivatedBy;
