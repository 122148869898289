import _ from "lodash";
import { useEffect } from "react";
import { convertArrayToSelectList, convertArrayToSelectListV2 } from "../../helper";
import { qalBoxSearchDisplayCategoriesHook } from "../../../../hooks";
import QalboxDisplayCategoryMultiSelectDropdown from "../../media/helperComponents/qalboxDisplayCategoriesMultiSelect";
import LoadingOverlay from "react-loading-overlay";
import { ScaleLoader } from "react-spinners";

export const QalboxDisplayCategoriesDropdown = ({displayCategories, handleMultiSelect}) => {
  const [displayCategoryResponseData, setDisplayCategoryReuqestData] = qalBoxSearchDisplayCategoriesHook();

  const handleDropDownSelect = (name, array) => {
    handleMultiSelect(name, array, _.get(displayCategoryResponseData, "data", []));
  }

  const requestDisplayCategoryData = () => {
    setDisplayCategoryReuqestData({
      searchConfig: {
        language_code: "en",
        active: true,
      },
    });
  };

  useEffect(() => {
    requestDisplayCategoryData();
  }, []);

  return (
    <LoadingOverlay
      active={_.get(displayCategoryResponseData, "isLoading", false)}
      spinner={<ScaleLoader />}
      text="Loading...."
    >
      <div className="form-group">
        <label>Display Categories</label>
        <QalboxDisplayCategoryMultiSelectDropdown
          name={"display_categories"}
          onChange={handleDropDownSelect}
          selectedOptions={convertArrayToSelectList(displayCategories)}
          options={convertArrayToSelectListV2(
            _.get(displayCategoryResponseData, "data", [])
          )}
          displayCategories={_.get(displayCategoryResponseData, "data", [])}
        />
      </div>
    </LoadingOverlay>
  );
};