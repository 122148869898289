import _ from 'lodash';
import {
  COUNTRIES,
  PRECALC_FILES,
  CACHE_FILE_TYPES,
  SHEET_NUMBER_TYPES,
  GOOGLE_SHEETS_BASE_URL,
  PRECALC_LIST_OPERATIONS,
} from './Constants';

const getDocuments = (countryCode) => {
  if(countryCode && countryCode !== 'default'){
    const CountrySheets = PRECALC_FILES[countryCode];
    return Object.keys(CountrySheets).map(key =>  ({
      key: CountrySheets[key],
      value: 'Document - ' + key
    }));
  }

  return null;
};

const getSelectedDocumentUrl = (documentId) => {
  if(documentId && documentId !== 'default') {
    return GOOGLE_SHEETS_BASE_URL + documentId
  }

  return null;
};

const shouldShowSubmitButton = (
    selectedSheetNumberType, selectedSheetNumbers,
    startSheetNumber, endSheetNumber, selectedDocument,
    ) => {

  if(selectedDocument === 'default') {
    return false;
  }

  switch (selectedSheetNumberType) {
    case SHEET_NUMBER_TYPES.All:
      return true;
    case SHEET_NUMBER_TYPES.Custom:
      return !_.isEmpty(selectedSheetNumbers);
    case SHEET_NUMBER_TYPES.Range:
      return startSheetNumber && endSheetNumber;
    default:
      return false;
  }
};

const getValidateOrPushApiPayload = ({
                                       selectedDocument, selectedSheetNumberType, selectedCountry,
                                       selectedSheetNumbers, startSheetNumber, endSheetNumber,
                                     }) => {
  switch (selectedSheetNumberType) {
    case SHEET_NUMBER_TYPES.All:
      return {
        'documentId': selectedDocument,
        'countryCode': selectedCountry,
        'mode': selectedSheetNumberType,
        'sheetNumbers': null
      };
    case SHEET_NUMBER_TYPES.Custom:
      return {
        'documentId': selectedDocument,
        'countryCode': selectedCountry,
        'mode': selectedSheetNumberType,
        'sheetNumbers': selectedSheetNumbers.map(sheetNumber => parseInt(sheetNumber.name, 10)),
      };
    case SHEET_NUMBER_TYPES.Range:
      return {
        'documentId': selectedDocument,
        'countryCode': selectedCountry,
        'mode': selectedSheetNumberType,
        'sheetNumbers': `${startSheetNumber}-${endSheetNumber}`
      };
    default:
      return null;
  }
};

const getUpdatedPrecalcsList = (selectedCountry, existingPrecalcsList, updatedPrecalcsList) => {
  return existingPrecalcsList[selectedCountry] = updatedPrecalcsList;
};

const getSuccessList = (apiResponseData) => {
  const successList = apiResponseData && apiResponseData['success_list'];
  return _.isEmpty(successList) ? null: successList;
};

const getFailedList = (apiResponseData) => {
  const failedList = apiResponseData && apiResponseData['failed_list'];
  return _.isEmpty(failedList) ? null: failedList;
};

const getPrecalcListUpdateStatus = (apiResponseData) => {
  return apiResponseData && apiResponseData['should_update_precalc_list_manually'];
};

const getSuccessListJSONToCopy = (successListObj) => {
  const successList = _.map(successListObj, (value, key) => {
    return {
      'Sheet Name' : key,
      'Saved File Name': value['file_id'] || 'Did not save this file.',
    }
  });

  return JSON.stringify(successList)
};

const getErrorListJSONToCopy = (errorListObj) => {
  const failedList = _.map(errorListObj, (value, key) => {
    return {
      'Sheet Name' : key,
      'Errors Count': value['errors_count'],
      'All Sheet Errors': value['errors'],
    }
  });

  return JSON.stringify(failedList)
};

const getDiffableOldCountryListJSON = (selectedCountryOldJSON, selectedCountryNewJSON) => {
  const newIds = _.uniq(_.map(selectedCountryNewJSON, (value) => value['id']));
  return getDiffableOldCountryListJSONById(selectedCountryOldJSON, newIds);
};

const getDiffableOldCountryListJSONById = (selectedCountryJSON, ids) => {
  return _.filter(selectedCountryJSON, (item) => _.includes(ids, item.id));
};

const getDiffableCountryListJSONById = (diffableCountryJSON) => {
  return _.groupBy(diffableCountryJSON, item => item.id)
};

const getPrecalcListIds = (precalcListJSON) => {
  if(_.isEmpty(precalcListJSON)) {
    return [];
  }

  return Object.keys(precalcListJSON).sort();
};

const shoulDisableUpdatePrecaclListButton = (precalcListIds, mergedCountryJSONById) => {
  return precalcListIds.length
      !== Object.keys(_.pickBy(mergedCountryJSONById, item => item.length)).length
};

const getUpdatedCountryPrecalcList = (mergedCountryJSONById, existingPrecalcsList,
                                      selectedCountry) => {
  let currentCountryPrecalcs = existingPrecalcsList[selectedCountry];
  if(_.isEmpty(currentCountryPrecalcs)) {
    currentCountryPrecalcs = [];
  }

  Object.keys(mergedCountryJSONById).forEach(key => {
    _.remove(currentCountryPrecalcs, item => item.id === key);
    currentCountryPrecalcs.push(...mergedCountryJSONById[key]);
  });

  return currentCountryPrecalcs;
};

const shouldShowDiffer = (selectedOperation, diffableCountryNewJSONById, showDiffer) => {
  return selectedOperation === PRECALC_LIST_OPERATIONS.UpdateWithDiffer
      && showDiffer
      && !_.isEmpty(diffableCountryNewJSONById);
};

const shouldShowJSONInput = (selectedOperation, showDiffer) => {
  return selectedOperation === PRECALC_LIST_OPERATIONS.UpdateWithDiffer && !showDiffer
};

const shouldDisableStartDiffBtn = (selectedOperation, diffableCountryNewJSONById) => {
  return selectedOperation !== PRECALC_LIST_OPERATIONS.UpdateWithDiffer
      || _.isEmpty(diffableCountryNewJSONById);
};

const getPrecalcListByFileName = (selectedCountryJSON, fileName) => {
  return _.filter(selectedCountryJSON, (item) =>  item.id === fileName);
};

const getUpdatedAndReplacedPrecalcsList = (selectedCountryJSON, updatedPrecalcsList, fileName) => {
  const filteredJSON = _.filter(selectedCountryJSON, item => item.id !== fileName);
  return filteredJSON.concat(updatedPrecalcsList);
};


const getPrecalcsListTitleForRawUpdate = (fileName, selectedCountry) => {
  return fileName ?
      `Precalcs List for ${COUNTRIES[selectedCountry]} [${selectedCountry}] - [${fileName}]` :
      `Precalcs List for ${COUNTRIES[selectedCountry]} [${selectedCountry}]`;
};

const shouldDisableCacheClearButton = (selectedFileType, selectedCountry, validFileName) => {
  if(selectedFileType === 'default') {
    return true;
  }

  if(CACHE_FILE_TYPES[selectedFileType] === CACHE_FILE_TYPES.PRECALC_FILE) {
    return selectedCountry === 'default' || !validFileName;
  }

  return false;
};

const getCacheKeys = (selectedFileType, selectedCountry, fileName) => {
  if(CACHE_FILE_TYPES[selectedFileType] === CACHE_FILE_TYPES.PRECALC_FILE) {
    if(fileName) {
      return { mainKey: `precalc-${selectedCountry}`, subKey: fileName }
    }

    return { mainKey: `precalc-${selectedCountry}` }
  }

  if(CACHE_FILE_TYPES[selectedFileType] === CACHE_FILE_TYPES.PRECALC_LIST) {
    return { mainKey: 'settings', subKey: 'precalc_list' }
  }

  // Assume as the CACHE_FILE_TYPES.SETTINGS_API
  return { mainKey: 'settings', subKey: 'settings_api_base' }
};

const getGlobalWarnings = (apiPayloadData) => {
  if(!apiPayloadData || _.isEmpty(apiPayloadData['global_errors'])) {
    return [];
  }

  return _.filter(apiPayloadData['global_errors'], item => item.type === 'warning');
};

const getSelectedSheets = (selectedSheetNumberType, selectedSheetNumbers, startSheetNumber,
                           endSheetNumber) => {

  switch (selectedSheetNumberType) {
    case SHEET_NUMBER_TYPES.All:
      return 'All';
    case SHEET_NUMBER_TYPES.Custom:
      return `${selectedSheetNumbers.map(sheetNumber => sheetNumber.name).join(',')}`;
    case SHEET_NUMBER_TYPES.Range:
      return `${startSheetNumber} - ${endSheetNumber}`;
    default:
      return 'None';
  }
};

const shouldDisableMergeResetCurrentButton = (mergedCountryJSONById, currentListId) => {
  return _.isEmpty(mergedCountryJSONById[currentListId]);
};

const shouldDisableMergeResetAllButton = (mergedCountryJSONById) => {
  return _.isEmpty(mergedCountryJSONById);
};

export {
  getDocuments,
  getSelectedDocumentUrl,
  shouldShowSubmitButton,
  getValidateOrPushApiPayload,
  getUpdatedPrecalcsList,
  getSuccessList,
  getFailedList,
  getPrecalcListUpdateStatus,
  getSuccessListJSONToCopy,
  getErrorListJSONToCopy,
  getDiffableOldCountryListJSON,
  getDiffableCountryListJSONById,
  getDiffableOldCountryListJSONById,
  getPrecalcListIds,
  shoulDisableUpdatePrecaclListButton,
  getUpdatedCountryPrecalcList,
  shouldShowDiffer,
  shouldShowJSONInput,
  shouldDisableStartDiffBtn,
  getPrecalcListByFileName,
  getUpdatedAndReplacedPrecalcsList,
  getPrecalcsListTitleForRawUpdate,
  shouldDisableCacheClearButton,
  getCacheKeys,
  getGlobalWarnings,
  getSelectedSheets,
  shouldDisableMergeResetCurrentButton,
  shouldDisableMergeResetAllButton,
}
