import _ from 'lodash';

const getExistingTags = tags => {
  if(_.isEmpty(tags)) {
    return [];
  }

  return tags.map(tag => { return { name: tag.tag, ...tag }});
};

const getEmailTags = tags => {
  return _.map(tags, 'tag');
};

export {
  getExistingTags,
  getEmailTags,
}