import _ from 'lodash';
import React,
{
  Fragment,
  Component,
} from 'react';
import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import {
  Icon,
} from 'react-fa';
import ToggleButton from 'react-toggle-button';
import BasicLayout from '../../layouts/basiclayout';
import {
  countries,
} from '../../services/utils';
import Select from 'react-select';
import ReactTags from 'react-tag-autocomplete';
import {
  ToastContainer,
  toast,
} from 'react-toastify';
import './style.css';
import {
  isValidHashTag,
  getYearAndDayCodes,
} from './helper';
import {
  createV2Content,
  updateV2HashTags,
  suggestV2HashTags,
  getContentMetaData,
} from '../../services/mp-cf-api-service';
import {
  getAdUnits,
  fetchAdUnit,
  updateAdUnit,
} from '../../services/admin-db-service';
import {
  FEATURED_TYPE,
} from './Constants';
import FileUploader from '../../widgets/fileUploader';
import {
  uploadNewImage,
} from '../../services/api-v1-services/image-services';
import {
  getCdnPublicFileUrl,
} from '../../utils';
import {
  CONTENT_TYPE,
  AUDIENCE_TYPE,
  ContentSupportedLanguages,
  ENGLISH_CHARACTERS_SUPPORTED_LANGUAGES,
} from './Constants';

const adPermittedURLs = [
  'muslimpro.com',
];

const SupportedLanguages = _.without(_.keys(ContentSupportedLanguages), 'all');

const countryOptions = Object.keys(countries).map((countryCode) => {
  return {
    value: countryCode,
    label: countries[countryCode]
  }
});

const contentTypes = Object.keys(_.omit(CONTENT_TYPE, 'QUOTE')).map((type) => {
  return {
    key: CONTENT_TYPE[type],
    value: CONTENT_TYPE[type]
  }
});

const audienceTypes = Object.keys(AUDIENCE_TYPE).map((audienceType) => {
  return {
    value: AUDIENCE_TYPE[audienceType],
    label: audienceType
  }
});

const featuredTypes = Object.keys(FEATURED_TYPE).map((featuredType) => {
  return {
    value: FEATURED_TYPE[featuredType],
    label: featuredType
  }
});

delete ContentSupportedLanguages["all"];
const languages = Object.keys(ContentSupportedLanguages).map((languageCode) => {
  return {
    key: languageCode,
    value: ContentSupportedLanguages[languageCode]
  }
});

function curateLanguageCode(language_code) {
  if(!language_code || language_code.trim() === "" || SupportedLanguages.indexOf(language_code) === -1) {
    return "en";
  } else {
    return language_code;
  }
}

function getDataFromMeta(metaKey, el) {
  const metaName = el.attr('name');
  const metaProp = el.attr('property');
  if(metaName === `og:${metaKey}` || metaName === `twitter:${metaKey}` || metaProp === `og:${metaKey}` || metaProp === `twitter:${metaKey}`) {
    return el.attr('content');
  }
}

function showAds(url) {
  let domain, found = false,
  url_temp = url.split('//');
  if(url_temp.length === 1) {
    domain = (url_temp[0].split('/'))[0];
  } else {
    domain = (url_temp[1].split('/'))[0];
  }
  for(let i=0; i<adPermittedURLs.length; i++) {
    if(domain.endsWith(adPermittedURLs[i])) {
      found = true;
      break;
    }
  }
  return found;
}

// function warning(msg) {
//   toast.warning(msg, {
//     position: toast.POSITION.BOTTOM_RIGHT
//   });
// }

function getHashTags(hashTags) {
  if(_.isEmpty(hashTags)) {
    return [];
  }

  return hashTags.map(hashTag => { return { name: hashTag }});
}

const getRandomNumber = () => {
  return Math.floor(Math.random()*10) + 1;
};

class ContentCreate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {
        urls: [],
        thumbnail_url: null,
        title: null,
        summary: null,
        source: null,
        language_code: null,
        content_type: null,
        category_id: null,
        publish_date: '',
        article_date: '',
        featured_start_date: '',
        featured_end_date: '',
        pinned_start_date: '',
        pinned_end_date: '',
        video_id: null,
        show_ads: false,
        is_pinned: false,
        is_featured: false,
        is_hidden: false,
        audiences: [{"value":"all","label":"ALL"}],
        featured_category: {
          "value": FEATURED_TYPE.DEFAULT,
          "label": "DEFAULT"
        },
        ad_units: [],
        hash_tags: [],
        countries: [],
        external_url: '',
      },
      submitting: false,
      adUnitTypes: null,
      prefillingStatue: null,
      categories: [],
      selectedCategories: "default",
      showVideoIdField: false,
      hash_tags_suggestions: [],
      uploadedFile: null,
      countrySelectPolicy: "select"
    };
  }

  getAllHashTags (data) {
    suggestV2HashTags(data)
      .then(res => {
        const hashTags = res.data.hash_tags;
        console.log(hashTags);
        this.setState({
          hash_tags_suggestions: getHashTags(Array.from(hashTags))
        })
    }).catch(err => {
      console.log('Something went wrong while fetching the hashtag suggestions. Error : ', err);
    });
  }

  getAllAdUnits() {
    getAdUnits({ environment: 'Production'})
        .then(adUnits => {
          const adUnitTypes = Object.keys(adUnits).map((adUnitId) => {
            const adUnit = adUnits[adUnitId];
            return {
              value: adUnit.id,
              label: `${adUnit.name} - ${adUnit.id}`,
              id: adUnit.id,
            }
          });

          this.setState({
            adUnitTypes,
          })
        }).catch(err => {
      console.log('Something went wrong while fetching the ad units. Error : ', err);
    });
  }

  componentWillMount() {
    this.getAllHashTags({ language_code: 'en' });
    this.getAllAdUnits();
  }

  renderSelect(arr, selectKey, defaultVal = "default") {
    const { selectedCategories } = this.state;
    const options = arr.map((item, i) => {
      return (
        <option value={item.key} key={i}>{item.value}</option>
      );
    });
    if(selectKey === 'category_id') {
      defaultVal = selectedCategories;
    }

    return (
        <select value={defaultVal}
                defaultValue="default"
                onChange={this.onSelectChange.bind(this, selectKey)}
                className="form-control"
        >
          <option disabled value="default">Please select one</option>
          {options}
        </select>
    )
  }

  getValueByKey(key, arr) {
    let res;
    for(let i=0; i<arr.length; i++) {
      if(arr[i].key === key) {
        res = arr[i].value;
        break;
      }
    }
    return res;
  }

  onSelectChange(selectKey, e) {
    let { content, categories, selectedCategories } = this.state;
    content[selectKey] = e.target.value;
    if(selectKey === 'category_id') {
      content['category'] = this.getValueByKey(e.target.value, categories);
      selectedCategories = e.target.value;
    }

    if(selectKey === 'language_code') {
      // this.fetchAndSetCategories(e.target.value);
      this.getAllHashTags({ language_code : e.target.value});
    }

    if(selectKey === 'content_type') {
      if(e.target.value === 'video') {
        this.setState({ showVideoIdField: true });
      } else {
        this.setState({ showVideoIdField: false });
      }
    }

    if(selectKey === 'content_type') {
      content.thumbnail_url = content.urls[0];
    }

    this.setState({ content, selectedCategories });
  }

  // fetchAndSetCategories(language_code) {
  //   let locale = require(`../../locales/${curateLanguageCode(language_code)}`);
  //   const cats = locale.content.categories;
  //   const catKeys = Object.keys(cats);
  //   let categories = [];
  //   catKeys.forEach((key) => {
  //     categories.push({
  //       key,
  //       value: cats[key]
  //     });
  //   });
  //   this.setState({
  //     categories,
  //     selectedCategories: "default"
  //   });
  // }

  getLocaleDate(date) {
    return date ? new Date(date) : null;
  }

  getContentTobeCreated(content) {
    const updatedContent = {
      ...content
    };

    delete updatedContent['countrySelectPolicy'];

    updatedContent['random_number'] = getRandomNumber();
    updatedContent['article_date'] = this.getLocaleDate(content.article_date);
    updatedContent['publish_date'] = this.getLocaleDate(content.publish_date);
    updatedContent['pinned_start_date'] = this.getLocaleDate(content.pinned_start_date);
    updatedContent['pinned_end_date'] = this.getLocaleDate(content.pinned_end_date);
    updatedContent['featured_start_date'] = this.getLocaleDate(content.featured_start_date);
    updatedContent['featured_end_date'] = this.getLocaleDate(content.featured_end_date);
    updatedContent['created_date'] = new Date();
    updatedContent['created_by'] = localStorage.getItem('uid');
    updatedContent['countries'] = _.map(content.countries, 'value');
    updatedContent['hash_tags'] = _.map(content.hash_tags, 'name');
    updatedContent['audiences'] = _.map(content.audiences, 'value');

    // Ad units
    if(!_.isEmpty(content.ad_units)) {
      updatedContent['ad_unit_id'] = content.ad_units[0].id;
    }
    delete updatedContent['ad_units'];

    // Featured Type
    if(content.is_featured) {
      if(!content.featured_category) {
        updatedContent['featured_category'] = FEATURED_TYPE.DEFAULT;
      } else {
        updatedContent['featured_category'] = content['featured_category']['value'];
      }
    } else {
      delete updatedContent['featured_category'];
    }

    if(updatedContent['content_type'] === 'quote') {
      const { yearCode, dayCode } = getYearAndDayCodes(updatedContent['publish_date']);

      updatedContent['year_code'] = yearCode;
      updatedContent['day_code'] = dayCode;
    }

    return _.pickBy(updatedContent, (v) => !(_.isNil(v) || v === ''));
  }

  updateImageDetails(content, imageUrl) {
    content['urls'] = [imageUrl];
    content['thumbnail_url'] = imageUrl;
  }

  async onAddFormSubmit(e) {
    e.preventDefault();
    this.setState({ submitting: true });
    let { content, uploadedFile, countrySelectPolicy } = _.cloneDeep( this.state )

    try {
      if( countrySelectPolicy == "exclude" ) {
        let newlist = _.differenceWith(countryOptions, [{ value: 'ALL' }, ...content.countries], (c1, c2) => {
          return c1.value == c2.value;
        }).map( ( item ) => ({ label: item.value , value: item.value }))
        content.countries = newlist;
      }

      console.log("list countries", content.countries )
      let newImagelUrl = null;
      if(uploadedFile && content.content_type === 'image') {
        const imageUploadResp = await uploadNewImage(uploadedFile, {
          optimizeImages: true,
          fileUploadPath: `/images/contentv2/image/${content.language_code}/${uploadedFile.name}`,
        });
        const imageUploadRespData = imageUploadResp['data'];
        if(imageUploadRespData && imageUploadRespData.success) {
          const urlList = imageUploadRespData['data'];
          const imagePublicUrl = _.map(urlList, 'public_url')[0];
          newImagelUrl = getCdnPublicFileUrl(imagePublicUrl);
          if(newImagelUrl) {
            this.updateImageDetails(content, newImagelUrl);
          }
        } else {
          console.log(`Image upload failed. ${JSON.stringify(imageUploadResp)}`);
          toast.error('Something went wrong while uploading the image.', {
            position: toast.POSITION.BOTTOM_LEFT
          });
          return;
        }
      }

      const updatedContent = this.getContentTobeCreated(content);
      console.log(`updatedContent : ${JSON.stringify(updatedContent)}`);

      if(this.validate(content)) {

        const createContentResp = await createV2Content({ content: updatedContent });
        console.log('Content saved successfully! Content Id : ', JSON.stringify(createContentResp));
        const createdContent = {
          id: createContentResp.data.id,
          hash_tags: updatedContent.hash_tags,
          publish_date: updatedContent.publish_date,
          language_code: updatedContent.language_code,
        };
        console.log('Content createdContent ', JSON.stringify(createdContent));
        if (!_.isEmpty(createdContent.hash_tags) && !content.is_hidden) {
          await updateV2HashTags({ contents: [createdContent], operation: 'update' });
          console.log(`Hashtags updated successfully.`);
        }

        if (updatedContent['ad_unit_id']) {
          let currentAdUnit = await fetchAdUnit({
            environment:'Production', id: updatedContent['ad_unit_id']
          });
          console.log('currentAdUnit', currentAdUnit);
          if(_.isEmpty(currentAdUnit['content_ids'])) {
            currentAdUnit['content_ids'] = [createdContent['id']];
          } else {
            currentAdUnit['content_ids'].push(createdContent['id']);
          }
          const adUnitUpdateResp = await updateAdUnit({
            environment:'Production',
            ...currentAdUnit,
          });

          console.log(`Ad unit updated successfully. Res ${JSON.stringify(adUnitUpdateResp)}`);
        }

        this.setState({ submitting: false });
        this.clearForm();
        toast.success('New content published successfully!', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      } else {
        this.setState({ submitting: false });
        console.log("Has error");
      }
    } catch (err) {
      console.log('Error occured while creating the content. Error : ', err);
      this.setState({ submitting: false });
      toast.error('Something went wrong while publishing content', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  }

  clearForm() {
    const content = {
      series_id: '',
      urls: [],
      thumbnail_url: "",
      title: "",
      summary: "",
      source: "",
      video_id: "",
      language_code: "default",
      content_type: "default",
      category_id: "default",
      is_pinned: false,
      is_featured: false,
      is_hidden: false,
      publish_date: '',
      article_date: '',
      featured_start_date: '',
      featured_end_date: '',
      pinned_start_date: '',
      pinned_end_date: '',
      hash_tags: [],
      countries: [],
      audiences: [{"value":"all","label":"ALL"}],
      ad_units: [],
      featured_category: null,
      external_url: '',
      uploadedFile: null,
    };
    this.setState({
      content,
      selectedCategories: "default",
      prefillingStatue: null,
      showVideoIdField: false,
      countrySelectPolicy: "select",
    });
  }

  validate(content) {
    let errFound = false;
    Object.keys(content).forEach((key) => {
      const item = content[key];
      if(key === 'publish_date' && !item) {
        alert("Invalid publish date");
        errFound = true;
      }
      else if(key === 'is_pinned' && item && !content['pinned_end_date']) {
        alert("You must set pinned end date since the content is going to be pinned!");
        errFound = true;
      }
      // This condition is mainkly for skipping the empty params
      else if(key === 'show_ads' || key === 'article_date' || key === 'video_id' || key === 'summary') {
        // Skipping and removing from the content
        if(!item || item === "") {
          delete content[key];
          this.setState({content});
        }
      } else {
        if(!(key === "external_url" || key === "is_featured" || key === "is_hidden" || key === "is_pinned"
            || key === "series_id" || key === "featured_end_date" || key === "featured_start_date"
            || key === "pinned_end_date" || key === "pinned_start_date" || key === "category_id")) {
          if(!item || (typeof(item) === 'string' && item.trim() === "")) {
            alert(`Invalid ${key}`);
            errFound = true;
          }
        }
      }
    });
    return !errFound;
  }

  onFieldChange(fieldKey, e) {
    let { content } = this.state;
    content[fieldKey] = e.target.value;
    if(fieldKey === 'publish_date'
        || fieldKey === 'article_date'
        || fieldKey === 'featured_start_date'
        || fieldKey === 'featured_end_date'
        || fieldKey === 'pinned_start_date'
        || fieldKey === 'pinned_end_date') {
      content[fieldKey] = e.target.value;
    }

    if(fieldKey === 'series_id') {
      content[fieldKey] = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');
    }

    this.setState({content});
    if(fieldKey === 'urls') {
      this.prefillContentMeta(e.target.value);
      let contentInner = this.state.content;
      contentInner['show_ads'] = showAds(e.target.value);
      this.setState({ content: contentInner });
    }
  }

  onCountrySelectPolicyChange(event){
    this.setState((prev) => {
      return {
        ...prev,
        countrySelectPolicy: event.target.value
      }
    })
  }

  onCountriesChange(countries) {
    let { content } = this.state;
    if(countries.length === 0) {
      delete content["countries"];
    } else {
      let countryCodes = [];

      countries.forEach((countryCode) => {
        countryCodes.push({ value: countryCode.value, label: countryCode.value});
      });
      content["countries"] = countryCodes;
    }
    this.setState({
      content
    });
  }

  onAudiencesChange(selectedAudiences) {
    let { content } = this.state;

    if(selectedAudiences.length === 0) {
      content["audiences"] = [{"value":"all","label":"ALL"}]
    } else {
      let newAudiences = [];

      selectedAudiences.forEach((selectedAudience) => {
        newAudiences.push({ value: selectedAudience.value, label: selectedAudience.label});
      });

      content["audiences"] = newAudiences;
    }

    this.setState({
      content
    });
  }

  onUploadFile(event) {
    const file = event.target.files[0];
    this.setState({ uploadedFile: file });
  }
  onRemoveFile() {
    this.setState({ uploadedFile: null });
  }

  async onAdUnitsChange(selectedAdUnits) {
    let { content } = this.state;

    if(!selectedAdUnits || selectedAdUnits.length === 0) {
      content["ad_units"] = []
    } else {
      // Enable this if in future decide to have multiple units + Select isMulti should be true.
      // let newAdUnits = [];
      //
      // selectedAdUnits.forEach((selectedAdUnit) => {
      //   newAdUnits.push({ value: selectedAdUnit.value, label: selectedAdUnit.label});
      // });
      // content["ad_units"] = newAdUnits;
      const currentAdUnit = await fetchAdUnit({ environment:'Production', id: selectedAdUnits.id  });
      console.log('currentAdUnit', currentAdUnit);

      content["ad_units"] = [selectedAdUnits];
    }

    this.setState({
      content
    });
  }

  onFeaturedTypeChange(selectedFeaturedType) {
    let { content } = this.state;

    content['featured_category'] = selectedFeaturedType;

    this.setState({
      content
    });
  }

  getLangFromHtml(htmlString) {
    // fetching lang from html tag
    const htmlRegex = /(<html.*?>)/;
    let htmlStartFound = htmlString.match(htmlRegex);
    let crawledHtmlTag = (htmlStartFound && htmlStartFound[0]) || "";
    // fetched <html all attrs >
    const langRegex = /(lang=".*?")/;
    let langFound = crawledHtmlTag.match(langRegex) ? crawledHtmlTag.match(langRegex)[0] : "";
    const codeRegex = /(".*?")/;
    let codeFound = langFound.match(codeRegex);
    let code = (codeFound && codeFound[0]) || "";
    code = code ? code.split('"').join('') : code;
    code = code.slice(0, 2);
    return code;
  }

  prefillContentMeta(url) {
    let { content } = this.state;
    delete content['video_id'];
    this.setState({content, prefillingStatue: 0, showVideoIdField: false});
    if(content['content_type'] === 'default' ) {
      content['content_type'] = "article";
    }
    // analyszing the url for youtube
    let url_temp = url;
    let domain;
    url_temp = url_temp.split('//');
    if (url_temp.length === 1) {
      //no protocol - no http
      url = 'http://' + url;
      domain = (url_temp[0].split('/'))[0];
    } else {
      domain = (url_temp[1].split('/'))[0];
    }
    if(domain.endsWith('youtube.com') || domain.endsWith('youtu.be')) {
      if(content['content_type'] === 'default' ) {
        content['content_type'] = "video";
      }
      this.setState({ content });
      let video_id = this.fetchVideoId(url);
      if (video_id != null) {
        url = 'https://youtube.com/watch?v=' + video_id;
      }
    }
    // fetching meta data from url
    getContentMetaData({url}).then((res) => {
      const htmlString = res.data;
      const fetchedLC = this.getLangFromHtml(htmlString);
      if(fetchedLC !== "") {
        content['language_code'] = curateLanguageCode(fetchedLC);
        // this.fetchAndSetCategories(fetchedLC);
      }
      // fetching meta
      const jQ = window.jQuery;
      const crawledHTML = jQ.parseHTML(htmlString);
      let contentThumbnail, contentTitle, contentDescription, contentSource;
      crawledHTML.forEach((token) => {
        if(jQ(token).is('meta')) {
          if(!contentThumbnail){ contentThumbnail = getDataFromMeta('image', jQ(token)); }
          if(!contentTitle){ contentTitle = getDataFromMeta('title', jQ(token)); }
          if(!contentDescription){ contentDescription = getDataFromMeta('description', jQ(token)); }
          if(!contentSource){ contentSource = getDataFromMeta('site_name', jQ(token)); }
        }
      });
      let prefillingStatue = 1;
      if(!contentThumbnail && !contentTitle && !contentDescription && !contentSource) {
        prefillingStatue = -1;
      }
      content.urls = [url];
      content.thumbnail_url = contentThumbnail;
      content.title = contentTitle;
      content.summary = contentDescription;
      content.source = contentSource;
      this.setState({content, prefillingStatue});
    });
  }

  // Will be fired only if the url is youtubes
  fetchVideoId(url) {
    const re=/[/=]([a-zA-Z0-9\-_]{11})[&/]?/;
    const found = url.match(re);
    let { content } = this.state;
    if(found != null && found.length === 2) {
      content.video_id = found[1];
      this.setState({content, showVideoIdField: true});
      return found[1];
    } else {
      delete content['video_id'];
      this.setState({content, showVideoIdField: false});
      return null;
    }
  }

  blockSubmission(language) {
    if(!language || SupportedLanguages.indexOf(language) === -1) {
      return true;
    } else {
      return false;
    }
  }

  renderSubmitButton(blockSubmission) {
    const { submitting } = this.state;
    if(submitting) {
      return (
        <Button disabled={true} className="btn-primary content_editor_submit_btn">Submitting...</Button>
      );
    } else {
      return (
        <Button disabled={blockSubmission} type="submit" className="btn-primary content_editor_submit_btn">Submit</Button>
      );
    }
  }

  renderPrefillStatus(prefillingStatue) {
    if(prefillingStatue === null) {
      return null;
    } else if(prefillingStatue === 0) {
      return <p className="small text-muted"><Icon spin name="spinner" /> Trying to prefill</p>;
    } else if(prefillingStatue === 1) {
      return <p className="small text-success"><Icon name="check-circle" /> Prefilling successful</p>;
    } else if(prefillingStatue === -1) {
      return <p className="small text-danger"><Icon name="exclamation-circle" /> Prefilling failed</p>;
    }
  }

  renderVideoIdField(showVideoIdField, video_id) {
    if(showVideoIdField) {
      return <div className="form-group">
        <label>Video Id</label>
        <input value={video_id}
               onChange={this.onFieldChange.bind(this, 'video_id')}
               type="text"
               className="form-control"
               placeholder="Video Id"/>
      </div>
    } else {
      return null;
    }
  }

  onShowAdsToggle() {
    let { content } = this.state;
    content.show_ads = !content.show_ads;
    this.setState({
      content,
    });
  }

  onIsPinnedToggle(isPinned) {
    const confirm = isPinned || window.confirm("Do you really want to pin this post?");
    let { content } = this.state;
    if(confirm) {
      content.is_pinned = !content.is_pinned;
      if(isPinned) {
        delete content['pinned_start_date'];
        delete content['pinned_end_date'];
      }

      this.setState({
        content,
      });
    }
  }

  onIsFeaturedToggle(isFeatured) {
    let { content } = this.state;
    content.is_featured = !content.is_featured;
    if(isFeatured) {
      delete content['featured_start_date'];
      delete content['featured_end_date'];
      content['featured_category'] = {
        "value": FEATURED_TYPE.DEFAULT,
        "label": "DEFAULT"
      };
    }

    this.setState({
      content,
    });
  }

  onIsHiddenToggle() {
    let { content } = this.state;
    content.is_hidden = !content.is_hidden;
    this.setState({
      content,
    });
  }

  renderPinnedDates(pinned_start_date, pinned_end_date) {
    return (
        <Fragment>
          <Col md={12}>
            <div className="form-group">
              <label className="show__ads_label">Pinned start at</label>
              <input required
                     value={pinned_start_date}
                     onChange={this.onFieldChange.bind(this, 'pinned_start_date')}
                     type="datetime-local"
                     className="form-control"
                     placeholder="Pinned start date"
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="form-group">
              <label className="show__ads_label">Pinned end at</label>
              <input required
                     value={pinned_end_date}
                     onChange={this.onFieldChange.bind(this, 'pinned_end_date')}
                     type="datetime-local"
                     className="form-control"
                     placeholder="Pinned end date"
              />
            </div>
          </Col>
        </Fragment>
    );
  }

  renderFeatureDates(featured_start_date, featured_end_date, featured_category) {
    return (
        <Fragment>
          <Col md={12}>
            <div className="form-group">
              <label>Featured Type</label>
              <Select onChange={this.onFeaturedTypeChange.bind(this)}
                      value={featured_category}
                      isSearchable={true}
                      options={featuredTypes}
                      isMulti={false}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="form-group">
              <label>Featured start at</label>
              <input required
                     value={featured_start_date}
                     onChange={this.onFieldChange.bind(this, 'featured_start_date')}
                     type="datetime-local"
                     className="form-control"
                     placeholder="Feature start date"
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="form-group">
              <label>Featured end at</label>
              <input required
                     value={featured_end_date}
                     onChange={this.onFieldChange.bind(this, 'featured_end_date')}
                     type="datetime-local"
                     className="form-control"
                     placeholder="Feature end date"
              />
            </div>
          </Col>
        </Fragment>
    );
  }

  handleHashTagDelete (i) {
    const tags = this.state.content.hash_tags.slice(0);
    tags.splice(i, 1);
    let { content } = this.state;
    content.hash_tags = tags;
    this.setState({ content });
  }

  handleHashTagAddition (newTag, language_code) {
    let updatedTag;
    if(ENGLISH_CHARACTERS_SUPPORTED_LANGUAGES.includes(language_code)) {
      updatedTag = { name: newTag.name.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase()};
    } else {
      updatedTag = { name: newTag.name };
    }
    const tags = [].concat(this.state.content.hash_tags, updatedTag);
    let { content } = this.state;
    content.hash_tags = _.uniqBy(tags, 'name');
    this.setState({ content });
  }

  handleHashTagValidation (tag, language_code) {
    if(ENGLISH_CHARACTERS_SUPPORTED_LANGUAGES.includes(language_code)) {
      return tag.name.length > 2 && isValidHashTag(tag.name);
    }

    return isValidHashTag(tag.name);
  }

  render() {
    const {
      content, prefillingStatue, showVideoIdField, hash_tags_suggestions, adUnitTypes,
      uploadedFile,
    } = this.state;

    const {
      urls, thumbnail_url, title, summary, source, content_type, language_code,
      video_id, series_id, hash_tags, show_ads, is_pinned, is_featured, is_hidden, publish_date,
      article_date, featured_start_date, featured_end_date,pinned_start_date, pinned_end_date,
      countries, audiences, ad_units, featured_category, external_url,
    } = content;

    const uploadedImageUrl = uploadedFile ? URL.createObjectURL(uploadedFile) : null;
    const isValidLanguage = language_code && language_code !== 'default';
    const showImageUploader = isValidLanguage && content_type === 'image';

    return (
      <BasicLayout pagePermission="content_editor">
        <ToastContainer autoClose={2000} />
        <h3 className="content_editor_title">Add Content</h3>
        <Row>
          <Col md={6}>
            <form onSubmit={this.onAddFormSubmit.bind(this)}>
              <div className="form-group">
                <label>Content Type</label>
                {this.renderSelect(contentTypes, 'content_type', content_type)}
              </div>
              <div className="form-group">
                <label>Audiences</label>
                <Select onChange={this.onAudiencesChange.bind(this)}
                        value={audiences}
                        isSearchable={true}
                        options={audienceTypes}
                        isMulti
                />
              </div>
              {
                showImageUploader ?
                    <FileUploader
                        onUploadFile={this.onUploadFile.bind(this)}
                        fileDeleted={!uploadedImageUrl}
                    /> : (content_type === 'image' ?
                    <h6 style={{'color': 'red'}}>{'Select Language to Show the Image Uploader.'}</h6>
                    :
                    null)
              }
              <div className="form-group">
                <label>Series Id (Optional)</label>
                <input value={series_id}
                       onChange={this.onFieldChange.bind(this, 'series_id')}
                       type="text"
                       className="form-control"
                       placeholder="Series Id"
                />
              </div>
              {
                adUnitTypes ?
                    <div className="form-group">
                      <label>Ad Unit (Optional)</label>
                      <Select onChange={this.onAdUnitsChange.bind(this)}
                              value={ad_units}
                              isSearchable={true}
                              options={adUnitTypes}
                              isMulti={false}
                              isClearable
                      />
                    </div> : null
              }
              {
                content_type !== 'image' ?
                    <Fragment>
                      <div className="form-group">
                        <label>URL</label>
                        <input required
                               value={urls[0] || ''}
                               onChange={this.onFieldChange.bind(this, 'urls')}
                               type="text"
                               className="form-control"
                               placeholder="URL"
                        />
                        {this.renderPrefillStatus(prefillingStatue)}
                      </div>
                      {this.renderVideoIdField(showVideoIdField, video_id)}
                      <div className="form-group">
                        <label>Thumbnail URL</label>
                        <input value={thumbnail_url}
                               required
                               onChange={this.onFieldChange.bind(this, 'thumbnail_url')}
                               type="text"
                               className="form-control"
                               placeholder="Thumbnail URL"
                        />
                      </div>
                    </Fragment> : null
              }
              {
                content_type === 'image' ?
                    <div className="form-group">
                      <label>External URL (Optional)</label>
                      <input value={external_url}
                             onChange={this.onFieldChange.bind(this, 'external_url')}
                             type="text"
                             className="form-control"
                             placeholder="External URL"
                      />
                    </div> : null
              }
              <div className="form-group">
                <label>Title</label>
                <input value={title}
                       required
                       onChange={this.onFieldChange.bind(this, 'title')}
                       type="text"
                       className="form-control"
                       placeholder="Title"
                />
              </div>
              <div className="form-group">
                <label>Summary</label>
                <textarea value={summary}
                          onChange={this.onFieldChange.bind(this, 'summary')}
                          type="text"
                          className="form-control summary__textarea"
                          placeholder="Summary"
                />
              </div>
              <div className="form-group">
                <label>Source</label>
                <input value={source}
                       required
                       onChange={this.onFieldChange.bind(this, 'source')}
                       type="text"
                       className="form-control"
                       placeholder="Source"
                />
              </div>
              <Row>
                <Col md={6}>
                  <div className="form-group">
                    <label>Language</label>
                    {this.renderSelect(languages, 'language_code', language_code)}
                  </div>
                </Col>
                {/*<Col md={6}>*/}
                {/*  <div className="form-group">*/}
                {/*    <label>Category</label>*/}
                {/*    {this.renderSelect(categories, 'category_id', category_id)}*/}
                {/*  </div>*/}
                {/*</Col>*/}
                <Col md={12} className={'countries-dropdown'}>
                  <div className="form-group">
                    <label>Countries (Optional)</label>
                    <div onChange={this.onCountrySelectPolicyChange.bind(this)}>
                      <input
                        type="radio"
                        value="select"
                        name="countries-select-policy"
                        checked={this.state.countrySelectPolicy == 'select'}
                        defaultChecked
                      /><label style={{ paddingRight: 10}}>Select</label>
                      <input
                        type="radio"
                        value="exclude"
                        name="countries-select-policy"
                        checked={this.state.countrySelectPolicy == 'exclude'}
                      /><label>Exclude</label>
                    </div>
                    <Select onChange={this.onCountriesChange.bind(this)}
                            value={countries}
                            isSearchable={true}
                            options={countryOptions}
                            isMulti
                    />
                  </div>
                </Col>
                <Col md={12} className={'newHashTag'}>
                  <div className="form-group">
                    <label>Hash Tags</label>
                    <ReactTags
                        allowNew
                        delimiterChars={[',', ' ']}
                        placeholder={'Add new Hashtag'}
                        tags={hash_tags}
                        suggestions={hash_tags_suggestions}
                        handleDelete={this.handleHashTagDelete.bind(this)}
                        handleAddition={(tag) => this.handleHashTagAddition(tag, language_code)}
                        handleValidate={(tag) => this.handleHashTagValidation(tag, language_code)}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label>Article date (Optional)</label>
                    <input onChange={this.onFieldChange.bind(this, 'article_date')}
                           value={article_date}
                           type="datetime-local"
                           className="form-control"
                           placeholder="Article date"/>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label>Publish date</label>
                    <input required
                           value={publish_date}
                           onChange={this.onFieldChange.bind(this, 'publish_date')}
                           type="datetime-local"
                           className="form-control"
                           placeholder="Publish date"/>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="form-group">
                    <label className="show__ads_label">Show Ads</label>
                    <div className="show__ads_btn_wrapper">
                      <ToggleButton
                          value={ show_ads }
                          onToggle={this.onShowAdsToggle.bind(this)}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="form-group">
                    <label className="show__ads_label">Pin content</label>
                    <div>
                      <ToggleButton
                          value={is_pinned}
                          onToggle={this.onIsPinnedToggle.bind(this)}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="form-group">
                    <label className="show__ads_label">Feature content</label>
                    <div>
                      <ToggleButton
                          value={is_featured}
                          onToggle={this.onIsFeaturedToggle.bind(this)}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="form-group">
                    <label className="show__ads_label">Hide content</label>
                    <div>
                      <ToggleButton
                          value={is_hidden}
                          onToggle={this.onIsHiddenToggle.bind(this)}
                      />
                    </div>
                  </div>
                </Col>
                {
                  is_pinned ?
                    this.renderPinnedDates(
                        pinned_start_date,
                        pinned_end_date)
                      : null
                }
                {
                  is_featured ?
                    this.renderFeatureDates(
                        featured_start_date,
                        featured_end_date,
                        featured_category)
                      : null
                }
              </Row>
              <div className="form-group">
                {this.renderSubmitButton(this.blockSubmission(language_code))}
              </div>
            </form>
          </Col>
          <Col md={6}>
            <strong>App Preview</strong>
            <div className="content__preview_div" md={6}>
              {
                uploadedImageUrl ?
                    <div>
                      <p>
                        <img src={uploadedImageUrl} alt="" width="100%" />
                      </p>
                      <button onClick={this.onRemoveFile.bind(this)}
                              type={'button'}
                              className="btn-danger btn-sm">
                                <span
                                    className="glyphicon glyphicon-trash"
                                    aria-hidden="true"
                                />
                        &nbsp;{'Remove Image'}
                      </button>
                    </div>: null
              }
              <h4>{title}</h4>
              <p className="content__preview_div_source">{source}</p>
              <p>{summary}</p>
            </div>
          </Col>
          <Col md={6}>
            <br />
            <strong>On Browser</strong>
            <div className="content__preview_div" md={6}>
              <iframe title="preview"
                      className="on__browser__iframe"
                      src={uploadedImageUrl}
                      width="100%"/>
            </div>
          </Col>
        </Row>
      </BasicLayout>
    );
  }
}

export default ContentCreate;
