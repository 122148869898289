import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';

class WIP extends Component {
  render() {
    return (
      <BasicLayout pagePermission="read_codes">
        <Row>
            <Col md={12}>
                <p className="text-danger">The data studio report has been discontinued because of incomplete data.</p>
                {/* <iframe width="100%"
                        height="1400"
                        src='https://datastudio.google.com/embed/reporting/d6070c6d-122a-4bd3-b66a-9e797ea1db0e/page/J5M2B'
                        frameBorder="0"
                        style={{ border: '0' }}
                        allowFullScreen
                        title='Premium-Subscription Stats'
                >
                </iframe> */}
            </Col>
        </Row>
      </BasicLayout>
    );
  }
}

export default WIP;
