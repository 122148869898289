import React from 'react';
import {
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  MPUploadExcel,
} from '../../widgets/upload-excel';
import {
  ExcelUploadInfoPanel,
} from './common';
import {
  hajjAndUmrahCreateOrUpdateHook,
} from '../../hooks';
import {
  getItems,
} from './helper';

const UploadExcel = () => {

  return (
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={2000}/>
        <Row>
          <Col md={12}>
            <h3 className='content_editor_title'>{'Upload Excel'}</h3>
          </Col>
        </Row>
        <MPUploadExcel
            InfoPanel={ExcelUploadInfoPanel}
            fileUpdateHook={hajjAndUmrahCreateOrUpdateHook}
            fileUpdateSuccessMessage={'Items Updated Successfully!!'}
            getItems={getItems}
        />
      </BasicLayout>
  );
};

export default UploadExcel;
