import {
  SPECIAL_HEADERS_COUNT, THEMES_LIST_SPECIAL_HEADERS_COUNT,
} from "./Constants";
import _ from "lodash";
import {getDateFromExcelValue} from "../../utils";

const isValidDate = (d) => {
  if( /\d{4}-\d{2}-\d{2}/.test(d) ) {
    return true;
  }else{
    return false;
  }
}

const isValidSuraId = (id) => {
  if( /\d{1,3}/.test(id) ) {
    return true;
  }else{
    return false;
  }
}

const isValidVerseId = (id) => {
  if( /\d{1,3}/.test(id) ) {
    return true;
  }else{
    return false;
  }
}

const isValidDuration = (dur) => {
  if( _.isNumber(dur) ) { // check with 60 - 90
    return true;
  }else{
    return false;
  }
}

const isValidFlag = (f) => {
  return ['regular', 'default', 'preview'].includes( f )
}

const getMediaCopyItems = (displayItemsData) => {
  console.log('getMediaCopyItems : ', displayItemsData);
  return JSON.stringify(displayItemsData);
};

const getMediaItems = async (rows) => {
  const items = [];
  const allErrors = [];
  for(let i = SPECIAL_HEADERS_COUNT; i < rows.length; i++) {
    let errorsCount = 0;
    const data = rows[i];

    const item = {};

    const errors = {
      'row': i + 1,
      'errorsCount': 0,
      'errorList': [],
      'excelSheetRowData': data,
    };

    // Flag -
    const flag = _.get(data, '[0]', null)
    if(flag) {
      if(isValidFlag(flag) ) {
        item['flag'] = flag.toLowerCase();
      } else {
        errors['errorList'].push(`${++errorsCount}. Flag is invalid.`);
      }
    } else {
      errors['errorList'].push(`${++errorsCount}.  Flag  not found.`);
    }

    // Date - Schedule At
    const date = _.get(data, "[1]", null)
    if(date) {
      if(isValidDate(date) ) {
        item['date'] = date;
      } else {
        errors['errorList'].push(`${++errorsCount}. Date is invalid.`);
      }
    } else {
      errors['errorList'].push(`${++errorsCount}.  Date  not found.`);
    }

    // Group

    // ContentType
    const contenttype = _.get(data, '[3]', null)
    if(contenttype) {
      item['content_type'] = contenttype.toLowerCase();
    } else {
      errors['errorList'].push(`${++errorsCount}.  Content Type not found.`);
    }

    // SuraName
    // Verse Range

    // Sura ID,
    const suraid = _.get(data, '[4]', null)
    if( suraid ){
      if( isValidSuraId(suraid) ) {
        item['start_sura'] = suraid
        item['end_sura'] = suraid
      }else {
        errors['errorList'].push(`${++errorsCount}.  SuraID invalid.`);
      }
    }else{
      errors['errorList'].push(`${++errorsCount}.  SuraID not found.`);
    }

    // Verse Start
    const versestart = _.get(data, '[7]', null)
    if( versestart ){
      if( isValidVerseId(versestart) ) {
        item['start_aya'] = versestart
      }else {
        errors['errorList'].push(`${++errorsCount}.  AyaID-start invalid.`);
      }
    }else{
      errors['errorList'].push(`${++errorsCount}.  AyaID-start not found.`);
    }

    // Verse End
    const verseend = data[8] || versestart
    if( verseend ){
      if( isValidVerseId(verseend) ) {
        item['end_aya'] = verseend
      }else {
        errors['errorList'].push(`${++errorsCount}.  AyaID-end not invalid.`);
      }
    }else{
      errors['errorList'].push(`${++errorsCount}.  AyaID-end not found.`);
    }



    // Duration
    const duration = _.get(data, '[9]', null)
    if(duration) {
      if( isValidDuration(duration) ) {
        item['duration'] = duration;
      }else{
        errors['errorList'].push(`${++errorsCount}.  Duration not found.`);
      }
    } else {
      errors['errorList'].push(`${++errorsCount}.  Duration not found.`);
    }

    // Arabic Text
    // Translation
    // Cell.Dur
    // Theme

    // ThemeKey
    const theme = _.get(data, '[12]', null);
    if(theme) {
      item['theme'] = theme;
    } else {
      errors['errorList'].push(`${++errorsCount}.  Theme not found.`);
    }


    // ContentBody
    const contentbody = _.get(data, '[13]', "");
    item['content_body'] = contentbody || "";

    // Image Label

    // BGImgUrl
    const bgimg = _.get(data, '[15]', null)
    if(bgimg) {
      item['full_img'] = bgimg
    } else {
      errors['errorList'].push(`${++errorsCount}.  BG ImgUrl not found.`);
    }


    // HeaderImgUrl
    const himg = _.get(data, '[16]', null)
    if(himg) {
      item['header_img'] = himg
    } else {
      errors['errorList'].push(`${++errorsCount}.  Header ImgUrl not found.`);
    }




    errors['errorsCount'] = errorsCount;
    if(errors['errorsCount'] > 0) {
      allErrors.push(errors);
    }
    items.push(item);
  }

  console.log('All Items : ', items);
  console.log('All Errors : ', allErrors);

  return {
    items,
    allErrors,
  };
};

const getThemesListItems = async (rows) => {
  const items = [];
  const allErrors = [];
  for(let i = THEMES_LIST_SPECIAL_HEADERS_COUNT; i < rows.length; i++) {
    let errorsCount = 0;
    const data = rows[i];

    const item = {};

    const errors = {
      'row': i + 1,
      'errorsCount': 0,
      'errorList': [],
      'excelSheetRowData': data,
    };
    console.log('Validation row : ', i + 1);

    // key
    const theme_key = _.get(data, '[1]', null)
    if( theme_key == "##END##" ) {
      // break
      break;
    }
    if( theme_key ) {
      item['theme_key'] = theme_key;
    }else{
      continue;
    }

    const theme_label_en = _.get(data, '[0]', null)
    if( theme_label_en ) {
      item['theme_label_en'] = theme_label_en;
    }else{
      errors['errorList'].push(`${++errorsCount}.  theme_label  not found.`);
    }

    errors['errorsCount'] = errorsCount;
    if(errors['errorsCount'] > 0) {
      allErrors.push(errors);
    }
    items.push(item);
  }

  console.log('All Items : ', items);
  console.log('All Errors : ', allErrors);

  return {
    items,
    allErrors,
  };
};


export {
  getMediaCopyItems,
  getMediaItems,
  getThemesListItems,
}