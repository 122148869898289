import _ from "lodash";
import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { Col, Row } from "react-bootstrap";
import BasicLayout from "../../../layouts/basiclayout";
import {
  qalBoxSearchDisplayCategoriesHook,
  qalBoxDeleteMediaHook,
} from "../../../hooks";
import {
  getDeletableDisplayCategories,
  getMediaIdsToDelete,
  getDisplayCategoryColumns,
  getSupportedLanguages,
  getDisplayCategoriesDisplayData,
  convertArrayToObjMap,
  getValuesFromRow,
  getArrayFromString,
} from "../helper";
import { MPSelect } from "../../../widgets/common";
import { DEFAULT_SUPPORTED_LANGUAGE, DEFAULT_PREMIUM_SEARCH_FILTER } from "../Constants";
import { confirmAlert } from "react-confirm-alert";
import AppModal from "../../../widgets/modal";
import { ScaleLoader } from "react-spinners";
import QalboxTable from "../../../widgets/qalbox-table";
import DisplayCategoryModal from "./displayCategoryModal";
import QalboxSearchFilter from "../media/helperComponents/qalboxSearchFilter";

const DisplayCategories = () => {
  const [row, setRow] = useState({});
  const [filterDisplayCategoryPremium, setFilterDisplayCategoryPremium] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState({
    language: DEFAULT_SUPPORTED_LANGUAGE,
    active: "true",
    filterDisplayCategoryPremium
  });
  const [responseData, setOperationData] = qalBoxSearchDisplayCategoriesHook();
  const [deleteResponseData, setDeleteOperationData] = qalBoxDeleteMediaHook();
  const [showDisplayCategoryModal, setShowDisplayCategoryModal] = useState(false);

  const onOperationComplete = (operationData) => {
    if (operationData.isError) {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const onDeleteComplete = (operationData, resetSelectedRows) => {
    requestData(searchCriteria);
    if (operationData.isError) {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      toast.success(`Selected display categories items deleted successfully.`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }

    resetSelectedRows();
  };

  const requestData = (searchCriteriaObj) => {
    setOperationData({
      searchConfig: {
        language_code: searchCriteriaObj.language,
        active_only: searchCriteriaObj.active,
        filterDisplayCategoryPremium: searchCriteriaObj.filterDisplayCategoryPremium
      },
      onOperationComplete,
    });
  };

  useEffect(() => {
    requestData(searchCriteria);
  }, [searchCriteria]);

  const onDeleteSelected = (selectedFlatRows, resetSelectedRows) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            headingText={`Are you sure want to delete the following ${_.size(
              selectedFlatRows
            )} display categories items ?`}
            modalBody={getDeletableDisplayCategories(selectedFlatRows)}
            modalBodyClassName={"precalcs-diff-merge-view"}
            closeButtonText={"No"}
            okButtonText={"Yes, Delete"}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              console.log(getMediaIdsToDelete(selectedFlatRows));
              setDeleteOperationData({
                deleteConfig: {
                  category_ids: getMediaIdsToDelete(selectedFlatRows),
                  language_code: searchCriteria.language,
                  deleteType: "displayCategory",
                },
                onOperationComplete: (response) =>
                  onDeleteComplete(response, resetSelectedRows),
              });
            }}
          />
        );
      },
    });
  };

  const onEditSelected = (selectedFlatRows, resetSelectedRows) => {
    const editableRow = _.get(selectedFlatRows[0], "values");
    const editableObject = getValuesFromRow(editableRow);
    let data = { ...editableObject };

    data.tags = getArrayFromString(data.tags, ", ");
    data.genres = getArrayFromString(data.genres, ", ");
    data.countries_allowed = getArrayFromString(data.countries_allowed, ", ");
    data.countries_disallowed = getArrayFromString(
      data.countries_disallowed,
      ", "
    );
    data.tags = _.remove(data.tags, (element) => {
      return element != "";
    });
    data.genres = _.remove(data.genres, (element) => {
      return element != "";
    });
    data.countries_allowed = _.remove(data.countries_allowed, (element) => {
      return element != "";
    });
    data.countries_disallowed = _.remove(
      data.countries_disallowed,
      (element) => {
        return element != "";
      }
    );

    setRow(data);
    setShowDisplayCategoryModal(true);
    resetSelectedRows();
  };

  const onUpdate = (response) => {
    setShowDisplayCategoryModal(false);
    alert(JSON.stringify(response));
    requestData(searchCriteria);
  };

  const handleCreate = () => {
    setRow({});
    setShowDisplayCategoryModal(true);
  };

  const handleDisplayCategoryPremiumSelect = (name, option) => {
    setFilterDisplayCategoryPremium((prevState) => ({
      ...prevState,
      [name]: option.value,
    }));
    setSearchCriteria((prev) => ({
      ...prev,
      filterDisplayCategoryPremium: option.value,
    }))
  };

  return (
    <LoadingOverlay
      active={ _.get(deleteResponseData, "isDeleting", false)}
      spinner={<ScaleLoader />}
      text="Deleting the selected display categories items."
    >
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={2000} />
        <Row>
          <Col md={9}>
            <h3 className="content_editor_title">{"Display Categories"}</h3>
          </Col>
          <Col md={3} style={{ paddingTop: "20px" }}>
            <button
              style={{
                display: "inline-block",
                padding: "10px 20px",
                backgroundColor: "green",
                color: "white",
                textDecoration: "none",
                borderRadius: "4px",
              }}
              onClick={handleCreate}
            >
              Create
            </button>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div className="form-group">
              <label
                style={{
                  marginRight: "5px",
                }}
              >
                {"Language : "}
              </label>
              <MPSelect
                items={getSupportedLanguages()}
                selectedItem={searchCriteria.language}
                onChangeSelect={(e) =>
                  setSearchCriteria((prev) => ({
                    ...prev,
                    language: e,
                  }))
                }
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="form-group">
              <label
                style={{
                  marginRight: "5px",
                }}
              >
                {"Active : "}
              </label>
              <MPSelect
                items={convertArrayToObjMap(["true", "false"])}
                selectedItem={searchCriteria.active}
                onChangeSelect={(e) =>
                  setSearchCriteria((prev) => ({
                    ...prev,
                    active: e,
                  }))
                }
              />
            </div>
          </Col>
          <Col md={4}>
            <QalboxSearchFilter
              labelName={"Free / Premium : "}
              filterList={DEFAULT_PREMIUM_SEARCH_FILTER}
              selectedSearchFilter={filterDisplayCategoryPremium}
              handleSearchFilterSelect={handleDisplayCategoryPremiumSelect}
            />
          </Col>
        </Row>
        <QalboxTable
          table={{
            data: getDisplayCategoriesDisplayData(_.get(responseData, "data", [])),
            columns: getDisplayCategoryColumns(),
            handleDelete: onDeleteSelected,
            handleEdit: onEditSelected,
            isLoading: _.get(responseData, "isLoading", false),
            tableError: _.get(responseData, "isError", false),
          }}
        />
      </BasicLayout>
      <AppModal
        show={showDisplayCategoryModal}
        showBody={true}
        headingText={`Create Display Categories`}
        modalBody={
          <DisplayCategoryModal
            rowData={row}
            onUpdate={onUpdate}
          />
        }
        modalBodyClassName={""}
        closeButtonText={""}
        okButtonText={""}
        handleClose={() => setShowDisplayCategoryModal(false)}
      />
    </LoadingOverlay>
  );
};

export default DisplayCategories;
