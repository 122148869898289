import _ from 'lodash';
import React from 'react';
import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import {
  fetchPrecalcDataByLatLongHook,
} from '../../hooks';
import {
  CopyToClipboard,
} from 'react-copy-to-clipboard';

const PrecalcDetails = ({ prayertimeLocation }) => {
  if(_.isEmpty(prayertimeLocation)) {
    return null;
  }

  const {
    location_lat,
    location_long,
    location_timezone,
    location_altitude,
    location_place_name,
    location_country_code,
    location_country_name,
  } = prayertimeLocation;

  let precalcFile = null;

  if(location_country_code && location_lat && location_long) {
    const [ precalcData ] = fetchPrecalcDataByLatLongHook({
      latitude: location_lat,
      longitude: location_long,
      countryCode: location_country_code,
      withData: false,
      shouldFetchPrecalcData: true,
    });

    precalcFile = precalcData && precalcData['precalc_id'];
  }



  return (
      <div className="resultitem__group">
        <Row>
          <Col className="text-right" xs={5}>Country :</Col>
          <Col className="small" xs={6}>{location_country_name || 'N/A'}</Col>
        </Row>
        <Row>
          <Col className="text-right" xs={5}>City :</Col>
          <Col className="small" xs={6}>{location_place_name || 'N/A'}</Col>
        </Row>
        <Row>
          <Col className="text-right" xs={5}>Timezone :</Col>
          <Col className="small" xs={6}>{location_timezone || 'N/A'}</Col>
        </Row>
        <Row>
          <Col className="text-right" xs={5}>Altitude :</Col>
          <Col className="small" xs={6}>{location_altitude || 'N/A'}</Col>
        </Row>
        <Row>
          <Col className="text-right" xs={5}>Latitude :</Col>
          <Col className="small" xs={6}>{location_lat || 'N/A'}</Col>
        </Row>
        <Row>
          <Col className="text-right" xs={5}>Longitude :</Col>
          <Col className="small" xs={6}>{location_long || 'N/A'}</Col>
        </Row>
        <Row>
          <Col className="text-right" xs={5}>Precalc ID :</Col>
          <Col className="small" xs={6}>
            <div className={'user-precalc-file-id-main'}>
              <p className={'user-precalc-file-id'}>
                {precalcFile || 'N/A'}
              </p>
              {
                precalcFile ?
                    <CopyToClipboard
                        text={precalcFile}
                        onCopy={() => {
                          console.log(`Copied : ${precalcFile}`)
                        }}
                    >
                      <div className={'copy-user-precalc-file-id-btn'}>
                        <Button className="btn btn-xs btn-dark">
                          &nbsp;<span className="glyphicon glyphicon-duplicate" aria-hidden="true"/>
                        </Button>
                      </div>
                    </CopyToClipboard> : null
              }
            </div>
          </Col>
        </Row>
      </div>
  );
};

export default PrecalcDetails;
