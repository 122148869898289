import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  settingsGetFileVersions,
} from '../../services/mp-service-v1-api-service';

const fetchSettingsFileWithVersions = () => {
  const [ settingsFileVersionsData, setSettingsFileVersionsData ] = useState({ });
  const [ fetchData, setFetchData ] = useState(null);
  const {
    fileName,
    onOperationComplete,
  } = fetchData || {};

  const fetchSettingsFileVersions = async () => {
    try {
      console.log(`Fetching file versions: ${fileName}...`);
      setSettingsFileVersionsData({
        isFetching: true,
        isError: false,
        fetchedFileName: fileName,
      });
      const getFileVersionsApiResp =  await settingsGetFileVersions({
        fileName,
      });

      const responseData = getFileVersionsApiResp.data;
      let errorMsg = null;
      if(responseData.success) {
        console.log(`Fetching file : ${fileName}  versions Success!!`);
      } else {
        console.log(`Fetching file : ${fileName}  versions Failed!!`);
        errorMsg = `Error occurred while fetching file: ${fileName}  versions.`;
      }

      setSettingsFileVersionsData({
        data: responseData.data,
        isFetching: false,
        isError: !responseData.success,
        errorMsg,
        fetchedFileName: fileName,
      });
      onOperationComplete({
        isError: !responseData.success,
        errorMsg,
      });
    } catch (err) {
      console.log(`Error while fetching file: ${fileName} versions. Error : ${err}`);
      const errorMsg = (err.response && err.response.status === 404) ?
          `File: ${fileName} not found!` : `Error occurred while fetching file : ${fileName} versions.`;
      setSettingsFileVersionsData({
        data: null,
        isFetching: false,
        isError: true,
        errorMsg,
        fetchedFileName: fileName,
      });
      onOperationComplete({
        isError: true,
        errorMsg,
      });
    }
  };

  useEffect( () => {
    if(!_.isEmpty(fetchData)) {
      fetchSettingsFileVersions();
    }
  }, [ fetchData ]);

  return [
    settingsFileVersionsData,
    setFetchData,
  ];
};

export default fetchSettingsFileWithVersions;
