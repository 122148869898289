import {
  RedisCaches,
  CFRedisFlushKeys,
  APIV2RedisFlushKeys,
  APIV2RedisFlushTypes,
} from './Constants';

const shouldDisableAPIV2FlushCacheSubmitBtn = ({
                                                 mainCacheKey,
                                                 selectedCacheKey,
                                                 selectedFlushType,
                                               }) => {
  if(APIV2RedisFlushTypes[selectedFlushType] === APIV2RedisFlushTypes.select) {
    return !selectedCacheKey || selectedCacheKey === 'default';
  } else if(APIV2RedisFlushTypes[selectedFlushType] === APIV2RedisFlushTypes.manual) {
    return !mainCacheKey;
  } else {
    return true;
  }
};

const getCacheFlushKeys = (
  selectedAPI,
  selectedCacheKey,
  mainCacheKey,
  subCacheKey,
  ) => {
  let mainKey;
  let subKey;

  if(selectedCacheKey) {

    const cacheKeys = RedisCaches[selectedAPI] === RedisCaches.API_V2 ?
        APIV2RedisFlushKeys[selectedCacheKey] : CFRedisFlushKeys[selectedCacheKey];
    mainKey = cacheKeys.mainKey;
    subKey = cacheKeys.subKey || null;
  } else {
    mainKey = mainCacheKey;
    subKey = subCacheKey || null;
  }

  return [mainKey, subKey];
};

export {
  getCacheFlushKeys,
  shouldDisableAPIV2FlushCacheSubmitBtn,
}
