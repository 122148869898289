import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  bulkAddUpdateVirtualTours,
} from '../../services/mp-cf-api-service';

const bulkAddOrUpdateVirtualTours = () => {
  const [ responseData, setResponseData ] = useState({ });
  const [ updateData, setUpdateData ] = useState(null);
  const {
    items,
    onOperationComplete,
  } = updateData || {};

  const addUpdateVirtualTours = async () => {
    try {
      console.log(`Bulk add or update virtual tours...`);
      setResponseData({
        isUpdating: true,
        isError: false,
      });

      const virtualToursBulkAddUpdateApiResp = await bulkAddUpdateVirtualTours({
        virtualTours: items,
      });
      const responseData = virtualToursBulkAddUpdateApiResp.data;

      let errorMsg = null;
      let isError = false;

      if(responseData.success) {
        console.log('Virtual tours add/update Success!!');
      } else {
        console.log('Virtual tours add/update Failed!!');
        errorMsg = `Failed to add/update Virtual tours`;
        isError = true;
      }

      setResponseData({
        isUpdating: false,
        isError,
        errorMsg,
        data: responseData.data,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError,
          errorMsg,
          data: responseData.data,
        });
      }
    } catch (err) {
      console.log('Something went wrong while add/update virtual tours. ', err);
      const errorMsg = `Error occurred while add/update virtual tours.`;
      setResponseData({
        isUpdating: false,
        isError: true,
        errorMsg,
        data: null,
      });
      if(onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
          data: null,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(updateData)) {
      addUpdateVirtualTours();
    }
  }, [ updateData ]);

  return [
    responseData,
    setUpdateData,
  ];
};

export default bulkAddOrUpdateVirtualTours;
