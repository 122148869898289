import React,
{
  Component,
} from 'react';
import {
  Row,
  Button,
  Col
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {MPUploadExcel} from "../../widgets/upload-excel";
import { ClearDbInfoPanel, MediaExcelUploadInfoPanel } from './common';
import aideenResetOrUpdateHook from '../../hooks/aideen/resetOrUpdate';
import AiDeenService from '../../service_v2/aideen/aideen-service';
import { getMediaItems } from './helper';

class ClearQueryCollection extends Component {

    async handleClearSubmit(e) {
        e.preventDefault();
        //call api to reset mongodb
        const resp = await AiDeenService.resetAiDeenDb();
        if (resp){
            alert('Database is reset');
        } else {
            alert('An error occurred');
        }
    }

    render() {
        return (
            <BasicLayout pagePermission="readonly">
                <div>
                    <form className={'form-inline'} onSubmit={this.handleClearSubmit.bind(this)}>
                    <Row>
                        <h3>Reset Query Collection in MongoDb</h3>
                    </Row>
                    <Row>
                        <ClearDbInfoPanel></ClearDbInfoPanel>
                        <Button
                            className="btn-primary"
                            type="submit">
                            Clear & Reset
                        </Button>
                            <br/>
                    </Row>
                    </form>
                    <form className={'form-inline'}>
                     <Row>
                        <h3>Upload data to query collection</h3>
                    </Row>
                    <Row>
                        {/* <OperationStatus operationInProgressText='Uploading in progress..'/> */}
                         <MPUploadExcel
                            InfoPanel={MediaExcelUploadInfoPanel}
                            fileUpdateHook={aideenResetOrUpdateHook}
                            fileUpdateSuccessMessage={'Media Items Added/Updated Successfully!!'}
                            getItems={getMediaItems}
                            getCopySuccessItems={()=>{}}
                            // onUploadSuccess={onUploadSuccess}
                            // onUploadFailed={onUploadFailed}
                            showResultOnSuccess={true}
                        />
                       
                    </Row>
                    </form>
                </div>
            </BasicLayout>
        )
    }
}
export default ClearQueryCollection;