import './style.css';

const CustomFilter = ({ column }) => {
  const { filterValue, setFilter } = column;

  return (
    <input
      className="search-input"
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value)}
      placeholder={`Filter ${column.Header}`}
    />
  );
};

export default CustomFilter;
