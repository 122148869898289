import _ from 'lodash';
import {
  ADMIN_DATABASE_URL,
} from '../Constants';
import firebase from 'firebase/app';

const getAdUnits = async ({
                            environment= 'Production',
                          } ) => {
  const app = firebase.app();
  const dbPath = `AdUnits/${environment}`;
  const snapshot = await app.database(ADMIN_DATABASE_URL).ref(dbPath).once('value');
  return snapshot.val();
};

const createAdUnit  = async ({
                               id,
                               name,
                               environment= 'Production',
                             } ) => {
  const app = firebase.app();
  const dbPath = `AdUnits/${environment}/${id}`;
  return app.database(ADMIN_DATABASE_URL).ref(dbPath).set({
    name: name,
    id: id,
    content_ids: [],
  });
};

const fetchAdUnit = async ({
                             id,
                             environment= 'Production',
                           } ) => {
  const app = firebase.app();
  const dbPath = `AdUnits/${environment}/${id}`;
  const snapshot = await app.database(ADMIN_DATABASE_URL).ref(dbPath).once('value');

  return snapshot.val();
};

const updateAdUnit  = async ({
                               id,
                               name,
                               content_ids,
                               environment= 'Production',
                             } ) => {
  const app = firebase.app();
  const dbPath = `AdUnits/${environment}/${id}`;
  let updatedData  = {};
  if(id) {
    updatedData['id'] = id;
  }

  if(name) {
    updatedData['name'] = name;
  }

  if(content_ids) {
    updatedData['content_ids'] = content_ids;
  }

  if(!_.isEmpty(updatedData)) {
    return app.database(ADMIN_DATABASE_URL).ref(dbPath).update(updatedData);
  }

  return false;
};

const deleteAdUnit = async ({
                              id,
                              environment= 'Production',
                            }) => {
  const app = firebase.app();
  const dbPath = `AdUnits/${environment}/${id}`;
  return app.database(ADMIN_DATABASE_URL).ref(dbPath).remove();
};

const getEmailDashboardSenderAddresses = async ({
                                                  environment = 'Production',
                                                }) => {
  const app = firebase.app();
  const dbPath = `Configs/${environment}/EmailDashboard/senderAddresses`;
  const snapshot = await app.database(ADMIN_DATABASE_URL).ref(dbPath).once('value');
  return snapshot.val();
};

const getEmailDashboardReplyToAddresses = async ({
                                                   environment = 'Production',
                                                 }) => {
  const app = firebase.app();
  const dbPath = `Configs/${environment}/EmailDashboard/replyToAddresses`;
  const snapshot = await app.database(ADMIN_DATABASE_URL).ref(dbPath).once('value');
  return snapshot.val();
};

const addActionHistory = async ({
                                  actionPath='ROOT_ACCESS_NOT_SUPPORTED',
                                  history={},
                                }) => {
  history['timestamp'] = Date.now();
  const app = firebase.app();
  const dbPath = `history/${actionPath}`;
  return app.database(ADMIN_DATABASE_URL).ref(dbPath).push(history);
};

const getHistories = async (path) => {
  const app = firebase.app();
  const dbPath = `history/${path}`;
  const snapshot = await app.database(ADMIN_DATABASE_URL).ref(dbPath).once('value');
  return snapshot.val();
};

const getModerators = async () => {
  const app = firebase.app();
  const dbPath = 'moderators';
  const snapshot = await app.database(ADMIN_DATABASE_URL).ref(dbPath).once('value');
  return snapshot.val();
};

const getDataModerators = async () => {
  const app = firebase.app();
  const dbPath = 'data-moderators';
  const snapshot = await app.database(ADMIN_DATABASE_URL).ref(dbPath).once('value');
  return snapshot.val();
};

const getModerator = async (uid) => {
  const app = firebase.app();
  const dbPath = `moderators/${uid}`;
  const snapshot = await app.database(ADMIN_DATABASE_URL).ref(dbPath).once('value');
  return snapshot.val();
};

const deleteModerator = async (uid) => {
  const app = firebase.app();
  const dbPath = `moderators/${uid}`;
  return app.database(ADMIN_DATABASE_URL).ref(dbPath).remove();
};

const updateModerator = async (moderatorData, uid=null) => {
  const app = firebase.app();
  const dbPath = uid ? `moderators/${uid}` : 'moderators';
  return app.database(ADMIN_DATABASE_URL).ref(dbPath).set(moderatorData);
};

const getTestEmails = async () => {
  const app = firebase.app();
  const dbPath = 'test-emails';
  const snapshot = await app.database(ADMIN_DATABASE_URL).ref(dbPath).once('value');
  return snapshot.val();
};

export {
  getAdUnits,
  createAdUnit,
  fetchAdUnit,
  updateAdUnit,
  deleteAdUnit,
  getEmailDashboardSenderAddresses,
  getEmailDashboardReplyToAddresses,
  addActionHistory,
  getHistories,
  getModerators,
  getModerator,
  updateModerator,
  deleteModerator,
  getDataModerators,
  getTestEmails,
}
