import React,
{
  Fragment,
  Component,
} from 'react';
import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  listPrecalcFileVersions,
} from '../../services/mp-service-v1-api-service';
import './style.css';
import {
  fileNameRegex,
} from './Constants';
import GCSFileVersionViewer from '../../widgets/gcs-flie-version-viewer';
import {
  updatePrecalcFileHook,
} from '../../hooks';

const InitialState = {
  submitting: false,
  fileName: '',
  apiResponseData: null,
  selectedDate: null,
};

class ListPrecalcFileVersions extends Component {
  constructor(props) {
    super(props);
    this.state = InitialState;
  }

  async handleSubmit(e) {
    console.log(JSON.stringify(this.state));
    e.preventDefault();
    this.setState({ submitting : true });

    const {
      fileName,
    } =  this.state;

    const apiPayload = { fileName, };

    try {
      console.log('apiPayload : ', JSON.stringify(apiPayload));
      if (apiPayload) {
        const precalcApiResp =  await listPrecalcFileVersions(apiPayload);

        const responseData = precalcApiResp.data;
        console.log('responseData : ', JSON.stringify(responseData));
        if(responseData) {
          console.log('success');
          toast.success('Operation completed!', {
            position: toast.POSITION.BOTTOM_LEFT
          });
          this.setState({
            submitting: false,
            apiResponseData: {
              responseData,
              success: true,
              reason: null,
            },
          });
        } else {
          console.log(`Received error API Response . ${JSON.stringify(precalcApiResp)}`);
          toast.error(`Something went wrong!, Please try again.`, {
            position: toast.POSITION.BOTTOM_LEFT
          });
          this.setState({
            submitting: false,
          });
        }
      }
    } catch (err) {
      console.log(`Something went wrong while trying to get the API response. Error :  ${JSON.stringify(err)}`);
      if(err.response && err.response.status === 404) {
        this.setState({
          submitting: false,
          apiResponseData: {
            responseData: null,
            success: false,
            reason: 'File not found!'
          },
        });
        toast.error(`File not found, Check the file name again.`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
      } else {
        toast.error(`Something went wrong!, Please try again.`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
        this.setState({ submitting: false });
      }
    }
  }

  renderSubmitButton(submitting, validFileName) {
    return (
        <Button
            type="submit"
            disabled={submitting || !validFileName}
            className="btn-primary api-test-submit-button">
          { submitting ? 'Fetching...' : 'Fetch' }
        </Button>
    );
  }

  onFieldChange(fieldKey, e) {
    const fileName = e.target.value;
    this.setState({ [fieldKey]: fileName, apiResponseData: null });
  }

  renderResponseDataView({ apiResponseData, fileName }) {
    if(!apiResponseData) {
      return null;
    }

    const respCssClass = apiResponseData.success ? 'success' : 'error';

    // TODO : check the empty list.

    return (
      <div className={`precalc-api-response-main-${respCssClass}`}>
        <Row>
          <Col md={12}>
            <p className={`precalc-api-response-main-${respCssClass}-header`}>
              {
                apiResponseData.success ?
                    `All versions for - ${fileName}` :
                    `File not found with the name "${fileName}"`
              }
            </p>
          </Col>
        </Row>
        {
          apiResponseData.success ?
            <Fragment>
              <Row>
                <Col md={12}>
                  <GCSFileVersionViewer
                      fileVersionsList={apiResponseData.responseData.data}
                      fileUpdateHook={updatePrecalcFileHook}
                  />
                </Col>
              </Row>
            </Fragment> : null
        }
      </div>
    );
  }

  render() {
    const {
      apiResponseData, fileName, submitting,
    } = this.state;

    const validFileName = fileName ? (fileName === 'precalc_list' || fileNameRegex.test(fileName)) : false;
    const invalidFileNameErrorMsg = 'Invalid file name. File name should be "precalc_list" or Should follow pattern : MY0000003';

    return (
        <BasicLayout pagePermission="moderate_precalc">
          <ToastContainer autoClose={2000} />
          <div>
            <form className={'form-inline'} onSubmit={this.handleSubmit.bind(this)}>
              <Row>
                <h3>{'List Precalc File Versions'}</h3>
              </Row>
              <br/>
              <Row>
                <Col md={4}>
                  <div className="form-group">
                    <label style={{'marginRight': '5px'}}>{'File Name : '}</label>
                    <input value={fileName}
                           required
                           onChange={this.onFieldChange.bind(this, 'fileName')}
                           type="text"
                           className="form-control"
                           placeholder="Sheet Number"
                    />
                    {
                      fileName && !validFileName ?
                          <div className={'invalid-file-name-error'}>
                            {invalidFileNameErrorMsg}
                          </div> : null
                    }
                  </div>
                </Col>
                <Col md={3}>
                  <div className="form-group">
                    {this.renderSubmitButton(submitting, validFileName)}
                  </div>
                </Col>
              </Row>
              {
                this.renderResponseDataView({ apiResponseData, fileName })
              }
            </form>
          </div>
        </BasicLayout>
    );
  }
}

export default ListPrecalcFileVersions;
