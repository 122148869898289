import {
  useState,
  useEffect,
} from 'react';
import { getV2RedisInfo } from '../../services/mp-service-v1-api-service';

const getAPIV2RedisInfo = ({
                             section,
                             shouldGetInfo
                           }) => {
  const [ redisInfoResponse, setRedisInfoResponse ] = useState(null);

  const getV2Info = async () => {
    try {
      console.log(`Fetching Info... For Section : ${section}`);
      const redisInfoApiResp =  await getV2RedisInfo({
        section,
      });

      const responseData = redisInfoApiResp.data;
      if(responseData.success) {
        console.log('Fetching Info Success!!');
      } else {
        console.log('Fetching Info Failed!!');
      }

      setRedisInfoResponse({
        status: responseData.success,
        message: responseData.success ? 'Info fetched Successfully!!' : 'Opz, Something went wrong while fetching info!!',
        data: responseData.data,
      });
    } catch (err) {
      console.log(`Error while fetching info for section : ${section}. Error : ${err}`);
      setRedisInfoResponse({
        status: false,
        message: 'Opz, Something went wrong while fetching info!!',
        data: null,
      });
    }
  };

  useEffect( () => {
    if(shouldGetInfo) {
      getV2Info();
    } else {
      setRedisInfoResponse(null)
    }
  }, [ shouldGetInfo, section ]);

  return [
    redisInfoResponse,
  ];
};

export default getAPIV2RedisInfo;
