import React from 'react';
import {
  ScaleLoader,
} from 'react-spinners';

const SectionLoader = (props) => {
    return (
        <div className="text-center">
            <ScaleLoader />
            <span className="small">{props.text}</span>
        </div>
    );
};

export default SectionLoader;
