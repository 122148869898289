import {Alert, Col, Row} from "react-bootstrap";
import Collapse, {Panel} from "rc-collapse";
import {MPExpandIcon} from "../../widgets/common";
import React from "react";


const ImageUploadInfoPanel = () => {

  return (
    <Row>
      <Col>
        <Alert>
          <strong style={{'fontSize': '20px'}}>
            Important Note (Please read before you upload):
          </strong>
          <br/>
          <Collapse
            accordion={true}
            className={'upload-note-collapse'}
            expandIcon={MPExpandIcon}
          >
            <Panel
              header="Image Upload Guide"
              headerClass="excel-upload-note-header"
              extra={<span>Click to see/hide Details</span>}
            >
              <ol>
                <li>
                  <p>Please note you can only upload <strong><u> maximum of 30</u></strong> images to the Google Cloud Storage at a single upload.</p>
                </li>
                <li>
                  <p>Also note that if you upload the same name file it will <strong><u> replace the existing</u></strong> image in the Google Cloud Storage and it is <strong><u> irreversible.</u></strong></p>
                </li>
                <li>
                  <p>When you replace images make sure to tick the  <strong>Clear CDN Cache</strong> tick, which clear the CDN cache and it will take up to 10 minutes to take effect.</p>
                </li>
                <li>
                  <p>Before hit the <strong>upload</strong> button double check the:  <strong><u> Language</u></strong>, <strong><u> Type</u></strong>, and <strong><u> Size</u></strong> parameters.</p>
                </li>
                <li>
                  <p>If you are uploading the unoptimized images then please tick the Optimize Images section to optimize the images.
                    <strong><u>Please be careful</u></strong> when using this feature, because if you tick for already optimized images
                    it will caused to produce very weak images after the upload.
                  </p>
                </li>
              </ol>
            </Panel>
          </Collapse>
        </Alert>
      </Col>
    </Row>
  );
};


export {
  ImageUploadInfoPanel,
}
