import React,
{
  useReducer,
} from 'react';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  MPInput,
  MPCheckBox,
  ShowJSONData,
  OperationStatus,
} from '../../widgets/common';
import {
  TSAInitialState,
  testSettingsAPIReducer,
} from './Reducers';
import {
  actionTypes,
} from './actionTypes';
import {
  testSettingsAPIHook,
} from '../../hooks';

const TestSettingAPI = () => {
  const[ state, dispatch ] = useReducer(testSettingsAPIReducer, TSAInitialState);

  const {
    version,
    platform,
    language,
    flushCache,
    countryCode,
    premiumStatus,
  } = state;

  const [ apiCallData, callAPI ] = testSettingsAPIHook();
  const isFetching = apiCallData && apiCallData.isFetching;

  console.log(apiCallData)
  const showJSONData = apiCallData && apiCallData.data;

  const onOperationComplete = operationData => {
    if(!operationData.isError) {
      toast.success(`Getting Settings API Response Success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const testAPIResponse = () => {
    callAPI({
      apiInputValues: {
        version,
        platform,
        flushcache: flushCache ? 'true' : 'false',
        premium_status: premiumStatus,
        language,
        country_code: countryCode,
      },
      onOperationComplete,
    });
  };

  const disableFetchButton = isFetching || !language;

  return (
      <BasicLayout pagePermission="readonly">
        <ToastContainer autoClose={2000}/>
        <div>
          <Row>
            <Col md={12}>
              <h3 className='content_editor_title'>{'Test Settings API'}</h3>
            </Col>
          </Row>
          <form className={'form-inline'}>
            <Row>
              <Col md={6}>
                <div className='form-group'>
                  <label style={{ 'marginRight': '5px' }}>{`Platform : `}</label>
                  <MPInput
                      value={platform}
                      onChangeValue={(value) => dispatch({
                        type: actionTypes.STA_UPDATE_PROP_VAL,
                        payload: {
                          prop: 'platform',
                          value,
                        },
                      })
                      }
                      placeHolder={'Platform'}
                  />
                </div>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={6}>
                <div className='form-group'>
                  <label style={{ 'marginRight': '5px' }}>{`Version : `}</label>
                  <MPInput
                      value={version}
                      onChangeValue={(value) => dispatch({
                        type: actionTypes.STA_UPDATE_PROP_VAL,
                        payload: {
                          prop: 'version',
                          value,
                        },
                      })
                      }
                      placeHolder={'Version'}
                  />
                </div>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={6}>
                <div className='form-group'>
                  <label style={{ 'marginRight': '5px' }}>Language<sup>*</sup> : </label>
                  <MPInput
                      value={language}
                      onChangeValue={(value) => dispatch({
                        type: actionTypes.STA_UPDATE_PROP_VAL,
                        payload: {
                          prop: 'language',
                          value,
                        },
                      })
                      }
                      placeHolder={'Language'}
                  />
                </div>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={6}>
                <div className='form-group'>
                  <label style={{ 'marginRight': '5px' }}>{`Premium Status : `}</label>
                  <MPInput
                      value={premiumStatus}
                      onChangeValue={(value) => dispatch({
                        type: actionTypes.STA_UPDATE_PROP_VAL,
                        payload: {
                          prop: 'premiumStatus',
                          value,
                        },
                      })
                      }
                      placeHolder={'Premium Status'}
                  />
                </div>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={6}>
                <div className='form-group'>
                  <label style={{ 'marginRight': '5px' }}>{`Flush Cache : `}</label>
                  <MPCheckBox
                      name={'Flush Cache'}
                      checked={flushCache}
                      onChangeValue={(value) => dispatch({
                        type: actionTypes.STA_UPDATE_PROP_VAL,
                        payload: {
                          prop: 'flushCache',
                          value,
                        },
                      })
                      }
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Button
                    onClick={testAPIResponse}
                    disabled={disableFetchButton}
                    className="btn-primary api-test-submit-button">
                  { isFetching ? 'Fetching..' : 'Fetch' }
                </Button>
              </Col>
            </Row>
          </form>
          <br/>
          {
            showJSONData ?
                <ShowJSONData data={apiCallData.data} />
                 : null
          }
          {
            isFetching ?
                <OperationStatus
                    operationInProgressText={`Getting Settings API Response...`}
                /> : null
          }
        </div>
      </BasicLayout>
  );
};

export default TestSettingAPI;
