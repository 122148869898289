// Moed old developer options to general
import React,
{
  Component,
} from 'react';
import BasicLayout from '../../layouts/basiclayout';
import {
  Row,
  Col,
} from 'react-bootstrap';
import Toggle from 'react-toggle';
import {
  developers,
} from '../../AppConfig';
import './style.css';

class DeveloperOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBgLogsRes: false,
    };
  }

  componentWillMount() {
    const showBgLogsRes = localStorage.getItem("showBgLogsRes") || false;
    this.setState({
      showBgLogsRes,
    });
  }

  toggleHandler(key, checked) {
    this.setState({
      [key]: checked
    });
    if(checked) {
      window.localStorage.setItem(key, true);
    } else {
      window.localStorage.removeItem(key);
    }
    setTimeout(() => {
      this.refresh();
    }, 500);
  }

  refresh() {
    window.location = '/developer-options';
  }

  isDeveloper(currentUid) {
    return developers.indexOf(currentUid) > -1;
  }

  render() {
    const currentUid = localStorage.getItem('uid');
    const checkIsDeveloper = this.isDeveloper(currentUid);
    if(!checkIsDeveloper) {
      return (
        <BasicLayout pagePermission="moderate_precalc">
          <Row className="developer__options">
            <p className="text-danger"><span className="glyphicon glyphicon-exclamation-sign"></span> Access denied! Your account is not a developer account.</p>
          </Row>
        </BasicLayout>
      );
    }

    const {
      showBgLogsRes,
    } = this.state;
    return (
      <BasicLayout pagePermission="write_users">
        <Row className="developer__options">
          <Item
            checked={showBgLogsRes}
            handleToggleChange={this.toggleHandler.bind(this, 'showBgLogsRes')}
            title="Show BigQuery logs"
            detail="Keep it on if you want to see the BigQuery admin logs API confirmation or error response after every action from admin console."
          />
        </Row>
      </BasicLayout>
    );
  }
}

class Item extends Component {
  handleToggleChange(e) {
    this.props.handleToggleChange(e.target.checked)
  }

  render() {
    const { checked, detail, title } = this.props;
    return (
      <div className="devop__item">
        <Col md={5} className="text-right">
          <p className="title"><strong>{title}</strong></p>
          <p className="small detail">{detail}</p>
        </Col>
        <Col className="toggle_wrapper" md={7}>
        <Toggle
          checked={checked}
          onChange={this.handleToggleChange.bind(this)} />
        </Col>
      </div>
    );
  }
}

export default DeveloperOptions;
