const skuDbUrl = 'https://mp-premium-skus.firebaseio.com';

const platformIconMap = {
  'ios': 'apple',
  'android': 'google',
  'web': 'globe'
};

const skuPlatforms = [
  {
    key: 'android',
    value: 'Android'
  },
  {
    key: 'ios',
    value: 'iOS'
  },
  {
    key: 'web',
    value: 'Web'
  }
];

const premiumListPositions = [
  {
    key: 'premium_page',
    value: 'Premium Page'
  },
  {
    key: 'premium_popup',
    value: 'Premium Popup'
  }
];

export {
    skuDbUrl,
    platformIconMap,
    skuPlatforms,
    premiumListPositions,
};
