import React,
{
  useState,
} from 'react';
import {
  BlockPicker,
} from 'react-color';
import './style.css';

/**
 *
 * @param hexColor - String : Hex Value of the Color
 * @param showText - Boolean : Show Text or Not.
 * @param textDirection - String: Text show direction ['right', 'bottom']
 * @returns {*} ColorPreviewBox
 * @constructor
 */
const ColorPreviewBox = ({
                           hexColor,
                           showText=true,
                           textDirection='right',
                         }) => {
  const textStyles = {};
  if(textDirection === 'right') {
    textStyles['marginLeft'] = '5px';
  }

  return (
      <div className={'color-picker-color-box-main'}
           style={{ flexDirection: `${textDirection==='right' ? 'row': 'column'}` }}
      >
        <div
            className={'color-picker-color-box'}
            style={{ background: `${hexColor}` }}
        />
        {
          showText ?
              <div className={'color-picker-color-box-text'}
                   style={textStyles}
              >
                {hexColor}
              </div> : null
        }

      </div>
  );
};

const ColorBlockPicker = ({
                        initialColor='#F0921F',
                        pickerMainCssClass='color-picker-main',
                        defaultColors=['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF'],
                        onColorChange=(color)=> console.log(`Color changed to ${color}`),
                        closeOnSelect=false,
                        resetState=false,
                        upperCaseHex=true,
                      }) => {
  const initialColorWithCase = upperCaseHex ? initialColor.toUpperCase() : initialColor;

  const [ color, setColor ] = useState({ hex : initialColorWithCase });
  const [ showPicker, setShowPicker ] = useState(false);

  if(resetState && ((color && color.hex) !== initialColorWithCase)) {
    setColor({ hex : initialColorWithCase });
  }

  const onChange = (color) => {
    const colorWithCase = upperCaseHex ? {
      ...color,
      hex: color.hex.toUpperCase(),
    } : color;

    setColor(colorWithCase);
    onColorChange(colorWithCase);

    if(closeOnSelect) {
      setShowPicker(false);
    }
  };

  return (
      <div>
        <div className={'color-picker-swatch'} onClick={() => setShowPicker(!showPicker)}>
          <ColorPreviewBox hexColor={color.hex} />
        </div>
        {
          showPicker ?
              <div className={`${pickerMainCssClass} ${upperCaseHex ? 'color-picker-upper-case-hex' : ''}`}>
                <div className={'color-picker-cover'} onClick={() => setShowPicker(false)} />
                <BlockPicker
                    color={color}
                    colors={defaultColors}
                    onChange={onChange}
                    triangle={'hide'}
                />
              </div> : null
        }
      </div>
  );
};

export {
  ColorPreviewBox,
  ColorBlockPicker,
};
