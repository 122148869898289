import _ from "lodash";

export const convertBrandArrayToSelectList = (objectArray) => {
  return _.map(objectArray, (item) => {
    return {
      value: item.uuid,
      label: item.name,
    };
  });
};

export const convertBrandToOption = (value, brandList = []) => {
  const brand = brandList.find(item => item.uuid === value);

  if (!value) {
    return {
      value: null,
      label: 'Select a Brand',
    };
  }

  return {
    value: value,
    label: brand?.name,
  };
};

