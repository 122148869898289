import React from 'react';
import Select from 'react-select';

const QalboxSelectDropdown = ({ name, options, selectedOption, onChange }) => {
  const handleChange = option => {
    onChange(name, option);
  };

  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={handleChange}
      placeholder="Select an option..."
      isSearchable
    />
  );
};

export default QalboxSelectDropdown;