import _ from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { DELETETABLE_ROWS_AFTER, SPECIAL_HEADERS_COUNT } from './Constants';
import { getDateFromExcelValue } from '../../utils';

const moment = extendMoment(Moment);

const getDateString = (dateString) => {
  //inputDateFormat='D-M-YYYY';
  const [ date, month, year] = dateString.split('-');
  return `${month}-${date}-${year}`;
};

const getOrderedQuranQuotesList = (quranQuotesList, reversed=true) => {
  return reversed
    ? _.sortBy(quranQuotesList, quranQuote => {
      return new Date(getDateString(_.keys(quranQuote)[0]));
    }).reverse()
    : _.sortBy(quranQuotesList, quranQuote => {
      return new Date(getDateString(_.keys(quranQuote)[0]));
    });
}

const getQuranQuotesList = (quranQuotesListData) => {
  return _.isEmpty(quranQuotesListData.data) ?
    [] : getOrderedQuranQuotesList(quranQuotesListData.data);
};

const getRangeMinMaxDates = ({
                              serverMaxDate, serverMinDate, excelMaxDate, excelMinDate,
                            }) => {
  let maxDate, minDate;

  const serverHasMaxDate = new Date(serverMaxDate) > new Date(excelMaxDate);
  const serverHasMinDate = new Date(serverMinDate) < new Date(excelMinDate);
  minDate = serverHasMinDate ? serverMinDate : excelMinDate;
  maxDate = serverHasMaxDate ? serverMaxDate : excelMaxDate;

  return {
    minDate,
    maxDate,
  };
};

const getUpdatedQuranQuotesList = (quranQuotesList, changedData) => {
  const changedItemIndex = _.findIndex(quranQuotesList, changedData.date);
  const newValue = {
    [changedData.date] : {
      [changedData.sura]: `${changedData.verse}`
    }
  };

  if( changedItemIndex !== -1) {
    quranQuotesList.splice(changedItemIndex, 1, newValue);
  }

  return quranQuotesList;
};

const getDeletedQuranQuotesList = (quranQuotesList, deleteData) => {
  const deletedItemIndex = _.findIndex(quranQuotesList, deleteData.date);

  if( deletedItemIndex !== -1) {
    quranQuotesList.splice(deletedItemIndex, 1);
  }

  return quranQuotesList;
};

const getNextElementsAdded = (quranQuotes, addNext) => {
  const lastElement =  quranQuotes[0];
  const nextElementDate = new Date(getDateString(_.keys(lastElement)[0]));
  _.range(addNext).forEach(() => {
    nextElementDate.setDate(nextElementDate.getDate() + 1);
    quranQuotes.unshift({ [`${moment(nextElementDate).format('D-M-YYYY')}`] : {'': ''}});
  });

  return quranQuotes;
};

const isXDaysOrMore = ({ dateString, xDays=7, startDay }) => {
  return moment(getDateString(startDay))
    .diff(getDateString(dateString), 'days') > xDays;
};

const getValidationStartDay = (firstDate) => {
  const diff = moment().diff(getDateString(firstDate), 'days');
  return diff > 0 ? firstDate : moment().format('D-M-YYYY');
};

const isDataUpdated = (quranQuotes, initialQuranQuotes) => {
  return !_.isEmpty(_.difference(quranQuotes, initialQuranQuotes))
    || !_.isEmpty(_.difference(initialQuranQuotes, quranQuotes));
};

const isDataValid = (quranQuotes) => {
  let dataValid = true;
  _.forEach(quranQuotes, quranQuote => {
    const suraAndVerse = _.values(quranQuote)[0];
    const sura = _.keys(suraAndVerse)[0];
    const verse = _.values(suraAndVerse)[0];
    if(!sura || !verse) {
      dataValid = false;
      return false;
    }
  });

  return dataValid
};

const getUpdatableQuranQuotes = (quranQuotes, firstDate) => {
  const validationStartDay = getValidationStartDay(firstDate);

  const updatableQuranQuotes = [];
  _.forEach(quranQuotes, quranQuote => {
    const date = _.keys(quranQuote)[0];
    const deletableDate = isXDaysOrMore({
      dateString: date,
      startDay: validationStartDay,
      xDays: DELETETABLE_ROWS_AFTER,
    });

    if(deletableDate) {
      // Moving out from the loop since all quotes after this should be deleted.
      return false;
    }
    updatableQuranQuotes.push(quranQuote);
  });

  return getOrderedQuranQuotesList(updatableQuranQuotes, false);
};

const getQuranQuotesMap = quranQuotesList => {
  return _.reduce(quranQuotesList, (qqMap, quranQuote) => {
    qqMap[_.keys(quranQuote)[0]] = _.values(quranQuote)[0];
    return qqMap;
  }, {});
};

const getQuranQuotesListFromMap = quranQuotesMap => {
  return  _.reduce(quranQuotesMap, (qqArr, val, key) => {
    qqArr.push({ [key] : val });
    return qqArr;
  }, []);
};

const getDatesArray = orderedQuranQuotesList => {
  return _.map(orderedQuranQuotesList, qq => {
    return getDateString(_.keys(qq)[0]);
  })
};

const getMissingDates = ({ minDate, maxDate, orderedQuranQuotesList }) => {
  const missingDates = [];
  const datesArray = getDatesArray(orderedQuranQuotesList);
  const fullDateRange = moment.range(minDate, maxDate);
  const allDateRanges = Array.from(fullDateRange.by('days', { step: 1 }));

  allDateRanges.forEach(date => {
    const currentDate = date.format('M-D-YYYY');
    if(datesArray.includes(currentDate)) {
      console.log('Date found!')
    } else {
      missingDates.push(currentDate);
    }
  });

  return missingDates;
};

const getServerMaxDate = quranQuotesList => {
  return _.isEmpty(quranQuotesList)
    ? null : getDateString(_.keys(quranQuotesList[0])[0]);
};

const getServerMinDate = quranQuotesList => {
  return  _.isEmpty(quranQuotesList)
    ? null : getDateString(_.keys(quranQuotesList[quranQuotesList.length - 1])[0]);
};

const getItems = async (quranQuotesList, rows) => {
  const serverMaxDate = getServerMaxDate(quranQuotesList);
  const serverMinDate = getServerMinDate(quranQuotesList);
  let excelMaxDate = null, excelMinDate = null;
  const quranQuotesMap = getQuranQuotesMap(quranQuotesList);

  const allErrors = [];
  // Starting from SPECIAL_HEADERS_COUNT, because we need to skip headers.
  for(let i = SPECIAL_HEADERS_COUNT; i < rows.length; i++) {
    let errorsCount = 0;
    const data = rows[i];

    const errors = {
      'Row error': 'Row has some errors',
      'Row': i + 1,
      'errorsCount': 0,
      'errorList': [],
      'excelSheetRowData': data,
    };

    // Date
    const date = data[0] || null;
    let excelDate;
    if(date) {
      excelDate = getDateFromExcelValue(date, false, 'D-M-YYYY');
    } else {
      errors['errorList'].push(`${++errorsCount}. Date is empty.`);
    }

    // Sura
    const sura = data[1] || null;
    if(!sura) {
      errors['errorList'].push(`${++errorsCount}. Sura is empty.`);
    }

    // Verse
    const verse = data[2] || null;
    if(!verse) {
      errors['errorList'].push(`${++errorsCount}. Verse is empty.`);
    }

    if(excelDate && sura && verse) {
      quranQuotesMap[excelDate] = { [`${sura}`]: `${verse}` };

      // Max Date
      if(!excelMaxDate
        || new Date(getDateString(excelDate)) > new Date(excelMaxDate)) {
        excelMaxDate = getDateString(excelDate);
      }

      // Min Date
      if(!excelMinDate
        || new Date(getDateString(excelDate)) < new Date(excelMinDate)) {
        excelMinDate = getDateString(excelDate);
      }
    }

    errors['errorsCount'] = errorsCount;
    if(errors['errorsCount'] > 0) {
      allErrors.push(errors);
    }
  }

  const { minDate, maxDate } = getRangeMinMaxDates({
    serverMaxDate, serverMinDate, excelMaxDate, excelMinDate,
  });
  const quranQuotesListFromMap = getQuranQuotesListFromMap(quranQuotesMap);
  const orderedQuranQuotesList = getOrderedQuranQuotesList(quranQuotesListFromMap);
  const missingDates = getMissingDates({ minDate, maxDate, orderedQuranQuotesList })

  if(!_.isEmpty(missingDates)) {
    allErrors.push({
      'Sheet error': 'Some dates are missing from the excel',
      'Missing dates': missingDates,
      'Server data max date': serverMaxDate,
      'Server data min date': serverMinDate,
      'Excel data max date': excelMaxDate,
      'Excel data min date': excelMinDate,
    })
  }

  return {
    items: getUpdatableQuranQuotes(orderedQuranQuotesList, moment(maxDate).format('D-M-YYYY')),
    allErrors,
  };
};

export {
  getQuranQuotesList,
  getUpdatedQuranQuotesList,
  getDeletedQuranQuotesList,
  getNextElementsAdded,
  isXDaysOrMore,
  getValidationStartDay,
  isDataUpdated,
  isDataValid,
  getUpdatableQuranQuotes,
  getItems,
}