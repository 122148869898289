import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  bigqueryGetUserLogs,
} from '../../services/api-v1-services/other-services';

const getUserLogs = () => {
  const [ userLogsData, setUserLogsData ] = useState({ });
  const [ fetchData, setFetchData ] = useState(null);
  const {
    requestData, onOperationComplete,
  } = fetchData || {};

  const fetchUserLogs = async () => {
    try {
      console.log(`Fetching user logs rows data...`);
      setUserLogsData({
        isFetching: true,
        isError: false,
      });

      const apiResp =  await bigqueryGetUserLogs(requestData);

      const responseData = apiResp.data;
      let errorMsg = null;
      if(responseData.success) {
        console.log(`Fetching user logs rows data Success!!`);
      } else {
        console.log(`Fetching user logs rows data Failed!!`);
        errorMsg = `Error occurred while fetching user logs rows data.`;
      }

      setUserLogsData({
        data: responseData.data,
        isFetching: false,
        isError: !responseData.success,
        errorMsg,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError: !responseData.success,
          errorMsg,
          data: responseData.data,
        });
      }

    } catch (err) {
      const errorMsg = (err.response && err.response.status === 422) ?
        `Can't process due to invalid data!` : `Error occurred while fetching user logs rows data.`;
      console.log(`${errorMsg} Error : ${err}`);
      setUserLogsData({
        data: null,
        isFetching: false,
        isError: true,
        errorMsg,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
          data: null,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(fetchData)) {
      fetchUserLogs();
    }
  }, [ fetchData ]);

  return [
    userLogsData,
    setFetchData,
  ];
};

export default getUserLogs;
