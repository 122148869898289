export const AWS_LANGUAGES = Object.freeze({
  English: "ENG",
  Indonesian: "IND",
  Malay: "MSA",
  French: "FRA",
  Arabic: "ARA",
  Bengali: "BEN",
  Bosnian: "BOS",
  Dutch: "DUT",
  Hindi: "HIN",
  Kurdish: "KUR",
  Persian: "FAS",
  Tagalog: "TGL",
  Thai: "THA",
  Turkish: "TUR",
  German: "GER",
  Kazakh: "KAZ",
});

export const LIVE_STATUS_ARRAY = ["LIVE", "UPCOMING", "ENDED", "PAUSED"];

export const convertToLabelValue = (obj) => {
  return Object.entries(obj).map(([label, value]) => ({ label, value }));
};
