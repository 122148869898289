import React,
{
  useEffect,
} from 'react';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  updateHijriHook,
  fetchHijriFileVersionsHook,
} from '../../hooks';
import GCSFileVersionViewer from '../../widgets/gcs-flie-version-viewer';
import {
  shouldShowGcsFileVersionLister,
} from './helper';
import {
  OperationStatus,
} from '../../widgets/common';

const ListSettingsFileVersions = () => {

  const [ hijriFileVersionsData, doFetch ] = fetchHijriFileVersionsHook();
  const isFetching = hijriFileVersionsData && hijriFileVersionsData.isFetching;
  const showGcsFileVersionLister =
      shouldShowGcsFileVersionLister(isFetching, hijriFileVersionsData)

  const onCompleteFetch = operationData => {
    if(!operationData.isError) {
      toast.success(`Fetching file versions Success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  useEffect(() => {
    doFetch({
      onOperationComplete: onCompleteFetch,
    });
  }, []);

  return (
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={2000}/>
          <Row>
            <Col md={12}>
              <h3 className='content_editor_title'>{'Hijri File Versions'}</h3>
            </Col>
          </Row>
        <br/>
        {
          isFetching &&
          <OperationStatus
              operationInProgressText={`Please wait! Fetching Hijri file versions...`}
          />
        }
        {
          showGcsFileVersionLister ?
              <Row>
                <Col md={12}>
                  <GCSFileVersionViewer
                      fileVersionsList={hijriFileVersionsData.data}
                      fileUpdateHook={updateHijriHook}
                  />
                </Col>
              </Row> : null
        }
      </BasicLayout>
  );
};

export default ListSettingsFileVersions;
