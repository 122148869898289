import React from 'react';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  MPUploadExcel,
} from '../../widgets/upload-excel';
import {
  ExcelUploadInfoPanel,
} from './common';
import {
  virtualToursBulkAddOrUpdateHook,
  updateSettingsFileTimestampsHook,
} from '../../hooks';
import {
  getItems,
} from './helper';
import {
  OperationStatus,
} from '../../widgets/common';

const UploadExcel = () => {
  const [ settingsFileUpdateData, doUpdate ] = updateSettingsFileTimestampsHook();
  const updatingTimeStamps = settingsFileUpdateData && settingsFileUpdateData.isUpdating;
  console.log('on settings File updating TimeStamps : ', updatingTimeStamps);

  const onTimestampUpdateComplete = operationData => {
    if(!operationData.isError) {
      toast.success(`Timestamps update success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onExcelUploadSuccess = () => {
    doUpdate({
      fileName: 'settings_api_base',
      updateConfig: {
        'updateTimestamps': true,
        'timestampLocations': [
          'all>timestamp',
          'all>configurable_apis>hajj_journey>timestamp',
        ],
      },
      onOperationComplete: onTimestampUpdateComplete,
    });
  };

  return (
    <BasicLayout pagePermission="moderate_precalc">
      <ToastContainer autoClose={2000}/>
      <Row>
        <Col md={12}>
          <h3 className='content_editor_title'>{'Upload Excel'}</h3>
        </Col>
      </Row>
      {
        !updatingTimeStamps ?
          <MPUploadExcel
            InfoPanel={ExcelUploadInfoPanel}
            fileUpdateHook={virtualToursBulkAddOrUpdateHook}
            fileUpdateSuccessMessage={'Virtual Tours Updated Successfully!!'}
            getItems={getItems}
            onUploadSuccess={onExcelUploadSuccess}
          /> : null
      }
      {
        updatingTimeStamps ?
          <OperationStatus
            operationInProgressText={`Updating timestamps.`}
          /> : null
      }
    </BasicLayout>
  );
};

export default UploadExcel;
