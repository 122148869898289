import _ from 'lodash';
import {
  actionTypes,
} from './actionTypes';
import {
  isValidHijriYear,
  getDefaultYearDates,
  getHijriDateFromMoment,
} from './helper';

const SightingInitialState = {
  initialHijri: null,
  updatedHijri: null,
  newYear: '',
  yearAddError: null,
  dataKey: null,
  updated: false,
};

const sightingReducer = (state, { type , payload }) => {
  const {
    dataKey,
    initialHijri,
    updatedHijri,
  } = state;

  switch (type) {
    case actionTypes.SIGHTING_UPDATE_PROP_VAL:
      const {
        prop,
        value,
      } = payload;

      return {
        ...state,
        [prop]: value,
      };

    case actionTypes.SIGHTING_ADD_NEW_YEAR:
      const validYear = payload ? isValidHijriYear(payload) : true;
      if(!validYear) {
        return {
          ...state,
          yearAddError: 'Invalid year value.'
        }
      }

      const existingData = updatedHijri['sightings'][dataKey][payload];
      if(existingData) {
        return {
          ...state,
          yearAddError: 'Year data already existing.'
        }
      }

      const newYearAddedHijri = {
        ...updatedHijri,
      }
      newYearAddedHijri['sightings'][dataKey][payload] = { default: false };

      return {
        ...state,
        yearAddError: '',
        updatedHijri: newYearAddedHijri,
        updated: true,
      };

    case actionTypes.SIGHTING_UPDATE_DATA:
      return {
        ...state,
        updatedHijri: payload,
        initialHijri: _.cloneDeep(payload),
        updated: false,
      };

    case actionTypes.SIGHTING_ON_RESET_YEAR:
    {
      const {
        year,
      }  = payload;
      const nextHijriUpdate = _.cloneDeep(updatedHijri);
      const initialYearData = initialHijri['sightings'][dataKey][year];
      if(initialYearData) {
        nextHijriUpdate['sightings'][dataKey][year] = initialYearData;
      } else {
        nextHijriUpdate['sightings'][dataKey][year] = { default: false };
      }

      return {
        ...state,
        updatedHijri: nextHijriUpdate,
      }
    }

    case actionTypes.SIGHTING_ON_DELETE_YEAR:
    {
      const {
        year,
      }  = payload;
      const nextHijriUpdate = _.cloneDeep(updatedHijri);
      delete nextHijriUpdate['sightings'][dataKey][year];

      return {
        ...state,
        updatedHijri: nextHijriUpdate,
        updated: true,
      }
    }

    case actionTypes.SIGHTING_ON_TOGGLE_COUNTRY:
    {
      const {
        year,
        value,
        countryCode,
      }  = payload;
      const nextHijriUpdate = _.cloneDeep(updatedHijri);
      nextHijriUpdate['sightings'][dataKey][year][countryCode] = value;

      return {
        ...state,
        updatedHijri: nextHijriUpdate,
        updated: true,
      }
    }

    case actionTypes.SIGHTING_ON_ADD_COUNTRY:
    {
      const {
        year,
        countryCodes=[],
      }  = payload;
      const nextHijriUpdate = _.cloneDeep(updatedHijri);
      countryCodes.forEach(countryCode => {
        nextHijriUpdate['sightings'][dataKey][year][countryCode] = false;
      });

      return {
        ...state,
        updatedHijri: nextHijriUpdate,
        updated: true,
      }
    }

    case actionTypes.SIGHTING_ON_DELETE_COUNTRY:
    {
      const {
        year,
        countryCode,
      }  = payload;
      const nextHijriUpdate = _.cloneDeep(updatedHijri);
      delete nextHijriUpdate['sightings'][dataKey][year][countryCode];

      return {
        ...state,
        updatedHijri: nextHijriUpdate,
        updated: true,
      }
    }

    case actionTypes.SIGHTING_RESET_STATE:
      return {
        ...SightingInitialState,
        updatedHijri: initialHijri,
        initialHijri: _.cloneDeep(initialHijri),
        updated: false,
        dataKey: payload,
      };

    default:
      return state;
  }
};


const HijriYearsInitialState = {
  initialHijri: null,
  updatedHijri: null,
  newCountries: [],
  newCountriesList: null,
  newCountriesAddError: null,
  refMap: {},
  updated: false,
};

const hijriYearsReducer = (state, { type , payload }) => {
  const {
    refMap,
    initialHijri,
    updatedHijri,
    newCountries,
  } = state;

  switch (type) {
    case actionTypes.HYU_UPDATE_PROP_VAL:
      const {
        prop,
        value,
      } = payload;

      if(prop === 'newCountries') {
        let errorMessage = 'Countries already existing : ';
        const existingCountries = [];
        value.forEach( ({ value }) => {
          const countryCode = value.toLowerCase();
          if(updatedHijri[countryCode]) {
            existingCountries.push(countryCode);
          }
        });

        if(!_.isEmpty(existingCountries)) {
          errorMessage += existingCountries.join(', ');
          return {
            ...state,
            [prop]: value,
            newCountriesAddError: errorMessage,
          }
        } else {
          return {
            ...state,
            [prop]: value,
            newCountriesAddError: null,
          };
        }
      }

      return {
        ...state,
        [prop]: value,
      };

    case actionTypes.HYU_UPDATE_DATA:
      return {
        ...state,
        updatedHijri: payload,
        initialHijri: _.cloneDeep(payload),
        updated: false,
      };

    case actionTypes.HYU_ADD_NEW_COUNTRIES:
      const nextHijriUpdate = _.cloneDeep(updatedHijri);
      newCountries.forEach( ({ value }) => {
        const countryCode = value.toLowerCase();
        nextHijriUpdate[countryCode] = {};
      });

      return {
        ...state,
        updatedHijri: nextHijriUpdate,
        newCountriesList: newCountries,
        newCountries: [],
        updated: true,
      };

    case actionTypes.HYU_ON_DELETE_COUNTRY:
    {
      const {
        countryCode,
      }  = payload;
      const nextHijriUpdate = _.cloneDeep(updatedHijri);
      delete nextHijriUpdate[countryCode];

      return {
        ...state,
        updatedHijri: nextHijriUpdate,
        updated: true,
      }
    }

    case actionTypes.HYU_ON_RESET_COUNTRY:
    {
      const {
        countryCode,
      }  = payload;
      const nextHijriUpdate = _.cloneDeep(updatedHijri);
      const initialYearData = initialHijri[countryCode];
      if(initialYearData) {
        nextHijriUpdate[countryCode] = initialYearData;
      } else {
        nextHijriUpdate[countryCode] = {};
      }

      return {
        ...state,
        updatedHijri: nextHijriUpdate,
      }
    }

    case actionTypes.HYU_ON_ADD_YEAR:
    {
      const {
        year,
        countryCode,
      }  = payload;
      const nextHijriUpdate = _.cloneDeep(updatedHijri);
      nextHijriUpdate[countryCode][year] = getDefaultYearDates();

      return {
        ...state,
        updatedHijri: nextHijriUpdate,
        updated: true,
      }
    }

    case actionTypes.HYU_ON_DELETE_YEAR:
    {
      const {
        year,
        countryCode,
      }  = payload;
      const nextHijriUpdate = _.cloneDeep(updatedHijri);
      delete nextHijriUpdate[countryCode][year];

      return {
        ...state,
        updatedHijri: nextHijriUpdate,
        updated: true,
      }
    }

    case actionTypes.HYU_ON_RESET_YEAR:
    {
      const {
        year,
        countryCode,
      }  = payload;
      console.log(countryCode)
      console.log(year)
      const nextHijriUpdate = _.cloneDeep(updatedHijri);
      const initialYearData = initialHijri[countryCode] ? initialHijri[countryCode][year] : null;
      if(initialYearData) {
        nextHijriUpdate[countryCode][year] = initialYearData;
      } else {
        nextHijriUpdate[countryCode][year] = getDefaultYearDates();
      }

      return {
        ...state,
        updatedHijri: nextHijriUpdate,
      }
    }

    case actionTypes.HYU_ON_UPDATE_DATE:
    {
      const {
        year,
        date,
        index,
        countryCode,
      }  = payload;
      const nextHijriUpdate = _.cloneDeep(updatedHijri);
      nextHijriUpdate[countryCode][year][index] = getHijriDateFromMoment(date);

      return {
        ...state,
        updatedHijri: nextHijriUpdate,
        updated: true,
      }
    }

    case actionTypes.HYU_ON_UPDATE_REF_DATA:
    {
      const {
        key,
        ref,
      }  = payload;
      refMap[key] = ref;

      return {
        ...state,
        refMap: refMap,
      }
    }

    case actionTypes.HYU_RESET_STATE:
      return {
        ...HijriYearsInitialState,
        updatedHijri: initialHijri,
        initialHijri: _.cloneDeep(initialHijri),
        updated: false,
      };

    default:
      return state;
  }
};

export {
  sightingReducer,
  hijriYearsReducer,
  SightingInitialState,
  HijriYearsInitialState,
};
