import _ from 'lodash';
import React,
{
  Fragment,
} from 'react';
import {
  SettingsFileUpdateTypes,
  SettingsFilesTSLocations,
  SettingsFileOperationTypes,
  SettingsTSFileOperationTypes
} from './Constants';
import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import {
  MPInput,
  MPSelect,
  MpMultiCheckBox,
  MPSingleKeyJsonInput,
} from '../../widgets/common';
import Collapse,
{
  Panel,
} from 'rc-collapse';
import './style.css';

const getCheckBoxes = (selectedSettingsFile) => {
  const tsLocations = SettingsFilesTSLocations[selectedSettingsFile];
  return _.map(tsLocations, tsLocation => {
    return {
      id: tsLocation,
      name: tsLocation,
    }
  })
};

const arrowPath = 'M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88' +
    '.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.' +
    '6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-0.7 5.' +
    '2-2L869 536.2c14.7-12.8 14.7-35.6 0-48.4z';

const OperationTypeSelect = ({
                               dispatch,
                               actionType,
                               operationLabel,
                               actionPropName,
                               operationTypes,
                               selectedOperationType,
                         }) => {
  return (
      <Row>
        <Col md={6}>
          <div className='form-group'>
            <label style={{'marginRight': '5px'}}>{operationLabel}</label>
            <MPSelect
                items={operationTypes}
                selectedItem={selectedOperationType}
                onChangeSelect={(value) => dispatch({
                  type: actionType,
                  payload: {
                    prop: actionPropName,
                    value,
                  },
                })
                }
                defaultValue={''}
            />
          </div>
        </Col>
      </Row>
  );
}

const RenderOperationTypeInputs = ({
                                     dispatch,
                                     actionType,
                                     inputLabel,
                                     selectLabel,
                                     operationTypes,
                                     selectedOperation,
                                     operationPropName,
                                     selectedOperationType,
                                   }) => {
  if (SettingsFileOperationTypes[selectedOperationType] === SettingsFileOperationTypes.manual) {
    return (
        <Row>
          <Col md={12}>
            <div className='form-group'>
              <label style={{ 'marginRight': '5px' }}>{`${inputLabel} : `}</label>
              <MPInput
                  value={selectedOperation}
                  onChangeValue={(value) => dispatch({
                    type: actionType,
                    payload: {
                      prop: operationPropName,
                      value,
                    },
                  })
                  }
                  placeHolder={inputLabel}
              />
            </div>
          </Col>
        </Row>
    );
  } else if (SettingsFileOperationTypes[selectedOperationType] === SettingsFileOperationTypes['drop-down']) {
    return (
        <Row>
          <Col md={6}>
            <div className='form-group'>
              <label style={{ 'marginRight': '5px' }}>{`${selectLabel} : `}</label>
              <MPSelect
                  items={operationTypes}
                  selectedItem={selectedOperation}
                  onChangeSelect={(value) => dispatch({
                    type: actionType,
                    payload: {
                      prop: operationPropName,
                      value,
                    },
                  })
                  }
                  defaultValue={''}
              />
            </div>
          </Col>
        </Row>
    );
  } else {
    return null
  }
};

const expandIcon = ({
                      isActive,
                    }) => {
  return (
      <i style={{ marginRight: '.5rem' }}>
        <svg
            viewBox="0 0 1024 1024"
            width="1em"
            height="1em"
            fill="currentColor"
            style={{
              verticalAlign: '-.125em',
              transition: 'transform .2s',
              transform: `rotate(${isActive ? 90 : 0}deg)`,
            }}
        >
          <path d={arrowPath} p-id="5827"></path>
        </svg>
      </i>
  );
}

const RenderTSOperationTypeInputs = ({
                                       selectedOperationType,
                                       selectedOperation,
                                       dispatch,
                                       actionType,
                                       operationPropName,
                                       inputLabel,
                                       checkBoxPropName,
                                       selectedFile,
                                       validLocations,
                                       invalidLocationMsg,
                                     }) => {
  if (SettingsTSFileOperationTypes[selectedOperationType] === SettingsTSFileOperationTypes.manual) {
    return (
        <Fragment>
          <Row>
            <Col md={12}>
              <div className='form-group'>
                <label style={{ 'marginRight': '5px' }}>{`${inputLabel} : `}</label>
                <MPInput
                    value={selectedOperation}
                    onChangeValue={(value) => dispatch({
                      type: actionType,
                      payload: {
                        prop: operationPropName,
                        value,
                      },
                    })
                    }
                    placeHolder={inputLabel}
                />
                {
                  selectedOperation && !validLocations ?
                     <p className={'settings-ts-location-manual-warning'}>
                       {invalidLocationMsg}
                     </p> : null
                }
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Collapse
                  accordion={true}
                  className={'settings-ts-note-collapse'}
                  expandIcon={expandIcon}
              >
                <Panel
                    header="How to specify manual location"
                    headerClass="settings-ts-manual-location-note-header"
                    extra={<span>Click to see/hide Details</span>}
                >
                  <ol>
                    <li>
                      <strong><u>Top level location:</u></strong> &nbsp; if it is a top level
                      location you can simply specify the key. <strong>Ex: timestamp</strong>
                    </li>
                    <li>
                      <strong><u>Inner location:</u></strong> &nbsp; if it is a inner location you
                      can specify the keys with <strong>">"</strong> symbol.
                      <strong>Ex: all>timestamp</strong>
                    </li>
                    <li>
                      <strong><u>Multiple locations:</u></strong> &nbsp; For multiple locations you
                      can follow above syntax for each key location and each location should be
                      separated with a comma <strong>","</strong> symbol.
                      <strong>Ex: all>timestamp,ios>timestamp,wp>timestamp,timestamp</strong>
                    </li>
                  </ol>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </Fragment>
    );
  } else if (SettingsTSFileOperationTypes[selectedOperationType] === SettingsTSFileOperationTypes['check-box']) {
    const checkBoxes = getCheckBoxes(selectedFile);
    let checkBoxContent = null;
    if(_.isEmpty(checkBoxes)) {
      checkBoxContent = (
          <p className={'settings-check-box-option-warning'}>
            {`For file ${selectedFile}, check box options are not available. Try Manual option`}
          </p>
      );
    } else {
      checkBoxContent = (
          <MpMultiCheckBox
              checkBoxes={checkBoxes}
              onUpdateValues={(updatedValues) => dispatch({
                type: actionType,
                payload: {
                  prop: checkBoxPropName,
                  value: updatedValues,
                },
              })}
          />
      )
    }
    return (
        <Fragment>
          {
            selectedFile ?
                <Row>
                  <Col md={6}>
                    <div className='form-group'>
                      {checkBoxContent}
                    </div>
                  </Col>
                </Row> : null
          }
        </Fragment>
    );
  } else {
    return null
  }
};

const FetchButton = ({
                       dispatch,
                       onSubmit,
                       actionType,
                       isFetching,
                       selectedFile,
                       selectedFetchType,
                       disableFetchButton,
                     }) => {
  if (!selectedFetchType) {
    return null;
  }

  return (
      <Row>
        <Col md={6}>
          <Button
              type="submit"
              onClick={() => {
                dispatch({ type: actionType });
                onSubmit(selectedFile);
              }}
              disabled={disableFetchButton}
              className="btn-primary api-test-submit-button">
            { isFetching ? 'Fetching..' : 'Fetch' }
          </Button>
        </Col>
      </Row>
  )
}

const UpdateButton = ({
                        onSubmit,
                        dispatch,
                        actionType,
                        isUpdating,
                        selectedFile,
                        selectedFetchType,
                        disableUpdateButton,
                     }) => {
  if (!selectedFetchType) {
    return null;
  }

  return (
      <Row>
        <Col md={6}>
          <Button
              onClick={() => {
                dispatch({ type: actionType });
                onSubmit(selectedFile);
              }}
              disabled={disableUpdateButton}
              className="btn-primary api-test-submit-button">
            { isUpdating ? 'Updating..' : 'Update' }
          </Button>
        </Col>
      </Row>
  )
}

const FetchForm = ({
                     dispatch,
                     onSubmit,
                     isFetching,
                     fetchTypes,
                     settingFiles,
                     selectedFile,
                     fetchActionType,
                     selectedFetchType,
                     disableFetchButton,
                     propUpdateActionType,
                   }) => {

  return (
      <form className={'form-inline'} onSubmit={(e) => e.preventDefault()}>
        <OperationTypeSelect
            operationTypes={fetchTypes}
            selectedOperationType={selectedFetchType}
            dispatch={dispatch}
            actionType={propUpdateActionType}
            operationLabel={'Fetch Type'}
            actionPropName={'selectedFetchType'}
        />
        <br/>
        <RenderOperationTypeInputs
            selectedOperationType={selectedFetchType}
            selectedOperation={selectedFile}
            operationTypes={settingFiles}
            dispatch={dispatch}
            actionType={propUpdateActionType}
            operationPropName={'selectedFile'}
            inputLabel={'File Name'}
            selectLabel={'File'}
        />
        <br/>
        <FetchButton
            dispatch={dispatch}
            selectedFile={selectedFile}
            selectedFetchType={selectedFetchType}
            actionType={fetchActionType}
            disableFetchButton={disableFetchButton}
            isFetching={isFetching}
            onSubmit={onSubmit}
        />
      </form>
  );
};

const TimestampUpdateForm = ({
                               onSubmit,
                               dispatch,
                               isUpdating,
                               selectedFile,
                               validLocations,
                               operationTypes,
                               fetchActionType,
                               tsOperationTypes,
                               selectedFetchType,
                               settingFilesWithTs,
                               disableUpdateButton,
                               propUpdateActionType,
                               timestampLocationManual,
                               selectedTimestampLocationType,
                             }) => {

  return (
      <form className={'form-inline'} onSubmit={(e) => e.preventDefault()}>
        <OperationTypeSelect
            operationTypes={operationTypes}
            selectedOperationType={selectedFetchType}
            dispatch={dispatch}
            actionType={propUpdateActionType}
            operationLabel={'File Select Type'}
            actionPropName={'selectedFetchType'}
        />
        <br/>
        <RenderOperationTypeInputs
            selectedOperationType={selectedFetchType}
            selectedOperation={selectedFile}
            operationTypes={settingFilesWithTs}
            dispatch={dispatch}
            actionType={propUpdateActionType}
            operationPropName={'selectedFile'}
            inputLabel={'File Name'}
            selectLabel={'File'}
        />
        <br/>
        {
          selectedFetchType && selectedFile ?
              <Fragment>
                <OperationTypeSelect
                    operationTypes={tsOperationTypes}
                    selectedOperationType={selectedTimestampLocationType}
                    dispatch={dispatch}
                    actionType={propUpdateActionType}
                    operationLabel={'Timestamp Location Type'}
                    actionPropName={'selectedTimestampLocationType'}
                />
                <br/>
                <RenderTSOperationTypeInputs
                    selectedOperationType={selectedTimestampLocationType}
                    selectedOperation={timestampLocationManual}
                    selectedFile={selectedFile}
                    operationTypes={settingFilesWithTs}
                    dispatch={dispatch}
                    actionType={propUpdateActionType}
                    validLocations={validLocations}
                    operationPropName={'timestampLocationManual'}
                    checkBoxPropName={'timestampLocationSelect'}
                    inputLabel={'Timestamp Location'}
                    selectLabel={'Timestamp'}
                    invalidLocationMsg={'Invalid timestamp location. Please fix!'}
                />
                <br/>
                <UpdateButton
                    dispatch={dispatch}
                    selectedFile={selectedFile}
                    selectedFetchType={selectedFetchType}
                    actionType={fetchActionType}
                    disableUpdateButton={disableUpdateButton}
                    isUpdating={isUpdating}
                    onSubmit={onSubmit}
                />
              </Fragment> : null
        }
      </form>
  );
};

const KeyValueUpdateForm = ({
                              dispatch,
                              onSubmit,
                              isUpdating,
                              updateTypes,
                              selectedFile,
                              keyLocations,
                              validLocations,
                              operationTypes,
                              fetchActionType,
                              selectedFetchType,
                              settingFilesWithTs,
                              selectedUpdateType,
                              disableUpdateButton,
                              propUpdateActionType,
                             }) => {

  return (
      <form className={'form-inline'} onSubmit={(e) => e.preventDefault()}>
        <OperationTypeSelect
            operationTypes={operationTypes}
            selectedOperationType={selectedFetchType}
            dispatch={dispatch}
            actionType={propUpdateActionType}
            operationLabel={'File Select Type'}
            actionPropName={'selectedFetchType'}
        />
        <br/>
        <RenderOperationTypeInputs
            selectedOperationType={selectedFetchType}
            selectedOperation={selectedFile}
            operationTypes={settingFilesWithTs}
            dispatch={dispatch}
            actionType={propUpdateActionType}
            operationPropName={'selectedFile'}
            inputLabel={'File Name'}
            selectLabel={'File'}
        />
        <br/>
        {
          selectedFetchType && selectedFile ?
              <Fragment>
                <RenderTSOperationTypeInputs
                    selectedOperationType={'manual'}
                    selectedOperation={keyLocations}
                    selectedFile={selectedFile}
                    operationTypes={settingFilesWithTs}
                    dispatch={dispatch}
                    actionType={propUpdateActionType}
                    validLocations={validLocations}
                    operationPropName={'keyLocations'}
                    inputLabel={'Key Locations'}
                    invalidLocationMsg={'Invalid location. Please fix!'}
                />
                <br/>
                {
                  keyLocations && validLocations ?
                      <OperationTypeSelect
                          operationTypes={updateTypes}
                          selectedOperationType={selectedUpdateType}
                          dispatch={dispatch}
                          actionType={propUpdateActionType}
                          operationLabel={'Operation Type'}
                          actionPropName={'selectedUpdateType'}
                      /> : null
                }
                <br/>
                {
                  selectedUpdateType
                  && (selectedUpdateType !== SettingsFileUpdateTypes.delete.toLowerCase()) ?
                      <MPSingleKeyJsonInput
                          onUpdateValue={(value) => dispatch({
                            type: propUpdateActionType,
                            payload: {
                              prop: 'jsonValue',
                              value,
                            },
                          })
                          }
                          height={'200px'}
                      /> : null
                }
                <br/>
                <UpdateButton
                    dispatch={dispatch}
                    selectedFile={selectedFile}
                    selectedFetchType={selectedFetchType}
                    actionType={fetchActionType}
                    disableUpdateButton={disableUpdateButton}
                    isUpdating={isUpdating}
                    onSubmit={onSubmit}
                />
              </Fragment> : null
        }
      </form>
  );
};

export {
  FetchForm,
  KeyValueUpdateForm,
  TimestampUpdateForm,
};
