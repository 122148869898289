import React,
{
  Component,
} from 'react';
import BasicLayout from '../../layouts/basiclayout';
import firebase from 'firebase/app';
import 'firebase/firestore'
import {
  Row,
  Col,
  Table,
} from 'react-bootstrap';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  createLog,
} from '../../services/logs';
import {
  Icon,
} from 'react-fa';
import PermissionCol from './permissioncol';
import './style.css';
import {
  RoleManager,
} from '../../services/roleManager';
import {
  getModerators,
  updateModerator,
  deleteModerator,
} from '../../services/admin-db-service';
import {
  getUser,
} from '../../services/mp-cf-api-service';

let roleManager;

class Moderators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mods: [],
      alertEmails: [],
      fetchingModerators: false,
      fetchingAlertEmails: false,
      muid: null,
      alertEmail: null,
      usersPermission: null,
      moderatorsObj: null,
      lastUpdated: null
    };
    roleManager = new RoleManager(firebase);
  }

  componentWillMount() {
    this.fetchModerators();
    // this.fetchAlertEmails();
    roleManager.getCurrentUserPermission().then((usersPermission) => {
      this.setState({
        usersPermission
      });
    });
  }

  fetchModerators() {
    this.setState({
      fetchingModerators: true
    });
    getModerators().then((moderatorsObj) => {
      const moderatorsUids = Object.keys(moderatorsObj);
      let mods = [];
      let promises = moderatorsUids.map((uid) => {
        return getUser(uid)
        .then((res) => {
          const profile = res.data;
          const role = moderatorsObj[uid];
          const { displayName, email } = profile;
          const isGoogleProvider = this.checkIfProviderGoogle(email, profile.providerData);
          mods.push({ uid, displayName, email, isGoogleProvider, role });
          return Promise.resolve();
        })
        .catch((err) => {
          console.log(err.message);
          return Promise.resolve();
        });
      });
      return Promise.all(promises)
        .then(() => {
          this.setState({
            mods,
            fetchingModerators: false,
            moderatorsObj
          });
        });
    });
  }

  checkIfProviderGoogle(email, provider) {
    if(!provider) {
      return false;
    }

    for(let i=0; i<provider.length; i++) {
      if(email === provider[i].email && provider[i].providerId === "google.com") {
        return true;
      }
    }
    return false;
  }

  compareDisplayName(a, b) {
    const dnA =  a.displayName ? a.displayName.toUpperCase() : "";
    const dnB =  b.displayName ? b.displayName.toUpperCase() : "";
    let comparison = 0;
    if (dnA > dnB) {
      comparison = 1;
    } else if (dnA < dnB) {
      comparison = -1;
    }
    return comparison;
  }

  compareAddress(a, b) {
    const dnA =  a.address ? a.address.toUpperCase() : "";
    const dnB =  b.address ? b.address.toUpperCase() : "";
    let comparison = 0;
    if (dnA > dnB) {
      comparison = 1;
    } else if (dnA < dnB) {
      comparison = -1;
    }
    return comparison;
  }

  checkModEmailValidity(email) {
    if(!email) {
      return false;
    }
    const domain = email.split('@')[1];
    if(domain === 'bitsmedia.com') {
      return true;
    } else {
      return false;
    }
  }

  handleModDelete(muid, email) {
    const confirmation = window.confirm('Do you really want to revoke access to this user?');
    if(confirmation) {
      createLog('revoke_admin', {
        user_id: muid,
        email_address: email
      });
      // revoke_admin
      return deleteModerator(muid).then(() => {
          toast.success(`${muid} removed as moderator`, {
            position: toast.POSITION.BOTTOM_LEFT
          });
        });
    }
  }

  checkRevokeEligibility(usersPermission, mod, currentUID) {
    const isSuperAdmin = (usersPermission === 127 || usersPermission === 255);
    const isYourself = (currentUID === mod.uid);
    if(isYourself) {
      return false;
    } else {
      if(isSuperAdmin) {
        if(parseInt(mod.role, 10) !== 127 && parseInt(mod.role, 10) !== 255) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }

  renderModeratorTable(fetchingModerators, moderators) {
    const { usersPermission, lastUpdated } = this.state;
    const currentUID = localStorage.getItem("uid");
    moderators.sort(this.compareDisplayName);
    let rows = (moderators.length === 0) ? <tr><td colSpan="12">{(fetchingModerators === true) ? 'Fetching moderators...' : 'No moderators found!'}</td></tr> : moderators.map((mod, i) => {
      let revokeLink = <span><Icon name="ban"/></span>
      if(this.checkRevokeEligibility(usersPermission, mod, currentUID)) {
        revokeLink = <span className="revoke__btn" onClick={this.handleModDelete.bind(this, mod.uid, mod.email)}>Revoke</span>;
      }
      return (
        <tr className={`${(this.checkModEmailValidity(mod.email) && mod.isGoogleProvider) ? "" : "invalid__mod"}`} key={i}>
          <td>{i+1}</td>
          <td className="name_col small"><a href={`/search-user/${mod.uid}`}>{mod.displayName || mod.uid}</a></td>
          <td className="name_col small">{mod.email ? mod.email : '-'}</td>
          <PermissionCol
            currentUID={currentUID}
            usersPermission={usersPermission}
            toast={toast}
            firebase={firebase}
            permission={mod.role}
            muid={mod.uid}
            updatePermissionObj={this.updatePermissionObj.bind(this)}
            lastUpdated={lastUpdated}
          />
          <td className="text-center">{revokeLink}</td>
        </tr>
      );
    });
    return (
      <div className="moderators__table">
        <p className="title">Existing Moderators</p>
        <Table bordered condensed hover>
          <thead>
            <tr className="table__header">
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Moderate prayer request</th>
              <th>Read promo codes</th>
              <th>Write promo codes</th>
              <th>Read users</th>
              <th>Write users</th>
              <th>Moderate Precalc</th>
              <th>Change moderators permissions</th>
              <th>Aideen</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </Table>
        <div className="permission__btns">
          <button onClick={this.applyPermissionChanges.bind(this)} className="btn btn-primary apply__permission_btn">Apply changes</button>
          {' '}
          <button onClick={this.reloadPage.bind(this)} className="btn btn-default">Clear</button>
        </div>
      </div>
    );
  }

  reloadPage() {
    window.location = "/moderators";
  }

  applyPermissionChanges() {
    const {moderatorsObj} = this.state;
    updateModerator(moderatorsObj).then(() => {
      toast.success("Permissions updated", {
        position: toast.POSITION.BOTTOM_LEFT
      });
      this.setState({
        lastUpdated: Date.now()
      });
    });
  }

  handleAddAlertEmailSubmit(email) {
    // e.preventDefault();
    // const { alertEmail } = this.state;
    const confirmation = window.confirm(`Do you really want to add ${email} as alert recipient?`);
    if(confirmation) {
      createLog('add_tech_warning_email_recipient', {
        email_address: email
      });
      const db = firebase.firestore();
      db.collection("AlertEmails").add({
          address: email
      })
      .then((docRef) => {
        toast.success(`${email} added as recipient`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
      })
      .catch((error) => {
        toast.success(`Error adding document: , ${error}`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
      });
    }
  }

  handleAddModeratorSubmit(e) {
    e.preventDefault();
    toast.warn("This feature is disabled for now", {
      position: toast.POSITION.BOTTOM_LEFT
    });
  }

  handleInputChange(e) {
    this.setState({
      muid: e.target.value
    });
  }

  handleAlertEmailInputChange(e) {
    this.setState({
      alertEmail: e.target.value
    });
  }

  updatePermissionObj(uid, permission) {
    const {moderatorsObj} = this.state;
    moderatorsObj[uid] = permission;
    this.setState({
      moderatorsObj
    });
  }

  render() {
    const { fetchingModerators, mods } = this.state;
    return (
      <BasicLayout pagePermission="readonly">
        <ToastContainer autoClose={2000} />
        <Row>
          <Col md={10}>
            {this.renderModeratorTable(fetchingModerators, mods)}
          </Col>
        </Row>
      </BasicLayout>
    );
  }
}

export default Moderators;
