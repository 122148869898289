import React,
{
  Fragment,
  useReducer,
} from 'react';
import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import {
  actionTypes,
} from './actionTypes';
import ReactJson from 'react-json-view';
import {
  fileUpdateReducer,
} from './Reducer';
import {
  confirmAlert,
} from "react-confirm-alert";
import AppModal from '../modal';
import './style.css';
import JSONDiffView from '../json-diff-view';

const getUpdatedFileDiff = (updatedData, initialData) => {

  return (
      <JSONDiffView
          leftSideData={initialData}
          rightSideData={updatedData}
          messageIfBothSideIdentical={'No change found!'}
      />
  );
}

const InitialState = {
  updatedData: null,
};

const JSONFileUpdater = ({
                           jsonData,
                           fileName,
                           resetButtonText='Reset',
                           updateButtonText='Update',
                           jsonViewTitle='',
                           displayDataTypes=true,
                           displayObjectSize=true,
                           themeName='monokai',
                           onSubmitUpdatedFile=(file)=> console.log(file),
                         }) => {
  const [ state, dispatch ] = useReducer(fileUpdateReducer, {
    ...InitialState,
    initialJSON: jsonData,
    mutableJSON: jsonData,
  });
  const {
    updatedData,
    mutableJSON,
    initialJSON
  } = state;

  const disableResetBtn = false;
  const disableUpdateBtn = !updatedData;

  const onClickReset = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <AppModal
                show={true}
                showBody={true}
                headingText={`Are you sure want to reset the changes you made ?`}
                modalBody={getUpdatedFileDiff(updatedData, initialJSON)}
                modalBodyClassName={'precalcs-diff-merge-view'}
                closeButtonText={'No'}
                okButtonText={'Yes, Reset'}
                handleClose={onClose}
                handleOk={() => {
                  dispatch({
                    type: actionTypes.JFU_RESET_FILE_UPDATE,
                    payload: fileName,
                  });
                  onClose();
                }}
            />
        );
      }
    });
  };

  const onClickUpdate = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <AppModal
                show={true}
                showBody={true}
                headingText={`Are you sure want to update file with the changes you made ?`}
                modalBody={getUpdatedFileDiff(updatedData, initialJSON)}
                modalBodyClassName={'precalcs-diff-merge-view'}
                closeButtonText={'No'}
                okButtonText={'Yes, Update'}
                handleClose={onClose}
                handleOk={() => {
                  onSubmitUpdatedFile(updatedData)
                  onClose();
                }}
            />
        );
      }
    });
  };

  return(
      <div className={'update-row-file-main'}>
        <Row>
          <Col md={12}>
            <p className={'update-differ-paste-header'}>
              {`JSON Preview for ${fileName}:`}
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className={'precalc-update-raw-tool-bar'}>
            <div className={'precalc-update-raw-tool-bar'}>
              <div className={'precalc-update-raw-reset-buttons'}>
                {
                  updatedData ?
                    <Fragment>
                      <Button
                          onClick={() => onClickReset()}
                          disabled={disableResetBtn}
                          className="btn-primary precalc-update-raw-reset-btn"
                      >
                        {resetButtonText}
                      </Button>
                      <Button
                          onClick={() => onClickUpdate()}
                          disabled={disableUpdateBtn}
                          className="btn-primary precalc-update-raw-reset-btn"
                      >
                        {updateButtonText}
                      </Button>
                    </Fragment>: null
                }
              </div>
            </div>
          </Col>
        </Row>
        <Row className={'json-file-updater-main'}>
          <Col md={12}
               className={'json-file-updater-json-view json-file-updater-diff-view'}
          >
            <ReactJson
                src={mutableJSON}
                theme={themeName}
                name={jsonViewTitle}
                displayDataTypes={displayDataTypes}
                displayObjectSize={displayObjectSize}
                collapsed={!fileName}
                onEdit={(updatedValues) => dispatch({
                  type: actionTypes.JFU_UPDATE_JSON_VALUE,
                  payload: updatedValues,
                })
                }
                onAdd={(updatedValues) => dispatch({
                  type: actionTypes.JFU_UPDATE_JSON_VALUE,
                  payload: updatedValues,
                })
                }
                onDelete={(updatedValues) => dispatch({
                  type: actionTypes.JFU_UPDATE_JSON_VALUE,
                  payload: updatedValues,
                })
                }
            />
          </Col>
        </Row>
      </div>
  );
}

export default JSONFileUpdater;
