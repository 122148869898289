import _ from 'lodash';
import React,
{
  Fragment,
  Component,
} from 'react';
import moment from 'moment-timezone';
import ToggleButton from 'react-toggle-button';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  SubmitButton,
  PrefillStatus,
} from './common';
import Select from 'react-select';
import {
  countries as CountryList,
} from '../../services/utils';
import {
  getMetaInfo,
  getSupportedLanguages,
} from './helper';
import {
  HAJJ_AND_UMRAH_TYPE,
  IMAGES_DIRECTORY_PREFIX,
} from './Constants';
import {
  AUDIENCE_TYPE,
} from '../../Constants';
import FileUploader from '../../widgets/fileUploader';
import {
  hajjAndUmrahAddOrUpdate,
  uploadImagesToGCSBucket,
} from '../../services/mp-service-v1-api-service';
import {
  getContentMetaData,
} from '../../services/mp-cf-api-service';
import {
  getDefaultGCSConfigs,
} from '../../utils';
import {
  CopyToClipboard,
} from 'react-copy-to-clipboard';

const uploadNewThumbnail  = async (
  file,
  language,
  optimizeImages,
  ) => {
  const encodedFiles = [];

  const encodedImage = await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => resolve(e.target.result);
    fileReader.readAsDataURL(file);
  });

  const base64EncodedImage = encodedImage.replace('data:image/jpeg;base64,', '')
                                        .replace('data:image/png;base64,', '');

  const metadata = {
    contentType: file.type,
    cacheControl: 'public, max-age=31536000',
  };

  const fileUploadPath = `/${IMAGES_DIRECTORY_PREFIX}/${language}/${file.name}`;
  encodedFiles.push({
    fileBuffer: base64EncodedImage,
    options: {
      metadata,
    },
    fileType: 'image',
    fileUploadPath,
    makePublic: true, // This is options, default is makePublic: true, kept as a API reference.
    clear_cdn_cache: true,
  });

  const {
    project,
    bucketName,
  } = getDefaultGCSConfigs('all');

  return uploadImagesToGCSBucket({
    files: encodedFiles,
    clearCdnCache: true,
    bucketName,
    project,
    optimizeImages,
  });
};

const hajjAndUmrahTypes = Object.keys(HAJJ_AND_UMRAH_TYPE).map((type) => {
  return {
    key: HAJJ_AND_UMRAH_TYPE[type],
    value: type
  }
});

const audienceTypes = Object.keys(AUDIENCE_TYPE).map((audienceType) => {
  return {
    value: AUDIENCE_TYPE[audienceType],
    label: audienceType
  }
});

const countryOptions = Object.keys(CountryList).map((countryCode) => {
  return {
    value: countryCode,
    label: CountryList[countryCode]
  }
});

const getAudiences = (audiences) => {
  return audiences.map((audience) => {
    return {
      'value': audience,
      'label': _.invert(AUDIENCE_TYPE)[audience]
    }
  });
};

const getCountries = (countries) => {
  return countries.map((country) => {
    return {
      'value': country,
      'label': country
    }
  });
};

const getContentFromRowData = (content) => {
  return {
    ...content,
    audiences: getAudiences(content.audiences),
    countries: getCountries(content.countries),
    publish_start_date: moment(content.publish_start_date).format('YYYY-MM-DDTHH:mm'),
    publish_end_date: moment(content.publish_end_date).format('YYYY-MM-DDTHH:mm'),
  }
};

const getArrayToSave = (dataArray) => {
  if(_.isEmpty(dataArray)) {
    return dataArray;
  }

  return _.map(dataArray, 'value');
};

const getDateToSave = (date) => {
  return date ? new Date(date).getTime() : null;
};

const getContentToSave = (content, newThumbnailUrl) => {
  const contentToSave = {
    ...content,
    audiences: getArrayToSave(content.audiences),
    countries: getArrayToSave(content.countries),
    publish_start_date: getDateToSave(content.publish_start_date),
    publish_end_date: getDateToSave(content.publish_end_date),
    thumbnail_url: newThumbnailUrl || content.thumbnail_url,
  };

  delete contentToSave['countrySelectPolicy'];

  return contentToSave;

};


class Create extends Component {
  constructor(props) {
    super(props);
    const {
      state,
    } = props.history.location;
    const content = state ? state.content : null;

    const InitialState = {
      submitting: false,
      content: {
        id: null,
        audiences: [{'value':'all','label':'ALL'}],
        countries: [],
        language_code: '',
        type: '',
        url: '',
        thumbnail_url: '',
        title: '',
        summary: '',
        source: '',
        is_hidden: false,
        publish_start_date: moment().format('YYYY-MM-DDTHH:mm'),
        publish_end_date: '',
      },
      uploadedFile: null,
      optimizeImages: false,
      prefillingStatus: null,
      countrySelectPolicy: "select"
    };

    if(content) {
      this.state = {
        ...InitialState,
        content: getContentFromRowData(content),
      }
    } else {
      this.state = InitialState;
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      submitting: true,
    });
    const {
      uploadedFile,
      optimizeImages,
      countrySelectPolicy
    } = this.state;
    const content = _.cloneDeep(this.state.content);

    if( countrySelectPolicy == "exclude" ) {
      let newlist = _.differenceWith(countryOptions, [{ value: 'ALL' }, ...content.countries], (c1, c2) => {
        return c1.value == c2.value;
      }).map( ( item ) => ({ label: item.value , value: item.value }))
      content.countries = newlist;
    }
    console.log("list countries", content.countries )

    try {
      // Save image if there is a file else save content.
      let newThumbnailUrl = null;
      if (uploadedFile) {
        const uploadRes = await uploadNewThumbnail(uploadedFile,
            content.language_code, optimizeImages);
        const uploadResData = uploadRes.data;

        if(uploadResData.success) {
          const urlList = uploadResData['data'];
          const urlArray = _.map(urlList, 'public_url');
          newThumbnailUrl = urlArray[0];
        } else {
          console.log(`Image upload failed. ${JSON.stringify(uploadRes)}`);
          toast.error('Something went wrong while uploading the image.', {
            position: toast.POSITION.BOTTOM_LEFT
          });
          this.setState({ submitting: false });
          return;
        }
      }

      const updatedContent = getContentToSave(content, newThumbnailUrl);
      const response = await hajjAndUmrahAddOrUpdate({
        contents: [updatedContent],
      });
      console.log(`response : ${JSON.stringify(response)}`);
      if(response.data.success) {
        toast.success('Content saved successfully!', {
          position: toast.POSITION.BOTTOM_LEFT
        });
        setTimeout(()=> {
          this.props.history.push('/hajj-and-umrah-list');
        }, 2000);
      } else {
        toast.error('Something went wrong while saving the content.', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }
      this.setState({ submitting: false });
    } catch (err) {
      console.log(`Something went wrong while saving the content.`);
      this.setState({ submitting: false });
    }
  };

  onAudiencesChange(selectedAudiences) {
    let { content } = this.state;

    if(selectedAudiences.length === 0) {
      content['audiences'] = [{'value':'all','label':'ALL'}]
    } else {
      let newAudiences = [];

      selectedAudiences.forEach((selectedAudience) => {
        newAudiences.push({ value: selectedAudience.value, label: selectedAudience.label});
      });

      content['audiences'] = newAudiences;
    }

    this.setState({
      content,
    });
  };

  onCountrySelectPolicyChange(event){
    this.setState((prev) => {
      return {
        ...prev,
        countrySelectPolicy: event.target.value
      }
    })
  }

  onCountriesChange(selectedCountries) {
    let {
      content,
    } = this.state;

    if(selectedCountries.length === 0) {
      content['countries'] = [];
    } else {
      let countryCodes = [];

      selectedCountries.forEach((countryCode) => {
        countryCodes.push({ value: countryCode.value, label: countryCode.value});
      });
      content['countries'] = countryCodes;
    }

    this.setState({ content });
  }

  async prefillContentMeta(url) {
    let {
      content,
    } = this.state;
    this.setState({
      prefillingStatus: 0,
    });

    try {
      const resp = await getContentMetaData({ url });
      const htmlString = resp.data;
      const {
        thumbnailUrl,
        title,
        summary,
        source,
        languageCode,
      } = getMetaInfo(htmlString);

      let prefillingStatus = 1;
      if(!thumbnailUrl && !title && !summary && !source) {
        prefillingStatus = -1;
      }

      content['url'] = url;
      content['title'] = title;
      content['thumbnail_url'] = thumbnailUrl;
      content['summary'] = summary;
      content['language_code'] = languageCode;
      content['source'] = source;

      this.setState({
        content,
        prefillingStatus,
      });
    } catch (err) {
      console.log('Error Occurred while pre filling Metadata. Error : ', err);
      this.setState({ prefillingStatus: -1 });
    }
  }

  shouldBlockSubmission = (content) => {

    return _.isEmpty(content['url'])
        || _.isEmpty(content['type'])
        || _.isEmpty(content['language_code']);
  };

  onFieldChange(fieldKey, e) {
    const value = e.target.value;
    let { content } = this.state;

    if(fieldKey === 'publish_start_date') {
      content['publish_start_date'] = value;
    } else if (fieldKey === 'publish_end_date') {
      content['publish_end_date'] = value;
    } else if (fieldKey === 'url') {
      this.prefillContentMeta(value);
    } else {
      content[fieldKey] = value;
    }

    this.setState({
      content,
    });
  }

  onSelectChange(selectKey, e) {
    let { content } = this.state;
    content[selectKey] = e.target.value;

    this.setState({
      content,
    });
  }

  onToggle(toggleKey) {
    let { content } = this.state;
    content[toggleKey] = !content[toggleKey];
    this.setState({
      content,
    });
  }

  onUploadFile(event) {
    const file = event.target.files[0];
    this.setState({
      uploadedFile: file,
    });
  }

  onRemoveFile() {
    this.setState({
      uploadedFile: null,
    });
  }

  onToggleCheckBox(type, e) {
    this.setState({
      [type]: e.target.checked,
    });
  }

  renderSelect(arr, selectKey, defaultVal = '') {
    const options = arr.map((item, i) => {
      return (
          <option value={item.key} key={i}>{item.value}</option>
      );
    });

    return (
        <select
            value={defaultVal}
            onChange={this.onSelectChange.bind(this, selectKey)}
            className='form-control'
        >
          <option
              disabled
              value={''}>
            Please select one
          </option>
          {options}
        </select>
    )
  }

  render() {
    const {
      content,
      submitting,
      uploadedFile,
      optimizeImages,
      prefillingStatus,
    } = this.state;
    const uploadedImageUrl = uploadedFile ? URL.createObjectURL(uploadedFile) : null;
    const {
      id,
      url,
      type,
      title,
      source,
      summary,
      audiences,
      is_hidden,
      countries,
      language_code,
      thumbnail_url,
      publish_end_date,
      publish_start_date,
    } = content;

    return (
        <BasicLayout pagePermission='content_editor'>
          <ToastContainer autoClose={2000} />
          <h3 className='content_editor_title'>Add Hajj and Umrah</h3>
          <Row>
            <form onSubmit={this.handleSubmit}>
              <Col md={6}>
                {
                  id &&
                  <div className='form-group'>
                    <label>Id : </label> &nbsp;
                    <Fragment>
                      <span style={{ color: '#00722d' }}>{id}</span> &nbsp;
                      <CopyToClipboard
                        text={id}
                        onCopy={() => {
                          console.log(`Copied id : ${id}`)
                        }}
                      >
                        <Button className="btn btn-xs btn-dark mp-content-v2-table-hover-btn">
                          &nbsp;<span className="glyphicon glyphicon-duplicate" aria-hidden="true"/>
                        </Button>
                      </CopyToClipboard>
                    </Fragment>
                  </div>
                }
                <div className='form-group'>
                  <label>URL</label>
                  <input required
                         value={url}
                         onChange={this.onFieldChange.bind(this, 'url')}
                         type='text'
                         className='form-control'
                         placeholder='URL'
                  />
                  <PrefillStatus
                      prefillingStatus={prefillingStatus}
                  />
                </div>
                <div className='form-group'>
                  <label>Type</label>
                  {
                    this.renderSelect(
                        hajjAndUmrahTypes,
                        'type',
                        type)
                  }
                </div>
                <div className='form-group'>
                  <label>Language</label>
                  {
                    this.renderSelect(
                        getSupportedLanguages(),
                        'language_code',
                        language_code)
                  }
                </div>
                <div className='form-group'>
                  <label>Audiences</label>
                  <Select
                      onChange={this.onAudiencesChange.bind(this)}
                      value={audiences}
                      isSearchable={true}
                      options={audienceTypes}
                      isMulti
                  />
                </div>
                <div className='form-group'>
                  <label>Title</label>
                  <input value={title}
                         required
                         onChange={this.onFieldChange.bind(this, 'title')}
                         type='text'
                         className='form-control'
                         placeholder='Title'
                  />
                </div>
                <div className='form-group'>
                  <label>Thumbnail URL</label>
                  <input value={thumbnail_url}
                         required
                         onChange={this.onFieldChange.bind(this, 'thumbnail_url')}
                         type='text'
                         className='form-control'
                         placeholder='Thumbnail URL'
                  />
                </div>
                <FileUploader
                    onUploadFile={this.onUploadFile.bind(this)}
                    fileDeleted={!uploadedImageUrl}
                />
                <div className='form-group'>
                  <label>Summary</label>
                  <textarea value={summary}
                            onChange={this.onFieldChange.bind(this, 'summary')}
                            className='form-control'
                            placeholder='Summary'
                  />
                </div>
                <div className='form-group'>
                  <label>Source</label>
                  <input value={source}
                         required
                         onChange={this.onFieldChange.bind(this, 'source')}
                         type='text'
                         className='form-control'
                         placeholder='Source'
                  />
                </div>
                <div className='form-group'>
                  <label>Countries (Optional)</label>
                  <div onChange={this.onCountrySelectPolicyChange.bind(this)}>
                    <input
                      type="radio"
                      value="select"
                      name="countries-select-policy"
                      checked={this.state.countrySelectPolicy == 'select'}
                      defaultChecked
                    /><label style={{ paddingRight: 10}}>Select</label>
                    <input
                      type="radio"
                      value="exclude"
                      name="countries-select-policy"
                      checked={this.state.countrySelectPolicy == 'exclude'}
                    /><label>Exclude</label>
                  </div>
                  <Select onChange={this.onCountriesChange.bind(this)}
                          value={countries}
                          isSearchable={true}
                          options={countryOptions}
                          isMulti
                  />
                </div>
                <div className='form-group'>
                  <label>Publish Start Date</label>
                  <input required
                         value={publish_start_date}
                         onChange={this.onFieldChange.bind(this, 'publish_start_date')}
                         type='datetime-local'
                         className='form-control'
                         placeholder='Publish Start Date'/>
                </div>
                <div className='form-group'>
                  <label>Publish End Date</label>
                  <input value={publish_end_date}
                         onChange={this.onFieldChange.bind(this, 'publish_end_date')}
                         type='datetime-local'
                         className='form-control'
                         placeholder='Publish End Date'/>
                </div>
                <div className='form-group'>
                  <label>Hide content</label>
                  <div>
                    <ToggleButton
                        value={is_hidden}
                        onToggle={this.onToggle.bind(this, 'is_hidden')}
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <SubmitButton
                      id={id}
                      blockSubmission={this.shouldBlockSubmission(content)}
                      submitting={submitting}
                  />
                </div>
              </Col>
              <Col md={6}>
                {
                  uploadedImageUrl || thumbnail_url ?
                      <div className='content__preview_div'>
                        <img src={uploadedImageUrl || thumbnail_url} alt='' width='100%'/>
                        {
                          uploadedImageUrl ?
                              <div className={'upload-tools'}>
                                <button onClick={this.onRemoveFile.bind(this)}
                                        type={'button'}
                                        className="btn-danger btn-sm">
                                <span
                                    className="glyphicon glyphicon-trash"
                                    aria-hidden="true"
                                />
                                  &nbsp;{'Reset Image'}
                                </button>
                                <span>
                                  <label className={'content__list_options_bar_label'}>
                                    {'Optimize Image : '}
                                    <input
                                        className={'content__list_options_bar_input'}
                                        type="checkbox"
                                        checked={optimizeImages}
                                        onChange={this.onToggleCheckBox.bind(this, 'optimizeImages')}
                                    />
                                  </label>
                               </span>
                              </div> : null
                        }
                        <h4>{title}</h4>
                        <p className='content__preview_div_source'>{source}</p>
                        <p>{summary}</p>
                      </div> : null
                }
              </Col>
            </form>
          </Row>
        </BasicLayout>
    );
  }
}

export default Create;
