import React,
{
  Component,
} from 'react';
import {
  Col,
  Row,
  FormControl,
  ControlLabel,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import firebase from 'firebase/app';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  createLog,
} from '../../services/logs';
import ToggleButton from 'react-toggle-button';
import {
  ADMIN_DATABASE_URL,
} from '../../Constants';
import {
  csvDownloader,
} from '../../utils/index';
import rtdbRef from "../../apis/rtdbRef";

class GeneratePromoCodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "monthly",
      campaign: "",
      expiry: "",
      multiUsageExpiry: "",
      multiUsageCode: "",
      duration: 1,
      numberOfCodes: 1,
      textareaValue: "",
      showDownloadBtn: false,
      errorMessage: "",
      showError: false,
      newPromoCodes: [],
      createdBy: "",
      created_datetime: null,
      releaseError: [],
      codeType: "onetime",
      shouldImportCode: false,
      importedCodes: null,
      subdomain: null,
      feature: 'mp',
    };
  }

  clearFields(e) {
    e.preventDefault();
    this.setState({
      campaign: "",
      expiry: "",
      multiUsageExpiry: "",
      multiUsageCode: "",
      duration: 1,
      numberOfCodes: 1,
      importedCodes: null,
      shouldImportCode: false
    });
  }

  handleInputChange(key, e) {
    this.setState({
      [key]: e.target.value,
      showError: false,
      errorMessage: ""
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const {
      type,
      campaign,
      expiry,
      duration,
      numberOfCodes,
      importedCodes,
      shouldImportCode,
    } = this.state;
    const createdBy = localStorage.getItem('uid');
    let newPromoCodes = [];
    if(!this.validateEntries(type, campaign, expiry, duration, numberOfCodes, importedCodes)) {
      return;
    }
    const len = shouldImportCode ? importedCodes.length : parseInt(numberOfCodes, 10);
    let str = "*********************************************************************\nCode | Type | Duration | Campaign | Expiry | CreatedBy\n*********************************************************************\n\n";
    if(type === 'lifetime') {
      str = "*********************************************************************\nCode | Type | Campaign | Expiry | CreatedBy\n*********************************************************************\n\n";
    }

    for(let i=0; i<len; i++) {
      let code = shouldImportCode ? importedCodes[i] : this.generateCode(12);
      newPromoCodes.push(code);
      if(type === 'lifetime') {
        str += code+" "+type+" "+campaign+" "+expiry+" "+createdBy+"\n";
      } else {
        str += code+" "+type+" "+duration+" "+campaign+" "+expiry+" "+createdBy+"\n";
      }
    }
    this.setState({
      textareaValue: str,
      showDownloadBtn: true,
      newPromoCodes,
      createdBy,
      releaseError: []
    });
  }

  isValidDate(dateString) {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if(!dateString.match(regEx)) return false;
    let d = new Date(dateString);
    if(!d.getTime() && d.getTime() !== 0) return false;
    if (d.getTime() < Date.now()) return false;
    return d.toISOString().slice(0,10) === dateString;
  }

  validateEntries(type, campaign, expiry, duration, numberOfCodes, importedCodes) {
    let error = false, errorMessage = "";
    if(type.trim() === "") {
      error = true;
      errorMessage = "Type can't be empty";
    }
    if(campaign.trim() === "") {
      error = true;
      errorMessage = "Campaign name can't be empty";
    }
    if(!this.isValidDate(expiry)) {
      error = true;
      errorMessage = "Expiry is not valid";
    }
    if(type === 'monthly' && !duration) {
      error = true;
      errorMessage = "Duration can't be empty";
    }
    if(!numberOfCodes) {
      error = true;
      errorMessage = "Number of codes can't be empty";
    }

    if(importedCodes && importedCodes.length > 0) {
      importedCodes.forEach((item) => {
        if(item.length < 9) {
          error = true;
          errorMessage = "Imported code can't be less than 9 characters";
        }
      });
    }

    if(!error) {
      this.setState({
        showError: error,
        errorMessage: ""
      });
      return true;
    } else {
      this.setState({
        showError: error,
        errorMessage
      });
      return false;
    }
  }

  generateCode(len) {
    let chars = "ABCDEFGHJKLMNPQRSTUVWXYZ123456789";
    let result = "";
    for (let i = 0; i<len; ++i) {
        result += chars.charAt(Math.floor(Math.random()*chars.length));
    }
    return result;
  }

  renderDuration(type) {
    if(type === 'monthly' || type === 'yearly') {
      return (
        <div>
          <ControlLabel>Duration</ControlLabel>
          <FormControl
            defaultValue="1"
            type="number"
            min="1"
            placeholder="Example: 6"
            onChange={this.handleInputChange.bind(this, 'duration')}
          />
        </div>
      );
    }
    return null;
  }

  downLoadCodes() {
    const { campaign, codeType, multiUsageCode } = this.state;
    let fileAdditional = campaign.replace(/[^a-zA-Z0-9]/g, "");
    if(codeType === 'multi') {
      fileAdditional = multiUsageCode.replace(/[^a-zA-Z0-9]/g, "");
    }
    csvDownloader({
      content: this.processCSV(),
      fileName: `MP_PROMO_CODES_${fileAdditional}_${Date.now()}`
    });
  }

  processCSV() {
    const {
      type,
      expiry,
      feature,
      campaign,
      duration,
      codeType,
      numberOfCodes,
      newPromoCodes,
      multiUsageCode,
    } = this.state;
    let csvContent = "#,Created At,Created by";
    let entitledFeatures = feature && feature === 'mp_qalbox' ? 'Muslim Pro + Qalbox' : 'Muslim Pro';

    if (codeType === 'multi') {
      csvContent = "#,Code,Campaign,Entitled Features,Type,Usage Limit,Expiry,Created by";
    }
    if(codeType === 'onetime') {
      csvContent = "#,Campaign,Entitled Features,Code,Type,Expiry,Created by";
      if(type !== "lifetime") {
        csvContent = "#,Campaign,Entitled Features,Code,Type,Duration,Expiry,Created by";
      }
    }
    csvContent += "\r\n"
    if(codeType === 'onetime') {
      let row = "";
      newPromoCodes.forEach((code, i) => {
        if(type === 'lifetime') {
          row = `${i+1},${campaign},${entitledFeatures},${code},${type},${expiry},${localStorage.getItem('uid')}`;
        } else {
          row = `${i+1},${campaign},${entitledFeatures},${code},${type},${duration},${expiry},${localStorage.getItem('uid')}`;
        }
        csvContent += row + "\r\n";
      });
    } else {
      csvContent += `1,${multiUsageCode},${campaign},${entitledFeatures},${type},${numberOfCodes},${expiry},${localStorage.getItem('uid')}\r\n`;
    }
    return csvContent;
  }

  releasePromoCodes() {
    const confirmation = window.confirm("Do you really want release this promo codes into firebase?");
    if(!confirmation) {
      return;
    }

    const { codeType } = this.state;
    if(codeType === 'onetime') {
      const {
        type,
        expiry,
        feature,
        campaign,
        duration,
        createdBy,
        subdomain,
        releaseError,
        newPromoCodes,
        numberOfCodes,
      } = this.state;
      let promoObject = {
        campaign,
        expiry,
        created_by: createdBy,
        duration: parseInt(duration, 10),
        type,
        feature,
      };
      if(type === 'lifetime') {
        promoObject = {
          campaign,
          expiry,
          created_by: createdBy,
          type
        };
      }
      if(subdomain && subdomain.trim() !== "") {
        promoObject["subdomain"] = subdomain;
      }
      console.log("Will write this object: ", promoObject);
      let missingCount = 0;
      const releasePromises = newPromoCodes.map((code) => {
        const codePath = rtdbRef.promoCodes(`/promoCodes/${code}`);
        return codePath.once("value", (pSnapshot) => {
          if(pSnapshot.val()) {
            missingCount++;
            releaseError.push(`${code} is a duplicate key`);
            return Promise.resolve();
          } else {
            return codePath.set(promoObject);
          }
        });
      });
      Promise.all(releasePromises).then(() => {
        let app = firebase.app();
        app.database(ADMIN_DATABASE_URL)
           .ref(`/Configs/Production/PromoCodes/campaigns/${campaign}`)
           .set(true);
        app.database(ADMIN_DATABASE_URL)
           .ref(`/Configs/Production/PromoCodes/creators/${createdBy}`)
           .set(true);
        toast.success(`Successfully created ${numberOfCodes-missingCount} promo codes`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
        this.setState({releaseError});
        delete promoObject['created_by'];
        createLog('generate_promo',{
          ...promoObject,
          number_of_codes: parseInt(numberOfCodes, 10)
        });
      });
    } else {
      const {
        type,
        expiry,
        feature,
        duration,
        campaign,
        createdBy,
        numberOfCodes,
        multiUsageCode,
        created_datetime,
      } = this.state;
      const multiUsagePromo = {
        created_datetime,
        created_by: createdBy,
        expiry: this.formatDate(expiry),
        campaign,
        type,
        usage_limit: parseInt(numberOfCodes),
        usage_count: 0,
        feature,
        duration,
      };
      rtdbRef.promoCodes(`/promoCodesMultiUsage/${multiUsageCode}`).set(multiUsagePromo).then(() => {
        toast.success(`Successfully created ${multiUsageCode} as a multi usage promo code`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
        createLog('generate_promo', multiUsagePromo);
      });
    }
  }

  renderActionButtons(show) {
    if(!show) {
      return <div className="promo__action__buttons_div">
        <button className="btn btn-info promo__download_btn" disabled><span className="glyphicon glyphicon-download"></span> Download as CSV</button>
        <button className="btn btn-danger promo__download_btn" disabled><span className="glyphicon glyphicon-plane"></span> Release</button>
      </div>
    }
    return <div className="promo__action__buttons_div">
      <button onClick={this.downLoadCodes.bind(this)} className="btn btn-info promo__download_btn"><span className="glyphicon glyphicon-download"></span> Download as CSV</button>
      <button onClick={this.releasePromoCodes.bind(this)} className="btn btn-danger promo__download_btn"><span className="glyphicon glyphicon-plane"></span> Release</button>
    </div>;
  }

  renderError(showError, errorMessage) {
    if(!showError) {
      return null;
    }

    return <div className="alert alert-danger promo__code_error_alert" role="alert">
      {errorMessage}
    </div>;
  }

  renderReleaseError(releaseError) {
    if(releaseError.length === 0) {
      return null;
    } else {
      return releaseError.map((item, i) => {
        return <p className="text-danger promo__release_error small" key={i}>{item}</p>;
      });
    }
  }

  handleCodeTypeChange(e) {
    this.setState({
      codeType: e.target.value
    });
  }

  async handleMultiUsageSubmit(e) {
    e.preventDefault();
    const {
      type,
      expiry,
      feature,
      campaign,
      numberOfCodes,
      multiUsageCode,
    } = this.state;
    const createdBy = localStorage.getItem('uid'),
    created_datetime = Date.now();
    let newPromoCodes = [];

    if(!(await this.validateMultiEntries(multiUsageCode, expiry, campaign, numberOfCodes))) {
      return;
    }

    const entitledFeatures = feature && feature === 'mp_qalbox' ? 'Muslim Pro + Qalbox' : 'Muslim Pro';
    const str = multiUsageCode+" "+campaign+" "+entitledFeatures+" "+type+" "+numberOfCodes+" "+expiry+" "+createdBy+" "+created_datetime;

    this.setState({
      textareaValue: str,
      showDownloadBtn: true,
      newPromoCodes,
      createdBy,
      created_datetime,
      releaseError: []
    });
  }

  isValidCode(str) {
    const regEx = /^[A-Z0-9]{5,15}$/;
    return str.match(regEx);
  }

  async fetchMultiUsagePromoCode(promoCode) {
    const snapshot = await rtdbRef.promoCodes(`/promoCodesMultiUsage/${promoCode}`).once("value");
    return snapshot.val();
  }

  async validateMultiEntries(multiUsageCode, multiUsageExpiry, campaign, numberOfCodes) {
    let error = false, errorMessage = "";
    if(!this.isValidCode(multiUsageCode)) {
      error = true;
      errorMessage = "Not a valid code";
    }

    if(!this.isValidDate(multiUsageExpiry)) {
      error = true;
      errorMessage = "Expiry is not valid";
    }

    if((campaign || '').trim() === "") {
      error = true;
      errorMessage = "Campaign name can't be empty";
    }

    if(parseInt(numberOfCodes || '0')  <= 0) {
      error = true;
      errorMessage = "Usage limit should be greater than 0";
    }

    const promoCodeData = await this.fetchMultiUsagePromoCode(multiUsageCode);

    if (promoCodeData) {
      error = true;
      errorMessage = `Multi-usage promo code ${multiUsageCode} is already in use`;
    }

    if(!error) {
      this.setState({
        showError: error,
        errorMessage: ""
      });
      return true;
    } else {
      this.setState({
        showError: error,
        errorMessage
      });
      return false;
    }
  }

  formatDate(d) {
    const splited = d.split("-");
    const formattedDate = splited[2]+"-"+splited[1]+"-"+splited[0];
    return formattedDate;
  }

  toggleShouldImportCode() {
    this.setState({
      shouldImportCode: !this.state.shouldImportCode
    });
  }

  onImportedCodesChanged(e) {
    const codes = e.target.value.trim();
    this.setState({
      importedCodes: codes.split('\n')
    });
  }

  renderImportPanel(show) {
    if(!show) {
      return null;
    } else {
      return <div>
        <ControlLabel>Paste your custom codes here</ControlLabel>
        <textarea onChange={this.onImportedCodesChanged.bind(this)} className="form-control" rows="10"></textarea>
      </div>;
    }
  }

  render() {
    const {
      type,
      codeType,
      showError,
      releaseError,
      errorMessage,
      textareaValue,
      showDownloadBtn,
      shouldImportCode,
    } = this.state;

    const numberOfCodesEl = shouldImportCode ? null : <span>
      <ControlLabel>Number of codes</ControlLabel>
      <FormControl hidden={shouldImportCode} value={this.state.numberOfCodes} type="number" min="1" placeholder="Example: 100" onChange={this.handleInputChange.bind(this, 'numberOfCodes')}/>
    </span>;

    let formEl = codeType !== 'multi' ? <form className="promo_generation_form" onSubmit={this.handleSubmit.bind(this)}>
      <ControlLabel>Entitled Features</ControlLabel>
      <select className="form-control" value={this.state.feature} onChange={this.handleInputChange.bind(this, 'feature')}>
        <option value="mp">Muslim Pro</option>
        <option value="mp_qalbox">Muslim Pro + Qalbox</option>
      </select>
      <ControlLabel>Campaign name</ControlLabel>
      <FormControl value={this.state.campaign} placeholder="Enter campaign name" onChange={this.handleInputChange.bind(this, 'campaign')}/>
      <ControlLabel>Custom subdomain (Optional)</ControlLabel>
      <FormControl value={this.state.subdomain} placeholder="Enter subdomain" onChange={this.handleInputChange.bind(this, 'subdomain')}/>
      <ControlLabel>Expiry date</ControlLabel>
      <FormControl type="date" value={this.state.expiry} placeholder="YYYY-MM-DD" onChange={this.handleInputChange.bind(this, 'expiry')}/>
      <ControlLabel>Type</ControlLabel>
      <select className="form-control" value={this.state.type} onChange={this.handleInputChange.bind(this, 'type')}>
        <option value="monthly">Monthly</option>
        <option value="yearly">Yearly</option>
        <option value="lifetime">Lifetime</option>
      </select>
      {this.renderDuration(type)}
      {numberOfCodesEl}
      <ControlLabel>Import custom code</ControlLabel>
      <ToggleButton value={shouldImportCode} onToggle={this.toggleShouldImportCode.bind(this)}/>
      {this.renderImportPanel(shouldImportCode)}
      <button type="submit" className="btn btn-primary generate__promo_btn">Generate</button> {' '}
      <button onClick={this.clearFields.bind(this)} className="btn btn-default generate__promo_btn">Clear</button>
    </form> :
        <form className="multi_promo_generation_form" onSubmit={this.handleMultiUsageSubmit.bind(this)}>
          <ControlLabel>Entitled Features</ControlLabel>
          <select className="form-control" value={this.state.feature} onChange={this.handleInputChange.bind(this, 'feature')}>
            <option value="mp">Muslim Pro</option>
            <option value="mp_qalbox">Muslim Pro + Qalbox</option>
          </select>
          <ControlLabel>Code</ControlLabel>
          <FormControl value={this.state.multiUsageCode} placeholder="Enter code" onChange={this.handleInputChange.bind(this, 'multiUsageCode')}/>
          <ControlLabel>Campaign name</ControlLabel>
          <FormControl value={this.state.campaign} placeholder="Enter campaign name" onChange={this.handleInputChange.bind(this, 'campaign')}/>
          <ControlLabel>Expiry date</ControlLabel>
          <FormControl type="date" value={this.state.expiry} placeholder="YYYY-MM-DD" onChange={this.handleInputChange.bind(this, 'expiry')}/>
          <ControlLabel>Type</ControlLabel>
          <select className="form-control" value={this.state.type} onChange={this.handleInputChange.bind(this, 'type')}>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
            <option value="lifetime">Lifetime</option>
          </select>
          {this.renderDuration(type)}
          <span>
            <ControlLabel>Usage limit</ControlLabel>
            <FormControl value={this.state.numberOfCodes} type="number" min="1" placeholder="Example: 100" onChange={this.handleInputChange.bind(this, 'numberOfCodes')}/>
          </span>
          <button type="submit" className="btn btn-primary generate__promo_btn">Generate</button> {' '}
          <button onClick={this.clearFields.bind(this)} className="btn btn-default generate__promo_btn">Clear</button>
        </form>
    // if(codeType === 'multi') {
    //     formEl = <form onSubmit={this.handleMultiUsageSubmit.bind(this)}>
    //       <ControlLabel>Code</ControlLabel>
    //       <FormControl value={this.state.multiUsageCode} placeholder="Enter code" onChange={this.handleInputChange.bind(this, 'multiUsageCode')}/>
    //       <ControlLabel>Expiry date</ControlLabel>
    //       <FormControl type="date" value={this.state.multiUsageExpiry} placeholder="YYYY-MM-DD" onChange={this.handleInputChange.bind(this, 'multiUsageExpiry')}/>
    //       <button type="submit" className="btn btn-primary generate__promo_btn">Generate</button> {' '}
    //       <button onClick={this.clearFields.bind(this)} className="btn btn-default generate__promo_btn">Clear</button>
    //     </form>;
    // }

    return (
      <BasicLayout pagePermission="write_codes">
        <ToastContainer autoClose={2000} />
        <Row className="generate__promo_code_wrapper">
          <Col md={4}>
            <ControlLabel>Code type</ControlLabel>
            <select className="form-control" value={this.state.codeType} onChange={this.handleCodeTypeChange.bind(this)}>
              <option value="onetime">One-time codes</option>
              <option value="multi">Multi-usage codes</option>
            </select>
            {formEl}
            {this.renderError(showError, errorMessage)}
          </Col>
          <Col md={8} className="generate__promo_code_ta_wrapper">
            <textarea className="form-control" value={textareaValue}></textarea>
            {this.renderActionButtons(showDownloadBtn)}
            {this.renderReleaseError(releaseError)}
          </Col>
        </Row>
      </BasicLayout>
    );
  }
}

export default GeneratePromoCodes;
