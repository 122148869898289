import {
  actionTypes,
} from './actionTypes';
import {
  getCustomHashtags,
  getBlockedHashtags,
  getRemovedHashtags,
  isHashtagAlreadyExisting,
  getReorderedCustomHashtags,
} from './helper';

const THTInitialState = {
  selectedLanguageCode: 'en',
  order: 'DESC',
  dbLimit: 10,
  apiLimit: 10,
  newCustomHashTag: '',
  newBlockedHashTag: '',
  rtdbCustomHashtags: [],
  rtdbBlockedHashtags: [],
  customHashtags: [],
  blockedHashtags: [],
  hashtagExistingErrorMsg: '',
};

const trendingHashtagsReducer = (state, { type , payload }) => {
  const {
    customHashtags, blockedHashtags, rtdbCustomHashtags, rtdbBlockedHashtags,
  } = state;

  switch (type) {
    case actionTypes.THT_UPDATE_PROP_VAL:
      const { prop, value } = payload;

      if(prop === 'selectedLanguageCode') {
        return {
          ...state,
          order: THTInitialState.order,
          dbLimit: THTInitialState.dbLimit,
          apiLimit: THTInitialState.apiLimit,
          newCustomHashTag: '',
          newBlockedHashTag: '',
          [prop]: value,
        };
      }

      return {
        ...state,
        [prop]: value,
      };

    case actionTypes.THT_CHANGE_CUSTOM_HASHTAG_ORDER:
      const { hashtag, direction } = payload;

      return {
        ...state,
        customHashtags:
          [...getReorderedCustomHashtags(customHashtags, hashtag, direction)],
      }

    case actionTypes.THT_ADD_CUSTOM_HASHTAG:
    {
      const hashtagAlreadyExisting = isHashtagAlreadyExisting(customHashtags, payload);

      if(hashtagAlreadyExisting) {

        return {
          ...state,
          hashtagExistingErrorMsg:
            `Hashtag "${payload.hashtag}" already existing in custom hashtags.`,
          newCustomHashTag: '',
        };
      } else {

        return {
          ...state,
          customHashtags:
            [...customHashtags, { ...payload, priority: customHashtags.length + 1 }],
          newCustomHashTag: '',
          hashtagExistingErrorMsg: '',
        };
      }
    }


    case actionTypes.THT_BLOCK_HASHTAG:
    {
      const hashtagAlreadyExisting = isHashtagAlreadyExisting(blockedHashtags, payload);

      if(hashtagAlreadyExisting) {

        return {
          ...state,
          hashtagExistingErrorMsg:
            `Hashtag "${payload.hashtag}" already existing in blocked hashtags.`,
          newBlockedHashTag: '',
        };
      } else {

        return {
          ...state,
          blockedHashtags: [...blockedHashtags, payload],
          newBlockedHashTag: '',
          hashtagExistingErrorMsg: '',
        };
      }
    }

    case actionTypes.THT_REMOVE_CUSTOM_HASHTAG:

      return {
        ...state,
        customHashtags: getRemovedHashtags(customHashtags, payload),
      };

    case actionTypes.THT_REMOVE_BLOCKED_HASHTAG:

      return {
        ...state,
        blockedHashtags: getRemovedHashtags(blockedHashtags, payload),
      };

    case actionTypes.THT_FETCH_COMPLETE_RTDB_TRENDING_HASHTAGS:
      const rtdbTrendingCustomHashtags = getCustomHashtags(payload);
      const rtdbTrendingBlockedHashtags = getBlockedHashtags(payload);

      return {
        ...state,
        rtdbCustomHashtags: rtdbTrendingCustomHashtags,
        rtdbBlockedHashtags: rtdbTrendingBlockedHashtags,
        customHashtags: [...rtdbTrendingCustomHashtags],
        blockedHashtags: [...rtdbTrendingBlockedHashtags],
      };

    case actionTypes.THT_FETCH_COMPLETE_RTDB_TRENDING_HASHTAGS_API_LIMIT:
      const rtdbAPILimit = (payload && payload.data) || 10;

      return {
        ...state,
        apiLimit: rtdbAPILimit,
      };

    case actionTypes.THT_RESET_CUSTOM_HASHTAGS:
      return {
        ...state,
        customHashtags: [...rtdbCustomHashtags],
      };

    case actionTypes.THT_RESET_BLOCKED_HASHTAGS:
      return {
        ...state,
        blockedHashtags: [...rtdbBlockedHashtags],
      };

    default:
      return state;
  }
};

export {
  THTInitialState,
  trendingHashtagsReducer,
};
