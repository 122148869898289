import React from 'react';
import Select from 'react-select';

const QalboxDisplayCategoryMultiSelectDropdown = ({ name, options, selectedOptions, onChange, displayCategories }) => {
  const handleChange = option => {
    onChange(name, option);
  };

  const MultiValueLabel = ({ children, ...props }) => {
    const displayCategory = displayCategories.find(item => item.id === props.data.value);
    const label = `${displayCategory?.name}-${displayCategory?.type}`;
    return (
    <div style={{ display: 'inline-block', background: '#f0f0f0', padding: '2px 6px', borderRadius: '4px', margin: '2px' }}>
      {label}
    </div>
  )};

  return (
    <Select
      isMulti
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      components={{ MultiValueLabel }}
      placeholder="Select Options..."
      isSearchable
    />
  );
};

export default QalboxDisplayCategoryMultiSelectDropdown;