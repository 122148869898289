import { useState } from 'react';
import axiosService from '../../../../service_v2/common/axios-service';

export const useProdSyncShort = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const prodSyncShort = async (shorts, handleUpdate) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await axiosService.post(
        `${process.env.REACT_APP_PROD_SYNC_URL}qalbox-admin-short-service/v1/sync/prod`,
        { data: shorts }
      );

      if (response?.data) {
        setSuccess(true);
        handleUpdate(response?.data);
      } else {
        setError("Failed to sync the short to production");
      }
    } catch (error) {
      setError("An error occurred while syncing the short to production");
    } finally {
      setLoading(false);
    }
  };

  return [ prodSyncShort, loading, error, success ];
};