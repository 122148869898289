
const IMAGES_DIRECTORY_PREFIX  = 'images';

const SUPPORTED_UPLOAD_TARGET = Object.freeze({
  INAPP_MESSAGE: 'in_app_message',
  QURAN_REDESIGN: 'quran_redesign'
});

export {
  SUPPORTED_UPLOAD_TARGET,
  IMAGES_DIRECTORY_PREFIX,
}