import React,
{
  useState,
} from 'react';
import {
  getV2RedisInfoHook,
} from '../../hooks';
import BasicLayout from '../../layouts/basiclayout';
import {
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  RedisSectionOptions,
} from './Constants';
import {
  renderInfo,
} from './common';
import {
  MPSelect,
} from '../../widgets/common';

const options = Object.keys(RedisSectionOptions).map((option) => {
  return {
    key: option,
    value: RedisSectionOptions[option],
  }
});

const RedisInfoAPIV2 = () => {

  const [ selectedCategory, setSelectedCategory ] = useState('all');
  const [ redisInfoResponse ] = getV2RedisInfoHook({
    shouldGetInfo: true,
    section: selectedCategory,
  });

  return (
      <BasicLayout pagePermission="readonly">
        <ToastContainer autoClose={2000}/>
        <div>
          <Row>
            <Col md={12}>
              <h3 className='content_editor_title'>{'Redis Info - API V2'}</h3>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <form>
                <div className='form-group'>
                  <label style={{'marginRight': '5px'}}>Info Category</label>
                  <MPSelect
                      items={options}
                      selectedItem={selectedCategory}
                      onChangeSelect={setSelectedCategory}
                  />
                </div>
              </form>
            </Col>
          </Row>
          <Row>
            {
              renderInfo({
                info: redisInfoResponse && redisInfoResponse.data,
                category: selectedCategory,
              })
            }
          </Row>
        </div>
      </BasicLayout>
  );
};

export default RedisInfoAPIV2;
