import React,
{
  useReducer,
} from 'react';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  OperationStatus,
} from '../../widgets/common';
import {
  SettingsFiles,
  SettingsFileUpdateTypes,
  SettingsFileOperationTypes,
  SettingsTSFileOperationTypes,
} from './Constants';
import {
  KVUpdateInitialState,
  updateKeyValuesReducer,
} from './Reducers';
import {
  actionTypes,
} from './actionTypes';
import {
  updateSettingsFileKeyValuesHook,
} from '../../hooks';
import {
  getKeyValues,
  isValidKeyLocations,
  getKeyValueUpdateConfig,
  shouldDisableKvUpdateButton,
} from './helper';
import {
  KeyValueUpdateForm,
} from './common';
import {
  createLog,
} from '../../services/logs';

const operationTypes = Object.keys(SettingsFileOperationTypes).map((option) => {
  return {
    key: option,
    value: SettingsFileOperationTypes[option]
  }
});

const updateTypes = Object.keys(SettingsFileUpdateTypes).map((option) => {
  return {
    key: option,
    value: SettingsFileUpdateTypes[option]
  }
});

const tsOperationTypes = Object.keys(SettingsTSFileOperationTypes).map((option) => {
  return {
    key: option,
    value: SettingsTSFileOperationTypes[option]
  }
});

const settingFiles = Object.keys(SettingsFiles).map((option) => {
  return {
    key: option,
    value: SettingsFiles[option]
  }
});


const UpdateSettingsFileKeyValues = () => {
  const[ state, dispatch ] = useReducer(updateKeyValuesReducer, KVUpdateInitialState);

  const {
    jsonValue,
    selectedFile,
    keyLocations,
    selectedFetchType,
    selectedUpdateType,
  } = state;

  const validLocations = isValidKeyLocations(keyLocations);

  const [ settingsFileUpdateData, doUpdate ] = updateSettingsFileKeyValuesHook();
  const isUpdating = settingsFileUpdateData && settingsFileUpdateData.isUpdating;
  const onOperationComplete = operationData => {
    if(!operationData.isError) {
      createLog(`${selectedFile}_key_value_update_success`.toLowerCase(), {
        keyValuesData: getKeyValues(keyLocations, jsonValue, selectedUpdateType),
      }).then(() => console.log(`${selectedFile} create log success!`))
        .catch(() => console.log(`${selectedFile} create log failed!`));
      toast.success(`Updating key values of file: ${selectedFile} Success!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      dispatch({
        type: actionTypes.SUKV_RESET_STATE,
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const updateKeyValues = () => {
    doUpdate({
      fileName: selectedFile,
      keyValuesData: getKeyValues(keyLocations, jsonValue, selectedUpdateType),
      updateConfig: getKeyValueUpdateConfig(selectedFile),
      onOperationComplete,
    });
  };

  const updatingData = settingsFileUpdateData && settingsFileUpdateData.isUpdating;
  const disableUpdateButton = shouldDisableKvUpdateButton(validLocations, selectedUpdateType);

  return (
      <BasicLayout pagePermission="readonly">
        <ToastContainer autoClose={2000}/>
        <div>
          <Row>
            <Col md={12}>
              <h3 className='content_editor_title'>{'Update Settings File Key Values'}</h3>
            </Col>
          </Row>
          <KeyValueUpdateForm
              dispatch={dispatch}
              operationTypes={operationTypes}
              tsOperationTypes={tsOperationTypes}
              selectedFetchType={selectedFetchType}
              settingFilesWithTs={settingFiles}
              selectedFile={selectedFile}
              keyLocations={keyLocations}
              disableUpdateButton={disableUpdateButton || isUpdating}
              validLocations={validLocations}
              updateTypes={updateTypes}
              selectedUpdateType={selectedUpdateType}
              isUpdating={isUpdating}
              propUpdateActionType={actionTypes.SUKV_UPDATE_PROP_VAL}
              fetchActionType={actionTypes.SUKV_FETCH_FILE}
              onSubmit={updateKeyValues}
          />
          {
            updatingData ?
                <OperationStatus
                    operationInProgressText={`Updating ${selectedFile} file.`}
                /> : null
          }
        </div>
      </BasicLayout>
  );
};

export default UpdateSettingsFileKeyValues;
