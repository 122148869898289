const fileNameRegex = /^[A-Za-z]{2}\d+$/;
const keyLocationRegex = /^[A-Za-z0-9\-_]+(>?[A-Za-z0-9\-_])*$/;

const SettingsFileOperationTypes = {
  'manual': 'Manual',
  'drop-down': 'Drop Down Select',
};

const SettingsTSFileOperationTypes = {
  'manual': 'Manual',
  'check-box': 'Check Box Select',
};

const SettingsFileUpdateTypes = {
  'add': 'Add',
  'update': 'Update',
  'delete': 'Delete',
};

const SettingsFiles = {
  'settings_api_base': 'Settings API Base',
  'recitations': 'Recitations',
  'dua_recitations': 'Dua Recitations',
  'adhans': 'Adhans',
  'translations': 'Translations',
  'autosettings': 'Auto Settings',
  'settings_legacy': 'Settings Legacy',
  'quran_quotes': 'Quran Quotes',
  'didyouknow': 'Did you know',
  'dhikrs': 'Dhikrs',
  'testimonials': 'Testimonials',
  'terms_and_privacy': 'TnC and Privacy',
};

const SettingsFilesTSLocations = {
  'settings_api_base': [
    'all>timestamp',
    'all>configurable_apis>hajj_journey>timestamp',
    'all>configurable_apis>dhikrs>timestamp',
    'all>configurable_apis>premium_skus_list>timestamp',
    'all>configurable_apis>hajj_and_umrah>timestamp',
    'all>configurable_apis>polls>timestamp',
    'all>configurable_apis>translations>timestamp',
    'all>configurable_apis>zakat>timestamp',
    'all>configurable_apis>backgrounds>timestamp',
    'all>configurable_apis>hijri>timestamp',
    'all>configurable_apis>stickers>timestamp',
    'all>configurable_apis>didyouknow>timestamp',
    'all>configurable_apis>quran_get_update_url>timestamp',
    'all>configurable_apis>tnc_pp>timestamp',
    'all>configurable_apis>terms_and_privacy>timestamp',
    'all>configurable_apis>achievements_list>timestamp',
    'android>timestamp',
    'ios>timestamp',
    'wp>timestamp'
  ],
  'dua_recitations': ['timestamp'],
  'didyouknow': ['timestamp'],
  'testimonials': null,
  'terms_and_privacy': null,
  'recitations': null,
  'adhans': null,
  'translations': null,
  'autosettings': null,
  'settings_legacy': null,
  'quran_quotes': null,
  'dhikrs': null,
};

export {
  fileNameRegex,
  SettingsFiles,
  keyLocationRegex,
  SettingsFileUpdateTypes,
  SettingsFilesTSLocations,
  SettingsFileOperationTypes,
  SettingsTSFileOperationTypes,
};
