import _ from 'lodash';
import React,
{
  Component,
} from 'react';
import Header from '../../widgets/header';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import axios from 'axios';
import './style.css';
import {
  Menu,
} from '../../menu';
import {
  RoleManager,
} from '../../services/roleManager';
import {
  getTestEmails,
  getModerators,
  getDataModerators,
} from '../../services/admin-db-service';
import {
  getUser,
} from '../../services/mp-cf-api-service';
import {
  WHAT_IS_MY_IP_API,
} from '../../Constants';

let roleManager;

class BasicLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      authenticating: null,
      pageRenderingPermission: null,
      usersPermission: null
    };
    roleManager = new RoleManager(firebase);
  }

  storeIPAddress() {
    axios.get(WHAT_IS_MY_IP_API).then((res) => {
      localStorage.setItem('ip_address', res.data);
    });
  }

  componentWillMount() {
    this.storeIPAddress();
    this.setState({
      authenticating: true
    });

    firebase.auth().onAuthStateChanged((user) => {
      this.setState({
        user,
        authenticating: false
      });
      firebase.auth().currentUser.getIdToken(false).then((idToken) => {
        localStorage.setItem('idToken', idToken);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('uid', user.uid);
        this.setPageRenderingPermission(user.uid);
        this.fetchModeratorsInfo();
      }).catch(function(error) {
        localStorage.removeItem("idToken");
        localStorage.removeItem("uid");
        localStorage.removeItem("user");
        localStorage.removeItem("userPermission");
      });
    });

    firebase.auth().onIdTokenChanged((user) => {
      if (user) {
        // User is signed in or token was refreshed.
        console.log('User assign token were refreshed!');
        firebase.auth().currentUser.getIdToken(false).then((idToken) => {
          console.log('Updating refreshed idToken');
          localStorage.setItem('idToken', idToken);
        });
      }
    });
  }

  fetchModeratorsInfo() {
    if(!localStorage.getItem('moderatorsInfo') || localStorage.getItem('moderatorsInfo')  === "{}") {
      getModerators().then((moderatorsObj) => {
        const moderatorsUids = Object.keys(moderatorsObj);
        let mods = {};
        let promises = moderatorsUids.map((uid) => {
          return getUser(uid)
          .then((res) => {
            console.log('A getUser : ', res);
            const profile = res.data;
            const { displayName, email } = profile;
            mods[profile.uid] = {
              displayName, email
            };
            return Promise.resolve();
          })
          .catch((err) => {
            console.log(err.message);
            return Promise.resolve();
          });
        });
        return Promise.all(promises).then(() => {
          localStorage.setItem('moderatorsInfo', JSON.stringify(mods))
        });
      });
    }

    if(!localStorage.getItem('dataModerators') || localStorage.getItem('dataModerators')  === "{}") {
      getDataModerators().then((moderatorsObj) => {
        const moderatorsUids = _.keys(_.pickBy(moderatorsObj, _.identity));
        let mods = {};
        let promises = moderatorsUids.map((uid) => {
          return getUser(uid)
          .then((res) => {
            console.log('B getUser : ', res);
            const profile = res.data;
            const { displayName, email } = profile;
            mods[profile.uid] = {
              displayName, email, uid: profile.uid,
            };
            return Promise.resolve();
          })
          .catch((err) => {
            console.log(err.message);
            return Promise.resolve();
          });
        });
        return Promise.all(promises).then(() => {
          localStorage.setItem('dataModerators', JSON.stringify(mods))
        });
      });
    }
    if(!localStorage.getItem('testEmails') || localStorage.getItem('testEmail') === "[]") {
      getTestEmails().then((testEmails) => {
        localStorage.setItem('testEmails', JSON.stringify(testEmails))
      });
    }
  }

  setPageRenderingPermission(uid) {
    roleManager.getPageRenderingPermission(uid, this.props.pagePermission).then((permObj) => {
      this.setState({
        pageRenderingPermission: permObj.pageRenderingPermission,
        usersPermission: permObj.usersPermission
      });
    });
  }

  render() {
    const {
      authenticating, user, pageRenderingPermission, usersPermission,
    } = this.state;
    const {
      alertMessage, alertType, children,
    } = this.props;

    return (
      <div>
        <Header
          alertMessage={alertMessage}
          alertType={alertType}
          user={user}
        />
        <Menu
          authenticating={authenticating}
          user={user}
          pageRenderingPermission={pageRenderingPermission}
          usersPermission={usersPermission}
          layoutComponent={children}
        />
      </div>
    );
  }
}

export default BasicLayout;
