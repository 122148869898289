import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  hijriGetFileVersions,
} from '../../services/mp-service-v1-api-service';

const fetchSettingsFileWithVersions = () => {
  const [ hijriFileVersionsData, setHijriFileVersionsData ] = useState({ });
  const [ fetchData, setFetchData ] = useState(null);
  const {
    onOperationComplete,
  } = fetchData || {};

  const fetchHijriFileVersions = async () => {
    try {
      console.log(`Fetching file versions...`);
      setHijriFileVersionsData({
        isFetching: true,
        isError: false,
      });
      const getFileVersionsApiResp =  await hijriGetFileVersions() ;

      const responseData = getFileVersionsApiResp.data;
      let errorMsg = null;
      if(responseData.success) {
        console.log(`Fetching file versions Success!!`);
      } else {
        console.log(`Fetching file versions Failed!!`);
        errorMsg = `Error occurred while fetching file versions.`;
      }

      setHijriFileVersionsData({
        data: responseData.data,
        isFetching: false,
        isError: !responseData.success,
        errorMsg,
      });
      onOperationComplete({
        isError: !responseData.success,
        errorMsg,
      });
    } catch (err) {
      console.log(`Error while fetching file versions. Error : ${err}`);
      const errorMsg = (err.response && err.response.status === 404) ?
          `File not found!` : `Error occurred while fetching file  versions.`;
      setHijriFileVersionsData({
        data: null,
        isFetching: false,
        isError: true,
        errorMsg,
      });
      onOperationComplete({
        isError: true,
        errorMsg,
      });
    }
  };

  useEffect( () => {
    if(!_.isEmpty(fetchData)) {
      fetchHijriFileVersions();
    }
  }, [ fetchData ]);

  return [
    hijriFileVersionsData,
    setFetchData,
  ];
};

export default fetchSettingsFileWithVersions;
