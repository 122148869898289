import React from "react";
import QalboxImageUploader from "../../media/helperComponents/qalboxImageUploader";

const ShortMediaImageBox = ({ thumbnail_image, first_frame, handleInputTextChange }) => {
  return (
    <div className="form-group">
      <label>Thumbnail Images</label>
      <QalboxImageUploader
        name={"thumbnail_image.small"}
        value={thumbnail_image ? thumbnail_image.small : ''}
        handleInputTextChange={handleInputTextChange}
      />
      <QalboxImageUploader
        name={"thumbnail_image.large"}
        value={thumbnail_image ? thumbnail_image.large : ''}
        handleInputTextChange={handleInputTextChange}
      />
      <label>First Frame</label>
      <QalboxImageUploader
        name={"first_frame"}
        value={first_frame}
        handleInputTextChange={handleInputTextChange}
      />
    </div>
  );
};

export default ShortMediaImageBox;
