import axiosService from "../common/axios-service";


class DailyDeenService {
  updateDailyDeenContent = ( dd_array ) => {
    return axiosService.post("DailyDeen-adminConsole-UpdateContent", dd_array).then( response => {
      if( response.status === 200 ) {
        const data = response.data;
        if( data && (data.success && data.status === 'success') ) {
          return data.payload;
        }else{
          console.log('updateDailyDeenContent', 'error',  data.error )
          throw new Error( data.error )
        }
      }else{
        console.log('updateDailyDeenContent', response.status )
        throw new Error( "updateDailyDeenContent error" )
      }
    }).catch( error => {
      console.log('updateDailyDeenContent', error )
      throw new Error( error )
    })
  }

  updateDailyDeenThemesList = (themes_list) => {
    console.log("API CALL THEMES LIST UPDATE");
    return axiosService.post("DailyDeen-adminConsole-UpdateThemesList", themes_list).then( response => {
      if( response.status === 200 ) {
        const data = response.data;
        if( data && (data.success && data.status === 'success') ) {
          return data.payload;
        }else{
          console.log('updateDailyDeenThemesList', 'error',  data.error )
          throw new Error( data.error )
        }
      }else{
        console.log('updateDailyDeenThemesList', response.status )
        throw new Error( "updateDailyDeenThemesList error" )
      }
    }).catch( error => {
      console.log('updateDailyDeenThemesList', error )
      throw new Error( error )
    })
  }
  getDailyDeenList = () => {
    return axiosService.post("DailyDeen-adminConsole-GetList", {
      lang: "en"
    }).then( response => {
      if( response.status === 200 ) {
        const data = response.data;
        if( data && (data.success && data.status === 'success') ) {
          return data.payload || [];
        }else{
          console.log('getDailyDeenList', 'error',  data.error )
          throw new Error( data.error )
        }
      }else{
        console.log('getDailyDeenList', response.status )
        throw new Error( "getDailyDeenList error" )
      }
    }).catch( error => {
      console.log('getDailyDeenList', error )
      throw new Error( error )
    })
  }

  getDailyDeenThemesList = ( lang ) => {
    return axiosService.post("DailyDeen-adminConsole-GetThemesList", {
      lang: lang || 'en'
    }).then( response => {
      if( response.status === 200 ) {
        const data = response.data;
        if( data && (data.success && data.status === 'success') ) {
          return data.payload;
        }else{
          console.log('getDailyDeenThemesList', 'error',  data.error )
          throw new Error( data.error )
        }
      }else{
        console.log('getDailyDeenThemesList', response.status )
        throw new Error( "getDailyDeenThemesList error" )
      }
    }).catch( error => {
      console.log('getDailyDeenThemesList', error )
      throw new Error( error )
    })
  }

  deleteDailyDeenById = ( id ) => {
    return axiosService.post("DailyDeen-adminConsole-DeleteById", {
      key: id
    }).then( response => {
      if( response.status === 200 ) {
        const data = response.data;
        if( data && (data.success && data.status === 'success') ) {
          return data.payload;
        }else{
          console.log('deleteDailyDeenById', 'error',  data.error )
          throw new Error( data.error )
        }
      }else{
        console.log('deleteDailyDeenById', response.status )
        throw new Error( "deleteDailyDeenById error" )
      }
    }).catch( error => {
      console.log('deleteDailyDeenById', error )
      throw new Error( error )
    })
  }

}

export default new DailyDeenService();