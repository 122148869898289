import _ from 'lodash';
import React,
{
  Fragment,
  Component,
} from 'react';
import BasicLayout from '../../layouts/basiclayout';
import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import {
  Icon,
} from 'react-fa';
import ToggleButton from 'react-toggle-button';
import {
  countries,
} from '../../services/utils';
import Select from 'react-select';
import ReactTags from 'react-tag-autocomplete';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import './style.css';
import moment from "moment-timezone";
import {
  isValidHashTag,
  getYearAndDayCodes,
} from './helper';
import {
  updateV2Content,
  updateV2HashTags,
  getV2ContentData,
  suggestV2HashTags,
  getContentMetaData,
} from '../../services/mp-cf-api-service';
import {
  getAdUnits,
  fetchAdUnit,
  updateAdUnit,
} from '../../services/admin-db-service';
import {
  FEATURED_TYPE,
} from './Constants';
import FileUploader from '../../widgets/fileUploader';
import {
  uploadNewImage,
} from '../../services/api-v1-services/image-services';
import {
  getCdnPublicFileUrl,
} from '../../utils';
import {
  CONTENT_TYPE,
  AUDIENCE_TYPE,
  ContentSupportedLanguages,
  ENGLISH_CHARACTERS_SUPPORTED_LANGUAGES,
} from './Constants';

const adPermittedURLs = [
  'muslimpro.com',
];


const SupportedLanguages = _.without(_.keys(ContentSupportedLanguages), 'all');

const countryOptions = Object.keys(countries).map((countryCode) => {
  return {
    value: countryCode,
    label: countries[countryCode]
  }
});

const contentTypes = Object.keys(CONTENT_TYPE).map((type) => {
  return {
    key: CONTENT_TYPE[type],
    value: CONTENT_TYPE[type]
  }
});

const audienceTypes = Object.keys(AUDIENCE_TYPE).map((audienceType) => {
  return {
    value: AUDIENCE_TYPE[audienceType],
    label: audienceType
  }
});

const featuredTypes = Object.keys(FEATURED_TYPE).map((featuredType) => {
  return {
    value: FEATURED_TYPE[featuredType],
    label: featuredType
  }
});

delete ContentSupportedLanguages["all"];
const languages = Object.keys(ContentSupportedLanguages).map((languageCode) => {
  return {
    key: languageCode,
    value: ContentSupportedLanguages[languageCode]
  }
});

function curateLanguageCode(language_code) {
  if(!language_code || language_code.trim() === "" || SupportedLanguages.indexOf(language_code) === -1) {
    return "en";
  } else {
    return language_code;
  }
}

function getDataFromMeta(metaKey, el) {
  const metaName = el.attr('name');
  const metaProp = el.attr('property');
  if(metaName === `og:${metaKey}` || metaName === `twitter:${metaKey}` || metaProp === `og:${metaKey}` || metaProp === `twitter:${metaKey}`) {
    return el.attr('content');
  }
}

function showAds(url) {
  let domain, found = false,
      url_temp = url.split('//');
  if(url_temp.length === 1) {
    domain = (url_temp[0].split('/'))[0];
  } else {
    domain = (url_temp[1].split('/'))[0];
  }
  for(let i=0; i<adPermittedURLs.length; i++) {
    if(domain.endsWith(adPermittedURLs[i])) {
      found = true;
      break;
    }
  }
  return found;
}

// function warning(msg) {
//   toast.warning(msg, {
//     position: toast.POSITION.BOTTOM_RIGHT
//   });
// }

function getHashTags(hashTags) {
  if(_.isEmpty(hashTags)) {
    return [];
  }

  return hashTags.map(hashTag => { return { name: hashTag }});
}


class ContentEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {
        urls: [],
        thumbnail_url: null,
        title: null,
        summary: null,
        source: null,
        language_code: null,
        content_type: null,
        category_id: null,
        publish_date: '',
        article_date: '',
        featured_start_date: '',
        featured_end_date: '',
        pinned_start_date: '',
        pinned_end_date: '',
        video_id: null,
        show_ads: false,
        is_pinned: false,
        is_featured: false,
        is_hidden: false,
        for_ramadan: false,
        audiences: ["all"],
        featured_category: 'default',
        ad_units: [],
        hash_tags: [],
        external_url: '',
      },
      moderators: localStorage.getItem('moderatorsInfo') ? JSON.parse(localStorage.getItem('moderatorsInfo')) : null,
      submitting: false,
      adUnitTypes: null,
      previous_ad_unit_id: null,
      prefillingStatue: null,
      categories: [],
      selectedCategories: "default",
      showVideoIdField: false,
      hash_tags_suggestions: [],
      uploadedFile: null,
    };
  }

  getAllHashTags (data) {
    suggestV2HashTags(data)
        .then(res => {
          const hashTags = res.data.hash_tags;
          this.setState({
            hash_tags_suggestions: getHashTags(Array.from(hashTags))
          })
        }).catch(err => {
      console.log('Something went wrong while fetching the hashtag suggestions. Error : ', err);
    });
  }

  getAllAdUnits({ currentAdUnitId=null }) {
    getAdUnits({ environment: 'Production'})
        .then(adUnits => {
          let ad_units = null;
          const adUnitTypes = Object.keys(adUnits).map((adUnitId) => {
            const adUnit = adUnits[adUnitId];

            if(currentAdUnitId && currentAdUnitId === adUnitId) {
              ad_units = [{
                value: adUnit.id,
                label: `${adUnit.name} - ${adUnit.id}`,
                id: adUnit.id,
              }];
            }

            return {
              value: adUnit.id,
              label: `${adUnit.name} - ${adUnit.id}`,
              id: adUnit.id,
            }
          });

          if(ad_units) {
            let {  content } = this.state;
            content['ad_units'] = ad_units;
            this.setState({
              adUnitTypes,
              content,
            })
          } else {
            this.setState({
              adUnitTypes,
            })
          }
        }).catch(err => {
      console.log('Something went wrong while fetching the ad units. Error : ', err);
    });
  }

  componentWillMount() {
    const query = this.props.match.params.query;
    if (query) {
      this.updateContentData(query);
    }
  }

  updateContentData(contentId) {
    return getV2ContentData({
      content_id: contentId,
    })
    .then((res) => {
      const response = res.data;
      if(response.result === "success") {
        const contentData = response.content;
        this.setState({
          content: this.getEditContentData(contentData),
          previous_ad_unit_id: contentData['ad_unit_id'],
          // categories: this.fetchCategories(contentData.language_code)
        });
        this.getAllHashTags({ language_code: contentData.language_code });
        this.getAllAdUnits({ currentAdUnitId : contentData['ad_unit_id'] });
      } else {
        return null
      }
    });
  }

  getEditContentData(data) {
    return {
      ...data,
      hash_tags: this.getHashTags(data.hash_tags)
    }
  }

  getHashTags(currentTags) {
    return currentTags.map((hashtag, index) => {
      return { id: index + 1, name: hashtag }
    })
  }

  renderSelect(arr, selectKey, defaultVal = "default") {
    const options = arr.map((item, i) => {
      return (
          <option value={item.key} key={i}>{item.value}</option>
      );
    });

    return (
        <select value={defaultVal}
                defaultValue="default"
                onChange={this.onSelectChange.bind(this, selectKey)}
                className="form-control">
          <option disabled value="default">Please select one</option>
          {options}
        </select>
    );

  }

  getValueByKey(key, arr) {
    let res;
    for(let i=0; i<arr.length; i++) {
      if(arr[i].key === key) {
        res = arr[i].value;
        break;
      }
    }
    return res;
  }

  onSelectChange(selectKey, e) {
    let { content, categories, selectedCategories } = this.state;
    content[selectKey] = e.target.value;
    if(selectKey === 'category_id') {
      content['category'] = this.getValueByKey(e.target.value, categories);
      selectedCategories = e.target.value;
    }

    if(selectKey === 'language_code') {
      // this.fetchAndSetCategories(e.target.value);
      this.getAllHashTags({ language_code : e.target.value});
    }

    if(selectKey === 'content_type') {
      if(e.target.value === 'video') {
        this.setState({ showVideoIdField: true });
      } else {
        this.setState({ showVideoIdField: false });
      }
    }

    if(selectKey === 'content_type') {
      content.thumbnail_url = content.urls[0];
    }

    this.setState({ content, selectedCategories });
  }

  // fetchCategories(language_code) {
  //   let locale = require(`../../locales/${curateLanguageCode(language_code)}`);
  //   const cats = locale.content.categories;
  //   const catKeys = Object.keys(cats);
  //   let categories = [];
  //   catKeys.forEach((key) => {
  //     categories.push({
  //       key,
  //       value: cats[key]
  //     });
  //   });
  //
  //   return categories;
  // }

  // fetchAndSetCategories(language_code) {
  //   const categories = this.fetchCategories(language_code);
  //   this.setState({
  //     categories,
  //     selectedCategories: "default"
  //   });
  // }

  getLocaleDate(date) {
    if(date && date._seconds) {
      return new Date(date._seconds*1000);
    }

    return date ? new Date(date) : null;
  }

  getContentTobeCreated(content) {
    const updatedContent = {
      ...content
    };

    updatedContent['article_date'] = this.getLocaleDate(content.article_date);
    updatedContent['publish_date'] = this.getLocaleDate(content.publish_date);
    updatedContent['pinned_start_date'] = this.getLocaleDate(content.pinned_start_date);
    updatedContent['pinned_end_date'] = this.getLocaleDate(content.pinned_end_date);
    updatedContent['featured_start_date'] = this.getLocaleDate(content.featured_start_date);
    updatedContent['featured_end_date'] = this.getLocaleDate(content.featured_end_date);
    updatedContent['updated_date'] = new Date();
    updatedContent['updated_by'] = localStorage.getItem('uid');
    updatedContent['hash_tags'] = _.map(content.hash_tags, 'name');

    // Ad units
    updatedContent['ad_unit_id'] = _.isEmpty(content.ad_units) ? '' : content.ad_units[0].id;
    delete updatedContent['ad_units'];

    // Featured Type
    if(content.is_featured) {
      if(!content.featured_category) {
        updatedContent['featured_category'] = FEATURED_TYPE.DEFAULT;
      } else {
        updatedContent['featured_category'] = content['featured_category'];
      }
    } else {
      delete updatedContent['featured_category'];
    }

    // Audiences
    if(_.isEmpty(updatedContent['audiences'])) {
      updatedContent['audiences'] = ['all']
    }

    if(updatedContent['content_type'] === 'quote') {
      // Consider updating the image url.
      const { yearCode, dayCode } = getYearAndDayCodes(updatedContent['publish_date']);

      updatedContent['year_code'] = yearCode;
      updatedContent['day_code'] = dayCode;
    } else {
      delete updatedContent['for_ramadan']
    }

    return _.pickBy(updatedContent, (v) => !(_.isNil(v) || v === ''));
  }

  updateImageDetails(content, imageUrl) {
    content['urls'] = [imageUrl];
    content['thumbnail_url'] = imageUrl;
  }

  async onAddFormSubmit(e) {
    e.preventDefault();
    this.setState({ submitting: true });
    const { content, previous_ad_unit_id, uploadedFile } = this.state;

    try {
      let newImagelUrl = null;
      if(uploadedFile && content.content_type === 'image') {
        const imageUploadResp = await uploadNewImage(uploadedFile, {
          optimizeImages: true,
          fileUploadPath: `/images/contentv2/image/${content.language_code}/${uploadedFile.name}`,
        });
        const imageUploadRespData = imageUploadResp['data'];
        if(imageUploadRespData && imageUploadRespData.success) {
          const urlList = imageUploadRespData['data'];
          const imagePublicUrl = _.map(urlList, 'public_url')[0];
          newImagelUrl = getCdnPublicFileUrl(imagePublicUrl);
          if(newImagelUrl) {
            this.updateImageDetails(content, newImagelUrl);
          }
        } else {
          console.log(`Image upload failed. ${JSON.stringify(imageUploadResp)}`);
          toast.error('Something went wrong while uploading the image.', {
            position: toast.POSITION.BOTTOM_LEFT
          });
          return;
        }
      }

      const updatedContentToPush = this.getContentTobeCreated(content);
      console.log('Updated content : ', JSON.stringify(updatedContentToPush));

      if(this.validate(content)) {
        const updateContentResp = await updateV2Content({
          content: updatedContentToPush,
        });
        console.log('Content updated successfully. Response ', updateContentResp);
        const updatedContent = {
          id: content.id,
          hash_tags: updatedContentToPush.hash_tags,
          publish_date: updatedContentToPush.publish_date,
          language_code: updatedContentToPush.language_code,
          ad_unit_id: updatedContentToPush.ad_unit_id,
        };

        if (!_.isEmpty(updatedContent.hash_tags)) {
          let hashTagUpdateResp = null;
          if(content.is_hidden) {
            // Remove hashtags
            hashTagUpdateResp = await updateV2HashTags({
              contents: [updatedContent],
              operation: 'remove',
            });
          } else {
            // Add hashtags
            hashTagUpdateResp = await  updateV2HashTags({
              contents: [updatedContent],
              operation: 'update',
            });
          }

          console.log(`Hashtags updated successfully. Res ${JSON.stringify(hashTagUpdateResp)}`);
        }

        if (updatedContent['ad_unit_id']) {
          console.log('Ad unit found.');
          if(previous_ad_unit_id !== updatedContent['ad_unit_id']) {
            console.log('Ad unit are different.');
            // Ad unit are different.
            if(previous_ad_unit_id) {
              console.log('There was a previous id, removing it.');
              // There was a previous id, removing it.
              let previousAdUnit = await fetchAdUnit({
                environment:'Production', id: previous_ad_unit_id,
              });
              previousAdUnit['content_ids'] = _.filter(previousAdUnit['content_ids'],
                  id => id !== updatedContent['id']);
              const previousAdUnitUpdateResp = await updateAdUnit({
                environment:'Production',
                ...previousAdUnit,
              });
              console.log(`Previous Ad unit updated successfully. Res ${JSON.stringify(previousAdUnitUpdateResp)}`);

              if(updatedContent['ad_unit_id']) {
                let nextAdUnit = await fetchAdUnit({
                  environment:'Production', id: updatedContent['ad_unit_id'],
                });
                if(_.isEmpty(nextAdUnit['content_ids'])) {
                  nextAdUnit['content_ids'] = [updatedContent['id']];
                } else {
                  nextAdUnit['content_ids'].push(updatedContent['id']);
                }
                const nextAdUnitUpdateResp = await updateAdUnit({
                  environment:'Production',
                  ...nextAdUnit,
                });
                console.log(`Next Ad unit updated successfully. Res ${JSON.stringify(nextAdUnitUpdateResp)}`);
              }
            } else {
              // New Ad Unit
              console.log('There was no previous id, adding the new ad unit.');
              let currentAdUnit = await fetchAdUnit({
                environment:'Production', id: updatedContent['ad_unit_id'],
              });
              if(_.isEmpty(currentAdUnit['content_ids'])) {
                currentAdUnit['content_ids'] = [updatedContent['id']];
              } else {
                currentAdUnit['content_ids'].push(updatedContent['id']);
              }
              const adUnitUpdateResp = await updateAdUnit({
                environment:'Production',
                ...currentAdUnit,
              });
              console.log(`Ad unit updated successfully. Res ${JSON.stringify(adUnitUpdateResp)}`);
            }
          } else {
            // Ad unit are same do nothing.
            console.log('Ad unit are same do nothing.');
          }
        } else {
          console.log('Ad unit not found.');
          if(previous_ad_unit_id) {
            console.log('There was a previous ad unit, hence removing it.');
            // There was a previous ad unit, hence removing it.
            let currentAdUnit = await fetchAdUnit({
              environment:'Production', id: previous_ad_unit_id,
            });
            console.log('currentAdUnit', currentAdUnit);
            if(_.isEmpty(currentAdUnit['content_ids'])) {
              // Wired can't be empty.
            } else {
              currentAdUnit['content_ids'] = _.filter(currentAdUnit['content_ids'],
                  id => id !== updatedContent['id']);
            }

            if(currentAdUnit) {
              const adUnitUpdateResp = await updateAdUnit({
                environment:'Production',
                ...currentAdUnit,
              });
              console.log(`Ad unit updated successfully. Res ${JSON.stringify(adUnitUpdateResp)}`);
            }
          }
        }

        this.setState({ submitting: false });
        toast.success('Content updated successfully!', {
          position: toast.POSITION.BOTTOM_LEFT
        });
        setTimeout(()=> {
          window.location.reload();
        }, 1000);
      } else {
        this.setState({ submitting: false });
        console.log("Has error");
      }

    } catch (err) {
      console.log('Error while updating the content : ', err);
      this.setState({ submitting: false });
      toast.error('Something went wrong while updating the content', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  }

  clearForm() {
    const content = {
      series_id: '',
      urls: [],
      thumbnail_url: "",
      title: "",
      summary: "",
      source: "",
      video_id: "",
      language_code: "default",
      content_type: "default",
      category_id: "default",
      is_pinned: false,
      is_featured: false,
      is_hidden: false,
      for_ramadan: false,
      audiences: ["all"],
      featured_category: 'default',
      publish_date: '',
      article_date: '',
      featured_start_date: '',
      featured_end_date: '',
      pinned_start_date: '',
      pinned_end_date: '',
      hash_tags: [],
      countries: [],
      ad_units: [],
      external_url: '',
      uploadedFile: null,
    };
    this.setState({
      content,
      selectedCategories: "default",
      previous_ad_unit_id: null,
      prefillingStatue: null,
      showVideoIdField: false
    });
  }

  validate(content) {
    let errFound = false;
    Object.keys(content).forEach((key) => {
      const item = content[key];
      if(key === 'publish_date' && !item) {
        alert("Invalid publish date");
        errFound = true;
      }
      else if(key === 'is_pinned' && item && !content['pinned_end_date']) {
        alert("You must set pinned end date since the content is going to be pinned!");
        errFound = true;
      }
      // This condition is mainkly for skipping the empty params
      else if(key === 'show_ads' || key === 'article_date' || key === 'video_id' || key === 'summary') {
        // Skipping and removing from the content
        if(!item || item === "") {
          delete content[key];
          this.setState({content});
        }
      } else {
        if(!(key === "external_url" || key === "is_featured" || key === "is_hidden"
            || key === "is_pinned" || key === "for_ramadan" || key === "series_id"
            || key === "featured_end_date" || key === "featured_start_date"
            || key === "pinned_end_date" || key === "pinned_start_date" || key === "category_id")) {
          if(!item || (typeof(item) === 'string' && item.trim() === "")) {
            alert(`Invalid ${key}`);
            errFound = true;
          }
        }
      }
    });
    return !errFound;
  }

  onFieldChange(fieldKey, e) {
    let { content } = this.state;
    content[fieldKey] = e.target.value;
    if(fieldKey === 'publish_date'
        || fieldKey === 'article_date'
        || fieldKey === 'featured_start_date'
        || fieldKey === 'featured_end_date'
        || fieldKey === 'pinned_start_date'
        || fieldKey === 'pinned_end_date'
    ) {
      content[fieldKey] = e.target.value;
    }

    if(fieldKey === 'series_id') {
      content[fieldKey] = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');
    }

    this.setState({content});
    if(fieldKey === 'urls') {
      this.prefillContentMeta(e.target.value);
      let contentInner = this.state.content;
      contentInner['show_ads'] = showAds(e.target.value);
      this.setState({ content: contentInner });
    }
  }

  onCountriesChange(countries) {
    let { content } = this.state;
    if(countries.length === 0) {
      content["countries"] = [];
    } else {
      let countryCodes = [];
      countries.forEach((countryCode) => {
        countryCodes.push(countryCode.value);
      });
      content["countries"] = countryCodes;
    }
    this.setState({
      content
    });
  }

  onAudiencesChange(selectedAudiences) {
    let { content } = this.state;

    if(selectedAudiences.length === 0) {
      content["audiences"] = ["all"]
    } else {
      let newAudiences = [];

      selectedAudiences.forEach((selectedAudience) => {
        newAudiences.push(selectedAudience.value);
      });

      content["audiences"] = newAudiences;
    }

    this.setState({
      content
    });
  }

  onUploadFile(event) {
    const file = event.target.files[0];
    this.setState({ uploadedFile: file });
  }
  onRemoveFile() {
    this.setState({ uploadedFile: null });
  }

  async onAdUnitsChange(selectedAdUnits) {
    let { content } = this.state;

    if(!selectedAdUnits || selectedAdUnits.length === 0) {
      content["ad_units"] = []
    } else {
      // Enable this if in future decide to have multiple units + Select isMulti should be true.
      // let newAdUnits = [];
      //
      // selectedAdUnits.forEach((selectedAdUnit) => {
      //   newAdUnits.push({ value: selectedAdUnit.value, label: selectedAdUnit.label});
      // });
      // content["ad_units"] = newAdUnits;
      let currentAdUnit = await fetchAdUnit({ environment:'Production', id: selectedAdUnits.id  });
      if(_.isEmpty(currentAdUnit['content_ids'])) {
        currentAdUnit['content_ids'] = [selectedAdUnits.id];
      } else {
        currentAdUnit['content_ids'].push(selectedAdUnits.id);
      }
      console.log('currentAdUnit', currentAdUnit);

      content["ad_units"] = [selectedAdUnits];
    }

    this.setState({
      content
    });
  }

  onFeaturedTypeChange(selectedFeaturedType) {
    let { content } = this.state;

    content['featured_category'] = selectedFeaturedType.value;

    this.setState({
      content
    });
  }

  getLangFromHtml(htmlString) {
    // fetching lang from html tag
    const htmlRegex = /(<html.*?>)/;
    let htmlStartFound = htmlString.match(htmlRegex);
    let crawledHtmlTag = (htmlStartFound && htmlStartFound[0]) || "";
    // fetched <html all attrs >
    const langRegex = /(lang=".*?")/;
    let langFound = crawledHtmlTag.match(langRegex) ? crawledHtmlTag.match(langRegex)[0] : "";
    const codeRegex = /(".*?")/;
    let codeFound = langFound.match(codeRegex);
    let code = (codeFound && codeFound[0]) || "";
    code = code ? code.split('"').join('') : code;
    code = code.slice(0, 2);
    return code;
  }

  prefillContentMeta(url) {
    let { content } = this.state;
    delete content['video_id'];
    this.setState({content, prefillingStatue: 0, showVideoIdField: false});
    if(content['content_type'] === 'default' ) {
      content['content_type'] = "article";
    }

    // analyszing the url for youtube
    let url_temp = url;
    let domain;
    url_temp = url_temp.split('//');
    if (url_temp.length === 1) {
      //no protocol - no http
      url = 'http://' + url;
      domain = (url_temp[0].split('/'))[0];
    } else {
      domain = (url_temp[1].split('/'))[0];
    }
    if(domain.endsWith('youtube.com') || domain.endsWith('youtu.be')) {
      if(content['content_type'] === 'default' ) {
        content['content_type'] = "video";
      }

      this.setState({ content });
      let video_id = this.fetchVideoId(url);
      if (video_id != null) {
        url = 'https://youtube.com/watch?v=' + video_id;
      }
    }
    // fetching meta data from url
    getContentMetaData({url}).then((res) => {
      const htmlString = res.data;
      const fetchedLC = this.getLangFromHtml(htmlString);
      if(fetchedLC !== "") {
        content['language_code'] = curateLanguageCode(fetchedLC);
        // this.fetchAndSetCategories(fetchedLC);
      }
      // fetching meta
      const jQ = window.jQuery;
      const crawledHTML = jQ.parseHTML(htmlString);
      let contentThumbnail, contentTitle, contentDescription, contentSource;
      crawledHTML.forEach((token) => {
        if(jQ(token).is('meta')) {
          if(!contentThumbnail){ contentThumbnail = getDataFromMeta('image', jQ(token)); }
          if(!contentTitle){ contentTitle = getDataFromMeta('title', jQ(token)); }
          if(!contentDescription){ contentDescription = getDataFromMeta('description', jQ(token)); }
          if(!contentSource){ contentSource = getDataFromMeta('site_name', jQ(token)); }
        }
      });
      let prefillingStatue = 1;
      if(!contentThumbnail && !contentTitle && !contentDescription && !contentSource) {
        prefillingStatue = -1;
      }
      content.urls = [url];
      content.thumbnail_url = contentThumbnail;
      content.title = contentTitle;
      content.summary = contentDescription;
      content.source = contentSource;
      this.setState({content, prefillingStatue});
    });
  }

  // Will be fired only if the url is youtubes
  fetchVideoId(url) {
    const re=/[/=]([a-zA-Z0-9\-_]{11})[&/]?/;
    const found = url.match(re);
    let { content } = this.state;
    if(found != null && found.length === 2) {
      content.video_id = found[1];
      this.setState({content, showVideoIdField: true});
      return found[1];
    } else {
      delete content['video_id'];
      this.setState({content, showVideoIdField: false});
      return null;
    }
  }

  blockSubmission(language) {
    if(!language || SupportedLanguages.indexOf(language) === -1) {
      return true;
    } else {
      return false;
    }
  }

  renderSubmitButton(blockSubmission) {
    const { submitting } = this.state;
    if(submitting) {
      return (
          <Button disabled={true} className="btn-primary content_editor_submit_btn">Submitting...</Button>
      );
    } else {
      return (
          <Button disabled={blockSubmission} type="submit" className="btn-primary content_editor_submit_btn">Update</Button>
      );
    }
  }

  renderPrefillStatus(prefillingStatue) {
    if(prefillingStatue === null) {
      return null;
    } else if(prefillingStatue === 0) {
      return <p className="small text-muted"><Icon spin name="spinner" /> Trying to prefill</p>;
    } else if(prefillingStatue === 1) {
      return <p className="small text-success"><Icon name="check-circle" /> Prefilling successful</p>;
    } else if(prefillingStatue === -1) {
      return <p className="small text-danger"><Icon name="exclamation-circle" /> Prefilling failed</p>;
    }
  }

  renderVideoIdField(showVideoIdField, video_id) {
    if(showVideoIdField) {
      return <div className="form-group">
        <label>Video Id</label>
        <input value={video_id} onChange={this.onFieldChange.bind(this, 'video_id')} type="text" className="form-control" placeholder="Video Id"/>
      </div>
    } else {
      return null;
    }
  }

  onShowAdsToggle() {
    let { content } = this.state;
    content.show_ads = !content.show_ads;
    this.setState({
      content
    });
  }

  onIsPinnedToggle(isPinned) {
    const confirm = isPinned || window.confirm("Do you really want to pin this post?");
    let { content } = this.state;
    if(confirm) {
      content.is_pinned = !content.is_pinned;
      if(isPinned) {
        delete content['pinned_start_date'];
        delete content['pinned_end_date'];
      }

      this.setState({
        content,
      });
    }
  }

  onIsFeaturedToggle(isFeatured) {
    let { content } = this.state;
    content.is_featured = !content.is_featured;
    if(isFeatured) {
      delete content['featured_start_date'];
      delete content['featured_end_date'];
      content['featured_category'] = FEATURED_TYPE.DEFAULT;
    }

    this.setState({
      content,
    });
  }

  onIsHiddenToggle() {
    let { content } = this.state;
    content.is_hidden = !content.is_hidden;
    this.setState({
      content,
    });
  }

  onForRamadanToggle() {
    let { content } = this.state;
    content.for_ramadan = !content.for_ramadan;
    this.setState({
      content,
    });
  }

  renderPinnedDates(show, startDate, endDate) {
    if (startDate && endDate) {
      return (
      <Fragment>
        <Col md={12}>
          <div className="form-group">
            <label className="show__ads_label">Pinned start at</label>
            <input value={startDate}
                   required
                   onChange={this.onFieldChange.bind(this, 'pinned_start_date')}
                   type="datetime-local"
                   className="form-control"
                   placeholder="Pinned start date"
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="form-group">
            <label className="show__ads_label">Pinned end at</label>
            <input value={endDate}
                   required
                   onChange={this.onFieldChange.bind(this, 'pinned_end_date')}
                   type="datetime-local"
                   className="form-control"
                   placeholder="Pinned end date"
            />
          </div>
        </Col>
      </Fragment>);
    }

    if(!show) {
      return null;
    } else {
      return (
      <Fragment>
        <Col md={12}>
          <div className="form-group">
            <label className="show__ads_label">Pinned start at</label>
            <input required
                   onChange={this.onFieldChange.bind(this, 'pinned_start_date')}
                   type="datetime-local"
                   className="form-control"
                   placeholder="Pinned start date"
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="form-group">
            <label className="show__ads_label">Pinned end at</label>
            <input required
                   onChange={this.onFieldChange.bind(this, 'pinned_end_date')}
                   type="datetime-local"
                   className="form-control"
                   placeholder="Pinned end date"
            />
          </div>
        </Col>
      </Fragment>
      );
    }

  }

  renderFeatureDates(show, startDate, endDate, featured_category) {
    if (startDate && endDate) {
      return (
      <Fragment>
        <Col md={12}>
          <div className="form-group">
            <label>Featured Type</label>
            <Select onChange={this.onFeaturedTypeChange.bind(this)}
                    value={featured_category}
                    isSearchable={true}
                    options={featuredTypes}
                    isMulti={false}
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="form-group">
            <label>Featured start at</label>
            <input value={startDate}
                   required
                   onChange={this.onFieldChange.bind(this, 'featured_start_date')}
                   type="datetime-local"
                   className="form-control"
                   placeholder="Feature start date"
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="form-group">
            <label>Featured end at</label>
            <input value={endDate}
                   required
                   onChange={this.onFieldChange.bind(this, 'featured_end_date')}
                   type="datetime-local"
                   className="form-control"
                   placeholder="Feature end date"
            />
          </div>
        </Col>
      </Fragment>);
    }

    if(!show) {
      return null;
    } else {
      console.log('we have a end date');
      return (
      <Fragment>
        <Col md={12}>
          <div className="form-group">
            <label>Featured Type</label>
            <Select onChange={this.onFeaturedTypeChange.bind(this)}
                    value={featured_category}
                    isSearchable={true}
                    options={featuredTypes}
                    isMulti={false}
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="form-group">
            <label>Featured start at</label>
            <input required
                   onChange={this.onFieldChange.bind(this, 'featured_start_date')}
                   type="datetime-local"
                   className="form-control"
                   placeholder="Feature start date"
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="form-group">
            <label>Featured end at</label>
            <input required
                   onChange={this.onFieldChange.bind(this, 'featured_end_date')}
                   type="datetime-local"
                   className="form-control"
                   placeholder="Feature end date"
            />
          </div>
        </Col>
      </Fragment>);
    }
  }

  handleHashTagDelete (i) {
    const tags = this.state.content.hash_tags.slice(0);
    const removedTags = tags.splice(i, 1);
    let { content } = this.state;
    content.hash_tags = tags;
    this.setState({ content });

    const updatedContent = {
      id: content.id,
      hash_tags: _.map(removedTags, 'name'),
      publish_date: this.getLocaleDate(content.publish_date),
      language_code: content.language_code,
    };

    updateV2HashTags({ contents: [updatedContent], operation: 'remove' });
  }

  handleHashTagAddition (newTag, language_code) {
    let updatedTag;
    if(ENGLISH_CHARACTERS_SUPPORTED_LANGUAGES.includes(language_code)) {
      updatedTag = { name: newTag.name.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase()};
    } else {
      updatedTag = { name: newTag.name };
    }
    const tags = [].concat(this.state.content.hash_tags, updatedTag);
    let { content } = this.state;
    content.hash_tags = _.uniqBy(tags, 'name');
    this.setState({ content });
  }

  handleHashTagValidation (tag, language_code) {
    if(ENGLISH_CHARACTERS_SUPPORTED_LANGUAGES.includes(language_code)) {
      return tag.name.length > 2 && isValidHashTag(tag.name);
    }

    return isValidHashTag(tag.name);
  }

  getLocalTime(timestamp) {
    if (timestamp && timestamp._seconds) {
      return moment(timestamp._seconds*1000).format('YYYY-MM-DDTHH:mm');
    }

    return timestamp || '';
  }

  getSelectedCountries(countriesList) {
    if (_.isEmpty(countriesList)) {
      return [];
    }

    return countriesList.map(countryCode => {
      return {
        value: countryCode,
        label: countries[countryCode]
      }
    });
  }

  getSelectedAudiences(audiences) {
    if (_.isEmpty(audiences)) {
      return [{"value":"all","label":"ALL"}];
    }

    return audiences.map(audience => {
      return {
        value: audience,
        label: _.invert(AUDIENCE_TYPE)[audience]
      }
    });
  }

  getSelectedAdUnit(adUnitId, adUnitTypes, selectedAdUnits) {
    if (!adUnitId) {
      return selectedAdUnits;
    } else {
      if(selectedAdUnits) {
        return selectedAdUnits;
      }

      return _.find(adUnitTypes, adUnit => adUnit.id === adUnitId) || [];
    }
  }

  getSelectedFeaturedType(featured_category=FEATURED_TYPE.DEFAULT) {
    return {
      "value": featured_category,
      "label": _.invert(FEATURED_TYPE)[featured_category],
    };
  }

  render() {
    const {
      content, prefillingStatue, showVideoIdField, hash_tags_suggestions, moderators, adUnitTypes,
      uploadedFile,
    } = this.state;

    const {
      id, urls, thumbnail_url, title, summary, source, content_type, language_code,
      video_id, series_id, article_date, publish_date, featured_start_date, featured_end_date,
      pinned_start_date, pinned_end_date, countries, hash_tags, show_ads, is_pinned, is_featured,
      is_hidden, created_by, created_date, updated_by, updated_date, for_ramadan, audiences,
      ad_units, ad_unit_id, featured_category, external_url,
    } = content;

    if (!id) {
      return null;
    }

    const articleDate = this.getLocalTime(article_date);
    const publishedDate = this.getLocalTime(publish_date);
    const featuredStartDate = this.getLocalTime(featured_start_date);
    const featuredEndDate = this.getLocalTime(featured_end_date);
    const pinnedStartDate = this.getLocalTime(pinned_start_date);
    const pinnedEndDate = this.getLocalTime(pinned_end_date);
    const selectedCountries = this.getSelectedCountries(countries);
    const selectedAudiences = this.getSelectedAudiences(audiences);
    const selectedAdUnits = this.getSelectedAdUnit(ad_unit_id, adUnitTypes, ad_units);
    const selectedFeaturedType = this.getSelectedFeaturedType(featured_category);
    const createdByLink = `/search-user/${created_by}`;
    const updatedByLink = `/search-user/${updated_by}`;
    const mdSize = 3;
    // const dirPath = `images/contentv2/image/${language_code}`;
    const uploadedImageUrl = uploadedFile ? URL.createObjectURL(uploadedFile) : null;

    return (
        <BasicLayout pagePermission="content_editor">
          <ToastContainer autoClose={2000} />
          <h3 className="content_editor_title">
            Update Content -
             [<a
                target="_blank"
                href={"https://console.firebase.google.com/project/muslim-pro-app/database/firestore/data~2FContentsV2~2F"+id}
            >
              {id}
            </a> ]
          </h3>
          <Row>
            <Col md={6}>
              <form onSubmit={this.onAddFormSubmit.bind(this)}>
                <div className="form-group">
                  <label>Content Type</label>
                  {this.renderSelect(contentTypes, 'content_type', content_type)}
                </div>
                <div className="form-group">
                  <label>Audiences</label>
                  <Select onChange={this.onAudiencesChange.bind(this)}
                          value={selectedAudiences}
                          isSearchable={true}
                          options={audienceTypes}
                          isMulti
                  />
                </div>
                {
                  content_type === 'image' ?
                      <FileUploader
                          onUploadFile={this.onUploadFile.bind(this)}
                          fileDeleted={!uploadedImageUrl}
                      /> : null
                }
                <div className="form-group">
                  <label>Series Id (Optional)</label>
                  <input value={series_id}
                         onChange={this.onFieldChange.bind(this, 'series_id')}
                         type="text"
                         className="form-control"
                         placeholder="Series Id"
                  />
                </div>
                {
                  adUnitTypes ?
                      <div className="form-group">
                        <label>Ad Unit (Optional)</label>
                        <Select onChange={this.onAdUnitsChange.bind(this)}
                                value={selectedAdUnits}
                                isSearchable={true}
                                options={adUnitTypes}
                                isMulti={false}
                                isClearable
                        />
                      </div> : null
                }
                <div className="form-group">
                  <label>URL</label>
                  <input required value={urls[0]}
                         onChange={this.onFieldChange.bind(this, 'urls')}
                         type="text"
                         className="form-control"
                         placeholder="URL"
                  />
                  {this.renderPrefillStatus(prefillingStatue)}
                </div>
                {this.renderVideoIdField(showVideoIdField, video_id)}
                <div className="form-group">
                  <label>Thumbnail URL</label>
                  <input value={thumbnail_url}
                         required
                         onChange={this.onFieldChange.bind(this, 'thumbnail_url')}
                         type="text"
                         className="form-control"
                         placeholder="Thumbnail URL"
                  />
                </div>
                {
                  content_type === 'image' ?
                      <div className="form-group">
                        <label>External URL (Optional)</label>
                        <input value={external_url}
                               onChange={this.onFieldChange.bind(this, 'external_url')}
                               type="text"
                               className="form-control"
                               placeholder="External URL"
                        />
                      </div> : null
                }
                <div className="form-group">
                  <label>Title</label>
                  <input value={title}
                         required
                         onChange={this.onFieldChange.bind(this, 'title')}
                         type="text"
                         className="form-control"
                         placeholder="Title"
                  />
                </div>
                <div className="form-group">
                  <label>Summary</label>
                  <textarea value={summary}
                            onChange={this.onFieldChange.bind(this, 'summary')}
                            type="text"
                            className="form-control summary__textarea"
                            placeholder="Summary"
                  />
                </div>
                <div className="form-group">
                  <label>Source</label>
                  <input value={source}
                         required
                         onChange={this.onFieldChange.bind(this, 'source')}
                         type="text"
                         className="form-control"
                         placeholder="Source"
                  />
                </div>
                <Row>
                  <Col md={6}>
                    <div className="form-group">
                      <label>Language</label>
                      {this.renderSelect(languages, 'language_code', language_code)}
                    </div>
                  </Col>
                  {/*<Col md={6}>*/}
                  {/*  <div className="form-group">*/}
                  {/*    <label>Category</label>*/}
                  {/*    {this.renderSelect(categories, 'category_id', category_id)}`*/}
                  {/*  </div>*/}
                  {/*</Col>*/}
                  <Col md={12} className={'countries-dropdown'}>
                    <div className="form-group">
                      <label>Countries (Optional)</label>
                      <Select
                          defaultValue={selectedCountries}
                          onChange={this.onCountriesChange.bind(this)}
                          isSearchable={true}
                          options={countryOptions}
                          isMulti
                      />
                    </div>
                  </Col>
                  <Col md={12} className={'newHashTag'}>
                    <div className="form-group">
                      <label>Hash Tags</label>
                      <ReactTags
                          allowNew
                          delimiterChars={[',', ' ']}
                          placeholder={'Add new Hashtag'}
                          tags={hash_tags}
                          suggestions={hash_tags_suggestions}
                          handleDelete={this.handleHashTagDelete.bind(this)}
                          handleAddition={(tag) => this.handleHashTagAddition(tag, language_code)}
                          handleValidate={(tag) => this.handleHashTagValidation(tag, language_code)}
                      />
                    </div>
                  </Col>
                  {
                    articleDate?
                        <Col md={6}>
                          <div className="form-group">
                            <label>Article date (Optional)</label>
                            <input value={articleDate}
                                   onChange={this.onFieldChange.bind(this, 'article_date')}
                                   type="datetime-local"
                                   className="form-control"
                                   placeholder="Article date"
                            />
                          </div>
                        </Col> :
                        <Col md={6}>
                          <div className="form-group">
                            <label>Article date (Optional)</label>
                            <input onChange={this.onFieldChange.bind(this, 'article_date')}
                                   type="datetime-local"
                                   className="form-control"
                                   placeholder="Article date"
                            />
                          </div>
                        </Col>
                  }
                  {
                    publishedDate ?
                        <Col md={6}>
                          <div className="form-group">
                            <label>Publish date</label>
                            <input value={publishedDate}
                                   required
                                   onChange={this.onFieldChange.bind(this, 'publish_date')}
                                   type="datetime-local"
                                   className="form-control"
                                   placeholder="Publish date"
                            />
                          </div>
                        </Col> :
                        <Col md={6}>
                          <div className="form-group">
                            <label>Publish date</label>
                            <input onChange={this.onFieldChange.bind(this, 'publish_date')}
                                   type="datetime-local"
                                   className="form-control"
                                   placeholder="Publish date"
                            />
                          </div>
                        </Col>
                  }
                  <Col md={mdSize}>
                    <div className="form-group">
                      <label className="show__ads_label">Show Ads</label>
                      <div className="show__ads_btn_wrapper">
                        <ToggleButton
                            value={ show_ads }
                            onToggle={this.onShowAdsToggle.bind(this)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={mdSize}>
                    <div className="form-group">
                      <label className="show__ads_label">Pin content</label>
                      <div>
                        <ToggleButton
                            value={is_pinned}
                            onToggle={this.onIsPinnedToggle.bind(this)}
                        />
                      </div>
                    </div>
                  </Col>
                  {
                    content_type !== CONTENT_TYPE.QUOTE ?
                        <Col md={mdSize}>
                          <div className="form-group">
                            <label className="show__ads_label">Feature content</label>
                            <div>
                              <ToggleButton
                                  value={is_featured}
                                  onToggle={this.onIsFeaturedToggle.bind(this)}
                              />
                            </div>
                          </div>
                        </Col> : null
                  }
                  {
                    content_type === CONTENT_TYPE.QUOTE ?
                        <Col md={mdSize}>
                          <div className="form-group">
                            <label className="show__ads_label">For Ramadan</label>
                            <div>
                              <ToggleButton
                                  value={for_ramadan}
                                  onToggle={this.onForRamadanToggle.bind(this)}
                              />
                            </div>
                          </div>
                        </Col> : null
                  }
                  <Col md={mdSize}>
                    <div className="form-group">
                      <label className="show__ads_label">Hide content</label>
                      <div>
                        <ToggleButton
                            value={is_hidden}
                            onToggle={this.onIsHiddenToggle.bind(this)}
                        />
                      </div>
                    </div>
                  </Col>
                  {this.renderPinnedDates(is_pinned, pinnedStartDate, pinnedEndDate)}
                  {
                    this.renderFeatureDates(
                        (is_featured && content_type !== CONTENT_TYPE.QUOTE),
                        featuredStartDate,
                        featuredEndDate,
                        selectedFeaturedType,
                    )
                  }
                </Row>
                <br/>
                <div className='meta-info'>
                  <p>
                    {`Created by `}
                    <a target="_blank" href={createdByLink}>
                      {(moderators && moderators[created_by] && moderators[created_by].displayName) ? moderators[created_by].displayName : created_by}
                    </a>
                    {` on ${moment(created_date._seconds*1000).format('MMM ddd DD, YYYY hh:mm A')}`}
                  </p>
                  {
                    updated_by ?
                        <p>
                          {`Last Modified by `}
                          <a target="_blank" href={updatedByLink}>
                            {(moderators && moderators[updated_by] && moderators[updated_by].displayName) ? moderators[updated_by].displayName : (updated_by || 'N/A')}
                          </a>
                          {` on ${moment(updated_date._seconds*1000).format('MMM ddd DD, YYYY hh:mm A')}`}
                        </p> : null
                  }

                </div>
                <div className="form-group">
                  {this.renderSubmitButton(this.blockSubmission(language_code))}
                </div>
              </form>
            </Col>
            <Col md={6}>
              <strong>App Preview</strong>
              <div className="content__preview_div" md={6}>
                <div>
                  <p>
                    <img src={uploadedImageUrl || thumbnail_url} alt="" width="100%" />
                  </p>
                  {
                    uploadedImageUrl ?
                        <button onClick={this.onRemoveFile.bind(this)}
                                type={'button'}
                                className="btn-danger btn-sm">
                                <span
                                    className="glyphicon glyphicon-trash"
                                    aria-hidden="true"
                                />
                          &nbsp;{'Reset Image'}
                        </button> : null
                  }

                </div>
                <h4>{title}</h4>
                <p className="content__preview_div_source">{source}</p>
                <p>{summary}</p>
              </div>
            </Col>
            <Col md={6}>
              <br />
              <strong>On Browser</strong>
              <div className="content__preview_div" md={6}>
                <iframe title="preview"
                        className="on__browser__iframe"
                        src={uploadedImageUrl || urls[0]}
                        width="100%"/>
              </div>
            </Col>
          </Row>
        </BasicLayout>
    );
  }
}

export default ContentEdit;
