import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  bigqueryDeleteUserLogs,
} from '../../services/api-v1-services/other-services';

const deleteUserLogs = () => {
  const [ userLogsDeleteData, setUserLogsDeleteData ] = useState({ });
  const [ deleteData, setDeleteData ] = useState(null);
  const {
    requestData,
    onOperationComplete,
  } = deleteData || {};

  const deleteUserLogsFromBQ = async () => {
    try {
      console.log(`Deleting user logs data...`);
      setUserLogsDeleteData({
        isDeleting: true,
        isError: false,
      });

      console.log('requestData : ', requestData);
      const apiResp =  await bigqueryDeleteUserLogs(requestData);

      const responseData = apiResp.data;
      let errorMsg = null;
      if(responseData.success) {
        console.log(`Deleting user logs data Success!!`);
      } else {
        console.log(`Deleting user logs data Failed!!`);
        errorMsg = `Error occurred while deleting user logs data.`;
      }

      setUserLogsDeleteData({
        data: responseData.data,
        isDeleting: false,
        isError: !responseData.success,
        errorMsg,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError: !responseData.success,
          errorMsg,
          data: responseData.data,
        });
      }

    } catch (err) {
      const errorMsg = (err.response && err.response.status === 422) ?
        `Can't process due to invalid data!` : `Error occurred while deleting user logs rows data.`;
      console.log(`${errorMsg} Error : ${err}`);
      setUserLogsDeleteData({
        data: null,
        isDeleting: false,
        isError: true,
        errorMsg,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
          data: null,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(deleteData)) {
      deleteUserLogsFromBQ();
    }
  }, [ deleteData ]);

  return [
    userLogsDeleteData,
    setDeleteData,
    setUserLogsDeleteData,
  ];
};

export default deleteUserLogs;
