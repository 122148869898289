import axiosService from "../common/axios-service";


class PremiumService {
  transferPremium = (from, to) => {
    return axiosService.post("adminConsoleTransferPremium", {
      sourceUser: from,
      targetUser: to
    }).then( response => {
      if( response.status === 200 ) {
        const data = response.data;
        if( data && (data.success && data.status === 'success') ) {
          return data.message;
        }else{
          console.log('adminConsoleTransferPremium', 'error',  data.error )
          throw new Error( data.error )
        }
      }else{
        console.log('adminConsoleTransferPremium', response.status )
        throw new Error( "adminConsoleTransferPremium error" )
      }
    }).catch( error => {
      console.log('adminConsoleTransferPremium', error )
      throw new Error( error )
    })
  }

  restorePremium = (from, to) => {
    return axiosService.post("adminConsoleRestorePremium", {
      sourceUser: from,
      targetUser: to
    }).then( response => {
      if( response.status === 200 ) {
        const data = response.data;
        if( data && (data.success && data.status === 'success') ) {
          return data.message;
        }else{
          console.log('adminConsoleRestorePremium', 'error',  data.error )
          throw new Error( data.error )
        }
      }else{
        console.log('adminConsoleRestorePremium', response.status )
        throw new Error( "adminConsoleRestorePremium error" )
      }
    }).catch( error => {
      console.log('adminConsoleRestorePremium', error )
      throw new Error( error )
    })
  }

  verifyTransferPremium = (from, to) => {
    return axiosService.post("adminConsoleVerifyTransferPremium", {
      sourceUser: from,
      targetUser: to
    }).then( response => {
      console.log('response')
      console.log('response', response.data)
      if( response.status === 200 ) {
        return response.data;
      }else{
        console.log('adminConsoleVerifyTransferPremium', response.status )
        throw new Error( "adminConsoleVerifyTransferPremium error" )
      }
    }).catch( error => {
      console.log('adminConsoleVerifyTransferPremium', error )
      throw new Error( error )
    })
  }

  getStripePurchase = (uid) => {
    return axiosService.post("adminConsoleGetStripePurchase", {
      target_uid: uid
    }).then( response => {
      if( response.status === 200 ) {
        return response.data;
      }else{
        console.log('adminConsoleGetStripePurchase', response.status )
        throw new Error( "adminConsoleGetStripePurchase error" )
      }
    }).catch( error => {
      console.log('adminConsoleGetStripePurchase', error )
      throw new Error( error )
    })
  }

  restoreStripePurchase = (uid) => {
    console.log('invoking')
    return axiosService.post("adminConsoleRestoreStripePurchase", {
      target_uid: uid
    }).then( response => {
      console.log('invoking response')
      if( response.status === 200 ) {
        return response.data;
      }else{
        console.log('adminConsoleRestoreStripePurchase', response.status )
        throw new Error( "adminConsoleRestoreStripePurchase error" )
      }
    }).catch( error => {
      console.log('adminConsoleRestoreStripePurchase', error )
      throw new Error( error )
    })
  }
}

export default new PremiumService();
