import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  listV2Contents,
} from '../../services/mp-cf-api-service';
import {
  getLastItemId,
  getSortConfig,
  getFilterConfig,
} from '../../pages/content-v2/helper';
import {
  getDisplayData,
} from '../../pages/content-v2/common';

const listHashTags = () => {
  const [ listContentsData, setListContentsData ] = useState({ });
  const [ listData, seListData ] = useState(null);
  const {
    tableConfig={},
    lastTableConfig={},
    originalData,
    pageIndexData,
    skipPageResetRef,
    onOperationComplete,
  } = listData || {};

  const {
    pageIndex,
    pageSize,
    sortBy,
    filters,
  } = tableConfig;
  const {
    pageIndex: lastPageIndex,
    pageSize: lastPageSize,
    sortBy: lastSortBy,
    filters: lastFilters,
  } = lastTableConfig;

  const listCV2Contents = async () => {

    let errorMsg = null;
    let isError = false;
    let contents = [];
    let displayData = [];

    try {

      // Sort Related Decisions
      const sortConfig = getSortConfig(sortBy);
      const isSortByEmpty = _.isEmpty(sortBy);
      const isLastSortByEmpty = _.isEmpty(lastSortBy);
      const isBothSortEmpty = isSortByEmpty && isLastSortByEmpty;
      const isBothSortEqual = _.isEqual(sortBy, lastSortBy);
      const isEqualAndLastSortNotEmpty = !isLastSortByEmpty && isBothSortEqual;
      const sendLastSortItem = isBothSortEmpty || isEqualAndLastSortNotEmpty;

      // Filter Related Decision
      const filterConfig = getFilterConfig(filters);
      const isFiltersEmpty = _.isEmpty(filters);
      const isLastFiltersByEmpty = _.isEmpty(lastFilters);
      const isBothFiltersEmpty = isFiltersEmpty && isLastFiltersByEmpty;
      const isBothFiltersEqual = _.isEqual(filters, lastFilters);
      const isEqualAndLastFiltersNotEmpty = !isLastFiltersByEmpty && isBothFiltersEqual;
      const sendLastFilterItem = isBothFiltersEmpty || isEqualAndLastFiltersNotEmpty;

      // Page Size Related Decisions
      const sendLastPageSizeItem = lastPageSize === pageSize;

      // Pagination Related Decision
      const sendLastItem = sendLastSortItem && sendLastFilterItem && sendLastPageSizeItem;

      const isNextResult = lastPageIndex <= pageIndex;
      const lastItemId = getLastItemId({
        isNextResult,
        sendLastItem,
        originalData,
        pageIndexData,
        pageIndex,
      });

      setListContentsData({
        isFetching: true,
        isError: false,
        dataFetchError: null,
      });

      const filtersConfig = {
        itemsPerPage: pageSize,
        lastItemId,
        sortConfig,
        filterConfig,
        isNextResult,
      };

      const listContentV2Resp = await listV2Contents({
        filters: filtersConfig,
      });

      console.log('listContentV2Resp', listContentV2Resp)
      if (listContentV2Resp.data.result === 'success') {
        console.log('Content list fetch Success!!');

        console.log('listContentV2Resp data', listContentV2Resp.data)
        contents = listContentV2Resp['data']['contents'];
        if(_.isEmpty(contents)) {
          errorMsg = 'Data is empty!';
          isError = true;
        } else {
          displayData = getDisplayData(contents, pageIndex, pageSize, sortBy);
          skipPageResetRef.current = true;
        }
      } else {
        console.log('Content list fetch Failed!!');
        errorMsg = `Error occurred while fetching contents.`;
        isError = true;
      }

      setListContentsData({
        isFetching: false,
        isError,
        errorMsg,
      });

      if(onOperationComplete) {
        if(isError) {
          onOperationComplete({
            dataFetchError: {
              message: errorMsg,
              isError,
            },
          });
        } else {
          onOperationComplete({
            displayData,
            contents,
            pageCount: Math.ceil(10000 / pageSize),
            dataFetchError: null,
          });
        }
      }
    } catch (err) {
      console.log('Something went wrong while fetching contents. ', err);

      const errorData = err.response && err.response.data;
      const errorPayload = errorData && errorData.payload;
      const errorMessage = errorPayload ? errorPayload.errorMessage : 'Something went wrong!'
      const errorNextActionUrl = errorPayload ? errorPayload.errorNextActionUrl : null;

      setListContentsData({
        isFetching: false,
        isError: true,
        errorMsg: errorMessage,
      });

      if(onOperationComplete) {
        onOperationComplete({
          dataFetchError: {
            message: errorMessage,
            actionURL: errorNextActionUrl,
            isError: true,
          },
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(listData)) {
      listCV2Contents();
    }
  }, [ listData ]);

  return [
    listContentsData,
    seListData,
  ];
};

export default listHashTags;
