import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LoadingOverlay from "react-loading-overlay";
import { ScaleLoader } from "react-spinners";

const QalboxVideoUploader = ({ name, value, handleInputTextChange }) => {
  const [videoUrl, setVideoUrl] = useState('');
  const [signedForm, setSignedForm] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const uploadVideo = async (file) => {
    const response = await fetch(
      `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}qalbox-admin-short-service/v1/get/signedUploadUrl/${file.name}`
    );

    if (response.status !== 200) {
      throw response.status;
    }

    const responseJSON = await response.json();
    setSignedForm(responseJSON);
  };

  const handleVideoChange = async (event) => {
    const file = event.target.files[0];
    await uploadVideo(file);
    const folderName = file.name.split('.')[0];
    setVideoUrl(`${folderName}/${file.name}`);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData =  new FormData(event.target);
    try {
      await fetch(
        `${signedForm.url}`,
        {
          method: "POST",
          mode: 'no-cors',
          body: formData,
        }
      );

      alert('Video upload successful !!!');
    } catch (e) {
      console.log(e);
      alert('error when uploading video');
    }

    setLoading(false);
  };

  useEffect(() => {
    if (value) {
      setVideoUrl(value);
    }
  }, [value])
  
  return (
    <LoadingOverlay active={isLoading} spinner={<ScaleLoader />} text="Loading....">
      <div className="qalbox-form-row">
        <textarea
          value={videoUrl}
          name={name}
          type="text"
          onChange={handleInputTextChange}
          className="form-control"
          placeholder={name}
          style={{ marginBottom: "5px", marginRight: "10px" }}
        />
        <form onSubmit={handleSubmit}>
          {signedForm && Object.keys(signedForm.fields).map((name) => (
            <input
              key={name}
              type="hidden"
              name={name}
              value={signedForm.fields[name]}
            />
          ))}
          <input type="file" name="file" accept="video/*" onChange={handleVideoChange} /><br/>
          <input type={signedForm ? "submit" : "hidden"} value="Upload File" />
        </form>
        <CopyToClipboard text={videoUrl}>
          <Button className="btn btn-xs btn-dark mp-content-v2-table-hover-btn">
            &nbsp;
            <span className="glyphicon glyphicon-duplicate" aria-hidden="true" />
          </Button>
        </CopyToClipboard>
      </div>
    </LoadingOverlay>
  );
};

export default QalboxVideoUploader;