import React,
{
    useState,
    useEffect,
} from 'react';
import {
    getHistories,
} from '../../services/admin-db-service';
import {
    ListGroup,
    ListGroupItem,
} from 'react-bootstrap';
import SectionLoader from '../../widgets/sectionloader';
import moment from 'moment';
import './style.css';
import {
    Icon,
} from 'react-fa';
import {
    HISTORY_PATH,
} from './Constants';

const Content = () => {
    const [ historyMap, setHistoryMap ] = useState();
    useEffect(() => {
        const callAPI = async () => {
            const hMap = await getHistories(HISTORY_PATH);
            setHistoryMap(hMap);
        };
        callAPI();
    }, []);

    let listItemComps = <SectionLoader text="Please wait while we fetch sent email history"/>;

    if(historyMap) {
        const listItems = Object.keys(historyMap).reverse().map((key) => {
            const historyItem = historyMap[key];
            const { from, to, timestamp, sentBy, subject, template, scheduledAt, replyTo } = historyItem;
            const isTestMail = sentBy === to;
            const linkToDB = `https://console.firebase.google.com/u/0/project/muslim-pro-app/database/mp-admin-db/data/~2Fhistory~2FemailDashboard~2FsentMail~2F${key}`;
            return (<ListGroupItem key={key} className="history__list_item">
                <p className="small head">
                    <span className="pull-left"><a href={linkToDB} target="_blank"><Icon name="clock"/> Sent at {moment(timestamp).format("LLLL")}</a></span>
                    <span className="pull-right"><Icon name="user"/> Moderated by {sentBy}</span>
                </p>
                <p className="block clear"><strong>Subject: </strong>{subject}</p>
                <p><strong>Sender address: </strong>{from}</p>
                <p><strong>Reply-to address: </strong>{replyTo || 'N/A'}</p>
                <p><strong>Recipient: </strong>{to}</p>
                <p><strong>Template: </strong>{template}</p>
                <p><strong>Scheduled: </strong>{moment(scheduledAt).format('LLLL') || 'N/A'}</p>
                <p><strong>Type: </strong>{!isTestMail ? <span><Icon className="text-success" name="check-circle"/> Sent to users</span> : <span><Icon name="check"/> Sent to moderator as test</span>}</p>
            </ListGroupItem>);
        });
        listItemComps = <ListGroup>{listItems}</ListGroup>;
    }

    return (<div className="block clear">
        <h5 className="history__title">Previously sent emails</h5>
        {listItemComps}
    </div>);
};

const Histories = () => {
    const [show, setShow] = useState(false);
    const contentComp = show ? <Content /> : null;
    const toggleButtonLabelPrefix = show ? 'HIDE' : 'SHOW';
    return (<div className={"history__panel " + show}>
        <div onClick={() => {setShow(!show)}} className="pull-right toggle_history_btn">{toggleButtonLabelPrefix} HISTORY PANEL</div>
        {contentComp}
    </div>);
};

export default Histories;
