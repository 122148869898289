import React from 'react';
import PeriodPicker from './periodPicker';
import moment from 'moment';
import {
  Col,
  Row,
} from 'react-bootstrap';


export default class PeriodPickerWithDefaultButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: null,
      to: null
    };
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.from) {
      this.setState({
        from: nextProps.from
      });
    }
    if(nextProps.to) {
      this.setState({
        to: nextProps.to
      });
    }
  }

  setThisWeek() {
    this.setState({
      from: moment().startOf('isoWeek').toDate(),
      to: moment().toDate()
    });
  }

  setLastWeek() {
    this.setState({
      from: moment().subtract(6, 'd').startOf('isoWeek').toDate(),
      to: moment().subtract(6, 'd').endOf('isoWeek').toDate()
    });
  }

  setThisMonth() {
    this.setState({
      from: moment().startOf('month').toDate(),
      to: moment().toDate()
    });
  }

  setLastMonth() {
    this.setState({
      from: moment().subtract(1, 'months').startOf('month').toDate(),
      to: moment().subtract(1, 'months').endOf('month').toDate()
    });
  }

 render() {
  const {from, to} = this.state;
   return <Row>
     <Col md={8}>
        <PeriodPicker {...this.props} from={from} to={to} />
     </Col>
     <Col md={4}>
        <button onClick={this.setThisWeek.bind(this)} className="btn btn-default btn-block btn-xs">This week</button>
        <button onClick={this.setLastWeek.bind(this)} className="btn btn-default btn-block btn-xs">Last week</button>
        <button onClick={this.setThisMonth.bind(this)} className="btn btn-default btn-block btn-xs">This month</button>
        <button onClick={this.setLastMonth.bind(this)} className="btn btn-default btn-block btn-xs">Last month</button>
     </Col>
   </Row>;
 }
}
