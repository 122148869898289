import _ from "lodash";
import { useState, useEffect } from "react";
import { qalBoxGetMediaSeriesEpisodeList, qalBoxGetMediaSeriesList } from "../../services/mp-cf-api-service";

const getMediaSeriesEpisodeList = () => {
  const [responseData, setResponseData] = useState({});
  const [updateData, setOperationData] = useState(null);
  const { searchConfig, onOperationComplete } = updateData || {};

  const getMediaSeriesEpisodeListItems = async () => {
    try {
      console.log(`QalBox searching media series list...`);
      setResponseData({
        isLoading: true,
        isError: false,
      });

      const mediaItemsApiResp = await qalBoxGetMediaSeriesEpisodeList({
        ...searchConfig,
      });

      const responseData = _.get(mediaItemsApiResp, "data", {});

      let errorMsg = null;
      let isError = false;

      if (responseData.success) {
        console.log(
          "QalBox search media series list items add/update Success!!"
        );
      } else {
        console.log(
          "QalBox search media series list items add/update Failed!!"
        );
        errorMsg = `Failed to search QalBox media series list items.`;
        isError = true;
      }

      setResponseData({
        isLoading: false,
        isError,
        errorMsg,
        data: responseData.payload,
      });

      if (onOperationComplete) {
        onOperationComplete({
          isError,
          errorMsg,
          data: responseData.payload,
        });
      }
    } catch (err) {
      console.log(
        "Something went wrong while searching QalBox media series list items. ",
        err
      );
      const errorMsg = `Error occurred while searching QalBox media series list items.`;
      setResponseData({
        isLoading: false,
        isError: true,
        errorMsg,
        data: null,
      });
      if (onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
          data: null,
        });
      }
    }
  };

  useEffect(() => {
    if (!_.isEmpty(updateData)) {
      getMediaSeriesEpisodeListItems();
    }
  }, [updateData]);

  return [responseData, setOperationData];
};

export default getMediaSeriesEpisodeList;
