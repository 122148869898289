import _ from 'lodash';
import React, { Component } from 'react';
import { Alert, Row, Col, Button, Panel } from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import MultiFileUploader from "../../widgets/multiFileUploader";
import { Icon } from 'react-fa';
import { ToastContainer, toast } from 'react-toastify';
import { bulkUploadQuranPlaylists, redisFlushCacheForCF } from '../../services/mp-cf-api-service';
import { getDateFromExcelValue } from "../../utils/index";
import readXlsxFile from 'read-excel-file';
import moment from 'moment';
import './style.css';
import { SurasV2 } from './common';
import { SURA_AYA_LENGTH } from './constants';
import { getUrlParams } from  '../../utils';

const {
  QURAN_PLAYLIST_COVER_IMG_BASE_URL,
  MAX_PUBLISH_DATE_IN_MONTHS,
  QURAN_PLAYLIST_FEATURED_CACHE_ID_DEBUG,
  QURAN_PLAYLIST_FEATURED_CACHE_ID, } = require('./constants');

const sampleExcelFileUrl =
  'https://docs.google.com/spreadsheets/d/1J_AEw-WSLqL6HshTl-sOEnP-gQGn2lriVI7esi5AQio/edit?usp=sharing';

// Method to process surah from playlist spreadsheet
const processSuras = (suras) => {
  console.log('suras ', suras)
  const surasV1 = [];
  const surasV2 = [];

  const splittedSuras = suras.replace(/\s/g, '').split('&');
  splittedSuras.forEach(splittedSura => {
    const suraAndAyas = splittedSura.split(/\((.*)\)/);
    const sura = Number(suraAndAyas[0]);
    const ayas = suraAndAyas[1];
    const suraV2Obj = {
      sura_id: sura
    };

    if(ayas) {
      if(ayas.indexOf('-') > -1) {
        const ayasSplitted = ayas.split('-');
        suraV2Obj['aya_ids'] = _.range(Number(ayasSplitted[0]), Number(ayasSplitted[1]) + 1);
      } else {
        suraV2Obj['aya_ids'] = _.map(ayas.split(','), _.toInteger);
      }
    }
    surasV1.push(sura);
    surasV2.push(suraV2Obj)
  });

  console.log('surasV1 : ', surasV1);
  console.log('surasV2 : ', surasV2);

  return { surasV1, surasV2 };
};

// Method for calculating featured and pinned duration
const getDuration = ((publishDate, tillDate, pastDate) => {
  if(!pastDate && !tillDate) {
    console.log("Something went wrong while processing duration");
    return null;
  } else if(!tillDate) {
    return pastDate;
  } else {
    tillDate = new Date(tillDate);
    tillDate.setHours(6);
    tillDate.setMinutes(0);
    const duration = moment(tillDate).diff(moment(publishDate), 'days');
    return duration;
  }
});

// Method for prcessing supported countries from spreadsheet
const processSupportedCountries = ((countries) => {
  if(!countries) {
    return [];
  } else if(countries.toLowerCase() === 'all') {
    return [];
  } else {
    return countries.trim().split(",").map((item) => { return item.trim().toUpperCase(); });
  }
});

class QuranPlaylistBulkUploadFeatured extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      playlists: [],
      errors: [],
      fileUploaded: false,
      allPlaylistValidated: null,
      skippedPlaylistCount: 0,
      debug: 0
    };
  }

  UNSAFE_componentWillMount() {
    let debug = getUrlParams(this.props).debug;
    debug = debug ? parseInt(debug, 10) : 0;
    this.setState({debug});
  }

  removeFile(file) {
    const newFiles = [...this.state.files];
    newFiles.splice(newFiles.indexOf(file), 1);
    this.setState({ files: newFiles, playlists: [], fileUploaded: false, errors: [], allPlaylistValidated: null, skippedPlaylistCount: 0 });
  };

  removeAllFiles() {
    this.setState({ files: [], playlists: [], fileUploaded: false, errors: [], allPlaylistValidated: null, skippedPlaylistCount: 0 });
  }

  onDrop(files) {
    this.setState({
      files: files.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })),
      fileUploaded: true,
      errors: [],
      allPlaylistValidated: null,
      skippedPlaylistCount: 0,
      playlists: []
    })
  }

  async onValidatePlaylistsBtnClick(files) {
    this.setState({
      playlists: [],
      errors: [],
      allPlaylistValidated: null,
      skippedPlaylistCount: 0
    });
    const rows = await readXlsxFile(files[0]);
    this.buildPlaylists(rows);
  }

  // Check validity of playlist and list error where possible
  isPlaylistValid({playlist, rowIndex}) {
    const { errors = [] } = this.state;
    const errorForThisPlaylist = [];
    const {
      name, description, language_code, suras = [], suras_v2, published_at, pinned_duration,
      featured_duration,
    } = playlist;

    // Name
    if(!name || name.trim() === "") {
      errorForThisPlaylist.push('Playlist title can\t be empty');
    }
    // Desccription
    if(!description || description.trim() === "") {
      errorForThisPlaylist.push(`Playlist description can't be empty`);
    }

    // Language code
    if(!language_code || language_code.trim() === "") {
      errorForThisPlaylist.push(`Playlist language code can't be empty`);
    }

    // Suras
    if(!suras.length) {
      errorForThisPlaylist.push('Can\'t process empty or invalid sura list');
    }

    for(let s=0; s<suras.length; s++) {
      const sura = suras[s];
      if(typeof(sura) !== 'number') {
        errorForThisPlaylist.push('Sura id is not a number');
        break;
      } else if(sura > 114 || sura < 1) {
        errorForThisPlaylist.push(`${sura} is not a valid surah id`);
        break;
      }
    }

    // Suras V2
    _.forEach(suras_v2, ({ sura_id, aya_ids }) => {
      if(!_.isNumber(sura_id)) {
        errorForThisPlaylist.push('Sura id is not a number');
        return false;
      }

      if(!_.inRange(sura_id, 1, 115)) {
        errorForThisPlaylist.push(`${sura_id} is not a valid surah id`);
        return false;
      }

      if(!_.isEmpty(aya_ids)) {
        _.forEach(aya_ids, (aya_id) => {
          if(!_.inRange(aya_id, 1, SURA_AYA_LENGTH[sura_id] + 1)) {
            errorForThisPlaylist.push(`Aya ids for sura ${sura_id}, should be in between of 1 and ${SURA_AYA_LENGTH[sura_id]}`);
            return false;
          }
        });
      }
    });

    if(!published_at || !moment(published_at).isValid) {
      errorForThisPlaylist.push('Invalid published date');
    }

    if(published_at && moment(published_at).isValid) {
      const eta = Math.abs(moment().diff(moment(published_at), 'months'));
      if(eta > MAX_PUBLISH_DATE_IN_MONTHS) {
        errorForThisPlaylist.push(`Publish date is ${eta} months later from today. That's too far away from today.`);
      }
    }

    if(pinned_duration < 1 || featured_duration < 1) {
      errorForThisPlaylist.push("Pinned or Featured must be a future date");
    }

    if(errorForThisPlaylist.length) {
      errors.push({
        errorLocation: rowIndex,
        errorList: errorForThisPlaylist
      });
    }
    this.setState({
      errors
    });
    return !errors.length;
  }

  buildSinglePlaylist({row, rowIndex, config}) {
    const { keys, previous = {} } = config;
    // console.log("keys", keys);
    const titleIndex = keys.indexOf("Playlist title");
    const descriptionIndex = keys.indexOf("Playlist Description");
    const publishDateIndex = keys.indexOf("Publish Date");
    const featuredTillIndex = keys.indexOf("Featured till");
    const pinnedTillIndex = keys.indexOf("Pinned till");
    const languageCodeIndex = keys.indexOf("Language Code");
    const coverImgUrlIndex = keys.indexOf("Playlist Image URL (1)");
    const isInfluencerIndex = keys.indexOf("Influencer");
    const surasIndex = keys.indexOf("Surah IDs");
    const supportedCountryIndex = keys.indexOf("Country Code");
    const publishedIndex = keys.indexOf("Published");
    const isAlreadyPublished = row[publishedIndex];

    let published_at = typeof(row[publishDateIndex]) === 'number' ?
                       getDateFromExcelValue(row[publishDateIndex]) :
                       previous.published_at;
    let pinnedTill = typeof(row[pinnedTillIndex]) === 'number' ?
                       getDateFromExcelValue(row[pinnedTillIndex]) :
                       previous.pinnedTill;
   let featuredTill = typeof(row[featuredTillIndex]) === 'number' ?
                      getDateFromExcelValue(row[featuredTillIndex]) :
                      previous.featuredTill;

    let coverImgUrl = QURAN_PLAYLIST_COVER_IMG_BASE_URL;
    if(isInfluencerIndex > -1) {
      coverImgUrl += 'influencers/';
    }
    coverImgUrl += row[coverImgUrlIndex];

    const pinned_duration = getDuration(published_at, pinnedTill, previous.pinned_duration)
    const featured_duration = getDuration(published_at, featuredTill, previous.featured_duration)

    const allSuras = row[surasIndex] ? processSuras(row[surasIndex]) : previous.allSuras;

    let supported_countries = processSupportedCountries(row[supportedCountryIndex]);

    let playlist = {
      name: row[titleIndex],
      description: row[descriptionIndex],
      language_code: row[languageCodeIndex],
      created_at: new Date(),
      created_by: localStorage.getItem('uid'),
      cover_img_url: coverImgUrl,
      published_at,
      suras: allSuras.surasV1,
      suras_v2: allSuras.surasV2,
      pinned_duration,
      featured_duration,
      supported_countries,
      is_pinned: true,
      is_featured: true,
      owner_name: "Muslim Pro",
      owner_user_id: "muslimpro"
    };

    const isValid = this.isPlaylistValid({ playlist, rowIndex });
    if(!isValid) {
      playlist = null;
    }
    const previousData = {
      published_at,
      allSuras,
      pinned_duration,
      featured_duration
    };

    return { playlist, previous: previousData, isAlreadyPublished };
  }

  buildPlaylists(rows) {
    const keys = rows[0];
    let skippedPlaylistCount = 0;
    let previous, allPlaylistValidated = true, playlists = [];
    for(let i=1; i<rows.length; i ++) {
      const playlistData = this.buildSinglePlaylist({rowIndex: i, row: rows[i], config: {
        keys,
        previous
      }});
      previous = playlistData.previous;
      if(!playlistData.playlist) {
        allPlaylistValidated = false;
        playlists = [];
      } else if(playlistData.isAlreadyPublished) {
        skippedPlaylistCount++;
      }else {
        playlists.push(playlistData.playlist);
      }
    }
    this.setState({
      skippedPlaylistCount,
      allPlaylistValidated,
      playlists
    });
  }

  renderMessages({fileUploaded, errors, allPlaylistValidated, validatedPlaylistCount, skippedPlaylistCount}) {
    let messages = [];
    if(fileUploaded) {
      messages.push(
        <Alert key="-1" bsStyle='info'><Icon name="check-circle" /> <strong>File has been uplaoded successfully</strong></Alert>
      );
      if(allPlaylistValidated) {
        messages.push(
          <Alert key="-2" bsStyle='success'><strong><p><Icon name="check-circle" /> {validatedPlaylistCount} playlists has been successfully validated</p><p className="text-muted"><Icon name="times-circle" /> {skippedPlaylistCount} playlists skipped since they are already published.</p></strong></Alert>
        );
      } else if(allPlaylistValidated === false) {
        messages.push(
          <Alert key="-2" bsStyle='danger'><Icon name="exclamation-circle" /> <strong>Failed to validate one or more playlist(s). Please check the errors below!</strong></Alert>
        );
        if(errors.length) {
          const errorMessages = errors.map((errorListForOnePlaylist, i) => {
            const paragraphs = errorListForOnePlaylist.errorList.map((msg, j) => { return <p className="small" key={`${i}${j}${msg}`}>- {msg}</p>; });
            return <Alert key={`${i}-alert`} bsStyle='danger'>
              <p><strong><Icon name="hand-point-right" /> Row # {errorListForOnePlaylist.errorLocation+1}</strong></p>
              {paragraphs}
            </Alert>;
          });
          messages.push(errorMessages);
        }
      }
    }
    return messages;
  }

  renderTobePushedItems(playlists) {
    if(!playlists.length) {
      return <p>No items ready to be pushed.</p>;
    } else {
      console.log("playlists", playlists);
      return playlists.map((playlist, i) => {
        return <TobePushedPlaylist data={playlist} />
      });
    }
  }

  async onPushPlaylists() {
    const { playlists, debug } = this.state;
    const resp = await bulkUploadQuranPlaylists({playlists, debug});
    if(resp.data && resp.data.success) {
      const cacheId = debug ? QURAN_PLAYLIST_FEATURED_CACHE_ID_DEBUG : QURAN_PLAYLIST_FEATURED_CACHE_ID;
      const flushCacheResp = await redisFlushCacheForCF({cacheId});
      if(flushCacheResp.data && flushCacheResp.data.success) {
        toast.success('Playlists pushed successfully & cache cleared', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }
    } else {
      toast.error('Failed to push playlists', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
    this.setState({
      playlists: [],
      fileUploaded: false,
      errors: [],
      allPlaylistValidated: null,
      skippedPlaylistCount: 0
    });
  }

  render() {
    const { files, errors, fileUploaded, allPlaylistValidated, playlists = [], skippedPlaylistCount } = this.state;
    return (
      <BasicLayout pagePermission="content_editor">
        <ToastContainer autoClose={2000} />
        <div className="qp_bulk_upload">
            <Row>
              <Col md={6}>
              <h4>Bulk upload featured quran playlist</h4>
                <p style={{ color: '#f94444' }}>
                  <label>See the Sample Excel Sheet : </label> &nbsp;
                  <a href={sampleExcelFileUrl} target="_blank">
                    Click to see the sample excel sheet.
                  </a>
                </p>
                <br/>
                <MultiFileUploader
                    files={files}
                    showPreviews={files.length}
                    onRemoveFile={this.removeFile.bind(this)}
                    showRemoveAllButton={false}
                    multiple={false}
                    onRemoveAllFiles={this.removeAllFiles.bind(this)}
                    onDropFiles={this.onDrop.bind(this)}
                    dropFileMainText={'Click here to upload or Drag and drop the Excel Sheet.'}
                />
                <Button
                  disabled={files.length === 0}
                  onClick={this.onValidatePlaylistsBtnClick.bind(this, files)}
                  className="qp_action_btn"
                  bsStyle="primary">Validate</Button>
                <Button
                  disabled={playlists.length === 0}
                  onClick={this.onPushPlaylists.bind(this)}
                  className="qp_action_btn"
                  bsStyle="success">Push</Button>
                  <hr />
                  <h4>Logs</h4>
                  {this.renderMessages({fileUploaded, errors, allPlaylistValidated, skippedPlaylistCount, validatedPlaylistCount: playlists.length})}
              </Col>
              <Col md={6}>
                <h4>Tobe pushed</h4>
                {this.renderTobePushedItems(playlists)}
              </Col>
            </Row>
        </div>
      </BasicLayout>
    );
  }
}

class TobePushedPlaylist extends Component {
  render() {
    const {
      name, description, language_code, published_at, suras, supported_countries = [],
      pinned_duration = 'N/A', featured_duration = 'N/A', cover_img_url, suras_v2,
    } = this.props.data;
    const surasEls = suras.join(', ');
    let supportedCountriesEl = supported_countries.length ? supported_countries.join(', ') :
    <span>All countries</span>;

    return <Panel className="qp_tobe_pushed small">
      <Panel.Heading><code>{language_code}</code> <strong>{name}</strong> <span className="pull-right"> Will be published at {moment(published_at).format('MMM DD, YYYY hh:mm A')} UTC</span></Panel.Heading>
      <Panel.Body className="qp_tobe_pushed_panel_body">
        <Row>
          <Col md={9}>
            <p><strong>Description:</strong> {description}</p>
            <p><strong>Suras:</strong> {surasEls}</p>
            <p><strong>Ayas:</strong></p>
            <SurasV2 suras_v2={suras_v2} />
            <p><strong>Supported countries:</strong> {supportedCountriesEl}</p>
            <p><strong>Pinned duration:</strong> {pinned_duration} days</p>
            <p><strong>Featured duration:</strong> {featured_duration} days</p>
          </Col>
          <Col md={3}>
            <img width="100%" alt="cover_img_url" src={cover_img_url} />
          </Col>
        </Row>
      </Panel.Body>
    </Panel>;
  }
}

export default QuranPlaylistBulkUploadFeatured;
