import _ from 'lodash';
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import { getQuranPlaylists } from '../../services/mp-cf-api-service';
import DateTime from '../../widgets/datetime';
import { Icon } from 'react-fa';
import './style.css';
import EditModal from './editModal';
import { SurasV2 } from './common';
import {
  FEATURED_SUPPORTED_LANGUAGES,
} from './constants';
import { getUrlParams } from '../../utils';

// getQuranPlaylists

class FilterBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: 'en'
    };
  }

  onLanguageChange(e) {
    this.setState({
      selectedLanguage: e.target.value
    });
    this.props.onLanguageChange(e.target.value);
  }

  render() {
    const { selectedLanguage } = this.state;
    const options = Object.keys(FEATURED_SUPPORTED_LANGUAGES).map((key) => {
      return <option value={key}>{FEATURED_SUPPORTED_LANGUAGES[key]}</option>;
    });
    return <Row className="qp_filter_bar">
      <Col className="language_picker" md={6}>
        <label>Select supported language</label>
        {' '}
        <select value={selectedLanguage} onChange={this.onLanguageChange.bind(this)} className="form-control">
          <option value="all">All</option>
          {options}
        </select>
      </Col>
    </Row>;
  }
}

class QuranPlaylistFeaturedList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: 'en',
      quranPlaylists: {},
      stsMsg: 'Please wait while fetching data from the Database..',
      debug: 0,
      showEditModal: false,
      selectedPlaylist: null
    };
  }

  hideEditModal() {
    this.setState({
      showEditModal: false,
      selectedPlaylist: null
    });
  }

  onLanguageChange(selectedLanguage) {
    this.setState({
      selectedLanguage
    });
    this.fetchQuranPlaylists(selectedLanguage, this.state.debug);
  }

  async fetchQuranPlaylists(selectedLanguage, debug) {
    this.setState({
      stsMsg: 'Please wait while fetching data from the Database..'
    });
    try {
      const resp = await getQuranPlaylists({languageCode: selectedLanguage, debug});
      if(resp.data && resp.data.success && resp.data.payload) {
        const quranPlaylists = resp.data.payload;
        const { alreadyPublished = [], tobePublished = [] } = quranPlaylists;
        this.setState({
          quranPlaylists,
          stsMsg: `Showing ${alreadyPublished.length + tobePublished.length} results`
        });
      } else {
        this.setState({
          stsMsg: 'Something went wrong. Please refresh the page and try again.'
        });
      }
    } catch (e) {
      this.setState({
        stsMsg: 'Something went wrong. Please refresh the page and try again.'
      });
    }
  }

  componentWillMount() {
    let debug = getUrlParams(this.props).debug;
    debug = debug ? parseInt(debug, 10) : 0;
    this.setState({debug});
    this.fetchQuranPlaylists('en', debug);
  }

  onEditPlaylist(selectedPlaylist) {
    this.setState({
      selectedPlaylist,
      showEditModal: true
    });
  }

  onEditSuccessful() {
    const { selectedLanguage, debug } = this.state;
    this.fetchQuranPlaylists(selectedLanguage, debug);
  }

  renderQuranPlaylists(list, {title, scheduled, iconName}) {
    const rows = list.map((playlist, i) => {
      const {
        is_deleted, is_pinned, is_active_pinned,
        id, name, description, created_at, published_at,
        language_code, followers_count, suras = [], suras_v2,
        supported_countries = []
      } = playlist;

      const inactivePinnedMsg = scheduled ? 'Will be' : 'Previously';
      const previouslyPinnedMsg = (!is_active_pinned && is_pinned) ?
                                  <p className={"small inactive_pinned " + (scheduled ? 'scheduled':'')}>[{inactivePinnedMsg} pinned]</p> : null;

      const currentlyPinnedMsg = is_active_pinned ? <p className="small currently_pinned">[Currently pinned]</p> : null;
      const deletedMsg = is_deleted ? <p className="small is_deleted_msg">[Deleted]</p> : null;

      const pinnedClass = is_active_pinned ? 'is_pinned' : '';
      const deletedClass = is_deleted ? 'is_deleted' : '';

      const surasEl = suras.map((sura, i) => {
        return <span key={i}><code>{sura}</code>&nbsp;</span>;
      });

      const supportedCountriesEl = supported_countries.map((countries, i) => {
        return <span key={i}><code>{countries}</code>&nbsp;</span>;
      });

      let renderSupportedCountries = supported_countries.length ? supportedCountriesEl : <p className="text-success">All</p>;

      const collectionName = this.state.debug === 1 ? 'QuranPlaylistsDebug' : 'QuranPlaylists';

      const qpDBLink = `https://console.firebase.google.com/project/muslim-pro-app/database/firestore/data~2F${collectionName}~2F${id}`;


      return <Row key={i} className={`qp_result_row ${pinnedClass} ${deletedClass}`}>
        <Col className="qp_serial" xs={1}><a target="_blank" href={qpDBLink}>{i+1}</a></Col>
        <Col className="qp_name" xs={2}>{name} {previouslyPinnedMsg}{currentlyPinnedMsg}{deletedMsg}</Col>
        <Col xs={3}>
          {description}
          <div className="qp_sura_ids"><strong>Suras:</strong> <p>{surasEl}</p></div>
          {
            _.isEmpty(suras_v2)
              ? null
              : <div className="qp_sura_ids">
                  <strong>Ayas :</strong>
                  <SurasV2 suras_v2={suras_v2} />
                </div>
          }
          <div className="qp_sura_ids"><strong>Supported countries:</strong> <p>{renderSupportedCountries}</p></div>
        </Col>
        <Col xs={1}><DateTime timestamp={created_at}/></Col>
        <Col xs={2}><DateTime timestamp={published_at}/></Col>
        <Col xs={1}>{FEATURED_SUPPORTED_LANGUAGES[language_code]}</Col>
        <Col xs={1}>{followers_count}</Col>
        <Col xs={1}><Icon onClick={this.onEditPlaylist.bind(this, playlist)} className="edit_playlist_icon" name="pencil" /></Col>
      </Row>
    });
    return <div>
      <Row>
        <Col xs={12} className="qp_table_title">
          <h3>{title} <Icon name={iconName} /></h3>
        </Col>
      </Row>
      <Row className="small qp_result_header_row">
        <Col xs={1}>#</Col>
        <Col xs={2}>Name</Col>
        <Col xs={3}>Description</Col>
        <Col xs={1}>Created At</Col>
        <Col xs={2}>Published At</Col>
        <Col xs={1}>Language</Col>
        <Col xs={1}>Follower count</Col>
        <Col xs={1}>Edit</Col>
      </Row>
      {rows}
    </div>;
  }

  renderStatus() {
    return <p className="qp_api_sts">{this.state.stsMsg}</p>;
  }

  render() {
    const { quranPlaylists = {}, showEditModal, selectedPlaylist, debug } = this.state;
    const { alreadyPublished = [], tobePublished = [] } = quranPlaylists;
    return (
      <BasicLayout pagePermission="content_editor">
        <EditModal
          debug={debug}
          show={showEditModal}
          onHide={this.hideEditModal.bind(this)}
          onEditSuccessful={this.onEditSuccessful.bind(this)}
          playlist={{...selectedPlaylist}} />
        <FilterBar onLanguageChange={this.onLanguageChange.bind(this)}/>
        {this.renderStatus()}
        {this.renderQuranPlaylists(alreadyPublished, {title: 'Already published', iconName: 'check-circle'})}
        {this.renderQuranPlaylists(tobePublished, {title: 'Scheduled to be published', scheduled : true, iconName: 'clock'})}
      </BasicLayout>
    );
  }
}

export default QuranPlaylistFeaturedList;
