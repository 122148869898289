import _ from "lodash";
import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { Col, Row } from "react-bootstrap";
import BasicLayout from "../../../layouts/basiclayout";
import {
  getDeletableDisplayCategories,
  convertArrayToObjMap,
} from "../helper";
import { MPSelect } from "../../../widgets/common";
import { confirmAlert } from "react-confirm-alert";
import AppModal from "../../../widgets/modal";
import { ScaleLoader } from "react-spinners";
import QalboxTable from "../../../widgets/qalbox-table";
import { AdUnitModal } from "./adUnitModal";
import { getAdUnitDisplayData } from "./helpers/adUnitDataTransform";
import { getQalboxAdUnitColumns } from "./helpers/adUnitColumns";
import { useFetchAdUnits } from "./hooks/useFetchAdUnits";
import { useDeleteAdUnit } from "./hooks/useDeleteAdUnit";

const QalboxAdUnits = () => {
  const [refresh, setRefresh] = useState(false);
  const [ShowAdUnitModal, setShowAdUnitModal] = useState(false);
  const [adunitRow, setAdUnitRow] = useState(null);
  const [active, setActive] = useState(true);
  const [adUnitsResponse, isLoadingAdUnits, errorFetching] = useFetchAdUnits(active, refresh);
  const [deleteAdUnit, isDeleting, isDeletingError] = useDeleteAdUnit();

  const onDeleteSelected = (selectedFlatRows, resetSelectedRows) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            headingText={`Are you sure want to delete the following ${_.size(
              selectedFlatRows
            )} ad units items ?`}
            modalBody={getDeletableDisplayCategories(selectedFlatRows)}
            modalBodyClassName={"precalcs-diff-merge-view"}
            closeButtonText={"No"}
            okButtonText={"Yes, Delete"}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              const uuidListToDelete = _.map(selectedFlatRows, "values.id.props.text");
              _.forEach(uuidListToDelete, id => {
                deleteAdUnit(id, handleRefresh);
              });

              alert('Successfully Deleted !!!');
              resetSelectedRows();
            }}
          />
        );
      },
    });
  };

  const onEditClicked = (selectedFlatRows, resetSelectedRows) => {
    const id = _.get(selectedFlatRows, '[0].values.id.props.text', null);
    const shortMediaItem = adUnitsResponse.find(item => item.id === id);
    setAdUnitRow(shortMediaItem);
    setShowAdUnitModal(true);
    resetSelectedRows();
  };

  const handleRefresh = () => {
    setRefresh(prevRefresh => !prevRefresh);
  };

  const onUpdate = (response) => {
    setShowAdUnitModal(false);
    handleRefresh();
  };

  const handleCreate = () => {
    setAdUnitRow(null);
    setShowAdUnitModal(true);
  };

  return (
    <LoadingOverlay
      active={isLoadingAdUnits || isDeleting}
      spinner={<ScaleLoader />}
      text={isDeleting ? "Deleting the selected ad unit items." : "Loading Ad units..."}
    >
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={2000} />
        <Row>
          <Col md={9}>
            <h3 className="content_editor_title">{"Qalbox Ad Units"}</h3>
          </Col>
          <Col md={3} style={{ paddingTop: "20px" }}>
            <button
              style={{
                display: "inline-block",
                padding: "10px 20px",
                backgroundColor: "green",
                color: "white",
                textDecoration: "none",
                borderRadius: "4px",
              }}
              onClick={handleCreate}
            >
              Create
            </button>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div className="form-group">
              <label
                style={{
                  marginRight: "5px",
                }}
              >
                {"Active : "}
              </label>
              <MPSelect
                items={convertArrayToObjMap(["true", "false"])}
                selectedItem={active}
                onChangeSelect={(e) => setActive(e)}
              />
            </div>
          </Col>
        </Row>
        <QalboxTable
          table={{
            data: getAdUnitDisplayData(adUnitsResponse),
            columns: getQalboxAdUnitColumns(),
            handleDelete: onDeleteSelected,
            handleEdit: onEditClicked,
            isLoading: isLoadingAdUnits,
            tableError: errorFetching,
          }}
        />
      </BasicLayout>
      <AppModal
        show={ShowAdUnitModal}
        showBody={true}
        headingText={`Create Ad Unit`}
        modalBody={
          <AdUnitModal
            data={adunitRow}
            handleUpdates={onUpdate}
          />
        }
        modalBodyClassName={""}
        closeButtonText={""}
        okButtonText={""}
        handleClose={() => {setShowAdUnitModal(false); setAdUnitRow(null)}}
      />
    </LoadingOverlay>
  );
};

export default QalboxAdUnits;
