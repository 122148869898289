import _ from 'lodash';
import {
  JsonInputTypes,
} from './Constants';

const getJsonInputPlaceHolderValue = (inputType) => {
  switch ( JsonInputTypes[inputType]) {
    case JsonInputTypes.string:
      return {[JsonInputTypes.string]: null};
    case JsonInputTypes.int:
      return {[JsonInputTypes.int]: null};
    case JsonInputTypes.float:
      return {[JsonInputTypes.float]: null};
    case JsonInputTypes.array:
      return {[JsonInputTypes.array]: null};
    case JsonInputTypes.boolean:
      return {[JsonInputTypes.boolean]: null};
    default:
      return {[JsonInputTypes.object]: null};
  }
};

const isValidJsonInputObject = (updateData, selectedType) => {
  const updatedData = updateData['jsObject'];

  if(_.isEmpty(updatedData) || (_.size(updatedData) !== 1)) {
    return false;
  }

  return _.keys(updatedData)[0].toLowerCase() ===
      JsonInputTypes[selectedType].toLowerCase();
}

const isValidJsonInputData = (updateData, selectedType) => {
  const updatedData = updateData['jsObject'];

  if(_.isEmpty(updatedData) || (_.size(updatedData) !== 1)) {
    return false;
  }

  const updatedValue = _.values(updatedData)[0];

  switch ( JsonInputTypes[selectedType]) {
    case JsonInputTypes.string:
      return _.isNull(updatedValue) || _.isString(updatedValue);
    case JsonInputTypes.int:
      return _.isNull(updatedValue) || _.isInteger(updatedValue);
    case JsonInputTypes.float:
      return _.isNull(updatedValue) || _.isNumber(updatedValue);
    case JsonInputTypes.array:
      return _.isNull(updatedValue) || _.isArray(updatedValue);
    case JsonInputTypes.boolean:
      return _.isNull(updatedValue) || _.isBoolean(updatedValue);
    case JsonInputTypes.object:
      return _.isNull(updatedValue) || _.isObject(updatedValue);
    default:
      return false;
  }
}
const getJsonInputErrorMsg = (isValidKey, isValidData, selectedType, updatedData) => {
  if(!isValidKey && !isValidData) {
    if(_.isEmpty(updatedData)) {
      return 'Invalid json, Please fix.';
    } else if (_.size(updatedData) > 1) {
      return 'Only one key allowed, Please fix.';
    } else {
     // Do nothing.
    }
  }

  if(!isValidKey) {
    return 'Invalid Key, Please do not update the key.';
  }

  if(!isValidData) {
    return  `Invalid ${JsonInputTypes[selectedType]} data, Please fix.`;
  }

  return null;
}

const getJsonInputFilteredValues = (updatedJsonValues) => {
  return _.values(updatedJsonValues)[0];
};

const getInitialCheckedItemsState = (checkBoxes) => {
  const initialState = {};
  checkBoxes.forEach(checkBox => {
    initialState[checkBox['id']] = Boolean(checkBox['initialValue']);
  });

  return initialState;
};

export {
  getJsonInputPlaceHolderValue,
  isValidJsonInputObject,
  isValidJsonInputData,
  getJsonInputErrorMsg,
  getJsonInputFilteredValues,
  getInitialCheckedItemsState,
}
