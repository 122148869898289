import React,
{
  Component,
} from 'react';
import BasicLayout from '../../layouts/basiclayout';
import './style.css';
import firebase from 'firebase/app';
import {
  Row,
  Col,
  ControlLabel,
} from 'react-bootstrap';
import DateTime from '../../widgets/datetime';
import rtdbRef from "../../apis/rtdbRef";
import {
  csvDownloader,
} from '../../utils/index';

class ListPromoCodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaigns: null,
      creators: null,
      fetchingFilterData: false,
      fetchingCodes: false,
      selectedCampaign: "all",
      selectedCreator: "all",
      selectedRedeemedStatus: 'all',
      firstRefKey: "",
      lastRefKey: "",
      limit: 25,
      action: "",
      moderators: localStorage.getItem('moderatorsInfo') ? JSON.parse(localStorage.getItem('moderatorsInfo')) : null
    };
  }

  componentWillMount() {
    this.getFilterData();
    const campaign = this.props.match.params.query;
    if(!campaign) {
      window.location = '/onetime-promo-codes/All';
    }

    this.setState({
      selectedCampaign: campaign
    });
    if(this.props.location.search !== "") {
      const search = this.props.location.search.substring(1);
      const queryParams = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
      const tempState = {};
      if(queryParams.limit) {
        tempState["limit"] = queryParams.limit;
      }
      if(queryParams.refKey) {
        tempState["refKey"] = queryParams.refKey;
      }
      if(queryParams.action) {
        tempState["action"] = queryParams.action;
      }
      if(queryParams.created_by) {
        tempState["selectedCreator"] = queryParams.created_by;
      }
      this.setState(tempState);
      if(campaign) {
        this.getCodes(campaign, tempState);
      }
    }
  }

  // Get campaigns by not passing a specific campaign
  getFilterData() {
    this.setState({
      fetchingFilterData: true
    });
    if(!localStorage.getItem('promoCodesFilterData')) {
      let app = firebase.app();
      app.database("https://mp-admin-db.firebaseio.com")
        .ref('/Configs/Production/PromoCodes')
        .on('value', ((snapshot) => {
          const campaigns = snapshot.val().campaigns;
          const creators = snapshot.val().creators;
          localStorage.setItem('promoCodesFilterData', JSON.stringify(snapshot.val()))
          this.setState({
            campaigns,
            creators,
            fetchingFilterData: false
          });
        }));
    } else {
      const filterData = JSON.parse(localStorage.getItem('promoCodesFilterData'));
      const { campaigns, creators } = filterData;
      this.setState({
        campaigns,
        creators,
        fetchingFilterData: false
      });
    }
  }

  getCodes(campaign, tempState) {
    this.setState({
      fetchingCodes: true
    });
    let baseRef = rtdbRef.promoCodes('/promoCodes').orderByChild('campaign');
    let query;
    const { action, refKey, limit, selectedCreator } = tempState;
    if(campaign === 'All') {
      baseRef = rtdbRef.promoCodes('/promoCodes').orderByChild('created_by');
      if(refKey && action === "next") {
        query = baseRef.startAt(selectedCreator, refKey).limitToFirst(parseInt(limit, 10)+1);
      } else if(refKey && action === "previous") {
        query = baseRef.endAt(selectedCreator, refKey).limitToLast(parseInt(limit, 10)+1);
      } else {
        query = baseRef.equalTo(selectedCreator).limitToFirst(parseInt(limit, 10)+1);
      }
    } else {
      if(refKey && action === "next") {
        query = baseRef.startAt(campaign, refKey).limitToFirst(parseInt(limit, 10)+1);
      } else if(refKey && action === "previous") {
        query = baseRef.endAt(campaign, refKey).limitToLast(parseInt(limit, 10)+1);
      } else {
        query = baseRef.equalTo(campaign).limitToFirst(parseInt(limit, 10)+1);
      }
    }
    // The real query happening here
    query.on('value', ((snapshot) => {
      const codes = snapshot.val();
      const firstRefKey = codes && (Object.keys(codes)[0] || ""),
      lastRefKey = firstRefKey ? Object.keys(codes)[Object.keys(codes).length-1] : "";
      this.setState({
        codes,
        firstRefKey,
        lastRefKey,
        fetchingCodes: false
      });
    }));
  }

  onCampaignChange(e) {
    this.setState({
      firstRefKey: "",
      lastRefKey: "",
      refKey: "",
      selectedCreator: "All"
    });
    this.redirectToQueriedUrl({selectedCampaign: e.target.value, refKey: "", selectedCreator: "All"});
  }

  onCreatorChange(e) {
    this.setState({
      firstRefKey: "",
      lastRefKey: "",
      refKey: "",
      selectedCampaign: "All"
    });
    this.redirectToQueriedUrl({selectedCreator: e.target.value, refKey: "", selectedCampaign: "All"});
  }

  onRedeemedStatusChange(e) {
    const selectedRedeemedStatus = e.target.value;
    this.setState({
      selectedRedeemedStatus
    });
  }

  renderRedeemStatusSelect() {
    return <div className="listpromocodes__wrapper">
      <ControlLabel>Redeemed status</ControlLabel>
      <select
        onChange={this.onRedeemedStatusChange.bind(this)}
        className="form-control">
        <option value="all">All</option>
        <option value="already_redeemed">Already redeemed</option>
        <option value="not_redeemed">Not redeemed</option>
      </select>
    </div>;
  }

  renderCampaignsSelect(campaigns, fetchingFilterData, selectedCampaign) {
    let comp;
    if(fetchingFilterData) {
      comp = <p>Fetching campaigns..</p>;
    } else {
      if(!campaigns || Object.keys(campaigns).length === 0) {
        comp = <p>No campaign found. Please refresh the page to try again.</p>;
      } else {
        let campaignKeys = ['All'].concat(Object.keys(campaigns));
        const opts = campaignKeys.map((cam) => {
          return <option key={cam} value={cam}>{cam}</option>
        });
        comp = <select value={selectedCampaign} onChange={this.onCampaignChange.bind(this)} className="form-control">{opts}</select>;
      }
    }
    return <div className="listpromocodes__wrapper">
      <ControlLabel>Campaign</ControlLabel>
      {comp}
    </div>;
  }

  renderCreatorsSelect(creators, fetchingFilterData, selectedCreator) {
    let comp;
    if(fetchingFilterData) {
      comp = <p>Fetching creators..</p>;
    } else {
      if(!creators || Object.keys(creators).length === 0) {
        comp = <p>No creator found. Please refresh the page to try again.</p>;
      } else {
        let creatorKeys = ['All'].concat(Object.keys(creators));
        const opts = creatorKeys.map((uid) => {
          let label = this.getModeratorLabel(uid);
          return <option key={uid} value={uid}>{label}</option>
        });
        comp = <select value={selectedCreator} onChange={this.onCreatorChange.bind(this)} className="form-control">{opts}</select>;
      }
    }
    return <div className="listpromocodes__wrapper">
      <ControlLabel>Created By</ControlLabel>
      {comp}
    </div>;
  }

  renderRedeemedBy(uid) {
    if(!uid) {
      return <td>Not redeemed</td>;
    } else {
      // URL USAGE ( UN-REFACTORED )
      return <td><span className="truncated_col_el">By <a target="_blank" href={"https://console.firebase.google.com/project/muslim-pro-app/database/muslim-pro-app/data/users/"+uid}>{uid}</a></span></td>;
    }
  }

  renderCreatedBy(uid) {
    const modProfileUrl = `https://muslim-pro-app.firebaseapp.com/search-user/${uid}`;
    let label = this.getModeratorLabel(uid);
    return <a target="_blank" href={modProfileUrl}>{label}</a>
  }

  getModeratorLabel(uid) {
    const { moderators } = this.state;
    let label;
    if(!moderators || !moderators[uid] || !moderators[uid].email) {
      label = uid;
    } else {
      const { displayName, email } = moderators[uid];
      label = `${displayName} - ${email}`;
    }
    return label;
  }

  renderRowVal(i, key, type, campaign, expiry, uid, created_by, redeemedClassName, redeemedDateTime, displayName, duration) {
    type = (type === 'monthly' || type === "yearly") ? `${type} (${duration})` : type;
    return <tr className={redeemedClassName} key={key}>
      <td>{i+1}</td>
      <td><a href={"https://console.firebase.google.com/project/muslim-pro-app/database/mp-promo-codes/data/promoCodes/"+key} target="_blank">{key}</a></td>
      <td>{type}</td>
      <td>{campaign}</td>
      <td><a className="truncated_col_el" target="_blank" href={"https://muslim-pro-app.firebaseapp.com/search-user/"+created_by}>{displayName || (created_by.startsWith("webui") ? "WebUI" : created_by)}</a></td>
      <td>{expiry}</td>
      {this.renderRedeemedBy(uid)}
      <td>{redeemedDateTime}</td>
    </tr>;
  }

  renderPromoCodes(codes, fetchingCodes) {
    const { selectedRedeemedStatus } = this.state;
    if(fetchingCodes) {
      return <div className="listpromocodes__wrapper">
        Fetching codes. Please wait patiently...
      </div>;
    } else {
      if(!codes || codes === {}) {
        return <div className="alert alert-info">
          Nothing found! Select a valid <strong>Campaign</strong> or a valid <strong>Creator</strong>.
        </div>;
      } else {
        let redeemedCount = 0, totalCount = 0;
        const rows = Object.keys(codes).map((code, count) => {
          const promoCodeItem = codes[code];
          const redeemedDateTime = promoCodeItem.redeem_datetime ? <DateTime timestamp={promoCodeItem.redeem_datetime} /> : "N/A";
          const redeemedClassName = promoCodeItem.redeem_datetime ? "promo__redeemed_row" : "";
          const { type, campaign, expiry, user_id, created_by, duration } = promoCodeItem;
          totalCount++;
          if(user_id) {
            redeemedCount++;
          }
          // Filter codes
          if(selectedRedeemedStatus === 'already_redeemed' && !user_id) {
            return null;
          }
          if(selectedRedeemedStatus === 'not_redeemed' && user_id) {
            return null;
          }
          return this.renderRowVal(count++, code, type, campaign, expiry, user_id, created_by, redeemedClassName, redeemedDateTime, this.renderCreatedBy(created_by), duration);
        });
        const promoCountInfo = !totalCount ? <span>Data being processed...</span> : <span>{redeemedCount} promo codes has been redeemed out of {totalCount}. {totalCount-redeemedCount} remains.</span>;
        return (
          <div className="promo_code_table_wrapper">
            <p className="promo__count">{promoCountInfo}</p>
            <table className="table promo_code_table small">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Promo code</th>
                  <th>Type</th>
                  <th>Campaign</th>
                  <th>Created by</th>
                  <th>Expiry</th>
                  <th>Redeem status</th>
                  <th>Redeemed at</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
          </div>
        );
      }
    }

  }

  onLimitChange(e) {
    this.redirectToQueriedUrl({limit: e.target.value});
  }

  redirectToQueriedUrl(update) {
    const updatedState = Object.assign(this.state, update);
    const { selectedCampaign, selectedCreator, limit, refKey, action } = updatedState;
    const url = `/onetime-promo-codes/${selectedCampaign}/?created_by=${selectedCreator}&action=${action}&refKey=${refKey}&limit=${limit}`;
    window.location = url;
  }

  paginate(action, e) {
    e.preventDefault();
    let refKey = "";
    const { firstRefKey, lastRefKey } = this.state;
    if(firstRefKey !== "" && lastRefKey !== "") {
      refKey = (action === 'next') ? lastRefKey : firstRefKey;
    }
    this.setState({
      action,
      refKey
    });
    this.redirectToQueriedUrl({action, refKey});
  }

  processCSV(codes, moderators) {
    let csvContent = "#, Code, Campaign, Type, Duration, Created by, Expiry, Redeemed at, Redeemed by\r\n";
    Object.keys(codes).forEach((code, i) => {
      const data = codes[code];
      const { campaign, created_by, expiry, type, duration='-', redeem_datetime, user_id = '-' } = data;
      const createdBy = moderators && moderators[created_by] && moderators[created_by].displayName? moderators[created_by].displayName : created_by;
      const redeemDatetime = redeem_datetime ? new Date(redeem_datetime).toISOString().slice(0, 10) : '-';
      const row = `${i+1},${code},${campaign},${type},${duration},${createdBy},${expiry},${redeemDatetime},${user_id}`;
      csvContent += row + "\r\n";
    });
    return csvContent;
  }

  downloadCSV(config) {
    const { codes, moderators, selectedCampaign } = config;
    csvDownloader({
      content: this.processCSV(codes, moderators),
      fileName: `MP_PROMO_CODES_${selectedCampaign}_${Date.now()}`
    });
  }

  render() {
    const { campaigns, creators, fetchingFilterData, codes, selectedCampaign, selectedCreator, fetchingCodes, firstRefKey, lastRefKey, limit, moderators } = this.state;
    return (
      <BasicLayout pagePermission="read_codes">
        <Row>
          <Col md={12}>
            <Row>
              <Col md={4}>
                {this.renderRedeemStatusSelect()}
              </Col>
              <Col md={4}>
                {this.renderCampaignsSelect(campaigns, fetchingFilterData, selectedCampaign)}
              </Col>
              <Col md={4}>
                {this.renderCreatorsSelect(creators, fetchingFilterData, selectedCreator)}
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            {this.renderPromoCodes(codes, fetchingCodes)}
          </Col>
          <Col className="promocodes__btn_container" md={12}>
            <button disabled={!selectedCampaign} onClick={this.paginate.bind(this, 'previous')} className="btn btn-default btn-xs">Previous</button>
            {' '}
            <button disabled={!selectedCampaign} onClick={this.paginate.bind(this, 'next')} className="btn btn-default btn-xs">Next</button>
            {' '}
            <select disabled={!firstRefKey && !lastRefKey} onChange={this.onLimitChange.bind(this)} value={limit}>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
              <option value="5000">5000</option>
              <option value="10000">10000</option>
              <option value="20000">20000</option>
              <option value="30000">30000</option>
              <option value="50000">50000</option>
              <option value="100000">100000</option>
            </select>
            <button disabled={!codes || codes === {}} onClick={this.downloadCSV.bind(this, {codes, moderators, selectedCampaign})} className="btn btn-default btn-xs pull-right">Download CSV</button>
          </Col>
        </Row>
      </BasicLayout>
    );
  }
}

export default ListPromoCodes;
