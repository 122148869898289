import _ from 'lodash';
import React,
{
  Fragment,
} from 'react';
import {
  Icon,
} from 'react-fa';
import {
  toast,
} from 'react-toastify';
import ImageZoom from 'react-medium-image-zoom';
import {
  CopyToClipboard,
} from 'react-copy-to-clipboard';
import {
  Panel,
  Button,
  MenuItem,
  ButtonGroup,
  DropdownButton,
} from 'react-bootstrap';
import {
  FIRESTORE_COLLECTIONS,
  FIRESTORE_DATABASE_URL,
} from '../../Constants';
import {
  getDisplayDate,
  getHashtagColor,
} from './helper';
import {
  MPInput,
  OperationStatus,
} from '../../widgets/common';
import JSONDiffView from '../../widgets/json-diff-view';

const renderTypeIcon = (ct) => {
  switch (ct) {
    case 'video':
      return <span className="content-v2-type-icon glyphicon glyphicon-facetime-video"/>;
    case 'image':
      return <span className="content-v2-type-icon glyphicon glyphicon-picture"/>;
    case 'article':
      return <span className="content-v2-type-icon glyphicon glyphicon-paperclip"/>;
    case 'quote':
      return <span className="content-v2-type-icon glyphicon glyphicon-heart-empty"/>;
    default:
      return null;
  }
}

const getModeratorName = (id, moderators) => {
  if(!id) {
    return 'N/A';
  }

  const idName = id ? `${id.substring(0, 5)}...` : null;

  return moderators && moderators[id] && moderators[id].displayName
      ? moderators[id].displayName.split(' ')[0] : idName;
};

const HashTags = ({
                    hashTags, hashTagBackGroundColorCode='#9ae6b4',
                    hashTagTextColorCode='#22543d',
                  }) => {
  if(_.isEmpty(hashTags)) {
    return null;
  }

  return (
      <div className={'mp-content-v2-table-hashtag-list'}>
        {hashTags.map((hashTag, idx) => {
          return (
              <span
                key={idx}
                className="mp-content-v2-table-hashtag"
                style={{
                  color: hashTagTextColorCode,
                  backgroundColor: hashTagBackGroundColorCode,
                }}
              >
                {`#${hashTag}`}
              </span>
          );
        })}
      </div>
  );
};

const SeriesId = ({ seriesId }) => {
  if(!seriesId) {
    return null;
  }

  return (
      <span className="mp-content-v2-table-series-id">
        {`#${seriesId}`}
      </span>
  );
};

const getDisplayData  = (contents, pageIndex, pageSize, sortBy) => {
  if(!contents) {
    return [];
  }

  let sortDesc  = false;
  if(!_.isEmpty(sortBy)) {
    sortDesc = sortBy[0].desc;
  }

  const moderators = localStorage.getItem('moderatorsInfo')
      ? JSON.parse(localStorage.getItem('moderatorsInfo'))
      : null;

  const nextIndex = sortDesc ? (pageIndex + 1) * pageSize : pageIndex * pageSize + 1;

  return contents.map((ro, i) => {

    const {
      id,
      urls,
      title,
      source,
      series_id,
      is_pinned,
      created_by,
      created_date,
      publish_date,
      content_type,
      language_code,
      thumbnail_url,
      updated_by,
      updated_date,
      is_hidden,
      is_featured,
      hash_tags,
      featured_start_date,
      featured_end_date,
      featured_category,
      pinned_start_date,
      pinned_end_date,
      for_ramadan,
      year_code,
    } = ro;

    const createdByLink = `/search-user/${created_by}`;
    const updatedByLink = `/search-user/${updated_by}`;

    const editContentLink = `/content-v2-edit/${id}`;
    const rowExtraCss = is_hidden
        ? 'hidden_item'
        : (is_pinned ? 'pinned' : (is_featured ? 'featured_item' : ''));
    const sourceCss = is_hidden ? 'source__col_disabled' :'source__col';
    const contentDBURL = `${FIRESTORE_DATABASE_URL}${FIRESTORE_COLLECTIONS.CONTENT_V2}${id}`;

    return {
      index: (
          <div className={'content-v2-index'}>
            <div className={'content-v2-index-number'}>
              {sortDesc ? nextIndex - i : nextIndex + i}
            </div>
            <div className={'content-v2-index-items'}>
              {
                is_featured
                    ? <Icon className={'content-v2-index-item'} name={'bookmark'}/>
                    : null
              }
              {
                is_pinned
                    ? <Icon className={'content-v2-index-item'} name={'thumbtack'}/>
                    : null
              }
              {
                is_hidden
                    ? <Icon className={'content-v2-index-item'} name={'times-circle'}/>
                    : null
              }
            </div>
          </div>
      ),
      preview: (
          <ImageZoom
              image={{
                src: `${thumbnail_url}`,
                style: { width: '64px' }
              }}
              zoomImage={{
                src: `${thumbnail_url}`,
              }}
          />
      ),
      title: (
          <Fragment>
            <p className={'word-wrap-text text-center'}>{title}</p>
            <CopyToClipboard
                text={title}
                onCopy={() => {
                  console.log(`Copied title : ${title}`)
                }}
            >
              <Button className="btn btn-xs btn-dark mp-content-v2-table-hover-btn">
                &nbsp;<span className="glyphicon glyphicon-duplicate" aria-hidden="true"/>
              </Button>
            </CopyToClipboard>
          </Fragment>
      ),
      imageUrl: (
          <a className={' text-center'} target="_blank" href={urls ? urls[0] : '#'}>
            Click
          </a>
      ),
      source: <p className={`text-center ${sourceCss}`}>{source}</p>,
      typeIcon: (
          <div>
            {content_type} {renderTypeIcon(content_type)}
          </div>
      ),
      seriesId: <SeriesId seriesId={series_id} />,
      hashTags: <HashTags hashTags={hash_tags} />,
      languageCode: <p>{language_code}</p>,
      createdDate: <div>{getDisplayDate(created_date)}</div>,
      createdBy: (
          <Fragment>
            <a  className={'word-wrap-text'} target="_blank" href={createdByLink} >
              {getModeratorName(created_by, moderators)}
            </a>
          </Fragment>
      ),
      updatedDate: <div>{getDisplayDate(updated_date)}</div>,
      updatedBy: (
          <Fragment>
            <a className={'word-wrap-text'} target="_blank" href={updatedByLink} >
              {getModeratorName(updated_by, moderators)}
            </a>
          </Fragment>
      ),
      publishedDate: <div>{getDisplayDate(publish_date)}</div>,
      featured: <div>{is_featured ? 'True' : 'False'}</div>,
      featuredStartDate: <div>{getDisplayDate(featured_start_date)}</div>,
      featuredEndDate: <div>{getDisplayDate(featured_end_date)}</div>,
      pinned: <div>{is_pinned ? 'True' : 'False'}</div>,
      pinnedStartDate: <div>{getDisplayDate(pinned_start_date)}</div>,
      pinnedEndDate: <div>{getDisplayDate(pinned_end_date)}</div>,
      hidden: <div>{is_hidden ? 'True' : 'False'}</div>,
      forRamadan: <div>{for_ramadan ? 'True' : 'False'}</div>,
      yearCode: <div>{year_code}</div>,
      featuredCategory: <div>{featured_category}</div>,
      dbLink: (
          <a
              target="_blank"
              href={contentDBURL}
          >
            Database link
          </a>
      ),
      contentId: (
          <Fragment>
            {id} &nbsp;
            <CopyToClipboard
                text={id}
                onCopy={() => {
                  console.log(`Copied id : ${id}`)
                }}
            >
              <Button className="btn btn-xs btn-dark mp-content-v2-table-hover-btn">
                &nbsp;<span className="glyphicon glyphicon-duplicate" aria-hidden="true"/>
              </Button>
            </CopyToClipboard>
          </Fragment>
      ),
      operations: (
          <a target="_blank" href={editContentLink}
             className="margin-all3 btn btn-primary btn-xs mp-content-v2-table-hover-btn">
            <span className={"glyphicon glyphicon-edit"} aria-hidden="true"/> Edit
          </a>
      ),
      editContentLink,
      rowExtraCss,
      dataItemId: id,
    };
  });
};


const getConfirmItems = (selectedFlatRows) => {
  const rows = selectedFlatRows.map((flatRowObj, index) => {
    const renderedCells = flatRowObj.cells.reduce((filtered, cell) => {
      if(['preview', 'title', 'typeIcon', 'contentId', 'languageCode'].includes(cell.column.id)) {
        filtered.push(
            <td
                key={cell.column.id}
            >
              {cell.render('Cell')}
            </td>
        );
      }

      return filtered;
      }, []
    );

    return (
        <tr key={index}>
          {renderedCells}
        </tr>
    );
  });

  const tableHeaders = (
      <thead>
        <tr>
          <th>Preview</th>
          <th>Title</th>
          <th>Type</th>
          <th>Language</th>
          <th>Content Id</th>
        </tr>
      </thead>
  );

  return (
      <table className={'table mp-content-v2-preview-table small'}>
        {tableHeaders}
        <tbody>
        {rows}
        </tbody>
      </table>
  );
};

const getConfirmHashTags = (selectedFlatRows) => {
  const rows = selectedFlatRows.map((flatRowObj, index) => {
    const renderedCells = _.keys(flatRowObj.original).reduce((filtered, key) => {
          if(['hashTag', 'languageCode', 'contentIdsEditLinks'].includes(key)) {
            filtered.push(
                <td
                    key={key}
                >
                  {flatRowObj.original[key]}
                </td>
            );
          }

          return filtered;
        }, []
    );

    return (
        <tr key={index}>
          {renderedCells}
        </tr>
    );
  });

  const tableHeaders = (
      <thead>
      <tr>
        <th>HashTag</th>
        <th>Language</th>
        <th>Content Ids</th>
      </tr>
      </thead>
  );

  return (
      <div>
        <p className={'hash-tags-delete-warning'}>
          Before deleting hash tags make sure that you are logged in properly,
          If the hash tags delete operation fails halfway you will end up having
          inconsistent data in database. Hence, It's recommended you to
          <strong> logout and login again to admin console </strong>
          before you proceed to deleting Hashtags.
        </p>
        <table className={'table mp-content-v2-preview-table small'}>
          {tableHeaders}
          <tbody>
          {rows}
          </tbody>
        </table>
      </div>
  );
}

const ContentV2ListTableToolBox = ({
                                     selectedRows,
                                     setAllFilters,
                                     initialFilters=[],
                                     onDeleteSelected,
                                     onDownloadSelected,
                                     disableDelDownButtons,
                                     disableResetAllFilters,
                                   }) => {

  const preElements = null;

  const postElements = (
      <ul className={'color-codes-list-v2'}>
        <li className={'color-codes-list-item'}>
          Featured
          <div className={'featured_color_code'}>
            <Icon className={'featured_color_code_icon'} name={'bookmark'}/>
          </div>
        </li>
        <li className={'color-codes-list-item'}>
          Pinned
          <div className={'pinned_color_code'}>
            <Icon className={'pinned_color_code_icon'} name={'thumbtack'}/>
          </div>
        </li>
        <li className={'color-codes-list-item'}>
          Hidden
          <div className={'hidden_color_code'}>
            <Icon className={'hidden_color_code_icon'} name={'times-circle'}/>
          </div>
        </li>
      </ul>
  );

  return (
      <TableToolBox
          preElements={preElements}
          onDeleteSelected={onDeleteSelected}
          onDownloadSelected={onDownloadSelected}
          disableDelDownButtons={disableDelDownButtons}
          disableResetAllFilters={disableResetAllFilters}
          selectedRows={selectedRows}
          setAllFilters={setAllFilters}
          initialFilters={initialFilters}
          postElements={postElements}
      />
  );
};

const TableToolBox = ({
                        selectedRows,
                        setAllFilters,
                        onDeleteSelected,
                        onDownloadSelected,
                        disableDelDownButtons,
                        disableResetAllFilters,
                        initialFilters=[],
                        preElements=null,
                        postElements=null,
                      }) => {
  return (
      <div className={'mp-content-v2-table-tools'}>
        {preElements}
        <button
            className="margin-all3 btn btn-danger btn-sm"
            disabled={disableDelDownButtons}
            onClick={() => onDeleteSelected(selectedRows)}>
          <span className={"glyphicon glyphicon-trash"} aria-hidden="true"/>
          &nbsp;Delete Selected
        </button>
        <ButtonGroup>
          <DropdownButton
              id={'content-v2-download'}
              disabled={disableDelDownButtons}
              title="Download Selected"
              className="margin-all3 btn btn-primary btn-sm"
          >
            <MenuItem eventKey="1"
                      onClick={() => onDownloadSelected(selectedRows, 'txt')}>
              As Text File
            </MenuItem>
            <MenuItem eventKey="2"
                      onClick={() => onDownloadSelected(selectedRows, 'csv')}>
              As CSV File
            </MenuItem>
            <MenuItem eventKey="3"
                      onClick={() => onDownloadSelected(selectedRows, 'xls')}>
              As XLS File
            </MenuItem>
            <MenuItem eventKey="4"
                      onClick={() => onDownloadSelected(selectedRows, 'json')}>
              As JSON File
            </MenuItem>
          </DropdownButton>
        </ButtonGroup>
        <button
            className="margin-all3 btn btn-info btn-sm"
            disabled={disableResetAllFilters}
            onClick={() => setAllFilters(initialFilters)}>
          <span className={"glyphicon glyphicon-filter"} aria-hidden="true"/>
          &nbsp;Reset All Filters
        </button>
        {postElements}
      </div>
  );
};

const ContentEditLinks = ({
                            contentIdsEditLinks,
                          }) => {

  const [showPanel, setShowPanel] = React.useState(false);
  const contentsSize = _.size(contentIdsEditLinks)

  return (
      <div>
        <p>{`Attached to ${contentsSize} contents.`}</p>
        <Button
            className="btn btn-primary btn-sm"
            onClick={() => setShowPanel(!showPanel)}>
          {showPanel ? 'Hide' : 'Show'}
        </Button>
        <br />
        <Panel id="content-v2-edit-links"
               className={'content-v2-edit-panel'}
               expanded={showPanel}
               onToggle={() => setShowPanel(!showPanel)}
        >
          <Panel.Collapse>
            <Panel.Body>
              <div className={'content-v2-edit-links'}>
                {contentIdsEditLinks}
              </div>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </div>
  );
}

const getHashTagDisplayData = (hashTagData) => {
  if(!hashTagData) {
    return [];
  }

  return _.reduce(hashTagData, (hashTags, languageHashTags, languageCode) => {
    const tags = _.map(_.keys(languageHashTags), hashTag => {
      const {
        ids_map, minPublishDate,
      } = languageHashTags[hashTag];
      const contentIdArray = _.keys(ids_map);
      const contentIds = contentIdArray.join(', ');
      const contentIdsEditLinks = contentIdArray.map(contentId => {
        const editContentLink = `/content-v2-edit/${contentId}`;

        return (
            <a key={editContentLink}
               className={'content-v2-edit-link'}
               target="_blank"
               href={editContentLink}>
              {contentId}
            </a>
        );
      });

      return {
        hashTag,
        languageCode,
        contentIdsEditLinks: (
            <ContentEditLinks contentIdsEditLinks={contentIdsEditLinks} />
        ),
        minPublishDate,
        contentIds,
      };
    });

    return hashTags.concat(tags);
  }, []);
};

const onDeleteComplete = operationData => {
  if(!operationData.isError) {
    toast.success(`Selected Contents Deleted Successfully!!`, {
      position: toast.POSITION.BOTTOM_LEFT
    });
    setTimeout(()=> {
      window.location.reload();
    }, 1000);
  } else {
    toast.error(operationData.errorMsg, {
      position: toast.POSITION.BOTTOM_LEFT
    });
  }
};

const ContentV2OperationStatus = ({
                                    tableError,
                                  }) => {

  return (
      <OperationStatus
          operationInProgressText={`Please wait! Loading data...`}
          showActionBtn={tableError && tableError.actionURL}
          actionBtnText={tableError && tableError.actionURL ? 'Fix' : 'Restart'}
          actionBtnAction={() => window.open(tableError.actionURL,'_blank')}
          operationFailedMsg={tableError && tableError.message}
          operationFailedInfoMsg={tableError && tableError.actionURL ?
              'If the issue is related to firebase indexing then try creat thee index by clicking Fix button and and restarting the page after crearting the index, if you don`t have permission to create index ask backend team to create the index for you by sending the URL link by clicking Fix button.' : 'Please try again by changing your filters.'}
          operationStatusData={tableError}
      />
  );
};

const DatabaseTrendingHashTags = ({
                                    onClickBlock,
                                    dbTrendingHashTags,
                                    onClickAddToCustom,
                                  }) => {
  if(_.isEmpty(dbTrendingHashTags)) {
    return 'No trending hashtags found.'
  }

  const hashTags = _.map(dbTrendingHashTags, ({record_count, hashtag}, index) => {

    return (
      <tr key={hashtag} className={'trending-hashtags-table-row'}>
        <td>{`${index + 1}.`}</td>
        <td>
          <HashTags
            hashTags={[hashtag]}
            hashTagBackGroundColorCode={'rgb(230 199 154)'}
          />
        </td>
        <td>
          <Button
            style={{'marginRight': '5px'}}
            onClick={() => onClickAddToCustom({ hashtag, source: 'bq' })}
            disabled={false}
            className="btn-primary btn-xs"
            type={'button'}>
            {'Add to Custom'}
          </Button>
        </td>
        <td>
          <Button
            style={{'marginRight': '5px'}}
            onClick={() => onClickBlock({ hashtag, source: 'bq' })}
            disabled={false}
            className="btn-danger btn-xs"
            type={'button'}>
            {'Block'}
          </Button>
        </td>
        <td>{`${record_count} hits.`}</td>
      </tr>
    );
  });

  return (
    <div className={'trending-hashtags-table-container'}>
      <table className={'trending-hashtags-table'}>
        <tbody>
        {hashTags}
        </tbody>
      </table>
    </div>
  );
};

const ApiTrendingHashTags = ({
                               apiTrendingHashTags,
                             }) => {
  if(_.isEmpty(apiTrendingHashTags)) {
    return 'No trending hashtags found.'
  }

  const hashTags = _.map(apiTrendingHashTags, (hashtag, index) => {

    return (
      <tr key={hashtag} className={'trending-hashtags-table-row'}>
        <td>{`${index + 1}.`}</td>
        <td>
          <HashTags
            hashTags={[hashtag]}
          />
        </td>
      </tr>
    );
  });

  return (
    <div className={'trending-hashtags-table-container'}>
      <table className={'trending-hashtags-table'}>
        <tbody>
        {hashTags}
        </tbody>
      </table>
    </div>
  );
};

const TrendingHashTags = ({
                            onClickUp,
                            onClickDown,
                            onRemoveHashtag,
                            showRemoveButton=true,
                            trendingHashTags,
                            showUpAndDownButtons=false,
                            hashTagTextColorCode='#22543d',
                            hashTagBackGroundColorCode='#9ae6b4',
                          }) => {
  if(_.isEmpty(trendingHashTags)) {
    return 'No trending hashtags found.'
  }

  const hashTags = _.map(trendingHashTags, ({ hashtag, source }, index) => {

    return (
      <tr key={hashtag}>
        <td>{`${index + 1}. `}</td>
        <td>
          <HashTags
            hashTags={[hashtag]}
            hashTagBackGroundColorCode={getHashtagColor(source, hashTagBackGroundColorCode)}
            hashTagTextColorCode={hashTagTextColorCode}
          />
        </td>
        {
          showRemoveButton ?
            <td>
              <Button
                onClick={() => onRemoveHashtag(hashtag)}
                disabled={false}
                className="btn-danger btn-xs"
                type={'button'}>
                {'Remove'}
              </Button>
            </td> : null
        }
        {
          showUpAndDownButtons ?
            <Fragment>
              <td>
                <Button
                  onClick={() => onClickUp(hashtag)}
                  disabled={index === 0}
                  className="btn-success btn-xs"
                  type={'button'}>
                  <Icon name="arrow-up" />
                </Button>
              </td>
              <td>
                <Button
                  onClick={() => onClickDown(hashtag)}
                  disabled={index === trendingHashTags.length - 1}
                  className="btn-success btn-xs"
                  type={'button'}>
                  <Icon name="arrow-down" />
                </Button>
              </td>
            </Fragment> : null
        }

      </tr>
    );
  });

  return (
    <div className={'trending-hashtags-table-container'}>
      <table className={'trending-hashtags-table'}>
        <tbody>
        {hashTags}
        </tbody>
      </table>
    </div>
  );
};

const TrendingHashTagsToolBox = ({
                                   onChangeValue, placeHolder, value,
                                   inputType='text', min, max, inlineLabel,
                                   labelText, showLabel=false,
                                   firstButtonText='Add', onClickFirstButton,
                                   disableFirstButton = false, showSecondButton=true,
                                   secondButtonText='Sync', onClickSecondButton,
                                   disableSecondButton = false, showThirdButton=true,
                                   thirdButtonText='Reset', onClickThirdButton,
                                   disableThirdButton = false,
                                  }) => {

  return (
    <div className={'trending-hashtags-tools-container'}>
      <div
        style={{'marginRight': '5px'}}
      >
        <MPInput
          type={inputType}
          min={min}
          max={max}
          showLabel={showLabel}
          inlineLabel={inlineLabel}
          labelText={labelText}
          value={value}
          placeHolder={placeHolder}
          onChangeValue={onChangeValue}
        />
      </div>
      <Button
        style={{'marginRight': '5px'}}
        onClick={onClickFirstButton}
        disabled={disableFirstButton}
        className="btn-primary btn-sm"
        type={'button'}>
        {firstButtonText}
      </Button>
      {
        showSecondButton &&
        <Button
          style={{'marginRight': '5px'}}
          onClick={onClickSecondButton}
          disabled={disableSecondButton}
          className="btn-primary btn-sm"
          type={'button'}>
          {secondButtonText}
        </Button>
      }
      {
        showThirdButton &&
        <Button
          style={{'marginRight': '5px'}}
          onClick={onClickThirdButton}
          disabled={disableThirdButton}
          className="btn-primary btn-sm"
          type={'button'}>
          {thirdButtonText}
        </Button>
      }
    </div>
  );
}

const getUpdatedHashtagsInfo = ({
                                  newHashtags,
                                  rtdbHashtags,
                                }) => {

  return (
    <JSONDiffView
      leftSideData={rtdbHashtags}
      rightSideData={newHashtags}
      messageIfBothSideIdentical={'No change found!'}
    />
  );
};

export {
  TableToolBox,
  getDisplayData,
  getConfirmItems,
  onDeleteComplete,
  TrendingHashTags,
  getConfirmHashTags,
  ApiTrendingHashTags,
  getHashTagDisplayData,
  ContentV2ListTableToolBox,
  ContentV2OperationStatus,
  DatabaseTrendingHashTags,
  TrendingHashTagsToolBox,
  getUpdatedHashtagsInfo,
}
