// Permission Table
// 000001 = prayer_requests, 1
// 000010 = read_codes, 2
// 000100 = write_codes, 4
// 001000 = read_users, 8
// 010000 = write_users, 16
// 100000 = change_user_permission, 32

exports.app = {
  name: "MuslimPro Firebase Admin Console",
  version: "1.0"
};

exports.developers = [
  'QRepVKUgSYMsQHIEJ2WpHm4BKUJ2',
  'i5ArJi6SeQYJfH4cngh1uHz2Sm73',
];
