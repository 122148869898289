import _ from "lodash";
import React, { Fragment, Component } from "react";
import { Row, Col, Alert, Button } from "react-bootstrap";
import BasicLayout from "../../layouts/basiclayout";
import { toast, ToastContainer } from "react-toastify";
import MultiFileUploader from "../../widgets/multiFileUploader";
import JSONPretty from "react-json-pretty";
import JSONPrettyMon from "react-json-pretty/dist/adventure_time";
import Collapse, { Panel } from "rc-collapse";
import "rc-collapse/assets/index.css";
import Papa from "papaparse";
import { LessonSupportedLanguages } from "./Constants";
import LessonIngestionService from "../../service_v2/content-ingestion/lesson-ingestion-service";
const sampleExcelFileUrl =
  "https://docs.google.com/spreadsheets/d/11J15fILNkMLNGzoOLQtzBypUA3n-3-pqWzdqvq-YM0Y/edit#gid=1939514503";
const SupportedLanguages = _.keys(LessonSupportedLanguages);
const languagesObject = SupportedLanguages.reduce((langsObj, language) => {
  langsObj[language] = language.toUpperCase();
  return langsObj;
}, {});

const languages = Object.keys(languagesObject).map((option) => {
  return {
    key: option,
    value: languagesObject[option],
  };
});
const CSV_CONTENT_CREATE_LIMIT = 10000;
const SPECIAL_HEADERS_COUNT = 1;

const InitialState = {
  uploading: false,
  verifying: false,
  files: [],
  allErrors: [],
  selectedLanguage: "default",
  isValidCSV: false,
};

const arrowPath =
  "M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88" +
  ".5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3." +
  "6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-0.7 5." +
  "2-2L869 536.2c14.7-12.8 14.7-35.6 0-48.4z";

function expandIcon({ isActive }) {
  return (
    <i style={{ marginRight: ".5rem" }}>
      <svg
        viewBox="0 0 1024 1024"
        width="1em"
        height="1em"
        fill="currentColor"
        style={{
          verticalAlign: "-.125em",
          transition: "transform .2s",
          transform: `rotate(${isActive ? 90 : 0}deg)`,
        }}
      >
        <path d={arrowPath} p-id="5827"></path>
      </svg>
    </i>
  );
}

const sanitizeContent = (rows) => {
  const sanitizedRows = rows.map((row) => {
    const sanitizedRow = {};
    for (const key in row) {
      if (row.hasOwnProperty(key)) {
        let value = row[key];
        if (value === "") {
          value = null;
        } else if (!isNaN(value) && value.indexOf(",") === -1) {
          value = Number(value);
        }
        sanitizedRow[key] = value;
      }
    }
    return sanitizedRow;
  });

  return sanitizedRows;
};

class LessonIngestionUploadCSV extends Component {
  constructor(props) {
    super(props);
    this.state = InitialState;
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach((file) => URL.revokeObjectURL(file.preview));
  }

  async publishLessonCsv(csvFile, language) {
    try {
      const uploadResponse = await LessonIngestionService.uploadLessonCsv(
        csvFile,
        language
      );
      toast.success("CSV uploaded successfully", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } catch (error) {
      console.log("CSV upload failed.", error);
      const errorData =
        (typeof error.message === "string" && error.message.startsWith("{")
          ? JSON.parse(error.message)
          : error?.message) || "";

      const errorMessages =
        errorData?.debug?.debugData?.validationError ||
        (typeof (errorData?.message || errorData) === "string"
          ? (errorData?.message || errorData).split("\n")
          : [errorData]);

      this.setState({
        allErrors: errorMessages,
      });
    } finally {
      const { allErrors } = this.state;
      if (allErrors.length === 0) {
        this.setState(InitialState);
      }
    }
  }

  async verifyLessonCSV(lessonContent) {
    try {
      const verified = await LessonIngestionService.validateLesson(
        lessonContent
      );
      if (verified) {
        this.setState({
          isValidCSV: true,
          verifying: false,
        });
        console.log("CSV verified successfully.");
        toast.success("CSV verified successfully, Ready to upload.", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (error) {
      const errorData =
        (typeof error.message === "string" && error.message.startsWith("{")
          ? JSON.parse(error.message)
          : error?.message) || "";

      const errorMessages = errorData?.debug?.debugData?.validationError
        ? errorData.debug.debugData.validationError
        : typeof errorData === "string"
        ? errorData.split("\n")
        : typeof errorData?.data?.errors === "string"
        ? errorData.data.errors.split("\n")
        : errorData.error_code && errorData.message
        ? [`${errorData.error_code} - ${errorData.message}`]
        : [errorData];

      this.setState({
        allErrors: errorMessages,
      });
    } finally {
      this.setState({ verifying: false });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const confirm = window.confirm(
      `Are you sure about uploading this file for creating lessons in Database ?`
    );
    if (confirm) {
      this.setState({ uploading: true });
      const { files } = this.state;
      const csvFile = files[0];
      const { selectedLanguage } = this.state;

      if (!csvFile.name.endsWith(".csv")) {
        this.setState({
          allErrors: ["Error: Please upload a valid CSV file."],
        });
        return;
      }

      this.publishLessonCsv(files[0], selectedLanguage);
    }
  }

  async handleVerify() {
    const confirm = window.confirm(
      `Are you sure about uploading this file for verify ?`
    );
    if (confirm) {
      const { selectedLanguage } = this.state;
      if (_.isEqual(selectedLanguage, "default")) {
        toast.error(`Please select the language and try again.`, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        return;
      }

      const { files } = this.state;
      const csvFile = files[0];
      if (!csvFile.name.endsWith(".csv")) {
        this.setState({
          allErrors: ["Error: Please upload a valid CSV file."],
        });
        return;
      }
      const reader = new FileReader();

      reader.onload = async () => {
        const csvData = reader.result;
        Papa.parse(csvData, {
          header: true,
          complete: (parsedData) => {
            const rows = parsedData.data;
            this.setState({ verifying: true });
            if (rows.length > CSV_CONTENT_CREATE_LIMIT) {
              this.setState({
                allErrors: [
                  `Error : You only can create/update ${CSV_CONTENT_CREATE_LIMIT} contents for a single upload. Uploaded file has ${
                    rows.length - SPECIAL_HEADERS_COUNT
                  } contents.`,
                ],
              });
            } else {
              const contents = sanitizeContent(rows);
              this.verifyLessonCSV(contents);
            }
          },
          error: (err) => {
            console.error("Error parsing CSV:", err);
          },
        });
      };

      reader.readAsText(csvFile);
    }
  }

  resetView() {
    this.setState(InitialState);
  }

  onDrop(files) {
    this.setState({
      files: files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
  }

  onSelectChange(type, e) {
    const value = e.target.value;

    this.setState({ selectedLanguage: value });

    if (type === "languages") {
      this.setState({ selectedLanguage: value });
    }
  }

  renderSelect(arr, selectedLanguage, type) {
    const options = arr.map((item, i) => {
      return (
        <option value={item.key} key={i}>
          {item.value}
        </option>
      );
    });

    return (
      <select
        value={selectedLanguage}
        onChange={this.onSelectChange.bind(this, type)}
        className="form-control"
      >
        <option disabled value="default">
          Please select one
        </option>
        {options}
      </select>
    );
  }

  removeFile(file) {
    const newFiles = [...this.state.files];
    newFiles.splice(newFiles.indexOf(file), 1);
    this.setState({ files: newFiles, verifying: false, isValidCSV: false });
  }

  removeAllFiles() {
    this.setState({ files: [], verifying: false, isValidCSV: false });
  }

  renderVerifyButton(verifying, filesLength, isValidCSV) {
    if (verifying) {
      console.log("Verifying.....");
      return (
        <Button
          disabled={true}
          className="btn-primary content_editor_submit_btn"
          style={{ marginRight: "10px" }}
        >
          Verifying...
        </Button>
      );
    } else {
      return (
        <Button
          disabled={filesLength === 0 || isValidCSV}
          type="button"
          onClick={this.handleVerify.bind(this)}
          className="btn-primary content_editor_submit_btn"
          style={{ marginRight: "10px" }}
        >
          Verify
        </Button>
      );
    }
  }

  renderSubmitButton(uploading, filesLength, isValidCSV) {
    if (isValidCSV) {
      if (uploading) {
        return (
          <Button
            disabled={true}
            className="btn-primary content_editor_submit_btn"
          >
            Uploading...
          </Button>
        );
      } else {
        return (
          <Button
            disabled={filesLength === 0}
            type="submit"
            className="btn-primary content_editor_submit_btn ml-2"
          >
            Upload
          </Button>
        );
      }
    } else {
      return <></>;
    }
  }

  render() {
    const {
      files,
      allErrors,
      isValidCSV,
      uploading,
      verifying,
      selectedLanguage,
    } = this.state;

    return (
      <BasicLayout pagePermission="readonly">
        <ToastContainer autoClose={3000} />
        <div>
          <form
            className={"form-inline"}
            onSubmit={this.handleSubmit.bind(this)}
          >
            <Row>
              <h3>Lesson Ingestion - CSV Upload</h3>
            </Row>

            {allErrors.length > 0 ? (
              <div className={"content-v2-excel-upload-errors-container"}>
                <Row>
                  <button
                    type={"button"}
                    onClick={this.resetView.bind(this)}
                    className="btn btn-primary"
                  >
                    {"Start a new Upload"}
                    &nbsp;
                    <span
                      className="glyphicon glyphicon-upload"
                      aria-hidden="true"
                    />
                  </button>
                </Row>
                <br />
                <Row>
                  <Col>
                    <h4>
                      {
                        "CSV file contains following errors. Please fix them and try again :"
                      }
                    </h4>
                    <br />
                    <JSONPretty
                      id="content-v2-excel-upload-error-list"
                      data={JSON.stringify(allErrors)}
                      theme={JSONPrettyMon}
                    />
                  </Col>
                </Row>
              </div>
            ) : (
              <Fragment>
                <Row>
                  <Col xs={8}>
                    <div style={{ margin: "15px" }} className="form-group">
                      <Alert>
                        <strong style={{ fontSize: "20px" }}>
                          Important Note (Please read before you upload):
                        </strong>
                        <br />
                        <Collapse
                          accordion={true}
                          className={"upload-note-collapse"}
                          expandIcon={expandIcon}
                        >
                          <Panel
                            header="Content Upload Guide"
                            headerClass="excel-upload-note-header"
                            extra={<span>Click to see/hide Details</span>}
                          >
                            <ol>
                              <li>
                                All fields that are marked with&nbsp;
                                <strong>
                                  <u>required</u>
                                </strong>{" "}
                                should be available when uploading the file.
                              </li>
                              <li>
                                <strong>
                                  <u>Required fields are -</u>
                                </strong>
                                'Lesson ID','Lesson category', 'Category
                                id','Category title','Card
                                sequence','Sura','Aya','Card type'.
                              </li>
                              <li>
                                <strong>
                                  <u>Card type</u>
                                </strong>{" "}
                                and{" "}
                                <strong>
                                  <u>Card tag</u>
                                </strong>{" "}
                                value must be in &nbsp;
                                <strong>
                                  <u>lowercase</u>
                                </strong>{" "}
                                like,<strong>Card type:</strong>{" "}
                                intro,review_complete etc.
                                <strong>Card tag:</strong> hadith,quranic_verse
                                etc.{" "}
                              </li>
                              <li>
                                Select the appropriate &nbsp;
                                <strong>
                                  <u>Language</u>
                                </strong>{" "}
                                for the content you are{" "}
                                <strong>
                                  <u>uploading.</u>
                                </strong>{" "}
                              </li>
                              <li>
                                if you have file in{" "}
                                <strong>xlsx or google sheet</strong> convert or
                                download it as &nbsp;
                                <strong>
                                  <u>CSV.</u>
                                </strong>
                              </li>
                              <li>
                                You can only upload <u>one file at a time</u>{" "}
                                and it should be in&nbsp;
                                <strong>
                                  <u>CSV Type </u>
                                </strong>
                                (i.e [your_file_name].csv)
                              </li>
                            </ol>
                          </Panel>
                        </Collapse>
                        <label>See the Sample Excel Sheet : </label>
                        <a href={sampleExcelFileUrl} target="_blank">
                          {" "}
                          Click to see the sample excel sheet (download as CSV).
                        </a>
                      </Alert>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div style={{ marginRight: "8px" }} className="form-group">
                      <label style={{ marginRight: "5px" }}>
                        {"Language : "}
                      </label>
                      {this.renderSelect(
                        languages,
                        selectedLanguage,
                        "languages"
                      )}
                    </div>
                  </Col>
                </Row>
                <MultiFileUploader
                  files={files}
                  showPreviews={files.length > 0}
                  onRemoveFile={this.removeFile.bind(this)}
                  showRemoveAllButton={false}
                  multiple={false}
                  onRemoveAllFiles={this.removeAllFiles.bind(this)}
                  onDropFiles={this.onDrop.bind(this)}
                  dropFileMainText={
                    "Click here to upload or Drag and drop the csv file."
                  }
                />
                <div className="form-group">
                  {this.renderVerifyButton(verifying, files.length, isValidCSV)}
                  {this.renderSubmitButton(uploading, files.length, isValidCSV)}
                </div>
              </Fragment>
            )}
          </form>
        </div>
      </BasicLayout>
    );
  }
}

export default LessonIngestionUploadCSV;
