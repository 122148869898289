import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  hajjAndUmrahAddOrUpdate,
} from '../../services/api-v1-services/other-services';

const createOrUpdate = () => {
  const [ responseData, setResponseData ] = useState({ });
  const [ createUpdateData, setCreateUpdateData ] = useState(null);
  const {
    items,
    onOperationComplete,
  } = createUpdateData || {};

  const createOrUpdateItems = async () => {
    try {
      console.log(`Create or update items...`);
      setResponseData({
        isUpdating: true,
        isError: false,
      });

      const hajjAndUmrahUpdateApiResp = await hajjAndUmrahAddOrUpdate({
        contents: items,
      });
      const responseData = hajjAndUmrahUpdateApiResp.data;

      let errorMsg = null;
      let isError = false;

      if(responseData.success) {
        console.log('Items Create or Update Success!!');
      } else {
        console.log('Items Create or Update Failed!!');
        errorMsg = `Failed to create/update items!`;
        isError = true;
      }

      setResponseData({
        isUpdating: false,
        isError,
        errorMsg,
        data: responseData.data,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError,
          errorMsg,
        });
      }
    } catch (err) {
      console.log('Something went wrong while create/update items. ', err);
      const errorMsg = `Error occurred while create/update items.`;
      setResponseData({
        isUpdating: false,
        isError: true,
        errorMsg,
        data: null,
      });
      if(onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(createUpdateData)) {
      createOrUpdateItems();
    }
  }, [ createUpdateData ]);

  return [
    responseData,
    setCreateUpdateData,
  ];
};

export default createOrUpdate;
