import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  listMailGunEmailTags,
} from '../../services/mp-cf-api-service';

const listEmailTags = () => {
  const [ responseData, setResponseData ] = useState({});
  const [ fetchData, setFetchData ] = useState(null);
  const {
    limit = 100,
    onOperationComplete,
  } = fetchData || {};

  const listMGEmailTags = async () => {
    try {
      console.log(`Fetching email tags, limit: ${limit}...`);
      setResponseData({
        isFetching: true,
        isError: false,
      });

      const listEmailTagsApiResp = await listMailGunEmailTags({
        limit,
      });
      const responseData = listEmailTagsApiResp.data;

      let errorMsg = null;
      let isError = false;

      if(responseData.success) {
        console.log('Mail Gun Email Tags Fetch Success!!');
      } else {
        console.log('Mail Gun Email Tags fetch Failed!!');
        errorMsg = `Failed to list email tags.`;
        isError = true;
      }

      setResponseData({
        isFetching: false,
        isError,
        errorMsg,
        data: responseData.payload,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError,
          errorMsg,
          data: responseData.data,
        });
      }
    } catch (err) {
      console.log('Something went wrong while fetching tags. ', err);
      const errorMsg = `Error occurred while fetching tags.`;
      setResponseData({
        isFetching: false,
        isError: true,
        errorMsg,
        data: null,
      });
      if(onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
          data: null,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(fetchData)) {
      listMGEmailTags();
    }
  }, [ fetchData ]);

  return [
    responseData,
    setFetchData,
  ];
};

export default listEmailTags;
