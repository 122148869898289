import {
  uploadImagesToGCSBucket,
} from '../mp-service-v1-api-service';

const getProjectAndBucketName = (projectNameToUpload, bucketNameToUpload) => {
  const projectName = projectNameToUpload || 'muslimproapi';
  const bucketName = bucketNameToUpload || 'mp_cdn_origin';
  return [projectName, bucketName];
};

/**
 *
 * @param file - File
 * @param options - Object -  Contains all the required options to upload an image.
 * (fileUploadPath --> /images/hajj_and_umrah/en/image.jpeg )
 * @returns {Promise<*>}
 */
const uploadNewImage  = async (file, options) => {
  const encodedFiles = [];
  const {
    resizeImages=true,
    optimizeImages=false,
    metaData={},
    makePublic=true,
    clearCdnCache=true,
    projectNameToUpload,
    bucketNameToUpload,
    fileUploadPath,
  } = options || {};

  if(!fileUploadPath) {
    return false;
  }

  const encodedImage = await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => resolve(e.target.result);
    fileReader.readAsDataURL(file);
  });

  const base64EncodedImage = encodedImage.replace('data:image/jpeg;base64,', '')
      .replace('data:image/png;base64,', '');

  const metadata = {
    contentType: file.type,
    cacheControl: 'public, max-age=31536000',
    ...metaData,
  };

  encodedFiles.push({
    fileBuffer: base64EncodedImage,
    options: {
      metadata,
    },
    fileType: 'image',
    fileUploadPath,
    makePublic: makePublic, // This is options, default is makePublic: true, kept as a API reference.
    clear_cdn_cache: clearCdnCache,
  });

  const [project, bucketName] = getProjectAndBucketName(projectNameToUpload, bucketNameToUpload);

  return uploadImagesToGCSBucket({
    files: encodedFiles,
    clearCdnCache: clearCdnCache,
    bucketName,
    project,
    optimizeImages,
    resizeImages,
  });
};

export {
  uploadNewImage,
};
