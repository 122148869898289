import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  settingsTestSettingsAPIResponse,
} from '../../services/mp-service-v1-api-service';

const testSettingsAPI = () => {
  const [ apiCallData, setApiCallData ] = useState({ });
  const [ testAPIData, setTestAPIData ] = useState(null);
  const {
    apiInputValues,
    onOperationComplete,
  } = testAPIData || {};


  const testSettingsAPIEndpoint = async () => {
    try {
      console.log(`Testing settings API with -> ${JSON.stringify(apiInputValues)}...`);
      setApiCallData({
        isFetching: true,
        isError: false,
      });
      const getRawFileApiResp =  await settingsTestSettingsAPIResponse(apiInputValues);

      const responseData = getRawFileApiResp.data;
      let errorMsg = null;

      setApiCallData({
        data: responseData,
        isFetching: false,
        isError: false,
        errorMsg,
      });
      onOperationComplete({
        isError: false,
        errorMsg,
      });
    } catch (err) {
      console.log(`Error occurred while getting settings API Response. Error : ${err}`);
      const errorMsg = `Error occurred while getting settings API Response.`;
      setApiCallData({
        data: null,
        isFetching: false,
        isError: true,
        errorMsg,
      });
      onOperationComplete({
        isError: true,
        errorMsg,
      });
    }
  };

  useEffect( () => {
    if(!_.isEmpty(testAPIData)) {
      testSettingsAPIEndpoint();
    }
  }, [ testAPIData ]);

  return [
    apiCallData,
    setTestAPIData,
  ];
};

export default testSettingsAPI;
