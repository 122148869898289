import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  qalBoxDeleteMedia,
  qalBoxDeletehDisplayCategories,
  qalBoxDeleteMediaTag,
  qalBoxDeleteGenre
} from '../../services/mp-cf-api-service';

const deleteItemsCall = async (type, deleteConfig) => {
  console.log({type})
  let mediaItemsDeleteApiResp = null;
   switch(type) {
      case 'displayCategory':
          mediaItemsDeleteApiResp = await qalBoxDeletehDisplayCategories(deleteConfig);
          return _.get(mediaItemsDeleteApiResp, "data", {})
      case 'genres':
          mediaItemsDeleteApiResp = await qalBoxDeleteGenre(deleteConfig);
          return _.get(mediaItemsDeleteApiResp, "data", {})
      case 'mediaTag':
          mediaItemsDeleteApiResp = await qalBoxDeleteMediaTag(deleteConfig);
          return _.get(mediaItemsDeleteApiResp, "data", {})
      default :
          mediaItemsDeleteApiResp = await qalBoxDeleteMedia(deleteConfig);
          return _.get(mediaItemsDeleteApiResp, "data", {});
  }
}

const mediaDelete = () => {
  const [ deleteResponseData, setResponseData ] = useState({ });
  const [ deleteData, setDeleteOperationData ] = useState(null);
  const {
    deleteConfig,
    onOperationComplete,
  } = deleteData || {};

  const deleteMediaItems = async () => {
    try {
      console.log(`QalBox deleting media...`);
      setResponseData({
        isDeleting: true,
        isError: false,
      });

      const type = deleteConfig.deleteType;
      const responseData = await deleteItemsCall(type, deleteConfig);

      let errorMsg = null;
      let isError = false;

      if(responseData.success) {
        console.log('QalBox search media items delete Success!!');
      } else {
        console.log('QalBox search media items delete Failed!!');
        errorMsg = `Failed to delete QalBox media items.`;
        isError = true;
      }

      setResponseData({
        isDeleting: false,
        isError,
        errorMsg,
        data: responseData.payload,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError,
          errorMsg,
          data: responseData.payload,
        });
      }
    } catch (err) {
      console.log('Something went wrong while deleting QalBox media items. ', err);
      const errorMsg = `Error occurred while deleting QalBox media items.`;
      setResponseData({
        isDeleting: false,
        isError: true,
        errorMsg,
        data: null,
      });
      if(onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
          data: null,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(deleteData)) {
      deleteMediaItems();
    }
  }, [ deleteData ]);

  return [
    deleteResponseData,
    setDeleteOperationData,
  ];
};

export default mediaDelete;
