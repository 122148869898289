import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  addMailGunEmailTag,
} from '../../services/mp-cf-api-service';

const addEmailTag = () => {
  const [ responseData, setResponseData ] = useState({ });
  const [ addData, setAddData ] = useState(null);
  const {
    tag,
    description='',
    onOperationComplete,
  } = addData || {};

  const addMGEmailTag = async () => {
    try {
      console.log(`Adding email tag ${tag}...`);
      setResponseData({
        isUpdating: true,
        isError: false,
      });

      const addEmailTagApiResp = await addMailGunEmailTag({
        tag,
        description,
      });
      const responseData = addEmailTagApiResp.data;

      let errorMsg = null;
      let isError = false;

      if(responseData.success) {
        console.log('Mail Gun Tag Add Success!!');
      } else {
        console.log('Mail Gun Tag Add Failed!!');
        errorMsg = `Failed to add email tag : ${tag}`;
        isError = true;
      }

      setResponseData({
        isUpdating: false,
        isError,
        errorMsg,
        data: responseData.data,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError,
          errorMsg,
          data: responseData.payload,
        });
      }
    } catch (err) {
      console.log('Something went wrong while adding tag. ', err);
      const errorMsg = `Error occurred while adding tag: ${tag}`;
      setResponseData({
        isUpdating: false,
        isError: true,
        errorMsg,
        data: null,
      });
      if(onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
          data: null,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(addData)) {
      addMGEmailTag();
    }
  }, [ addData ]);

  return [
    responseData,
    setAddData,
  ];
};

export default addEmailTag;
