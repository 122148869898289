import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view"; // Import React JSON View

const QalboxJSONEditor = ({ jsonObject, onChange }) => {
  const [json, setJson] = useState();
  const [error, setError] = useState(null); // State to hold JSON parsing errors

  const handleJSONInput = (e) => {
    const { value } = e.target;
    setJson(value);
    
    try {
      const parsedValue = JSON.parse(value);
      setError(null); // Clear previous error
      onChange(parsedValue);
    } catch(e) {
      setError(e.message); // Set error message
    }
  };

  useEffect(() => {
    if (jsonObject) {
      setJson(JSON.stringify(jsonObject, null, 2)); // Format JSON with indentation
    }
  }, [jsonObject])

  return (
    <div>
      <textarea
        value={json}
        onChange={handleJSONInput}
        rows={10} // Adjust the number of rows as needed
        style={{ width: "100%" }} // Adjust the width as needed
      />
      {error && <div style={{ color: "red" }}>{error}</div>} {/* Display error message */}
      {/* Display formatted JSON */}
      {json && !error && <ReactJson src={JSON.parse(json)} theme="monokai" />}
    </div>
  );
};

export default QalboxJSONEditor;