import _ from 'lodash';
import React,
{
  Fragment,
  Component,
} from 'react';
import moment from 'moment';
import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import firebase from 'firebase/app';
import {
  Icon,
} from 'react-fa';
import DateTime from '../../widgets/datetime';
import {
  CopyToClipboard,
} from 'react-copy-to-clipboard';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  createLog,
} from '../../services/logs';
import {
  getTypeBySKU,
} from '../../services/utils';
import InlineWrapper from '../../widgets/roledwrapper/inlinewrapper';
import DataModeratorWrapper from '../../widgets/roledwrapper/datamoderatorwrapper';
import PremiumActivatedBy from './premiumActivatedBy';
import PrecalcDetails from './precalcDetails';
import RedeemPremiumCode from './redeemPremiumCode';
import {
  RoleManager,
} from '../../services/roleManager';
import {
  trackUser,
  adminConsoleDeleteUser,
  adminConsoleRevalidatePurchase,
  adminConsoleSendVerificationEmail,
} from '../../services/mp-cf-api-service';
import {
  USER_TRACKING_TYPE,
} from './Constants';
import {
  Link,
} from 'react-router-dom';
import {
  confirmAlert,
} from "react-confirm-alert";
import AppModal from '../../widgets/modal';
import {
  DownloadUserData,
} from './components';
import {
  updateUserFreeTrialStatus,
} from '../../services/rtdb-service';
import {
  getFreeTrialStatus,
} from './helper';
import {
  getModerator,
  updateModerator,
} from '../../services/admin-db-service';
import {
  getRewardDef,
  getUserRewardMeta,
} from '../../services/reward-db-service';
import {
  md5,
  removeUserPremiumForTest,
} from '../../utils';
import {
  setTransactionEntryItem,
  removeTransactionEntryItem,
  getTransactionEntrySnapshotWithCallback,
} from '../../repositories/transactionRepository';
import {
  RTDB_DATABASE,
  RTDB_DATABASE_URL,
  RTDB_DATABASE_NODE,
} from "../../Constants";
import PremiumService from "../../service_v2/premium/premium-service";

let roleManager;

const rewardDBUserURL = `${RTDB_DATABASE_URL}${RTDB_DATABASE.REWARDS}/${RTDB_DATABASE_NODE.USER_REWARDS}/`;

class ResultItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      purchases: [],
      modSts: null,
      usersPermission: null,
      showPremiumActivatedBy: false,
      definitions: [],
      meta: [],
      streakRewardClaimed: false,
      userReceipt: '',
      onRefresh: props.onRefresh,
      restoreTransfer: false,
      restoreTransferConfirmed: false,
    };
    roleManager = new RoleManager(firebase);
  }

  UNSAFE_componentWillMount() {
    const user = this.props.user;
    const premium_status = user.muslimProAcc.premium_status
    const purchases = (premium_status && premium_status.purchases) || null;
    this.validatePurchases(premium_status, user.firebaseAcc.uid);
    const { firebaseAcc } = user;
    const { uid } = firebaseAcc;
    this.getModeratorInfo(uid, firebaseAcc);
    this.onStreakRewardClaimed(purchases, uid);
    roleManager.getCurrentUserPermission().then((usersPermission) => {
      this.setState({
        usersPermission
      });
    });
  }

  renderMatched(str) {
    let { searchString } = this.props;
    if(!str) {
      return '-';
    }

    if(str.charAt(0) === '+' && searchString.charAt(0) !== '+') {
      searchString = `+${searchString}`;
    }

    if(str === searchString) {
      return <span className="matched__string">{str}</span>;
    }
    return <span>{str}</span>;
  }

  validatePurchases(premium_status, uid) {
    let purchases = [];
    if(!premium_status || !premium_status.purchases) {
      return Promise.resolve();
    }
    const { active_sku } = premium_status;
    const purchasesObj = premium_status.purchases;
    const purchaseKeys = Object.keys(purchasesObj);
    const promises = purchaseKeys.map((key, i) => {
      const purchase = purchasesObj[key];
      if(purchase.token) {
        const md5Token = md5(purchase.token);
        const { duration_unit, recurring, token, store, sku, receipt, purchase_time, expiry_date, price } = purchase;
        let p = {
          duration_unit,
          recurring,
          token,
          store,
          sku,
          receipt,
          purchase_time,
          expiry_date,
          price: (price !== null && price!== undefined) ? price : null
        };
        return getTransactionEntrySnapshotWithCallback(md5Token, (snapshot) => {
          const transactions = snapshot.val();
          if(transactions) {
            Object.keys(transactions).forEach((tKey) => {
              const t = transactions[tKey];
              if(t.token === purchase.token && t.user_id !== uid) {
                p.originalUserId = t.user_id;
              }
            });
          }
          purchases.push(p);
        });
      } else {
        return Promise.resolve();
      }
    });

    return Promise.all(promises).then(() => {
      this.setState({
        purchases,
        active_sku
      });
    });
  }

  hasActivePremium(premiumStatus){
    const now = moment();
    return ( premiumStatus && premiumStatus.premium != "none" && now.isSameOrBefore( moment(premiumStatus.expiry) ) )
  }
  renderConnectedDevices(devices) {
    if(!devices) {
      return <p>N/A</p>
    }
    return Object.keys(devices).map((key, i) => {
      const device = devices[key];
      return <p className="conn__devices" key={i}>
        <span className="pull-left">{device.manufacturer} {device.name} at</span> <span className="pull-right small"><DateTime timestamp={device.last_used_timestamp} /></span>
      </p>;
    });
  }

  showPremiumActivatedByComp() {
    this.setState({
      showPremiumActivatedBy: true
    });
  }

  renderPremiumInfo(status) {
    if(!this.hasActivePremium(status)) {
      const now = moment();
      const statusError = status && status.error;
      return <div className="resultitem__group">
        <Row>
          <Col className="text-right" xs={5}>Premium activated</Col>
          <Col xs={6}>
            No { now.isSameOrBefore(status && status.expiry) ? "" : "(Expired)"}
          </Col>
        </Row>
        {this.renderRowIfAvailable(statusError, "Premium error", statusError)}
      </div>
    } else {
      const restorationCount = status.purchases ? Object.keys(status.purchases).length : 0;
      return <div className="resultitem__group">
        <Row>
          <Col className="text-right" xs={5}>Premium activated</Col>
          <Col xs={6}>
            Yes <Icon className="resultitems__purchase_token_ok_icon" name="check-circle-o" />
          </Col>
        </Row>
        <Row>
          <Col className="text-right" xs={5}>Premium type</Col>
          <Col xs={6}>
            {status.premium}
          </Col>
        </Row>
        <Row>
          <Col className="text-right" xs={5}>Active SKU</Col>
          <Col xs={6}>
            {status.active_sku || 'N/A'}
          </Col>
        </Row>
        <Row>
          <Col className="text-right" xs={5}><u>Premium Entitlements</u></Col>
          <Col xs={6}></Col>
        </Row>
        <Row>
          <Col className="text-right" xs={5}>Muslim Pro</Col>
          <Col xs={6}>{ _.get(status, "entitled_features.muslimpro", false) ? "Yes" : "No" }</Col>
        </Row>
        <Row>
          <Col className="text-right" xs={5}>QalBox</Col>
          <Col xs={6}>{ _.get(status, "entitled_features.qalbox", false) ? "Yes" : "No" }</Col>
        </Row>
        <Row>
          <Col className="text-right" xs={5}>Premium activated by</Col>
          <Col xs={6}>
            {this.state.showPremiumActivatedBy ? <PremiumActivatedBy user={this.props.user} /> : <p onClick={this.showPremiumActivatedByComp.bind(this)} className="small text-warning text-btn">Show info</p>}
          </Col>
        </Row>
        {this.renderYesNo(status.free_trial, "Free trial")}
        {this.renderRowIfAvailable(status.expiry, "Will be expired on", <DateTime timestamp={status.expiry} />)}
        {this.renderRowIfAvailable((status.premium === "none"), "Number of tentative for restorations", restorationCount)}
      </div>
    }
  }

  transferRestoreErrorPremium(restore_error) {
    console.log('restore error: ', restore_error);
    this.setState({
      restoreTransfer: true,
    });
  }

  clearTransferPremiumBox() {
    this.setState({
      restoreTransfer: false,
    });
  }

  async confirmTransferPremium(restoreError) {
    this.setState({
      restoreTransfer: false,
      restoreTransferConfirmed: true,
    })

    const {
      firebaseAcc,
    } = this.props.user;
    const {
      uid,
    } = firebaseAcc;

    try {
      await PremiumService.restorePremium(uid, restoreError.usedBy);
      toast.success('Successfully restored premium', {
        position: toast.POSITION.BOTTOM_LEFT
      });
      setTimeout(() => window.location.reload(), 2000);
    } catch (err) {
      console.error(err);
      toast.error('Premium restore failed', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }

    this.setState({
      restoreTransfer: false,
      restoreTransferConfirmed: false,
    })
  }

  renderRestoreError(status) {
    if(status && status.restore_error) {
      const restoreError = status.restore_error;
      return <div className="resultitem__group">
        <Row>
          <Col style={{ color: 'red'}} className="text-right" xs={5}>Restore Attempt Failed</Col>
          <Col style={{ color: 'red'}} className="text-left" xs={5}>
            {restoreError && restoreError.store === 'apple' && <Button onClick={() => this.transferRestoreErrorPremium(restoreError)} style={{ marginBottom: '10px', marginTop: '0'}} className="btn btn-xs btn-success copy__button"><Icon name="repeat" />Restore Premium</Button>}
          </Col>
        </Row>
        {this.state.restoreTransfer && <Row>
          <Col xs={12}>
            <Row className="confirmation__section">
              <Col className="confirmation__section_header" md={12}>
                <Icon name="exclamation-circle" /> Confirm premium restore
              </Col>
              <Col md={12}>
                <p className="small">This action will remove the <span style={{ color: 'red', fontSize: '13px'}}>{restoreError.premiumType}</span > premium purchase <span style={{ color: 'red', fontSize: '13px'}}>{restoreError.sku}</span> from
                  <a target="_blank" href={window.location.origin + '/search-user/' + restoreError.usedBy}> {restoreError.usedBy} </a>
                  and restore it to the current account. Do you wish to continue?</p>
              </Col>
              <Col md={12} style={{ marginTop: '20px'}}>
                <Button style={{marginRight: '10px'}} onClick={() => this.confirmTransferPremium(restoreError)} className="transfer__button btn-danger">Confirm Restore</Button>
                <Button onClick={this.clearTransferPremiumBox.bind(this)} className="transfer__button btn-default">Cancel</Button>
              </Col>
            </Row>
          </Col>
        </Row>}
        {
          this.state.restoreTransferConfirmed && <Row>
          <Col xs={12}>
            <Row className="confirmation__section" style={{ textAlign: 'center'}}>
              <Col className="confirmation__section_header" md={12}>
                <Icon name="exclamation-circle" /> Confirm premium restore
              </Col>
              <Col md={12}>
                <p className="small">Processing premium restore...</p>
              </Col>
            </Row>
          </Col>
          </Row>
        }
        <Row>
          <Col className="text-right" xs={5}>Restore attempted on:</Col>
          <Col xs={6}>
            <span className={'small'}>{new Date(restoreError.timestamp).toLocaleString()} SGT</span>
          </Col>
        </Row>
        <Row>
          <Col className="text-right" xs={5}>sku:</Col>
          <Col xs={6}>{restoreError.sku} <span className={'small'}>({restoreError.skuPlatform}:{restoreError.premiumType})</span>
          </Col>
        </Row>
        <Row>
          <Col className="text-right" xs={5}>Restore platform:</Col>
          <Col xs={6}>
            {restoreError.store}
          </Col>
        </Row>
        <Row>
          <Col className="text-right" xs={5}>Restore error:</Col>
          <Col xs={6}>
            {restoreError.errorString || ''}: <a
                target="_blank"
                href={window.location.origin + '/search-user/' + restoreError.usedBy}
            >
              {this.renderMatched(restoreError.usedBy)}
            </a>
          </Col>
        </Row>
        <Row>
          <Col className="text-right" xs={5}>Transaction reference:</Col>
          <Col xs={6}>
            <a
              target="_blank"
              href={`https://console.firebase.google.com/project/muslim-pro-app/database/${RTDB_DATABASE.PREMIUM_TRANSACTION}/transaction-userid/` + restoreError.md5Token}
            >
            {this.renderMatched(restoreError.md5Token)}
          </a>
          </Col>
        </Row>
      </div>
    }
    return null;
  }

  renderStreakRewardInfo(definitions, meta) {
    const {
      firebaseAcc,
    } = this.props.user;
    const {
      uid,
    } = firebaseAcc;

    const lastClaimedReward = _.get(meta, 'last_claimed_reward');
    // get claimed timestamp for latest claimed reward
    const rewardInfo = _.get(meta.rewards, lastClaimedReward);
    const claimedTimestamp = _.get(rewardInfo, 'claimed_timestamp');

    // find reward definition data of latest claimed reward
    const def = _.get(definitions, lastClaimedReward);
    const defConfig = _.get(def, 'config');
    const streakDays = _.get(defConfig, 'streak_days');

    // format reward validity from claim
    const startTimestamp = moment(claimedTimestamp).format("MMM DD YYYY");
    const endTimestamp = moment(claimedTimestamp).add(streakDays, "days").format("MMM DD, YYYY");
    const rewardURL = `${rewardDBUserURL}/${uid}/STREAK/meta/rewards/${lastClaimedReward}`;
    console.log('rewardURL : ', rewardURL);
    return (
      <>
        <Row>
          <Col className="text-right" xs={5}>Streak Reward Claimed</Col>
          <Col xs={6}>{lastClaimedReward ?
            <a href={rewardURL}>{lastClaimedReward}</a> : ''}</Col>
        </Row>
        <Row>
          <Col className="text-right" xs={5}>Streak Reward Definition</Col>
          <Col xs={6}>{def ? def.reward : ''}</Col>
        </Row>
        <Row>
          <Col className="text-right" xs={5}>Streak Claim Validity</Col>
          <Col xs={6}>{startTimestamp} - {endTimestamp}</Col>
        </Row>
      </>
    )
  }

  renderYesNo(checker, label) {
    let bl = 'No';
    if(checker) {
      bl = 'Yes';
    }
    return (
      <Row>
        <Col className="text-right" xs={5}>{label}</Col>
        <Col xs={6}>
          {bl}
        </Col>
      </Row>
    );
  }

  renderRowIfAvailable(checker, label, value) {
    if(checker) {
      return (
        <Row>
          <Col className="text-right" xs={5}>{label}</Col>
          <Col xs={6}>
            {value}
          </Col>
        </Row>
      );
    }
    return null;
  }

  renderPurchaseTime(timestamp) {
    if(timestamp) {
      return <p className="small"><strong>Purchase time:</strong> <DateTime timestamp={timestamp} /></p>
    }
    return null;
  }

  renderExpiryTime(timestamp) {
    if(timestamp) {
      return <p className="small"><strong>Expiry time:</strong> <DateTime timestamp={timestamp} /></p>
    }
    return null;
  }

  onDeleteUser(uid) {
    const confirmation = window.confirm("Do you really want to delete this user?");
    if(confirmation) {
      const data = {
        uid,
      };
      return adminConsoleDeleteUser(data)
      .then((res) => {
        createLog('delete_user', {
          user_id: uid
        });
        toast.success('Successfully deleted user', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      })
      .catch((err) => {
        toast.error(`User delete failed ${err}`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
      });
    }
  }

  onDownloadData(firebaseAcc, muslimProAcc) {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            modalBody={
              <DownloadUserData
                firebaseData={firebaseAcc}
                mpAccountData={muslimProAcc}
              />
            }
            headingText={`Select and Download user data.`}
            closeButtonText={'Close'}
            handleClose={onClose}
            handleOk={() => {
              onClose();
            }}
          />
        );
      }
    });
  }

  async onAddRemoveFreeTrial(uid, status=true) {
    const confirmation = window.confirm(`Do you really want to ${status ? 'add free trial to' : 'remove free trial from'} this user?`);
    if(confirmation) {
      try {
        await updateUserFreeTrialStatus({
          status,
          uid,
        });
        createLog('user_add_remove_free_trial_success', {
          status,
          uid,
        });
        toast.success(`Successfully updated free trial status for user with id : ${uid} `, {
          position: toast.POSITION.BOTTOM_LEFT
        });
        this.state.onRefresh( uid );
      } catch (err) {
        toast.error(`Failed to update free trial status for user with id ${uid}. Error ${JSON.stringify(err)}`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }
    }
  }

  fixEmptyUser(purchase, uid) {
    const confirmation = window.confirm("Do you really want to fix the empty user issue for this user?");
    if (confirmation) {
      const data = {
        purchase,
        uid,
        revalidateType: 'fixEmptyUser',
      };
      return adminConsoleRevalidatePurchase(data).then(() => {
        toast.success("Empty user fix successful!", {
          position: toast.POSITION.BOTTOM_LEFT
        });
        setTimeout(()=> {
          window.location.reload();
        }, 1000);
      }).catch((err) => {
        const errorMessage = err.response ? err.response.data.error : 'Empty user fix failed.';
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_LEFT
        })
      });
    }
  }


  async onToggleTrackingUser({ uid, disable_bq_tracking }) {
    const confirmation =
      window.confirm(`Do you really want to ${disable_bq_tracking ? 'enable' : 'disable'} bq tracking for this user?`);
    if(confirmation) {
      const data = {
        uid,
        trackingType: USER_TRACKING_TYPE.BQ_LOGS,
        trackingData: {
          node: 'disable_bq_tracking',
          status: Boolean(!disable_bq_tracking),
        },
      };

      try {
        const res = await trackUser(data);
        console.log('Res : res', res)
        toast.success('Successfully updated firebase tracking configs for the user', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      } catch (err) {
        toast.error(`Failed to update the firebase tracking configs for the user. Error : ${err}`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }
    }
  }

  revalidatePurchase(purchase, uid) {
    const confirmation = window.confirm("Do you really want to revalidate this purchase?");
    if(confirmation) {
      const data = {
        purchase,
        uid,
        revalidateType: 'default',
      };

      return adminConsoleRevalidatePurchase(data)
      .then((res) => {
        return getTransactionEntrySnapshotWithCallback(md5(purchase.token), (snap) => {
          const transactionUIDs = snap.val();
          if(transactionUIDs) {
            const keys = Object.keys(transactionUIDs);
            if(keys.length > 1) {
              const lastKey = keys[keys.length-1];
              const lastObj = transactionUIDs[lastKey];
              if(!purchase || !purchase.token) {
                toast.error("Revalidate not completed. Invalid purchase.", {
                  position: toast.POSITION.BOTTOM_LEFT
                });
                return Promise.resolve();
              }
              return removeTransactionEntryItem(md5(purchase.token)).then(() => {
                return setTransactionEntryItem(lastKey, lastObj).then(() => {
                  toast.success("Revalidate successful!", {
                    position: toast.POSITION.BOTTOM_LEFT
                  })
                  this.state.onRefresh( uid )
                });
              });

            } else {
              toast.success("Revalidate successful!", {
                position: toast.POSITION.BOTTOM_LEFT
              });
              this.state.onRefresh( uid )
            }
          } else {
            toast.error("Revalidate not completed. No entry found under transaction user_id!", {
              position: toast.POSITION.BOTTOM_LEFT
            });
          }
        });
      })
      .catch((err) => {
        toast.error(`Revalidate failed ${err}`, {
          position: toast.POSITION.BOTTOM_LEFT
        })
      });
    }
  }

  restoreStripePurchase(purchase, uid) {
    console.log("stripedata", purchase, uid )
    const confirmation = window.confirm("Do you really want to restore this purchase?");
    if(confirmation) {
      return PremiumService.restoreStripePurchase(uid)
        .then((res) => {
          if( res.success ) {
            toast.success("Restore successful!", {
              position: toast.POSITION.BOTTOM_LEFT
            })
            this.state.onRefresh( uid )
          }else{
            toast.error(`Restore Failed`, {
              position: toast.POSITION.BOTTOM_LEFT
            });
          }
        })
        .catch((err) => {
          toast.error(`Restore failed ${err}`, {
            position: toast.POSITION.BOTTOM_LEFT
          })
        });
    }
  }
  renderHistoryButton(purchase) {
      return <Link to={{
        pathname: '/subscription-history',
        state: purchase
      }} className="btn btn-xs btn-block history__btn btn-default"><Icon name="history" /> See history</Link>
  }

  renderCopyReceipt(purchase) {
    if(purchase.store !== 'apple') {
      return null;
    }

    return <div>
      {' '}
      <CopyToClipboard text={purchase.receipt}
        onCopy={() =>
          toast.success("Apple receipt copied on clipboard", {
            position: toast.POSITION.BOTTOM_LEFT
          })
        }>
        <Button className="btn btn-xs btn-block copy__button btn-info"><Icon name="copy" /> Copy receipt</Button>
      </CopyToClipboard>
    </div>;
  }

  renderPurchaseItem(title, value) {
    return <p className="small break-word purchase__item"><strong>{title}</strong><br/>{value}</p>
  }

  renderStripeSubItem(title, value) {
    return <p className="small break-word purchase__item"><strong>{title}</strong><br/>{value || "N/A"}</p>
  }

  renderPurchaseType(recurring) {
    if(recurring !== undefined && recurring !== null) {
      const value = recurring ? 'Subscription' : 'One-time';
      return this.renderPurchaseItem("Purchase type", value);
    }
  }

  renderStripeData(uid) {
    const stripeData = _.get(this.props, 'user.stripeData', null)
    if( stripeData == null || stripeData.payload == null ) {
      console.log("stripeData", stripeData);
      return;
    }

    const payload = stripeData.payload;
    console.log("stripe payload", payload);


    return (
      <div className="resultitem__group ptoken">
        <Row>
          <Col className="resultitem__purchasetokens_title" xs={6}><strong>Stripe Purchases</strong></Col>
          <Col xs={12}>
            <Row className="token__row">
              <Col title="stripe_data" className="resultitems__purchase_token text-center" xs={12}>
                <Row>
                  <Col className='text-right' xs={3}>
                    {this.renderStripeSubItem("Store", "stripe")}
                    {this.renderStripeSubItem("Premium type", payload.type)}
                    {this.renderStripeSubItem("Duration", payload.metadata.type)}
                    {this.renderStripeSubItem("Entitlement",
                      (
                        (_.get( payload, 'entitlement.muslimpro', false ) ? "Muslim Pro, " :  "") +
                        (_.get(payload, 'entitlement.qalbox', false) ? "QalBox": "")
                      ) || "N/A"
                    )}
                  </Col>
                  <Col className='text-left' xs={6}>
                    {this.renderStripeSubItem("SKU", payload.metadata.sku)}
                    {this.renderPurchaseTime(payload.created)}
                    {this.renderExpiryTime(payload.expiry)}
                  </Col>
                  <Col className='text-left' xs={3}>
                    <InlineWrapper usersPermission={this.state.usersPermission} permission={16}>
                      <Button className="btn btn-xs btn-block copy__button btn-danger" onClick={this.restoreStripePurchase.bind(this, payload, uid) }><Icon name="repeat" /> Restore</Button>
                    </InlineWrapper>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
  renderPurchases(uid) {
    const { purchases, active_sku } = this.state;
    // console.log("active_sku", active_sku);
    if(purchases.length === 0) {
      return null;
    } else {
      const purchaseEl = _.uniqWith(purchases, (p1, p2) => p1.store == p2.store && p1.sku == p2.sku && p1.token == p2.token).map((purchase, i) => {
        const { duration_unit = 'N/A', recurring, sku, store } = purchase;
        const skuActive = (active_sku === sku) ? <Icon name='check'/> : null;
        const skuComp = <span>
          {sku} {skuActive}
        </span>;
        const tokenComp = <InlineWrapper usersPermission={this.state.usersPermission} permission={16} optionalData={this.renderMatched(purchase.token)}>
          <a target="_blank" href={`https://console.firebase.google.com/project/muslim-pro-app/database/${RTDB_DATABASE.PREMIUM_TRANSACTION}/transaction-userid/`+md5(purchase.token)}>{this.renderMatched(purchase.token)}</a>
        </InlineWrapper>;
        const tokenCompLegacy = <InlineWrapper usersPermission={this.state.usersPermission} permission={16} optionalData={this.renderMatched(purchase.token)}>
          <a target="_blank" href={"https://console.firebase.google.com/project/muslim-pro-app/database/muslim-pro-app/data/transaction-userid/"+md5(purchase.token)}>{this.renderMatched(purchase.token)}</a>
        </InlineWrapper>;
        return (
          <Row className="token__row" key={i}>
            <code className='serial'>{i+1}</code>
            <Col title={purchase.token} className="resultitems__purchase_token text-center" xs={12}>
              <Row>
                <Col className='text-right' xs={3}>
                  {this.renderPurchaseItem("Store", store)}
                  {this.renderPurchaseItem("Premium type", getTypeBySKU(sku))}
                  {this.renderPurchaseItem("Duration", duration_unit)}
                  {this.renderPurchaseType(recurring)}
                </Col>
                <Col className='text-left' xs={6}>
                  {this.renderPurchaseItem("SKU", skuComp)}
                  {this.renderPurchaseItem("Token", tokenComp)}
                  {this.renderPurchaseTime(purchase.purchase_time)}
                  {this.renderWrongPurchaseWarning(purchase.originalUserId)}
                </Col>
                <Col className='text-left' xs={3}>
                  <CopyToClipboard text={purchase.token}
                      onCopy={() =>
                        toast.success("Token copied on clipboard", {
                          position: toast.POSITION.BOTTOM_LEFT
                        })
                      }>
                      <Button className="btn btn-xs btn-block copy__button btn-primary"><Icon name="copy" /> Copy token</Button>
                    </CopyToClipboard>
                    {' '}
                    {this.renderCopyReceipt(purchase)}
                    {this.renderHistoryButton(purchase)}
                    <InlineWrapper usersPermission={this.state.usersPermission} permission={16}>
                      <Button className="btn btn-xs btn-block copy__button btn-danger" onClick={this.revalidatePurchase.bind(this, purchase, uid)}><Icon name="repeat" /> Revalidate</Button>
                    </InlineWrapper>
                    {this.renderFixEmptyUserButton(purchase, uid, this.state.usersPermission)}
                </Col>
              </Row>
            </Col>
          </Row>
        );
      });
      return (
        <div className="resultitem__group ptoken">
          <Row>
            <Col className="resultitem__purchasetokens_title" xs={6}><strong>Purchases</strong></Col>
            <Col xs={12}>{purchaseEl}</Col>
          </Row>
        </div>
      );
    }
  }

  renderFixEmptyUserButton(purchase, uid, usersPermission) {
    if(purchase.originalUserId) {
      return (
          <Fragment>
            {' '}
            <InlineWrapper usersPermission={usersPermission} permission={16}>
              <Button className="btn btn-xs btn-block copy__button btn-success"
                      onClick={this.fixEmptyUser.bind(this, purchase, uid)}>
                <Icon name="repeat" />
                {' Fix Empty User'}
              </Button>
            </InlineWrapper>
          </Fragment>
      );
    }

    return null;
  }

  renderWrongPurchaseWarning(originalUserId) {
    if(!originalUserId) {
      return <span className='small'>
        <Icon className="resultitems__purchase_token_ok_icon" name="check-circle-o" />
        {' '}
        Premium tied to correct account
      </span>;
    } else {
      return (
        <span className='small'>
          <Icon className="resultitems__purchase_token_error_icon" name="exclamation-circle" /> Belongs to
          {' '}
          <a target="_blank" href={`/search-user/${originalUserId}`}>{originalUserId}</a>
        </span>
      );
    }
  }

  fetchPhoneNumber(providerData) {
    if(!providerData) {
      return "";
    }
    let phoneNo = "";
    providerData.forEach((data) => {
      if(data.providerId === 'phone') {
        phoneNo = data.uid;
      }
    });
    return phoneNo;
  }

  fetchMpAccountPhoneNumber(muslimProAcc) {
    let phoneNo = '';
    if(!muslimProAcc) {
      return phoneNo;
    }

    const profile = muslimProAcc.profile;
    if(!profile) {
      return phoneNo;
    }

    const phone = profile.phone;
    if(!phone) {
      return phoneNo;
    }

    phoneNo = phone.phonenumber || phone.uid;
    return phoneNo;
  }

  makeModerator(muid, email) {
    const confirmation = window.confirm('Do you really want to make this user a moderator?');
    if(confirmation) {
      createLog('grant_admin', {
        user_id: muid,
        email_address: email
      });
      return updateModerator(1, muid)
        .then(() => {
          toast.success(`${muid} added as a moderator`, {
            position: toast.POSITION.BOTTOM_LEFT
          });
          return Promise.resolve();
        });
    }
  }

  checkModEmailValidity(email) {
    if(!email) {
      return false;
    }
    const domain = email.split('@')[1];
    if(domain === 'bitsmedia.com') {
      return true;
    } else {
      return false;
    }
  }

  getModeratorInfo(uid, firebaseAcc) {
    const email = firebaseAcc.email;
    const providers = firebaseAcc.providerData || [];
    let employeeFlag = false;
    providers.forEach((p) => {
      if(p.providerId === 'google.com' && p.email === email && this.checkModEmailValidity(email)) {
        employeeFlag = true;
      }
    });
    return getModerator(uid).then((modSnapshot) => {
      let modSts = '';
      if(modSnapshot && employeeFlag) {
        modSts = 'active';
      } else if(modSnapshot && !employeeFlag) {
        modSts = 'invalidly-active'
      }
      else if(!modSnapshot && employeeFlag) {
        modSts = 'inactive'
      } else {
        modSts = 'invalid';
      }
      this.setState({
        modSts
      });
    });

  }

  onStreakRewardClaimed (purchases, uid) {
    const streakReward = _.find(purchases, { store: 'muslim_pro'});
    if (streakReward) {
      this.setState({ streakRewardClaimed: true });
      this.getStreakRewardInfo(uid)
    }
  }

  async getStreakRewardInfo (uid, debug = false) {
    await getRewardDef(debug).then(val => {
      if (val) {
        this.setState({
          definitions: val
        })
      }
    });

    await getUserRewardMeta(uid, debug).then(val => {
      if (val) {
        this.setState({
          meta: val.meta
        })
      }
    });
  }

  renderModeratorInfo(uid, email) {
    const { modSts } = this.state;
    if(modSts === 'active') {
      return <p className="small text-success"><Icon name="check-circle" /> User is a moderator</p>
    } else if(modSts === 'inactive') {
      return <p onClick={this.makeModerator.bind(this, uid, email)} className="small text-primary make__moderator"><Icon name="user" /> Make moderator</p>;
    } else if(modSts === 'invalidly-active') {
      return <p title="The email address is not a bitsmedia.com address" className="small text-danger"><Icon name="exclamation-circle" /> User is a moderator</p>
    } else {
      return null;
    }
  }

  getEmailVerified(firebaseAcc) {
    const { email, emailVerified, providerData } = firebaseAcc;
    let sts;
    if(!email) {
      sts = <span title="This user doesnt have any email address"><Icon name="question-circle" /> N/A</span>;
    } else if(emailVerified) {
      sts = <span><Icon name="check-circle" /> Verified</span>;
    } else if(!providerData && !emailVerified) {
      sts = <span><Icon name="exclamation-circle" /> Not verified</span>;
    } else if(providerData && !emailVerified) {
      let found = false;
      providerData.forEach((pData) => {
        if(email === pData.email && pData.providerId!== 'password') {
          found = true;
        }
      });
      if(found) {
        sts = <span><Icon name="check-circle" /> Verified</span>;
      } else {
        sts = <span><Icon name="exclamation-circle" /> Not verified</span>;
      }
    }
    return sts;
  }

  renderProviders(providerData) {
    const providerEl = providerData.map((provider, i) => {
      const { providerId, email, phoneNumber } = provider;
      return <p className="small search__result_provider_info">{providerId.split('.')[0]} ({email||phoneNumber})</p>;
    });
    return providerEl;
  }

  renderResultItemWarning(show, errorMessage) {
    if(!show) {
      return null;
    } else {
      return <p className="resultitems__warning small alert alert-warning"><Icon className="resultitems__warning_icon" name="exclamation-circle" /> {errorMessage}</p>
    }
  }

  checkIfDateIsToday(date) {
    const today = moment().startOf('day');
    const someDay = moment(date).startOf('day');
    return today.isSame(someDay, 'd');
  }

  renderPremiumCodeRedeemForm(targettedUserId) {
    return <div className="resultitem__group">
      <Row>
        <Col className="text-right" xs={5}>Redeem premium code</Col>
        <Col xs={6}>
          <RedeemPremiumCode targettedUserId={targettedUserId} />
        </Col>
      </Row>
    </div>;
  }

  render() {

    const { firebaseAcc, muslimProAcc, shardUrl, shardId } = this.props.user;
    // Generic info
    const { displayName, email, uid, metadata } = firebaseAcc;
    const { connected_devices, premium_status, blocked } = muslimProAcc;
    const loginTypeChecker = firebaseAcc.providerData.length;
    const loginTypeVal = this.renderProviders(firebaseAcc.providerData)
    const loginTypeEl = this.renderRowIfAvailable(loginTypeChecker, "Login type", loginTypeVal);
    const phoneNo = this.fetchPhoneNumber(firebaseAcc.providerData)
      || this.fetchMpAccountPhoneNumber(muslimProAcc);
    const emailVerified = this.getEmailVerified(firebaseAcc);
    const languageCode = muslimProAcc && muslimProAcc.saved_data && muslimProAcc.saved_data.app_language;
    const { profile } = muslimProAcc;
    const verificationEmailCount = (profile && profile.verification_email_count) ? profile.verification_email_count : 0;
    const dailyRequestCounts = profile && profile.dailyRequestCounts ? profile.dailyRequestCounts : {};
    const dailyPrayerRequestsCounts = dailyRequestCounts.createPrayerRequest || { count: 0, lastRequestDate: null };
    const prayertime_location = muslimProAcc && muslimProAcc.saved_data && muslimProAcc.saved_data.prayertime_location;
    const disable_bq_tracking =  muslimProAcc
      && muslimProAcc.saved_data && muslimProAcc.saved_data.disable_bq_tracking;
    const displayReward = this.state.streakRewardClaimed;
    return (
      <div className="resultitem">
      <ToastContainer autoClose={5000} />
        <div className="resultitem__group">
          <Row>
            <Col className="text-right" xs={5}>Name</Col>
            <Col xs={6}>{displayName}</Col>
          </Row>
          <Row>
            <Col className="text-right" xs={5}>Email</Col>
            <Col xs={7}>
              <Row>
                <Col xs={12}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      {this.renderMatched(email)}
                      {
                        email ?
                          <CopyToClipboard
                            text={email}
                            onCopy={() => {
                              console.log(`Copied : ${email}`)
                            }}
                          >
                            <div className={'copy-user-precalc-file-id-btn'}>
                              <Button className="btn btn-xs btn-dark">
                                &nbsp;<span className="glyphicon glyphicon-duplicate" aria-hidden="true"/>
                              </Button>
                            </div>
                          </CopyToClipboard> : null
                      }
                    </div>
                    <InlineWrapper usersPermission={this.state.usersPermission} permission={32}>
                      {this.renderModeratorInfo(uid, email)}
                    </InlineWrapper>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="text-right" xs={5}>Email verified</Col>
            <Col xs={6}>{emailVerified}</Col>
          </Row>
          <Row>
            <Col className="text-right" xs={5}>Is blocked</Col>
            <Col xs={6}>{blocked ? <span><Icon className="resultitems__purchase_token_error_icon" name="exclamation-circle" /> Yes</span> : <span><Icon name="check-circle" /> No</span>}</Col>
          </Row>
          <Row>
            <Col className="text-right" xs={5}>Phone</Col>
            <Col xs={6}>{this.renderMatched(phoneNo)}</Col>
          </Row>
          <Row>
            <Col className="text-right" xs={5}>User ID</Col>
            <Col xs={6}>
              <InlineWrapper
                usersPermission={this.state.usersPermission}
                permission={16}
                optionalData={this.renderMatched(uid)}
              >
                <div style={{'display': 'flex'}}>
                  <a
                    target="_blank"
                    href={shardUrl}
                  >
                    {this.renderMatched(uid)}
                  </a>
                  {
                    uid ?
                      <CopyToClipboard
                        text={uid}
                        onCopy={() => {
                          console.log(`Copied : ${uid}`)
                        }}
                      >
                        <div className={'copy-user-precalc-file-id-btn'}>
                          <Button className="btn btn-xs btn-dark">
                            &nbsp;<span className="glyphicon glyphicon-duplicate" aria-hidden="true"/>
                          </Button>
                        </div>
                      </CopyToClipboard> : null
                  }
                </div>
              </InlineWrapper>
            </Col>
          </Row>
          <Row>
            <Col className="text-right" xs={5}>Is Migrated</Col>
            <Col xs={6}>
              { shardId == -1 ? <span>No</span>: <span><Icon className="resultitems__purchase_token_ok_icon" name="check-circle-o" />Yes</span>}
            </Col>
          </Row>
          <Row>
            <Col className="text-right" xs={5}>Shard ID</Col>
            <Col xs={6}>
              { shardId == -1 ? <span>legacy</span> : <span>{shardId}</span>}
            </Col>
          </Row>
        </div>
        <div className="resultitem__group">
          <Row>
            <Col className="text-right" xs={5}>Created at</Col>
            <Col className="small" xs={6}>
              <DateTime timestamp={metadata.createdAt || new Date(metadata.creationTime).getTime()} />
            </Col>
          </Row>
          <Row>
            <Col className="text-right" xs={5}>Last logged in at</Col>
            <Col className="small" xs={6}>
              <DateTime timestamp={metadata.lastSignedInAt || new Date(metadata.lastSignInTime).getTime()} />
            </Col>
          </Row>
          {loginTypeEl}
          <Row>
            <Col className="text-right" xs={5}>Daily prayer request creation count</Col>
            <Col xs={6}>
              <code>{dailyPrayerRequestsCounts.count}</code> <span className="small">Last prayer request date is <code>{dailyPrayerRequestsCounts.lastRequestDate ? moment(dailyPrayerRequestsCounts.lastRequestDate).format("MMM DD, YYYY") : 'N/A'}</code></span>
              {this.renderResultItemWarning((dailyPrayerRequestsCounts.count >= 5 && this.checkIfDateIsToday(dailyPrayerRequestsCounts.lastRequestDate)), "Daily quota finished. User won't be able to create prayer requests until tomorrow.")}
            </Col>
          </Row>
          <Row>
            <Col className="text-right" xs={5}>Verification email count</Col>
            <Col xs={6}>
              <code>{verificationEmailCount}</code>
            </Col>
          </Row>
        </div>
        <PrecalcDetails prayertimeLocation={prayertime_location} />
        <div className="resultitem__group">
          <Row>
            <Col className="text-right" xs={5}>Connected devices</Col>
            <Col xs={6}>
              {this.renderConnectedDevices(connected_devices)}
            </Col>
          </Row>
        </div>
        {this.renderPremiumInfo(premium_status)}
        {this.renderRestoreError(premium_status)}
        {displayReward ? this.renderStreakRewardInfo(this.state.definitions, this.state.meta) : '' }
        {this.renderPremiumCodeRedeemForm(uid)}
        {this.renderStripeData(uid)}
        {this.renderPurchases(uid)}
        <InlineWrapper usersPermission={this.state.usersPermission} permission={16}>
          <ControlBar
            personalData={{
              email,
              displayName,
              languageCode,
              uid,
            }}
            emailVerified={firebaseAcc.emailVerified}
            hasActivePremium={this.hasActivePremium(premium_status)}
            premiumStatus={premium_status}
            onDeleteUser={this.onDeleteUser.bind(this)}
            onDownloadData={this.onDownloadData.bind(this, firebaseAcc, muslimProAcc)}
            onAddRemoveFreeTrial={this.onAddRemoveFreeTrial.bind(this)}
            onToggleTrackingUser={this.onToggleTrackingUser.bind(this)}
            onRefresh={this.state.onRefresh}
            uid={uid}
            disable_bq_tracking={disable_bq_tracking}
          />
        </InlineWrapper>
      </div>
    );
  }
}

class ControlBar extends Component {
  onDeleteUser(uid) {
    this.props.onDeleteUser(uid);
  }

  onToggleTrackingUser(disableData) {
    this.props.onToggleTrackingUser(disableData);
  }

  sendVerificationLinkEmail(data) {
    const confirm = window.confirm("Do you really want to send an email verification link?");
    if(confirm) {
      adminConsoleSendVerificationEmail(data).then((res) => {
        if(res.data.result === 'success') {
          toast.success('Email verification link sent successfully!', {
            position: toast.POSITION.BOTTOM_LEFT
          });
        } else {
          toast.error('Sending email verification link failed', {
            position: toast.POSITION.BOTTOM_LEFT
          });
        }
      });
    }
  }

 onRevokeUserPremium(uid) {
    const confirmation = window.confirm("Do you really want to revoke premium of this user?");
    if(confirmation) {
      try {
        removeUserPremiumForTest({ uid, removePurchases:false });
        createLog('revoked_premium', {
          user_id: uid
        });
        toast.success('Successfully Revoked Premium', {
          position: toast.POSITION.BOTTOM_LEFT
        });
        this.props.onRefresh( uid )
      } catch (err) {
        toast.error(`Revoke Premium Failed ${err}`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }
    }
  }

  revokeUserPremium(uid) {
    this.onRevokeUserPremium(uid);
  }

  render() {
    const {
      hasActivePremium,
      premiumStatus,
      uid,
      personalData,
      emailVerified,
      disable_bq_tracking,
    } = this.props;

    const emailVerifyLinkButton =
      <button disabled={emailVerified}
        onClick={this.sendVerificationLinkEmail.bind(this, personalData)}
        className="btn btn-xs btn-info">
      <Icon name="envelope" />
        &nbsp;{'Send email verification link'}
      </button>;
    const revokePremiumButton =
      <button disabled={!hasActivePremium}
              onClick={this.revokeUserPremium.bind(this, uid)}
              className="btn btn-xs btn-danger">
               <Icon name="user" />
        &nbsp;{'Revoke Premium'}
      </button>
    const transferURL = hasActivePremium ? "/transfer-premium?from="+uid : "/transfer-premium?to="+uid;
    const premiumButtonTxt = hasActivePremium ? "Transfer premium" : "Make premium";
    const freeTrialStatus = getFreeTrialStatus(premiumStatus);
    const freeTrialBtnTxt = freeTrialStatus ? 'Remove free trial' : 'Add free trial';

    return (
        <span>
          <a href={transferURL} className="btn btn-xs btn-warning">
            <Icon name="exchange" />
            &nbsp;{premiumButtonTxt}
          </a>
          {' '}
          {revokePremiumButton}
          {' '}
          <Button className={`btn btn-xs btn-${freeTrialStatus ? 'danger' : 'primary'}`}
                  onClick={() => this.props.onAddRemoveFreeTrial(uid, !freeTrialStatus)} >
            <Icon name={`user-${freeTrialStatus ? 'times' : 'plus'}`} />
            &nbsp;{freeTrialBtnTxt}
          </Button>
          {' '}
          <DataModeratorWrapper usersEmail={personalData.email}>
            <Button className="btn btn-xs btn-danger" onClick={this.onDeleteUser.bind(this, uid)} >
              <Icon name="trash" />
              &nbsp;Delete user
            </Button>
          </DataModeratorWrapper>
          {' '}
          <DataModeratorWrapper>
          <Button className={`${disable_bq_tracking ?
            'btn btn-xs btn-success' : 'btn btn-xs btn-danger'}`}
                  onClick={this.onToggleTrackingUser.bind(this, {uid, disable_bq_tracking})}
          >
            <Icon name={`${disable_bq_tracking ? 'eye' : 'eye-slash'}`} />
            &nbsp;{`${disable_bq_tracking ? 'Enable bq tracking' : 'Disable bq tracking'}`}
          </Button>
          </DataModeratorWrapper>
          {' '}
          {emailVerifyLinkButton}
          <DataModeratorWrapper>
          <Link
            className='margin-all3 btn btn-primary btn-xs'
            to={{
              pathname: '/delete-user-logs',
              state: { userId: uid }
            }}
          >
            <span className={'glyphicon glyphicon-trash'} aria-hidden='true'/>
            &nbsp;Delete BQ Logs
          </Link>
          </DataModeratorWrapper>
          {' '}
          <DataModeratorWrapper>
          <Button className="btn btn-xs btn-primary" onClick={this.props.onDownloadData} >
            <Icon name="download" />
            &nbsp;Download data
          </Button>
          </DataModeratorWrapper>
        </span>
    );
  }
}

export default ResultItem;
