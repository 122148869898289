import aideenAxiosService from "../../apis/mp_service_aideen_api";
class AiDeenService {
    updateAiDeenContent = (array) =>{
       try{
    return aideenAxiosService.post('upload-qna',array).then( response => {
                    if(response.data.status === 200){
                        return response.data.data;
                    } else {
                        return false;
                    }
                });
       } catch(error){
        console.log('updateAiDeenContent', error )
        throw new Error( error )
       }
        
    }

    resetAiDeenDb = () => {
        try {
            return aideenAxiosService.post('reset-db').then( response => {
                if(response.data.status === 200){
                    return true;
                } else {
                    return false;
                }
            })
        } catch(error) {
            console.log("Error at reset: ", error);
            return false;
        }
    }
}
export default new AiDeenService();