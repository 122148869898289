import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  redisFlushCacheForCF,
} from '../../services/mp-cf-api-service';

const clearV2RedisCache = () => {
  const [ clearCacheResponseData, setClearCacheResponseData ] = useState({ });
  const [ clearCacheData, setClearCacheData] = useState(null);
  const {
    subKey,
    mainKey,
    onOperationComplete,
  } = clearCacheData || {};

  const clearV2Cache = async () => {
    const errorMsg =
        `Error occurred while clearing cache for mainKey : ${mainKey}, subKey : ${subKey}`;
    try {
      console.log(`Cleaning Cache... For mainKey : ${mainKey}, subKey : ${subKey}`);
      setClearCacheResponseData({
        isClearing: true,
        isError: false,
      });

      const clearCacheApiResp =  await redisFlushCacheForCF({
        subKey,
        mainKey,
      });
      const responseData = clearCacheApiResp.data;

      if(responseData.result === 'success') {
        console.log('Clear Cache Success!!');
      } else {
        console.log('Clear Cache Failed!!');
      }

      setClearCacheResponseData({
        isClearing: false,
        isError: responseData.result !== 'success',
        errorMsg,
      });
      if(onOperationComplete) {
        onOperationComplete({
          isError: responseData.result !== 'success',
          errorMsg,
        });
      }
    } catch (err) {
      console.log(`${errorMsg}. Error : ${err}`);
      setClearCacheResponseData({
        isClearing: false,
        isError: true,
        errorMsg,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(clearCacheData)) {
      clearV2Cache();
    }
  }, [ clearCacheData ]);

  return [
    clearCacheResponseData,
    setClearCacheData,
  ];
};

export default clearV2RedisCache;
