import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  getV2Contents,
  updateV2HashTags,
  createOrUpdateV2BulkContents,
} from '../../services/mp-cf-api-service';

const removeHashTags = () => {
  const [ hashTagsRemoveRespData, setHashTagsRemoveRespData ] = useState({ });
  const [ hashTagsRemoveData, setHashTagsRemoveData ] = useState(null);
  const {
    deleteHashTags=[],
    onOperationComplete,
  } = hashTagsRemoveData || {};

  const removeCV2HashTags = async () => {
    try {
      console.log(`Removing hash tags ${JSON.stringify(deleteHashTags)}...`);

      if(_.isEmpty(deleteHashTags)) {
        console.log('Hashtags delete data is empty');
        return;
      }

      setHashTagsRemoveRespData({
        isDeleting: true,
        isError: false,
      });

      let errorMsg = null;
      let isError = false;

      // Remove Hashtags in Sequence
      for (const deleteHashTag of deleteHashTags) {
        const {
          contentIds,
          hashTags=[],
        } = deleteHashTag;

        // Fetching existing contents.
        const existingContentsResp = await getV2Contents({
          data: {
            content_id_list: contentIds,
          },
        });
        const existingContentsRespData = existingContentsResp.data;
        if (existingContentsRespData.result.result === 'success') {
          console.log('Existing contents fetch success.');
        } else {
          console.log('Existing contents fetch failed.');
          isError = true;
          errorMsg = 'Existing contents fetch failed';
          break;
        }
        const existingContents = existingContentsRespData.result.contents;

        if(_.isEmpty(existingContents)) {
          isError = true;
          errorMsg = 'Existing contents is empty for selected hashtags';
          break;
        }

        // Removing existing hashtags from HashTags DB.
        const hashTagsRemoveResp = await updateV2HashTags({
          contents: existingContents,
          operation: 'remove',
          force_error: false,
        });
        if (hashTagsRemoveResp.data.result === 'success') {
          console.log('Hashtags delete success.');
        } else {
          console.log('Hashtags delete failed.');
          isError = true;
          errorMsg = 'Existing hashtags delete failed';
          break;
        }

        // Remove hashTag from existing content's hashTags.
        const updatedContents = _.map(existingContents, content => {
          return {
            ...content,
            hash_tags: _.without(content.hash_tags, ...hashTags),
          }
        });

        // Update updated contents in ContentsV2 DB
        const contentUpdateResp =
            await createOrUpdateV2BulkContents({ contents:
                  updatedContents.map(content => {
                    return {
                      id: content.id,
                      hash_tags: content.hash_tags,
                    };
                  })
            });
        if (contentUpdateResp.data.result === 'success') {
          console.log('Contents update success.');
        } else {
          console.log('Contents update failed.');
          isError = true;
          errorMsg = 'Updating contents with removed hashtags failed';
          break;
        }

        // Update new hashTags in Hashtags DB
        const updateOldHashTagsResp = await updateV2HashTags({
          contents: updatedContents,
          operation: 'update',
          force_error: false,
          use_db_content_publish_date: true,
        });
        if (updateOldHashTagsResp.data.result === 'success') {
          console.log('Hashtags update success.');
        } else {
          console.log('Hashtags update failed.');
          isError = true;
          errorMsg = 'Updating content`s hashtags failed';
          break;
        }
      }

      setHashTagsRemoveRespData({
        isDeleting: false,
        isError,
        errorMsg,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError,
          errorMsg,
        });
      }
    } catch (err) {
      console.log('Something went wrong while deleting hashtags. ', err);
      const errorMsg = `Error occurred while deleting hashtags.`;
      setHashTagsRemoveRespData({
        isDeleting: false,
        isError: true,
        errorMsg,
      });
      if(onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(hashTagsRemoveData)) {
      removeCV2HashTags();
    }
  }, [ hashTagsRemoveData ]);

  return [
    hashTagsRemoveRespData,
    setHashTagsRemoveData,
  ];
};

export default removeHashTags;
