import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  listV2HashTags,
} from '../../services/mp-cf-api-service';

const listHashTags = () => {
  const [ hashTagsContentsData, setHashTagsContentsData ] = useState({ });
  const [ hashTagsListData, setHashTagsListData ] = useState(null);
  const {
    language_code,
    onOperationComplete,
  } = hashTagsListData || {};

  const listCV2HashTags = async () => {
    try {
      console.log(`Fetching hash tags for ${language_code}...`);
      setHashTagsContentsData({
        isFetching: true,
        isError: false,
      });

      const hashTagFetchResp = await listV2HashTags({
        language_code,
      });

      let errorMsg = null;
      let isError = false;

      if (hashTagFetchResp.data.result === 'success') {
        console.log('Hash tags fetch Success!!');
      } else {
        console.log('Hash tags fetch Failed!!');
        errorMsg = `Error occurred while fetching hashtags.`;
        isError = true;
      }

      setHashTagsContentsData({
        isFetching: false,
        isError,
        errorMsg,
        data: hashTagFetchResp.data.hash_tags,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError,
          errorMsg,
        });
      }
    } catch (err) {
      console.log('Something went wrong while fetching contents. ', err);
      const errorMsg = `Error occurred while fetching contents.`;
      setHashTagsContentsData({
        isFetching: false,
        isError: true,
        errorMsg,
        data: null,
      });
      if(onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(hashTagsListData)) {
      listCV2HashTags();
    }
  }, [ hashTagsListData ]);

  return [
    hashTagsContentsData,
    setHashTagsListData,
  ];
};

export default listHashTags;
