import React from "react";
import { getMediaURLArrayV1 } from "../../helper";
import QalboxCopyInputBox from "./qalboxCopyInputBox";
import QalboxURLInputBox from "./qalboxURLInputBox";

const QalboxURLInputV1 = ({ componentName, urlArray, onChange }) => {
  const handleInputTextChange = (e) => {
    let { name, value } = e.target;

    const prefix = name.split(".")[1];
    name = name.split(".")[0];
    value = getMediaURLArrayV1(prefix, name, value, urlArray);

    onChange(name, value);
  };

  return (
    <div className="form-group">
      <label>HLS URL</label>
      <QalboxURLInputBox
        name={`${componentName}.0`}
        value={urlArray && urlArray.length > 0 ? urlArray[0].src : ""}
        handleInputTextChange={handleInputTextChange}
        isHls={true}
      />
      <label>DASH URL</label>
      <QalboxURLInputBox
        name={`${componentName}.1`}
        value={urlArray && urlArray.length > 1 ? urlArray[1].src : ""}
        handleInputTextChange={handleInputTextChange}
      />
    </div>
  );
};

export default QalboxURLInputV1;