import React,
{
  Component,
} from 'react';

class RecentSearches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recents: [],
    };
  }

  componentWillMount() {
    const recent = localStorage.getItem("recentSearches");
    this.processRecentSearches(recent, "");
  }

  componentWillReceiveProps(nextProps) {
    const recent = localStorage.getItem("recentSearches");
    this.processRecentSearches(recent, nextProps.lastSearch);
  }

  processRecentSearches(recent, lastSearch) {
    let obj = JSON.parse(recent),
    recents;

    if(obj) {
      recents = obj.data;
    } else {
      obj = {};
      recents = [];
    }

    recents = this.updateRecentArray(lastSearch, recents, this.props.limit);
    recents = Array.from(new Set(recents));
    obj.data = recents;
    const str = JSON.stringify(obj);
    localStorage.setItem("recentSearches", str);
    this.setState({
      recents
    });
  }

  updateRecentArray(last, arr, n) {
    if(last.trim()!== "") {
      arr.unshift(last);
    }
    return arr.slice(0, n);
  }

  handleRecentItemClick(recent) {
    this.props.onRecentClick(recent);
  }

  render() {
    const { recents } = this.state;
    let recentEl;
    if(recents.length === 0) {
      recentEl = <p>Nothing found</p>;
    } else {
      recentEl = recents.map((recent, i) => {
        return <p onClick={this.handleRecentItemClick.bind(this, recent)} className="recentsearches__item" key={i}>{recent}</p>
      });
    }
    return (
      <div className="recentsearches">
        {recentEl}
      </div>
    );
  }
}

export default RecentSearches;
