import React,
{
  useState,
} from 'react';
import BasicLayout from '../../layouts/basiclayout';
import {
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  ReportsList,
} from './Constants';
import {
  MPSelect,
} from '../../widgets/common';

const reportKeys = Object.keys(ReportsList);

const reportsList = reportKeys.map((option) => {
  return {
    key: option,
    value: option,
  }
});

const Reports = (props) => {

  const [ selectedReport, setSelectedReport ] = useState('');

  return (
      <BasicLayout pagePermission="readonly">
        <ToastContainer autoClose={2000}/>
        <div>
          <Row>
            <Col md={12}>
              <h3 className='content_editor_title'>{'Reports'}</h3>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className='form-group'>
                <label style={{'marginRight': '5px'}}>Report :</label>
                <MPSelect
                    items={reportsList}
                    selectedItem={selectedReport}
                    onChangeSelect={setSelectedReport}
                />
              </div>
            </Col>
          </Row>
          <br/>
          {
            selectedReport && selectedReport !== 'default' ?
                <Row>
                  <Col md={12}>
                    <iframe width="1024"
                            height="1000"
                            src={`${ReportsList[selectedReport]}`}
                            frameBorder="0"
                            style={{ border: '0' }}
                            allowFullScreen
                            title={selectedReport}
                    >
                    </iframe>
                  </Col>
                </Row> : null
          }
        </div>
      </BasicLayout>
  );
};

export default Reports;
