import _ from 'lodash';
import {
  WEB_APP_ENV,
} from '../Constants';

const updateExpandedMenus = (expandedMenus, key, showHide) => {
  expandedMenus[key] = showHide;

  _.keys(expandedMenus).forEach(menuKey => {
    if(menuKey !== key) {
      expandedMenus[menuKey] = false;
    }
  });

  return {...expandedMenus};
};

const getActiveStatusByEnvironment = ({
                                         activeEnvs  = [],
                                       }) => {

  return _.includes(activeEnvs, WEB_APP_ENV);
};

export {
  updateExpandedMenus,
  getActiveStatusByEnvironment,
}
