import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  deleteMailGunEmailTag,
} from '../../services/mp-cf-api-service';

const deleteEmailTag = () => {
  const [ responseData, setResponseData ] = useState({ });
  const [ deleteData, setDeleteData ] = useState(null);
  const {
    tag,
    onOperationComplete,
  } = deleteData || {};

  const deleteMGEmailTag = async () => {
    try {
      console.log(`Deleting email tag ${tag}...`);
      setResponseData({
        isDeleting: true,
        isError: false,
      });

      const deleteEmailTagApiResp = await deleteMailGunEmailTag({
        tagToDelete: tag,
      });
      const responseData = deleteEmailTagApiResp.data;

      let errorMsg = null;
      let isError = false;

      if(responseData.success) {
        console.log('Mail Gun Tag Delete Success!!');
      } else {
        console.log('Mail Gun Tag Delete Failed!!');
        errorMsg = `Failed to delete email tag : ${tag}`;
        isError = true;
      }

      setResponseData({
        isDeleting: false,
        isError,
        errorMsg,
        data: responseData.data,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError,
          errorMsg,
          data: responseData.payload,
        });
      }
    } catch (err) {
      console.log('Something went wrong while deleting tag. ', err);
      const errorMsg = `Error occurred while deleting tag: ${tag}`;
      setResponseData({
        isDeleting: false,
        isError: true,
        errorMsg,
        data: null,
      });
      if(onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
          data: null,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(deleteData)) {
      deleteMGEmailTag();
    }
  }, [ deleteData ]);

  return [
    responseData,
    setDeleteData,
  ];
};

export default deleteEmailTag;
