import { useState } from 'react';
import axiosService from '../../../../service_v2/common/axios-service';

export const useUpdateShort = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const updateShort = async (shortMedia, handleUpdate) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await axiosService.put(
        `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}qalbox-admin-short-service/v1/update/`,
        {
          short_content: shortMedia,
          uuid: shortMedia.uuid,
        }
      );

      if (response?.data) {
        setSuccess(true);
        handleUpdate(response?.data);
      } else {
        setError("Failed to update short");
      }
    } catch (error) {
      setError("An error occurred while updating the short");
    } finally {
      setLoading(false);
    }
  };

  return [ updateShort, loading, error, success ];
};