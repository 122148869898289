import _ from 'lodash';
import React from 'react';
import {
  MPDateRangePicker,
} from '../date-range-picker';

const DefaultColumnFilter = ({
                               column: {
                                 id,
                                 setFilter,
                                 filterValue,
                                 preFilteredRows,
                               },
                               placeholderText='Search here...',
                             }) => {

  return (
      <input
          value={filterValue || ''}
          onChange={e => {
            setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
          }}
          id={id}
          type="text"
          className={`mp-table-input ${_.isEmpty(filterValue) ? null : 'mp-table-filter-applied' }`}
          placeholder={placeholderText}
          required
      />
  );
}

const SelectColumnFilter = ({
                              getSelectOptions,
                            }) => {
  const ColumnFilter = ({
                          column: {
                            id,
                            setFilter,
                            filterValue,
                            preFilteredRows,
                          },
                          defaultValue='All',
                        }) => {

    const options = React.useMemo(() => {
      return getSelectOptions(id);
    }, [id]);

    const otherOptions = options.map((option, i) => {
      return (
          <option value={option} key={i}>{option}</option>
      );
    });

    return (
        <select className={`mp-table-drop-down ${_.isEmpty(filterValue) ? null : 'mp-table-filter-applied' }`}
                value={filterValue || ''}
                onChange={(e) => setFilter(e.target.value || undefined)}
        >
          <option value={''}>{defaultValue}</option>
          {otherOptions}
        </select>
    );
  }

  return ColumnFilter;
}

const DateRangeFilter = ({
                           column: {
                             filterValue,
                             setFilter,
                             preFilteredRows,
                             id,
                           },
                         }) => {

  const onApplyDate = changedValues  => {
    const {
      endDate,
    } = changedValues;
    if(endDate) {
      setFilter(changedValues);
    } else {
      setFilter(undefined);
    }
  };

  return (
      <div className={`mp-table-date-picker  ${_.isEmpty(filterValue) ? null : 'mp-table-filter-applied' }`}>
        <MPDateRangePicker
            onApplyDate={onApplyDate}
        />
      </div>
  );
}

const Filters = {
  DefaultColumnFilter,
  SelectColumnFilter,
  DateRangeFilter,
}

export  {
  Filters,
  DefaultColumnFilter,
}
