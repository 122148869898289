import React from "react";
import QalboxSelectDropdown from "./qalboxSelect";
import { convertArrayToSelectList, convertToSelectObject } from "../../helper";
import { AWS_LANGUAGES, convertToLabelValue } from "./Constants";

const QalboxSubtitleBox = ({ subtitleList = [], handleSubtitleUpdate }) => {
  const handleSubtitleInputChange = (index, e) => {
    const { name, value } = e.target;
    const newLanguages = [...subtitleList];
    newLanguages[index][name] = value;
    handleSubtitleUpdate(newLanguages);
  };

  const handleSelect = (index, name, option) => {
    const newLanguages = [...subtitleList];
    newLanguages[index][name] = option.value;
    handleSubtitleUpdate(newLanguages);
  };

  const handleAddSubtitle = () => {
    handleSubtitleUpdate([...subtitleList, { language: "", link: "" }]);
  };

  const handleSubtitleRemove = (e) => {
    e.preventDefault();
    const { name } = e.target;
    const newLanguages = subtitleList ? subtitleList : [];
    const indexToDelete = name;
    newLanguages.splice(indexToDelete, 1);

    handleSubtitleUpdate(newLanguages);
  };

  return (
    <div>
      {subtitleList &&
        subtitleList.length > 0 &&
        subtitleList.map((subtitle, index) => (
          <div key={index}>
            <div className="qalbox-form-row">
              <div
                name={index}
                style={{ marginRight: "10px" }}
                onClick={handleSubtitleRemove}
              >
                <span
                  className="glyphicon glyphicon-remove"
                  aria-hidden="true"
                />
              </div>
              <label>Subtitle {index + 1}</label>
            </div>
            <QalboxSelectDropdown
              name={"language"}
              selectedOption={convertToSelectObject(subtitle.language)}
              options={convertToLabelValue(AWS_LANGUAGES)}
              onChange={(name, option) => handleSelect(index, name, option)}
            />
            <textarea
              name={`link`}
              value={subtitle.link}
              onChange={(e) => handleSubtitleInputChange(index, e)}
              type="text"
              className="form-control"
              placeholder="Subtitle Link"
              style={{ marginBottom: "5px", marginTop: "5px" }}
            />
          </div>
        ))}
      <div onClick={handleAddSubtitle}>
        <span className="glyphicon glyphicon-plus" aria-hidden="true" />
      </div>
    </div>
  );
};

export default QalboxSubtitleBox;
