import _ from 'lodash';
import {
  URL_REGEX,
  VIRTUAL_TOUR_TYPES,
  SUPPORTED_LANGUAGES,
  SPECIAL_HEADERS_COUNT,
  IMAGES_DIRECTORY_PREFIX,
} from './Constants';
import {
  getMetaData,
} from '../../services/meta-data';

const getFileUploadPath = ({
                             fileName,
                             selectedLanguage,
                           }) => {
  return `/${IMAGES_DIRECTORY_PREFIX}/${selectedLanguage}/${fileName}`;
};

const getUploadableFiles = async (files, selectedLanguage) => {
  const uploadableFiles = [];

  for(const file of files) {
    const encodedImage = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(e.target.result);
      fileReader.readAsDataURL(file);
    });
    const base64EncodedImage = encodedImage.replace('data:image/jpeg;base64,', '');

    // More info about adding meta data :
    // https://cloud.google.com/storage/docs/viewing-editing-metadata#storage-view-object-metadata-nodejs
    // https://cloud.google.com/storage/docs/uploading-objects
    const metadata = {
      contentType: 'image/jpeg',
      cacheControl: 'public, max-age=31536000'
    };

    const fileUploadPath = getFileUploadPath({
      fileName: file.name,
      selectedLanguage,
    });

    uploadableFiles.push({
      fileBuffer: base64EncodedImage,
      options: {
        metadata,
      },
      fileType: 'image',
      fileUploadPath,
      makePublic: true, // This is options, default is makePublic: true, kept as a API reference.
      clear_cdn_cache: true,
    });
  }

  return { uploadableFiles };
};

const getSupportedLanguages = () => {
  return SUPPORTED_LANGUAGES.map((languageCode) => {
    return {
      key: languageCode,
      value: languageCode
    }
  });
};

const getItems = async (rows) => {
  const items = [];
  const allErrors = [];
  // Starting from SPECIAL_HEADERS_COUNT, because we need to skip title and comments.
  for(let i = SPECIAL_HEADERS_COUNT; i < rows.length; i++) {
    let errorsCount = 0;
    const data = rows[i];

    const item = {};

    const errors = {
      'row': i + 1,
      'errorsCount': 0,
      'errorList': [],
      'excelSheetRowData': data,
    };

    // console.log('Validation row : ', i + 1);

    // Type
    const type = data[0] || null;
    if(type) {
      if(isValidType(type)) {
        item['type'] = type;
      } else {
        errors['errorList'].push(`${++errorsCount}. Type is invalid.`);
      }
    } else {
      errors['errorList'].push(`${++errorsCount}. Type not found.`);
    }

    // console.log('type : ', type);

    // Language Code
    let languageCode = data[1];
    languageCode = languageCode ? languageCode.toLowerCase() : null;
    if(languageCode) {
      if(isValidLanguageCode(languageCode)) {
        item['language_code'] = languageCode;
      } else {
        languageCode = null;
        errors['errorList'].push(`${++errorsCount}. Language code is invalid.`);
      }
    } else {
      errors['errorList'].push(`${++errorsCount}. Language Code not found.`);
    }

    // console.log('languageCode : ', languageCode);

    // Document Id
    let id;
    if(item['language_code'] && item['type']) {
      id = `${item['type']}-${item['language_code']}`;
      item['id'] = id;
    }

    // console.log('id : ', id);

    const existingItem = getExistingItem(items, id);
    // console.log('existingItem : ', existingItem);

    // Day Number
    let dayNumber = data[2];
    let existingDay;
    let newDay;
    dayNumber = parseInt(dayNumber, 10);
    // console.log('dayNumber before', dayNumber);
    if(dayNumber || dayNumber === 0) {
      if(_.isInteger(dayNumber)) {
        // console.log('dayNumber is a integer.');
        if(existingItem) {
          const existingDays = existingItem['days'];
          existingDay = getExistingDay(existingDays, dayNumber)
          if(!existingDay) {
            newDay = { day_number: dayNumber };
          }
        } else {
          newDay = { day_number: dayNumber };
        }
      } else {
        dayNumber = null;
        errors['errorList'].push(`${++errorsCount}. Invalid day number.`);
      }
    } else {
      errors['errorList'].push(`${++errorsCount}. Day number not found.`);
    }

    // console.log('dayNumber after', dayNumber);
    // console.log('existingDay ', existingDay);
    // console.log('newDay ', newDay);

    // Place Number
    let placeNumber = data[3];
    let placeId;
    placeNumber = parseInt(placeNumber, 10);
    if(placeNumber || placeNumber === 0) {
      if(_.isInteger(placeNumber)) {
        placeId = String(placeNumber);
      } else {
        placeNumber = null;
        errors['errorList'].push(`${++errorsCount}. Invalid place number.`);
      }
    } else {
      errors['errorList'].push(`${++errorsCount}. Place number not found.`);
    }

    // console.log('placeNumber ', placeNumber);
    // console.log('placeId ', placeId);

    let existingPlace;
    let newPlace;
    if(placeId) {
      if(existingDay) {
        const existingPlaces = existingDay['places'];
        existingPlace = getExistingPlace(existingPlaces, placeId);
        if(!existingPlace) {
          newPlace = { id: placeId };
        }
      } else {
        newPlace = { id: placeId };
      }
    }

    // console.log('existingPlace ', existingPlace);
    // console.log('newPlace ', newPlace);

    // Hijri Date
    const hijriDate = data[4];
    // console.log('hijriDate : ', hijriDate);
    if(hijriDate) {
      if(existingDay) {
        existingDay['hijri_date'] = hijriDate
      } else {
        newDay['hijri_date'] = hijriDate
      }
    }

    // Day Title
    const dayTitle = data[5];
    if(dayTitle) {
      if(existingDay) {
        existingDay['day_title'] = dayTitle
      } else {
        newDay['day_title'] = dayTitle
      }
    } else {
      if(placeNumber === 0 && dayNumber === 0) {
        errors['errorList'].push(`${++errorsCount}. Date title not found.`);
      }
    }

    // console.log('dayTitle ', dayTitle);

    // Place Name
    const placeName = data[6];
    if(placeName) {
      if(existingPlace) {
        existingPlace['name'] = placeName;
      } else {
        newPlace['name'] = placeName;
      }
    } else {
      errors['errorList'].push(`${++errorsCount}. Place name not found.`);
    }

    // console.log('placeName ', placeName);

    // Place Summary
    const placeSummary = data[7];
    if(placeSummary) {
      if(existingPlace) {
        existingPlace['summary'] = placeSummary;
      } else {
        newPlace['summary'] = placeSummary;
      }
    } else {
      errors['errorList'].push(`${++errorsCount}. Summary not found.`);
    }

    // console.log('placeSummary ', placeSummary);

    // Place Location
    const placeLocation = data[8];
    if(placeLocation) {
      const { isValid, latitude, longitude,} = getLocationData(placeLocation);
      if(isValid) {
        if(existingPlace) {
          existingPlace['location'] = { lat: latitude, lon: longitude };
        } else {
          newPlace['location'] = { lat: latitude, lon: longitude };
        }
      } else {
        errors['errorList'].push(`${++errorsCount}. Location data is invalid.`);
      }
    } else {
      errors['errorList'].push(`${++errorsCount}. Location not found.`);
    }

    // console.log('placeLocation ', placeLocation);

    // Place Description
    const placeDescription = data[10];
    if(placeDescription) {
      if(existingPlace) {
        existingPlace['description'] = placeDescription;
      } else {
        newPlace['description'] = placeDescription;
      }
    } else {
      errors['errorList'].push(`${++errorsCount}. Description not found.`);
    }

    // console.log('placeDescription ', placeDescription);

    // Place Featured Image URL
    const placeFeaturedImageUrlData = data[11];
    if(placeFeaturedImageUrlData) {
      let [url, credit] = getArrayFromString(placeFeaturedImageUrlData);
      if(url) {
        url = url.trim();
        if(existingPlace) {
          existingPlace['featured_image'] = {}
          existingPlace['featured_image']['url'] = url;
          if(credit) {
            credit = credit.trim();
            existingPlace['featured_image']['credit'] = credit;
          }
        } else {
          newPlace['featured_image'] = {}
          newPlace['featured_image']['url'] = url;
          if(credit) {
            credit = credit.trim();
            newPlace['featured_image']['credit'] = credit;
          }
        }
      } else {
        errors['errorList'].push(`${++errorsCount}. Featured Image URL not found.`);
      }
    } else {
      errors['errorList'].push(`${++errorsCount}. Featured Image URL data not found.`);
    }

    // console.log('placeFeaturedImageUrlData ', placeFeaturedImageUrlData);

    // Place Gallery Images URLs
    const placeGalleryImagesUrls = data[12];
    // console.log('placeGalleryImagesUrls ', placeGalleryImagesUrls);
    if(placeGalleryImagesUrls) {
      try {
        const galleryImagesData = getArrayFromString(placeGalleryImagesUrls, '+');
        const galleryImages = getGalleryImages(galleryImagesData);
        if(existingPlace) {
          existingPlace['gallery'] = galleryImages;
        } else {
          newPlace['gallery'] = galleryImages;
        }
      } catch (e) {
        console.log('Error occurred while fetching Images URLs, Error : ', e);
        errors['errorList'].push(`${++errorsCount}. Invalid image URL Data.`);
      }
    } else {
      errors['errorList'].push(`${++errorsCount}. Other Images URLs not found.`);
    }

    // Place Videos URLs
    const placeGalleryVideosUrls = data[13];
    // console.log('placeGalleryVideosUrls ', placeGalleryVideosUrls);
    if(placeGalleryVideosUrls) {
      try {
        const galleryVideosData = getArrayFromString(placeGalleryVideosUrls, '+');
        const galleryVideos = await getGalleryVideos(galleryVideosData);
        if(existingPlace) {
          if(_.isEmpty(existingPlace['gallery'])) {
            existingPlace['gallery'] = galleryVideos;
          } else {
            existingPlace['gallery'] = existingPlace['gallery'].concat(galleryVideos);
          }
        } else {
          if(_.isEmpty(newPlace['gallery'])) {
            newPlace['gallery'] = galleryVideos;
          } else {
            newPlace['gallery'] = newPlace['gallery'].concat(galleryVideos);
          }
        }
      } catch (e) {
        console.log('Error occurred while fetching Videos URLs, Error : ', e);
        errors['errorList'].push(`${++errorsCount}. Invalid video URL Data.`);
      }
    }

    // Street view availability
    const streetViewAvailability = data[14];
    if(!_.isNil(streetViewAvailability)) {
      if(_.isBoolean(streetViewAvailability)) {
        if(existingPlace) {
          existingPlace['street_view_available'] = streetViewAvailability;
        } else {
          newPlace['street_view_available'] = streetViewAvailability;
        }
      } else {
        errors['errorList'].push(`${++errorsCount}. Street view availability value invalid.`);
      }
    } else {
      errors['errorList'].push(`${++errorsCount}. Street view availability value not found.`);
    }

    // Heading
    let heading = data[15];
    heading = parseInt(heading, 10);
    if(_.isFinite(heading)) {
      if(_.isInteger(heading)) {
        if(_.inRange(heading, 0, 361)) {
          if(existingPlace) {
            existingPlace['heading'] = heading;
          } else {
            newPlace['heading'] = heading;
          }
        } else {
          errors['errorList'].push(`${++errorsCount}. Heading value should be in range [0 to 360].`);
        }
      } else {
        errors['errorList'].push(`${++errorsCount}. Heading value invalid.`);
      }
    }

    // Pitch
    let pitch = data[16];
    pitch = parseInt(pitch, 10);
    if(_.isFinite(pitch)) {
      if(_.isInteger(pitch)) {
        if(_.inRange(pitch, -90, 91)) {
          if(existingPlace) {
            existingPlace['pitch'] = pitch;
          } else {
            newPlace['pitch'] = pitch;
          }
        } else {
          errors['errorList'].push(`${++errorsCount}. Pitch value should be in range [-90 to 90].`);
        }
      } else {
        errors['errorList'].push(`${++errorsCount}. Pitch value invalid.`);
      }
    }

    // Zoom
    let zoom = data[17];
    zoom = parseInt(zoom, 10);
    if(_.isFinite(zoom)) {
      if(_.isInteger(zoom)) {
        if(_.inRange(zoom, 1, 6)) {
          if(existingPlace) {
            existingPlace['zoom'] = zoom;
          } else {
            newPlace['zoom'] = zoom;
          }
        } else {
          errors['errorList'].push(`${++errorsCount}. Zoom value should be in range [1 to 5].`);
        }
      } else {
        errors['errorList'].push(`${++errorsCount}. Zoom value invalid.`);
      }
    }

    errors['errorsCount'] = errorsCount;
    if(errors['errorsCount'] > 0) {
      allErrors.push(errors);
    }

    // console.log('existingDay final : ', existingDay);
    // console.log('newDay final : ', newDay);
    // console.log('existingPlace final : ', existingPlace);
    // console.log('newPlace final : ', newPlace);

    if(existingPlace) {
      const index = _.findIndex(existingDay['places'], { id: existingPlace.id });
      existingDay['places'].splice(index, 1, existingDay['places']);
    } else {
      if(existingDay) {
        if(_.isEmpty(existingDay['places'])) {
          existingDay['places'] = [newPlace];
        } else {
          existingDay['places'].push(newPlace);
        }
      } else {
        if(_.isEmpty(newDay['places'])) {
          newDay['places'] = [newPlace];
        } else {
          newDay['places'].push(newPlace);
        }
      }
    }

    if(existingDay) {
      const index = _.findIndex(existingItem['days'], { day_number: existingDay.day_number });
      existingItem['days'].splice(index, 1, existingDay);
    } else {
      if(existingItem) {
        if(_.isEmpty(existingItem['days'])) {
          existingItem['days'] = [newDay];
        } else {
          existingItem['days'].push(newDay);
        }
      } else {
        if(_.isEmpty(item['days'])) {
          item['days'] = [newDay];
        } else {
          item['days'].push(newDay);
        }
      }
    }

    // console.log('final : existingItem : ', existingItem);
    // console.log('final : newItem : ', item);
    if(existingItem) {
      const index = _.findIndex(items, { id: existingItem.id });
      items.splice(index, 1, existingItem);
    } else {
      items.push(item);
    }
  }

  // console.log('All items : ', items);

  return {
    items,
    allErrors,
  };
};

const isValidType = type => {
  return Object.keys(VIRTUAL_TOUR_TYPES).includes(type);
};

const isValidLanguageCode = languageCode => {
  return SUPPORTED_LANGUAGES.includes(languageCode);
};

const getLocationData = location => {
  const [lat , lon] = location.split(',');
  let latitude, longitude;

  if(!lat || !lon) {
    return {
      isValid: false,
      latitude: null,
      longitude: null,
    }
  }

  latitude = parseFloat(lat);
  longitude = parseFloat(lon);
  if(!_.isFinite(latitude) || !_.isFinite(longitude)) {
    return {
      isValid: false,
      latitude: null,
      longitude: null,
    }
  }

  return {
    isValid: true,
    latitude,
    longitude,
  }
};

const getExistingItem = (items, id) => {
  return _.find(items, item => item.id === id);
};

const getExistingDay = (days, dayNumber) => {
  return _.find(days, day => day.day_number === dayNumber);
};

const getExistingPlace = (places, placeId) => {
  return _.find(places, place => place.id === placeId);
};

const getArrayFromString = (str, splitToken=',') => {
  return str.trim().replace(/(^,)|(,$)/g, "").split(splitToken)
};

const getGalleryImages = (imagesData=[]) => {
  return _.map(imagesData, imageData => {
    let [url, credit] = getArrayFromString(imageData);

    if(!url || !URL_REGEX.test(url)) {
      throw Error('Invalid url found!');
    }

    url = url.trim();

    if(credit) {
      credit = credit.trim();

      return {
        type: 'image',
        url,
        credit,
      }
    }

    return {
      type: 'image',
      url,
    }
  });
}

const getGalleryVideos = async (videosData=[]) => {
  return Promise.all(_.map(videosData, async (videoData) => {
    let [url, credit] = getArrayFromString(videoData);

    if(!url || !URL_REGEX.test(url)) {
      throw Error('Invalid url found!');
    }

    url = url.trim();

    const metaData = await getMetaData(url);

    if(credit) {
      credit = credit.trim();

      return {
        type: 'video',
        url,
        credit,
        thumbnail_url: metaData.thumbnailUrl,
        id: metaData.video_id,
      }
    }

    return {
      type: 'video',
      url,
      thumbnail_url: metaData.thumbnailUrl,
      id: metaData.video_id,
    }
  }));
}

export  {
  getItems,
  getUploadableFiles,
  getSupportedLanguages,
}
