import _ from 'lodash';
import React from 'react';
import {IMAGES_DIRECTORY_PREFIX, SUPPORTED_UPLOAD_TARGET} from "./Constants";

const getUploadableFiles = async ({
  files,
  selectedUploadTarget
}) => {
  const uploadableFiles = [];

  for(const file of files) {
    console.log(file);
    const encodedImage = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(e.target.result);
      fileReader.readAsDataURL(file);
    });

    const isPngImage = file.type === 'image/png';

    console.log('encodedImage : ', encodedImage);
    const base64EncodedImage = isPngImage
      ? encodedImage.replace('data:image/png;base64,', '')
      : encodedImage.replace('data:image/jpeg;base64,', '');

    // More info about adding meta data :
    // https://cloud.google.com/storage/docs/viewing-editing-metadata#storage-view-object-metadata-nodejs
    // https://cloud.google.com/storage/docs/uploading-objects
    const metadata = {
      contentType: `${isPngImage ? 'image/png' : 'image/jpeg'}`,
      cacheControl: 'public, max-age=31536000'
    };

    const fileUploadPath = getFileUploadPath({
      fileName: file.name,
      uploadTarget: selectedUploadTarget
    });

    console.log('base64EncodedImage : ', base64EncodedImage);
    console.log('metadata : ', metadata);

    uploadableFiles.push({
      fileBuffer: base64EncodedImage,
      options: {
        metadata,
      },
      fileType: 'image',
      fileUploadPath,
      makePublic: true, // This is options, default is makePublic: true, kept as a API reference.
      clear_cdn_cache: true,
    });
  }

  return {
    uploadableFiles,
  };
};

const getFileUploadPath = ({
  fileName,
  uploadTarget
}) => {
  return `/${IMAGES_DIRECTORY_PREFIX}/${uploadTarget}/${fileName}`;
};

const getSupportedUploadTarget = () => {
  return _.map(SUPPORTED_UPLOAD_TARGET, (languageCode) => {

    return {
      key: languageCode,
      value: languageCode,
    };
  });
};

export  {
  getUploadableFiles,
  getFileUploadPath,
  getSupportedUploadTarget,
}
