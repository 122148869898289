import AdminUserService from "./admin-user-service";
import {RTDB_DATABASE, RTDB_DATABASE_NODE, RTDB_DATABASE_URL} from "../../Constants";
const _ = require("lodash")
class ShardManager {
  getUserShardUrl = (uid) => {
    return AdminUserService.getUserShardId(uid).then( result => {
      if (result.shardid == -1){
        return `${RTDB_DATABASE_URL}${RTDB_DATABASE.MUSLIM_PRO_APP}/${RTDB_DATABASE_NODE.USERS}/${uid}`;
      }else{
        return `${RTDB_DATABASE_URL}${RTDB_DATABASE.USER_SHARD(result['shardid'])}/${RTDB_DATABASE_NODE.USERS}/${uid}`;
      }
    })
  }

  getUserShardInfo = (uid) => {
    return AdminUserService.getUserShardId(uid).then( result => _.get(result, 'shardid', -1))
  }
}

export default new ShardManager()