const ReportsList = {
  'Khatam': 'https://datastudio.google.com/embed/reporting/e4dbc9d0-2d8b-49b4-b8d2-162e6cb79ea5/page/3IuCC',
  'Engagement - Overview': 'https://datastudio.google.com/embed/reporting/c52f149e-4a64-41da-85ff-d83fd6194949/page/jVK3B',
  'Engagement - User Demographic': 'https://datastudio.google.com/embed/reporting/c52f149e-4a64-41da-85ff-d83fd6194949/page/vv03B',
  'Engagement - Content': 'https://datastudio.google.com/embed/reporting/c52f149e-4a64-41da-85ff-d83fd6194949/page/DkH4B',
  'Engagement - Inspiration': 'https://datastudio.google.com/embed/reporting/aeee031a-ffbf-4eee-95b6-f94079083725/page/AnpUC',
  'KaiOS Downloads': 'https://datastudio.google.com/embed/reporting/1lk917qWcFIwQQaU0TqvrT5mqPpgHBz2W/page/kc6LB',
};

export {
  ReportsList,
}