import _ from "lodash";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QalboxVideoPlayer from "./qalboxVideoPlayer";
import AppModal from "../../../../widgets/modal";

const QalboxURLInputBox = ({ name, value, handleInputTextChange, isHls = false }) => {
  const [showVideoPreview, setShowVideoPreview] = useState(false);
  const [authXml, setAuthXml] = useState('');
  const [authLoading, setAuthLoading] = useState(true);

  const getAuthXml = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_MP_CF_API_BASE_URL}QalBox-GetAuthXml`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            platform: 'web',
          },
        }),
      }
    );

    response.json().then((json) => {
      if (_.get(json, "result", false)) {
        setAuthXml(json.result);
        setAuthLoading(false);
      } else {
        setAuthLoading(true);
      }
    });
  }

  useState(() => {
    getAuthXml();
  }, [])

  return (
    <div>
      <div className="qalbox-form-row">
        <textarea
          value={value}
          name={name}
          type="text"
          onChange={handleInputTextChange}
          className="form-control"
          placeholder={name}
          style={{ marginBottom: "5px", marginRight: "10px" }}
        />
        <CopyToClipboard text={value}>
          <Button className="btn btn-xs btn-dark mp-content-v2-table-hover-btn">
            &nbsp;
            <span
              className="glyphicon glyphicon-duplicate"
              aria-hidden="true"
            />
          </Button>
        </CopyToClipboard>
        <div
          style={{ marginLeft: "10px", display: !authLoading ? 'block' : 'none'}}
          onClick={() => setShowVideoPreview(true)}
        >
          <span className="glyphicon glyphicon-film" aria-hidden="true" />
        </div>
      </div>
      <AppModal
        show={showVideoPreview}
        showBody={true}
        headingText={`Video Preview`}
        modalBody={
          <QalboxVideoPlayer
            url={value}
            authXml={authXml}
            encodingMethod={isHls ? 'hls' : 'dash'}
          />
        }
        modalBodyClassName={""}
        closeButtonText={""}
        okButtonText={""}
        handleClose={() => setShowVideoPreview(false)}
      />
    </div>
  );
};

export default QalboxURLInputBox;
