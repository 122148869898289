import _ from 'lodash';
import {
  getCdnPublicFileUrl,
} from '../../utils';

const getUploadedFileDetails = (uploadedFilesData) => {
  return {
    files: _.map(uploadedFilesData, fileData => ({
          fileName: fileData.file_name,
          originalPublicUrl: fileData.public_url,
          cdnPublicUrl: getCdnPublicFileUrl(fileData.public_url),
        }
    ))
  }
};

export {
  getUploadedFileDetails,
}
