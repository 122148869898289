import LoadingOverlay from "react-loading-overlay";
import { useRedisCacheClear } from "./hooks/useRedisCacheClear";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import { ScaleLoader } from "react-spinners";

export const QalboxRedisClearCacheButton = () => {
  const [clearCache, loading, error, success] = useRedisCacheClear();

  const handleRedisCacheClear = () => {
    clearCache();
  };

  useEffect(() => {
    if (error) {
      toast.error("Error occured while deleting redis cache", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    if (success) {
      toast.success("Redis cache clear is completed !!!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [error, success]);

  return (
      <LoadingOverlay
        active={loading}
        spinner={<ScaleLoader />}
        text="Loading...."
      >
        <ToastContainer autoClose={2000} />
        <button className="toolbar-button delete redis" onClick={handleRedisCacheClear}>Clear Redis Cache</button>
      </LoadingOverlay>
  );
};
