import _ from 'lodash';
import React,
{
  Component,
  Fragment,
} from 'react';
import {
  Row,
  Col,
  Alert,
  Button,
} from 'react-bootstrap';
import BasicLayout from "../../layouts/basiclayout";
import ToggleButton from "react-toggle-button";
import {
  toast,
  ToastContainer,
} from "react-toastify";
import MultiFileUploader from '../../widgets/multiFileUploader';
import JSONPretty from 'react-json-pretty';
import JSONPrettyMon from 'react-json-pretty/dist/adventure_time';
import {
  uploadImagesToGCSBucket,
} from '../../services/mp-service-v1-api-service';
import {
  getCdnPublicFileUrl,
} from '../../utils';
import {
  ContentSupportedLanguages,
  CONTENT_TYPE, GCS_IMAGE_UPLOAD_LIMIT,
} from './Constants';

const SupportedLanguages = _.without(_.keys(ContentSupportedLanguages), 'all');

const languagesObject = SupportedLanguages
    .reduce((langsObj, language) => {
      langsObj[language] = language.toUpperCase();
      return langsObj;
    }, {});

const languages = Object.keys(languagesObject).map((option) => {
  return {
    key: option,
    value: languagesObject[option]
  }
});

const contentTypes = Object.keys(CONTENT_TYPE).map((type) => {
  return {
    key: CONTENT_TYPE[type],
    value: CONTENT_TYPE[type]
  }
});

const InitialState = {
  uploading: false,
  optimizeImages: false,
  selectedLanguage: 'default',
  selectedType: 'default',
  for_ramadan: false,
  files: [],
  uploadResponse: null,
};

const getFileUploadPath = (configs) => {
  const {
    contentType, fileName, selectedLanguage, for_ramadan,
  } = configs;

  let fileUploadPath;
  if(CONTENT_TYPE.QUOTE === contentType) {
    const directoryPrefix = 'images/inspiration';

    fileUploadPath = `/${directoryPrefix}/${selectedLanguage}/${fileName}`;
    if(for_ramadan) {
      fileUploadPath = `/${directoryPrefix}/ramadan/${selectedLanguage}/${fileName}`;
    }
  } else {
    const directoryPrefix = 'images/contentv2/' + contentType;
    fileUploadPath = `/${directoryPrefix}/${selectedLanguage}/${fileName}`;
  }

  return fileUploadPath;
};

const getFilesToBeUploaded = async (files, selectedLanguage, for_ramadan, contentType) => {
  const encodedFiles = [];

  for(const file of files) {
    const encodedImage = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(e.target.result);
      fileReader.readAsDataURL(file);
    });
    const base64EncodedImage = encodedImage.replace('data:image/jpeg;base64,', '');

    // More info about adding meta data :
    // https://cloud.google.com/storage/docs/viewing-editing-metadata#storage-view-object-metadata-nodejs
    // https://cloud.google.com/storage/docs/uploading-objects
    const metadata = {
      contentType: 'image/jpeg',
      cacheControl: 'public, max-age=31536000'
    };

    const fileUploadPath = getFileUploadPath({
      contentType: contentType,
      fileName: file.name,
      selectedLanguage,
      for_ramadan,
    });

    encodedFiles.push({
      fileBuffer: base64EncodedImage,
      options: {
        metadata,
      },
      fileType: 'image',
      fileUploadPath,
      makePublic: true, // This is options, default is makePublic: true, kept as a API reference.
      clear_cdn_cache: true,
    });
  }

  return encodedFiles;
};

const getProjectAndBucketName = () => {
  return ['muslimproapi', 'mp_cdn_origin'];
};

const getModifiedUploadResponse = (uploadResponse) => {
  return {
    ...uploadResponse,
    data: _.map(uploadResponse.data, urlData => ({
        fileName: urlData.file_name,
        originalPublicUrl: urlData.public_url,
        cdnPublicUrl: getCdnPublicFileUrl(urlData.public_url),
      }
    ))
  }
};

const shouldDisableSubmitButton = (filesLength, selectedType, selectedLanguage) => {
  return filesLength === 0
      || filesLength > GCS_IMAGE_UPLOAD_LIMIT
      || selectedType === 'default'
      || selectedLanguage === 'default';
};

class UploadContentsImages extends Component {
  constructor(props) {
    super(props);
    this.state = InitialState;
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach(file => URL.revokeObjectURL(file.preview))
  }

  uploadImages() {
    const {
      files, selectedLanguage, for_ramadan, selectedType, optimizeImages,
    } = this.state;
    return getFilesToBeUploaded(files, selectedLanguage, for_ramadan, selectedType)
      .then(encodedFiles => {
        console.log(`Files to be uploaded : ${encodedFiles.length}`);
        const [project, bucketName] = getProjectAndBucketName();
          return uploadImagesToGCSBucket({
            files: encodedFiles,
            clearCdnCache: true,
            bucketName,
            project,
            optimizeImages,
          })
      .catch((err) => {
        console.log('Error occurred while uploading images. Error : ', err);
        throw err;
      });
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { files , selectedLanguage, for_ramadan, selectedType } = this.state;
    if(!selectedLanguage || selectedLanguage === 'default') {
      alert('You need to select a language before uploading.')
    } else if(!selectedType || selectedType === 'default') {
      alert('You need to select a type before uploading.')
    } else {
      const confirm = window.confirm(`Are you sure about uploading ${files.length} files for ${languagesObject[selectedLanguage]} language, and ${for_ramadan ? '' : 'not '}for Ramadan ?`);
      if(confirm) {
        this.setState({ uploading: true });
        try {
          const uploadRes = await this.uploadImages();

          if(uploadRes.data.success) {
            console.log(`Upload API response : ${JSON.stringify(uploadRes)}`);
            this.setState({
              uploadResponse: uploadRes.data
            });

            toast.success('New images uploaded successfully!', {
              position: toast.POSITION.BOTTOM_LEFT
            });
          } else {
            throw Error('Error occurred while uploading the images to Cloud Storage');
          }
        } catch (err) {
          console.log('Error occurred while uploading images. Error : ', err);
          this.setState(InitialState);
          toast.error('Something went wrong while uploading images', {
            position: toast.POSITION.BOTTOM_LEFT
          });
        }
      }
    }
  }

  onDrop(files) {
    this.setState({
      files: files.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
    })
  };

  onSelectChange(type, e) {
    const value = e.target.value;
    if(type === 'contents') {
      this.setState({ selectedType : value });
    }

    if(type === 'languages') {
      this.setState({ selectedLanguage : value });
    }
  }

  renderSelect(arr, selectedItem, type) {
    const options = arr.map((item, i) => {
      return (
          <option value={item.key} key={i}>{item.value}</option>
      );
    });

    return (
        <select value={selectedItem}
                onChange={this.onSelectChange.bind(this, type)}
                className="form-control">
          <option disabled value="default">Please select one</option>
          {options}
        </select>
    );
  }

  onForRamadanToggle() {
    let { for_ramadan } = this.state;
    this.setState({
      for_ramadan: !for_ramadan,
    });
  }

  resetView() {
    this.setState(InitialState);
  }

  copyUrls(uploadResponse) {
    const urlList = uploadResponse['data'];
    const urlArray = _.map(urlList, urlListItem => getCdnPublicFileUrl(urlListItem['public_url']));
    const copyString = urlArray.join('\r\n');
    console.log(copyString);
    const textArea = document.createElement('textarea');

    // Store string in innerHTML of textArea element
    textArea.innerHTML = copyString;

    // Find an id element within the body to append the textArea there temporarily
    const parentElement = document.getElementById('content-v2-image-upload-url-list');
    parentElement.appendChild(textArea);

    // Simulate selection of the text from textArea programmatically
    textArea.select();

    // Simulate copy command (ctrl+c)
    // Now the string with newlines should be copied to the clipboard
    document.execCommand('copy');

    // Get rid of your fluffy textarea element
    parentElement.removeChild(textArea);
  }

  removeFile(file) {
    const newFiles = [...this.state.files];
    newFiles.splice(newFiles.indexOf(file), 1);
    this.setState({ files: newFiles });
  };

  removeAllFiles() {
    this.setState({ files: [] });
  }

  renderSubmitButton(uploading, disableSubmitButton) {
    if(uploading) {
      return (
          <Button
              disabled={true}
              className="btn-primary content_editor_submit_btn">
            Uploading...
          </Button>
      );
    } else {
      return (
          <Button
              disabled={disableSubmitButton}
              type="submit"
              className="btn-primary content_editor_submit_btn">
            Upload
          </Button>
      );
    }
  }

  onToggleCheckBox(type, e) {
    this.setState({
      [type]: e.target.checked,
    });
  }

  render() {
    const {
      files, selectedType, selectedLanguage, for_ramadan, uploading, uploadResponse,
      optimizeImages,
    } = this.state;

    const disableSubmitButton =
        shouldDisableSubmitButton(files.length, selectedType, selectedLanguage);

    return (
        <BasicLayout pagePermission="readonly">
          <ToastContainer autoClose={2000} />
          <div>
            <form className={'form-inline'} onSubmit={this.handleSubmit.bind(this)}>
              <Row>
                <h3>{'Upload Images'}</h3>
              </Row>
              <br/>
              <Row>
                <Col md={3}>
                  <div style={{'marginRight': '8px'}} className="form-group">
                    <label style={{'marginRight': '5px'}}>{'Type : '}</label>
                    {this.renderSelect(contentTypes, selectedType, 'contents')}
                  </div>
                </Col>
                <Col md={4}>
                  <div style={{'marginRight': '8px'}} className="form-group">
                    <label style={{'marginRight': '5px'}}>{'Language : '}</label>
                    {this.renderSelect(languages, selectedLanguage, 'languages')}
                  </div>
                </Col>
                <Col md={3}>
                  <span>
                    <label className={'content__list_options_bar_label'}>
                      {'Optimize Images : '}
                      <input
                          className={'content__list_options_bar_input'}
                          type="checkbox"
                          checked={optimizeImages}
                          onChange={this.onToggleCheckBox.bind(this, 'optimizeImages')}
                      />
                    </label>
                 </span>
                </Col>
              </Row>
              <br/>
              {
                selectedType === CONTENT_TYPE.QUOTE ?
                    <div style={{'marginLeft': '15px'}}>
                      <Row>
                        <Col>
                          <div className="form-group">
                            <label className="show__ads_label">{'For Ramadan : '}</label>
                            <div>
                              <ToggleButton
                                  value={for_ramadan}
                                  onToggle={this.onForRamadanToggle.bind(this)}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <br/>
                    </div> : null
              }
              <Row>
                <Col>
                  <div style={{'marginLeft': '15px'}} className="form-group">
                    <Alert>
                      <strong style={{'fontSize': '20px'}}>NOTE :</strong>
                      <p></p>
                      <p></p>
                      <ul style={{ 'paddingLeft': '25px' }}>
                        <li>
                          <p>Please note you can only upload <strong><u> maximum of 30</u></strong> images to the Google Cloud Storage at a single upload.</p>
                        </li>
                        <li>
                          <p>Also note that if you upload the same name file it will <strong><u> replace the existing</u></strong> image in the Google Cloud Storage and it is <strong><u> irreversible.</u></strong></p>
                        </li>
                        <li>
                          <p>Before hit the <strong>upload</strong> button double check the  <strong><u> Type, Language, For Ramadan</u></strong> parameters.</p>
                        </li>
                        <li>
                          <p>If you are uploading the unoptimized images then please tick the Optimize Images section to optimize the images.
                            <strong><u>Please be careful</u></strong> when using this feature, because if you tick for already optimized images
                            it will caused to produce very weak images after the upload.
                          </p>
                        </li>
                      </ul>
                      <br/>
                    </Alert>
                  </div>
                </Col>
              </Row>
              {
                uploadResponse ?
                    <div style={{'marginLeft': '15px', 'marginRight': '15px'}}>
                      <Row>
                        <button
                            type={'button'}
                            onClick={this.resetView.bind(this)}
                            className="btn btn-primary">
                          {'Start a new Upload'}
                          &nbsp;<span className="glyphicon glyphicon-upload" aria-hidden="true"/>
                        </button>
                      </Row>
                      <br/>
                      <Row>
                        <Col>
                          <h4>{'File Urls :'}</h4>
                          <br/>
                          <div>
                            <button
                                type={'button'}
                                onClick={this.copyUrls.bind(this, uploadResponse)}
                                className="btn btn-primary">
                              {'Copy Urls'}
                              &nbsp;<span className="glyphicon glyphicon-copy" aria-hidden="true"/>
                            </button>
                          </div>
                          <JSONPretty
                              id="content-v2-image-upload-url-list"
                              data={JSON.stringify(getModifiedUploadResponse(uploadResponse))}
                              theme={JSONPrettyMon}
                          />
                        </Col>
                      </Row>
                    </div> :
                    <Fragment>
                      <div style={{'marginLeft': '15px', 'marginRight': '15px'}}>
                        <MultiFileUploader
                            files={files}
                            acceptTypes={'image/jpg , image/jpeg'}
                            showPreviews={files.length > 0}
                            onRemoveFile={this.removeFile.bind(this)}
                            showRemoveAllButton={true}
                            onRemoveAllFiles={this.removeAllFiles.bind(this)}
                            onDropFiles={this.onDrop.bind(this)}
                            maxFilesCount={GCS_IMAGE_UPLOAD_LIMIT}
                            maxFileReachedErrorMsg={`Maximum number of files you can upload at a single upload is ${GCS_IMAGE_UPLOAD_LIMIT}.`}
                        />
                      </div>
                      <div className="form-group">
                        {this.renderSubmitButton(uploading, disableSubmitButton)}
                      </div>
                    </Fragment>
              }

            </form>
          </div>
        </BasicLayout>
    );
  }
}

export default UploadContentsImages;
