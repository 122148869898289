import React,
{
  Component,
} from 'react';
import BasicLayout from '../../layouts/basiclayout';
import DateTime from '../../widgets/datetime';
import './style.css';
import rtdbRef from "../../apis/rtdbRef";
import {
  getUser,
} from '../../services/mp-cf-api-service';


class MultiUsagePromoCodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoCodes: null,
      mods: null,
      uniqueCreatedBys: [],
    };
  }

  componentWillMount() {
    this.fetchPromoCodes();
  }

  fetchModerators(uniqueCreatedBys) {
    let moderatorsUids = uniqueCreatedBys;
    let mods = {};
    let promises = moderatorsUids.map((uid) => {
      return getUser(uid)
      .then((res) => {
        const profile = res.data;
        const { displayName } = profile;
        mods[uid] = displayName;
        return Promise.resolve();
      })
      .catch((err) => {
        console.log(err.message);
        return Promise.resolve();
      });
    });
    return Promise.all(promises)
      .then(() => {
        this.setState({
          mods
        });
      });
  }

  fetchPromoCodes() {
    rtdbRef.promoCodes("/promoCodesMultiUsage").on("value", (snapshot) => {
      const promoCodes = snapshot.val();
      this.setState({promoCodes});
      this.saveUniqueCreatedBy(promoCodes);
    });
  }

  saveUniqueCreatedBy(promoCodes) {
    let { uniqueCreatedBys } = this.state;
    Object.keys(promoCodes).forEach((key) => {
      const createdBy = promoCodes[key]['created_by'];
      if(uniqueCreatedBys.indexOf(createdBy) === -1) {
        uniqueCreatedBys.push(createdBy);
      }
    });
    this.setState({uniqueCreatedBys});
    this.fetchModerators(uniqueCreatedBys);
  }

  renderPromoCodes() {
    const { promoCodes, mods } = this.state;
    let rows;

    if(!promoCodes) {
      rows = null;
    } else {
      const promoCodesKeys = Object.keys(promoCodes);
      rows = promoCodesKeys.map((key, i) => {
        const {
          expiry,
          feature,
          campaign,
          created_by,
          usage_limit,
          usage_count,
          created_datetime,
        } = promoCodes[key];
        return this.renderRowVal(i+1, key, expiry, campaign, created_by, created_datetime, usage_limit, usage_count, feature, mods);
      });
    }

    return (
      <div className="promo_code_table_wrapper">
        <table className="table promo_code_table small">
          <thead>
            <tr>
              <th>SL</th>
              <th>Promo code</th>
              <th>Campaign</th>
              <th>Expiry</th>
              <th>Created By</th>
              <th>Created At</th>
              <th>Redeem Status</th>
              <th>Entitled Features</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  }

  renderRowVal(i, key, expiry, campaign, created_by, created_datetime, usage_limit, usage_count, feature, mods) {
    return <tr key={key}>
      <td>{i}</td>
      <td><a href={"https://console.firebase.google.com/project/muslim-pro-app/database/mp-promo-codes/data/promoCodesMultiUsage/"+key} target="_blank">{key}</a></td>
      <td>{campaign}</td>
      <td>{expiry}</td>
<td><a className="truncated_col_el" target="_blank" href={"https://muslim-pro-app.firebaseapp.com/search-user/"+created_by}>{mods ? mods[created_by] : 'Fetching name..'}</a></td>
      <td><DateTime timestamp={created_datetime} /></td>
      <td>{usage_count || 0}/{usage_limit}</td>
      <td>{feature === 'mp_qalbox' ? 'Muslim Pro + Qalbox' : 'Muslim Pro'}</td>
    </tr>;
  }

  render() {
    return (
      <BasicLayout pagePermission="read_codes">
        {this.renderPromoCodes()}
      </BasicLayout>
    );
  }
}

export default MultiUsagePromoCodes;
