import React from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import ReactJson from 'react-json-view';

const PecalcListDiff = ({
                          theme='monokai',
                          mergeJSON,
                          onLeftItemDelete,
                          diffableLeftJSON,
                          onRightItemDelete,
                          diffableRightJSON,
                          leftSectionName = 'Left View',
                          rightSectionName='Right View',
                          displayDataTypes = false,
                          displayObjectSize = false,
                          leftSectionHeader='Left Values',
                          rightSectionHeader='Right Values',
                          mergeSectionName='Merge View',
                          mergeSectionHeader='Merged Values',
                          mainViewClassName,
                        }) => {
  return (
      <div className={mainViewClassName}>
        <Row className={'precalc-list-manual-update-diff'}>
          <Col md={6}>
            <p className={'precalcs-diff-side-view-header'}>
              {leftSectionHeader}
            </p>
            <div className={'precalcs-diff-side-view'}>
              <ReactJson
                  src={diffableLeftJSON}
                  theme={theme}
                  name={leftSectionName}
                  displayDataTypes={displayDataTypes}
                  displayObjectSize={displayObjectSize}
                  onDelete={onLeftItemDelete}
              />
            </div>
          </Col>
          <Col md={6}>
            <p className={'precalcs-diff-side-view-header'}>
              {rightSectionHeader}
            </p>
            <div className={'precalcs-diff-side-view'}>
              <ReactJson
                  src={diffableRightJSON}
                  theme={theme}
                  name={rightSectionName}
                  displayDataTypes={displayDataTypes}
                  displayObjectSize={displayObjectSize}
                  onDelete={onRightItemDelete}
              />
            </div>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col md={12}>
            <p className={'precalcs-diff-merge-view-header'}>
              {mergeSectionHeader}
            </p>
            <div className={'precalcs-diff-merge-view'}>
              <ReactJson
                  src={mergeJSON}
                  theme={theme}
                  name={mergeSectionName}
                  displayDataTypes={displayDataTypes}
                  displayObjectSize={displayObjectSize}
              />
            </div>
          </Col>
        </Row>
      </div>
  );
};

export default PecalcListDiff;
