import { useState } from 'react';

export const useDeleteBrand = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const deleteBrand = async (uuid, callback) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}qalbox-admin-brand-service/v1/delete/${uuid}`,
        {
          method: "DELETE",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to delete brand (status ${response.status})`);
      }

      const json = await response.json();
      callback(true);
      return json.success; // Return success status from the response
    } catch (err) {
      console.error(err);
      setError(err.message || 'An error occurred while deleting the brand');
      return false; // Return false if an error occurs
    } finally {
      setIsLoading(false);
    }
  };

  return [ deleteBrand, isLoading, error ];
};