import _ from 'lodash';
import React,
{
  Component,
} from 'react';
import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  ToastContainer,
} from 'react-toastify';
import JSONInput from "react-json-editor-ajrm/index";
import locale from "react-json-editor-ajrm/locale/en";
import {
  getRawPrecalcsList,
} from '../../services/mp-service-v1-api-service';
import './style.css';

import {
  COUNTRIES,
  fileNameRegex,
  PRECALC_LIST_OPERATIONS,
} from './Constants';

import {
  shouldShowDiffer,
  getPrecalcListIds,
  shouldShowJSONInput,
  getPrecalcListByFileName,
  shouldDisableStartDiffBtn,
  getDiffableCountryListJSONById,
  getDiffableOldCountryListJSONById,
} from './helper';
import PrecalcListMerger from './precalcListMerger';
import PrecalcListRawUpdater from './precalcListRawUpdater';


const countries = _.sortBy(Object.keys(COUNTRIES).map((key) => {
  return {
    key,
    value: `${COUNTRIES[key]} - ${key}`,
  }
}), ['value'] );


const operations = Object.keys(PRECALC_LIST_OPERATIONS).map((key) => {
  return {
    key,
    value: PRECALC_LIST_OPERATIONS[key]
  }
});

const InitialState = {
  submitting: false,
  showDiffer: false,
  selectedCountry: 'default',
  selectedOperation: 'default',
  diffableCountryOldJSONById: null,
  diffableCountryNewJSONById: null,
  existingPrecalcsList: null,
  apiResponseData: null,
  precalcListIds: [],
};

class UpdatePrecalcsList extends Component {
  constructor(props) {
    super(props);
    this.state = InitialState;
  }

  componentDidMount() {
    this.setState({
          loading: true,
        },
        () => {
          this.fetchPrecalcList().then(() => console.log('Precacl list fetch success!'));
        });
  }

  async fetchPrecalcList() {
    try {
      const apiResponse = await getRawPrecalcsList();
      const response = apiResponse.data;

      if(response.success) {
        const existingPrecalcsList = response.data;
        this.setState({
          loading: false,
          existingPrecalcsList,
        })
      } else {
        this.setState({
          error: 'Something went wrong while precalc list.',
          loading: false,
        })
      }
    } catch (err) {
      console.log(`Something went wrong while precalc list. Error : ${err}`);
      this.setState({
        error: 'Something went wrong while precalc list.',
        loading: false,
      })
    }
  }

  onSelectChange(type, e) {

    const value = e.target.value;
    if(type === 'countries') {
      const {
        existingPrecalcsList,
      } =  this.state;

      const selectedCountryJSON = existingPrecalcsList && existingPrecalcsList[value];
      this.setState({
        selectedCountry: value,
        selectedOperation: 'default',
        showDiffer: false,
        selectedCountryPrecalcsListJSON: selectedCountryJSON || {},
        diffableCountryNewJSONById: null,
      });
    }

    if(type === 'operations') {
      this.setState({
        selectedOperation : value,
        diffableCountryNewJSONById: null,
      });
    }
  }

  renderSelect(arr, selectedItem, type) {
    const options = arr.map((item, i) => {
      return (
          <option value={item.key} key={i}>{item.value}</option>
      );
    });

    return (
        <select value={selectedItem}
                onChange={this.onSelectChange.bind(this, type)}
                className="form-control">
          <option disabled value="default">Please select</option>
          {options}
        </select>
    );
  }

  updateJSONValue(value) {
    if(!value.error) {
      this.setState({ diffableCountryNewJSONById: value.jsObject })
    }
  }

  onFieldChange(fieldKey, e) {
    const {
      existingPrecalcsList, selectedCountry,
    } =  this.state;

    const value = e.target.value;

    if(fieldKey === 'fileName') {
      const validFileName = value ? fileNameRegex.test(value) : true;

      if(validFileName) {
        const selectedCountryJSON = existingPrecalcsList && existingPrecalcsList[selectedCountry];
        const selectedCountryPrecalcsListJSON = getPrecalcListByFileName(selectedCountryJSON, value);
        this.setState({ [fieldKey]: value, validFileName, selectedCountryPrecalcsListJSON });
      } else {
        this.setState({ [fieldKey]: value, validFileName });
      }
    }
  }

  onStartResolvingConflicts() {
    const {
      diffableCountryNewJSONById, existingPrecalcsList, selectedCountry,
    } =  this.state;

    const precalcListIds = getPrecalcListIds(diffableCountryNewJSONById);
    const selectedCountryOldJSON = existingPrecalcsList[selectedCountry];
    const diffableCountryOldJSON =
        getDiffableOldCountryListJSONById(selectedCountryOldJSON, precalcListIds);
    const diffableCountryOldJSONById = getDiffableCountryListJSONById(diffableCountryOldJSON);

    this.setState({
      showDiffer: true,
      precalcListIds,
      diffableCountryOldJSONById,
    })
  }

  renderUpdatePrecalListMerger({
                                 showJSONDiffer,
                                 precalcListIds,
                                 selectedCountry,
                                 existingPrecalcsList,
                                 diffableCountryOldJSONById,
                                 diffableCountryNewJSONById,
                               }) {
    if(!showJSONDiffer) {
      return null;
    }

    const precalcListUpdateMsg =
        'Update Precaclcs List file manually.';
    const precalcListInstructMsg =
        'Let`s try to fix the issue. Please look at the diff below for each conflicted ' +
        'files and add them to merge view.';

    return (
        <PrecalcListMerger
            diffableCountryNewJSONById={diffableCountryNewJSONById}
            diffableCountryOldJSONById={diffableCountryOldJSONById}
            precalcListIds={precalcListIds}
            existingPrecalcsList={existingPrecalcsList}
            selectedCountry={selectedCountry}
            precalcListUpdateMsg={precalcListUpdateMsg}
            precalcListInstructMsg={precalcListInstructMsg}
        />
    );
  }

  renderPrecalListRawUpdater({
                               selectedCountry,
                               selectedOperation,
                               existingPrecalcsList,
                             }) {

    if(selectedOperation !== PRECALC_LIST_OPERATIONS.UpdateRawFile) {
      return null;
    }

    return (
        <PrecalcListRawUpdater
            selectedCountry={selectedCountry}
            existingPrecalcsList={existingPrecalcsList}
        />
    );
  }

  renderJSONInput({
                    showJSONInput,
                    disableStartDiffBtn,
                    diffableCountryNewJSONById,
                  }) {
    if(!showJSONInput) {
      return null
    }

    return <Row>
      <Col md={6} className={'update-with-differ-main'}>
        <div className={'update-differ-paste-main'}>
          <p className={'update-differ-paste-header'}>
            Paste the Country Precalcs List JSON Here :
          </p>
          <JSONInput
              placeholder={diffableCountryNewJSONById} // data to display
              theme="darktheme"
              locale={locale}
              colors={{
                string: "#DAA520" // overrides theme colors with whatever color value you want
              }}
              onChange={value => this.updateJSONValue(value)}
          />
          <Button
              disabled={disableStartDiffBtn}
              onClick={
                () => this.onStartResolvingConflicts()
              }
              className="btn-primary precalc-merge-start-btn"
          >
            Start Resolving Conflicts
          </Button>
        </div>
      </Col>
    </Row>;
  }

  render() {
    const {
      showDiffer,
      precalcListIds,
      selectedCountry,
      selectedOperation,
      existingPrecalcsList,
      diffableCountryNewJSONById,
      diffableCountryOldJSONById,
    } = this.state;

    const showJSONDiffer = shouldShowDiffer(selectedOperation, diffableCountryNewJSONById, showDiffer);
    const showJSONInput = shouldShowJSONInput(selectedOperation, showDiffer);
    const disableStartDiffBtn = shouldDisableStartDiffBtn(selectedOperation, diffableCountryNewJSONById);

    return (
        <BasicLayout pagePermission="moderate_precalc">
          <ToastContainer autoClose={2000} />
          <div>
            <form className={'form-inline'}>
              <Row>
                <h3>{'Update Precalcs List'}</h3>
              </Row>
              <br/>
              <Row>
                <Col md={4}>
                  <div className="form-group">
                    <label style={{'marginRight': '5px'}}>{'Country : '}</label>
                    {this.renderSelect(countries, selectedCountry, 'countries')}
                  </div>
                </Col>
                {
                  selectedCountry && selectedCountry !== 'default' ?
                      <Col md={4}>
                        <div className="form-group">
                          <label style={{'marginRight': '5px'}}>{'Operation : '}</label>
                          {this.renderSelect(operations, selectedOperation, 'operations')}
                        </div>
                      </Col> : null
                }
              </Row>
              {
                 this.renderJSONInput({
                   showJSONInput,
                   disableStartDiffBtn,
                   diffableCountryNewJSONById,
                 })
              }
              {
                this.renderUpdatePrecalListMerger({
                  showJSONDiffer,
                  precalcListIds,
                  selectedCountry,
                  existingPrecalcsList,
                  diffableCountryOldJSONById,
                  diffableCountryNewJSONById,
                })
              }
              {
                this.renderPrecalListRawUpdater({
                  selectedCountry,
                  selectedOperation,
                  existingPrecalcsList,
                })
              }
            </form>
          </div>
        </BasicLayout>
    );
  }
}

export default UpdatePrecalcsList;
