import axios from "axios";
import { MP_API_SERVER_BASE_URL } from "./Constants";

const getBaseConfig = (idToken) => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (idToken) {
    headers["Authorization"] = `Bearer ${idToken}`;
  }

  return {
    baseURL: MP_API_SERVER_BASE_URL,
    headers,
  };
};

export default axios.create({
  ...getBaseConfig(localStorage.getItem("idToken")),
  responseType: "json",
});
