const CONTENT_TYPE = Object.freeze({
  QUOTE: 'quote',
  IMAGE: 'image',
  VIDEO: 'video',
  ARTICLE: 'article'
});

const AUDIENCE_TYPE = Object.freeze({
  PREMIUM: 'premium',
  FREE: 'free',
  ALL: 'all',
});

const FEATURED_TYPE = Object.freeze({
  DEFAULT: 'default',
  PARTNERED: 'partnered',
  LIVE: 'live',
});

const ContentSupportedLanguages = {
  "all": "All",
  "en": "English",
  "id": "Bahasa Indonesia",
  "ms": "Bahasa Melayu",
  "fr": "French",
  "tr": "Turkey",
  "bn": "Bangladesh",
  "ar": "Arabic",
  "ur": "Urdu",
};

const LessonSupportedLanguages = {
  "en": "English",
  "id": "Bahasa Indonesia",
  "ms": "Bahasa Melayu",
  "fr": "French",
  "de": "German",
};

const ENGLISH_CHARACTERS_SUPPORTED_LANGUAGES = ['en', 'id', 'ms'];

const GCS_IMAGE_UPLOAD_LIMIT = 30;
const EXCEL_CONTENT_CREATE_LIMIT = 100;


const AdPermittedURLs = [
  'muslimpro.com'
];

const CONTENT_BASE_YEAR = 2019;

const CONTENT_V2_TRENDING_HASH_TAG_CACHE_ID = 'content-v2-trending-hash-tags';

export {
  AUDIENCE_TYPE,
  CONTENT_TYPE,
  ContentSupportedLanguages,
  LessonSupportedLanguages,
  GCS_IMAGE_UPLOAD_LIMIT,
  EXCEL_CONTENT_CREATE_LIMIT,
  AdPermittedURLs,
  ENGLISH_CHARACTERS_SUPPORTED_LANGUAGES,
  FEATURED_TYPE,
  CONTENT_BASE_YEAR,
  CONTENT_V2_TRENDING_HASH_TAG_CACHE_ID,
};