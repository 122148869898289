import * as _ from "lodash";
import React, { useState } from "react"
import '../style.css'
import {ProgressBar} from "react-bootstrap";

const DailyDeenContentPreview = (props) => {
  const [ show, setShow ] = useState(false);

  const handleOpen = () => {
    console.log("data", props.data)
    setShow(true);
  }

  const handleClose = () => {
    console.log("close ?")
    setShow(false);
  }

  const getContentBody = () => {
    console.log(_.get(props.data, 'content_type', ''))
    if( _.get(props.data, 'content_type', '') != "sura") {
      return "Al-Faatiha " + (
        Number(_.get(props.data, 'metadata.segment_start', '').slice(-3))
      ) + ":" + (
        Number(_.get(props.data, 'metadata.segment_end', '').slice(-3))
      );
    }else {
      return _.get( props.data, 'content_body', '');
    }
  }

  return (
    <span>
      <button className={"btn btn-primary"} onClick={handleOpen}>Preview</button>

      {
        show && (
          <div className="daily-deen-preview-popup" onClick={handleClose}>
            <div
              style={{
                position: "absolute",
                backgroundImage: `url(${_.get(props.data, 'content_array.full', '')})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                width: "480px",
                height: "853px",
              }}
              onClick={handleClose}
            >
              <span className={"toptext"}></span>
              <div className={"bottomtext"}>
                <div ><b>Daily Deen</b></div>
                <div>{getContentBody()}</div>
                <div>{}</div>
                <div>
                  <span className={"glyphicon glyphicon-envelope"}></span>
                  <span> Preview: {props.data.theme}</span>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </span>
  )
}

export default DailyDeenContentPreview;