import React,
{
  useState,
} from 'react';
import {
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  MPGCSUploadImages,
} from '../../widgets/upload-images';
import {
  ImageUploadInfoPanel,
} from './common';
import {
  getUploadableFiles,
  getSupportedUploadTarget,
} from './helper';
import {
  MPSelect,
} from '../../widgets/common';

const ToolsUploadImages = () => {
  const [ uploadTarget, setUploadTarget ] = useState('');
  const disableUploadButton = !uploadTarget;

  return (
    <BasicLayout pagePermission="moderate_precalc">
      <ToastContainer autoClose={2000}/>
      <Row>
        <Col md={12}>
          <h3 className='content_editor_title'>{'Upload Images'}</h3>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <div className="form-group">
            <label
              style={{
                'marginRight': '5px',
              }}
            >
              {'Image Upload Path : '}
            </label>
            <MPSelect
              items={getSupportedUploadTarget()}
              selectedItem={uploadTarget}
              onChangeSelect={setUploadTarget}
            />
          </div>
        </Col>
      </Row>
      <MPGCSUploadImages
        InfoPanel={ImageUploadInfoPanel}
        getUploadableFiles={
          files => getUploadableFiles({
            files,
            selectedUploadTarget: uploadTarget
          })
        }
        disableUploadButton={disableUploadButton}
        onUploadSuccess={() => setUploadTarget('')}
        acceptTypes={'image/jpg , image/jpeg, image/png'}
        enableOptimizeImages={false}
      />
    </BasicLayout>
  );
};

export default ToolsUploadImages;
