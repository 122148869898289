import { useState, useEffect } from 'react';
import axiosService from '../../../../service_v2/common/axios-service';

export const useFetchShorts = (title, refresh) => {
  const [shortsResponse, setShortResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchShorts = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await axiosService.get(
          `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}qalbox-admin-short-service/v1/get/all?title=${title}&&requestLimit=500`
        );

        const shorts = response?.data;
        setShortResponse(shorts);
      } catch (err) {
        console.error(err);
        setError(err.message || 'An error occurred while fetching shorts');
      } finally {
        setIsLoading(false);
      }
    };

    fetchShorts();

  }, [title, refresh]);

  return [ shortsResponse, isLoading, error ];
};