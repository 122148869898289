import _ from 'lodash';
import {
  validYearRegex,
  SIGHTING_HIJRI_NEXT_COUNT,
} from './Constants';
import moment_hirji from 'moment-hijri';
import {
  countries as CountryList,
} from '../../services/utils';
import moment from 'moment-timezone';
import JSONDiffView from '../../widgets/json-diff-view';
import React from 'react';

const isValidHijriYear = year => {
  return validYearRegex.test(year);
};

const getSelectedCountryCodes = (selectedCountries) => {
  if(_.isEmpty(selectedCountries)) {
    return [];
  }

  return _.map(selectedCountries, country => country.value.toLowerCase());
};

const getExistingCountryCodes = (selectedCountryCodes, existingCountries) => {
  if(_.isEmpty(selectedCountryCodes) || _.isEmpty(existingCountries)) {
    return null;
  }
  const existingCountryCodes = _.keys(existingCountries);

  return _.intersection(selectedCountryCodes, existingCountryCodes);
};

const getSightSelectYears = () => {
  const currentHijriYear = moment_hirji().iYear();
  const lastHijriYear = currentHijriYear + SIGHTING_HIJRI_NEXT_COUNT;

  return _.range(currentHijriYear, lastHijriYear).map(hijriYear => {
    return {
      key: hijriYear,
      value: hijriYear
    }
  });
};

const shouldShowGcsFileVersionLister = (
  fetching,
  versionData,
  ) => {

  return !(_.isEmpty(versionData) || fetching);
};

const getCountryOptions = () => {

  return  Object.keys(CountryList).map((countryCode) => {
    return {
      value: countryCode,
      label: CountryList[countryCode]
    }
  });
};

const getDefaultYearDates = () => {

  return [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ];
};

const getHijriDateFromMoment = date => {
  return moment.isMoment(date) ? date.format('D-M-YYYY') : date;
};

const getCountryBasedCalendarData = (hijriJSON) => {
  const copyJSON = _.cloneDeep(hijriJSON);
  if(copyJSON) {
    delete copyJSON['timestamp'];
    delete copyJSON['sightings'];
  }

  return copyJSON;
};

const isValidHijriJson = (hijriJSON) => {
  let validJSON = true
  const countryBasedCalendar = getCountryBasedCalendarData(hijriJSON);
  _.forEach(countryBasedCalendar, countryYears => {
    if(_.isEmpty(countryYears)) {
      validJSON = false;
      return false;
    }

    _.forEach(countryYears, dateArray => {
      _.forEach(dateArray, date => {
        if(_.isNil(date)) {
          validJSON = false;
          return false;
        }
      });

      if(!validJSON) {
        return false;
      }
    });

    if(!validJSON) {
      return false;
    }
  });

  return validJSON;
};

const getUpdatedFileDiff = (updatedData, initialData) => {

  return (
      <JSONDiffView
          leftSideData={initialData}
          rightSideData={updatedData}
          messageIfBothSideIdentical={'No change found!'}
      />
  );
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

export {
  isValidHijriYear,
  getSelectedCountryCodes,
  getExistingCountryCodes,
  getSightSelectYears,
  shouldShowGcsFileVersionLister,
  getCountryOptions,
  getDefaultYearDates,
  getHijriDateFromMoment,
  getCountryBasedCalendarData,
  isValidHijriJson,
  getUpdatedFileDiff,
  scrollToTop,
}
