import React from 'react';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
} from 'react-bootstrap';
import BasicLayout from '../../layouts/basiclayout';
import {
  MPUploadExcel,
} from '../../widgets/upload-excel';
import {
  DisplayCategoriesExcelUploadInfoPanel,
} from './common';
import {
  qalBoxDisplayCategoriesAddOrUpdateHook,
} from '../../hooks';
import {
  getDisplayCopyItems,
  getDisplayCategoryItems,
} from './helper';

const UploadExcel = () => {
  const onExcelUploadComplete = operationData => {
    if(!operationData.isError) {
      toast.success(operationData.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onUploadSuccess = () => {
    onExcelUploadComplete({
      operationData: {
        isError : false,
        message: 'Display categories add/update success!!',
      },
    });
  };

  const onUploadFailed = () => {
    onExcelUploadComplete({
      operationData: {
        isError : true,
        message: 'Display categories add/update success!!',
      },
    });
  };

  return (
    <BasicLayout pagePermission="moderate_precalc">
      <ToastContainer autoClose={2000}/>
      <Row>
        <Col md={12}>
          <h3 className='content_editor_title'>{'Upload Display Categories Excel'}</h3>
        </Col>
      </Row>
      <MPUploadExcel
        InfoPanel={DisplayCategoriesExcelUploadInfoPanel}
        fileUpdateHook={qalBoxDisplayCategoriesAddOrUpdateHook}
        fileUpdateSuccessMessage={'Display Categories Added/Updated Successfully!!'}
        getItems={getDisplayCategoryItems}
        getCopySuccessItems={getDisplayCopyItems}
        onUploadSuccess={onUploadSuccess}
        onUploadFailed={onUploadFailed}
        showResultOnSuccess={true}
      />
    </BasicLayout>
  );
};

export default UploadExcel;
