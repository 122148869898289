import React,
{
  Fragment,
  useReducer,
} from 'react';
import {
  clearV2RedisCacheHook,
  clearCFsRedisCacheHook,
} from '../../hooks';
import BasicLayout from '../../layouts/basiclayout';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import {
  RedisCaches,
  CFRedisFlushKeys,
  APIV2RedisFlushKeys,
  APIV2RedisFlushTypes,
} from './Constants';
import {
  getFlushInfo,
} from './common';
import {
  getCacheFlushKeys,
  shouldDisableAPIV2FlushCacheSubmitBtn,
} from './helper';
import {
  confirmAlert,
} from "react-confirm-alert";
import AppModal from '../../widgets/modal';
import {
  MPInput,
  MPSelect,
} from '../../widgets/common';
import {
  RCCInitialState,
  redisClearCacheReducer,
} from './Reducers';
import {
  actionTypes,
} from './actionTypes';

const redisCaches = Object.keys(RedisCaches).map((cache) => {
  return {
    key: cache,
    value: RedisCaches[cache]
  }
});

const flushTypes = Object.keys(APIV2RedisFlushTypes).map((option) => {
  return {
    key: option,
    value: APIV2RedisFlushTypes[option]
  }
});

const v2CacheKeys = Object.keys(APIV2RedisFlushKeys).map((option) => {
  return {
    key: option,
    value: option
  }
});

const cFCacheKeys = Object.keys(CFRedisFlushKeys).map((option) => {
  return {
    key: option,
    value: option
  }
});

const FlushCache = () => {

  const[ state, dispatch ] = useReducer(redisClearCacheReducer, RCCInitialState);
  const {
    selectedAPI,
    subCacheKey,
    mainCacheKey,
    selectedCacheKey,
    selectedFlushType,
  } = state;

  const [ mainKey, subKey ] =
      getCacheFlushKeys(selectedAPI, selectedCacheKey, mainCacheKey, subCacheKey);

  const clearCacheHook = RedisCaches[selectedAPI] === RedisCaches.API_V2 ?
      clearV2RedisCacheHook : clearCFsRedisCacheHook;

  const [ clearCacheResponseData, setClearCacheData ] = clearCacheHook();
  const isClearing = clearCacheResponseData && clearCacheResponseData.isClearing;
  const disableSubmitBtn =  shouldDisableAPIV2FlushCacheSubmitBtn({
    selectedFlushType,
    selectedCacheKey,
    mainCacheKey,
  });

  const onCompleteOperation = operationData => {
    if(!operationData.isError) {
      toast.success(`Cache clear success for main key : ${mainKey}, sub key : ${subKey}`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const renderFlushOptions = () => {
    if(APIV2RedisFlushTypes[selectedFlushType] === APIV2RedisFlushTypes.manual) {
      return (
          <Fragment>
            <Row>
              <Col md={12}>
                <div className='form-group'>
                  <label style={{'marginRight': '5px'}}>Main Key</label>
                  <MPInput
                    value={mainCacheKey}
                    placeHolder={'Main Key'}
                    onChangeValue={(value) => dispatch({
                        type: actionTypes.RCC_UPDATE_PROP_VAL,
                        payload: {
                          prop: 'mainCacheKey',
                          value,
                        },
                      })
                    }
                  />
                </div>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={12}>
                <div className='form-group'>
                  <label style={{'marginRight': '5px'}}>Sub Key</label>
                  <MPInput
                      value={subCacheKey}
                      placeHolder={'Sub Key'}
                      onChangeValue={(value) => dispatch({
                          type: actionTypes.RCC_UPDATE_PROP_VAL,
                          payload: {
                            prop: 'subCacheKey',
                            value,
                          },
                        })
                      }
                  />
                </div>
              </Col>
            </Row>
          </Fragment>
      );
    } else if(APIV2RedisFlushTypes[selectedFlushType] === APIV2RedisFlushTypes.select) {
      return (
          <Row>
            <Col md={6}>
              <div className='form-group'>
                <label style={{'marginRight': '5px'}}>Cache Key</label>
                <MPSelect
                   items={
                     RedisCaches[selectedAPI] === RedisCaches.API_V2 ? v2CacheKeys : cFCacheKeys
                   }
                   selectedItem={selectedCacheKey}
                   onChangeSelect={(value) => dispatch({
                       type: actionTypes.RCC_UPDATE_PROP_VAL,
                       payload: {
                         prop: 'selectedCacheKey',
                         value,
                       },
                     })
                   }
                />
              </div>
            </Col>
          </Row>
      );
    } else {
      return null
    }
  };

  const onClickFlushCacheSubmit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <AppModal
                show={true}
                showBody={true}
                headingText={`Are you sure want to flush the following keys ?`}
                modalBody={getFlushInfo({ selectedAPI, selectedCacheKey, mainCacheKey, subCacheKey })}
                modalBodyClassName={'precalcs-diff-merge-view'}
                closeButtonText={'No'}
                okButtonText={`Yes, Flush!`}
                handleClose={onClose}
                handleOk={() => {
                  onClose();
                  setClearCacheData({
                    mainKey,
                    subKey,
                    onOperationComplete: onCompleteOperation,
                  });
                }}
            />
        );
      }
    });
  };

  return (
      <BasicLayout pagePermission="readonly">
        <ToastContainer autoClose={2000}/>
        <div>
            <Row>
              <Col md={12}>
                <h3 className='content_editor_title'>{'Flush Cache'}</h3>
              </Col>
            </Row>
            <form className={'form-inline'}>
              <Row>
                <Col md={6}>
                  <div className='form-group'>
                    <label style={{'marginRight': '5px'}}>API</label>
                    <MPSelect
                        items={redisCaches}
                        selectedItem={selectedAPI}
                        onChangeSelect={(value) => dispatch({
                            type: actionTypes.RCC_UPDATE_PROP_VAL,
                            payload: {
                              prop: 'selectedAPI',
                              value,
                            },
                          })
                        }
                    />
                  </div>
                </Col>
              </Row>
              <br/>
              {
                selectedAPI &&
                <Row>
                  <Col md={6}>
                    <div className='form-group'>
                      <label style={{'marginRight': '5px'}}>Flush Type</label>
                      <MPSelect
                          items={flushTypes}
                          selectedItem={selectedFlushType}
                          onChangeSelect={(value) => dispatch({
                              type: actionTypes.RCC_UPDATE_PROP_VAL,
                              payload: {
                                prop: 'selectedFlushType',
                                value,
                              },
                            })
                          }
                      />
                    </div>
                  </Col>
                </Row>
              }
              <br/>
              {renderFlushOptions()}
              <br/>
              {
                selectedFlushType &&
                  <Row>
                    <Col md={6}>
                      <Button
                          onClick={onClickFlushCacheSubmit}
                          disabled={disableSubmitBtn || isClearing}
                          className="btn-primary api-test-submit-button">
                        { isClearing ? 'Clearing...' : 'Clear' }
                      </Button>
                    </Col>
                  </Row>
              }
            </form>
        </div>
      </BasicLayout>
  );
};

export default FlushCache;
