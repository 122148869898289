// Permission Table
// 0000001 = prayer_requests, 1
// 0000010 = read_codes, 2
// 0000100 = write_codes, 4
// 0001000 = read_users, 8
// 0010000 = write_users, 16
// 0100000 = moderate_precalc, 32
// 1000000 = change_user_permission, 64

const {
  ADMIN_DATABASE_URL,
} = require('../Constants');

const PAGE_PERMISSIONS = {
  ecommerce_editor: 1,
  content_editor: 1,
  prayer_requests: 1,
  read_codes: 2,
  write_codes: 4,
  read_users: 8,
  write_users: 16,
  moderate_precalc: 32,
  change_user_permission: 64,
  readonly: 0,
  aideen: 128
};

class RoleManager {
  constructor(firebase) {
    const app = firebase.app();
    this.firebase = firebase;
    this.DB = firebase.database();
    this.adminDB = app.database(ADMIN_DATABASE_URL);
    this.currentUID = localStorage.getItem('uid');
  }

  // Method to get any users role code
  getUserPermission(uid) {
    // For now read the roles from adminDB instead of DB
    return this.adminDB.ref(`/moderators/${uid}`).once('value').then((snapshot) => {
      if(uid === this.currentUID) {
        this.currentRoleCode = snapshot.val();
      }
      return snapshot.val();
    });
  }

  // Method to get current users role code
  getCurrentUserPermission() {
    return this.getUserPermission(this.currentUID).then((code) => {
      this.currentRoleCode = code;
      return code;
    });
  }

  getPageRenderingPermission(uid, permission) {
    return this.getUserPermission(uid).then((usersPermission) => {
      const defaultPagePermission = PAGE_PERMISSIONS[permission];
      const verdict = ((usersPermission & defaultPagePermission) === defaultPagePermission);
      // console.log(`${usersPermission} & ${defaultPagePermission} === ${defaultPagePermission} => ${verdict}`);
      return {
        pageRenderingPermission: verdict,
        usersPermission
      };
    });
  }

  int2bin(n) {
    const bin = n.toString(2);
    let padding = "";
    for(let i=bin.length; i<7; i++){
      padding+="0";
    }
    return padding+bin;
  }

}

export {
  RoleManager,
};
