// Trending Hashtags Actions
const THT_UPDATE_PROP_VAL = 'THT_UPDATE_PROP_VAL';
const THT_ADD_CUSTOM_HASHTAG = 'THT_ADD_CUSTOM_HASHTAG';
const THT_BLOCK_HASHTAG = 'THT_BLOCK_HASHTAG';
const THT_CHANGE_CUSTOM_HASHTAG_ORDER = 'THT_CHANGE_CUSTOM_HASHTAG_ORDER';
const THT_FETCH_COMPLETE_RTDB_TRENDING_HASHTAGS = 'THT_FETCH_COMPLETE_RTDB_TRENDING_HASHTAGS';
const THT_FETCH_COMPLETE_RTDB_TRENDING_HASHTAGS_API_LIMIT = 'THT_FETCH_COMPLETE_RTDB_TRENDING_HASHTAGS_API_LIMIT';
const THT_RESET_CUSTOM_HASHTAGS = 'THT_RESET_CUSTOM_HASHTAGS';
const THT_RESET_BLOCKED_HASHTAGS = 'THT_RESET_BLOCKED_HASHTAGS';
const THT_REMOVE_CUSTOM_HASHTAG = 'THT_REMOVE_CUSTOM_HASHTAG';
const THT_REMOVE_BLOCKED_HASHTAG = 'THT_REMOVE_BLOCKED_HASHTAG';

export const actionTypes = {
  THT_BLOCK_HASHTAG,
  THT_UPDATE_PROP_VAL,
  THT_ADD_CUSTOM_HASHTAG,
  THT_CHANGE_CUSTOM_HASHTAG_ORDER,
  THT_FETCH_COMPLETE_RTDB_TRENDING_HASHTAGS,
  THT_FETCH_COMPLETE_RTDB_TRENDING_HASHTAGS_API_LIMIT,
  THT_RESET_CUSTOM_HASHTAGS,
  THT_RESET_BLOCKED_HASHTAGS,
  THT_REMOVE_CUSTOM_HASHTAG,
  THT_REMOVE_BLOCKED_HASHTAG,
};