export const ad_spaces = [
  'pre_roll',
  'mid_roll',
  'post_roll',
  'media_page_banner_ad'
];

export const adSpacesWithNoDuration = () => { 
  const adSpaces = ad_spaces.filter( ( list ) => ![].includes( list ) );
  return adSpaces
}
export const adSpacesWithNoStartTime = () => { 
  const adSpaces = ad_spaces.filter( ( list ) => ![
    'mid_roll'
  ].includes( list ) );
  return adSpaces
}

export const DEFAULT_AD_UNIT_TIME_DURATION = 10;

const convertToSeconds = (time) => {
  const [minutes, seconds] = time.split(':').map(Number);
  return minutes * 60 + seconds;
};

const convertToTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};

export const getAutomatedTimeSlots = (slots, data) => {
  if (!data || !data.duration) {
    throw new Error("Invalid data object. Ensure 'data' has 'duration' property.");
  }

  const endOfTitle = data.play_next_after ? data.play_next_after : data.duration;
  const startOfTitle = "00:12"; //end of sonic branding
  const time_slots = [];

  const startSeconds = convertToSeconds(startOfTitle);
  const endSeconds = convertToSeconds(endOfTitle);

  if (endSeconds <= startSeconds) {
    throw new Error("End time must be greater than start time.");
  }

  const duration = endSeconds - startSeconds;
  const interval = duration / (slots * 2);

  for (let i = 1; i <= (slots * 2) ; i = i + 2) {
    const slotTimeInSeconds = startSeconds + (interval * i);
    time_slots.push(convertToTime(Math.round(slotTimeInSeconds)));
  }

  return time_slots;
}