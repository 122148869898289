import React,
{
  Fragment,
  useReducer,
} from 'react';
import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import {
  CopyToClipboard,
} from 'react-copy-to-clipboard';
import PecalcListDiff from './precalcListDiff';
import {
  getUpdatedCountryPrecalcList,
  shouldDisableMergeResetAllButton,
  shoulDisableUpdatePrecaclListButton,
  shouldDisableMergeResetCurrentButton,
} from './helper';
import {
  confirmAlert,
} from "react-confirm-alert";
import AppModal from '../../widgets/modal';
import ReactJson from 'react-json-view';
import _ from 'lodash';
import {
  ScaleLoader,
} from 'react-spinners';
import {
  fetchPrecalcListHook,
  updatePrecalcListHook,
} from '../../hooks';
import {
  listMergeReducer,
} from './Reducers';
import {
  actionTypes,
} from './actionTypes';

const getChangedPrecalcsInfo = (mergedCountryJSONById) => {
  return (
      <ReactJson
          src={mergedCountryJSONById}
          theme={'monokai'}
          name={'Merged Changes'}
          displayDataTypes={false}
          displayObjectSize={false}
      />
  );
};

const InitialState = {
  stateResetted: false,
  selectedPrecalcListIndex: 0,
  diffableSelectedItemOldJSONById: {},
  diffableSelectedItemNewJSONById: {},
  diffableCountryOldJSONById: null,
  diffableCountryNewJSONById: null,
  mergedCountryJSONById: {},
  currentListId: null,
  updatedPrecalcList: null,
  updatedPrecalcs: {},
  precalcListIds: [],
};

const PrecalcListMerger = ({
                             precalcListIds,
                             selectedCountry,
                             precalcListUpdateMsg,
                             precalcListInstructMsg,
                             reloadPage=true,
                             resetState=true,
                             diffableCountryOldJSONById,
                             diffableCountryNewJSONById,
                           }) => {

  const [ precalcList ] = fetchPrecalcListHook({
    shouldFetchPrecalcList: true,
  });

  const [ state, dispatch ] = useReducer(listMergeReducer, {
    ...InitialState,
    currentListId: precalcListIds[0],
    diffableSelectedItemOldJSONById: {...diffableCountryOldJSONById},
    diffableSelectedItemNewJSONById: {...diffableCountryNewJSONById},
    diffableCountryOldJSONById,
    diffableCountryNewJSONById,
    precalcListIds,
  });

  const {
    currentListId,
    stateResetted,
    updatedPrecalcList,
    mergedCountryJSONById,
    selectedPrecalcListIndex,
    diffableSelectedItemOldJSONById,
    diffableSelectedItemNewJSONById,
  } = state;


  if(!stateResetted && resetState) {
    dispatch({
      type: actionTypes.LM_RESET_MERGE,
    });
  }

  const [ precalcListUpdate ] = updatePrecalcListHook({
    selectedCountry,
    updatedPrecalcList,
    shouldUpdatePrecalcList: true,
  });

  if(precalcListUpdate && reloadPage) {
    setTimeout(()=> {
      window.location.reload();
    }, 1500);
  }

  const confirmPrecalcsListUpdate = () => {
    const updatedPrecalcList =
        getUpdatedCountryPrecalcList(mergedCountryJSONById, precalcList, selectedCountry);
    dispatch({
      type: actionTypes.LM_CONFIRM_PRECALC_LIST_UPDATE,
      payload: updatedPrecalcList,
    });
  };

  const updatePrecalcList = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <AppModal
                show={true}
                showBody={true}
                headingText={`Are you sure want to update the Precalcs List ?`}
                modalBody={getChangedPrecalcsInfo(mergedCountryJSONById)}
                modalBodyClassName={'precalcs-diff-merge-view'}
                closeButtonText={'No'}
                okButtonText={'Yes, Update'}
                handleClose={onClose}
                handleOk={() => {
                  confirmPrecalcsListUpdate();
                  onClose();
                }}
            />
        );
      }
    });
  };

  const disableUpdatePrecaclListButton =
      shoulDisableUpdatePrecaclListButton(precalcListIds, mergedCountryJSONById);
  const disableResetCurrentButton =
      shouldDisableMergeResetCurrentButton(mergedCountryJSONById, currentListId);
  const disableResetAllButton = shouldDisableMergeResetAllButton(mergedCountryJSONById);

  const isMergeItemsEmpty = _.isEmpty(mergedCountryJSONById[currentListId]);
  const mainViewClassName =
      isMergeItemsEmpty ? 'precalc-diff-main-error' : 'precalc-diff-main-success';
  const mergeSelectedItemNewJSONById = isMergeItemsEmpty ? [] :
      [...mergedCountryJSONById[currentListId]];

  if(precalcListUpdate) {
    return (
        <Row className={'precalc-list-manual-update'}>
          <Col md={12} className={'text-center'}>
            <h3 className={`precalc-list-update-operation-title-${precalcListUpdate.status ? 'success' : 'error'}`}>
              {precalcListUpdate.message}
            </h3>
            {
              !precalcListUpdate.status ?
                  <Fragment>
                    <p className={'precalc-list-update-operation-info'}>
                      {'Please copy the new changes, and forward to relevant party in order to update precalcs list manually!'}
                    </p>
                    <CopyToClipboard
                        text={JSON.stringify(diffableCountryNewJSONById)}
                        onCopy={() => {
                          console.log(`Copied : ${JSON.stringify(diffableCountryNewJSONById)}`)
                        }}
                    >
                      <div className={'copy-precalc-new-list-on-error'}>
                        <p className={'copy-precalc-new-list-text-on-error'}>
                          Copy All New
                        </p>
                        <Button className="btn btn-xs btn-dark copy-precalc-new-list-on-error-btn">
                          &nbsp;<span className="glyphicon glyphicon-duplicate" aria-hidden="true"/>
                        </Button>
                      </div>
                    </CopyToClipboard>
                  </Fragment> : null
            }
          </Col>
        </Row>
    );
  }

  if(updatedPrecalcList) {
    return (
        <Row className={'precalc-list-manual-update'}>
          <Col md={12} className={'text-center'}>
            <h6 className={'precalc-list-update-operation-title'}>
              {'Please wait!! Updating the Precalc List.'}
            </h6>
            <ScaleLoader
                color={'#097233'}
            />
          </Col>
        </Row>
    );
  }

  return (
      <Fragment>
        <Row className={'precalc-list-manual-update'}>
          <Col md={12}>
            <p className={'precalc-list-update-title'}>{precalcListUpdateMsg}</p>
          </Col>
          <Col md={12}>
            <div className={'precalc-list-update-inst-copy'}>
              <p className={'precalc-list-update-instructions'}>
                {precalcListInstructMsg}
              </p>
              <CopyToClipboard
                  text={JSON.stringify(diffableCountryNewJSONById)}
                  onCopy={() => {
                    console.log(`Copied : ${JSON.stringify(diffableCountryNewJSONById)}`)
                  }}
              >
                <div className={'copy-precalc-new-list'}>
                  <p className={'copy-precalc-new-list-text'}>
                    Copy All New
                  </p>
                  <Button className="btn btn-xs btn-dark">
                    &nbsp;<span className="glyphicon glyphicon-duplicate" aria-hidden="true"/>
                  </Button>
                </div>
              </CopyToClipboard>
            </div>
          </Col>
        </Row>
        <div className={'precalc-diff-resolver-main'}>
          <Row>
            <Col md={12} className={'precalc-merge-tool-bar'}>
              <div className={'precalc-merge-reset-buttons'}>
                <Button
                    disabled={disableResetAllButton}
                    onClick={() => dispatch({
                        type: actionTypes.LM_RESET_MERGE,
                      })
                    }
                    className="btn-primary precalc-merge-reset-btn"
                >
                  Reset All
                </Button>
                <Button
                    disabled={disableResetCurrentButton}
                    onClick={() => dispatch({
                        type: actionTypes.LM_RESET_CURRENT_MERGE,
                      })
                    }
                    className="btn-primary precalc-merge-reset-btn"
                >
                  {`Reset Merge - ${currentListId}`}
                </Button>
                <Button
                    onClick={() => updatePrecalcList()}
                    disabled={disableUpdatePrecaclListButton}
                    className="btn-primary precalc-merge-reset-btn"
                >
                  Update Precalcs List
                </Button>
              </div>
              <div className={'precalc-merge-navigation-buttons'}>
                <Button
                    disabled={selectedPrecalcListIndex < 1}
                    onClick={
                      () => dispatch({
                        type: actionTypes.LM_UPDATE_PRECALC_LIST_INDEX,
                        payload: {
                          indexValue: selectedPrecalcListIndex - 1,
                        }
                      })
                    }
                    className="btn-primary precalc-merge-navigate-btn"
                >
                  Previous
                </Button>
                <Button
                    onClick={
                      () => dispatch({
                        type: actionTypes.LM_UPDATE_PRECALC_LIST_INDEX,
                        payload: {
                          indexValue: selectedPrecalcListIndex + 1,
                        }
                      })
                    }
                    className="btn-primary precalc-merge-navigate-btn"
                >
                  Next
                </Button>
              </div>
            </Col>
          </Row>
          <PecalcListDiff
              leftSectionName={`Existing - ${currentListId}`}
              rightSectionName={`New - ${currentListId}`}
              mergeSectionName={`Merged - ${currentListId}`}
              leftSectionHeader={`Existing Precalcs Values for ${currentListId}`}
              rightSectionHeader={`New Precalcs Values for ${currentListId}`}
              mergeSectionHeader={`Merged Precalcs Values for ${currentListId}`}
              mainViewClassName={mainViewClassName}
              diffableLeftJSON={{...diffableSelectedItemOldJSONById[currentListId]}}
              diffableRightJSON={{...diffableSelectedItemNewJSONById[currentListId]}}
              mergeJSON={mergeSelectedItemNewJSONById}
              onLeftItemDelete={(data) =>  dispatch({
                  type: actionTypes.LM_UPDATE_CURRENT_MERGE,
                  payload: {
                    value: data,
                    section: 'left',
                  },
                })
              }
              onRightItemDelete={(data) =>  dispatch({
                  type: actionTypes.LM_UPDATE_CURRENT_MERGE,
                payload: {
                  value: data,
                  section: 'right',
                },
                })
              }
          />
        </div>
      </Fragment>
  );
};

export default PrecalcListMerger;
