import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  uploadNewImage,
  timelinePromoDelete,
  timelinePromoAddOrUpdate,
} from '../../services/mp-service-v1-api-service';
import {
  getCdnPublicFileUrl,
} from '../../utils';

const crudTimelinePromos = () => {

  const [ timelinePromosOperationData, setTimelinePromosOperationData ] = useState(null);
  const [ crudOperationData, setCrudOperationData] = useState(null);
  const {
    operation,
    imageFileData,
    timelinePromos,
    onCompleteOperation,
  } = crudOperationData || {};

  const doCrudTimelinePromos = async () => {
    try {
      console.log(`${operation.toUpperCase()} Timeline Promos...`);
      setTimelinePromosOperationData({
        operationInProgress: true,
        isError: false,
      });

      let operationResponse = null;
      let errorMsg = null;

      if(operation === 'create' || operation === 'update') {
        const updatedTimeLinePromo = timelinePromos[0];

        if(imageFileData && imageFileData.imageFile) {
          const imageUploadResp = await uploadNewImage(imageFileData.imageFile, {
            optimizeImages: imageFileData.optimizeImages,
            resizeImages: false,
            fileUploadPath: `/images/promos/${imageFileData.imageFile.name}`,
            bucketNameToUpload: 'mp_cdn_origin',
          });

          const responseData = imageUploadResp && imageUploadResp.data;
          const uploadedFiles = responseData && responseData.data;
          updatedTimeLinePromo['banner_url'] = getCdnPublicFileUrl(uploadedFiles[0]['public_url']);
        }

        operationResponse = await timelinePromoAddOrUpdate({
          timeline_promos: [updatedTimeLinePromo],
        });
      }

      if (operation === 'delete') {
        operationResponse = await timelinePromoDelete({ timeline_promos: timelinePromos });
      }

      const responseData = operationResponse && operationResponse.data;

      if(responseData.success) {
        console.log(`${operation} operation completed successfully.`);
      } else {
        console.log(`Error occurred while performing ${operation}.`);
        errorMsg = `Error occurred while performing ${operation}.`;
      }

      setTimelinePromosOperationData({
        data: responseData.data,
        operationInProgress: false,
        isError: !responseData.success,
        errorMsg,
      });

      onCompleteOperation({
        isError: !responseData.success,
        errorMsg,
      });
    } catch (err) {
      console.log(`Error while ${operation.toUpperCase()} Timeline Promos. Error : ${err}`);
      const errorMsg = `Error occurred while performing ${operation}.`;
      setTimelinePromosOperationData({
        data: null,
        operationInProgress: false,
        isError: true,
        errorMsg,
      });
      onCompleteOperation({
        isError: true,
        errorMsg,
      });
    }
  };

  useEffect( () => {
    if(!_.isEmpty(crudOperationData)) {
      doCrudTimelinePromos();
    }
  }, [ crudOperationData ]);

  return [
    timelinePromosOperationData,
    setCrudOperationData,
  ];
};

export default crudTimelinePromos;
