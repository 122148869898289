import React,
{
  useRef,
  Fragment,
  useEffect,
} from 'react';
import {
  Icon,
} from 'react-fa';

const FileUploader = ({
                        labelName='Upload File',
                        placeHolderText='Upload File',
                        acceptTypes='image/x-png,image/jpg,image/jpeg',
                        required=false,
                        className='file-uploader',
                        onUploadFile=(event) => console.log(` Data : ${event.target.files[0]}`),
                        uploadStatus,
                        fileDeleted=false,
                      }) => {
  const ref = useRef();

  useEffect(() => {
    if(fileDeleted ) {
      ref.current.value = ""
    }
  }, [ fileDeleted ]);

  const renderUploadStatus = (uploadStatus) => {
    switch (uploadStatus) {
      case 0:
        return (
            <p className='small text-muted'>
              <Icon spin name='circle-notch'/> Uploading...
            </p>
        );
      case 1:
        return (
            <p className='small text-success'>
              <Icon name='check-circle'/> Successful
            </p>
        );
      case -1:
        return (
            <p className='small text-danger'>
              <Icon name='exclamation-circle'/> Failed
            </p>
        );
      default:
        return null
    }
  };

  return (
      <Fragment>
        <div className='form-group'>
          <label>{labelName}</label>
          <input
              className={className}
              onChange={onUploadFile}
              required={required}
              type='file'
              placeholder={placeHolderText}
              accept={acceptTypes}
              multiple={false}
              ref={ref}
          />
          {renderUploadStatus(uploadStatus)}
        </div>
      </Fragment>
  );
};

export default FileUploader;
