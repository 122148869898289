import _ from 'lodash';
import React,
{
  useEffect,
} from 'react';
import LoadingOverlay from 'react-loading-overlay';
import {
  ScaleLoader,
} from 'react-spinners';
import BasicLayout from '../../layouts/basiclayout';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  removeHashTagsHook,
  listContentV2HashTagsHook,
} from '../../hooks';
import {
  TableToolBox,
  getConfirmHashTags,
  getHashTagDisplayData,
  ContentV2OperationStatus,
} from './common';
import {
  MPSimpleTable,
} from '../../widgets/mp-table';
import {
  confirmAlert,
} from "react-confirm-alert";
import AppModal from '../../widgets/modal';
import {
  getDeleteHashtags,
  getDownloadableHashTags,
} from './helper';
import exportFromJSON from 'export-from-json';
import {
  hashTagListColumns,
} from './columns';

const ListHashTags = () => {

  const [ hashTagsContentsData, doFetch ] = listContentV2HashTagsHook();
  const [ hashTagsRemoveRespData, doRemove ] = removeHashTagsHook();

  const isFetching = hashTagsContentsData && hashTagsContentsData.isFetching;
  const isDeleting = hashTagsRemoveRespData && hashTagsRemoveRespData.isDeleting;
  const tableError = hashTagsContentsData.isError && {
    message: hashTagsContentsData.errorMsg,
    isError: true,
  };

  useEffect(() => {
    doFetch({
      language_code: null,
    });
  }, []);

  const displayData = getHashTagDisplayData(hashTagsContentsData.data);

  const onDeleteComplete = operationData => {
    if(!operationData.isError) {
      toast.success(`Selected Hashtags Deleted Successfully!!`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      setTimeout(()=> {
        window.location.reload();
      }, 1000);
    } else {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const onDeleteSelected = (selectedFlatRows) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <AppModal
                show={true}
                showBody={true}
                headingText={`Are you sure want to delete the following ${_.size(selectedFlatRows)} hash tags ?`}
                modalBody={getConfirmHashTags(selectedFlatRows)}
                modalBodyClassName={'precalcs-diff-merge-view'}
                closeButtonText={'No'}
                okButtonText={'Yes, Delete'}
                handleClose={onClose}
                handleOk={() => {
                  const deleteHashTags = getDeleteHashtags(selectedFlatRows);
                  onClose();
                  doRemove({
                    deleteHashTags,
                    onOperationComplete: onDeleteComplete,
                  });
                }}
            />
        );
      }
    });
  };

  const onDownloadSelected = (selectedFlatRows, exportType) => {
    console.log('onDownloadSelected selectedFlatRows : ', selectedFlatRows);

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <AppModal
                show={true}
                showBody={true}
                headingText={`Are you sure want to download the following ${_.size(selectedFlatRows)} hash tags ?`}
                modalBody={getConfirmHashTags(selectedFlatRows)}
                modalBodyClassName={'precalcs-diff-merge-view'}
                closeButtonText={'No'}
                okButtonText={'Yes, Download'}
                handleClose={onClose}
                handleOk={() => {
                  onClose();
                  const downloadableContents = getDownloadableHashTags(selectedFlatRows);
                  const fileName = 'content-v2-hash-tags-download';
                  exportFromJSON({ data: downloadableContents, fileName, exportType })
                }}
            />
        );
      }
    });
  };

  return (
      <LoadingOverlay
          active={isDeleting}
          spinner={<ScaleLoader />}
          text='Deleting the selected hashtags.'
      >
        <BasicLayout pagePermission="moderate_precalc">
          <ToastContainer autoClose={2000}/>
          <div>
            <Row>
              <Col md={12}>
                <h3 className='content_editor_title'>{'Content V2 Hashtags List'}</h3>
              </Col>
            </Row>
            <MPSimpleTable
                originalData={hashTagsContentsData.data}
                displayData={displayData}
                columns={hashTagListColumns()}
                loading={isFetching}
                tableError={tableError}
                onDeleteSelected={onDeleteSelected}
                onDownloadSelected={onDownloadSelected}
                TableToolBox={TableToolBox}
                TableOperationStatus={ContentV2OperationStatus}
                initialFilters={[{ id: 'languageCode', value: 'en' }]}
                paginationList={[50, 100, 150, 200]}
            />
          </div>
        </BasicLayout>
      </LoadingOverlay>
  );
}

export default ListHashTags;
