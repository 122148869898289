const validYearRegex = /^[1-9][0-9]{3}$/;

const HIJRI_MONTH_NAMES = [
  'Muharram',
  'Safar',
  'Rabiulawal',
  'Rabiulakhir',
  'Jamadilawal',
  'Jamadilakhir',
  'Rejab',
  'Syaaban',
  'Ramadan',
  'Syawal',
  'Zulkaedah',
  'Zulhijjah'
];

const SIGHTING_HIJRI_NEXT_COUNT = 20;

export {
  validYearRegex,
  HIJRI_MONTH_NAMES,
  SIGHTING_HIJRI_NEXT_COUNT,
};
