import React from 'react';
import _ from 'lodash';
import { Table, Badge } from 'react-bootstrap';
import Select from 'react-select';
import { SURA_AYA_LENGTH } from './constants';

const AyaList = ({ aya_ids }) => {
  if(_.isEmpty(aya_ids)) {
    return <Badge>{'All'}</Badge>;
  }

  return _.map(aya_ids, aya => {
    return <Badge>{aya}</Badge>;
  })
};

const SurasV2 = ({ suras_v2 }) => {
  const rows = _.map(suras_v2, (sura, index) => {
    const { sura_id, aya_ids } = sura;

    return (
      <tr key={index}>
        <td>
          <code>{ sura_id }</code>
        </td>
        <td>
          <AyaList aya_ids={aya_ids} />
        </td>
      </tr>
    );
  });

  return (
    <Table striped bordered condensed hover className={'sura-v2-table'}>
      <thead>
      <tr>
        <th>Sura</th>
        <th>Ayas</th>
      </tr>
      </thead>
      <tbody>
      {rows}
      </tbody>
    </Table>
  );
};

const SurasV2Edit = ({ suras_v2, onEditAya }) => {
  const rows = _.map(suras_v2, (sura, index) => {
    const { sura_id, aya_ids } = sura;
    let selectedAyas;
    const ayasOptions = _.map(_.range(1, SURA_AYA_LENGTH[sura_id] + 1), (val) => {
      return {
        value: val,
        label: val
      };
    });

    if(_.isEmpty(aya_ids)) {
      selectedAyas = [];
    } else {
      selectedAyas = aya_ids.map((i) => {
        return { value: i, label: i };
      });
    }

    return (
      <tr key={index}>
        <td>{ sura_id }</td>
        <td>
          <Select
            onChange={(changes) => onEditAya(sura_id, changes)}
            isSearchable={true}
            value={selectedAyas}
            options={ayasOptions}
            isMulti
          />
        </td>
      </tr>
    );
  });

  return (
    <Table striped bordered condensed hover className={'sura-v2-table'}>
      <thead>
      <tr>
        <th>Sura</th>
        <th>Ayas</th>
      </tr>
      </thead>
      <tbody>
      {rows}
      </tbody>
    </Table>
  );
};

export {
  SurasV2,
  SurasV2Edit,
}