import React from "react";
import { MEDIA_LABELS, MEDIA_LABELS_V2 } from "../../Constants";
import { convertArrayToObjMap, convertArrayToObjMapWithKey } from "../../helper";
import QalboxImageInputBox from "./qalboxImageInputBox";
import { MPSelect } from "../../../../widgets/common";

const QalboxMediaLabelBox = ({
  mediaLabelList,
  handleMediaLabelUpdate,
  isFreeMedia,
}) => {
  const handleMediaLabelInputChange = (index, e) => {
    const { name, value } = e.target;
    const newMediaLabels = [...mediaLabelList];
    const labelObjPrevState = newMediaLabels[index]
      ? newMediaLabels[index]
      : {};
    newMediaLabels[index] = { ...labelObjPrevState, [name]: value };

    handleMediaLabelUpdate(newMediaLabels);
  };

  const handleMediaLabelAdd = (e) => {
    e.preventDefault();
    const newMediaLabels = mediaLabelList ? mediaLabelList : [];
    newMediaLabels.push({
      text: "",
      original_text: "",
      start_color: "",
      end_color: "",
      text_color: "",
      logo_image_url: "",
    });

    handleMediaLabelUpdate(newMediaLabels);
  };

  const handleMediaLabelRemove = (e) => {
    e.preventDefault();
    const { name } = e.target;
    const newMediaLabels = mediaLabelList ? mediaLabelList : [];
    const indexToDelete = name;
    newMediaLabels.splice(indexToDelete, 1);

    handleMediaLabelUpdate(newMediaLabels);
  };

  return (
    <div className="form-group">
      <label>Media Labels V2</label>
      {mediaLabelList &&
        mediaLabelList.length > 0 &&
        mediaLabelList.map((label, index) => (
          <div
            key={index}
            style={{
              marginBottom: "10px",
              border: "1px solid #ccc",
              padding: "3px",
            }}
          >
            <div className="qalbox-form-row">
              <div
                name={index}
                style={{ marginRight: "10px" }}
                onClick={handleMediaLabelRemove}
              >
                <span
                  className="glyphicon glyphicon-remove"
                  aria-hidden="true"
                />
              </div>
              <label>Media Label {index + 1}</label>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div style={{ marginRight: "5px" }}>
                <label>Text</label>
                <MPSelect
                  name={"text"}
                  items={convertArrayToObjMapWithKey(MEDIA_LABELS_V2, 'text')}
                  selectedItem={label.text}
                  onChangeSelect={(value) =>
                    handleMediaLabelInputChange(index, {
                      target: { name: "text", value: value },
                    })
                  }
                />
              </div>
              <div style={{ marginLeft: "20px" }}>
                <label>Original Text</label>x
                <MPSelect
                  name={"original_text"}
                  items={convertArrayToObjMapWithKey(MEDIA_LABELS_V2, 'originalText')}
                  selectedItem={label.original_text}
                  onChangeSelect={(value) =>
                    handleMediaLabelInputChange(index, {
                      target: { name: "original_text", value: value },
                    })
                  }
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div style={{ marginRight: "5px" }}>
                <label>Background color 1</label>
                <input
                  name="start_color"
                  value={label.start_color}
                  onChange={(e) => handleMediaLabelInputChange(index, e)}
                  type="text"
                  className="form-control"
                />
              </div>
              <div style={{ marginRight: "5px" }}>
                <label>Background color 2</label>
                <input
                  name="end_color"
                  value={label.end_color}
                  onChange={(e) => handleMediaLabelInputChange(index, e)}
                  type="text"
                  className="form-control"
                />
              </div>
              <div style={{ marginRight: "5px" }}>
                <label>Text Color</label>
                <input
                  name="text_color"
                  value={label.text_color}
                  onChange={(e) => handleMediaLabelInputChange(index, e)}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <QalboxImageInputBox
              name={"logo_image_url"}
              value={label.logo_image_url}
              handleInputTextChange={(e) => {
                handleMediaLabelInputChange(index, e);
              }}
            />
          </div>
        ))}
      <div
        style={{
          pointerEvents:
            (mediaLabelList && mediaLabelList.length >= 2) ||
            (isFreeMedia && mediaLabelList && mediaLabelList.length >= 1)
              ? "none"
              : "auto",
          color:
            (mediaLabelList && mediaLabelList.length >= 2) ||
            (isFreeMedia && mediaLabelList && mediaLabelList.length >= 1)
              ? "GrayText"
              : "ButtonText",
        }}
        onClick={handleMediaLabelAdd}
      >
        <span className="glyphicon glyphicon-plus" aria-hidden="true" />
      </div>
    </div>
  );
};

export default QalboxMediaLabelBox;
