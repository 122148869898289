import _ from 'lodash';
import crypto from 'crypto';
import moment from 'moment-timezone';
import {
  PREMIUM_TYPE,
  AUDIENCE_TYPE,
  CDN_ORIGIN_DOMAIN_NAME,
  MP_SERVICE_V1_API_API_KEY,
  CDN_PUBLIC_URL_DOMAIN_NAME,
} from '../Constants';
import {
  countries as CountryList,
} from '../services/utils';
import {
  getSecretKey,
} from '../services/mp-cf-api-service';
import {
  updateUserPremiumData,
} from '../services/rtdb-service';
import rtdbRef from "../apis/rtdbRef";

const timeUTCtoLocal = ({
                          utcTimeString,
                          utcFormat='YYYY-MM-DD HH:mm:ss',
                          localFormat='YYYY-MMM-DD h:mm:ss A',
                          returnValueForNotFound='N/A',
                        }) => {
  if(!utcTimeString) {
    return returnValueForNotFound;
  }

  const gmtDateTime = moment.utc(utcTimeString, utcFormat);
  return gmtDateTime.local().format(localFormat);
};

const getCdnPublicFileUrl = (fileUrl, replaceUrl = CDN_ORIGIN_DOMAIN_NAME) => {
  if(!fileUrl){
    return null;
  }

  return fileUrl.replace(CDN_PUBLIC_URL_DOMAIN_NAME, replaceUrl);
};

const getDisplayDate = (timestamp, pattern='MMM DD, YYYY hh:mm A') => {
  if(!timestamp) {
    return '-'
  }

  return moment(timestamp).format(pattern);
};

const checkIfDateIsLessThan = (timestamp, days = 1) => {
  if (!timestamp) {
    return false
  }

  const now = moment()
  const past = moment(timestamp)
  const duration = moment.duration(now.diff(past))
  const daysDiff = Math.floor(duration.asDays())

  return daysDiff < days
}

const displayTimeDifference = (pastTimestamp) => {
  try {
    const now = moment()
    const past = moment(pastTimestamp)
    const duration = moment.duration(now.diff(past))
  
    const days = Math.floor(duration.asDays())
    const hours = duration.hours()
    const minutes = duration.minutes()
    const seconds = duration.seconds()
  
    return `${days}D, ${hours}H, ${minutes}M, ${seconds}S`
  } catch (error) {
    return ''
  }
  
}

const csvDownloader = ({ content, fileName }) => {
  const element = document.createElement("a");
  const file = new Blob([content], {type: 'text/csv'});
  element.href = URL.createObjectURL(file);
  element.download = `${fileName}.csv`;
  element.click();
};

const getUrlParams = (props) => {
  if(props.location.search !== "") {
    const search = props.location.search.substring(1);
    return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
  } else {
    return {};
  }
};

const getUsersPromoRedemptions = async (uid) => {
  if(!uid) {
    return null;
  } else {
    const snapshot = await rtdbRef.promoCodes(`/promoCodes`).orderByChild('user_id').equalTo(uid).once('value');
    return snapshot.val();
  }
};

const getUsersActivePromoCode = async (uid, currentPremium) => {
  const redemptions = await getUsersPromoRedemptions(uid);
  if(!redemptions) {
    return null;
  } else {
    let activePromoCode;
    Object.keys(redemptions).forEach((key) => {
      const promo = redemptions[key];
      if(promo.type === currentPremium) {
        activePromoCode = promo;
        activePromoCode['code'] = key;
      }
    });
    return activePromoCode;
  }
};

const getDateFromExcelValue = (excelValue, epochMs=false, format=null) => {
  if(!excelValue) {
    return null;
  }

  let date = new Date((excelValue - (25569))*86400*1000);
  date.setTime(date.getTime() + date.getTimezoneOffset()*60*1000);

  if(format) {
    date = moment(date).format(format);
  }

  return epochMs ? date.getTime() : date;
};

const isValidAudiences = (audiences, targetAudiences=[]) => {
  if(_.isEmpty(targetAudiences)) {
    targetAudiences = _.values(AUDIENCE_TYPE);
  }

  return _.difference(audiences, targetAudiences);
};

const isValidCountryCodes = (countryCodes, targetCountryCodes=[], upperCase=true) => {
  if(_.isEmpty(targetCountryCodes)) {
    targetCountryCodes = _.keys(CountryList);
  }

  targetCountryCodes = upperCase
    ? _.map(targetCountryCodes,  value => value.toUpperCase())
    : _.map(targetCountryCodes,  value => value.toLowerCase());

  return _.isEmpty(_.difference(countryCodes, targetCountryCodes));
};

const isValidPlatforms = (platforms, targetPlatforms=[]) => {
  if(_.isEmpty(targetPlatforms)) {
    targetPlatforms = _.keys(AUDIENCE_TYPE);
  }

  return _.difference(platforms, targetPlatforms);
};

const getDefaultGCSConfigs = (configName) => {
  const projectConfig = { project: 'muslimproapi', bucketName: 'mp_cdn_origin'};
  return configName ? projectConfig[configName] :projectConfig ;
};

const getTestGCSConfigs = (configName) => {
  const testProjectConfig = { project: 'muslimproapi', bucketName: 'test_mp_cdn_origin'};
  return configName ? testProjectConfig[configName] : testProjectConfig;
};

const convertToRFCDateTime = (dateTime) => {
  return `${moment(dateTime).utc().format("ddd, DD MMM YYYY HH:mm:ss ZZ")}`;
};

const getAPIV1At = async () => {
  const eav1at = localStorage.getItem('eav1at');
  if (eav1at) {
    return Buffer.from(eav1at, 'base64').toString('ascii'); // Using ascii for the performance.
  } else {
    const serverEnvConfigsResp = await getSecretKey({
      secretKey: MP_SERVICE_V1_API_API_KEY,
    });
    const responseData = serverEnvConfigsResp.data;
    if(responseData.success) {
      const at = responseData.payload;

      if (at) {
        const eav1at =  Buffer.from(at).toString('base64');
        localStorage.setItem('eav1at', eav1at);
      }

      return at;
    } else {
      throw Error('API key fetch failed.');
    }
  }
};

const makeUserPremiumForTest = async ({
                                        uid,
                                        premiumType,
                                      }) => {
  if (!uid || !premiumType) {
    return false;
  }

  const premiumData = {
    error: null,
  };
  switch (premiumType) {
    case PREMIUM_TYPE.LIFETIME:
      premiumData['premium'] = PREMIUM_TYPE.LIFETIME;
      premiumData['expiry'] = null;
      premiumData['expiry_grace'] = null;
      premiumData['free_trial'] = null;
      premiumData['active_sku'] = 'com.bitsmedia.android.muslimpro.premiumupgrade.usd';
      break;
    case PREMIUM_TYPE.YEARLY:
      premiumData['premium'] = PREMIUM_TYPE.YEARLY;
      premiumData['expiry'] =  moment().add(365, 'days').valueOf();
      premiumData['expiry_grace'] = moment().add(14, 'days').valueOf();
      premiumData['free_trial'] = null;
      premiumData['active_sku'] = 'muslimpro_premium_yearly_1_usd';
      break;
    case PREMIUM_TYPE.MONTHLY:
      premiumData['premium'] = PREMIUM_TYPE.MONTHLY;
      premiumData['expiry'] = moment().add(30, 'days').valueOf();
      premiumData['expiry_grace'] = moment().add(14, 'days').valueOf();
      premiumData['free_trial'] = null;
      premiumData['active_sku'] = 'muslimpro_premium_monthly_1_usd';
      break;
    default:
      return false;
  }

  return updateUserPremiumData({
    uid,
    premiumData,
  });
};

const removeUserPremiumForTest = async ({
                                          uid,
                                          removePurchases=true,
                                        }) => {
  if (!uid) {
    return false;
  }

  const premiumData = {
    error: null,
    premium: null,
    expiry: null,
    expiry_grace: null,
    free_trial: null,
    active_sku: null,
  };

  if (removePurchases) {
    premiumData['purchases'] = null;
  }

  return updateUserPremiumData({
    uid,
    premiumData,
  });
};

const md5 = (string) => {
  return crypto.createHash('md5').update(string).digest('hex');
};

export {
  md5,
  timeUTCtoLocal,
  getCdnPublicFileUrl,
  getDisplayDate,
  csvDownloader,
  getDateFromExcelValue,
  getUrlParams,
  getUsersPromoRedemptions,
  getUsersActivePromoCode,
  isValidAudiences,
  isValidCountryCodes,
  isValidPlatforms,
  getDefaultGCSConfigs,
  getTestGCSConfigs,
  convertToRFCDateTime,
  getAPIV1At,
  makeUserPremiumForTest,
  removeUserPremiumForTest,
  displayTimeDifference,
  checkIfDateIsLessThan
}
