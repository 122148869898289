import axios from "axios";
import { MP_AIDEEN_BASE_URL } from "./Constants";

const getBaseConfig = (idToken) => {
  const headers = {
    'idToken': `Bearer ${idToken}` || '',
    'Content-Type': 'application/json',
  };

  if (idToken) {
    headers['Authorization'] = `Bearer ${idToken}`;  
  }

  return {
    baseURL: MP_AIDEEN_BASE_URL,
    headers,
  };
}

const aideenAxiosService = axios.create({
  ...getBaseConfig(localStorage.getItem('idToken')),
  responseType: 'json'
});
export default aideenAxiosService;