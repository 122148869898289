import _ from 'lodash';
import React,
{
  useState,
  useEffect,
} from 'react';
import BasicLayout from '../../layouts/basiclayout';
import {
  Row,
  Col,
} from 'react-bootstrap';
import './style.css';
import {
  ToastContainer,
} from 'react-toastify';
import {
  RTDB_DATABASE,
  RTDB_DATABASE_URL,
  RTDB_DATABASE_NODE,
} from '../../Constants';
import {
  getUser,
} from '../../services/rtdb-service';
import {
  DevOptOptions,
  DevOptSubOptions,
} from './Constants';
import OptionSection from './optionSection';
import {
  confirmAlert,
} from 'react-confirm-alert';
import AppModal from '../../widgets/modal';
import {
  processOption,
} from './helper';
import {
  OperationStatus,
} from '../../widgets/common';
import shardManager from "../../service_v2/users/shard-manager";

const DeveloperOptions = () => {

  let user = localStorage.getItem('user');
  const [ userData, setUserData ] = useState(null);
  const [ processing, setProcessing ] = useState(false);
  const [ shardUrl, setShardUrl ] = useState("");

  if (!user) {
    return (
        <div>
          <p>
            User not found to continue with developer options. Please logout and login back and try again.
          </p>
        </div>
    );
  } else {
    user = JSON.parse(user);
  }

  useEffect(async () => {
    if (user.uid) {
      const [dbUser, shardurl] = await Promise.all([
        getUser({
          uid: user.uid,
        }),
        shardManager.getUserShardUrl(user.uid)
      ]);
      dbUser['uid'] = user.uid;
      setUserData(dbUser);
      setShardUrl(shardurl)
    }
  }, [user.uid, processing]);

  const processOperation = async (optionData) => {
    setProcessing(true);
    await processOption(optionData);
    setProcessing(false);
  };

  const onClickOption = (optionData) => {
    const operation = DevOptSubOptions[DevOptOptions[optionData.mainOption]][optionData.subOption];

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <AppModal
                show={true}
                showBody={false}
                headingText={`Are you sure want to ${operation} for ${user.email}?`}
                modalBodyClassName={'precalcs-diff-merge-view'}
                closeButtonText={'No'}
                okButtonText={`Yes, Proceed`}
                handleClose={onClose}
                handleOk={() => {
                  processOperation(optionData);
                  onClose();
                }}
            />
        );
      }
    });
  };

  const developerOptions = _.map(DevOptOptions, (val, key) => {
    return (
        <OptionSection
            key={key}
            mainOption={key}
            mainOptionText={val}
            userData={userData}
            onClickOption={onClickOption}
        />
    );
  });

  return (
      <BasicLayout pagePermission="readonly">
        <ToastContainer autoClose={2000}/>
        <div className="dev-opt-wrapper">
          <Row>
            <Col md={12}>
              <h3>{'Developer Options'}</h3>
              <p className={'dev-opt-account-details'}>
                Following actions will be applied on&nbsp;
                <strong>
                  <a
                      target="_blank"
                      href={shardUrl}
                  >
                    {user.email}
                  </a>
                </strong>
              </p>
            </Col>
          </Row>
          <Col md={12}>
            {
              processing ?
                  <OperationStatus
                      operationInProgressText={`Please wait! Processing...`}
                  />
                  : developerOptions
            }
          </Col>
        </div>
      </BasicLayout>
  );
};

export default DeveloperOptions;
