// EUF Update Actions
const SIGHTING_UPDATE_PROP_VAL = 'SIGHTING_UPDATE_PROP_VAL';
const SIGHTING_ADD_NEW_YEAR = 'SIGHTING_ADD_NEW_YEAR';
const SIGHTING_UPDATE_DATA = 'SIGHTING_UPDATE_DATA';
const SIGHTING_RESET_STATE = 'SIGHTING_RESET_STATE';
const SIGHTING_ON_RESET_YEAR = 'SIGHTING_ON_RESET_YEAR';
const SIGHTING_ON_DELETE_YEAR = 'SIGHTING_ON_DELETE_YEAR';
const SIGHTING_ON_TOGGLE_COUNTRY = 'SIGHTING_ON_TOGGLE_COUNTRY';
const SIGHTING_ON_DELETE_COUNTRY = 'SIGHTING_ON_DELETE_COUNTRY';
const SIGHTING_ON_ADD_COUNTRY = 'SIGHTING_ON_ADD_COUNTRY';

// Hijri Year Update Actions
const HYU_UPDATE_PROP_VAL = 'HYU_UPDATE_PROP_VAL';
const HYU_ADD_NEW_COUNTRIES = 'HYU_ADD_NEW_COUNTRIES';
const HYU_UPDATE_DATA = 'HYU_UPDATE_DATA';
const HYU_RESET_STATE = 'HYU_RESET_STATE';
const HYU_ON_ADD_YEAR = 'HYU_ON_ADD_YEAR';
const HYU_ON_RESET_YEAR = 'HYU_ON_RESET_YEAR';
const HYU_ON_DELETE_YEAR = 'HYU_ON_DELETE_YEAR';
const HYU_ON_RESET_COUNTRY = 'HYU_ON_RESET_COUNTRY';
const HYU_ON_DELETE_COUNTRY = 'HYU_ON_DELETE_COUNTRY';
const HYU_ON_UPDATE_DATE = 'HYU_ON_UPDATE_DATE';
const HYU_ON_UPDATE_REF_DATA = 'HYU_ON_UPDATE_REF_DATA';

export const actionTypes = {
  SIGHTING_UPDATE_PROP_VAL,
  SIGHTING_ADD_NEW_YEAR,
  SIGHTING_UPDATE_DATA,
  SIGHTING_RESET_STATE,
  SIGHTING_ON_RESET_YEAR,
  SIGHTING_ON_DELETE_YEAR,
  SIGHTING_ON_TOGGLE_COUNTRY,
  SIGHTING_ON_DELETE_COUNTRY,
  SIGHTING_ON_ADD_COUNTRY,
  HYU_UPDATE_PROP_VAL,
  HYU_ADD_NEW_COUNTRIES,
  HYU_UPDATE_DATA,
  HYU_RESET_STATE,
  HYU_ON_ADD_YEAR,
  HYU_ON_RESET_YEAR,
  HYU_ON_DELETE_YEAR,
  HYU_ON_RESET_COUNTRY,
  HYU_ON_DELETE_COUNTRY,
  HYU_ON_UPDATE_DATE,
  HYU_ON_UPDATE_REF_DATA,
};