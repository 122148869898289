import _ from 'lodash';
import {
    DevOptOptions,
    DataLocations,
    DataLocationType,
    DevOptSubOptions,
} from './Constants';
import {
    makeUserPremiumForTest,
    removeUserPremiumForTest,
} from '../../utils';
import {
    PREMIUM_TYPE,
} from '../../Constants';
import {
    removeUserData,
} from '../../services/rtdb-service';
import {
    createLog,
} from '../../services/logs';

const getSubOptions = (mainOption) => {
    if (!mainOption) {
        return [];
    }

    return DevOptSubOptions[DevOptOptions[mainOption]];
};

const getFilteredUserData  = ({
                                  userData,
                                  mainOption,
                              }) => {
    const selectedDataLocation = DataLocations[DevOptOptions[mainOption]];
    if (!mainOption) {
        return {};
    }

    const {
        type,
        keyValue,
    } = selectedDataLocation;

    switch (type) {
        case DataLocationType.NODE:
            const nodeLocation = _.replace(keyValue,new RegExp('>','g'),'.');
            const nodeData = _.at(userData, [nodeLocation]);
            return _.isEmpty(nodeData) ? {} : nodeData[0];
        case DataLocationType.KEY_START_WITH:
            const locations = _.split(keyValue,'>');
            const filterKeyWord = locations[_.size(locations) - 1];
            const keyWordNodeLocation = _.join(_.dropRight(locations), '.');
            const dataNode = _.at(userData, [keyWordNodeLocation]);
            if (_.isEmpty(dataNode)) {
                return {};
            }

            return _.pickBy(dataNode[0], (value, key) => {
                return _.startsWith(key, filterKeyWord);
            });
        default:
            return {};
    }
};

const processOption = async ({
                                 userData,
                                 subOption,
                                 mainOption,
                             }) => {
    try {
        const selectedMainOption = DevOptSubOptions[DevOptOptions[mainOption]];
        const option = selectedMainOption[subOption];

        switch (option) {
            case selectedMainOption.MAKE_ACCOUNT_LIFE_TIME_PREMIUM:
                await makeUserPremiumForTest({
                    uid: userData.uid,
                    premiumType: PREMIUM_TYPE.LIFETIME,
                });
                break;
            case selectedMainOption.MAKE_ACCOUNT_YEARLY_PREMIUM:
                await makeUserPremiumForTest({
                    uid: userData.uid,
                    premiumType: PREMIUM_TYPE.YEARLY,
                });
                break;
            case selectedMainOption.MAKE_ACCOUNT_MONTHLY_PREMIUM:
                await makeUserPremiumForTest({
                    uid: userData.uid,
                    premiumType: PREMIUM_TYPE.MONTHLY,
                });
                break;
            case selectedMainOption.REVOKE_PREMIUM:
                await removeUserPremiumForTest({
                    uid: userData.uid,
                    removePurchases: false,
                });
                break;
            case selectedMainOption.WIPE_PREMIUM:
                await removeUserData({
                    uid: userData.uid,
                    node: 'premium_status',
                });
                break;
            case selectedMainOption.CLEAR_QURAN_BOOK_MARKS:
                await removeUserData({
                    uid: userData.uid,
                    node: 'saved_data/quran_bookmarks',
                });
                break;
            case selectedMainOption.CLEAR_QURAN_CHECK_MARKS:
                await removeUserData({
                    uid: userData.uid,
                    node: 'saved_data/quran_checkmarks',
                });
                break;
            case selectedMainOption.CLEAR_QURAN_HIGHLIGHTS:
                await removeUserData({
                    uid: userData.uid,
                    node: 'saved_data/quran_highlights',
                });
                break;
            default:
                return false;
        }

        createLog(option.toLowerCase(), {
            option,
            uid: userData.uid,
        }).then(() => console.log('Dev option logging success!!'))
            .catch(() => console.log('Dev option logging failed!'));
    } catch (err) {
        console.log('Error : ', err);
    }
};

export {
    getSubOptions,
    processOption,
    getFilteredUserData,
}
