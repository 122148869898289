import {
  useState,
  useEffect,
} from 'react';
import {
  timelinePromoGetRawJSON,
} from '../../services/mp-service-v1-api-service';

const fetchTimeLinePromosRawJson = ({
                                      shouldFetchTimelinePromos = false,
                                    }) => {
  const [ timelinePromos, setTimelinePromos ] = useState(null);

  const fetchTimeLinePromos = async () => {
    console.log('Fetching Timeline Promos...');
    const timelinePromosApiResp =  await timelinePromoGetRawJSON();

    const responseData = timelinePromosApiResp.data;
    if(responseData.success) {
      console.log('Timeline Promos Fetch Success!!');
      setTimelinePromos(responseData.data);
    } else {
      console.log('Timeline Promos Fetch Failed!!');
    }
  };

  useEffect( () => {
    if(shouldFetchTimelinePromos) {
      fetchTimeLinePromos();
    }
  }, [ shouldFetchTimelinePromos ]);

  return [
    timelinePromos,
  ];
};

export default fetchTimeLinePromosRawJson;
