import { useState, useEffect } from 'react';

export const useFetchShortEncodeDetails = (uuid, refresh) => {
  const [shortEncodeResponse, setShortEncodeResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchShortEncodeDetails = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}qalbox-admin-short-service/v1/get/encode/${uuid}`
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch short encode details (status ${response.status})`);
        }

        const encodeDetails = await response.json();
        setShortEncodeResponse(encodeDetails);
      } catch (err) {
        console.error(err);
        setError(err.message || 'An error occurred while fetching short encode details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchShortEncodeDetails();

  }, [uuid, refresh]); // Fetch brands whenever the 'active' parameter changes

  return [ shortEncodeResponse, isLoading, error ];
};