import { useState } from 'react';
import axiosService from '../../../../../service_v2/common/axios-service';

export const useTimelineCacheClear = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const cacheClear = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const timelineCacheClearResponse = await axiosService.delete(
        `${process.env.REACT_APP_MP_API_SERVER_BASE_URL}admin/cache?key=qb_timeline_carousel_cache&operation=directDelete`
      );
      
      if (timelineCacheClearResponse.status === 200) {
        setSuccess(true);
      } else {
        setError("Failed to clear timeline cache");
      }
    } catch (error) {
      setError("An error occurred while clearing the timeline cache");
    } finally {
      setLoading(false);
    }
  };

  return [ cacheClear, loading, error, success ];
};