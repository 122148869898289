import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  quranQuotesGetRawFile,
} from '../../services/mp-service-v1-api-service';

const fetchQuranQuotesList = () => {
  const [ quranQuotesListData, setQuranQuotesListData ] = useState({ });
  const [ fetchData, setFetchData ] = useState(null);
  const {
    onOperationComplete,
  } = fetchData || {};

  const fetchQuranQuotesRawFile = async () => {
    try {
      console.log(`Fetching quran quotes list...`);
      setQuranQuotesListData({
        isFetching: true,
        isError: false,
      });
      const getRawFileApiResp =  await quranQuotesGetRawFile();

      const responseData = getRawFileApiResp.data;
      let errorMsg = null;
      if(responseData.success) {
        console.log(`Fetching quran quotes list Success!!`);
      } else {
        console.log(`Fetching quran quotes list Failed!!`);
        errorMsg = `Error occurred while fetching quran quotes list.`;
      }

      setQuranQuotesListData({
        data: responseData.data,
        isFetching: false,
        isError: !responseData.success,
        errorMsg,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError: !responseData.success,
          errorMsg,
          data: responseData.data,
        });
      }

    } catch (err) {
      const errorMsg = (err.response && err.response.status === 404) ?
        `Quran quotes list file not found!` : `Error occurred while fetching quran quotes list.`;
      console.log(`${errorMsg} Error : ${err}`);
      setQuranQuotesListData({
        data: null,
        isFetching: false,
        isError: true,
        errorMsg,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
          data: null,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(fetchData)) {
      fetchQuranQuotesRawFile();
    }
  }, [ fetchData ]);

  return [
    quranQuotesListData,
    setFetchData,
  ];
};

export default fetchQuranQuotesList;
