import React from "react";
import { Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";

const QalboxCopyInputBox = ({ name, value, handleInputTextChange}) => {
  return (
    <div className="qalbox-form-row">
      <textarea
        value={value}
        name={name}
        type="text"
        onChange={handleInputTextChange}
        className="form-control"
        placeholder={name}
        style={{ marginBottom: "5px", marginRight: "10px" }}
      />
      <CopyToClipboard
        text={value}
      >
        <Button className="btn btn-xs btn-dark mp-content-v2-table-hover-btn">
          &nbsp;
          <span className="glyphicon glyphicon-duplicate" aria-hidden="true" />
        </Button>
      </CopyToClipboard>
    </div>
  );
};

export default QalboxCopyInputBox;
